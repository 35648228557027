import {  ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import PriceMaterialService from "../Services/PriceMaterialService";
import { IpriceMaterial } from "../Interfaces/IpriceMaterial";
import IdataModelPriceMaterielDtos from "../Models/IdataModelPriceMaterielDtos";




export const PriceMaterielTable:React.FC<IpriceMaterial>=props=>{

    const [priceMateriel, setpriceMateriel] = useState<Array<IdataModelPriceMaterielDtos>>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchdetails, setSearchdetails] = useState<string>("");
    
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6];
    
    const getRequestParams = (searchmaterielName:string, thepage: number, thepageSize: number) => {
      let params = {
        materielName:searchmaterielName,
        page: thepage,
        size:thepageSize
      };
    
      if (searchmaterielName) {
        params["materielName"] = searchmaterielName;
      }
    
      if (thepage) {
        params["page"] = thepage - 1;
      }
      
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
   

    const onChangeSearchMaterial = (e: ChangeEvent<HTMLInputElement>) => {
      const searchdetails = e.target.value;
      setSearchdetails(searchdetails);
    };
    
    const findBydetails = () => {
     // setpriceMateriel([]);
      PriceMaterialService.findByMaterielName(searchdetails)
        .then((response: any) => {
          const {materialPrice } = response.data;
          setpriceMateriel(materialPrice);
          setCurrentIndex(-1);
          console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const retrievedetails = () => {
      const params = getRequestParams(searchdetails, page, pageSize);
      // setpriceMateriel([]);
      PriceMaterialService.getAll(params)
    
        .then((response: any) => {
    
          const {materialPrice , totalPages} = response.data;
    
          setpriceMateriel(materialPrice);
          setCount(totalPages);
    
          console.log(response.data);
          console.log(params);
    
        })
    
        .catch((e: Error) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrievedetails();       
    },[page , pageSize]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
    const refreshList = () => {
      retrievedetails();
      setCurrentIndex(-1);
    };
    
    const setActiveMaterialPrice = (idataModelPriceMaterielDtos: IdataModelPriceMaterielDtos, index: number) => {
      setCurrentIndex(index); 
       props.onEditprice(idataModelPriceMaterielDtos);
       props.onHide();
      console.log(idataModelPriceMaterielDtos);
      console.log(index);
    };
    
    const OnDeleteMaterialPrice = (id:any) => {
    
      PriceMaterialService.remove(id)
        .then((response: any) => {
          console.log(response.data);
          refreshList();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

      return(
            <>
            <Row className="mb-3">
          
          <Stack  direction="horizontal" gap={2}>
                    <Form.Control className="me-auto"
                      placeholder="Filtre le soins..."
                      value={searchdetails}
                       onChange={onChangeSearchMaterial} 
                      />
                    <Button variant="outline-dark btn-sm"
                     onClick={findBydetails}>Cherchez</Button>
             </Stack>   
            <div className="mt-3">
              
            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
                <Row>
                  <Col>
                  <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />      
            </Col>             
          </Row>
              
            </div>
            <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>Material</th>
                <th>Prix</th>
                <th>Structure</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {priceMateriel.length>0 ? (
              priceMateriel.map((i, index) => (
                    <tr key={index }>
                    <td>{index+1}</td>
                    <td>{i.materielName}</td>
                    <td>{i.tariffPrice}</td>
                    <td>{i.hospitalName}</td>
                    <td>
                    <Stack direction="horizontal" gap={2}>
                    <Button className="me-4 btn btn-success btn-sm btn-block"
                    onClick={() => setActiveMaterialPrice(i, index)}>Edit</Button>
                      <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteMaterialPrice(i.tariffId)}>Delete</Button>
                    </Stack>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={3}>No details</td>
                </tr>
              )}
    
            </tbody>
          </Table>
          </Row>
    
            </>
        );
    }

