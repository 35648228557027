import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { CotisationAssuranceDetailsDto } from "../../../Components/ASSURANCES/Recouvrements/SaisieDetailsFacture/Models/CotisationAssuranceeDetailsDto";
import { Add } from "../../../Components/ASSURANCES/Recouvrements/SaisieDetailsFacture/Operations/Add";
import { Edit } from "../../../Components/ASSURANCES/Recouvrements/SaisieDetailsFacture/Operations/Edit";
import { TableOperations } from "../../../Components/ASSURANCES/Recouvrements/SaisieDetailsFacture/Tables/TableOperations";

const initialTypeStatedto = {
    // for cotisationAssurance
    cotisationAssuranceId:  0,
    money:  0,
    createdBy:  '',
    moisPayer:  '',
    statusPayer:  '',
    statusClassee:  '',
    comment:  '',
    dateCreated:  '',
    timeCreated:  '',
  
    // for cotisationAssuranceDetails
    cotisationAssuranceIdDetails:  '',
    codeCategory:  '',
    codeCategorySoins:  '',
    number:  '',
    total:  '',
    createdByDetails:  '',
    commentDetails:  '',
    dateCreatedDetails: '',
    timeCreatedDetails:  '',
  
    // for enterprise
    enterprisesAssuranceId:  0,
    enterpriseName:  '',
  
    // for socialInsurance
    socialinsuranceId:  0,
    socialInsuaranceName:  '',
};
export const CotisationEntrepriseDetails:React.FC=()=>{

  
    const [EditListAdherant,setEditListAdherant] = 
    useState<CotisationAssuranceDetailsDto>(initialTypeStatedto)

    const onCurrentAdhesion = (onCurrant: CotisationAssuranceDetailsDto) => {
        setEditListAdherant(onCurrant);
      };

    const [isSwichLayerAdherant,setSwichLayerAdherant] = useState(true);
    
    return(
        <>
        <Container fluid>
 <Row>  
                <Col> 
                <br />
                <Card>
            <Card.Body>
            {isSwichLayerAdherant?
                (
                <Add/>
                ):(
<Edit
 show={isSwichLayerAdherant}
   onHide={() => setSwichLayerAdherant(true)}
   EditInfo={EditListAdherant} /> 
                )
                }
 <TableOperations
  show={isSwichLayerAdherant}
  onHide={() => setSwichLayerAdherant(false)}
  onEdit={onCurrentAdhesion}
          />
   </Card.Body>
        </Card>  <br />
                   </Col>

            </Row>
          
        </Container>
        </>
    );
}