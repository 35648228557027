import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelCategoryType from "../Models/IdataModelCategoryType";

const getAll = (params:any) => {
  return http.get<Array<IdataModelCategoryType>>("/categoriesInsurance", { params });
};

const getAllTypesoinsWithoutPagination = () => {
  return http.get<Array<IdataModelCategoryType>>("/sortedCategoriesInsurance");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelCategoryType>>("/categoriesInsuranceAccountCategory");
}

const create = (socialinsuranceId:any,data: IdataModelCategoryType) => {
  return http.post<IdataModelCategoryType>(`/socialinsurance/${socialinsuranceId}/categoriesInsurance`, data);
};

const update = (id: any, data: IdataModelCategoryType) => {
  return http.put<any>(`/categoriesInsurance/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/categoriesInsurance/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/categoriesInsurance`);
};

const findByCategoryType = (categoryName: string) => {
  return http.get<Array<IdataModelCategoryType>>(`/categoriesInsurance?categoryName=${categoryName}`);
};

const findByCategoryName = (categoryName: string) => {
  return http.get<Array<IdataModelCategoryType>>(`/categoriesInsuranceCategoryName/${categoryName}`);
};

const CategoryAffilieTypeService = {
  findByCategoryName,
  getAllTypesoinsWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByCategoryType
};

export default CategoryAffilieTypeService;
