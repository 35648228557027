
import { useEffect, useState } from 'react';
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import IdataModelPMember from "../../../Components/ASSURANCES/AdherantPrencipales/Models/IdataModelPMember";
import { AddPmember } from '../../../Components/ASSURANCES/AdherantPrencipales/PMemberOperations/AddPmember';
import { EditPmember } from '../../../Components/ASSURANCES/AdherantPrencipales/PMemberOperations/EditPmember';
import { TablePmember } from '../../../Components/ASSURANCES/AdherantPrencipales/Tables/TablePmember';
import IdataModeEnterpriseDtos from '../../../Components/ASSURANCES/Entreprises/Models/IdataModeEnterpriseDtos';
import IdataModelPMemberDtos from '../../../Components/ASSURANCES/AdherantPrencipales/Models/IdataModelPMemberDtos';

const initialTypeStatePmemberDtos = {
   
    //table enterpriseAssurance   ta1
    idInterprise:0,
    accountInterprise:0,
    enterpriseName: '',
    couvertureDu: '',
    couvertureAau: '',
    resilierSociete:'',

///table PMember pa
    idPmember:0,
    codeAssuree: '',
    nomPrenomAffilie: '',
    cotisationExact: '',
    account:0,
    genre: '',
    catégorieSoins: '',
    email: '',
    telephones: '',
    adresseAffilie: '',
    autresInformations: '',

    plafond_ambilatoire: '',
    plafond_hospitalisation: '',
    plafond_pharmacy: '',
    plafond_ORL: '',
    plafond_urugence: '',
    plafond_ophtalmologie: '',
    category_code: '',
    plafond_dentisteries: '',
    resilier: '',
    dataResiliation: '',
    photoMember: '',
    dateNaissance: '',
    createdBy: '',
    dateCreated: '',
    tva:'',
    etatCivil:'',
   ////table socialInsurance so
    idSociete:0,
    societeName: ''
   
  };

    const initialTypeStateEnterpriseDtos = {
      ////table socialInsurance ta
      idSociete:0,
      societeName:0,
     //table enterpriseAssurance   ta1
    idInterprise:0,
    accountInterprise:0,
      enterpriseName: '',
      responsable: '',
      email: '',
      phoneNumber: '',
      couvertureDu: '',
      couvertureAau: '',
      address: '',
      autreInformation: '',
      resilier: '',
      dataResiliation: '',
     dateCreatedBy: new Date,
      createdBy: '',
      firstCallForPayment: '',
      secondCallForPayment: '',
      thirdCallForPayment: '',
      modePayment: '',
      frequancePayment: '',
     ////// configuration of assurance start
      primeIndividuelle: '',
      primeTotal: '',
      soinsAmbilatoire: '',
      hospitalisation: '',
      ophtalmologie: '',
      dentisterie: '',
      fraisFuneraires: ''
    };
  
  
  interface ICurrantinterprise {
    show: boolean; 
    onHide: () => void; 
    editinfo: IdataModeEnterpriseDtos;
  }

export const AdherantPrencipal:React.FC<ICurrantinterprise>=props=>{

    const [EditList, setEditList] = useState<IdataModelPMemberDtos>(initialTypeStatePmemberDtos);
    
    const [CurrantEnterprise, setCurrantEnterprise] = useState<IdataModeEnterpriseDtos>(initialTypeStateEnterpriseDtos);

    const onCurrentPMember = (onCurrent: IdataModelPMemberDtos) => {
        setEditList(onCurrent);
      };

    const [isSwichLayerPrencipalMember,setSwichLayerPrencipalMember] = useState(true);
    

    useEffect(() => {
        setCurrantEnterprise(props.editinfo)
          },[props.editinfo])
    return(
        <>
        <Modal {...props}
     fullscreen
    backdrop="static"
    keyboard={false}
    
     aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Adherant Prencipal de {CurrantEnterprise.enterpriseName} 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
      <Container fluid>
       <Row>
                <Col> 
                <Card>
            <Card.Body>
           {isSwichLayerPrencipalMember?
                (
                    <AddPmember editinfo={CurrantEnterprise} />
                ):(
            <EditPmember 
            show={isSwichLayerPrencipalMember}  
            onHide={() => setSwichLayerPrencipalMember(true)}
            EditInfo={EditList}
            /> 
                )
  }
         <TablePmember 
         show={isSwichLayerPrencipalMember}  
         onHide={() => setSwichLayerPrencipalMember(false)}
         onEdit={onCurrentPMember}
         onGetEnterpriseDtos={CurrantEnterprise}
          />
            </Card.Body>
        </Card>
                </Col>
            </Row>
          
        </Container>

     
      </Modal.Body>
    
    </Modal>
           
      
        </>
    );
}