import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/Store/store";

import React from "react";
import Excel from 'exceljs';
import saveAs from "file-saver";

import { RiSearch2Line } from 'react-icons/ri';
import { IoIosDownload } from 'react-icons/io';

import { useHistory } from 'react-router-dom';
import NewFactureLivraisonMedicamentDto from "../../../FactureMedicament/models/Dto/NewFactureLivraisonMedicamentDto";
import ServiceNewFactureLivraisonMedicament from "../../../FactureMedicament/services/ServiceNewFactureLivraisonMedicament";
import PharmacyTypeService from "../../../../ASSURANCES/StructurePartenaires/Pharmacies/Services/PharmacyTypeService";
import IdataModelPharmacy from "../../../../ASSURANCES/StructurePartenaires/Pharmacies/Models/IdataModelPharmacy";

    const defaultinitiaType: Array<IdataModelPharmacy> = [];
  
    let MonStructure:string='';
export const TableListeVisiteGroupDelcarationPharma:React.FC=()=>{
      
  const history = useHistory();
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
const DispatchPushCarrantisNewFactureLivraisonMedicamentDto=useDispatch();
const [isHospitalDropDown,setIsHospitalDropDown]=useState(true);
const [isNewFactureLivraisonMedicamentDto, setisNewFactureLivraisonMedicamentDto] = useState<Array<NewFactureLivraisonMedicamentDto>>([]);

const [isNewFactureLivraisonMedicamentDtoBetween, setisNewFactureLivraisonMedicamentDtoBetween] = useState<Array<NewFactureLivraisonMedicamentDto>>([]);
const [currentIndex, setCurrentIndex] = useState<number>(-1);

const [searchReceiptBill, setsearchReceiptBill] = useState<string>("");

const [pharmacyName, setpharmacyName] = useState('');

const [modalShow, setModalShow] = useState(false);
const [pharmacy, setPharmacy] = useState(defaultinitiaType);
const [groupedData, setGroupedData] = useState<Map<string, NewFactureLivraisonMedicamentDto[]>>(new Map());

const [groupedDataBETWEEN, setGroupedDataBETWEEN] = useState<Map<string, NewFactureLivraisonMedicamentDto[]>>(new Map());
  
 const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];

const getRequestParamss = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};
const getRequestParamsByStructure = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     pharmacyName:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    pharmacyName:pharmacyName,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }
  if (pharmacyName) {
    params["pharmacyName"] = pharmacyName;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};

const getRequestParamsByStructureBetweenDate = 
    (
      idinsurance:number,
      pharmacyName:string,
      startDate: string, 
      endDate: string) => {

  let params = {
    idinsurance:idinsurance,
    pharmacyName:pharmacyName,
    startDate:startDate,
    endDate:endDate,
  };

  if (idinsurance) {
    params["idinsurance"] = idinsurance;
  }

  if (pharmacyName) {
    params["pharmacyName"] = pharmacyName;
  }
  if (startDate) {
    params["startDate"] = startDate;
  }

  if (endDate) {
    params["endDate"] = endDate;
  }


  return params;
};

    const  handleChangePharmacy = (e: ChangeEvent<HTMLSelectElement>) => {
      setpharmacyName(e.target.value);
    };

    const getWithoutPagination = () => {  
      PharmacyTypeService.getAllTypeDoctorWithoutPagination()
        .then((response: any) => {
          setPharmacy(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

const onChangesearchReceiptBill = (e: ChangeEvent<HTMLInputElement>) => {
  const searchReceiptBill = e.target.value;
  setsearchReceiptBill(searchReceiptBill);
};

const findAllFactureDeclareBetween = () => {

  if(pharmacyName===''){

    let concatenatedString: string = StructuresData.join(", ");

    const params = getRequestParamsByStructureBetweenDate(52, concatenatedString,startDate, endDate);

    ServiceNewFactureLivraisonMedicament.getDeclarationBetweenDates(params)
    .then((response: any) => {

      setisNewFactureLivraisonMedicamentDtoBetween(response.data);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  }else{
    const params = getRequestParamsByStructureBetweenDate(52, pharmacyName,startDate, endDate);

    ServiceNewFactureLivraisonMedicament.getDeclarationBetweenDates(params)
    .then((response: any) => {

      setisNewFactureLivraisonMedicamentDtoBetween(response.data);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });

  }
  
};

const findAllFactureSasie = () => {


  let valueSwip=" "
  if(MonStructure.length>0){

    let concatenatedString: string = StructuresData.join(", ");
console.log("AA")
      valueSwip=concatenatedString
  }else{
console.log("bb")
      valueSwip=pharmacyName
  }

  if(StructuresData.length>0){

    ServiceNewFactureLivraisonMedicament.findBySocialinsuranceIdAndPatientCodeByStructure(52,searchReceiptBill,valueSwip)
    .then((response: any) => {
      const { facture, totalPages } = response.data;
      setisNewFactureLivraisonMedicamentDto(facture);
      setCurrentIndex(-1);
      console.log("response.data findAllFacture if",response.data);
      setCount(totalPages);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  }
  
};
const retrieveNewVisites = useCallback(() => {

  let valueSwip=" "
  if(MonStructure.length>0){
console.log("AA")
let concatenatedString: string = StructuresData.join(", ");
  
      valueSwip=concatenatedString
  }else{
console.log("bb")
      valueSwip=pharmacyName
  }
  
  if (StructuresData.length >0) {
    const params = getRequestParamsByStructure(52, searchReceiptBill, valueSwip, page, pageSize);

    ServiceNewFactureLivraisonMedicament.getAllNewFactureSaiseByStructureGrouped(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;
        setisNewFactureLivraisonMedicamentDto(facture);
        setCount(totalPages);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  } else {
    const params = getRequestParamss(52, searchReceiptBill, page, pageSize);
    ServiceNewFactureLivraisonMedicament.getAllNewFactureSaise(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;
        setisNewFactureLivraisonMedicamentDto(facture);
        setCount(totalPages);
    })
      .catch((e: Error) => {
        console.log(e);
      });
  }
}, [StructuresData, searchReceiptBill, page, pageSize, setisNewFactureLivraisonMedicamentDto, setCount]);

// Usage in the component
useEffect(() => {
  retrieveNewVisites();
}, [retrieveNewVisites]);

const trigerDropDownState = useCallback(() => {
  setIsHospitalDropDown((prevIsHospitalDropDown) => {
    if (StructuresData.length === 1) {
      let concatenatedString: string = StructuresData.join(", ");
      MonStructure = concatenatedString;
      return true;
    } else {
      return false;
    }
  });
}, [StructuresData]);
useEffect(() => {
  retrieveNewVisites();
  getWithoutPagination();
  trigerDropDownState();
},[page, pageSize, setisNewFactureLivraisonMedicamentDto, setCount, trigerDropDownState]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };

const totalReceiptBill = isNewFactureLivraisonMedicamentDto.length;
const totalParTotal = isNewFactureLivraisonMedicamentDto.reduce((acc: any, item: { partTotal: any; }) => acc + item.partTotal, 0);
const totalParPatient = isNewFactureLivraisonMedicamentDto.reduce((acc: any, item: { partpatient: any; }) => acc + item.partpatient, 0);
const totalpartMutuelle = isNewFactureLivraisonMedicamentDto.reduce((acc: any, item: { partMutuelle: any; }) => acc + item.partMutuelle, 0);

const totalReceiptBillBetween = isNewFactureLivraisonMedicamentDtoBetween.length;
const totalParTotalBetween = isNewFactureLivraisonMedicamentDtoBetween.reduce((acc: any, item: { partTotal: any; }) => acc + item.partTotal, 0);
const totalParPatientBetween = isNewFactureLivraisonMedicamentDtoBetween.reduce((acc: any, item: { partpatient: any; }) => acc + item.partpatient, 0);
const totalpartMutuelleBetween = isNewFactureLivraisonMedicamentDtoBetween.reduce((acc: any, item: { partMutuelle: any; }) => acc + item.partMutuelle, 0);


const exportToExcelLogic = () => {

if(startDate==="" && endDate===""){
exportToExcel()

}else{
exportToExcelBETWEEN()
};

};
const exportToExcel = () => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('@ALLY_DECLARATION');
  worksheet.properties.tabColor = { argb: 'FF0000FF' }; // Set tab color to blue
 
  // Add header row
  const headerRow = worksheet.addRow([
    'temps cree', 'Date Created','temps saisie','Date Saisie','temps Livree','Date Livree', 'Bon', 'Adherent', 'Code', 'Enterprise', 'Beneficieres',
      'Doctor', 'Prestation', 'Plafond', 'QTE', 'P.U',
      '100%', 'Par solis', 'Par Patient','hospital','pharmacy'
  ]);

  // Grouped data iteration
  groupedData.forEach((groupItems) => {
     

      // Add empty row for visual separation
    //  worksheet.addRow([]);

      // Add rows for individual items in the group
      groupItems.forEach((facture) => {
          worksheet.addRow([

            facture.timeCreated,
            facture.dateCreatedVisite,

            facture.timeSaisie,  
            facture.dateCreatedSaisie,

            facture.timeLivree,
            facture.dateLivree,
              facture.receiptBill,
              facture.nomPrenomAffilie,
              facture.codeAssuree,
              facture.enterpriseName,
              facture.patientName,
              facture.doctorName,
              facture.prestation,
              facture.plafondFacture,
              facture.quantity,
              facture.pricePrestation,
              facture.partTotal,
              facture.partMutuelle,
              facture.partpatient,
              facture.hospitalName,
              facture.pharmacyName
          ]);
      });

      // Add empty row for visual separation between groups
     // worksheet.addRow([]);
      // Calculate and add total row
      const groupSums = calculateGroupSums(groupItems);
      const totalRow =  worksheet.addRow([
          'Facture total Par Bon', '','', '', '', '', '', '', '', '', '', '', 'Total','',
          groupSums.partTotalFactureSum,
          groupSums.partMutuelleFactureSum,
          groupSums.partpatientFactureSum, 
      ]);
       // Apply style to the total row
       totalRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECFCEB' } // Green color
    };
    totalRow.eachCell((cell) => {
      cell.font = { color: { argb: 'FF008000' } }; // Green text color
  });
  });
   // Add empty row for visual separation
      worksheet.addRow([]);

   // Calculate and add total row
   const totalRow =  worksheet.addRow([
       'DECLARATION', '','', totalReceiptBill, '', '', '', '', '', '', '', '', 'Total A declare',
       '',
       totalParTotal,
       totalpartMutuelle,
       totalParPatient, 
   ]);
    // Apply style to the total row
    totalRow.fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: 'FFECFCEB' } // Green color
 };
 totalRow.eachCell((cell) => {
   cell.font = { color: { argb: 'FF008000' } }; // Green text color
});

  // Auto-fit column widths based on content
  worksheet.columns.forEach((column) => {
    if (column && column.eachCell) { // Check if column and column.eachCell are not undefined
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            let columnLength = 0;
            if (cell.value instanceof Date) {
                columnLength = cell.value
                    .toISOString()
                    .slice(0, 10)
                    .replace(/-/g, '.').length;
            } else {
                columnLength = String(cell.value).length;
            }
            if (columnLength > dataMax) {
                dataMax = columnLength;
            }
        });
        column.width = dataMax < 10 ? 15 : dataMax + 8;
    }
});

  // Save the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, '@ALLY_DECLARATION.xlsx');
  });
};

const exportToExcelBETWEEN = () => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('@ALLY_DECLARATION');
  worksheet.properties.tabColor = { argb: 'FF0000FF' }; // Set tab color to blue
 
  // Add header row
  const headerRow = worksheet.addRow([
    'temps cree', 'Date Created','temps saisie','Date Saisie','temps Livree','Date Livree', 'Bon', 'Adherent', 'Code', 'Enterprise', 'Beneficieres',
      'Doctor', 'Prestation', 'Plafond', 'QTE', 'P.U',
      '100%', 'Par solis', 'Par Patient','Hospital','pharmacy'
  ]);

  // Grouped data iteration
  groupedDataBETWEEN.forEach((groupItems) => {
     

      // Add empty row for visual separation
    //  worksheet.addRow([]);

      // Add rows for individual items in the group
      groupItems.forEach((facture) => {
          worksheet.addRow([

              facture.timeCreated,
              facture.dateCreatedVisite,

              facture.timeSaisie,  
              facture.dateCreatedSaisie,

              facture.timeLivree,
              facture.dateLivree,

              facture.receiptBill,
              facture.nomPrenomAffilie,
              facture.codeAssuree,
              facture.enterpriseName,
              facture.patientName,
              facture.doctorName,
              facture.prestation,
              facture.plafondFacture,
              facture.quantity,
              facture.pricePrestation,
              facture.partTotal,
              facture.partMutuelle,
              facture.partpatient,
              facture.hospitalName,
              facture.pharmacyName
          ]);
      });

      // Add empty row for visual separation between groups
     // worksheet.addRow([]);
      // Calculate and add total row
      const groupSums = calculateGroupSums(groupItems);
      const totalRow =  worksheet.addRow([
          'Facture total Par Bon', '','', '', '', '', '', '', '', '', '', '', 'Total','',
          groupSums.partTotalFactureSum,
          groupSums.partMutuelleFactureSum,
          groupSums.partpatientFactureSum, 
      ]);
       // Apply style to the total row
       totalRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECFCEB' } // Green color
    };
    totalRow.eachCell((cell) => {
      cell.font = { color: { argb: 'FF008000' } }; // Green text color
  });
  });
   // Add empty row for visual separation
      worksheet.addRow([]);

   // Calculate and add total row
   const totalRow =  worksheet.addRow([
       'DECLARATION', '','', totalReceiptBillBetween, '', '', '', '', '', '', '', '', 'Total A declare',
       '',
       totalParTotalBetween,
       totalpartMutuelleBetween,
       totalParPatientBetween, 
   ]);
    // Apply style to the total row
    totalRow.fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: 'FFECFCEB' } // Green color
 };
 totalRow.eachCell((cell) => {
   cell.font = { color: { argb: 'FF008000' } }; // Green text color
});

  // Auto-fit column widths based on content
  worksheet.columns.forEach((column) => {
    if (column && column.eachCell) { // Check if column and column.eachCell are not undefined
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            let columnLength = 0;
            if (cell.value instanceof Date) {
                columnLength = cell.value
                    .toISOString()
                    .slice(0, 10)
                    .replace(/-/g, '.').length;
            } else {
                columnLength = String(cell.value).length;
            }
            if (columnLength > dataMax) {
                dataMax = columnLength;
            }
        });
        column.width = dataMax < 10 ? 15 : dataMax + 8;
    }
});

  // Save the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, '@ALLY_DECLARATION.xlsx');
  });
};




const calculateGroupSums = (groupItems: NewFactureLivraisonMedicamentDto[]): { partMutuelleFactureSum: number, partpatientFactureSum: number, partTotalFactureSum: number } => {
  let partMutuelleFactureSum = 0;
  let partpatientFactureSum = 0;
  let partTotalFactureSum = 0;

  groupItems.forEach((facture) => {
    partMutuelleFactureSum += facture.partMutuelle;
    partpatientFactureSum += facture.partpatient;
    partTotalFactureSum += facture.partTotal;
  });

  return { partMutuelleFactureSum, partpatientFactureSum, partTotalFactureSum };
};

useEffect(() => {
    // Update groupedData whenever fetched data changes
    const groupData = () => {
      const grouped = new Map<string, NewFactureLivraisonMedicamentDto[]>();
      isNewFactureLivraisonMedicamentDto.forEach((facture) => {
        const key = `${facture.dateCreatedSaisie} -  ${facture.enterpriseName}  -  ${facture.receiptBill}  -  ${facture.patientName}`;
        const existingGroup = grouped.get(key) || [];
        existingGroup.push(facture);
        grouped.set(key, existingGroup);
      });
      setGroupedData(grouped);
    };

    groupData();
  }, [isNewFactureLivraisonMedicamentDto]);

  
useEffect(() => {
    // Update groupedData whenever fetched data changes
    const groupDataBETWEEN = () => {
      const grouped = new Map<string, NewFactureLivraisonMedicamentDto[]>();
      isNewFactureLivraisonMedicamentDtoBetween.forEach((facture) => {
        const key = `${facture.dateCreatedSaisie} -  ${facture.enterpriseName}  -  ${facture.receiptBill}  -  ${facture.patientName}`;
        const existingGroup = grouped.get(key) || [];
        existingGroup.push(facture);
        grouped.set(key, existingGroup);
      });
      setGroupedDataBETWEEN(grouped);
    };

    groupDataBETWEEN();
  }, [isNewFactureLivraisonMedicamentDtoBetween]);

  return(
        <>
        
        <Row className="mb-3">
        <div className="d-flex flex-column align-items-start">
      
        <Stack  direction="horizontal" gap={2}>
         <Form.Control className="me-auto"
                  placeholder="Filtre le patient ou Numero bon"
                  value={searchReceiptBill}
                   onChange={onChangesearchReceiptBill} 
                  />
                 
        DU
         <Form.Group controlId="formBasicStartDate" className="col col-sm-3">
                <InputGroup>
                    <Form.Control
                        size="sm"
                        type="date"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>
            AU
            <Form.Group controlId="formBasicEndDate" className="col col-sm-3">
                <InputGroup>
                    <Form.Control
                        size="sm"
                        type="date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>
                 <Button variant="outline-dark" size="sm" onClick={findAllFactureDeclareBetween}>
                <RiSearch2Line />
            </Button>

            <Button variant="outline-dark" size="sm" onClick={exportToExcelLogic}>
                <IoIosDownload /> 
            
            </Button>

        </Stack>
      <Stack  direction="horizontal" gap={2}>
      {"Page:"}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />         
{!isHospitalDropDown?(

<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
  
<Stack  direction="horizontal" gap={2}>

<Form.Select className="form-control" 
 onChange={handleChangePharmacy}>
   <option value="0">Pharmacy..</option>
  {pharmacy &&
pharmacy.map((pharmacy) => (
    <option key={pharmacy.id} value={pharmacy.pharmacyName} >{pharmacy.pharmacyName}</option>
))}
    
</Form.Select>

<Button variant="outline-dark" size="sm" onClick={findAllFactureSasie}>
              <RiSearch2Line />
          </Button>

</Stack>     
  </Form.Group>          

         ):(
          
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Form.Select className="form-control" 
 onChange={handleChangePharmacy}>
   <option value="0">{MonStructure}</option>
</Form.Select>
</Form.Group>
         )}
         </Stack>   
         <Stack direction="horizontal" gap={2}>
          
        </Stack>   
</div>
<Table striped bordered hover size="sm" responsive>
  <thead>
    <tr>

      <th>Temps L</th>
      <th>Date L</th>
      <th>bon</th>
      <th>Adherent</th>
      <th>Code</th>
      <th>Enterprise</th>
      <th>Beneficieres</th>
      <th>Doctor</th>
      <th>Prestation</th>
      <th>Plafond</th>
      <th>QTE</th>
      <th>P.U</th>
      <th>100%</th>
      <th>Par solis</th>
      <th>Par Patient</th>
      <th>Saisie A</th>
    </tr>
  </thead>
  <tbody>
    {Array.from(groupedData).map(([groupKey, groupItems], index) => (
      <React.Fragment key={index}>
   
        {groupItems.map((facture, factureIndex) => (
          <tr key={`${groupKey}-${factureIndex}`}>

            <td>{facture.timeLivree}</td>
            <td>{facture.dateLivree}</td>
            <td>{facture.receiptBill}</td>
            <td>{facture.nomPrenomAffilie}</td>
            <td>{facture.codeAssuree}</td>
            <td>{facture.enterpriseName}</td>
            <td>{facture.patientName}</td>
            <td>{facture.doctorName}</td>
            <td>{facture.prestation}</td>
            <td>{facture.plafondFacture}</td>
            <td>{facture.quantity}</td>
            <td>{facture.pricePrestation}</td>
            <td>{facture.partTotal}</td>
            <td>{facture.partMutuelle}</td>
            <td>{facture.partpatient}</td>
            <td>{facture.hospitalName}</td>
            <td>
            </td>
          </tr>
        ))}
         <tr>
      <td colSpan={9}>Facture total Par Bon</td>
      <td colSpan={1}></td>
      <td colSpan={1}></td>
      <td colSpan={1}></td>
      <td colSpan={1} style={{ color: 'green' }}  >Total</td>
      <td colSpan={1} style={{ color: 'green' }}>{calculateGroupSums(groupItems).partTotalFactureSum}</td>
  <td colSpan={1} style={{ color: 'green' }}>{calculateGroupSums(groupItems).partMutuelleFactureSum}</td>
  <td colSpan={1} style={{ color: 'green' }}>{calculateGroupSums(groupItems).partpatientFactureSum}</td>
  <td colSpan={1}></td>
    </tr>
      </React.Fragment>
    ))}
  </tbody>
</Table>



          </Row>

     
        </>
    );
}