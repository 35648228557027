import { ChangeEvent, useEffect, useState } from "react";
import IdataModelCategory from "../Models/IdataModelCategory";
import { Form, InputGroup, Row } from "react-bootstrap";
import CategoryTypeService from "../Services/CategoryTypeServices";


let accountTypeIncrement=0

const initialCategory = {
  id: null,
  typePrestationsName:'',
  accountType:accountTypeIncrement,
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
// 👇️ const now: Date
//const now = new Date();

//console.log(now); // 👉️ 2023-01-20T12:01:41.216Z

//console.log(now.toLocaleDateString()); // 👉️ 1/20/2023

//console.log(now.toLocaleString()); // 👉️ 1/20/2023, 2:01:41 PM

//console.log(now.toUTCString()); // 👉️ Fri, 20 Jan 2023 12:01:41 GMT

//console.log(now.toISOString()); // 👉️ 2023-01-20T12:01:41.216Z

export const CategorySoins:React.FC=()=> {

const getLastaccountTypeType =()=>{
  CategoryTypeService.getLastAccountType()
    .then((response: any) => {
        accountTypeIncrement=response.data.accountType
        console.log(accountTypeIncrement);
  })
}

useEffect(() => {
  getLastaccountTypeType();
});

const [isInput,setInput]=useState<IdataModelCategory>(initialCategory);

   
const handleChange = (e: { target: { name: any; value: any; }; }) => {
  setInput({ ...isInput, [e.target.name]: e.target.value });
}
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      const save = () => {  

        if(accountTypeIncrement===undefined){
          accountTypeIncrement=10000
        }
        let data = {
          typePrestationsName: isInput.typePrestationsName,
          accountType: accountTypeIncrement+1,
          createdBy:'ghost zila ',
          dateCreatedBy: new Date()
        };

        CategoryTypeService.create(data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            typePrestationsName: response.data.typePrestationsName,
            accountType:response.data.accountType,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialCategory)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialCategory);
      }

     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>name</Form.Label>
            <InputGroup>
         <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="typePrestationsName" name="typePrestationsName"value={isInput.typePrestationsName
} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}