import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { IClassee } from "../Interfaces/IClassee";
import ClasseeDto from "../Models/ClasseeDto";
import ServiceremboursementClass from "../Services/ServiceremboursementClass";

export const TableOperations:React.FC<IClassee>=props=>{
    

  const [data, setData] = useState<Array<ClasseeDto>>([]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [search, setSearch] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  
  const getRequestParams = (socialinsuranceId:any,searchenterpriseName:string, thepage: number, thepageSize: number) => {
    let params = {
      socialinsuranceId:socialinsuranceId,
      enterpriseName:searchenterpriseName,
      page: thepage,
      size:thepageSize
    };
  
    if (socialinsuranceId) {
      params["socialinsuranceId"] = socialinsuranceId;
    }
    if (searchenterpriseName) {
      params["enterpriseName"] = searchenterpriseName;
    }
  
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    setSearch(search);
  };
  
  const findByEnterpriseName = () => {
    ServiceremboursementClass.findByEnterpriseName(52,search)
      .then((response: any) => {
        const {RemboursementHospitalDtos} = response.data;
        setData(RemboursementHospitalDtos);

        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const retrieve= () => {
    const params = getRequestParams(52,search, page, pageSize);
  
    ServiceremboursementClass.getAll(params)
  
      .then((response: any) => {
  
        const {RemboursementHospitalDtos , totalPages} = response.data;
  
        setData(RemboursementHospitalDtos);
        setCount(totalPages);
  
        console.log(response.data);
        console.log(params);
  
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    retrieve();
  },[page, pageSize]);
  
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const refreshList = () => {
    retrieve();
    setCurrentIndex(-1);
  };
  
  const setActive = (data:ClasseeDto, index: number) => {
    setCurrentIndex(index); 
     props.onEdit(data);
     props.onHide();
    console.log(data);
    console.log(index);
  };
  
  const OnDelete = (id:any) => {
  
    ServiceremboursementClass.remove(id)
      .then((response: any) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
    return(
          <>
          <Row className="mb-3">
        
        <Stack  direction="horizontal" gap={2}>
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <InputGroup>
            <Form.Control size="sm" 
                    placeholder="Filtre ..."
                    value={search}
                     onChange={onChangeSearch} 
                    />
            </InputGroup>
        </Form.Group>
              
                  <Button variant="outline-dark btn-sm"
                   onClick={findByEnterpriseName}>Cherchez</Button>

            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
           </Stack>   
         
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>Entreprise</th>
              <th>Cotisation</th>
            </tr>
          </thead>
          <tbody>
          {data.length>0 ? (
            data.map((i, index) => (
                
                  <tr key={i.id }>
                  <td>{i.pharmacyName}</td>
                  <td>{i.money}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setActive(i, index)}>Edit</Button>
  
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDelete(i.id)}>Delete</Button>
                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no data</td>
              </tr>
            )}
  
          </tbody>
        </Table>
        </Row>
  
          </>
      );
  }