import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import IdataModelTypeDepertment from '../Models/IdataModelTypeDepertment';
import ServiceDepartment from '../Services/ServiceDepartment';

let accountIncrement=0

const initialTypeState = {
  id: null,
  typeName: '',
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};

export const AddDeptment:React.FC=()=> {


const [isInput,setInput]=useState<IdataModelTypeDepertment>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const saveType = () => {  

    
        let data = {
          typeName: isInput.typeName,
          account: accountIncrement+1,
          createdBy:'haliii ganii',
          dateCreatedBy: new Date()
        };

        ServiceDepartment.create(data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            typeName: response.data.typeName,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }

      
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Department </Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-label="Recipient's username" aria-describedby="basic-addon2" type="typeName" name="typeName" value={isInput.typeName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>saveType()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}