import { useRef } from "react";
import { TableEnterpriseCotisation } from "../../../Components/ASSURANCES/Entreprises/Tables/TableEnterpriseCotisation";


export const CotisationIndependant:React.FC=()=>{

  const componentToPrint = useRef(null);


      
    return (

      <TableEnterpriseCotisation 
      componentToPrint={componentToPrint}
       />
         
    );
}