
import ServiceDepartment from '../Services/ServiceMedicament';
import React, { useCallback } from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import IdataModelMedicament from '../Models/ListMedicament';

import { RootState } from '../../../Redux/Store/store';
import { useSelector } from 'react-redux';
import ServiceMedicament from '../Services/ServiceMedicament';
let codeIncrement=0

const initialTypeState = {
  code: 0,
       typeName: '',
       excl: '',  //ouii or no
       acc: '',  // oui or no
       createby: '',
       dateCreatedBy: '',
};

export const AddMedicament:React.FC=()=> {


const [isInput,setInput]=useState<IdataModelMedicament>(initialTypeState);

const preferredName = useSelector((state: RootState) => state.user.preferred_username);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      function getCurrentDate(): string {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        // Months are zero-based, so we add 1 to get the correct month
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      const getLastAccountType = useCallback(() => {
        ServiceMedicament.getLastAccountType()
          .then((response: any) => {
            codeIncrement = response.data.code;
            console.log("codeIncrement",codeIncrement);
          })
      }, []);
      
      useEffect(() => {
        getLastAccountType();
      }, [getLastAccountType]);

      const saveType = () => {  

    
        let currentDateFormat = getCurrentDate();
        let data = {
          typeName: isInput.typeName,
          code: codeIncrement+1,
          excl:isInput.excl,
          acc:isInput.acc,
          createby:preferredName,
          dateCreatedBy: currentDateFormat
        };

        ServiceMedicament.create(data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            typeName: response.data.typeName,
            code: response.data.code,
            excl:response.data.excl,
            acc:response.data.acc,
            createby:response.data.preferredName,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }

      
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Medicament </Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-label="Recipient's username" aria-describedby="basic-addon2" type="typeName" name="typeName" value={isInput.typeName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

         <Form.Group controlId="formBasicGender" className="col col-sm-3">
                <Form.Label>Exclue ?</Form.Label>
                <InputGroup>
                    <Form.Control 
                        size="sm" 
                        as="select" 
                        aria-label="Gender selection" 
                        name="excl" 
                        value={isInput.excl} 
                        onChange={handleChange}
                    >
                        <option value="">Select </option>
                        <option value="oui">OUI</option>
                        <option value="no">No</option>
                    </Form.Control>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="formBasicGender" className="col col-sm-3">
                <Form.Label>Couvert ?</Form.Label>
                <InputGroup>
                    <Form.Control 
                        size="sm" 
                        as="select" 
                        aria-label="Gender selection" 
                        name="acc" 
                        value={isInput.acc} 
                        onChange={handleChange}
                    >
                       <option value="">Select </option>
                        <option value="oui">OUI</option>
                        <option value="no">No</option>
                    </Form.Control>
                </InputGroup>
            </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>saveType()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}