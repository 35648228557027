import {  ChangeEvent, useEffect, useState } from "react";
import CategoryTypeService from "../Services/CategoryTypeServices";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { Button, Form, Row, Stack, Table } from "react-bootstrap";
import IdataModelCategory from "../Models/IdataModelCategory";
import { ICategory } from "../Interfaces/ICategory";


export const CategoryTable:React.FC<ICategory>=props=>{
    

    const [Category, setCategory] = useState<Array<IdataModelCategory>>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchCategory, setSearchCategory] = useState<string>("");
    
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6, 9,12];
    
    const getRequestParams = (searchtypePrestations1:string, thepage: number, thepageSize: number) => {
      let params = {
        typePrestations1:searchtypePrestations1,
        page: thepage,
        size:thepageSize
      };
    
      if (searchtypePrestations1) {
        params["typePrestations1"] = searchtypePrestations1;
      }
    
      if (thepage) {
        params["page"] = thepage - 1;
      }
    
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
    
    const onChangeSearchCategory = (e: ChangeEvent<HTMLInputElement>) => {
      const searchCategory = e.target.value;
      setSearchCategory(searchCategory);
    };
    
    const findByTypePrestationsName = () => {
      CategoryTypeService.findByTypePrestationsName(searchCategory)
        .then((response: any) => {
          const {typePrestations } = response.data;
          setCategory(typePrestations);
          setCurrentIndex(-1);
         console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const retrieveCategory = () => {
      
      const params = getRequestParams(searchCategory, page, pageSize);
    
      CategoryTypeService.getAll(params)
    
        .then((response: any) => {
    
          const {typePrestations , totalPages} = response.data;
    
          setCategory(typePrestations);
          setCount(totalPages);
    
          console.log(response.data);
          console.log(params);
    
        })
    
        .catch((e: Error) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrieveCategory();
    },[page , pageSize]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
    const refreshList = () => {
      retrieveCategory();
      setCurrentIndex(-1);
    };
    
    const setActiveCategory = (dataCategory: IdataModelCategory, index: number) => {
      setCurrentIndex(index); 
       props.onEditCategory(dataCategory);
       props.onHide();
      console.log(dataCategory);
      console.log(index);
    };
    
    const OnDeleteCategory = (id:any) => {
    
      CategoryTypeService.remove(id)
        .then((response: any) => {
          console.log(response.data);
          refreshList();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    
      return(
            <>
            <Row className="mb-3">
          
          <Stack  direction="horizontal" gap={2}>
                    <Form.Control className="me-auto"
                      placeholder="Filtre le Cat..."
                      value={searchCategory}
                       onChange={onChangeSearchCategory} 
                      />
                    <Button variant="outline-dark btn-sm"
                     onClick={findByTypePrestationsName}>Cherchez</Button>
             </Stack>   
            <div className="mt-3">
              
            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
            <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>Type</th>
                <th>Compte</th>
              </tr>
            </thead>
            <tbody>
            {Category.length>0 ? (
              Category.map((i, index) => (
                  
                    <tr key={i.id }>
                    <td>{i.id}</td>
                    <td>{i.typePrestationsName}</td>     
                    <td>{i.accountType}</td>
                    <td>
                    <Stack direction="horizontal" gap={2}>
                    <Button className="me-4 btn btn-success btn-sm btn-block"
                    onClick={() => setActiveCategory(i, index)}>Edit</Button>
    
                      <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteCategory(i.id)}>Delete</Button>
                    </Stack>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={3}>No Categories</td>
                </tr>
              )}
    
            </tbody>
          </Table>
          </Row>
    
            </>
        );
    }

