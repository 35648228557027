import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { Itype } from "../Interfaces/Itype";
import TypeService from "../Services/TypeServices";
import StructureDtos from "../Models/StructureDtos";


export const TypeTableStructure:React.FC<Itype>=props=>{
    

  const [type, setType] = useState<Array<StructureDtos>>([]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchTypestructureName, setsearchTypestructureName] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  
  const getRequestParams = (insuranceId:any,structureName:string, thepage: number, thepageSize: number) => {
    let params = {
      insuranceId:insuranceId,
      structureName:structureName,

      page: thepage,
      size:thepageSize
    };
  
    
    if (insuranceId) {
      params["insuranceId"] = insuranceId;
    }
    if (structureName) {
      params["structureName"] = structureName;
    }
    
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangesearchTypestructureName =(e: { target: {value: any; }; }) => {
    const searchTypestructureName = e.target.value;
    setsearchTypestructureName(searchTypestructureName);
  };
  
  const findByTypestructureName = () => {
    TypeService.findByTypeStructureName(52,searchTypestructureName)
      .then((response: any) => {
        const {types } = response.data;
        setType(types);
        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const retrieveType = () => {
    const params = getRequestParams(52,searchTypestructureName, page, pageSize);
  
    TypeService.getAll(params)
  
      .then((response: any) => {
  
        const {types , totalPages} = response.data;
  
        setType(types);
        setCount(totalPages);
  
        console.log(response.data);
        console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    retrieveType();
  },[page, pageSize]);
  
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const refreshList = () => {
    retrieveType();
    setCurrentIndex(-1);
  };
  
  const setActiveType = (datatype: StructureDtos, index: number) => {
    setCurrentIndex(index); 
     props.onEditType(datatype);
     props.onHide();
    console.log(datatype);
    console.log(index);
  };
  
  const OnDeleteType = (id:any) => {
  
    TypeService.remove(id)
      .then((response: any) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
    return(
          <>
          <Row className="mb-3">
        
        <Stack  direction="horizontal" gap={2}>
                  <Form.Control className="me-auto"
                    placeholder="Filtre Status strucutre..."
                    value={searchTypestructureName}
                     onChange={onChangesearchTypestructureName} 
                    />
                  <Button variant="outline-dark btn-sm"
                   onClick={findByTypestructureName}>Cherchez</Button>
           </Stack>   
          <div className="mt-3">
            
          {"Items per Page: "}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </div>
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>id</th>
              <th>Structure</th>
              <th>Assurance</th>
            </tr>
          </thead>
          <tbody>
          {type.length>0 ? (
            type.map((i, index) => (
                
                  <tr key={i.structureId }>
                  <td>{i.structureId}</td>
                  <td>{i.structureName}</td>
                  <td>{i.insuranceName}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setActiveType(i, index)}>Edit</Button>
  
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteType(i.structureId)}>Delete</Button>
                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no Type</td>
              </tr>
            )}
  
          </tbody>
        </Table>
        </Row>
  
          </>
      );
  }