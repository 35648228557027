import { Container, Row, Col, Card } from "react-bootstrap";
import { TableListeVisiteGroupDelcaration } from '../../../Components/Espace_Patients/FactureSaisie/Tables/TableListeVisite/TableListeVisiteGroupDelcaration';

export const FacturationListesDeclaration=()=>{

    return (

        <Container fluid>
     
        <Row>
         <Col>
        <br />
          <Card border="secondary" >
            <Card.Body>
              <TableListeVisiteGroupDelcaration/>
              </Card.Body>
          </Card>
          <br />
         </Col>
        </Row>
        
        </Container>
    
    );
}
