import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import IdataModelType from '../Models/IdataModelTypeStatus';
import ServiceTypeBonsVerification from '../Services/ServiceTypeBonsVerification';

let accountIncrement=0

const initialTypeState = {
  id: null,
  typeName: '',
  account:accountIncrement,
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};

export const AddTypeStatus:React.FC=()=> {

const getLastAccountType =()=>{
  ServiceTypeBonsVerification.getLastAccountType()
    .then((response: any) => {
        accountIncrement=response.data.account
        console.log(accountIncrement);
  })
}

useEffect(() => {
  getLastAccountType();
 
});


const [isInput,setInput]=useState<IdataModelType>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const saveType = () => {  

        if(accountIncrement===undefined){
          accountIncrement=200
        }
        let data = {
          typeName: isInput.typeName,
          account: accountIncrement+1,
          createdBy:'haliii ganii',
          dateCreatedBy: new Date()
        };

        ServiceTypeBonsVerification.create(52,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            typeName: response.data.typeName,
            account:response.data.account,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }

      
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Decaration Bon Status </Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="typeName" name="typeName" value={isInput.typeName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>saveType()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}