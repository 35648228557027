import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'; // Importing the question mark icon

interface MyModalProps {
  show: boolean;
  onHide: () => void;
}

export const AcceptValidation = (props: MyModalProps) => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {/* Implementing the big question mark icon */}
        <div style={{ textAlign: 'center', color: 'green' }}>
            <FontAwesomeIcon icon={faCheckCircle} size="5x" /> {/* Display tick mark icon */}
            <div>Data well saved</div>
          </div>
      </Modal.Body> <Modal.Footer style={{ justifyContent: 'center' }}>
       
        <Button variant="secondary" onClick={props.onHide}>
          finish
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
