/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from "react";
import NewFactureSaisieDto from "../models/Dto/NewFactureSaisieDto";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import ServicesNewFactureSaisie from "../services/ServiceNewFactureSaisie";
import {Container, Divider, Stack } from "@mui/material";

import Button from 'react-bootstrap/Button';
import { Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import RestPlafondYearService from "../../../ASSURANCES/CounterRestPlafond/Services/RestPlafondYearService";
import PlafondYearTypeVisiteService from "../../../ASSURANCES/PlafondYearTypeVisite/Services/PlafondYearTypeVisiteService";
import ServiceSmember from "../../../ASSURANCES/Beneficieres/Services/ServiceSmember";
import ServicesFactureTotalInOne from "../../FactureTotalinOne/services/ServiceFactureTotalInOne";
import FactureTotalInOne from "../../FactureTotalinOne/model/FactureTotalInOne";
import IdataModelCounterRestPlafond from "../../../ASSURANCES/CounterRestPlafond/Models/CounterRestPlafond";
import IdataModelService from "../../../ServicesData/Models/IdataModelService";
import IdataModelTypeDepertment from "../../../HopitalDepertment/Models/IdataModelTypeDepertment";
import ServiceHopital from "../../../ServicesData/Services/ServiceHopital";
import ServiceDepartment from "../../../HopitalDepertment/Services/ServiceDepartment";
import CouvertureconfigService from "../../../ASSURANCES/Configurations/Couvertures/Services/CouvertureconfigService";
import { updateFactureMutuelle } from "../../../../Redux/slices/FactureMutuelleDetails/FactureMutuelleDetails";
import { updatecurrentBill } from "../../../../Redux/slices/currentBill/currentBill";
import FactureTotalInOneDto from "../../FactureTotalinOne/model/Dto/FactureTotalInOneDto";
import { updateplafondFacturePrService } from "../../../../Redux/slices/plafondFacture/plafondFacturePrService";
import { RiSearch2Line } from "react-icons/ri";


  
const columns: GridColDef[] = [

    { field: 'plafondFacture', headerName: 'plafond' },
    { field: 'statusFacturePayer', headerName: 'Status' },
    { field: 'prestation', headerName: 'Prestation' },
    { field: 'pricePrestation', headerName: 'Price ' },

    { field: 'quantity', headerName: 'Quts' },

  //  { field: 'ticketService', headerName: 'D Ticket' },
    { field: 'ticketServiceFacture', headerName: 'Ticket' },
    { field: 'partMutuelle', headerName: 'Part Mutuelle' },
    { field: 'partpatient', headerName: 'Part Patient' },
    { field: 'partTotal', headerName: 'Total'  },

   // { field: 'partMutuelleFacture', headerName: 'F Part Mutuelle' },
   // { field: 'partpatientFacture', headerName: 'F Part Patient' },
   // { field: 'partTotalFacture', headerName: 'F Total'  },

    { field: 'typeNameVisite', headerName: 'Type Viste' },
   // { field: 'typeBonVerificationName', headerName: 'Verfier' },
    { field: 'typeBonCommendName', headerName: 'Type Bon' },
    { field: 'depertmentName', headerName: 'Depertement' },
    { field: 'dateCreatedSaisie', headerName: 'Created' },
    { field: 'timeSaisie', headerName: 'Time Saisie' },
    
  ];
  const idd: GridRowId[]=[];

let selectedRow:Array<NewFactureSaisieDto> = [];

let defaultListCheckBill: FactureTotalInOneDto;

const InitFactureTotalInOne ={
    
  totalFactureInOne:'',
  totalFactureInOneCouvert:'',
  totalFactureInOneNoCouvert:'',
  totalInOnePartPatient:'',
  totalInOnePartSolis:'',
  totalInOnePartPatientFacture:'',
  totalInOnePartSolisFacture:'',
  inOnecreatedBy:'',
  inOnedateCreated:'',
  inOnetimeCreated:'',
  mois:''
}



const InitRestPlafond={
  codeId:'',
  numeroBon:0,
  partPatientConsomme:0,
  idRestIncrementPlafondConsomme:0,
  status:'payer',
  created_by:'Ramadhani Ally',
  date_created_by: new Date(),
}

const defaultservice: Array<IdataModelService> = [];
const defaultdepertment:Array<IdataModelTypeDepertment>=[];


export const TableNoPayerFactureMituellePrService:React.FC=()=>

{
  const preferredName = useSelector((state: RootState) => state.user.preferred_username);

  const [service, setservice] = useState(defaultservice);
    
  const [depertment, setdepertment] = useState(defaultdepertment);

  const [idService, seIdService] = useState(0);

  const [isConfigPlafondFacture, setConfigPlafondFacture] = useState<number>(0);

    
  const [departmentId, setdepertmentId] = useState(0);

  const [isInputRestPlafond,setisInputRestPlafond]=useState<IdataModelCounterRestPlafond>(InitRestPlafond);
  
  const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);
  const dispatchOnUpdateListToPrintMituelle=useDispatch();

  const [isfactureCouvert, setisfactureCouvert] = useState(0);
    const [isfactureNoCouvert, setisfactureNoCouvert] = useState(0);
    const [isfactureTotal, setisfactureTotal] = useState(0);

    const [isPartMituelle, setisPartMituelle] = useState(0);
    const [isPartPatient, setisPartPatient] = useState(0);

    const [isPartMituelleFacture, setisPartMituelleFacture] = useState(0);
    const [isPartPatientFacture, setisPartPatientFacture] = useState(0);

    const [isfactureApeyer, setisfactureApayer] = useState(0);
    
    const [typeCountAyant, settypeCountAyant] = useState(0);

    const [isInputFactureTotalInOne,setisInputFactureTotalInOne]=useState<FactureTotalInOne>(InitFactureTotalInOne);

 const [isInputFactureSaisie, setisInputFactureSaisie] = useState<{ statusFacturePayer: string }>({ statusFacturePayer: '' });
 const [isStatus, setisStatus] = useState<{ status: string }>({ status: '' });


 const [isNewFactureSaisieDto, setisNewFactureSaisieDto] = useState<Array<NewFactureSaisieDto>>([]);

 const [parPartientDejaConsomme, setparPartientDejaConsomme] = useState(0);
 
 const [plafondYear, setPlafondYear] = useState(0);

 const [ischeckBillRecent, setCheckBillRecent] = useState(false);
 const [isPayer, setisPayer] = useState(true);

//const [selectedRows, setRowsSelected] = useState(defaultList);

const [selectedIdInRow, setIdRowSelected] = useState(idd);


const [totalAllServices, setTotalAllServices] = useState(0);
const [factureCouvertAllServices, setFactureCouvertAllServices] = useState(0);
const [factureNocouvertAllServices, setFactureNocouvertAllServices] = useState(0);
const [partMutuelleAllServices, setPartMutuelleAllServices] = useState(0);
const [partPatientAllServices, setPartPatientAllServices] = useState(0);
const [partMutuelleFactureAllServices, setPartMutuelleFactureAllServices] = useState(0);

const [partPatientFactureAllServices, setPartPatientFactureAllServices] = useState(0);

const [factureTotalInOneIdRecent, setFactureTotalInOneIdRecent] = useState(0);
const [totalAllServicesRecent, setTotalAllServicesRecent] = useState(0);
const [factureCouvertAllServicesRecent, setFactureCouvertAllServicesRecent] = useState<number>(0);
const [factureNocouvertAllServicesRecent, setFactureNocouvertAllServicesRecent] = useState(0);
const [partMutuelleAllServicesRecent, setPartMutuelleAllServicesRecent] = useState(0);
const [partPatientAllServicesRecent, setPartPatientAllServicesRecent] = useState(0);
const [partMutuelleFactureAllServicesRecent, setPartMutuelleFactureAllServicesRecent] = useState(0);

const [partPatientFactureAllServicesRecent, setPartPatientFactureAllServicesRecent] = useState(0);

const [totalAllServicesUpdate, setTotalAllServicesUpdate] = useState(0);
const [factureCouvertAllServicesUpdate, setFactureCouvertAllServicesUpdate] = useState(0);
const [factureNocouvertAllServicesUpdate, setFactureNocouvertAllServicesUpdate] = useState(0);
const [partMutuelleAllServicesUpdate, setPartMutuelleAllServicesUpdate] = useState(0);
const [partPatientAllServicesUpdate, setPartPatientAllServicesUpdate] = useState(0);
const [partMutuelleFactureAllServicesUpdate, setPartMutuelleFactureAllServicesUpdate] = useState(0);

const [partPatientFactureAllServicesUpdate, setPartPatientFactureAllServicesUpdate] = useState(0);


const DispatchPushCarrentBill=useDispatch();

const DispatchPushConfigFacturePlafondParService=useDispatch();
function getCurrentDate(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  // Months are zero-based, so we add 1 to get the correct month
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
function getCurrentTime(): string {
  const currentDate = new Date();
  const hours = renderNumber(currentDate.getHours(), 2);
  const minutes = renderNumber(currentDate.getMinutes(), 2);
  return `${hours}h:${minutes}min`;
}

function renderNumber(value: number, length: number): string {
  let result: string = String(value);
  while (result.length < length) {
    result = '0' + result;
  }
  return result;
}

  
const UpdateFactureSaisie = (id: any) => {
  let data = {
    statusFacturePayer: 'payer',
  };

  ServicesNewFactureSaisie.updateFactureSaisieStatusPayer(id, data.statusFacturePayer)
    .then((response: any) => {
      setisInputFactureSaisie({
        statusFacturePayer: response.data.statusFacturePayer,
      });

     dispatchOnUpdateListToPrintMituelle(updateFactureMutuelle(selectedRow));
     DispatchPushCarrentBill(updatecurrentBill(defaultListCheckBill)); // sending data to facture mutuelle
  
      findAllFactureByInsuarenceAndReceipt();
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const UpdateFactureSaisieById = () => {  

  for (let id of selectedIdInRow) {
    UpdateFactureSaisie(id)
  }
};

const checkExistingPayedBill = () => {  

  const formattedDate = getCurrentDate();
  // Define your parameters
  const params = {
    idinsurance: isgetselecterCurrantNewVisitePushed.idinsurance,
    idDepertment: departmentId, 
    idService: idService,
    inOnedateCreated:formattedDate,
    receiptBill: isgetselecterCurrantNewVisitePushed.receiptBill
  };
  ServicesFactureTotalInOne.checkBillNumberDateIdServiceIdDepartment(params)
  .then((response: any) => {
    // Check if the response data is not empty
    if (response.data!== '' ) {
      setCheckBillRecent(true)
        // Update state with the response data
      console.log('checkBillNumberDateIdServiceIdDepartment if ', response);
      // Add further processing if needed, such as updating state or rendering data

     
      defaultListCheckBill=response.data
      setFactureTotalInOneIdRecent(response.data.idFactureInOne);
setTotalAllServicesRecent(response.data.totalFactureInOne);
setFactureCouvertAllServicesRecent(response.data.totalFactureInOneCouvert);
setFactureNocouvertAllServicesRecent(response.data.totalFactureInOneNoCouvert);
setPartMutuelleAllServicesRecent(response.data.totalInOnePartSolis);
setPartPatientAllServicesRecent(response.data.totalInOnePartPatient);
setPartMutuelleFactureAllServicesRecent(response.data.totalInOnePartSolisFacture);
setPartPatientFactureAllServicesRecent(response.data.totalInOnePartPatientFacture);
    } else {
      // If the response data is empty, handle it accordingly (e.g., call a function)

  setCheckBillRecent(false)
      console.log('checkBillNumberDateIdServiceIdDepartment else', response);

      console.log('No content found.');
      // Add further handling as per your requirement

setTotalAllServicesRecent(0);
setFactureCouvertAllServicesRecent(0);
setFactureNocouvertAllServicesRecent(0);
setPartMutuelleAllServicesRecent(0);
setPartPatientAllServicesRecent(0);
setPartMutuelleFactureAllServicesRecent(0);
setPartPatientFactureAllServicesRecent(0);
    }
  })
  .catch(error => {
    // Handle errors
    console.error('Error fetching data:', error);
    // Add further error handling if needed
  });

};

const ActualizePerServices = () => {  
    findAllFactureByInsuarenceAndReceipt()
  };
   
const CalculateFacturePlafond =() => {
  if (plafondYear > parPartientDejaConsomme && (isfactureApeyer+parPartientDejaConsomme) < plafondYear) {
    setisPayer(false)
  } else { 
    setisPayer(true)
  }
};

useEffect(() => {
  CalculateFacturePlafond();
},[CalculateFacturePlafond]);


const getCountNomberOfAyantDroit = useCallback(() => {
  if (
    isgetselecterCurrantNewVisitePushed.idinsurance !== 0 &&
    isgetselecterCurrantNewVisitePushed.idEnterprise !== 0 &&
    isgetselecterCurrantNewVisitePushed.catégorieSoins !== null
  ) {

    ServiceSmember.AccountEnfants(

      isgetselecterCurrantNewVisitePushed.codeAssuree && isgetselecterCurrantNewVisitePushed.codeAssuree.slice(0, -1),
      isgetselecterCurrantNewVisitePushed.idinsurance
    )
      .then((response: any) => {
        settypeCountAyant(response.data);

        console.log('settypeCountAyant', response.data)
      })
      .catch((e: Error) => {
        console.log(e);
      });
  } else {
    console.log('haliii ganiii');
  }
}, [isgetselecterCurrantNewVisitePushed.idinsurance, isgetselecterCurrantNewVisitePushed.idEnterprise, isgetselecterCurrantNewVisitePushed.catégorieSoins, isgetselecterCurrantNewVisitePushed.codeAssuree]);

useEffect(() => {
getCountNomberOfAyantDroit();
}, [getCountNomberOfAyantDroit]);
  
const findByMultipleIdsPlafondYear = useCallback(() => {
  try {
    if (isgetselecterCurrantNewVisitePushed.codeAssuree === "") {
      /* Don't do anything */
    } else {
      const encodedCategoryName = encodeURIComponent(isgetselecterCurrantNewVisitePushed.catégorieSoins);

      console.log('isgetselecterCurrantNewVisitePushed.idtypeBonverification:', isgetselecterCurrantNewVisitePushed);

      PlafondYearTypeVisiteService.findAllByInsuarenceEnterpCategEnfEtatTwo(
        isgetselecterCurrantNewVisitePushed.idinsurance,
        isgetselecterCurrantNewVisitePushed.idEnterprise,
        encodedCategoryName,
        typeCountAyant,
        isgetselecterCurrantNewVisitePushed.etatCivil,
        isgetselecterCurrantNewVisitePushed.idVisite
      )
      .then((response: any) => {
        console.log('API Response:', response);
// Accessing the first item in the array and then accessing plafondYear property
const firstItem = response.data[0];
console.log('Plafond Year:', firstItem.plafondYear);
setPlafondYear(firstItem.plafondYear);
      })
      .catch((error) => {  // Fix: Added parentheses around 'error' parameter
        console.log('Error fetching plafondYear data:', error);
      });
    }
  } catch (error) {
    console.log('Error in findByMultipleIdsPlafondYear:', error);
  }
}, [
  isgetselecterCurrantNewVisitePushed.idinsurance,
  isgetselecterCurrantNewVisitePushed.idEnterprise,
  isgetselecterCurrantNewVisitePushed.catégorieSoins,
  isgetselecterCurrantNewVisitePushed.etatCivil,
  typeCountAyant
]);

useEffect(() => {
  findByMultipleIdsPlafondYear();
},[findByMultipleIdsPlafondYear]);


const getRestPlafondYear = useCallback(() => {
  try {
    if (isgetselecterCurrantNewVisitePushed.codeAssuree === "") {
      /* Don't do anything */
    } else {
      
    
      RestPlafondYearService.getSum(isgetselecterCurrantNewVisitePushed.codeAssuree,"payer",isgetselecterCurrantNewVisitePushed.idVisite)
        .then((response: any) => {
          console.log("getRestPlafondYear",response.data);
          if(response.data===undefined){
            setparPartientDejaConsomme(0);
          }else{
            setparPartientDejaConsomme(response.data);
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  } catch (error) {
    console.log(error);
  }
}, [isgetselecterCurrantNewVisitePushed.codeAssuree]);

useEffect(() => {
  getRestPlafondYear();
},[getRestPlafondYear]);





const AnalyseFactureToPrint = (selectedRowToPrint:any) => {  

  if(selectedRowToPrint.length===0)
{
setisfactureCouvert(0)
setisfactureNoCouvert(0)
setisPartPatient(0)
setisPartMituelle(0)
setisfactureTotal(0)

setisPartMituelleFacture(0)
setisPartPatientFacture(0)

setisfactureApayer(0)
setTotalAllServicesUpdate(totalAllServicesRecent);
setFactureCouvertAllServicesUpdate(factureCouvertAllServicesRecent);
setFactureNocouvertAllServicesUpdate(factureNocouvertAllServicesRecent);

setPartMutuelleAllServicesUpdate(partMutuelleAllServicesRecent);
setPartPatientAllServicesUpdate(partPatientAllServicesRecent);

setPartMutuelleFactureAllServicesUpdate(partMutuelleFactureAllServicesRecent);
setPartPatientFactureAllServicesUpdate(partPatientFactureAllServicesRecent);

}
else
{

// Create a Map to store the grouped results
const groupedByService = new Map<string, {
total: number,
quantity: number,
FactureCouvert: number,
FactureNocouvert: number,
PartMutuelle: number,
PartPatient: number ,
PartMutuelleFacture: number,
PartPatientFacture: number // New key for PartPatient 

}>();
let partMutuelleEqual = 0;
let partPatientEqual = 0;

let partMutuelleFactureEqual = 0;
let partPatientFactureEqual = 0;

    let partMutuelleGreater = 0;
    let partPatientGreater = 0;

    let partMutuelleFactureGreater = 0;
    let partPatientFactureGreater = 0;
// Iterate through the array and update the Map
selectedRowToPrint.forEach((
  entry: { service: any; quantity: any; partTotal: any; plafondFacture: any; ticketService: any; ticketServiceFacture: any; }) => {
const { service, quantity, partTotal, plafondFacture, ticketService,ticketServiceFacture } = entry;

if (groupedByService.has(service)) {
  // If the service is already in the Map, update the total and quantity
  const existingEntry = groupedByService.get(service)!;
  existingEntry.total += partTotal;
  existingEntry.quantity += quantity;

  // Check if the total is less than or equal to plafondFacture
  if (existingEntry.total <= plafondFacture) {
      existingEntry.FactureCouvert = existingEntry.total;
      existingEntry.FactureNocouvert = 0;
  } else {
      // If total is greater than plafondFacture, set FactureCouvert to plafondFacture and calculate FactureNocouvert
      existingEntry.FactureCouvert = plafondFacture;
      existingEntry.FactureNocouvert = existingEntry.total - plafondFacture;
  }

  // Calculate PartMutuelle based on FactureCouvert and ticket
  existingEntry.PartMutuelle = (existingEntry.FactureCouvert * (100 - ticketService)) / 100;
  existingEntry.PartMutuelleFacture = (existingEntry.FactureCouvert * (100 - ticketServiceFacture)) / 100;
  
  // Calculate PartPatient based on FactureCouvert and ticket
  existingEntry.PartPatient = (existingEntry.FactureCouvert * ticketService) / 100;
  existingEntry.PartPatientFacture = (existingEntry.FactureCouvert * ticketServiceFacture) / 100;

  console.log("Couverture Facture Decalre",ticketService)
  console.log("COuverture Facture",ticketServiceFacture)
} else {

  // If the service is not in the Map, add a new entry
  const factureCouvert = partTotal <= plafondFacture ? partTotal : plafondFacture;
  const factureNocouvert = partTotal <= plafondFacture ? 0 : partTotal - plafondFacture;

  // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
  const partMutuelle = (factureCouvert * (100 - ticketService)) / 100;
  const partPatient = (factureCouvert * ticketService) / 100;

  const partMutuelleFacture = (factureCouvert * (100 - ticketServiceFacture)) / 100;
  const partPatientFacture = (factureCouvert * ticketServiceFacture) / 100;
    // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
    partMutuelleEqual = (isConfigPlafondFacture * (100 - ticketService)) / 100;
   partPatientEqual = (isConfigPlafondFacture * ticketService) / 100;

   partMutuelleFactureEqual = (isConfigPlafondFacture * (100 - ticketServiceFacture)) / 100;
   partPatientFactureEqual = (isConfigPlafondFacture * ticketServiceFacture) / 100;

   partMutuelleGreater =  (isConfigPlafondFacture * (100 - ticketService)) / 100;
   partPatientGreater = (isConfigPlafondFacture * ticketService) / 100;

   partMutuelleFactureGreater =(factureCouvert * (100 - ticketServiceFacture)) / 100;
   partPatientFactureGreater =  (factureCouvert * ticketServiceFacture) / 100;

  console.log("Couverture Facture Decalre",ticketService)
  console.log("Couverture Facture",ticketServiceFacture)

  groupedByService.set(service, {
      total: partTotal,
      quantity: quantity,
      FactureCouvert: factureCouvert,
      FactureNocouvert: factureNocouvert,
      PartMutuelle: partMutuelle,
      PartPatient: partPatient,
      PartMutuelleFacture: partMutuelleFacture,
      PartPatientFacture: partPatientFacture
  });
}
});

// Calculate the total, FactureCouvert, FactureNocouvert, PartMutuelle, and PartPatient for all services

let totalAllServices = 0;
let factureCouvertAllServices = 0;
let factureNocouvertAllServices = 0;
let partMutuelleAllServices = 0;
let partPatientAllServices = 0;

let partMutuelleFactureAllServices = 0;
let partPatientFactureAllServices = 0;


groupedByService.forEach(({ total, FactureCouvert, FactureNocouvert, PartMutuelle, PartPatient,PartMutuelleFacture, PartPatientFacture }) => {
totalAllServices += total;
factureCouvertAllServices += FactureCouvert;
factureNocouvertAllServices += FactureNocouvert;
partMutuelleAllServices += PartMutuelle;
partPatientAllServices += PartPatient;

partMutuelleFactureAllServices += PartMutuelleFacture;
partPatientFactureAllServices += PartPatientFacture;
});

console.log("Total All Services:", totalAllServices);
console.log("FactureCouvert All Services:", factureCouvertAllServices);
console.log("FactureNocouvert All Services:", factureNocouvertAllServices);

console.log("PartMutuelle All Services:", partMutuelleAllServices);
console.log("PartPatient All Services:", partPatientAllServices);


console.log("PartMutuelleFacture All Services:", partMutuelleFactureAllServices);
console.log("PartPatientFacture All Services:", partPatientFactureAllServices);

const totalCombined = totalAllServicesRecent + totalAllServices;
const plafondFacture = Number(isConfigPlafondFacture);

let caseValue;

// Debugging output
console.log("totalCombined:", totalCombined);
console.log("plafondFacture:", plafondFacture);

if (totalCombined < plafondFacture) {
    caseValue = 'less';
} else if (totalCombined > plafondFacture) {
    caseValue = 'greater';
} else if (totalCombined === plafondFacture) {
    caseValue = 'equal';
} else {
    caseValue = 'default';
}

switch (caseValue) {
    case 'less':
        console.log("totalAllServicesRecent + totalAllServices < isConfigPlafondFacture");

        setisfactureTotal(totalAllServices);
        setisfactureCouvert(factureCouvertAllServices);
        setisfactureNoCouvert(factureNocouvertAllServices);

        setisPartMituelle(partMutuelleAllServices);
        setisPartPatient(partPatientAllServices);

        setisPartMituelleFacture(partMutuelleFactureAllServices);
        setisPartPatientFacture(partPatientFactureAllServices);
        setisfactureApayer(partPatientFactureAllServices + factureNocouvertAllServices);

        // Update fields
        setTotalAllServicesUpdate(totalAllServices + totalAllServicesRecent);
        setFactureCouvertAllServicesUpdate(factureCouvertAllServices + factureCouvertAllServicesRecent);
        setFactureNocouvertAllServicesUpdate(factureNocouvertAllServices + factureNocouvertAllServicesRecent);
        setPartMutuelleAllServicesUpdate(partMutuelleAllServices + partMutuelleAllServicesRecent);
        setPartPatientAllServicesUpdate(partPatientAllServices + partPatientAllServicesRecent);
        setPartMutuelleFactureAllServicesUpdate(partMutuelleFactureAllServices + partMutuelleFactureAllServicesRecent);
        setPartPatientFactureAllServicesUpdate(partPatientFactureAllServices + partPatientFactureAllServicesRecent);
        break;

    case 'greater':
        console.log("totalAllServicesRecent + totalAllServices > isConfigPlafondFacture");

        if (totalAllServicesRecent===0) {

        setisfactureTotal(totalAllServices);
        setisfactureCouvert(isConfigPlafondFacture);
        setisfactureNoCouvert(totalAllServices-isConfigPlafondFacture);
          // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
       // const partMutuelleGreater = 0;
       // const partPatientGreater = totalAllServices;

      //  const partMutuelleFactureGreater = 0;
      //  const partPatientFactureGreater = totalAllServices;

        setisPartMituelle(partMutuelleGreater);
        setisPartPatient(partPatientGreater);

        setisPartMituelleFacture(partMutuelleFactureGreater);
        setisPartPatientFacture(partPatientFactureGreater);
        setisfactureApayer(partPatientFactureGreater + Number(totalAllServices-isConfigPlafondFacture));

        // Update fields
        setTotalAllServicesUpdate(totalAllServices + totalAllServicesRecent);
        const couvert = Number(isConfigPlafondFacture) + Number(factureCouvertAllServicesRecent);
        const Nocouvert = Number(totalAllServices-isConfigPlafondFacture) + Number(factureNocouvertAllServicesRecent);

        setFactureCouvertAllServicesUpdate(couvert);
        setFactureNocouvertAllServicesUpdate(Nocouvert);

        setPartMutuelleAllServicesUpdate(partMutuelleGreater + partMutuelleAllServicesRecent);
        setPartPatientAllServicesUpdate(partPatientGreater + partPatientAllServicesRecent);
        setPartMutuelleFactureAllServicesUpdate(partMutuelleFactureGreater + partMutuelleFactureAllServicesRecent);
        setPartPatientFactureAllServicesUpdate(partPatientFactureGreater + partPatientFactureAllServicesRecent);
      } else{ 
        
        setisfactureTotal(totalAllServices);
        setisfactureCouvert(0);
        setisfactureNoCouvert(totalAllServices);
        // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
         const partMutuelleGreater2 = 0;
         const partPatientGreater2 = totalAllServices;
 
         const partMutuelleFactureGreater2 = 0;
         const partPatientFactureGreater2 = totalAllServices;
 
         setisPartMituelle(partMutuelleGreater2);
         setisPartPatient(partPatientGreater2);
 
         setisPartMituelleFacture(partMutuelleFactureGreater2);
         setisPartPatientFacture(partPatientFactureGreater2);
         setisfactureApayer(partPatientFactureGreater2);
 
         // Update fields
         setTotalAllServicesUpdate(totalAllServices + totalAllServicesRecent);
         const couvert = factureCouvertAllServicesRecent;
         const Nocouvert = Number(totalAllServices) + Number(factureNocouvertAllServicesRecent);
 
         setFactureCouvertAllServicesUpdate(couvert);
         setFactureNocouvertAllServicesUpdate(Nocouvert);
 
         setPartMutuelleAllServicesUpdate(partMutuelleGreater2 + partMutuelleAllServicesRecent);
         setPartPatientAllServicesUpdate(partPatientGreater2 + partPatientAllServicesRecent);
         setPartMutuelleFactureAllServicesUpdate(partMutuelleFactureGreater2 + partMutuelleFactureAllServicesRecent);
         setPartPatientFactureAllServicesUpdate(partPatientFactureGreater2 + partPatientFactureAllServicesRecent);
      } 
       
        break;

    case 'equal':
        console.log("totalAllServicesRecent + totalAllServices === isConfigPlafondFacture");

        setisfactureTotal(totalAllServices);
        setisfactureCouvert(isConfigPlafondFacture);
        setisfactureNoCouvert(0);
        
        setisPartMituelle(partMutuelleEqual);
        setisPartPatient(partPatientEqual);

        setisPartMituelleFacture(partMutuelleFactureEqual);
        setisPartPatientFacture(partPatientFactureEqual);
        setisfactureApayer(partPatientFactureEqual);

        // Update fields
        setTotalAllServicesUpdate(totalAllServices + totalAllServicesRecent);
        setFactureCouvertAllServicesUpdate(Number(isConfigPlafondFacture) + factureCouvertAllServicesRecent);
        setFactureNocouvertAllServicesUpdate(factureNocouvertAllServicesRecent);
        setPartMutuelleAllServicesUpdate(partMutuelleEqual + partMutuelleAllServicesRecent);
        setPartPatientAllServicesUpdate(partPatientEqual + partPatientAllServicesRecent);
        setPartMutuelleFactureAllServicesUpdate(partMutuelleFactureEqual + partMutuelleFactureAllServicesRecent);
        setPartPatientFactureAllServicesUpdate(partPatientFactureEqual + partPatientFactureAllServicesRecent);
        break;

    default:
        console.log("Default value of if", totalCombined);
        break;
}

  CalculateFacturePlafond();


}
  };

  const findAllFactureByInsuarenceAndReceipt = async () => {
    try {
      ServicesNewFactureSaisie.findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelleParService(
        isgetselecterCurrantNewVisitePushed.idinsurance,
        isgetselecterCurrantNewVisitePushed.receiptBill,
        idService,
        departmentId,
      ).then((response:any)=>{
        const facture = response.data;
        console.log('find all values',response.data)
      setisNewFactureSaisieDto(facture);
      checkExistingPayedBill();
      ConfigServiceInterprise();
      })
      
    } catch (e) {
      console.log(e);
    }
  };


  const saveFactureTotoalInOne = () => { 

    let currentDateFormat = getCurrentDate();
    console.log('saveFactureTotoalInOne', currentDateFormat); // Output: '2024-05-12'

    let currentTime = getCurrentTime();
    console.log('saveFactureTotoalInOne', currentTime); // Output: '12h:05min'
 // Get current month in French
 const now = new Date();

 // Get current month in French
 const monthsInFrench = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
 const moisPayer = monthsInFrench[now.getMonth()];

    let data = {
        totalFactureInOne: isfactureTotal,
        totalFactureInOneCouvert: isfactureCouvert,
        totalFactureInOneNoCouvert: isfactureNoCouvert,
        totalInOnePartPatient: isPartPatient,
        totalInOnePartSolis: isPartMituelle,
        totalInOnePartPatientFacture: isPartPatientFacture,
        totalInOnePartSolisFacture: isPartMituelleFacture,
        inOnecreatedBy: preferredName,  // Corrected
        inOnedateCreated: currentDateFormat,  // Corrected
        inOnetimeCreated: currentTime,  // Corrected
        mois:moisPayer
    };

    ServicesFactureTotalInOne.createFactureTotal(
        isgetselecterCurrantNewVisitePushed.idFacture,
        departmentId,
        idService,
        data)
        .then((response: any) => {
            setisInputFactureTotalInOne({
                id: response.data.id,
                totalFactureInOne: response.data.totalFactureInOne,
                totalFactureInOneCouvert: response.data.totalFactureInOneCouvert,
                totalFactureInOneNoCouvert: response.data.totalFactureInOneNoCouvert,
                totalInOnePartPatient: response.data.totalInOnePartPatient,
                totalInOnePartSolis: response.data.totalInOnePartSolis,
                totalInOnePartPatientFacture: response.data.totalInOnePartPatientFacture,
                totalInOnePartSolisFacture: response.data.totalInOnePartSolisFacture,
                inOnecreatedBy: response.data.inOnecreatedBy,  // Corrected
                inOnedateCreated: response.data.inOnedateCreated,  // Corrected
                inOnetimeCreated: response.data.inOnetimeCreated,  // Corrected
                mois:response.mois
            });
            saveRestPlafond();
            UpdateFactureSaisieById();
            // UpdatePlafondConsommeById();
            console.log(response.data);
        }).catch((e: Error) => {
      console.log(e);
    });
    
};
const updateFactureTotoalInOne = () => { 

  let currentDateFormat = getCurrentDate();
  console.log('updateFactureTotoalInOne', currentDateFormat); // Output: '2024-05-12'

  let currentTime = getCurrentTime();
  console.log('updateFactureTotoalInOne', currentTime); // Output: '12h:05min'
 // Get current month in French
 const now = new Date();

 // Get current month in French
 const monthsInFrench = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
 const moisPayer = monthsInFrench[now.getMonth()];

  let data = {
      totalFactureInOne: totalAllServicesUpdate,
      totalFactureInOneCouvert: factureCouvertAllServicesUpdate,
      totalFactureInOneNoCouvert: factureNocouvertAllServicesUpdate,
      totalInOnePartPatient: partPatientAllServicesUpdate,
      totalInOnePartSolis: partMutuelleAllServicesUpdate,
      totalInOnePartPatientFacture: partPatientFactureAllServicesUpdate,
      totalInOnePartSolisFacture: partMutuelleFactureAllServicesUpdate,
      inOnecreatedBy: preferredName,  // Corrected
      inOnedateCreated: currentDateFormat,  // Corrected
      inOnetimeCreated: currentTime,  // Corrected
      mois:moisPayer
  };

  ServicesFactureTotalInOne.update(
    factureTotalInOneIdRecent,
      data)
      .then((response: any) => {
          setisInputFactureTotalInOne({
              id: response.data.id,
              totalFactureInOne: response.data.totalFactureInOne,
              totalFactureInOneCouvert: response.data.totalFactureInOneCouvert,
              totalFactureInOneNoCouvert: response.data.totalFactureInOneNoCouvert,
              totalInOnePartPatient: response.data.totalInOnePartPatient,
              totalInOnePartSolis: response.data.totalInOnePartSolis,
              totalInOnePartPatientFacture: response.data.totalInOnePartPatientFacture,
              totalInOnePartSolisFacture: response.data.totalInOnePartSolisFacture,
              inOnecreatedBy: response.data.inOnecreatedBy,  // Corrected
              inOnedateCreated: response.data.inOnedateCreated,  // Corrected
              inOnetimeCreated: response.data.inOnetimeCreated,  // Corrected
              mois:response.data.mois
          });
          saveRestPlafond();
          UpdateFactureSaisieById();
          // UpdatePlafondConsommeById();
          console.log(response.data);
      }).catch((e: Error) => {
    console.log(e);
  });
  
};


const payMyBill = () => { 

  if(ischeckBillRecent===false){
    saveFactureTotoalInOne()
  }else{
    updateFactureTotoalInOne()
  }
};

const saveRestPlafond = () => { 
  let currentDateFormat=getCurrentDate()

  let data = {
    codeId:isgetselecterCurrantNewVisitePushed.codeAssuree,
    numeroBon:isgetselecterCurrantNewVisitePushed.receiptBill,
    partPatientConsomme:isPartPatientFacture,
    idRestIncrementPlafondConsomme:0,
    status:"payer" ,
    created_by:preferredName,
    date_created_by:currentDateFormat
  };

  RestPlafondYearService.createRestPlafond(
    isgetselecterCurrantNewVisitePushed.idinsurance,
    isgetselecterCurrantNewVisitePushed.idEnterprise,
    isgetselecterCurrantNewVisitePushed.idpmember,
    isgetselecterCurrantNewVisitePushed.plafondYearId,
    isgetselecterCurrantNewVisitePushed.categoryId,
    isgetselecterCurrantNewVisitePushed.idVisite,
    data)
  .then((response: any) => {
    setisInputRestPlafond({
      id: response.data.id,
      codeId: response.data.incrementIdReceiptBill,
      numeroBon: response.data.receiptBill,
      partPatientConsomme:response.data.partPatientConsomme,
      idRestIncrementPlafondConsomme:response.data.idRestIncrementPlafondConsomme,
      status:response.data.status,
      created_by: response.data.created_by,
      date_created_by: response.data.date_created_by
    });
    console.log(response.data); 
    console.log("isPartPatientFacture",isPartPatientFacture)
  }).catch((e: Error) => {
    console.log(e);
  });
  
};
const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }
  const handleChangeConfig = (e: { target: { name: any; value: any; }; }) => {
    seIdService(e.target.value)
   
  }
  const handleChangeDepertment = (e: { target: { name: any; value: any; }; }) => {
    setdepertmentId(e.target.value)
//        console.log('')
};
const getServices = useCallback(() => {
    ServiceHopital.getAllTypeWithoutPagination()
      .then((response: any) => {
        setservice(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [setservice]);

  const getDepertment = useCallback(() => {
    ServiceDepartment.getAllTypeWithoutPagination()
      .then((response: any) => {
        setdepertment(response.data);
        // setdepertmentFacture(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [setdepertment]);
   
  useEffect(() => {
    getDepertment();
    getServices();
},[getDepertment,  getServices]);

    
    const ConfigServiceInterprise =() => {
      if (
        isgetselecterCurrantNewVisitePushed.idinsurance !== 0 &&
        isgetselecterCurrantNewVisitePushed.idEnterprise !== 0 &&
        isgetselecterCurrantNewVisitePushed.serviceName.length !== 0 &&
        isgetselecterCurrantNewVisitePushed.category_code !== null
      ) {
        CouvertureconfigService.findByInsuranceIdAndEnterpriseIdAndSecteurAndidCouvertureConfig(
          isgetselecterCurrantNewVisitePushed.idinsurance,
          isgetselecterCurrantNewVisitePushed.idEnterprise,
          isgetselecterCurrantNewVisitePushed.typehospital,
          idService,
          isgetselecterCurrantNewVisitePushed.category_code
        )
          .then((response: any) => {
            setConfigPlafondFacture(response.data.plafondOrdonence);
            DispatchPushConfigFacturePlafondParService(updateplafondFacturePrService(response.data.plafondOrdonence)); // sending data print
  
           console.log('Visaa response.data.plafondOrdonence',response.data.plafondOrdonence)
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else {
         console.log('haliii ganiii')
      }
    };
    
      return (
        <>
        <div>
     < form className="form-edit" onSubmit={onFormSubmit}>
     <Stack direction="row" divider={<Divider orientation="horizontal" flexItem />} spacing={1}>
  <Row className="mb-3 mt-3">
    <Form.Group controlId="formBasicEmail" className="col col-sm-3" style={{ width: '200px' }}>
      <Form.Select className="form-control" size="sm" style={{ width: '100%' }} onChange={handleChangeConfig}>
        <option value="0" style={{ display: 'none' }}>Plafond..</option>
        {service &&
          service.map((service) => (
            <option key={service.id} value={service.id}>
              {service.typeName}
            </option>
          ))}
      </Form.Select>
    </Form.Group>

    <Form.Group controlId="formBasicEmail" className="col col-sm-3" style={{ width: '200px' }}>
      <Form.Select className="form-control" size="sm" style={{ width: '100%' }} onChange={handleChangeDepertment}>
        <option value="0" style={{ display: 'none' }}>Depertment..</option>
        {depertment &&
          depertment.map((depertment) => (
            <option key={depertment.id} value={depertment.id}>
              {depertment.typeName}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
    <Form.Group controlId="formBasicEmail" className="col col-sm-3" style={{ width: '200px' }}>
     
    <Button
      className="btn-sm rounded-pill mr-3"
      variant="outline-success"
      onClick={() => {
        ActualizePerServices();
      }}
    >
      <RiSearch2Line size={25} />
    </Button>
    </Form.Group>

  </Row>
</Stack>
        
            </form>
     </div>

     <div>

<Form.Label ><strong>Facture Recente</strong></Form.Label>
       <Stack
  direction="row"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}
>
              <Form.Label><strong>Total de Facture</strong>:{totalAllServicesRecent}</Form.Label>
              <Form.Label><strong>Part Patient </strong>:{partPatientFactureAllServicesRecent}</Form.Label> 
              <Form.Label><strong>Part Mituelle </strong>:{partMutuelleFactureAllServicesRecent}</Form.Label> 
              <Form.Label><strong>Facture couvert</strong>:{factureCouvertAllServicesRecent}</Form.Label> 
              <Form.Label><strong>Facture No couvert</strong>:{factureNocouvertAllServicesRecent}</Form.Label> 
</Stack>     

<Form.Label><strong>Facture Commende</strong></Form.Label>
<Stack
  direction="row"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}
>
              <Form.Label><strong>Total de Facture</strong>:{isfactureTotal}</Form.Label>
              <Form.Label><strong>Part Patient </strong>:{isPartPatientFacture}</Form.Label> 
              <Form.Label><strong>Part Mituelle </strong>:{isPartMituelleFacture}</Form.Label> 
              <Form.Label><strong>Facture couvert</strong>:{isfactureCouvert}</Form.Label> 
              <Form.Label><strong>Facture No couvert</strong>:{isfactureNoCouvert}</Form.Label> 
</Stack>    

<Form.Label><strong>New Updated bill</strong></Form.Label>
<Stack
  direction="row"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}
>
              <Form.Label><strong>Total de Facture</strong>:{totalAllServicesUpdate}</Form.Label>
              <Form.Label><strong>Part Patient </strong>:{partPatientFactureAllServicesUpdate}</Form.Label> 
              <Form.Label><strong>Part Mituelle </strong>:{partMutuelleFactureAllServicesUpdate}</Form.Label> 
              <Form.Label><strong>Facture couvert</strong>:{factureCouvertAllServicesUpdate}</Form.Label> 
              <Form.Label><strong>Facture No couvert</strong>:{factureNocouvertAllServicesUpdate}</Form.Label> 

</Stack>    

     </div>
     
<div style={{ width: '100%' }}>
<DataGrid 
          rows={isNewFactureSaisieDto}
          getRowId={(row) => row.idFacture}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel:{page:0,pageSize:6},
            },
          }}
          pageSizeOptions={[6,10,15,25,35,45]}
            autoHeight
            
          checkboxSelection
          
          onRowSelectionModelChange={(ids) => {

            const selectedIDs = new Set(ids);
            selectedRow = isNewFactureSaisieDto.filter((row) => selectedIDs.has(row.idFacture),);
         
            console.log("selectedIDs selectedIDs",selectedIDs)
            setIdRowSelected(ids)
            AnalyseFactureToPrint(selectedRow)
            console.log("values of selectedRow",selectedRow)
           

       
        }
        }
         
        />

<Stack
  direction="row"
  divider={<Divider orientation="horizontal" flexItem />}
  spacing={2}
>
  <Form.Label><strong>Facture A peyer </strong>:{isfactureApeyer}</Form.Label> 
</Stack>
 <Stack 
 direction="row"
 spacing={3}>
  

       {!isPayer ? (
   <Stack>
   <Button
     onClick={() => { payMyBill()}}
     variant='me-4 btn btn-success btn-sm btn-block'
   >
     Payer
   </Button>
 </Stack>
 
  
) : (

<>
<Button variant='outline-danger'>
        Plafond Insufficient
      </Button>
      <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
    >
      <Form.Label>Plafond Annuelle:{plafondYear}</Form.Label>
      <Form.Label>Plafond Consomme:{parPartientDejaConsomme}</Form.Label>
      <Form.Label>Plafond Restant:{plafondYear - parPartientDejaConsomme}</Form.Label>
      <Form.Label>Somme Manquante:{(isfactureApeyer + parPartientDejaConsomme) - plafondYear}</Form.Label>
    </Stack>

</>
)}

      

 </Stack>

     </div>


          
        </>
      );
    }
