import { Row, Table, Stack, Button, Form } from "react-bootstrap";
import { ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { Itype } from "../Interfaces/Itype";
import ServiceMedicament from "../Services/ServiceMedicament";
import { debounce } from "@mui/material";
import IdataModelListMedicament from "../Models/ListMedicament";
import { AddFactureMedicament } from "../../Espace_Patients/FactureMedicament/AddFactureMedicament/AddFactureMedicament";

export const TableMedicamentClient: React.FC<Itype> = (props) => {
  const [type, setType] = useState<Array<IdataModelListMedicament>>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [searchTypeTypeName, setSearchTypeTypeName] = useState<string>("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 16];
 // Modal state
 const [showModal, setShowModal] = useState(false);
 const [currentType, setCurrentType] = useState<IdataModelListMedicament | null>(null);

  const getRequestParams = (searchTypeTypeName: string, thePage: number, thePageSize: number) => {
    const params: any = {
      page: thePage - 1,
      size: thePageSize,
    };

    if (searchTypeTypeName) {
      params["typeName"] = searchTypeTypeName;
    }

    return params;
  };

  const onChangeSearchTypeTypeName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTypeTypeName(e.target.value);
  };

  const retrieveType = () => {
    const params = getRequestParams(searchTypeTypeName, page, pageSize);

    ServiceMedicament.getAll(params)
      .then((response: any) => {
        const { type, totalPages } = response.data;
        setType(type);
        setCount(totalPages);
      })
      .catch((e: Error) => {
        console.error(e);
      });
  };

  const debouncedRetrieveType = debounce(retrieveType, 300);

  useEffect(() => {
    debouncedRetrieveType();
    return debouncedRetrieveType;
  }, [searchTypeTypeName, page, pageSize, debouncedRetrieveType]);

  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const setActiveType = (datatype: IdataModelListMedicament, index: number) => {
    setCurrentIndex(index);
    setCurrentType(datatype);
    setShowModal(true);
    console.log(datatype);
    console.log(index);
  };


  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentType(null);
  };
  return (
    <>
      <Row className="mb-3">
        <Stack direction="horizontal" gap={2}>
          <Form.Control
            size="sm"
            className="me-auto w-25"
            placeholder="Filtre le type medicament..."
            value={searchTypeTypeName}
            onChange={onChangeSearchTypeTypeName}
          />
        </Stack>
        <div className="mt-3">
          {"Items per Page: "}
          <select onChange={handlePageSizeChange} value={pageSize}>
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
          <Pagination
            className="my-3"
            count={count}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
        <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>Liste Medicament</th>
            </tr>
          </thead>
          <tbody>
            {type.length > 0 ? (
              type.map((i, index) => (
                <tr key={i.id}>
                  <td>{i.typeName}</td>
                  <td>
                    <Stack direction="horizontal" gap={2}>
                      <Button
                        className="me-4 btn btn-success btn-sm btn-block"
                        onClick={() => setActiveType(i, index)}
                      >
                        Add Medecine
                      </Button>
                    </Stack>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10}>no Type</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>

      {/* Modal */}
      {currentType && (
        <AddFactureMedicament
          show={showModal}
          handleClose={handleCloseModal}
          currentType={currentType}
          onEditType={props.onEditType}
        />
      )}
    </>
  );
};
