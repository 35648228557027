import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card, Stack, Container } from "react-bootstrap";
import InsuranceTypeService from '../Services/InsuranceTypeService';
import Iinsurance from '../Models/Iinsurance';

let accountIncrement=0

const initialTypeState = {
  id: null,
  insuranceName: '',
  status: '',
  plafond: '',
  statusPlafond: '',
  typeInsurance: '',
  userName: '',
  phoneNumber: '',
  email: '',
  address: '',
  password: '',
  account: '',
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};

export const AddInsurance:React.FC=()=> {
  const [selectedStatus, setselectedStatus] = useState<string>('');
  const [selectedPlafond, setselectedPlafond] = useState<string>('');
  const [selectedStatusPlafond, setselectedStatusPlafond] = useState<string>('');
  const [selectedTypeAssurance, setselectedTypeAssurance] = useState<string>('');


  const radioHandlerStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setselectedStatus(event.target.value);
    console.log('selectedStatus',selectedStatus);
  };

  const radioHandlerPlafond = (event: React.ChangeEvent<HTMLInputElement>) => {

    console.log('selectedPlafond before',selectedPlafond);
    setselectedPlafond(event.target.value);
  };
  
  const radioHandlerStatusPlafond = (event: React.ChangeEvent<HTMLInputElement>) => {
    setselectedStatusPlafond(event.target.value);
  };
  const radioHandlerTypeAssurance = (event: React.ChangeEvent<HTMLInputElement>) => {
    setselectedTypeAssurance(event.target.value);
  };
const getLastAccountType =()=>{
  InsuranceTypeService.getLastAccountType()
    .then((response: any) => {
        accountIncrement=response.data.account
        console.log(accountIncrement);
  })
}

useEffect(() => {
 getLastAccountType();
});


const [isInput,setInput]=useState<Iinsurance>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        if(accountIncrement===undefined){
          accountIncrement=200
        }
        let data = {
          insuranceName:isInput.insuranceName,
          status:selectedStatus,
          plafond:selectedPlafond,
         statusPlafond:selectedStatusPlafond,
         typeInsurance:selectedTypeAssurance,
          userName: isInput.userName,
         phoneNumber: isInput.phoneNumber,
         email: isInput.email,
        address: isInput.address,
         password: isInput.password,
         account: accountIncrement+1,
          createdBy:'haliii ganii',
          dateCreatedBy: new Date()
        };

        InsuranceTypeService.create(data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            insuranceName: response.data.insuranceName,
            status: response.data.status,
           plafond: response.data.plafond,
           statusPlafond: response.data.statusPlafond,
           typeInsurance: response.data.typeInsurance,
           userName: response.data.userName,
           phoneNumber: response.data.phoneNumber,
           email: response.data.email,
             address: response.data.address,
            password: response.data.password,
            account:response.data.account,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
        setselectedPlafond('');
        setselectedStatus('');
        setselectedStatusPlafond('');
        setselectedTypeAssurance('');
      }

      
    return(
        <>
<Row>
<Form.Group controlId="formBasic" className="col col-sm-3">
 
 <Form >
 <div key="radio-button1" className="col col-sm-3">
  <Form.Check
    inline
    label="Activate"
    name="group1"
    type="radio"
    value="Activate"
    id="id_activate"
    onChange={radioHandlerStatus}
  />
  <Form.Check
    inline
    label="Deactivate"
    name="group1"
    type="radio"
    id="id_deactivate"
    value="Deactivate"
    onChange={radioHandlerStatus}
  />
</div>
</Form>   

</Form.Group>

<Form.Group controlId="formBasic" className="col col-sm-3">
   <Form>
   <div key="radio-button2" className="mb-3">
    <Form.Check
      inline
      label="Avec plafond"
      name="group1"
      type="radio"
      value="Avec plafond"
      id="id_avec_plafond"
      onChange={radioHandlerPlafond}
    />
    <Form.Check
      inline
      label="Sans Plafond"
      name="group1"
      type="radio"
      id="id_sans_plafond"
      value="Sans Plafond"
      onChange={radioHandlerPlafond}
    />
  </div>
</Form>  
  </Form.Group>
  <Form.Group controlId="formBasic" className="col col-sm-3">
  <Form>
  <div key="radio-button3" className="mb-3">
  <Form.Check
  inline
  label="Plafond Individuel"
  name="group1"
  type="radio"
  value="Plafond Individuel"
  id="id_plafond_Individuel"
  onChange={radioHandlerStatusPlafond}
  />
  <Form.Check
  inline
  label="Plafond Entreprise"
  name="group1"
  type="radio"
  id="id_plafond_entreprise"
  value="Plafond Entreprise"
  onChange={radioHandlerStatusPlafond}
  />
  </div>
  </Form>
  </Form.Group>
  <Form.Group controlId="formBasic" className="col col-sm-3">
    <Form>
    <div key="radio-button4" className="mb-3">
    <Form.Check
      inline
      label="Mutuélle"
      name="group1"
      type="radio"
      value="Mutuélle"
      id="id_mutuélle"
      onChange={radioHandlerTypeAssurance}
    />
    <Form.Check
      inline
      label="Assurance Sante"
      name="group1"
      type="radio"
      id="id_assurance_sante"
      value="Assurance Sante"
      onChange={radioHandlerTypeAssurance}
    />
  </div>
  </Form>
  </Form.Group>
</Row>
 
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Nom </Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="insuranceName" name="insuranceName" value={isInput.insuranceName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>tel</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="phoneNumber" name="phoneNumber" value={isInput.phoneNumber} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Email</Form.Label>
            <InputGroup>
                <Form.Control size="sm" aria-describedby="basic-addon2" type="email" name="email" value={isInput.email} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Address</Form.Label>
            <InputGroup>
                <Form.Control size="sm" aria-describedby="basic-addon2" type="address" name="address" value={isInput.address} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
         
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>User Name</Form.Label>
            <InputGroup>
                <Form.Control size="sm" aria-describedby="basic-addon2" type="userName" name="userName" value={isInput.userName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Password</Form.Label>
            <InputGroup>
                <Form.Control size="sm" aria-describedby="basic-addon2" type="password" name="password" value={isInput.password} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        
         </Row>

        <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
     
     
      </Row>


            </form>
        </>
    );
}