import { useEffect, useState } from "react";
import IdataModelSejourVisite from "../../Models/IdataModelSejourVisite";
import IdataModelSejourVisiteDtos from "../../../InOneSejourVisitePrice/Models/IdataModelSejourVisiteDtos";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { AddPrice } from "../../../InOneSejourVisitePrice/PriceOperations/AddPrice";
import { EditAddPrice } from "../../../InOneSejourVisitePrice/PriceOperations/EditAddPrice";
import { PriceSejourVisiteTable } from "../../../InOneSejourVisitePrice/Tables/PriceSejourVisiteTable";

const initialSejourVisiteDtos = {
       ///InOneSejourVisites    in
       idInOneSejourVisite:0,
       typeName:'',
       account:0,
      /// InOneSejourVisitesPrice inp
       idInOneSejourVisitesPrice:0,
       price:0,
      ///// hospital h
       idHospital:0,
       hospitalName:''
    
  };

  const initialSejourVisite= {
    id :'',
    typeName: '',
    account:'',
    createdBy: '',
    dateCreatedBy: new Date()
  };
  interface IPushedCurrantList {
    show: boolean; 
    hospitalid:any;
    onHide: () => void; 
    pushedinfo: IdataModelSejourVisite;
  }

  export const ModelSejourVisitesPrice:React.FC<IPushedCurrantList>=props=>
    {
        const [isPriceModel,setPriceModel]= useState(true);
        const [typeHospitalId,setTypeHospitalId]= useState(0);

        const [EditSejourVisiteDtos, setEditSejourVisiteDtos] = useState<IdataModelSejourVisiteDtos>(initialSejourVisiteDtos);

        const [EditPrice, setEditPrice] = useState<IdataModelSejourVisite>(initialSejourVisite);

        const onCurrantPriceEditDtos=(CurrantData:IdataModelSejourVisiteDtos)=>{
            setEditSejourVisiteDtos(CurrantData);
        }
        
  useEffect(() => {
    setEditPrice(props.pushedinfo)
    setTypeHospitalId(props.hospitalid)
      },[props.pushedinfo, props.hospitalid])
        return(
            <>
    <Modal {...props}
    size="lg"
    backdrop="static"
    keyboard={false}
    
     aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Prix de Sejour/Visite selon Hopital   
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
      <Container fluid>
                <Row>
                <Col> 
                <Card>
            <Card.Body>
           {isPriceModel?
                (
                    <AddPrice show={false}
                     hospitalid={typeHospitalId}
                     onHide={() => setPriceModel(false)}
                    editcurrantacts={EditPrice} />
                ):(
      <EditAddPrice 
      show={isPriceModel}
       onHide={() => setPriceModel(true)}
       editpriceDtos={EditSejourVisiteDtos}
             /> 
                )
  }
     <PriceSejourVisiteTable 
     show={isPriceModel} 
     onHide={() => setPriceModel(false)}
     onEditpriceActe={onCurrantPriceEditDtos}

          />
            </Card.Body>
        </Card>
                </Col>
                </Row>
            </Container>
      </Modal.Body>
    
    </Modal>
           
            </>
        );
    }