import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card, Button, Col } from "react-bootstrap";
import NonAffilieService from '../Services/NonAffilieService';
import IdataModelTypeDoctor from '../Models/IdataModelRegisretationNonAffilie';
import { RootState } from '../../../../Redux/Store/store';
import { useSelector } from 'react-redux';
import IdataModelRegisretationNonAffilie from '../Models/IdataModelRegisretationNonAffilie';
import HospitalTypeService from '../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService';
import IdataModelHospitalType from '../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType';


const initialTypeState = {
  id: null,
  fullNamePatient:'',
  phoneNumberPatient:'',
  addressPatient:'',
  cdnpatient:'',
  genderPatient:'',
  age:'',
  codePatient:0,
  createdBy:'',
  createdDate:'',
};

export const AddNonAffilier:React.FC=()=> {

  const preferredName = useSelector((state: RootState) => state.user.preferred_username);

  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  const [patientCode, setpatientCode] = useState(1);
  
  const [hosptId, sethosptId] = useState(0);

  const findByHospitalId = useCallback(() => {
    if (StructuresData.length > 0) {
      let concatenatedString: string = StructuresData.join(", ");
      console.log("concatenatedString", concatenatedString);
      const MonStructure = concatenatedString;

      HospitalTypeService.findByhospitalName(MonStructure)
        .then((response: any) => {
          const { hospital } = response.data;
          
          sethosptId(hospital[0].id);
          console.log("response.data.id", hospital);
        });
    }
  }, [StructuresData]);

  useEffect(() => {
    findByHospitalId();
  }, [findByHospitalId]);


const getLastCodePatient = useCallback(() => {
  if (hosptId !== 0) {
    NonAffilieService.getLastCodePatient(hosptId)
      .then((response: any) => {
        setpatientCode(response.data.codePatient);
        console.log("Last code", response.data.codePatient);
        if(response.data.codePatient===undefined) {
          setpatientCode(0);
        }
      })
      .catch((error) => {
        console.error('Error fetching last patient code:', error);
      });
  } else {
    console.log("hospitalId is null");
  }
}, [hosptId]);

  // Use the hook as needed, for example in a useEffect
  useEffect(() => {
    getLastCodePatient();
  }, [getLastCodePatient]);


  function getCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    // Months are zero-based, so we add 1 to get the correct month
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  

const [isInput,setInput]=useState<IdataModelRegisretationNonAffilie>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        const formattedDate = getCurrentDate();
        
        let data = {
          fullNamePatient: isInput.fullNamePatient,
          phoneNumberPatient: isInput.phoneNumberPatient,
          
          addressPatient: isInput.addressPatient,
          
          cdnpatient: isInput.cdnpatient,
          
          genderPatient: isInput.genderPatient,
    
          age: isInput.age,
          codePatient: patientCode+1,
          createdBy:preferredName,
          createdDate: formattedDate
        };

        NonAffilieService.create(hosptId,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
          fullNamePatient: response.data.fullNamePatient,
          phoneNumberPatient: response.data.phoneNumberPatient,          
          addressPatient: response.data.addressPatient,         
          cdnpatient: response.data.cdnpatient,         
          genderPatient: response.data.genderPatient,
          age: response.data.age,
          codePatient:response.data.codePatient,
          createdBy:response.data.createdBy,
          createdDate: response.data.createdDate
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
          getLastCodePatient()
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }

      return (
        <>
          <form className="form-edit" onSubmit={onFormSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formFullName">
                <Form.Label>Full Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="fullNamePatient"
                    value={isInput.fullNamePatient}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
              
              <Form.Group as={Col} controlId="formPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="phoneNumberPatient"
                    value={isInput.phoneNumberPatient}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
           
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="addressPatient"
                    value={isInput.addressPatient}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="formCDN">
                <Form.Label>CDN</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="cdnpatient"
                    value={isInput.cdnpatient}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>

            <Row className="mb-3">
            <Form.Group as={Col} controlId="formGender">
      <Form.Label>Gender</Form.Label>
      <InputGroup>
        <Form.Control
          as="select"
          name="genderPatient"
          value={isInput.genderPatient}
          onChange={handleChange}
        >
          <option value="">Select Gender</option>
          <option value="homme">Homme</option>
          <option value="femme">Femme</option>
        </Form.Control>
      </InputGroup>
    </Form.Group>
              <Form.Group as={Col} controlId="formAge">
                <Form.Label>Age</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="age"
                    value={isInput.age}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Col>
                <Button type="submit" onClick={save} className="me-4 btn btn-success btn-sm btn-block">
                  Save
                </Button>
                <Button type="button" onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </>
      );
}