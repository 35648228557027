import http from "../../../../AxiosRequest/httpcommon";
import IdataModelTypeStatus from "../Models/IdataModelTypeStatus";
import IdataModelTypeStatusDtos from "../Models/IdataModelTypeStatusDto";

const getAll = (params:any) => {
  return http.get<Array<IdataModelTypeStatusDtos>>("/TypeBonsVerificationDtos", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelTypeStatus>>("/sortedTypeBonsVerification");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelTypeStatus>>("/typePatientStatusAccount");
}

const create = (socialinsuranceId:any,data: IdataModelTypeStatus) => {
  return http.post<IdataModelTypeStatus>(`/socialinsurance/${socialinsuranceId}/TypeBonsVerification`, data);
};

const update = (id: any, data: IdataModelTypeStatus) => {
  return http.put<any>(`/TypeBonsVerification/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/TypeBonsVerification/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/TypeBonsVerification`);
};

const findByTypetypeBonsName = (insuranceId:any,typeBonsName: string) => {
  return http.get<Array<IdataModelTypeStatusDtos>>(`/TypeBonsVerificationDtos?insuranceId=${insuranceId}&typeBonsName=${typeBonsName}`);
};

const ServiceTypeBonsVerification = {
  getAllTypeWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByTypetypeBonsName,
};

export default ServiceTypeBonsVerification;