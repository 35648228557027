import http from "../../../../AxiosRequest/httpcommon";
import IdataModelCategory from "../Models/IdataModelCategory";

const getAll = (params:any) => {
  return http.get<Array<IdataModelCategory>>("/typePrestations", { params });
};

const getAllCategoryPrestationWithoutPagination = () => {
  return http.get<Array<IdataModelCategory>>("/sortedtypePrestations");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelCategory>>("/typePrestationsAccount");
}

const create = (data: IdataModelCategory) => {
  return http.post<IdataModelCategory>("/typePrestations", data);
};

const update = (id: any, data: IdataModelCategory) => {
  return http.put<any>(`/typePrestations/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/typePrestations/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/typePrestations`);
};


const findByTypePrestationsName = (typePrestationsName: string) => {
  
  return http.get<Array<IdataModelCategory>>(`/typePrestations?typePrestationsName=${typePrestationsName}`);
};

const CategoryService = {
  getAllCategoryPrestationWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByTypePrestationsName,
};

export default CategoryService;
