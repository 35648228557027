import { Container, Row, Col, Card } from "react-bootstrap";
import { TableListeVisiteGroupHospital } from '../../../Components/Espace_Patients/FactureSaisie/Tables/TableListeVisite/TableListeVisiteGroupHospital';

export const FacturationListes=()=>{

    return (

        <Container fluid>
     
          <Card >
            <Card.Body>
              <TableListeVisiteGroupHospital/>
              </Card.Body>
          </Card>
        
        </Container>
    
    );
}
