import { ChangeEvent, useEffect, useState } from "react";
import IDataModelTypeCategory from "../Models/IdataModelTypeCategory";
import { Form, InputGroup, Row } from "react-bootstrap";
import IDataModelCategory from "../../Categories/Models/IdataModelCategory";
import CategoryService from "../../Categories/Services/CategoryTypeServices";
import ServiceTypeCategory from "../Services/ServiceTypeCategory";

let accountIncrement = 0;

const initialType: IDataModelTypeCategory = {
  id: null,
  code: "",
  intitule: "",
  excl: "",
  acc: "",
  dateCreatedBy: "",
};
const defaultInitiaT: Array<IDataModelCategory> = [];

export const AddType: React.FC = () => {

  const getLastAccountType = () => {
    ServiceTypeCategory.getLastAccountType()
      .then((response: any) => {
        accountIncrement = response.data.accountName;
      });
  };


  useEffect(() => {
    getLastAccountType();
  }, []);

  const [isInput, setInput] = useState<IDataModelTypeCategory>(initialType);


  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setInput({ ...isInput, [e.target.name]: e.target.value });
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  
const [TypCatgry, setTypCatgry] = useState(defaultInitiaT);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0); // State to hold the selected category

  useEffect(() => {
    // Fetch categories when the component mounts
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    CategoryService.getAllCategoryPrestationWithoutPagination()
      .then((response:any) => {
        setTypCatgry(response.data); // Set the fetched categories to state
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  
  const handleCategoryChange = (e: { target: {value: any; }; }) => {
    setSelectedCategoryId(e.target.value);

      }
  const save = () => {
    // Get current date
const currentDate = new Date();

// Format date as dd-mm-yy
const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;

    if (accountIncrement === undefined) {
      accountIncrement = 20000;
    }

    let data = {
      code: isInput.code,
      intitule: isInput.intitule,
      excl: isInput.excl,
      acc: isInput.acc,
      dateCreatedBy: formattedDate
    };

    ServiceTypeCategory.create(selectedCategoryId, data)
      .then((response: any) => {
        setInput({
          id: response.data.id,
          code: response.data.code,
          intitule: response.data.intitule,
          excl: response.data.excl,
          acc: response.data.acc,
          dateCreatedBy: response.data.dateCreatedBy
        });
        console.log(response.data);
        console.log(data);
        setInput(initialType);
        setTypCatgry(defaultInitiaT);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const reset = () => {
    setInput(initialType);
  };

  return (
    <>
      <form className="form-edit" onSubmit={onFormSubmit}>
        <Row className="mb-3">
          <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Intitulé</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="intitule"
                value={isInput.intitule}
                onChange={handleChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formGridExcl" className="col col-sm-3">
            <Form.Label>Excl</Form.Label>
            <Form.Select
              name="excl"
              value={isInput.excl}
              onChange={handleChange}
            >
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="formGridAcc" className="col col-sm-3">
            <Form.Label>Acc</Form.Label>
            <Form.Select
              name="acc"
              value={isInput.acc}
              onChange={handleChange}
            >
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </Form.Select>
          </Form.Group>
        </Row>
       
        <Row className="mb-3">
    
    <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
          <Form.Label>Soins </Form.Label>
    <Form.Select className="form-control" 
        onChange={handleCategoryChange}>
        <option value="0">Choose..</option>
      {TypCatgry &&
         TypCatgry.map((TypCatgry) => (
            <option key={TypCatgry.id} value={TypCatgry.id} >
              {TypCatgry.typePrestationsName}</option>
))}
    
</Form.Select>
</Form.Group>

        </Row>
  
        
  
        <Row className="mb-3">
          <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button onClick={save} className="me-4 btn btn-success btn-sm btn-block">
              Save
            </button>
  
            <button onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">
              Cancel
            </button>
          </Form.Group>
        </Row>
      </form>
    </>
  );
};
