import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import ServiceSmember from '../Services/ServiceSmember';
import IdataModelSmember from '../Models/IdataModelSmember';
import ServicePrincipalMember from '../../AdherantPrencipales/Services/ServicePrincipalMember';
import IdataModeEnterprise from '../../Entreprises/Models/IdataModeEnterprise';
import IdataModelPMemberDtos from '../../AdherantPrencipales/Models/IdataModelPMemberDtos';
import IdataModelSmemberDtos from '../Models/IdataModelSmemberDtos';

let accountIncrement=0
let num=0;
const initialTypeState = {
  id: null,
  codeAyantDroit: '',
  account: accountIncrement,
  ayantDroit: '',
  dateNaissance: '',
  gender: '',
  epouse:'',
enfantplus:'',
  bloodGroup: '',
  resilier: '',
  photoMember:'',
  
  dateResiliation: '',
  createdBy: '',
  dateCreated: '',
  
};
interface IpushCurrantValue {
  editinfo: IdataModelPMemberDtos;
}
const initialTypeStatePMemberDtos = {
  
    //table enterpriseAssurance   ta1
    idInterprise:0,
    accountInterprise:0,
    enterpriseName: '',
    couvertureDu: '',
    couvertureAau: '',
    resilierSociete:'',

///table PMember pa
    idPmember:0,
    codeAssuree: '',
    nomPrenomAffilie: '',
    cotisationExact: '',
    account:0,
    genre: '',
    catégorieSoins: '',
    email: '',
    telephones: '',
    adresseAffilie: '',
    autresInformations: '',

    plafond_ambilatoire: '',
    plafond_hospitalisation: '',
    plafond_pharmacy: '',
    plafond_ORL: '',
    plafond_urugence: '',
    plafond_ophtalmologie: '',
    category_code: '',
    plafond_dentisteries: '',
    resilier: '',
    dataResiliation: '',
    photoMember: '',
    dateNaissance: '',
    createdBy: '',
    dateCreated: '',
    tva:'',
    etatCivil:'',

   ////table socialInsurance so
    idSociete:0,
    societeName: ''
};
export const AddSmember:React.FC<IpushCurrantValue>=props=> {

  const [getCurrantPMemberValue, setgetCurrantPMemberValue] = useState<IdataModelPMemberDtos>(initialTypeStatePMemberDtos);
  const [isResilier,setResilier]=useState('');

  const [isInput,setInput]=useState<IdataModelSmember>(initialTypeState);
  const [getLastNumberAccountDtos,setgetLastNumberAccountDtos]=useState<Array<IdataModelSmemberDtos>>([]);
 
  const getRequestParams = (idSociete:any,idInterprise:any,idPmember:any) => {
    let params = {
      idSociete:idSociete,
      idInterprise:idInterprise,
      idPmember:idPmember
    };
    if (idSociete) {
      params["idSociete"] = idSociete;
    }
    if (idInterprise) {
      params["idInterprise"] = idInterprise;
    }
    if (idPmember) {
      params["idPmember"] = idPmember;
    }
    return params;
  }

const getLastAccountType = useCallback(() => {
  const params = getRequestParams(getCurrantPMemberValue.idSociete, getCurrantPMemberValue.idInterprise,getCurrantPMemberValue.idPmember);
  ServiceSmember.getLastAccountType(params)
  .then((response: any) => {
    const {Account } = response.data;
    setgetLastNumberAccountDtos(Account);  

    {getLastNumberAccountDtos.length>0 ? (
      getLastNumberAccountDtos.map((i, index) => (
        accountIncrement=i.accountSmember
      // console.log('AccountValue',accountIncrement)
        ))
      ) : ( 
        console.log('haliiganii')
      )}  
})}, [getLastNumberAccountDtos,getCurrantPMemberValue]);

useEffect(() => {
  getLastAccountType();
  setgetCurrantPMemberValue(props.editinfo);

    },[getLastAccountType,props.editinfo])

const handleChange = (e: { target: { name: any; value: any; }; }) => {
  setInput({ ...isInput, [e.target.name]: e.target.value });
}
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const radioHandlerStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResilier(event.target.value);
      };

const save = () => {  
       
        if(accountIncrement===0){
          const str = '' + getCurrantPMemberValue.account + '01';
           num = Number(str);
           console.log('No',num)
        //  accountIncrement=num
        }else if(accountIncrement!==0){
          num= accountIncrement+1
          console.log('Yeds',num)
        }
        else{
          console.log('halii gani')
        }

        let data = {
          codeAyantDroit: isInput.codeAyantDroit ,
          account: num,
          ayantDroit: isInput.ayantDroit ,
          dateNaissance: isInput.dateNaissance ,
          gender: isInput.gender,
          
          bloodGroup: isInput.bloodGroup ,
          resilier: isInput.resilier,
          epouse:isInput.epouse,
          enfantplus:isInput.enfantplus,
          dateResiliation: isInput.dateResiliation ,
          createdBy: isInput.createdBy ,
          dateCreated: isInput.dateCreated 
        };

        ServiceSmember.create(getCurrantPMemberValue.idSociete, getCurrantPMemberValue.idInterprise,getCurrantPMemberValue.idPmember,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            codeAyantDroit: response.data.codeAyantDroit ,
            account:response.data.account,
            ayantDroit:response.data.ayantDroit,
            dateNaissance:response.data.dateNaissance,
            gender: response.data.gender,
            epouse:response.data.epouse,
            enfantplus:response.data.enfantplus,
            bloodGroup: response.data.bloodGroup ,
            resilier: response.data.resilier ,

            dateResiliation: response.data.dateResiliation ,
            createdBy: response.data.createdBy ,
            dateCreated: response.data.dateCreated 
            
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }

      const CountValue=()=>{
        for (let i = 0; i < 2000; i++) {
          save();
          console.log ("Block statement execution no." + i);
        }
      }
    return(
        <>
     
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
        
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>ayantDroit</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="text" name="ayantDroit" value={isInput.ayantDroit} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>date Naissance</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="date" name="dateNaissance" value={isInput.dateNaissance} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Sex</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="gender" 
            value={isInput.gender} onChange={handleChange}>
                <option>Choisir</option>
               <option value="Homme">Homme</option>
             <option value="Femme">Femme</option>
    </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Epouse</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="epouse" 
            value={isInput.epouse} onChange={handleChange}>
                <option>Choisir</option>
                <option value="Yes">yes</option>
             <option value="No">NO</option>
    </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Enfant de plus</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="enfantplus" 
            value={isInput.enfantplus} onChange={handleChange}>
                <option>Choisir</option>
               <option value="Yes">yes</option>
             <option value="No">NO</option>
    </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Groupe sang</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="text" name="bloodGroup" value={isInput.bloodGroup} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Resilier</Form.Label>
            <Form.Select  size="sm" name="resilier" value={isInput.resilier} onChange={handleChange}>
                <option>Choisir </option>
               <option value="Oui">Oui</option>
             <option value="No">No</option>
    </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>date Resiliation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="date" name="dateResiliation" value={isInput.dateResiliation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>

        </Form.Group>
      </Row>
            </form>
        </>
    );
}
