
import { useState } from "react";
import {Button, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import IdataModelListMedicament from "../../../ListMedicament/Models/ListMedicament";
import NewFactureSaisieMedicament from "../models/NewFactureSaisieMedicament";
import ServiceNewFactureSaisieMedicament from "../services/ServiceNewFactureSaisieMedicament";
import NewFactureSaisieMedicamentDto from "../models/Dto/NewFactureSaisieMedicamentDto";
import { clearcurrentMedical, updatecurrentMedical } from "../../../../Redux/slices/currentMedical/currentMedical";


      const newFactureSaisie={
        prestation:'',

  prestationPrescriptPar:'',
        pricePrestation:0,
        plafondFacture:0,
        ticketService:0,
        quantity:1,
        partMutuelle:0,
        partpatient:0,
        partTotal:0,
        statusFacturePayer:'No payer',
        createdBy:'Ramadhani Ally',
        dateCreatedSaisie:'',
        timeSaisie:'',

     partMutuelleFacture:0,
     partpatientFacture:0,
     partTotalFacture:0,
     ticketServiceFacture:0,
     statusFactureDeclare:'',
     statusFactureVerifier:'',
     statusFactureRembourceAvecAutreFacture:'',
     statusFactureRembourceUneFacture:'',
     idRestIncrementPlafondConsomme:'',
/// new
     idIncrement:0,
     accordPar:''
      } 
      
   
interface EditMedicamentModalProps {
  show: boolean;
  handleClose: () => void;
  currentType: IdataModelListMedicament;
  onEditType: (type: IdataModelListMedicament) => void;
}
let quantity=1

export const AddFactureMedicament: React.FC<EditMedicamentModalProps> = ({
  show,
  handleClose,
  currentType,
}) => {

  const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);

  let isDoctor=useSelector((state:RootState)=>state.ConfigAddFactureSaisiePrescriptPar);
  
  const preferredName = useSelector((state: RootState) => state.user.preferred_username);


  const currentMedecineValue = useSelector((state: RootState) => state.currentMedical);

    const [isTotal, setisTotal] = useState(0);
    const [isPartMituelle, setisPartMituelle] = useState(0);
    const [isPartPatient, setisPartPatient] = useState(0);

    const [isTotalFacture, setisTotalFacture] = useState(0);
    const [isPartMituelleFacture, setisPartMituelleFacture] = useState(0);
    const [isPartPatientFacture, setisPartPatientFacture] = useState(0);

    const [ConfigTicket, setConfigTicket] = useState(0);
    const [ConfigTicketFacture, setConfigTicketFacture] = useState(0);
     
     const [isInputFactureSaisie,setisInputFactureSaisie]=useState<NewFactureSaisieMedicament>(newFactureSaisie);
     
  const [currentMedecine, setEditedType] = useState<IdataModelListMedicament>(currentType);

const DispatchPushCarrentMedecine=useDispatch();
    
  const handleOtherMedical = () => {
    DispatchPushCarrentMedecine(clearcurrentMedical());
  };
function getCurrentDate(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  // Months are zero-based, so we add 1 to get the correct month
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
function getCurrentTime(): string {
  const currentDate = new Date();
  const hours = renderNumber(currentDate.getHours(), 2);
  const minutes = renderNumber(currentDate.getMinutes(), 2);
  return `${hours}h:${minutes}min`;
}

function renderNumber(value: number, length: number): string {
  let result: string = String(value);
  while (result.length < length) {
    result = '0' + result;
  }
  return result;
}


    const AddSaisiePharmacy = () => {  

      const formattedDate = getCurrentDate();
    
      const formattedTime = getCurrentTime();

      let idCurrentMedecine=0;
    
      if(currentMedecineValue.idFacture!==0){
        idCurrentMedecine=currentMedecineValue.idFacture
      }else { 
        idCurrentMedecine=0

      }
            let data = {
              prestation:currentMedecine.typeName,
              pricePrestation:0,
              prestationPrescriptPar:isDoctor.data,
              plafondFacture:0,
              ticketService:ConfigTicket,
              ticketServiceFacture:ConfigTicketFacture,
              quantity:quantity,
    
              partMutuelle:isPartMituelle,
              partpatient:isPartPatient,
              partTotal:isTotal,
              partMutuelleFacture:isPartMituelleFacture,
              partpatientFacture:isPartPatientFacture,
              partTotalFacture:isTotalFacture,
              statusFacturePayer:'No payer',
              statusFactureDeclare:'no',
              statusFactureVerifier:'no',
              statusFactureRembourceAvecAutreFacture:'no',
              statusFactureRembourceUneFacture:'no',
              createdBy:preferredName,
              dateCreatedSaisie:formattedDate,
              timeSaisie:formattedTime,
              idRestIncrementPlafondConsomme:0,
              idIncrement:idCurrentMedecine,
              accordPar:''
            };
    
            ServiceNewFactureSaisieMedicament.createFactureSaisiePharmacy(
              isgetselecterCurrantNewVisitePushed.idinsurance,
              isgetselecterCurrantNewVisitePushed.idFacture,
              isgetselecterCurrantNewVisitePushed.idDoctorName,
              isgetselecterCurrantNewVisitePushed.idtypeDoctor ,
              isgetselecterCurrantNewVisitePushed.idhospital,
              currentMedecine.id,
              data)
            .then((response: any) => {
              setisInputFactureSaisie({
                id: response.data.id,
                prestation: response.data.prestation,
                prestationPrescriptPar:response.data.prestationPrescriptPar,
                pricePrestation: response.data.pricePrestation,
                plafondFacture: response.data.plafondFacture,
                ticketService: response.data.ticketService,
                quantity: response.data.quantity,
                partMutuelle: response.data.partMutuelle,
                partpatient: response.data.partpatient,
                partTotal: response.data.partTotal,
                statusFacturePayer: response.data.statusFacturePayer,
                createdBy: response.data.createdBy,
                dateCreatedSaisie:response.data.dateCreatedSaisie,
                timeSaisie:response.data.timeSaisie,
                partMutuelleFacture:response.data.partMutuelleFacture,
                partpatientFacture:response.data.partpatientFacture,
                partTotalFacture:response.data.partTotalFacture,
                
                ticketServiceFacture:response.data.ticketServiceFacture,
          
                statusFactureDeclare:response.data.statusFactureDeclare,
                statusFactureVerifier:response.data.statusFactureVerifier,
                statusFactureRembourceAvecAutreFacture:response.data.statusFactureRembourceAvecAutreFacture,
                statusFactureRembourceUneFacture:response.data.statusFactureRembourceUneFacture,
                idRestIncrementPlafondConsomme:response.data.idRestIncrementPlafondConsomme,
                idIncrement:response.data.idIncrement,
                accordPar:response.data.accordPar
              });
    
              handleClose();
            })
            .catch((e: Error) => {
              console.log(e);
            });
        };

      const handleSaveChanges = () => {
        AddSaisiePharmacy()
      };
      const handleQuantiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
         quantity = Number(e.target.value);
        
      };
     

  return (
    <Modal 
      show={show}
      onHide={() => {
        handleClose();
        handleOtherMedical(); // Call your custom function when the modal is closed
      }}
       centered
       size="xl"
       >
      <Modal.Header closeButton>
        <Modal.Title>Saisie de Medicament</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row className="mb-3">
      <Form.Group controlId="formBasicGender" className="col col-sm-6">
            <Form.Label>Medicament</Form.Label>
            <Form.Control
              type="text"
              readOnly={true}
              value={currentMedecine.typeName}
            />
          </Form.Group>
          {currentMedecineValue.prestation && (
  <Form.Group controlId="formBasicGender" className="col col-sm-6">
    <Form.Label>Ou cet medicament</Form.Label>
    <Form.Control
      type="text"
      readOnly={true}
      value={currentMedecineValue.prestation}
    />
  </Form.Group>
)}

          
      <Form.Group controlId="formBasicGender" className="col col-sm-3">
            <Form.Label>Quantite</Form.Label>
            <Form.Control
              type="number"
              value={quantity}
              onChange={handleQuantiteChange}
            />
          </Form.Group>
     
      </Row>
        <Form>
          {/* Add other fields as necessary */}
          <Button variant="primary" onClick={handleSaveChanges}>
            Saisir
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}