import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { FaPhotoVideo } from 'react-icons/fa';
import MyPhotos from './MyPhotos';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/Store/store';
import SearchBonVerification from './SearchBonVerification';
export default function PatientInfo() {

  const [open, setOpen] = React.useState(false); // State to manage modal open/close
 
  const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);

  const handleClockClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // Prevent the click event from bubbling up to the parent container
        event.stopPropagation();
        // Your logic to open the modal goes here
        console.log('Clock clicked');
        setOpen(true);
  };
  return (
    <>

<Card sx={{ display: 'flex',
    mb: 1, // margin bottom
    ml: 3,
    mr: 3
     }}>
      <Box sx={{ 
      display: 'flex', 
      flexDirection: { xs: 'column', md: 'row' } // Change flexDirection based on screen size
    }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <SearchBonVerification/>
          <IconButton color="primary" aria-label="add to shopping cart" onClick={handleClockClick}>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" sx={{ marginRight: 1 }}>Voir Photo</Typography>
        <FaPhotoVideo />
      </Box>
    </IconButton>
          
        </CardContent>
        
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
         Enterprise:{isgetselecterCurrantNewVisitePushed.enterpriseName}
          </Typography>
           
          <Typography variant="subtitle1" color="text.secondary" component="div"> 
           Carte:{isgetselecterCurrantNewVisitePushed.codeAssuree}
            </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          category:{isgetselecterCurrantNewVisitePushed.catégorieSoins}
          </Typography>
        </CardContent>
        <CardContent sx={{ flex: '1 0 auto' }}>

          <Typography component="div" variant="h6">
          Patient:{isgetselecterCurrantNewVisitePushed.patientName}
          </Typography>

          <Typography variant="subtitle1" color="text.secondary" component="div">
          Date:{isgetselecterCurrantNewVisitePushed.dateCreatedSaisie}
          </Typography>

          <Typography variant="subtitle1" color="text.secondary" component="div">
          Type Bon:{isgetselecterCurrantNewVisitePushed.typeBonCommendName}
          </Typography>

        </CardContent>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
           
          Hospital: {isgetselecterCurrantNewVisitePushed.hospitalName}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          Visite: {isgetselecterCurrantNewVisitePushed.typeNameVisite}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          Doctor: {isgetselecterCurrantNewVisitePushed.prescriptPar}
          </Typography>
        </CardContent>
      </Box>
    </Card>
     <MyPhotos open={open} onClose={() => setOpen(false)} /> {/* Render your Modal component */}
    
    </>
  );
}
