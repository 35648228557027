import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { Beneficieres } from "../../../../../Pages/ASSURANCES/Beneficieres/Beneficieres";
import { IPmember } from "../../../../ASSURANCES/AdherantPrencipales/Interfaces/IPmember";
import IdataModelPMemberDtos from "../../../../ASSURANCES/AdherantPrencipales/Models/IdataModelPMemberDtos";
import ServicePrincipalMember from "../../../../ASSURANCES/AdherantPrencipales/Services/ServicePrincipalMember";
import IdataModeEnterpriseDtos from "../../../../ASSURANCES/Entreprises/Models/IdataModeEnterpriseDtos";
import { IPmemberHome } from "./interfaces/IPmemberHome";

const initialTypeState = {
 
    //table enterpriseAssurance   ta1
    idInterprise:0,
    accountInterprise:0,
    enterpriseName: '',
///table PMember pa
    idPmember:0,
    codeAssuree: '',
    nomPrenomAffilie: '',
    cotisationExact: '',
    account:0,
    genre: '',
    catégorieSoins: '',
    email: '',
    telephones: '',
    adresseAffilie: '',
    autresInformations: '',

    plafond_ambilatoire: '',
    plafond_hospitalisation: '',
    plafond_pharmacy: '',
    plafond_ORL: '',
    plafond_urugence: '',
    plafond_ophtalmologie: '',
    category_code: '',
    plafond_dentisteries: '',
    resilier: '',
    dataResiliation: '',
    photoMember: '',
    dateNaissance: '',
    createdBy: '',
    dateCreated: '',

   ////table socialInsurance so
    idSociete:0,
    societeName: ''
};
const initialTypeStateEnterpriseDtos = {
  ////table socialInsurance ta
  idSociete:0,
  societeName:0,
 //table enterpriseAssurance   ta1
idInterprise:0,
accountInterprise:0,
  enterpriseName: '',
  responsable: '',
  email: '',
  phoneNumber: '',
  couvertureDu: '',
  couvertureAau: '',
  address: '',
  autreInformation: '',
  resilier: '',
  dataResiliation: '',
     dateCreatedBy: new Date,
  createdBy: '',
  firstCallForPayment: '',
  secondCallForPayment: '',
  thirdCallForPayment: '',
  modePayment: '',
  frequancePayment: '',
 ////// configuration of assurance start
  primeIndividuelle: '',
  primeTotal: '',
  soinsAmbilatoire: '',
  hospitalisation: '',
  ophtalmologie: '',
  dentisterie: '',
  fraisFuneraires: ''
};

export const TableAdherantPrincipal:React.FC<IPmemberHome>=props=>{
    
  const [modalShow, setModalShow] = useState(false);

  const [PrencipalMember, setPrencipalMember] = useState<Array<IdataModelPMemberDtos>>([]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchPrencipalMember, setSearchPrencipalMember] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  
  const getRequestParams = (socialinsuranceId:any, searchnomPrenomAffilie:string, thepage: number, thepageSize: number) => {
    let params = {
      socialinsuranceId:socialinsuranceId,
      nomPrenomAffilie:searchnomPrenomAffilie,
      page: thepage,
      size:thepageSize
    };
  
    if (socialinsuranceId) {
      params["socialinsuranceId"] = socialinsuranceId;
    }
    if (searchnomPrenomAffilie) {
      params["nomPrenomAffilie"] = searchnomPrenomAffilie;
    }
  
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearchPrencipalMember = (e: ChangeEvent<HTMLInputElement>) => {
    const searchPrencipalMember = e.target.value;
    setSearchPrencipalMember(searchPrencipalMember);
  };
  
  const findBynomPrenomAffilie = () => {
    ServicePrincipalMember.findAllPrincipalMember(52,searchPrencipalMember)
      .then((response: any) => {
        const {PmB } = response.data;
        setPrencipalMember(PmB);
        setCurrentIndex(-1);
        //console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const retrievePrencipalMember = useCallback(() => {
    const params = getRequestParams(52,searchPrencipalMember, page, pageSize);
  
    ServicePrincipalMember.getAllAdherantPrincipal(params)
  
      .then((response: any) => {
  
        const {PmB , totalPages} = response.data;
  
        setPrencipalMember(PmB);
        setCount(totalPages);
  
       // console.log(response.data);
       // console.log(params);
  
      })
  
      .catch((e: Error) => {
        console.log(e);
      });
      }, [page, pageSize, searchPrencipalMember]);
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const refreshList = () => {
    retrievePrencipalMember();
    setCurrentIndex(-1);
  };
  
  const Enfant = (onCurrant: IdataModelPMemberDtos, index: number) => {
    setCurrentIndex(index); 
     props.onPush(onCurrant);
     props.onHide();
    console.log(onCurrant);
    console.log(index);
  };
  const setMoi = (onCurrant: IdataModelPMemberDtos, index: number) => {
    setCurrentIndex(index); 
     props.onPush(onCurrant);
     //props.onHide();
    console.log(onCurrant);
   // console.log(index);
  };
  
  useEffect(() => {
    retrievePrencipalMember();
  },[retrievePrencipalMember,page, pageSize]);
  
    return(
          <>
          <Row className="mb-3">
          <Stack  direction="horizontal" gap={2}>
          
          <Form.Group controlId="formFilter" className="col col-sm-3">
              <InputGroup>
                  <Form.Control size="sm"
                   type="text"
                    name="searchEnterprise" 
                      placeholder="Filtre le nom et prenom ou Code d'Assurée"
                    value={searchPrencipalMember}
                     onChange={onChangeSearchPrencipalMember} />
              </InputGroup>
          </Form.Group>
            <Button variant="outline-dark btn-sm"
            onClick={findBynomPrenomAffilie}>Cherchez</Button>
                {"    Items per Page: "}
                  <select onChange={handlePageSizeChange} value={pageSize}>
                    {pageSizes.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                <Pagination
                  className="my-3"
                  count={count}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
             </Stack> 
              
       
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>id</th>
              <th>Nom/prenom</th>
              <th>Code Assurée</th>
              <th>entreprise</th>
              <th>Telephone</th>
              <th>Naissance</th>
              <th>cotisation</th>
              <th>category Soins</th>
              <th>Resilier</th>
              <th>couverture debit</th>
              <th>couverture fin</th>
              <th>Optez</th>

              
            </tr>
          </thead>
          <tbody>
          {PrencipalMember.length>0 ? (
            PrencipalMember.map((i, index) => (
                
                  <tr key={i.idPmember }>
                  <td>{i.idPmember}</td>
                  <td>{i.nomPrenomAffilie}</td>
                  <td>{i.codeAssuree}</td>
                  <td>{i.enterpriseName}</td>
                  <td>{i.telephones}</td>
                  <td>{i.dateNaissance}</td>
                  <td>{i.cotisationExact}</td>
                  <td>{i.catégorieSoins}</td>
                  <td>{i.resilier}</td>
                  <td>{i.couvertureDu}</td>
                  <td>{i.couvertureAau}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>
                    
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setMoi(i, index)}>Moi</Button>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => Enfant(i, index)}>Ayant Droit</Button>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                 >Photo</Button>
                  
                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no Type Doctors</td>
              </tr>
            )}
  
          </tbody>
        </Table>
      
        </Row>
  
          </>
      );
  }