// userSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ArrayState {
  data: string[];
}

const initialState: ArrayState = {
 // data: ['offline_access', 'default-roles-hema-clinic', 'uma_authorization', 'receptionist', 'user'],
  data: [],
};

const userSlice = createSlice({
  name: 'userRoles',
  initialState,
  reducers: {
    updateUserRoles: (state, action: PayloadAction<string[]>) => {
      state.data = action.payload;
    },
  },
});

export const { updateUserRoles } = userSlice.actions;
export default userSlice.reducer;
