import {  useEffect, useState } from "react";
import IdataModelDoctor from "../Models/IdataModelDoctor";
import NamDoctorServices from "../Services/NamDoctorServices";
import {Form, InputGroup, Row } from "react-bootstrap";
import IdataModeConsultationDtos from "../Models/IdataModelConsultationDtos";
import { DropdownTypeDoctorHopital } from "../../Views/DropdownTypeDoctorHopital";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import { FaSave, FaTimes } from 'react-icons/fa';

interface IEditDoctorList {
    show: boolean; 
    onHide: () => void; 
    EditInfoNamDoctor: IdataModeConsultationDtos;
  }



export const EditNamDoctor :React.FC<IEditDoctorList>=props=> {
  

  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  
const [isHospitalDropDown,setIsHospitalDropDown]=useState(true);
    const initialNamDoctor = {
    id : '',
    doctorName:'',
   prescriptPar:'',
    accountNameDoctor:'',
    priceConsultation:'',
    createdBy: 'mama la mama',
    dateCreatedBy: new Date()
    };

    const initialNamDoctorDtos = {
    accountNameDoctor:'',
     accountTypeDoctor:'',
     doctorName:'',
     prescriptPar:'',
     doctorNameId:'',
     doctorTypeId:'',
     hospitalId:'',
     typehospital:'',
     hospitalAccount:'',
     referenceId:'',
     categorySoinsId:'',
     categorySoinsName:'',
     hospitalName:'',
     priceConsultation:'',
     typeName:''
      };

    const [isInput,setInput]=useState<IdataModelDoctor>(initialNamDoctor);

    const [isInputDtos,setInputDtos]=useState<IdataModeConsultationDtos>(props.EditInfoNamDoctor);

    const [IdtypeDoctor,setIdtypeDoctor]=useState(0);
    const [isTypeName,setIsTypeName]=useState('');
    const [IdtypeHospital,setIdtypeHospital]=useState(0);

    const updateIdtypeDoctor = (id: any):void => {
      setIdtypeDoctor(id)
 }
 const updateTypeName= (typeName: any):void => {
  setIsTypeName(typeName)
}

  const updateIdtypeHospital=(id: any):void => {
      setIdtypeHospital(id)
 }
    const handleChange = (e: { target: { name: any; value: any; }; }) => {
      setInputDtos({ ...isInputDtos, [e.target.name]: e.target.value });
    }

   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
         
            
   const EditsubmitButton = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              doctorName: isInputDtos.doctorName,
              prescriptPar:`${isTypeName}/${isInputDtos.doctorName}`,
              priceConsultation:isInputDtos.priceConsultation,
              createdBy:isInput.createdBy,
              dateCreatedBy: isInput.dateCreatedBy
            };
    
            if(IdtypeDoctor===0 && IdtypeHospital===0){

              NamDoctorServices.update(isInputDtos.doctorNameId,data)
            .then((response: any) => {
              setInput({
                id: response.data.doctorNameId,
                doctorName: response.data.doctorName,
                prescriptPar:response.data.prescriptPar,
                priceConsultation:response.data.priceConsultation,
                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);
             
            })
            .catch((e: Error) => {
              console.log(e);
            });
            EditCancel();
            }else{

              NamDoctorServices.updateAll(isInputDtos.doctorNameId,IdtypeDoctor,IdtypeHospital,data)
            .then((response: any) => {
              setInput({
                id: response.data.doctorNameId,
                doctorName: response.data.doctorName,
                prescriptPar:response.data.prescriptPar,
                priceConsultation:response.data.priceConsultation,
                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);

            })
            .catch((e: Error) => {
              console.log(e);
            });
            EditCancel();
            }
          }

          const EditCancel = () => {
            
            setInputDtos(initialNamDoctorDtos);
            setIdtypeDoctor(0);
            setIdtypeHospital(0);
            props.onHide();
          }
       
            
  useEffect(() => {
    setInputDtos(props.EditInfoNamDoctor);
    setIsHospitalDropDown(false)
      },[props])
      
          return(
            <>
        < form  onSubmit={onFormSubmit}>
            <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Nom du Docteur {isInputDtos.doctorName} {isInputDtos.typeName}</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="doctorName" name="doctorName" value={isInputDtos.doctorName} 
                onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Consultation {isInputDtos.priceConsultation} au {isInputDtos.hospitalName}</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="priceConsultation" name="priceConsultation" value={isInputDtos.priceConsultation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        {isHospitalDropDown?(
 <DropdownTypeDoctorHopital 
 typeDoctorId={IdtypeDoctor} 
 typeHospitalId={IdtypeHospital} 
 updateIdtypeDoctor={updateIdtypeDoctor} 
 updateIdtypeHospital={updateIdtypeHospital}
 updateTypeName={updateTypeName}/>
         ):(
          
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Form.Select className="form-control">
   <option value="0">{StructuresData}</option>
</Form.Select>
</Form.Group>
         )}
      
            </Row>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
              
<button type="submit" onClick={EditsubmitButton}  className="me-4 btn btn-success btn-sm btn-block">
        <FaSave className="me-2" />
       update
      </button>
      <button type="reset"onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">
        <FaTimes className="me-2" />
        Cancel
      </button>
           </Form.Group>
    
         </Row>
         </form>

            </>
        );
  }
