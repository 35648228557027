import {  ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import { IDetailsType } from "../Interfaces/IDetailsType";
import DetailsSoinsTypeService from "../Services/DetailsSoinsTypeService";
import IdataModeDetailsTypeDtos from "../Models/IdataModeDetailsTypeDtos";
import { ModelPriceActes } from "../Details/PriceDetailsModel/ModelPriceActes";
import IdataModelHospitalType from "../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import IdataModelPharmacy from "../../../ASSURANCES/StructurePartenaires/Pharmacies/Models/IdataModelPharmacy";
import PharmacyTypeService from "../../../ASSURANCES/StructurePartenaires/Pharmacies/Services/PharmacyTypeService";
import { ModelPriceActesAcceuille } from "../Details/PriceDetailsModel/ModelPriceActesAcceuille";

const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];


const initialTypeCategoryDetails = {// e
  idSoinsDetails:0, // Use wrapper type Long for the primary key to handle null values gracefully
  code: 0,
  intitule: '',
  excl: '',
  acc: '',
  dateCreatedBy: '',

 // typePrestation
 IdTypePrestation: 0,
typePrestationsName: '',
accountType: '',

   // Soins m
  idSoins:0, // Use wrapper type Long for the primary key to handle null values gracefully
  codeSoins: 0, // Changed to Long for consistency and nullability
  intituleSoins: ''};

export const DetailsTableAccueille:React.FC=()=>{
  const [typeHospitalId,setTypeHospitalId]= useState('Choose');

  const [modalShow, setModalShow] = useState(false);
  const [EditTypeCategoryDetails, setEditTypeCategoryDetails] = useState<IdataModeDetailsTypeDtos>(initialTypeCategoryDetails);

  const [hospital, setHospital] = useState(defaultinitiaHospitalType);

    const [details, setdetails] = useState<Array<IdataModeDetailsTypeDtos>>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchdetails, setSearchdetails] = useState<string>("");

    
    const getHospitalWithoutPagination = () => {  
      HospitalTypeService.getAllHospitalNameWithoutPagination()
        .then((response: any) => {
          setHospital(response.data);

        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    
    
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6, 9,12];
    
    const getRequestParams = (searchintitule:string, thepage: number, thepageSize: number) => {
      let params = {
        intitule:searchintitule,
        page: thepage,
        size:thepageSize
      };
    
      if (searchintitule) {
        params["intitule"] = searchintitule;
      }
    
      if (thepage) {
        params["page"] = thepage - 1;
      }
    
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
   

    const onChangeSearchdetails = (e: ChangeEvent<HTMLInputElement>) => {
      const searchdetails = e.target.value;
      setSearchdetails(searchdetails);
    };
    
    const findBydetails = () => {
      DetailsSoinsTypeService.findBySoinsDetailsName(searchdetails)
        .then((response: any) => {
          const {SoinsDetails } = response.data;
          setdetails(SoinsDetails);
          setCurrentIndex(-1);
          console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const retrievedetails = () => {
      const params = getRequestParams(searchdetails, page, pageSize);
    
      DetailsSoinsTypeService.getAll(params)
    
        .then((response: any) => {
    
          const {SoinsDetails , totalPages} = response.data;
    
          setdetails(SoinsDetails);
          setCount(totalPages);
    
          console.log(response.data);
          console.log(params);
    
        })
    
        .catch((e: Error) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrievedetails();
      getHospitalWithoutPagination();
       
    },[searchdetails, page, pageSize, setdetails, setCount]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
    const refreshList = () => {
      retrievedetails();
      setCurrentIndex(-1);
    };
    
    const setActivedetailsPrice = (datadetailsCategory: IdataModeDetailsTypeDtos, index: number) => {

      if(typeHospitalId==='Choose'){
        console.log('halii dganii missing hospitalId') 
     }
      else{
        setCurrentIndex(index); 
        setEditTypeCategoryDetails(datadetailsCategory);
        setModalShow(true);
      }
    };

    const handleChangeTypeHospital = (e: { target: {value: any; }; }) => {
      setTypeHospitalId(e.target.value);
      console.log('typeHospitalId',typeHospitalId);
        }
    const OnDeletedetails = (id:any) => {
    
      DetailsSoinsTypeService.remove(id)
        .then((response: any) => {
          console.log(response.data);
          refreshList();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

      return(
            <>
            <Row className="mb-3 mt-3">
            <div className="d-flex flex-column align-items-start">
            <Stack  direction="horizontal" gap={2}>
                    <Form.Control className="me-auto w-50"
                      placeholder="Filtrez les Soins ou les examens..."
                      value={searchdetails}
                       onChange={onChangeSearchdetails} 
                      />
                  
             </Stack>   
             <Row  className="mb-3">
             <Stack  direction="horizontal" gap={2}>
             {"Items per Page: "}
                  <select onChange={handlePageSizeChange} value={pageSize}>
                    {pageSizes.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                    <Col>
                    <Pagination
                  className="my-3"
                  count={count}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
                    </Col>
             </Stack>
            </Row>
          <Stack  direction="horizontal" gap={2}>
              
            
          <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
  
  <Form.Label>Hopitaux</Form.Label>
  <Form.Select className="form-control" 
   onChange={handleChangeTypeHospital}>
     <option defaultValue={0}>Choose</option>
    {hospital &&
  hospital.map((hospital) => (
      <option key={hospital.id} value={hospital.id} >{hospital.hospitalName}</option>
  ))}
  </Form.Select>
</Form.Group>  
              
                           
              <Form.Group controlId="formBasicEmail" className="col col-sm-3">
     </Form.Group>
                
              
          </Stack>
            </div>
        
            </Row>
         
            <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>Type</th>
                <th>Categories</th>
                <th>intitule</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {details.length>0 ? (
              details.map((i, index) => (
                    <tr key={i.idSoinsDetails }>
                    <td>{i.typePrestationsName}</td>
                    <td>{i.intituleSoins}</td>
                    <td>{i.intitule}</td>
                    <td>
                    <Stack direction="horizontal" gap={2}>
                   

                      <Button className="me-4 btn btn-success btn-sm btn-block"onClick={() => setActivedetailsPrice(i, index)}>Prix</Button>
                    </Stack>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={3}>No details</td>
                </tr>
              )}
    
            </tbody>
          </Table>
          <ModelPriceActesAcceuille
              show={modalShow}
              onHide={() => setModalShow(false)}
              editinfodetailstype={EditTypeCategoryDetails} 
              hospitalid={typeHospitalId} />

    
            </>
        );
    }

