
import { useCallback, useEffect, useState } from "react";
import NewFactureSaisieDto from '../models/Dto/NewFactureSaisieDto';
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import ServicesNewFactureSaisie from "../services/ServiceNewFactureSaisie";
import { Form } from "react-bootstrap";
import { Button, Divider, Stack } from "@mui/material";
import { RootState } from "../../../../Redux/Store/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateFactureMutuelle } from "../../../../Redux/slices/FactureMutuelleDetails/FactureMutuelleDetails";
import RestPlafondYearService from "../../../ASSURANCES/CounterRestPlafond/Services/RestPlafondYearService";



  const defaultList: Array<NewFactureSaisieDto> = [];
  
const columns: GridColDef[] = [

    { field: 'id', headerName: 'ID'  },
    { field: 'prestation', headerName: 'Prestation'  },
    { field: 'pricePrestation', headerName: 'Price '  },

    { field: 'ticketService', headerName: 'Ticket' },
    { field: 'quantity', headerName: 'Quantite' },

    { field: 'partMutuelle', headerName: 'Part Mutuelle' },
    { field: 'partpatient', headerName: 'Part Patient' },

    { field: 'partTotal', headerName: 'Total'   },

    { field: 'ticketServiceFacture', headerName: 'F Ticket ' },
    { field: 'partMutuelleFacture', headerName: 'F Part Mutuelle' },
    { field: 'partpatientFacture', headerName: 'F Part Patient' },
    { field: 'partTotalFacture', headerName: 'F Total'   },

    { field: 'statusFacturePayer', headerName: 'Status' },
    { field: 'typeNameVisite', headerName: 'Type Viste' },
    { field: 'typeBonVerificationName', headerName: 'Verfier' },
    { field: 'typeBonCommendName', headerName: 'Type Bon' },
    { field: 'depertmentName', headerName: 'Depertement' },
    { field: 'dateCreatedSaisie', headerName: 'Created' },
    { field: 'timeSaisie', headerName: 'Time Saisie'  },
    { field: 'plafondFacture', headerName: 'plafond'  },
    
  ];
  const idd: GridRowId[]=[];

let selectedRow:Array<NewFactureSaisieDto> = [];

export const TablePayerFactureMituelle:React.FC=()=>

{

  const [isfactureCouvert, setisfactureCouvert] = useState(0);
  const [isfactureNoCouvert, setisfactureNoCouvert] = useState(0);
  const [isfactureTotal, setisfactureTotal] = useState(0);

  const [isPartMituelle, setisPartMituelle] = useState(0);
  const [isPartPatient, setisPartPatient] = useState(0);
  
  
  const [isNewFactureSaisieDto, setisNewFactureSaisieDto] = useState<Array<NewFactureSaisieDto>>([]);
  const isgetCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);
const dispatchListMituelle=useDispatch();
const [selectedRows, setRowsSelected] = useState(defaultList);
const [selectedIdInRow, setIdRowSelected] = useState(idd);


const findAllFactureByInsuarenceAndReceipt = () => {

  try {
    ServicesNewFactureSaisie.findBySocialinsuranceIdAndReceiptBillStatusFacturePayerMituelle(
      isgetCurrantNewVisitePushed.idinsurance,
      isgetCurrantNewVisitePushed.receiptBill,
      "payer"
    ).then((response:any)=>{

      const facture = response.data;

      setisNewFactureSaisieDto(facture);
    });
  } catch (e) {
    console.log(e);
  }
};

  const AnalyseFactureToPrint = (selectedRowToPrint:any) => {  

    if(selectedRowToPrint.length===0)
{
  setisfactureCouvert(0)
  setisfactureNoCouvert(0)
  setisPartPatient(0)
  setisPartMituelle(0)
  setisfactureTotal(0)
 
 
}else
{

// Create a Map to store the grouped results
const groupedByService = new Map<string, {
total: number,
quantity: number,
FactureCouvert: number,
FactureNocouvert: number,
PartMutuelle: number,
PartPatient: number // New key for PartPatient
}>();

// Iterate through the array and update the Map
selectedRowToPrint.forEach((entry: { service: any; quantity: any; partTotal: any; plafondFacture: any; ticket: any; }) => {
const { service, quantity, partTotal, plafondFacture, ticket } = entry;

if (groupedByService.has(service)) {
    // If the service is already in the Map, update the total and quantity
    const existingEntry = groupedByService.get(service)!;
    existingEntry.total += partTotal;
    existingEntry.quantity += quantity;

    // Check if the total is less than or equal to plafondFacture
    if (existingEntry.total <= plafondFacture) {
        existingEntry.FactureCouvert = existingEntry.total;
        existingEntry.FactureNocouvert = 0;
    } else {
        // If total is greater than plafondFacture, set FactureCouvert to plafondFacture and calculate FactureNocouvert
        existingEntry.FactureCouvert = plafondFacture;
        existingEntry.FactureNocouvert = existingEntry.total - plafondFacture;
    }

    // Calculate PartMutuelle based on FactureCouvert and ticket
    existingEntry.PartMutuelle = (existingEntry.FactureCouvert * (100 - ticket)) / 100;

    // Calculate PartPatient based on FactureCouvert and ticket
    existingEntry.PartPatient = (existingEntry.FactureCouvert * ticket) / 100;
} else {
    // If the service is not in the Map, add a new entry
    const factureCouvert = partTotal <= plafondFacture ? partTotal : plafondFacture;
    const factureNocouvert = partTotal <= plafondFacture ? 0 : partTotal - plafondFacture;

    // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
    const partMutuelle = (factureCouvert * (100 - ticket)) / 100;
    const partPatient = (factureCouvert * ticket) / 100;

    groupedByService.set(service, {
        total: partTotal,
        quantity: quantity,
        FactureCouvert: factureCouvert,
        FactureNocouvert: factureNocouvert,
        PartMutuelle: partMutuelle,
        PartPatient: partPatient
    });
}
});

// Calculate the total, FactureCouvert, FactureNocouvert, PartMutuelle, and PartPatient for all services
let totalAllServices = 0;
let factureCouvertAllServices = 0;
let factureNocouvertAllServices = 0;
let partMutuelleAllServices = 0;
let partPatientAllServices = 0;

groupedByService.forEach(({ total, FactureCouvert, FactureNocouvert, PartMutuelle, PartPatient }) => {
totalAllServices += total;
factureCouvertAllServices += FactureCouvert;
factureNocouvertAllServices += FactureNocouvert;
partMutuelleAllServices += PartMutuelle;
partPatientAllServices += PartPatient;
});

console.log("Total All Services:", totalAllServices);
console.log("FactureCouvert All Services:", factureCouvertAllServices);
console.log("FactureNocouvert All Services:", factureNocouvertAllServices);
console.log("PartMutuelle All Services:", partMutuelleAllServices);
console.log("PartPatient All Services:", partPatientAllServices);


 setisfactureTotal(totalAllServices)
 setisfactureCouvert(factureCouvertAllServices)
 setisfactureNoCouvert(factureNocouvertAllServices)
 setisPartMituelle(partMutuelleAllServices)
 setisPartPatient(partPatientAllServices)


   // console.log('factureCouvert',isfactureCouvert);
   // console.log('factureNoCouvert',isfactureNoCouvert);
   // console.log('factureTotal',isfactureTotal);
   // console.log('Quantite Total',isQuantity);
    
   // console.log('part Mutuelle',isPartMituelle);
   // console.log('part patient',isPartPatient);
}

    };

      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      return (
        
        
        <>

      <Button onClick={() => {findAllFactureByInsuarenceAndReceipt()}} variant="contained" color="warning">
         Actualise
      </Button>

      <div style={{ height: 400, width: '100%' }}>
     
      <DataGrid
      autoHeight
            rows={isNewFactureSaisieDto}
            getRowId={(row) => row.idFacture}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel:{page:0,pageSize:10},
              },
            }}
            pageSizeOptions={[10,15,25,35,45]}
            checkboxSelection
            
            onRowSelectionModelChange={(ids) => {

              const selectedIDs = new Set(ids);
              selectedRow = isNewFactureSaisieDto.filter((row) => selectedIDs.has(row.idFacture),);
             
              setIdRowSelected(ids)
            setRowsSelected(selectedRow)
           AnalyseFactureToPrint(selectedRow)
          dispatchListMituelle(updateFactureMutuelle(selectedRow));
           console.log(selectedRow)
            }
          }
           
          />
     </div>
          <Stack
  direction="row"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}
>
              <Form.Label><strong>Total de Facture</strong>:{isfactureTotal}</Form.Label> 
              <Form.Label><strong>Part Patient</strong>:{isPartPatient}</Form.Label> 
              <Form.Label><strong>Part Mituelle</strong>:{isPartMituelle}</Form.Label> 
              <Form.Label><strong>Facture couvert</strong>:{isfactureCouvert}</Form.Label> 
              <Form.Label><strong>Facture No couvert</strong>:{isfactureNoCouvert}</Form.Label> 
</Stack>

        </>
        
      );
    }