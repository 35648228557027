import { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import IPlafondYearTypeVisite from '../Models/PlafondYearTypeVisite';
import IdataModelCategoryCode from '../../Categories/CategoriiesCode/Models/IdataModelCategoryCode';
import IdataModeEnterprise from '../../Entreprises/Models/IdataModeEnterprise';
import IdataModelTypeBons from '../../Bons/Models/IdataModelTypeBons ';
import TypeBonsService from '../../Bons/Services/TypeBonsService';
import EnterpriseService from '../../Entreprises/Services/EnterpriseService';
import PlafondYearTypeVisiteService from '../Services/PlafondYearTypeVisiteService';
import CategoryAffilieTypeService from '../../Categories/CategoriesAffilies/Services/CategoryAffilieTypeService';

const initialTypeState = {
  id: null,
  plafondYear:0,
  plafondYearAlert:0,
  enfant:0,
  prime:0,
  cotization:0,
  etatCivil:'',
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};

const defaultcode:Array<IdataModelCategoryCode>=[];
const defaultenterprise:Array<IdataModeEnterprise>=[];

const defaulttypeVisite: Array<IdataModelTypeBons> = [];

export const AddPlafondYearTypeVisite:React.FC=()=> {

  const [code, setcode] = useState(defaultcode);

  const [enterprise, setenterprise] = useState(defaultenterprise);


const [isInput,setInput]=useState<IPlafondYearTypeVisite>(initialTypeState);

const [typeVisite, settypeVisite] = useState(defaulttypeVisite);

const [isTypeVisite, setisTypeVisite] = useState(0);

const [isselectedEnterprise, SetselectedEnterprise] = useState(0);

const [isselectedCategory, SetselectedCategory] = useState(0);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const handleChangeSelected = (event: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setInput({ ...isInput, [name]: value });
      };
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      

const getAllWithoutPaginationCode = () => {
  
 CategoryAffilieTypeService.getAllTypesoinsWithoutPagination()
    .then((response: any) => {
      setcode(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const getAllWithoutPaginationEnterprise = () => {
  
  EnterpriseService.getAllEnterpriseNameWithoutPagination()
    .then((response: any) => {
      setenterprise(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
const getAllTypeVisite = () => {
  
  TypeBonsService.getAllTypeWithoutPagination()
    .then((response: any) => {
      settypeVisite(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

useEffect(() => {
  getAllWithoutPaginationCode();
  getAllWithoutPaginationEnterprise();
  getAllTypeVisite()
});
      const saveType = () => {  

        let data = {
          plafondYear:isInput.plafondYear,
          plafondYearAlert:isInput.plafondYearAlert,
          enfant:isInput.enfant,
          prime:isInput.prime,
          cotization:isInput.cotization,
          etatCivil:isInput.etatCivil,
          createdBy:'ghost zila',
          dateCreatedBy: new Date()
        };

        PlafondYearTypeVisiteService.create(52,isselectedEnterprise,isselectedCategory,isTypeVisite, data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            plafondYear: response.data.plafondYear,
            plafondYearAlert:response.data.plafondYearAlert,
            enfant:response.enfant,
            prime:response.prime,
            cotization:response.cotization,
            etatCivil:response.etatCivil,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }
      const handleChangeTypeVisite = (event: { target: { value: SetStateAction<any>; }; }) => {
        setisTypeVisite(event.target.value);
      };
      
 const handleChangeEnterprise= (e: { target: {value: any; }; }) => {
    SetselectedEnterprise(e.target.value);
    }
    const handleChangeCategory= (e: { target: {value: any; }; }) => {
      SetselectedCategory(e.target.value);
      }
      
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
      
<Form.Group controlId="formBasicEmail" className="col col-sm-3">
    <Form.Label>Cotization</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="cotization" value={isInput.cotization} onChange={handleChange} />
            </InputGroup>
</Form.Group>
      
<Form.Group controlId="formBasicEmail" className="col col-sm-3">
    <Form.Label>Enfant</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="enfant" value={isInput.enfant} onChange={handleChange} />
            </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicEmail" className="col col-sm-3">
    <Form.Label>Prime</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="prime" value={isInput.prime} onChange={handleChange} />
            </InputGroup>
</Form.Group>


<Form.Group controlId="formBasicEmail" className="col col-sm-3">
    <Form.Label>Plafond Anuelle Alert</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="plafondYearAlert" value={isInput.plafondYearAlert} onChange={handleChange} />
            </InputGroup>
</Form.Group> 

<Form.Group controlId="formBasicEmail" className="col col-sm-3">
    <Form.Label>Plafond Anuelle</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="plafondYear" value={isInput.plafondYear} onChange={handleChange} />
            </InputGroup>
</Form.Group>
      
        </Row>

        <Row className="mb-3">
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Etat Civil</Form.Label>
            <Form.Select  size="sm" name="etatCivil" value={isInput.etatCivil} onChange={handleChangeSelected}>
                <option>Choisir </option>
               <option value="yes">yes</option>
             <option value="No">no</option>
    </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
   <Form.Label>Entreprise</Form.Label>
    <Form.Select className="form-control"  size="sm"  
     onChange={handleChangeEnterprise}>
       <option value="0">Choose..</option>
      {enterprise &&
    enterprise.map((enterprise) => (
        <option key={enterprise.id} value={enterprise.id} >{enterprise.enterpriseName}</option>
    ))}
        
    </Form.Select>
</Form.Group>
        
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Code Category</Form.Label>
    <Form.Select className="form-control"  size="sm" 
     onChange={handleChangeCategory}>
       <option value="0">Choose..</option>
      {code &&
    code.map((code) => (
        <option key={code.id} value={code.id} >{code.categoryName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
   
<Form.Group controlId="formBasicEmail" className="col col-sm-3">
<Form.Label>Type Visite</Form.Label>
<Form.Select className="form-control"  size="sm"
onChange={handleChangeTypeVisite}>
<option value="0">Choose..</option>
{typeVisite &&
typeVisite.map((typeVisite) => (
<option key={typeVisite.id} value={typeVisite.id} >{typeVisite.typeName}</option>
))}

</Form.Select>
</Form.Group>
        </Row>
   


         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>saveType()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}