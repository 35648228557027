import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import IdataModeConsultationDtos from "../../../Components/Doctor/Names/Models/IdataModelConsultationDtos";
import { TableFactureSaisie } from "../../../Components/Espace_Patients/FactureSaisie/Tables/TableFactureSaisie";
import NewFactureSaisieDto from "../../../Components/Espace_Patients/FactureSaisie/models/Dto/NewFactureSaisieDto";

const initialNamDoctorDtos = {
  accountNameDoctor:'',
   accountTypeDoctor:'',
   doctorName:'',
   
   prescriptPar:'',
   doctorNameId:'',
   doctorTypeId:'',
   hospitalId:'',
   typehospital:'',
   hospitalAccount:'',
   referenceId:'',
   categorySoinsId:'',
   categorySoinsName:'',
   hospitalName:'',
   priceConsultation:'',
   typeName:''
    };
    const NewFactureSaisieDtoinit ={
    
      /// facture fa
      idFacture:'',
      prestation:'', //itule

  prestationPrescriptPar:'',
      pricePrestation:'',
      plafondFacture:'',
      ticketService:'',
      quantity:'',
      partMutuelle:'',
      partpatient:'',
      partTotal:'',
      statusFacturePayer:'',
      createdBy:'',
      dateCreatedSaisie:'',
      timeSaisie:'',


     partMutuelleFacture:0,
     partpatientFacture:0,
     partTotalFacture:0,
     
     ticketServiceFacture:0,

     statusFactureDeclare:'',
     statusFactureVerifier:'',
     statusFactureRembourceAvecAutreFacture:'',
     statusFactureRembourceUneFacture:'',

     /// hospital h
      idhospital:'',
      hospitalName:'',
      referenceId:'',
      typehospital:'',
      hospitalAccount:'',
  
     /// docteur
      idDoctorName:'',
      
    prescriptPar:'',
      doctorName:'',
       priceConsultation:'',
      accountNameDoctor:'',
  
     /// typeDoctor
     idtypeDoctor:'',
     typedoctorName:'',
     accountTypeDoctor:'',
     /// couvertureConfig  se
     idCouvertureConfig:'',
      ticket:'',
      particulie:'',
      access:'',
      secter:'',
      plafondOrdonence:'',

    // services ser
    idService:'',
    serviceName:'',
   // code_category co

   idCategoryCode:'',
   categoryCode:'',
   

  /// new visite
      idNewVisite:'',
      receiptBill:'',
      patientName:'',
      typeAssuree:'',
     patientCode:'',
     newVisitecreatedBy:'',
      dateCreatedVisite:'',
      timeCreated:'',
  
     //social Insuarance so
      idinsurance:'',
      insuranceName:'',
     //////////////////////////////////////////
     /// Enterprise   e
      idEnterprise:'',
      accountEnterprise:'',
      enterpriseName:'',
      couvertureDu:'',
      couvertureAau:'',
      modePayment:'',
      frequancePayment:'',
     /// pmember   p
      idpmember:'',
      codeAssuree:'',
      nomPrenomAffilie:'',
      cotisationExact:'',
      accountPmember:'',
      catégorieSoins:'',
      category_code:'',
     ///// typeBons Visite  tvi
      idVisite:'',
      typeNameVisite:'',
      accountVisite:'',
  
     ///// typeBon declaration verifivcation rembourcement b
      idtypeBonverification:'',
      typeBonVerificationName:'',
      accountVerificationName:'',
     /// typeBon Commend  tc
      idTypeBonCommend:'',
      typeBonCommendName:'',
      accountBonCommend:'',
     /// depertment dp
      idDepertment:'',
      depertmentName:'',

/// plafondYear pla
plafondYearId:null,
plafondYear:null,
/// category c
categoryId:null,
categoryName:null,

idRestIncrementPlafondConsomme:null
  
  }

export const ListeVisiteEncours=()=>{

  const [pushedDoctors, setpushedDoctors] = useState<IdataModeConsultationDtos>(initialNamDoctorDtos);
  
  const [pushedVisite, setpushedVisite] = useState<NewFactureSaisieDto>(NewFactureSaisieDtoinit);

  const onCurrantDoctor=(Data:IdataModeConsultationDtos)=>{
      setpushedDoctors(Data)
  }
 
    return (
        
      <>
      <Container fluid>
     
        <Card border="secondary" >
          <Card.Body>
            <TableFactureSaisie />  
            </Card.Body>
        </Card>
      </Container>
  
      </>
      
);
}