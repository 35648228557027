import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import IdataModelHospitalType from "../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";

import { IfactureTotalinOne } from "../interfaces/IfactureTotalinOne";
import FactureTotalInOneDto from "../model/Dto/FactureTotalInOneDto";
import { ModalFacturesServie } from "../../FactureServie/Modals/ModalFacturesServie";
import ServicesFactureTotalInOne from "../services/ServiceFactureTotalInOne";
import { RiSearch2Line } from "react-icons/ri";
import { IoIosDownload } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";


 
import React from "react";
import Excel from 'exceljs';
import saveAs from "file-saver";

const FactureTotalInOneDtoint = {
    

    idNewVisite:'',
    receiptBill:'',
    patientName:'',
    typeAssuree:'',
    patientCode:'',
    CodeSoins:'',
    newVisitecreatedBy:'',
    dateCreatedVisite:'',
    timeCreated:'',

   //social Insuarance so
    idinsurance:'',
    insuranceName:'',
   /// Enterprise   e
    idEnterprise:'',
    accountEnterprise:'',
    enterpriseName:'',
    couvertureDu:'',
    couvertureAau:'',
    modePayment:'',
    frequancePayment:'',

   /// pmember   p
    idpmember:'',
    codeAssuree:'',
    nomPrenomAffilie:'',
    cotisationExact:'',
    accountPmember:'',
    CatégorieSoins:'',

   ///// typeBons Visite  tvi to delete
   ///// typeBon declaration verifivcation rembourcement b to delete
   /// typeBon Commend  tc to delete
   /// depertment dp  to delete

   ///////////////////////
   // track visite k

   /// FactureTotalInOne q
    idFactureInOne:'',
    totalFactureInOne:'',
    totalFactureInOneCouvert:'',
    totalFactureInOneNoCouvert:'',
    totalInOnePartPatient:'',
    totalInOnePartSolis:'',

    totalInOnePartPatientFacture:'',
    totalInOnePartSolisFacture:'',
    inOnecreatedBy:'',
    inOnedateCreated:'',
    inOnetimeCreated:'',
    mois:' ',
   /// hospital h

    idhospital:'',
    hospitalName:'',
    referenceId:'',
    typehospital:'',
    hospitalAccount:'',
     /// couvertureConfig  se
    idCouvertureConfig:'',
      ticket:'',
      particulie:'',
      access:'',
      secter:'',
      plafondOrdonence:'',

    // services ser
    idService:'',
    serviceName:'',
   // code_category co

   idCategoryCode:'',
   categoryCode:'',
   

     /// depertment dp
     idDepertment:'',
      depertmentName:'',
}
    const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];

    let MonStructure:string='';
export const TableFactureTotalinOne:React.FC=()=>{
      

const [isFactureTotalInOne, setisFactureTotalInOne] = useState<Array<FactureTotalInOneDto>>([]);

const [isFactureTotalInOneBetween, setisFactureTotalInOneBetween] = useState<Array<FactureTotalInOneDto>>([]);
const [currentIndex, setCurrentIndex] = useState<number>(-1);
const [PushCarrantisFactureTotalInOne, setPushCarrantisFactureTotalInOne] = useState<FactureTotalInOneDto>(FactureTotalInOneDtoint);

const [searchReceiptBill, setsearchReceiptBill] = useState<string>("");
const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);

const [modalShow, setModalShow] = useState(false);
const [hospital, setHospital] = useState(defaultinitiaHospitalType);
const [HospitalName, setHospitalName] = useState<string>("");

const [groupedDataBETWEEN, setGroupedDataBETWEEN] = useState<Map<string, FactureTotalInOneDto[]>>(new Map());

const [groupedData, setGroupedData] = useState<Map<string,FactureTotalInOneDto[]>>(new Map());

const [isHospitalDropDown,setIsHospitalDropDown]=useState(true);

const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];
const [startDate, setStartDate] = useState("");

const [endDate, setEndDate] = useState("");

const getRequestParamsALL = 
    (

     hospitalName:any,
     searchReceiptBill:any,
     thepage: number, 
     thepageSize: number) => {

  let params = {

    hospitalName:hospitalName,
    receiptBill:searchReceiptBill,
    page: thepage,
    size:thepageSize
  };

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }
  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};

const getRequestParamsByStructureBetweenDate = 
    (
     hospitalName:string,
     startDate:string,
     endDate:string) => {

  let params = {
    receiptBill:searchReceiptBill,
    hospitalName:hospitalName,
    startDate:startDate,
    endDate:endDate
  };

  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }
  if (startDate) {
    params["startDate"] = startDate;
  }
  if (endDate) {
    params["endDate"] = endDate;
  }

  return params;
};
const handleChangeTypeHospital = (e: { target: {value: any; }; }) => {
  setHospitalName(e.target.value);
    }
    const getHospitalWithoutPagination = () => {  
      HospitalTypeService.getAllHospitalNameWithoutPagination()
        .then((response: any) => {
          setHospital(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

    const onChangesearchReceiptBill = (e: ChangeEvent<HTMLInputElement>) => {
      const searchReceiptBill = e.target.value;
      setsearchReceiptBill(searchReceiptBill);
    };
    
    const findAllFacture = () => {
      

      let valueSwip=" "

      if(MonStructure.length>0){
        console.log("AA")
                    valueSwip=MonStructure
                }else{
        console.log("bb")
                    valueSwip=HospitalName
                }
      const params = getRequestParamsALL( valueSwip,searchReceiptBill, page, pageSize);
    
        ServicesFactureTotalInOne.findBySocialinsuranceIdHospitalNamePage(params)
        .then((response: any) => {
          const {findAll} = response.data;
          setisFactureTotalInOne(findAll);
          setCurrentIndex(-1);
          console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    
    };


const calculateGroupSums = (groupItems: FactureTotalInOneDto[]): { partMutuelleFactureSum: number, partpatientFactureSum: number, partTotalFactureSum: number } => {
  let partMutuelleFactureSum = 0;
  let partpatientFactureSum = 0;
  let partTotalFactureSum = 0;

  groupItems.forEach((facture) => {
    partMutuelleFactureSum += facture.totalInOnePartSolisFacture;
    partpatientFactureSum += facture.totalInOnePartPatientFacture;
    partTotalFactureSum += facture.totalFactureInOne;
  });

  return { partMutuelleFactureSum, partpatientFactureSum, partTotalFactureSum };
};

    const retrieveInfo = () => {


        let valueSwip=" "

      if(MonStructure.length>0){
        console.log("AA")
                    valueSwip=MonStructure
                }else{
        console.log("bb")
                    valueSwip=HospitalName
                }
      const params = getRequestParamsALL( valueSwip,searchReceiptBill, page, pageSize);
    
      ServicesFactureTotalInOne.findBySocialinsuranceIdHospitalNamePage(params)
  
        .then((response: any) => {
    
          const {findAll , totalPages} = response.data;
    
          setisFactureTotalInOne(findAll);
          setCount(totalPages);
          
          console.log(response);

        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
useEffect(() => {
  retrieveInfo();
  getHospitalWithoutPagination();
},[searchReceiptBill, page, pageSize, setisFactureTotalInOne, setCount]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };



const setpPushNewVisite = (dataNouvelleVisite: FactureTotalInOneDto, index: number) => {
   setCurrentIndex(index); 

   setPushCarrantisFactureTotalInOne(dataNouvelleVisite);
   setModalShow(true);
};

const trigerDropDownState = useCallback(() => {
  setIsHospitalDropDown(() => {
    if (StructuresData.length === 1) {
      let concatenatedString: string = StructuresData.join(", ");
      MonStructure = concatenatedString;
      return true;
    } else {
      return false;
    }
  });
}, [StructuresData]);

useEffect(() => {
  trigerDropDownState()
},[StructuresData]);


const totalReceiptBill = isFactureTotalInOne.length;
const totalParTotal = isFactureTotalInOne.reduce((acc: any, item: { totalFactureInOneCouvert: any; }) => acc + item.totalFactureInOneCouvert, 0);
const totalParPatient = isFactureTotalInOne.reduce((acc: any, item: { totalInOnePartPatientFacture: any; }) => acc + item.totalInOnePartPatientFacture, 0);
const totalpartMutuelle = isFactureTotalInOne.reduce((acc: any, item: { totalInOnePartSolisFacture: any; }) => acc + item.totalInOnePartSolisFacture, 0);


const totalReceiptBillBetween = isFactureTotalInOneBetween.length;
const totalParTotalBetween = isFactureTotalInOneBetween.reduce((acc: any, item: { totalFactureInOneCouvert: any; }) => acc + item.totalFactureInOneCouvert, 0);
const totalParPatientBetween = isFactureTotalInOneBetween.reduce((acc: any, item: { totalInOnePartPatientFacture: any; }) => acc + item.totalInOnePartPatientFacture, 0);
const totalpartMutuelleBetween = isFactureTotalInOneBetween.reduce((acc: any, item: { totalInOnePartSolisFacture: any; }) => acc + item.totalInOnePartSolisFacture, 0);

const exportToExcel = () => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('@ALLY_DECLARATION');
  worksheet.properties.tabColor = { argb: 'FF0000FF' }; // Set tab color to blue
 
  // Add header row
  const headerRow = worksheet.addRow([
    'Time', 'Date Created', 'Bon', 'Adherent', 'Code', 'Enterprise', 'Beneficieres', 
    'total','total couvert','total no couvert', 'part patient', 'part mutuelle',
    'Depertment', 'Structure'
  ]);


  // Grouped data iteration
  groupedData.forEach((groupItems) => {
    // Add empty row for visual separation
  //  worksheet.addRow([]);
    // Add rows for individual items in the group
    groupItems.forEach((facture) => {
        worksheet.addRow([

            facture.inOnedateCreated,
            facture.inOnedateCreated,
            facture.receiptBill,
            facture.nomPrenomAffilie,
            facture.codeAssuree,
            facture.enterpriseName,
            facture.patientName,
            facture.totalFactureInOne,
            facture.totalFactureInOneCouvert,
            facture.totalFactureInOneNoCouvert,
            facture.totalInOnePartPatientFacture,
            facture.totalInOnePartSolisFacture,
            facture.depertmentName,
            facture.hospitalName
        ]);
    });

      // Add empty row for visual separation between groups
     // worksheet.addRow([]);
      // Calculate and add total row
      const groupSums = calculateGroupSums(groupItems);
      const totalRow =  worksheet.addRow([
        'Facture total Par Bon', '','', '', '', '', 'Total',
        groupSums.partTotalFactureSum,
        '',
        '',
        groupSums.partpatientFactureSum, 
        groupSums.partMutuelleFactureSum,
    ]);
     // Apply style to the total row
     totalRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFECFCEB' } // Green color
  };
  totalRow.eachCell((cell) => {
    cell.font = { color: { argb: 'FF008000' } }; // Green text color
});
});
 // Add empty row for visual separation
    worksheet.addRow([]);

 // Calculate and add total row
 const totalRow =  worksheet.addRow([
     'DECLARATION', '','', totalReceiptBill, '', '', 'Total A declare',
       totalParTotal,
       '',
       '',
       totalParPatient, 
       totalpartMutuelle,
   ]);
    // Apply style to the total row
    totalRow.fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: 'FFECFCEB' } // Green color
 };
 totalRow.eachCell((cell) => {
   cell.font = { color: { argb: 'FF008000' } }; // Green text color
});

  // Auto-fit column widths based on content
  worksheet.columns.forEach((column) => {
    if (column && column.eachCell) { // Check if column and column.eachCell are not undefined
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            let columnLength = 0;
            if (cell.value instanceof Date) {
                columnLength = cell.value
                    .toISOString()
                    .slice(0, 10)
                    .replace(/-/g, '.').length;
            } else {
                columnLength = String(cell.value).length;
            }
            if (columnLength > dataMax) {
                dataMax = columnLength;
            }
        });
        column.width = dataMax < 10 ? 15 : dataMax + 8;
    }
});

  // Save the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, '@ALLY_DECLARATION.xlsx');
  });
};

const exportToExcelBETWEEN = () => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('@ALLY_DECLARATION');
  worksheet.properties.tabColor = { argb: 'FF0000FF' }; // Set tab color to blue
 
  // Add header row
  const headerRow = worksheet.addRow([
    'Time', 'Date Created', 'Bon', 'Adherent', 'Code', 'Enterprise', 'Beneficieres', 
    'total','total couvert','total no couvert', 'part patient', 'part mutuelle',
    'Depertment', 'Structure'
  ]);


  // Grouped data iteration
  groupedDataBETWEEN.forEach((groupItems) => {
    // Add empty row for visual separation
  //  worksheet.addRow([]);
    // Add rows for individual items in the group
    groupItems.forEach((facture) => {
        worksheet.addRow([

            facture.inOnedateCreated,
            facture.inOnedateCreated,
            facture.receiptBill,
            facture.nomPrenomAffilie,
            facture.codeAssuree,
            facture.enterpriseName,
            facture.patientName,
            facture.totalFactureInOne,
            facture.totalFactureInOneCouvert,
            facture.totalFactureInOneNoCouvert,
            facture.totalInOnePartPatientFacture,
            facture.totalInOnePartSolisFacture,
            facture.depertmentName,
            facture.hospitalName
        ]);
    });

      // Add empty row for visual separation between groups
     // worksheet.addRow([]);
      // Calculate and add total row
      const groupSums = calculateGroupSums(groupItems);
      const totalRow =  worksheet.addRow([
        'Facture total Par Bon', '','', '', '', '', 'Total',
        groupSums.partTotalFactureSum,
        '',
        '',
        groupSums.partpatientFactureSum, 
        groupSums.partMutuelleFactureSum,
    ]);
     // Apply style to the total row
     totalRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFECFCEB' } // Green color
  };
  totalRow.eachCell((cell) => {
    cell.font = { color: { argb: 'FF008000' } }; // Green text color
});
});
 // Add empty row for visual separation
    worksheet.addRow([]);

 // Calculate and add total row
 const totalRow =  worksheet.addRow([
     'DECLARATION', '','', totalReceiptBillBetween, '', '', 'Total A declare',
       totalParTotalBetween,
       '',
       '',
       totalParPatientBetween, 
       totalpartMutuelleBetween,
   ]);
    // Apply style to the total row
    totalRow.fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: 'FFECFCEB' } // Green color
 };
 totalRow.eachCell((cell) => {
   cell.font = { color: { argb: 'FF008000' } }; // Green text color
});

  // Auto-fit column widths based on content
  worksheet.columns.forEach((column) => {
    if (column && column.eachCell) { // Check if column and column.eachCell are not undefined
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            let columnLength = 0;
            if (cell.value instanceof Date) {
                columnLength = cell.value
                    .toISOString()
                    .slice(0, 10)
                    .replace(/-/g, '.').length;
            } else {
                columnLength = String(cell.value).length;
            }
            if (columnLength > dataMax) {
                dataMax = columnLength;
            }
        });
        column.width = dataMax < 10 ? 15 : dataMax + 8;
    }
});

  // Save the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, '@ALLY_DECLARATION.xlsx');
  });
};

const exportToExcelLogic = () => {

  if(startDate==="" && endDate===""){
  exportToExcel()
  
  }else{
  exportToExcelBETWEEN()
  };
  
  };


const findAllFactureDeclareBetween = () => {

  if(HospitalName===''){

    let concatenatedString: string = StructuresData.join(", ");

    const params = getRequestParamsByStructureBetweenDate(concatenatedString,startDate, endDate);

    ServicesFactureTotalInOne.findBySocialinsuranceIdHospitalNameBetweenDateServieList(params)
    .then((response: any) => {

      setisFactureTotalInOneBetween(response.data);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  }else{
    const params = getRequestParamsByStructureBetweenDate(HospitalName,startDate, endDate);
    
    ServicesFactureTotalInOne.findBySocialinsuranceIdHospitalNameBetweenDateServieList(params)
    .then((response: any) => {

      setisFactureTotalInOneBetween(response.data);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });

  }
  
};


useEffect(() => {
  // Update groupedData whenever fetched data changes
  const groupDataBETWEEN = () => {
    const grouped = new Map<string, FactureTotalInOneDto[]>();
    isFactureTotalInOneBetween.forEach((facture) => {
      const key = `${facture.inOnedateCreated} -  ${facture.enterpriseName}  -  ${facture.receiptBill}  -  ${facture.patientName}`;
      const existingGroup = grouped.get(key) || [];
      existingGroup.push(facture);
      grouped.set(key, existingGroup);
    });
    setGroupedDataBETWEEN(grouped);
  };

  groupDataBETWEEN();
}, [isFactureTotalInOneBetween]);


useEffect(() => {
  // Update groupedData whenever fetched data changes
  const groupData = () => {
    const grouped = new Map<string, FactureTotalInOneDto[]>();
    isFactureTotalInOne.forEach((facture) => {
      const key = `${facture.inOnedateCreated} -  ${facture.enterpriseName}  -  ${facture.receiptBill}  -  ${facture.patientName}`;
      const existingGroup = grouped.get(key) || [];
      existingGroup.push(facture);
      grouped.set(key, existingGroup);
    });
    setGroupedData(grouped);
  };

  groupData();
}, [isFactureTotalInOne]);

  return(
        <>
        <Row className="mb-3">
      
        <div className="d-flex flex-column align-items-start">
      
      <Stack  direction="horizontal" gap={2}>
       <Form.Control className="me-auto"
                placeholder="Filtre le patient ou Numero bon"
                value={searchReceiptBill}
                onChange={onChangesearchReceiptBill} 
               />

<Button variant="outline-dark btn-sm"
  onClick={findAllFacture}>Cherchez</Button>
               
      DU
       <Form.Group controlId="formBasicStartDate" className="col col-sm-3">
              <InputGroup>
                  <Form.Control
                      size="sm"
                      type="date"
                      value={startDate}
                      onChange={e => setStartDate(e.target.value)}
                  />
              </InputGroup>
          </Form.Group>
          AU
          <Form.Group controlId="formBasicEndDate" className="col col-sm-3">
              <InputGroup>
                  <Form.Control
                      size="sm"
                      type="date"
                      value={endDate}
                      onChange={e => setEndDate(e.target.value)}
                  />
              </InputGroup>
          </Form.Group>
               <Button variant="outline-dark" size="sm" onClick={findAllFactureDeclareBetween}>
              <RiSearch2Line />
          </Button>

          <Button variant="outline-dark" size="sm" onClick={exportToExcelLogic}>
              <IoIosDownload /> 
          </Button>

      </Stack>
    <Stack  direction="horizontal" gap={2}>
    {"Page:"}
            <select onChange={handlePageSizeChange} value={pageSize}>
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          <Pagination
            className="my-3"
            count={count}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />         
{!isHospitalDropDown?(

<Form.Group controlId="formGridCheckbox" className="col col-sm-6">

<Stack  direction="horizontal" gap={2}>

<Form.Select className="form-control" 
onChange={handleChangeTypeHospital}>
 <option value="0">select hopital..</option>
{hospital &&
hospital.map((hospital) => (
  <option key={hospital.id} value={hospital.hospitalName} >{hospital.hospitalName}</option>
))}
  
</Form.Select>

<Button variant="outline-dark" size="sm" onClick={findAllFacture}>
              <RiSearch2Line />
          </Button>

</Stack>   
</Form.Group>          

       ):(
        
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Form.Select className="form-control" 
onChange={handleChangeTypeHospital}>
 <option value="0">{MonStructure}</option>
</Form.Select>
</Form.Group>
       )}
       </Stack>   
       <Stack direction="horizontal" gap={2}>
        
      </Stack>   
</div>  
        <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>Enterprise</th>
            <th>Code</th>
            <th>Patient</th>

            <th>tatal</th>
            <th>total Couvert</th>
            <th>total No Couvert</th>
            <th>Part patient</th>
            <th>Part Mutuelle</th>
            
            <th>Structure</th>
            <th>Depertment</th>
            <th>Bon</th>
            <th>visite</th>
            <th>Temp</th>
          </tr>
        </thead>
        <tbody>
        {isFactureTotalInOne.length>0 ? (
          isFactureTotalInOne.map((i, index) => (
                <tr key={i.idFactureInOne }>
                <td>{i.enterpriseName}</td>
                <td>{i.codeAssuree}</td>

                <td>{i.patientName}</td>

                <td>{i.totalFactureInOne}</td>
                <td>{i.totalFactureInOneCouvert}</td>
                <td>{i.totalFactureInOneNoCouvert}</td>             
                <td>{i.totalInOnePartPatientFacture}</td>
                <td>{i.totalInOnePartSolisFacture}</td>
              
                <td>{i.hospitalName}</td>

                <td>{i.depertmentName}</td>
                <td>{i.receiptBill}</td>
                <td>{i.inOnedateCreated}</td>
                <td>{i.inOnetimeCreated}</td>
               
              </tr>
            ))
          ) : ( 
            <tr>
              <td colSpan={3}>no Type </td>
            </tr>
          )}

        </tbody>
      </Table>
      <ModalFacturesServie
          show={modalShow}
          onHide={() => setModalShow(false)}
          CurrentFactureTotalInOneDto={PushCarrantisFactureTotalInOne}
             />

          </Row>

        </>
    );
}