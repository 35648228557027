import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewFactureSaisieMedicamentDto from '../../../Components/Espace_Patients/FactureMedicament/models/Dto/NewFactureSaisieMedicamentDto';

const initialState: NewFactureSaisieMedicamentDto[] = [];

const currentMedicalArray = createSlice({
  name: 'currentMedicalArray',
  initialState,
  reducers: {                                        
    addMedicalRecord: (state, action: PayloadAction<NewFactureSaisieMedicamentDto>) => {
      state.push(action.payload);
    },
    addMultipleMedicalRecords: (state, action: PayloadAction<NewFactureSaisieMedicamentDto[]>) => {
        state.push(...action.payload);
      },
    updateMedicalRecord: (state, action: PayloadAction<NewFactureSaisieMedicamentDto>) => {
      const index = state.findIndex(record => record.idFacture === action.payload.idFacture);
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
    deleteMedicalRecord: (state, action: PayloadAction<any | null>) => {
      return state.filter(record => record.idFacture !== action.payload);
    },
    clearMedicalRecords: () => {
      return initialState;
    },
  },
});

export const { addMedicalRecord, addMultipleMedicalRecords,updateMedicalRecord, deleteMedicalRecord, clearMedicalRecords } = currentMedicalArray.actions;
export default currentMedicalArray.reducer;
