import {  ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import { Button, Form, Row, Stack, Table } from "react-bootstrap";
import { IHospitalType } from "../Interfaces/IHospitalType";
import IdataModelHospitalType from "../Models/IdataModeHospitalType";
import HospitalTypeService from "../Services/HospitalTypeService";
import IdataModelHospitalCategoryDtos from "../Models/IdataModelHospitalCategoryDtos";


export const HospitalTable:React.FC<IHospitalType>=props=>{
    

    const [Hospital, setHospital] = useState<Array<IdataModelHospitalCategoryDtos>>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchHospital, setSearchHospital] = useState<string>("");
    
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6, 9,12];
    
    const getRequestParams = (searchhospitalName:string,socialinsuranceId:any, thepage: number, thepageSize: number) => {
      let params = {
        socialinsuranceId:socialinsuranceId,
        hospitalName:searchhospitalName,
        page: thepage,
        size:thepageSize
      };

        if (socialinsuranceId) {
          params["socialinsuranceId"] = socialinsuranceId;
        }
      if (searchhospitalName) {
        params["hospitalName"] = searchhospitalName;
      }
    
      if (thepage) {
        params["page"] = thepage - 1;
      }
    
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
    
    const onChangeSearchHospital = (e: ChangeEvent<HTMLInputElement>) => {
      const searchHospital = e.target.value;
      setSearchHospital(searchHospital);
    };
    
    const findByHospital = () => {
      HospitalTypeService.findByhospitalName(searchHospital)
        .then((response: any) => {
          const {hospital } = response.data;
          setHospital(hospital);
          setCurrentIndex(-1);
          console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const retrieveHospital = () => {
      const params = getRequestParams(searchHospital,52, page, pageSize);
    
      HospitalTypeService.getAll(params)
    
        .then((response: any) => {
    
          const {hospitalCategory , totalPages} = response.data;
    
          setHospital(hospitalCategory);
          setCount(totalPages);
    
          console.log(response.data);
          console.log(params);
    
        })
    
        .catch((e: Error) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrieveHospital();
    },[page , pageSize]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
    const refreshList = () => {
      retrieveHospital();
      setCurrentIndex(-1);
    };
    
    const setActiveHospital = (dataHospitalCategory: IdataModelHospitalCategoryDtos, index: number) => {
      setCurrentIndex(index); 
       props.onEditHospital(dataHospitalCategory);
       props.onHide();
      console.log(dataHospitalCategory);
      console.log(index);
    };
    
    const OnDeleteHospital = (id:any) => {
    
      HospitalTypeService.remove(id)
        .then((response: any) => {
          console.log(response.data);
          refreshList();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    
      return(
            <>
            <Row className="mb-3">
          
          <Stack  direction="horizontal" gap={2}>
                    <Form.Control className="me-auto"
                      placeholder="Filtre le Categorie..."
                      value={searchHospital}
                       onChange={onChangeSearchHospital} 
                      />
                    <Button variant="outline-dark btn-sm"
                     onClick={findByHospital}>Cherchez</Button>
             </Stack>   
            <div className="mt-3">
              
            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
            <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>Hospital</th>
                <th>Type</th>
                <th>Categorie Autorisee</th>
                <th>Compte</th>
              </tr>
            </thead>
            <tbody>
            {Hospital.length>0 ? (
              Hospital.map((i, index) => (
                  
                    <tr key={index+1}>
                    <td>{i.hospitalId}</td>
                    <td>{i.hospitalName}</td>
                    <td>{i.typehospital}</td>
                    <td>{i.categorySoinsName}</td>
                    <td>{i.hospitalAccount}</td>
                    <td>
                    <Stack direction="horizontal" gap={2}>
                    <Button className="me-4 btn btn-success btn-sm btn-block"
                    onClick={() => setActiveHospital(i, index)}>Edit</Button>
    
                      <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteHospital(i.hospitalId)}>Delete</Button>
                    </Stack>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={3}>No Hospital</td>
                </tr>
              )}
    
            </tbody>
          </Table>
          </Row>
    
            </>
        );
    }

