import http from "../../../../AxiosRequest/httpcommon";
import NewFactureSaisieDto from "../models/Dto/NewFactureSaisieDto";
import NewFactureSaisie from '../models/NewFactureSaisie';

const getAllNewFactureSaise = (params:any) => {
  return http.get<Array<NewFactureSaisieDto>>("/FactureSaisie", { params });
};
const getAllNewFactureSaiseByStructure = (params:any) => {
  return http.get<Array<NewFactureSaisieDto>>("/FactureSaisieByStructure", { params });
};

const getDeclarationBetweenDates = (params:any) => {
  return http.get<Array<NewFactureSaisieDto>>("/findBySocialinsuranceIdByStructureGroupedListAndDateCreatedSaisieBetween", { params });
};


const getDeclarationBetweenDatesParDeperment = (params:any) => {
  return http.get<Array<NewFactureSaisieDto>>("/findBySocialinsuranceIdByStructureGroupedListAndDateCreatedSaisieBetweenParDepertment", { params });
};


const getAllNewFactureSaiseByStructureGrouped = (params:any) => {
  return http.get<Array<NewFactureSaisieDto>>("/FactureSaisieByStructureGrouped", { params });
};

const getAllNewFactureSaiseByStructureGroupedParDepertment = (params:any) => {
  return http.get<Array<NewFactureSaisieDto>>("/FactureSaisieByStructureGroupedParDeperment", { params });
};

const getAllVisitelWithoutPagination = () => {
  return http.get<Array<NewFactureSaisie>>("/sortedFactureSaisie");
};

const findById=(id:any)=>{
  return http.get<Array<NewFactureSaisie>>(`/FactureSaisie/${id}`);
}

const findBySocialinsuranceIdAndPatientCode=(socialinsurance_id:any, patientCode: any)=>{

  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisie?socialinsuranceId=${socialinsurance_id}&receiptBill=${patientCode}`);
}
const findBySocialinsuranceIdAndPatientCodeByStructure=(socialinsurance_id:any, patientCode: any,hospitalName:any)=>{

  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisieByStructure?socialinsuranceId=${socialinsurance_id}&receiptBill=${patientCode}&hospitalName=${hospitalName}`);
}

const findBySocialinsuranceIdAndPatientCodeByStructureparDepertment=(socialinsurance_id:any, patientCode: any,hospitalName:any,departmentId:any)=>{

  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisieByStructureGroupedParDeperment?socialinsuranceId=${socialinsurance_id}&receiptBill=${patientCode}&hospitalName=${hospitalName}&departmentId=${departmentId}`);
}
const createFactureSaisie = (
  socialinsuranceId:any,     
  hemaNewVisitePatient_id:any,
  nameDoctors_id:any,
  typeDoctors_id:any,
  couvertureConfig_id:any,
  hospital_id:any,
  typeDepartment_id:any,
  plafondTypeVisite_id:any,
  data: NewFactureSaisie) => {

  return http.post<NewFactureSaisie>(`/socialinsurance/${socialinsuranceId}/${hemaNewVisitePatient_id}/${nameDoctors_id}/${typeDoctors_id}/${couvertureConfig_id}/${hospital_id}/${typeDepartment_id}/${plafondTypeVisite_id}/FactureSaisie`, data);
  
};

const updateFactureSaisie = (id: any, data: NewFactureSaisie) => {
  return http.put<any>(`/FactureSaisie/${id}`, data);
};
const updateFactureSaisieStatusPayer = (id: any, statusFacturePayer: string) => {
  const data = {
    params: {
      statusFacturePayer: statusFacturePayer,
    },
  };

  return http.put<any>(`/FactureSaisieStatusPayer/${id}`, null, data);
};
const updateStatusFactureVerifier = (hospitalId:any,id: any, status: string) => {
  const data = {
    params: {
      status:status,
    },
  };

  return http.put<any>(`/updateStatusFactureVerifierSaisie/${hospitalId}/${id}`, null, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/FactureSaisie/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/FactureSaisie`);
};

const findByReceiptBillAndSocialInsuarance = (socialinsurance_id:any, receiptBill:any) => {
  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisie/${socialinsurance_id}/${receiptBill}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisieNoPayer/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusFacturePayer = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisiePayer/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};

const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerSansMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisieNoPayerSansMituelle/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusFacturePayerSansMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisiePayerSansMituelle/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};


const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisieNoPayerMituelle/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};

const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelleParService = (socialinsurance_id:any, receiptBill:any,idService:any,idDepertment:any) => {
  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisieNoPayerMituelleParService/${socialinsurance_id}/${receiptBill}/${idService}/${idDepertment}`);
};
const findBySocialinsuranceIdAndReceiptBillIdServiceIdDepertmentPayer = (socialinsurance_id:any, receiptBill:any,idService:any,idDepertment:any) => {
  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisiePayerMituelleParService/${socialinsurance_id}/${receiptBill}/${idService}/${idDepertment}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusFacturePayerMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisiePayerMituelle/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};

const findBySocialInsuranceAndCodeAssuree = (socialinsurance_id:any,codeAssuree: any) => {
    return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisieCodeAssuree/${socialinsurance_id}/${codeAssuree}`);
  };

const findByNewVisitePatientId = (idNewVisite: any) => {
    return http.get<Array<NewFactureSaisieDto>>(`/FactureSaisie?receiptBill=${idNewVisite}`);
  };

const findByNewVisitePatientIdPharmacy = (idNewVisite: any) => {
  return http.get<NewFactureSaisieDto>(`/FactureSaisie?receiptBill=${idNewVisite}`);
};


const getAllFactureSaisieBySocialinsuranceIdAndCodeAssureePage = (params:any) => {
    return http.get<Array<NewFactureSaisieDto>>("/FactureSaisieBySocialinsuranceIdAndCodeAssureePage", { params });
  };


const ServicesNewFactureSaisie = {
  findByNewVisitePatientIdPharmacy,
  getAllNewFactureSaiseByStructure,
  findById,
  findByReceiptBillAndSocialInsuarance,
  findBySocialInsuranceAndCodeAssuree,
  getAllNewFactureSaise,
  findBySocialinsuranceIdAndPatientCode,
  findBySocialinsuranceIdAndPatientCodeByStructure,
  updateFactureSaisie,
  updateFactureSaisieStatusPayer,
  remove,
  removeAll,
  getAllFactureSaisieBySocialinsuranceIdAndCodeAssureePage,
  getAllVisitelWithoutPagination,
  createFactureSaisie,
  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer,
  findBySocialinsuranceIdAndReceiptBillStatusFacturePayer,
  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelleParService,
  findBySocialinsuranceIdAndReceiptBillIdServiceIdDepertmentPayer,
  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerSansMituelle,
  findBySocialinsuranceIdAndReceiptBillStatusFacturePayerSansMituelle,

  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelle,
  findBySocialinsuranceIdAndReceiptBillStatusFacturePayerMituelle,
  findByNewVisitePatientId,
  getAllNewFactureSaiseByStructureGrouped,
  getDeclarationBetweenDates,
  getAllNewFactureSaiseByStructureGroupedParDepertment,
  getDeclarationBetweenDatesParDeperment,
  findBySocialinsuranceIdAndPatientCodeByStructureparDepertment,

  updateStatusFactureVerifier
};

export default ServicesNewFactureSaisie;