import { ChangeEvent, useEffect, useState } from "react";
import IdataModelPharmacy from "../Models/IdataModelPharmacy";
import CategoryTypeService from "../Services/PharmacyTypeService";
import { Row, Form, InputGroup, Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/Store/store";
import PharmacyTypeService from "../Services/PharmacyTypeService";

interface EditHospitalTypeList {
    show: boolean; 
    onHide: () => void; 
    EditInfoPharmacy: IdataModelPharmacy;
  }

  export const PharmacyTypeEdit :React.FC<EditHospitalTypeList>=props=> {

    const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
    
const [selectedStructure, setSelectedStructure] = useState('');
    const handleChangeStructure = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = e.target.value;
     
      setSelectedStructure(selectedValue);
    };
    const initialTypeState = {
      id: null,
      pharmacyName: '',
      plafondFacture:0,
      raisonSociale:'',
    tel:'',
    nif:'',
    rc:'',
    bp:'',
    avenue:'',
    centreFiscal:'',
    secteurActivite:'',
    formeJuridique:'',
    tva:'',
      account:0,
      createdBy: '',
      dateCreatedBy: new Date()
    };

    const [isInput,setInput]=useState<IdataModelPharmacy>(props.EditInfoPharmacy);
    
    const handleChange = (e: { target: { name: any; value: any; }; }) => {
      setInput({ ...isInput, [e.target.name]: e.target.value });
   }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditHospital = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              pharmacyName: selectedStructure,
              plafondFacture:isInput.plafondFacture,
              raisonSociale:isInput.raisonSociale,
              tel:isInput.tel,
              nif:isInput.nif,
              rc:isInput.rc,
              bp:isInput.bp,
              avenue:isInput.avenue,
              centreFiscal:isInput.centreFiscal,
              secteurActivite:isInput.secteurActivite,
              formeJuridique:isInput.formeJuridique,
              tva:isInput.tva,
              createdBy:isInput.createdBy,
              dateCreatedBy: isInput.dateCreatedBy
            };
    
            PharmacyTypeService.update(isInput.id,data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                pharmacyName: response.data.pharmacyName,
                plafondFacture:response.data.plafondFacture,

                raisonSociale:response.data.raisonSociale,
                tel:response.data.tel,
                nif:response.data.nif,
                rc:response.data.rc,
                bp:response.data.bp,
                avenue:response.data.avenue,
                centreFiscal:response.data.centreFiscal,
                secteurActivite:response.data.secteurActivite,
                formeJuridique:response.data.formeJuridique,
                tva:response.data.tva,
    
                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);
              EditCancel()
            })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
            
  useEffect(() => {
    
      setInput(props.EditInfoPharmacy)
    
      },[props.EditInfoPharmacy])
      
          return(
            <>
        < form  onSubmit={onFormSubmit}>

         <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Edit Pharmacy</Accordion.Header>
        <Accordion.Body>

        <Row className="mb-3">
         <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
      <Form.Label>Structrures</Form.Label>
      <Form.Select
        className="form-control"
        name="typehospital"
        value={selectedStructure}
        onChange={handleChangeStructure}
      >
        <option value={isInput.pharmacyName}>{isInput.pharmacyName}</option>
        {StructuresData.map((group, index) => (
          <option key={index} value={group}>
            {group}
          </option>
        ))}
      </Form.Select>
         </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Plafond de Facture</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="plafondFacture" value={isInput.plafondFacture} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Edit info sur Facture</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Raison social</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="raisonSociale" value={isInput.raisonSociale} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Tel</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="tel" value={isInput.tel} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Nif</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="number" name="nif" value={isInput.nif} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>RC</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="rc" value={isInput.rc} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>BP</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="bp" value={isInput.bp} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Avenue</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="avenue" value={isInput.avenue} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Centre Fiscal</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="centreFiscal" value={isInput.centreFiscal} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Secteur d'Activite</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="secteurActivite" value={isInput.secteurActivite} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Forme juridique</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="formeJuridique" value={isInput.formeJuridique} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
 <Form.Label>TVA</Form.Label>
    <Form.Select  size="sm" name="tva" value={isInput.tva} onChange={handleChange}>
        <option>Choisir </option>
       <option value="No">No</option>
     <option value="Oui">Oui</option>
</Form.Select>
</Form.Group>
</Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditHospital} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
