import http from "../../../../../AxiosRequest/httpcommon";
import CotisationAssurance from "../Models/CotisationAssurance";
import CotisationAssuranceDto from "../Models/CotisationAssuranceDto";

const getAll = (params:any) => {
  return http.get<Array<CotisationAssuranceDto>>("/cotisationAssurance", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<CotisationAssurance>>("/sortedcotisationAssurance");
};
const getAllWithoutPaginationStatusClasseeBetweenParEnterprise = (params:any) => {
  return http.get<Array<CotisationAssuranceDto>>("/findAllByStatusClasseeBetweenParEnterprise", { params });
};

const getAllWithoutPaginationStatusPayerBetweenParEnterprise = (params:any) => {
  return http.get<Array<CotisationAssuranceDto>>("/findAllByStatusPayerBetweenParEnterprise", { params });
};
const findAllByStatusPayerBetween = (params:any) => {
  return http.get<Array<CotisationAssuranceDto>>("/findAllByStatusPayerBetween", { params });
};

const findAllByStatusClasseeBetween = (params:any) => {
  return http.get<Array<CotisationAssuranceDto>>("/findAllByStatusClasseeBetween", { params });
};
const getfindAllByStatusNoPayer = () => {
  return http.get<Array<CotisationAssuranceDto>>("/findAllByStatusNoPayer");
};
const getLastAccountType=()=>{
  return http.get<Array<CotisationAssurance>>("/cotisationAssuranceAccountEnterpriseName");
}

const create = (socialinsuranceId:any,enterprisesAssuranceId:any,data: CotisationAssurance) => {
  return http.post<CotisationAssurance>(`/${socialinsuranceId}/${enterprisesAssuranceId}/cotisationAssurance`, data);
};

const update = (id: any, data: CotisationAssurance) => {
  return http.put<any>(`/cotisationAssurance/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/cotisationAssurance/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/cotisationAssurance`);
};

const findByEnterpriseName = (socialinsuranceId:any,enterpriseName: string) => {
  return http.get<Array<CotisationAssuranceDto>>(`/cotisationAssurance?socialinsuranceId=${socialinsuranceId}&enterpriseName=${enterpriseName}`);
};

const ServiceCotisationAssurance = {
  getAllWithoutPagination,
  getAllWithoutPaginationStatusClasseeBetweenParEnterprise,
  getAllWithoutPaginationStatusPayerBetweenParEnterprise,
  findAllByStatusPayerBetween,
  findAllByStatusClasseeBetween,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByEnterpriseName,
  getfindAllByStatusNoPayer
};

export default ServiceCotisationAssurance;