import {  useEffect, useState } from "react";
import { Row, Form, InputGroup } from "react-bootstrap";
import IdataModelTypeCategoryDtos from '../Models/IdataModelTypeCategoryDtos';
import IdataModelCategory from "../../Categories/Models/IdataModelCategory";

import IdataModelTypeCategory from "../Models/IdataModelTypeCategory";
import ServiceTypeCategory from "../Services/ServiceTypeCategory";

interface EditTypeList {
    show: boolean; 
    onHide: () => void; 
    EditInfoTcategory: IdataModelTypeCategoryDtos;
  }
  const defaultinitiaT: Array<IdataModelCategory> = [];

  export const EditType :React.FC<EditTypeList>=props=> {


    const initialTypeState = {
      id: null,
       code: 0,
       intitule: '',
       excl: '',
       acc: '',
       dateCreatedBy: '',
    };


    const [isInputDtos,setInputDtos]=useState<IdataModelTypeCategoryDtos>
    (props.EditInfoTcategory);
    const [isInput,setInput]=useState<IdataModelTypeCategory>(initialTypeState);


    
const handleChange = (e: { target: { name: any; value: any; }; }) => {
  setInputDtos({ ...isInputDtos, [e.target.name]: e.target.value });
}
   
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          

          const EditTypeCateg = (e: { preventDefault: () => void; }) => {
            e.preventDefault();
            let data: any = {
              code: isInputDtos.code,
              intitule: isInputDtos.intitule,
              excl: isInputDtos.excl,
              acc: isInputDtos.acc,
              dateCreatedBy: isInputDtos.dateCreatedBy
            };
          
            ServiceTypeCategory.update(isInputDtos.idSoins, data)
                .then((response: any) => {
                  setInput({
                    id: response.data.id,
                    code: response.data.code,
                    intitule: response.data.intitule,
                    excl: response.data.excl,
                    acc: response.data.acc,
                    dateCreatedBy: response.data.dateCreatedBy
                  });
                  console.log(response.data);
                  console.log(data);
                  EditCancel();
                })
                .catch((e: Error) => {
                  console.log(e);
                });
              console.log(isInput);
            
          };
          
          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
         
         
          
  useEffect(() => {
    setInputDtos(props.EditInfoTcategory)

      },[props.EditInfoTcategory])
      
          return(
            <>
        < form  onSubmit={onFormSubmit}>
        <Row className="mb-3">
          <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Intitulé</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="intitule"
                value={isInputDtos.intitule}
                onChange={handleChange}
              />
            </InputGroup>
          </Form.Group>
          
          <Form.Group controlId="formGridExcl" className="col col-sm-3">
            <Form.Label>Excl</Form.Label>
            <Form.Select
              name="excl"
              value={isInputDtos.excl}
              onChange={handleChange}
            >
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </Form.Select>
          </Form.Group>

          
        <Row className="mb-3">
          <Form.Group controlId="formGridAcc" className="col col-sm-3">
            <Form.Label>Acc</Form.Label>
            <Form.Select
              name="acc"
              value={isInputDtos.acc}
              onChange={handleChange}
            >
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </Form.Select>
          </Form.Group>
        </Row>
  
        </Row>
  


            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditTypeCateg} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
