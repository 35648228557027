import { useState } from "react";
import IdataModelFrequance from "../../../Components/ASSURANCES/Payments/Frequance/Models/IdataModelFrequance";
import IdataModelMode from "../../../Components/ASSURANCES/Payments/Mode/Models/IdataModelMode";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AddFrequance } from "../../../Components/ASSURANCES/Payments/Frequance/Operations/AddFrequance";
import { FrequancesTable } from "../../../Components/ASSURANCES/Payments/Frequance/Tables/FrequancesTable";
import { AddMode } from "../../../Components/ASSURANCES/Payments/Mode/Operations/AddMode";
import { TableMode } from "../../../Components/ASSURANCES/Payments/Mode/Tables/TableMode";
import { EditModes } from "../../../Components/ASSURANCES/Payments/Mode/Operations/EditModes";
import { EditFrequances } from "../../../Components/ASSURANCES/Payments/Frequance/Operations/EditFrequances";



const initialTypeState = {
    id: null,
    typeName: '',
    createdBy: '',
    dateCreatedBy: new Date()
  };

export const Payment:React.FC=()=>{

    const [EditFrequance, setEditFrequance] = useState<IdataModelFrequance>(initialTypeState);

    const onCurrentFrequance = (data: IdataModelFrequance) => {
        setEditFrequance(data);
      };

    const [EditMode,setEditMode] = 
    useState<IdataModelMode>(initialTypeState)

    const onCurrentDoctor = (data: IdataModelMode) => {
        setEditMode(data);
      };

    const [isSwichLayerFrequance,setSwichLayerFrequance] = useState(true);
    const [isSwichLayerMode,setSwichLayerMode] = useState(true);
    

    return(
        <>
        <Container fluid>
 <Row>
                <Col> 
                <br />
                <Card>
            <Card.Body>
           {isSwichLayerFrequance?
                (
                    <AddFrequance />
                ):(
            <EditFrequances 
            show={isSwichLayerFrequance}  
            onHide={() => setSwichLayerFrequance(true)}
            EditInfo={EditFrequance}
            /> 
                )
  }  <br />
         <FrequancesTable 
         show={isSwichLayerFrequance}  
         onHide={() => setSwichLayerFrequance(false)}
         onEdit={onCurrentFrequance}
          />
            
            </Card.Body>
        </Card> 
         <br />
                </Col>
                <Col> 
                <br />
                <Card>
            <Card.Body>
            {isSwichLayerMode?
            
                (
                <AddMode/>
                ):(
<EditModes
 show={isSwichLayerMode} 
  onHide={() => setSwichLayerMode(true)}
             EditInfo={EditMode} /> 
                )
                }
                <TableMode show={isSwichLayerMode}
                  onHide={() => setSwichLayerMode(false)}
         onEdit={onCurrentDoctor}
          />
 
   </Card.Body>
        </Card>
        <br />
       </Col>

            </Row>
          
        </Container>
        </>
    );
}