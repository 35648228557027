import React, { useState } from "react";
import { Card, Col, Container, Row } from 'react-bootstrap';
import { AjoutNomDoctor } from "../../Components/Doctor/Names/NameDoctorOperation/AjouteNomDocteur";
import { AddTypeDoctor } from "../../Components/Doctor/TypesDoctors/TypeDoctorOperations/AddTypeDocteur";
import { EditTypeDoctor } from "../../Components/Doctor/TypesDoctors/TypeDoctorOperations/EditTypeDoctor";
import { TypeDoctorTable } from "../../Components/Doctor/TypesDoctors/Tables/TypeDoctorTable";
import IdataModelTypeDoctor from '../../Components/Doctor/TypesDoctors/Models/IdataModelTypeDoctor';
import { EditNamDoctor } from '../../Components/Doctor/Names/NameDoctorOperation/EditNomDoctor';
import { ConsultationDoctor } from "../../Components/Doctor/Names/Tables/ConsultationDoctor";
import IdataModeConsultationDtos from "../../Components/Doctor/Names/Models/IdataModelConsultationDtos";



const initialTypeState = {
    id: null,
    typeName: '',
    accountType:0,
    createdBy: '',
    dateCreatedBy: new Date()
  };
  const initialNamDoctorDtos = {
    accountNameDoctor:'',
     accountTypeDoctor:'',
     doctorName:'',
     
    prescriptPar:'',
     doctorNameId:'',
     doctorTypeId:'',
     hospitalId:'',
     typehospital:'',
     hospitalAccount:'',
     referenceId:'',
     categorySoinsId:'',
     categorySoinsName:'',
     hospitalName:'',
     priceConsultation:'',
     typeName:''
      };
export const DoctorPage:React.FC=()=>{

    const [EditList, setEditList] = useState<IdataModelTypeDoctor>(initialTypeState);

    const onCurrentTypeDoctor = (TypeDoctor: IdataModelTypeDoctor) => {
        setEditList(TypeDoctor);
      };

    const [EditListDoctorConsultation,setEditListDoctorConsultation] = 
    useState<IdataModeConsultationDtos>(initialNamDoctorDtos)

    const onCurrentDoctor = (Doctor: IdataModeConsultationDtos) => {
        setEditListDoctorConsultation(Doctor);
      };

    const [isSwichLayerTypeDoctor,setSwichLayerTypeDoctor] = useState(true);
    const [isSwichLayerDoctor,setSwichLayerDoctor] = useState(true);
    

    return(
        <>
        <Container fluid>
 <Row>
                <Col> 
                <br />
                <Card>
            <Card.Body>
           {isSwichLayerTypeDoctor?
                (
                    <AddTypeDoctor />
                ):(
            <EditTypeDoctor 
            show={isSwichLayerTypeDoctor}  
            onHide={() => setSwichLayerTypeDoctor(true)}
            EditInfoTypeDoctor={EditList}
            /> 
             
                )
  }
         <TypeDoctorTable 
         show={isSwichLayerTypeDoctor}  
         onHide={() => setSwichLayerTypeDoctor(false)}
         onEditTypeDoctor={onCurrentTypeDoctor}
          />
            </Card.Body>
        </Card>
        <br />
                </Col>
                <Col> 
                <br />
                <Card>
            <Card.Body>
            {isSwichLayerDoctor?
            
                (
                <AjoutNomDoctor/>
                ):(
<EditNamDoctor show={isSwichLayerDoctor}  onHide={() => setSwichLayerDoctor(true)}
             EditInfoNamDoctor={EditListDoctorConsultation} /> 
                )
                }
                <ConsultationDoctor show={isSwichLayerDoctor}
                  onHide={() => setSwichLayerDoctor(false)}
                  OnEditNameDoctor={onCurrentDoctor}
                 />
 
   </Card.Body>
        </Card>
        <br />
                   </Col>

            </Row>
          
        </Container>
        </>
    );
}