import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Card, Row, Form, InputGroup } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Redux/Store/store';
import CotisationIndepandantDto from '../Models/CotisationIndepandantDto';
import CotisationIndepandant from '../Models/CotisationIndepandant';
import ServiceCotisationIndepandant from '../Services/ServiceCotisationIndepandant';
  interface IEditAdhesionList {
    show: boolean; 
    onHide: () => void; 
    EditInfo: CotisationIndepandantDto;
  }

  export const EditCotisationIndepandant :React.FC<IEditAdhesionList>=props=> {

  
    const initialTypeState = {
      id: null,
      money: '',
      createdBy: '',
      moisPayer: '',
      statusPayer: '',
      statusClassee: '',
      comment: '',
      dateCreated: '',
      timeCreated: '',
    };

    const [isInputDto,setInputDto]=useState<CotisationIndepandantDto>(props.EditInfo);

    const [isInput,setInput]=useState<CotisationIndepandant>(initialTypeState);

    const preferredName = useSelector((state: RootState) => state.user.preferred_username);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInput({ ...isInput, [name]: value });
       // console.log(event.target)
        }
        const handleChangeDto = (event: ChangeEvent<HTMLInputElement>) => {
          const { name, value } = event.target;
          setInputDto({ ...isInputDto, [name]: value });
         // console.log(event.target)
          }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const Edit = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            const now = new Date();;
        
            // Get current month in French
            const monthsInFrench = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
            const moisPayer = monthsInFrench[now.getMonth()];
           // Format date as YYYY-MM-DD
            const dateCreated = now.toISOString().split('T')[0];
        
            // Format time as HH:MM:SS
            const timeCreated = now.toTimeString().split(' ')[0];
      
            let data = {
              money: isInputDto.money,
              createdBy: preferredName,
              moisPayer:moisPayer,
              statusPayer: isInputDto.statusPayer,
              statusClassee: isInputDto.statusClassee,
              comment: isInputDto.comment,
              dateCreated: dateCreated,
              timeCreated: timeCreated,
            };

            ServiceCotisationIndepandant.update(isInputDto.id,data)
            .then((response: any) => {
              setInput({
                
            id: response.data.id, 
            money: response.data.money,
            createdBy: response.data.createdBy,
            moisPayer:response.data.moisPayer,
            statusPayer: response.data.statusPayer,
            statusClassee: response.data.statusClassee,
            comment: response.data.comment,
            dateCreated: response.data.dateCreated,
            timeCreated: response.data.timeCreated});
            
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
          
  useEffect(() => {

    setInputDto(props.EditInfo)

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
            <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-6">
               <Form.Label>Edit money </Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="money" value={ isInputDto.money} onChange={handleChangeDto} />
               </InputGroup>
           </Form.Group>
            </Row>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={Edit} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
