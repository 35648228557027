

import http from "../../../../AxiosRequest/httpcommon";
import NewFactureLivraisonMedicamentDto from "../models/Dto/NewFactureLivraisonMedicamentDto";
import NewFactureLivraisonMedicament from "../models/NewFactureLivraisonMedicament";

const getAllNewFactureSaise = (params:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>("/FactureSaisieLivraison", { params });
};
const getAllNewFactureSaiseByStructure = (params:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>("/FactureSaisieLivraisonByStructureLivraison", { params });
};

const getDeclarationBetweenDates = (params:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>("/findBySocialinsuranceIdByStructureGroupedListAndDateCreatedSaisieBetweenLivraison", { params });
};
const getAllNewFactureSaiseByStructureGrouped = (params:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>("/FactureSaisieLivraisonByStructureGrouped", { params });
};

const getAllVisitelWithoutPagination = () => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>("/sortedFactureSaisieLivraison");
};

const findById=(id:any)=>{
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonLivraison/${id}`);
}

const findBySocialinsuranceIdAndPatientCode=(socialinsurance_id:any, patientCode: any)=>{

  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonByStructureGrouped?socialinsuranceId=${socialinsurance_id}&receiptBill=${patientCode}`);
}
const findBySocialinsuranceIdAndPatientCodeByStructure=(socialinsurance_id:any, patientCode: any,pharmacyName:any)=>{

  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonByStructureGrouped?socialinsuranceId=${socialinsurance_id}&receiptBill=${patientCode}&pharmacyName=${pharmacyName}`);
}
const createFactureSaisiePharmacy = (
  couvertureConfig_id:any,
  pharmacies_id:any,
  factureSaisiePharmacy_id:any,
  data: NewFactureLivraisonMedicament) => {

  return http.post<NewFactureLivraisonMedicament>(`/socialinsurance/${couvertureConfig_id}/${pharmacies_id}/${factureSaisiePharmacy_id}/FactureSaisieLivraison`, data);
  
};

const updateFactureSaisiePharmacy = (id: any, data: NewFactureLivraisonMedicamentDto) => {
  return http.put<any>(`/FactureSaisieLivraison/${id}`, data);
};

const updateFactureSaisiePharmacyStatusPayer = (id: any, statusFacturePayer: string) => {
  const data = {
    params: {
      statusFacturePayer: statusFacturePayer,
    },
  };
  return http.put<any>(`/FactureSaisieLivraisonUpdateStatusFacturePayer/${id}`, null, data);
};

const updateStatusFactureVerifier = (pharmaciesId: any, id: any, status: string) => {
  const data = {
    params: {
      status:status,
    },
  };

  return http.put<any>(`/updateStatusFactureVerifierLivraison/${pharmaciesId}/${id}`, null, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/FactureSaisieLivraison/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/FactureSaisieLivraison`);
};

const findByReceiptBillAndSocialInsuarance = (socialinsurance_id:any, receiptBill:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonLivraison/${socialinsurance_id}/${receiptBill}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonPharmacyNoPayer/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusFacturePayer = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonPayerLivraison/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};

const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerSansMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonNoPayerSansMituelleLivraison/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusFacturePayerSansMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonPayerSansMituelleLivraison/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};


const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonNoPayerMituelleLivraison/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};

const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelleParService = (socialinsurance_id:any, receiptBill:any,idService:any,idDepertment:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonNoPayerMituelleParServiceLivraison/${socialinsurance_id}/${receiptBill}/${idService}/${idDepertment}`);
};
const findBySocialinsuranceIdAndReceiptBillIdServiceIdDepertmentPayer = (socialinsurance_id:any, receiptBill:any,idService:any,idDepertment:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonPayerMituelleParServiceLivraison/${socialinsurance_id}/${receiptBill}/${idService}/${idDepertment}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusFacturePayerMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonPayerMituelleLivraison/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};

const findBySocialInsuranceAndCodeAssuree = (socialinsurance_id:any,codeAssuree: any) => {
    return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonCodeAssureeLivraison/${socialinsurance_id}/${codeAssuree}`);
  };

const findByNewVisitePatientId = (idNewVisite: any) => {
    return http.get<Array<NewFactureLivraisonMedicamentDto>>(`/FactureSaisieLivraisonLivraison?receiptBill=${idNewVisite}`);
  };


const getAllFactureSaisiePharmacyBySocialinsuranceIdAndCodeAssureePage = (params:any) => {
    return http.get<Array<NewFactureLivraisonMedicamentDto>>("/FactureSaisieLivraisonBySocialinsuranceIdAndCodeAssureePageLivraison", { params });
  };


const ServiceNewFactureLivraisonMedicament = {
  getAllNewFactureSaiseByStructure,
  findById,
  findByReceiptBillAndSocialInsuarance,
  findBySocialInsuranceAndCodeAssuree,
  getAllNewFactureSaise,
  findBySocialinsuranceIdAndPatientCode,
  findBySocialinsuranceIdAndPatientCodeByStructure,
  updateFactureSaisiePharmacy,
  updateFactureSaisiePharmacyStatusPayer,
  remove,
  removeAll,
  getAllFactureSaisiePharmacyBySocialinsuranceIdAndCodeAssureePage,
  getAllVisitelWithoutPagination,
  createFactureSaisiePharmacy,
  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer,
  findBySocialinsuranceIdAndReceiptBillStatusFacturePayer,
  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelleParService,
  findBySocialinsuranceIdAndReceiptBillIdServiceIdDepertmentPayer,
  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerSansMituelle,
  findBySocialinsuranceIdAndReceiptBillStatusFacturePayerSansMituelle,

  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelle,
  findBySocialinsuranceIdAndReceiptBillStatusFacturePayerMituelle,
  findByNewVisitePatientId,
  getAllNewFactureSaiseByStructureGrouped,
  getDeclarationBetweenDates,

  updateStatusFactureVerifier
};

export default ServiceNewFactureLivraisonMedicament;