import { SetStateAction, useEffect, useRef, useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";import { FaHome, FaDollarSign, FaEdit, FaFileInvoice, FaList, FaSignOutAlt, FaCheckCircle } from 'react-icons/fa';

import { AddFactureSaisie } from "../AddFactureSaisie/AddFactureSaisie";
import { FacturationPriceActeTable } from "../Tables/ModalTables/FacturationPriceActeTable";
import IdataModelPriceActesDtos from "../../../Acts/PriceActes/Models/IdataModelPriceActesDtos";
import { AddFactureEmpty } from "../AddFactureSaisie/AddFactureEmpty";
import { TablePayerFactureMituelle } from "../Tables/TablePayerFactureMituelle";
import { TableFactureComplete } from "../Tables/TableFactureComplete";
import { TableNoPayerFactureSansMituelle } from "../Tables/TableNoPayerFactureSansMituelle";
import { TableFacturationTousSansMituelle } from "../Tables/ModalTables/TableFacturationTousSansMituelle";
import { TableFacturationTousMituelle } from "../Tables/TableFacturationTousMituelle";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import { useDispatch } from "react-redux";
import { TablePayerFactureSansMituelle } from "../Tables/TablePayerFactureSansMituelle";
import { AddFactureSaisieConfig } from "../AddFactureSaisie/AddFactureSaisieConfig";
import { TableNoPayerFactureMituellePrService } from "../Tables/TableNoPayerFactureMituellePrService";

import { useHistory } from 'react-router-dom';
import { TableEditFactureMituellePrService } from "../Tables/TableEditFactureMituellePrService";
import { TableMedicamentClient } from "../../../ListMedicament/Tables/TableMedicamentClient";
import IdataModelListMedicament from "../../../ListMedicament/Models/ListMedicament";
import { TableFactureMedicamentNoPayer } from "../../FactureMedicament/Table/TableFactureMedicamentNoPayer";
import { TableFactureMedicamentPayer } from "../../FactureMedicament/Table/TableFactureMedicamentPayer";
import { TableFactureMedicamentNoPayerLivraison } from "../../FactureMedicament/Table/TableFactureMedicamentNoPayerLivraison";
import { AddFactureSaisieConfigPharmacy } from "../AddFactureSaisie/AddFactureSaisieConfigPharmacy";
import { DetailsTableAccueille } from "../../../Acts/Details/Tables/DetailsTableAccueille";

const IdataModelPriceActesDtosinit =
{// soinsDetails e
  idSoinsDetails:0,
   code:0,
   intitule:'',
   excl:'',
   acc:'',
   dateCreatedBy:'',

  // soins s
  idSoins:0,
   codeSoins:'',
   intituleSoins:'',

  // typePrestation t
   idTypePrestation:0,
   typePrestationsName:'',
   accountType:0,

  // price b
   idPrice:0,
   price:0,
   priceCreatedBy:'',
  // hospital h
   hospitalId:'',
   hospitalName:''
}


const initialTypeState = {
  code: 0,
     typeName: '',
     excl: '',  //ouii or no
     acc: '',  // oui or no
     createby: '',
     dateCreatedBy: '',
};

  export const ModalFactureSaisie=()=>
    {
      const history = useHistory();
      const componentToPrint = useRef(null);
      const componentToPrint2 = useRef(null);

      const [modalShow, setModalShow] = useState(true);
      const [isSwichMedoc,setSwichMedoc]= useState(true);
      
      const [initMedecine, setInitMedecine] = useState<IdataModelListMedicament>(initialTypeState);

        const onCurrantMedicament=(data:IdataModelListMedicament)=>{
            setInitMedecine(data);
        }
  const [isgetCurrantPriceActesPushed, setgetCurrantPriceActesPushed] = useState<IdataModelPriceActesDtos>(IdataModelPriceActesDtosinit);
  const [activeMenuItem, setActiveMenuItem] = useState("Saisie Prestation");

  const isgetCurrentNewFactureDtoPushed = useSelector((state: RootState) => state.currentNewFacture);
  const dispatchisgetselecterCurrantNewVisitePushed = useDispatch();

  const onCurrantActesPrice = (price: IdataModelPriceActesDtos) => {
    setgetCurrantPriceActesPushed(price);
  };

  const handleMenuItemClick = (menuItem: SetStateAction<string>) => {
    if (menuItem === "exit") {
      history.push('/EspacePatients');
    }  else if (menuItem === "bon") {
      history.push('/FacturationConsultation');
    }else {
      setActiveMenuItem(menuItem);
    }
  };
  useEffect(() => {
    if (isgetCurrentNewFactureDtoPushed.nomPrenomAffilie==null) {
      history.push('/EspacePatients');
    } else {
      console.log('working')
    }
  }, );

  const renderActiveContent = () => {
    switch (activeMenuItem) { 

      case "prix actes":
        return <DetailsTableAccueille />;
      case "Saisie Prestation":
        return (
          <Container fluid>
            <Row>
              <Col>
                <div>{isgetCurrentNewFactureDtoPushed.nomPrenomAffilie}</div>
                {modalShow ? (
                  <AddFactureEmpty />
                ) : (
                  <>
                    <div>
                      <AddFactureSaisie
                        show={modalShow}
                        onHide={() => setModalShow(true)}
                        currentactsprices={isgetCurrantPriceActesPushed}
                      />
                    </div>
                  </>
                )}
                <>
                  <AddFactureSaisieConfig />
                  <FacturationPriceActeTable
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onEditpriceActe={onCurrantActesPrice}
                  />
                </>
              </Col>
            </Row>
            <Row></Row>
          </Container>
        );
        case "Saisir medicament":
          return (
            <Container fluid>
              <Row>
                <Col>
                  <div>{isgetCurrentNewFactureDtoPushed.nomPrenomAffilie}</div>
                  
                  <>
                  <TableFactureMedicamentPayer/>
                     <div>
                      <TableMedicamentClient
                      show={isSwichMedoc} 
                      onHide={() => setSwichMedoc(false)}
                      onEditType={onCurrantMedicament}
                       />
                      </div>
                  </>
                </Col>
              </Row>
              <Row></Row>
            </Container>
          );
      case "Payer Facture Mituelle":
        return <TableNoPayerFactureMituellePrService />;
      case "Modifier Facture Mituelle ":
        return <TableEditFactureMituellePrService />;
        case "Facture Mituelle":
          return <TableFacturationTousMituelle componentToPrint={componentToPrint} />;

          
      case "Payer Facture 100%":
        return <TableNoPayerFactureSansMituelle />;

      case "Modifier Facture 100%": /// to update
        return <TableNoPayerFactureSansMituelle />;

      case "Facture 100%":
        return <TableFacturationTousSansMituelle componentToPrint={componentToPrint} />;

      case "Liste Facture Combinee":
        return (
          <>
            <div>{isgetCurrentNewFactureDtoPushed.nomPrenomAffilie}</div>
            <TableFactureComplete />
          </>
        );
      case "Liste Facture deja payer de Mituelle":
        return (
          <>
            <div>{isgetCurrentNewFactureDtoPushed.nomPrenomAffilie}</div>
            <TablePayerFactureMituelle />
          </>
        );
      case "Liste Facture deja payer de 100%":
        return (
          <>
            <div>{isgetCurrentNewFactureDtoPushed.nomPrenomAffilie}</div>
            <TablePayerFactureSansMituelle />
          </>
        );
        
      default:
        return null;
    }
  };

  return (
    <>
      <Container fluid>
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
          <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("prix actes")} active={activeMenuItem === "prix actes"}>
                <FaHome className="me-2" /> Saisir Prix de actes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Saisie Prestation")} active={activeMenuItem === "Saisie Prestation"}>
                <FaHome className="me-2" /> Saisir acte
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Saisir medicament")} active={activeMenuItem === "Saisir medicament"}>
                <FaHome className="me-2" /> Saisir medicament
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Payer Facture Mituelle")} active={activeMenuItem === "Payer Facture Mituelle"}>
                <FaDollarSign className="me-2" /> Payer mituelle
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Modifier Facture Mituelle ")} active={activeMenuItem === "Modifier Facture Mituelle "}>
                <FaEdit className="me-2" /> Modifier facture mituelle
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Facture Mituelle")} active={activeMenuItem === "Facture Mituelle"}>
                <FaFileInvoice className="me-2" /> Facture mituelle
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Payer Facture 100%")} active={activeMenuItem === "Payer Facture 100%"}>
                <FaDollarSign className="me-2" /> Payer 100%
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Facture 100%")} active={activeMenuItem === "Facture 100%"}>
                <FaFileInvoice className="me-2" /> Facture 100%
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Liste Facture Combinee")} active={activeMenuItem === "Liste Facture Combinee"}>
                <FaList className="me-2" />Actes combinees
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Liste Facture deja payer de Mituelle")} active={activeMenuItem === "Liste Facture deja payer de Mituelle"}>
                <FaCheckCircle className="me-2" />Actes payer mituelle
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("Liste Facture deja payer de 100%")} active={activeMenuItem === "Liste Facture deja payer de 100%"}>
                <FaCheckCircle className="me-2" /> Facture payer 100%
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("exit")} active={activeMenuItem === "exit"}>
                <FaSignOutAlt className="me-2" /> Espace patients
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => handleMenuItemClick("bon")} active={activeMenuItem === "bon"}>
                <FaSignOutAlt className="me-2" /> Creation bon
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          {renderActiveContent()}
        </Col>
      </Row>
      </Container>
    </>
  );
};
