import { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import CotisationIndepandantDto from "../Models/CotisationIndepandantDto";
import { AddCotisationIndepandant } from "../Operations/AddCotisationIndepandant";
import { EditCotisationIndepandant } from "../Operations/EditCotisationIndepandant";
import { TableOperations } from "../Tables/TableOperations";

 
  interface IpushCurrantValue {
    show: boolean; 
    onHide: () => void; 
  }

const initialTypeStatedto = {
    // for cotisationIndependant
  id: 0,
  money: 0,

  createdBy: '',
  moisPayer: '',
  statusPayer: '',
  statusClassee: '',
  comment: '',
  dateCreated: '',
  timeCreated: '',

  // for enterprise
  enterprisesAssuranceId: 0,
  enterpriseName: '',

  // for PMember
  pMemberId: 0,
  codeAssuree: '',
  nomPrenomAffilie: '',
  CatégorieSoins: '',
  category_code: '',

  // for socialInsurance
  socialinsuranceId:0,
  socialInsuranceName: ''
  };
export const PayerIndepandant:React.FC<IpushCurrantValue>=props=>{


  
    const [EditListAdherant,setEditListAdherant] = 
    useState<CotisationIndepandantDto>(initialTypeStatedto)

    const onCurrentAdhesion = (onCurrant: CotisationIndepandantDto) => {
        setEditListAdherant(onCurrant);
      };
      const [isSwichLayerAdherant,setSwichLayerAdherant] = useState(true);
      
   
    return(
        <>
         <Modal {...props}
    size="lg"
    backdrop="static"
    keyboard={false}
    
     aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Facturation  ayant drois
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
      
      <Container fluid>
 <Row>  
                <Col> 
                <br />
                <Card>
            <Card.Body>
            {isSwichLayerAdherant?
                (
                <AddCotisationIndepandant/>
                ):(
<EditCotisationIndepandant
 show={isSwichLayerAdherant}
   onHide={() => setSwichLayerAdherant(true)}
   EditInfo={EditListAdherant} /> 
                )
                }
 <TableOperations
  show={isSwichLayerAdherant}
  onHide={() => setSwichLayerAdherant(false)}
  onEdit={onCurrentAdhesion}
          />
   </Card.Body>
        </Card>  <br />
                   </Col>

            </Row>
          
        </Container>
      </Modal.Body>
    
    </Modal>
       
        </>
    );
}