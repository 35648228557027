import http from "../../../../AxiosRequest/httpcommon";
import IdataModelTypeCategory from "../Models/IdataModelTypeCategory";
import IdataModeltypeSoinsCategoryDtos from "../Models/IdataModelTypeCategoryDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModeltypeSoinsCategoryDtos>>("/AllSoinsDtosPage", { params });
};

const getAlltypeSoinsNameWithoutPagination = () => {
  return http.get<Array<IdataModelTypeCategory>>("/sortedSoins");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelTypeCategory>>("/eSoinsCode");
}

const create = (typePrestationsId:any,data: IdataModelTypeCategory) => {
  return http.post<IdataModelTypeCategory>(`/CreateSoins/${typePrestationsId}/Soins`, data);
};

const update = (id:any, data: IdataModelTypeCategory) => {
  return http.put<any>(`/Soins/${id}`, data);
};
const updateAll = (id: any, typePrestationsId:any, data: IdataModelTypeCategory) => {
  return http.put<any>(`/Soins/${id}/${typePrestationsId}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/deleteSoins/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/Soins`);
};

const findBytypeSoinsName = (intitule: string) => {
  return http.get<Array<IdataModeltypeSoinsCategoryDtos>>
  (`/AllSoinsDtosPage?intitule=${intitule}`);
};

const ServiceTypeCategory = {
  getAlltypeSoinsNameWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  updateAll,
  remove,
  removeAll,
  findBytypeSoinsName
};

export default ServiceTypeCategory;
