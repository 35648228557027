import React, { ReactNode } from 'react';

const Footer: React.FC = () => {
  


  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* Footer */}
      <footer className="footer mt-auto py-3 text-center">
        <div className="container">
          <span className="text-muted">© {currentYear} Developed by Ramadhani Ally</span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
