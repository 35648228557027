import { useState, ChangeEvent, useEffect } from 'react';
import { Row, Form, InputGroup } from "react-bootstrap";
import IPlafondYearTypeVisiteDto from '../Models/PlafondYearTypeVisiteDto';
import IPlafondYearTypeVisite from '../Models/PlafondYearTypeVisite';
import PlafondYearTypeVisiteService from '../Services/PlafondYearTypeVisiteService';
import IdataModelCategoryCode from '../../Categories/CategoriiesCode/Models/IdataModelCategoryCode';
import CategoryAffilieTypeService from '../../Categories/CategoriesAffilies/Services/CategoryAffilieTypeService';
  interface IEditType {
    show: boolean; 
    onHide: () => void; 
    EditInfo: IPlafondYearTypeVisiteDto;
  }

  const defaultcode:Array<IdataModelCategoryCode>=[];
  export const EditPlafondYearTypeVisite:React.FC<IEditType>=props=> {

   
const initialTypeState = {
  id: null,
  plafondYear:0,
  plafondYearAlert:0,
  enfant:0,
  prime:0,
  cotization:0,
  etatCivil:'',
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};


    const [isInputDto,setInputDto]=useState<IPlafondYearTypeVisiteDto>(props.EditInfo);

    const [code, setcode] = useState(defaultcode);
    const [isInput,setInput]=useState<IPlafondYearTypeVisite>(initialTypeState);

    const getAllWithoutPaginationCode = () => {
  
      CategoryAffilieTypeService.getAllTypesoinsWithoutPagination()
         .then((response: any) => {
           setcode(response.data);
         })
         .catch((e: Error) => {
           console.log(e);
         });
     };
     useEffect(() => {
      getAllWithoutPaginationCode();
    });
        const handleChangeDtos = (event: ChangeEvent<HTMLInputElement>) => {
          const { name, value } = event.target;
          setInputDto({ ...isInputDto, [name]: value });
         // console.log(event.target)
          }
          const handleChangeSelected = (event: ChangeEvent<HTMLSelectElement>) => {
            const { name, value } = event.target;
            setInputDto({ ...isInputDto, [name]: value });
          };

   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditType = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              plafondYear:isInputDto.plafondYear,
              plafondYearAlert:isInputDto.plafondYearAlert,
              enfant:isInputDto.enfant,
              prime:isInputDto.prime,
              cotization:isInputDto.cotization,
              etatCivil:isInputDto.etatCivil,
              createdBy:'ghost zila',
              dateCreatedBy: new Date()
            };
            PlafondYearTypeVisiteService.update(isInputDto.plafondYearId,data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                plafondYear: response.data.plafondYear,
                plafondYearAlert:response.data.plafondYearAlert,
                enfant:response.enfant,
                prime:response.prime,
                cotization:response.cotization,
                etatCivil:response.etatCivil,
                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
          
  useEffect(() => {

    setInputDto(props.EditInfo)

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
        <Row className="mb-3">
      
      <Form.Group controlId="formBasicEmail" className="col col-sm-3">
          <Form.Label>Cotization</Form.Label>
                  <InputGroup>
                      <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="cotization" value={isInputDto.cotization} onChange={handleChangeDtos} />
                  </InputGroup>
      </Form.Group>
            
      <Form.Group controlId="formBasicEmail" className="col col-sm-3">
          <Form.Label>Enfant</Form.Label>
                  <InputGroup>
                      <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="enfant" value={isInputDto.enfant} onChange={handleChangeDtos} />
                  </InputGroup>
      </Form.Group>
      <Form.Group controlId="formBasicEmail" className="col col-sm-3">
          <Form.Label>Prime</Form.Label>
                  <InputGroup>
                      <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="prime" value={isInputDto.prime} onChange={handleChangeDtos} />
                  </InputGroup>
      </Form.Group>
      
      
      <Form.Group controlId="formBasicEmail" className="col col-sm-3">
          <Form.Label>Plafond Anuelle</Form.Label>
                  <InputGroup>
                      <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="plafondYear" value={isInputDto.plafondYear} onChange={handleChangeDtos} />
                  </InputGroup>
      </Form.Group>
      <Form.Group controlId="formBasicEmail" className="col col-sm-3">
          <Form.Label>Plafond Anuelle Alerte</Form.Label>
                  <InputGroup>
                      <Form.Control aria-label="plafondYearAlert" aria-describedby="basic-addon2" type="number" name="plafondYearAlert" value={isInputDto.plafondYearAlert} onChange={handleChangeDtos} />
                  </InputGroup>
      </Form.Group>
      <Form.Group controlId="formBasicemail" className="col col-sm-3 mt-3">
         <Form.Label>Etat Civil</Form.Label>
            <Form.Select  size="sm" name="etatCivil" value={isInputDto.etatCivil} onChange={handleChangeSelected}>
                <option>Choisir </option>
               <option value="yes">yes</option>
             <option value="No">no</option>
    </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3 mt-3">
         <Form.Label>Code Category:{isInputDto.categoryName} </Form.Label>
    <Form.Select className="form-control"  size="sm" 
     onChange={handleChangeSelected}>
       <option value="0">Choose..</option>
      {code &&
    code.map((code) => (
        <option key={code.id} value={code.id} >{code.categoryName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
   
              </Row>
      
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditType} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
