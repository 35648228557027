import { useState } from "react";
import NonAffiliesDto from '../../../Components/NonAffilier/Registration/Models/NonAffiliesDto';
import { Card, Col, Container } from "react-bootstrap";
import { AddNonAffilier } from "../../../Components/NonAffilier/Registration/Operations/AddNonAffilier";
import { EditNonAffilier } from "../../../Components/NonAffilier/Registration/Operations/EditNonAffilier";
import { NonAffilierTable } from "../../../Components/NonAffilier/Registration/Tables/NonAffilierTable";

      const initialTypeStateDto = {
        id: null,
        fullNamePatient:'',
        phoneNumberPatient:'',
        addressPatient:'',
        cdnpatient:'',
        genderPatient:'',
        age:'',
        codePatient:0,
        createdBy:'',
        createdDate:'',
        // hospital
        hospitalId:0,
        hospitalName:''
      };
export const SaveNonAfflier:React.FC=()=>{

    const [EditList, setEditList] = useState<NonAffiliesDto>(initialTypeStateDto);

    const onCurrentNonAffilier = (nonAffiliesDto: NonAffiliesDto) => {
        setEditList(nonAffiliesDto);
      };


    const [isSwichLayer,setSwichLayer] = useState(true);
    

    return(
        <>
        <Container fluid>        
                <Col> 
                <br />
                <Card>
            <Card.Body>
            {isSwichLayer?
            
                (
                <AddNonAffilier/>
                ):(
           <EditNonAffilier 
                show={isSwichLayer}  
                onHide={() => setSwichLayer(true)}
                EditNonAffilierDto={EditList} /> 
                )
                }
                < NonAffilierTable 
                  show={isSwichLayer}
                  onHide={() => setSwichLayer(false)}
                  onEditNonAffilie={onCurrentNonAffilier}
                 />
 
   </Card.Body>
        </Card>
        <br />
                   </Col>
        </Container>
        </>
    );
}