import IdataModelTypeDoctor from "../Models/IdataModelTypeDoctor";
import http from "../../../../AxiosRequest/httpcommon";

const getAll = (params:any) => {
  return http.get<Array<IdataModelTypeDoctor>>("/typeDoctors", { params });
};

const getAllTypeDoctorWithoutPagination = () => {
  return http.get<Array<IdataModelTypeDoctor>>("/sortedTypeDoctors");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelTypeDoctor>>("/typeDoctorsAccountTypeDoctor");
}

const create = (data: IdataModelTypeDoctor) => {
  return http.post<IdataModelTypeDoctor>("/typeDoctors", data);
};

const update = (id: any, data: IdataModelTypeDoctor) => {
  return http.put<any>(`/typeDoctors/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/typeDoctors/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/typeDoctors`);
};

const findByTypeDoctor = (typeName: string) => {
  return http.get<Array<IdataModelTypeDoctor>>(`/typeDoctors?typeName=${typeName}`);
};

const TypeDoctorService = {
  getAllTypeDoctorWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByTypeDoctor,
};

export default TypeDoctorService;