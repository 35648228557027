import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import ServicesFactureTotalInOne from '../Espace_Patients/FactureTotalinOne/services/ServiceFactureTotalInOne';
import ConsommationEnterpriseDto from './Models/ConsommationEnterpriseDto';

const columns = [
  { label: 'Enterprise Name', dataKey: 'enterpriseName', width: 150 },
  { label: 'Hospital Name', dataKey: 'hospitalName', width: 150 },
  { label: 'Insurance Name', dataKey: 'insuranceName', width: 150 },
  { label: 'Visite', dataKey: 'visite', width: 100, numeric: true },
  { label: 'Total Facture', dataKey: 'totalFacture', width: 150, numeric: true },
  { label: 'Total Couvert', dataKey: 'totalCouvert', width: 150, numeric: true },
  { label: 'Total No Couvert', dataKey: 'totalNoCouvert', width: 150, numeric: true },
  { label: 'Total Part Solis', dataKey: 'totalPartSolis', width: 150, numeric: true },
  { label: 'Total Part Patient', dataKey: 'totalPartPatient', width: 150, numeric: true },
  { label: 'Total Part Patient Facture', dataKey: 'totalPartPatientFacture', width: 200, numeric: true },
  { label: 'Total Part Solis Facture', dataKey: 'totalPartSolisFacture', width: 200, numeric: true },
];

const VirtuosoTableComponents: TableComponents<ConsommationEnterpriseDto> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index: number, row: ConsommationEnterpriseDto) {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align={column.numeric || false ? 'right' : 'left'}
        >
          {row[column.dataKey as keyof ConsommationEnterpriseDto]}
        </TableCell>
      ))}
    </React.Fragment>
  );
}

const TableConsommationEnterpriseDto: React.FC = () => {
  const [data, setData] = useState<ConsommationEnterpriseDto[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServicesFactureTotalInOne.findByConsommationEnterpriseDto();
        setData(response.data); // Assuming your API response returns an object with a 'data' property
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Paper style={{ height: '100vh', width: '100%' }}>
      <TableVirtuoso
        data={data}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={(index, row) => rowContent(index, row)}
      />
    </Paper>
  );
};

export default TableConsommationEnterpriseDto;
