// currentCouverturepharmaSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FactureTotalInOneDto from '../../../Components/Espace_Patients/FactureTotalinOne/model/Dto/FactureTotalInOneDto';
import IdataModelCouvertureDtos from '../../../Components/ASSURANCES/Configurations/CouverturestypeMedicament/Models/IdataModelCouvertureDtos';



const initialState:IdataModelCouvertureDtos= {
    /// c
     idCouvertureConfig:null,
     ticket:null,
     ticketFacture:null,
     particulie:null,
     access:null,
     secter:null,
     plafondOrdonence:null,
     createdBy:null,
     dateCreated:null,
  //// services ser
   idService:null,
   serviceName:null,  //replace service
  // code_category co
  
   idCategoryCode:null,
   categoryCode:null,  // replace type_category
    /// enterprise e
     idEnterprise:null,
     enterpriseName:null,
    //// insurance i
     idInsurance:null,
     insuranceName:null
  
  
    }

const currentCouverturepharmaSlice = createSlice({
  name: 'currentCouverturepharma',
  initialState,
  reducers: {
    updatecurrentCouverturepharma: (state, action: PayloadAction<IdataModelCouvertureDtos>) => {
        
      Object.assign(state, action.payload);
    },
    clearcurrentCouverturepharma: (state) => {
      return initialState;
    },
  },
});

export const { updatecurrentCouverturepharma, clearcurrentCouverturepharma } = currentCouverturepharmaSlice.actions;
export default currentCouverturepharmaSlice.reducer;
