import http from "../../../AxiosRequest/httpcommon";
import IdataModelListMedicamentPrix from "../Models/ListMedicamentPrix";
const getAll = (params:any) => {
  return http.get<Array<IdataModelListMedicamentPrix >>("/MedicamentTariff", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelListMedicamentPrix >>("/sortedMedicamentTariff");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelListMedicamentPrix >>("/lastCode");
}

const create = (listeMedicament_id: any,
    pharmacies_id:any,data: IdataModelListMedicamentPrix ) => {
  return http.post<IdataModelListMedicamentPrix >(`/medicamentTariff/${listeMedicament_id}/${pharmacies_id}`, data);
};

const update = (id: any, data: IdataModelListMedicamentPrix ) => {
  return http.put<IdataModelListMedicamentPrix >(`/medicamentTariff/${id}`, data);
};

const findFirstByListeMedicamentIdAndPharmaciesIdOrderByIdDesc =(
    listeMedicamentId: any,
    pharmaciesId:any) => {
    return http.get<IdataModelListMedicamentPrix>(`/getPriceOfMedicament/${listeMedicamentId}/${pharmaciesId}`);
  };

const remove = (id: any) => {
  return http.delete<any>(`/MedicamentTariff/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/MedicamentTariff`);
};

const findByTypeTypeName = (typeName: string) => {
  return http.get<Array<IdataModelListMedicamentPrix >>(`/MedicamentTariff?typeName=${typeName}`);
};

const ServiceTarifMedicament = {
    findFirstByListeMedicamentIdAndPharmaciesIdOrderByIdDesc,
  getAllTypeWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByTypeTypeName,
};

export default ServiceTarifMedicament;