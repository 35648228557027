import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'; // Importing the question mark icon

interface MyModalProps {
  show: boolean;
  onHide: () => void;
  onClickYes:()=> void;
}

export const QuestionValidation = (props: MyModalProps) => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {/* Implementing the big question mark icon */}
        <div style={{ textAlign: 'center' }} className='mb-2'>
          <FontAwesomeIcon icon={faQuestionCircle} size="5x" />
        </div>
        <div style={{ textAlign: 'center' }}>
          Are you sure you want to perform this operation?
        </div>
      </Modal.Body> <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button variant="primary"  onClick={props.onClickYes}>
          Yes
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
