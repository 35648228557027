import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { ISmember } from "../Interfaces/ISmember";
import ServiceSmember from "../Services/ServiceSmember";
import IdataModelSmemberDtos from "../Models/IdataModelSmemberDtos";
import IdataModelPMemberDtos from "../../AdherantPrencipales/Models/IdataModelPMemberDtos";

const initialTypeState = {
 
  //table enterpriseAssurance   ta1
  idInterprise:0,
  accountInterprise:0,
  enterpriseName: '',
  couvertureDu: '',
  couvertureAau: '',
  resilierSociete:'',

///table PMember pa
  idPmember:0,
  codeAssuree: '',
  nomPrenomAffilie: '',
  cotisationExact: '',
  account:0,
  genre: '',
  catégorieSoins: '',
  email: '',
  telephones: '',
  adresseAffilie: '',
  autresInformations: '',

  plafond_ambilatoire: '',
  plafond_hospitalisation: '',
  plafond_pharmacy: '',
  plafond_ORL: '',
  plafond_urugence: '',
  plafond_ophtalmologie: '',
  category_code: '',
  plafond_dentisteries: '',
  resilier: '',
  dataResiliation: '',
  photoMember: '',
  dateNaissance: '',
  createdBy: '',
  dateCreated: '',
  tva:'',
  etatCivil:'',
 ////table socialInsurance so
  idSociete:0,
  societeName: ''
};

export const TableSmember:React.FC<ISmember>=props=>{
    

  const [AyantDroit, setAyantDroit] = useState<Array<IdataModelSmemberDtos>>([]);

  const [pushprincipalMemberData, setpushprincipalMemberData] = useState<IdataModelPMemberDtos>(initialTypeState);

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchAyantDroit, setSearchAyantDroit] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  
  const getRequestParams = (idSociete:any,idInterprise:any,idPmember:any,searchayantDroit:string, thepage: number, thepageSize: number) => {
    let params = {
      idSociete:idSociete,
      idInterprise:idInterprise,
      idPmember:idPmember,
      ayantDroit:searchayantDroit,
      page: thepage,
      size:thepageSize
    };
  
    if (idSociete) {
      params["idSociete"] = idSociete;
    }
    if (idInterprise) {
      params["idInterprise"] = idInterprise;
    }
    if (idPmember) {
      params["idPmember"] = idPmember;
    }
    if (searchayantDroit) {
      params["ayantDroit"] = searchayantDroit;
    }
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearchAyantDroit = (e: ChangeEvent<HTMLInputElement>) => {
    const searchAyantDroit = e.target.value;
    setSearchAyantDroit(searchAyantDroit);
  };
  
  const findByAyantDroit = () => {
    ServiceSmember.findByAyantDroit(pushprincipalMemberData.idSociete,pushprincipalMemberData.idInterprise,pushprincipalMemberData.idPmember,searchAyantDroit)
      .then((response: any) => {
        const {ayantDroit } = response.data;
        setAyantDroit(ayantDroit);
        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const retrieveAyantDroit = useCallback(() => {
    const params = getRequestParams(pushprincipalMemberData.idSociete,pushprincipalMemberData.idInterprise,pushprincipalMemberData.idPmember,searchAyantDroit, page, pageSize);
    ServiceSmember.getAll(params)
      .then((response: any) => {
        const {ayantDroit , totalPages} = response.data;
        setAyantDroit(ayantDroit);
        setCount(totalPages);
        console.log(response.data);
        console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });  }, [AyantDroit,count]);

  useEffect(() => {
    retrieveAyantDroit();
    setpushprincipalMemberData(props.onGetPMemberDtos);
  },[retrieveAyantDroit,props.onGetPMemberDtos,page, pageSize]);
  
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const setActiveAyantDroit = (dataAyantDroit: IdataModelSmemberDtos, index: number) => {
    setCurrentIndex(index); 
     props.onEdit(dataAyantDroit);
     props.onHide();
    console.log(dataAyantDroit);
    console.log(index);
  };
  
  const OnDeleteAyantDroit = (id:any) => {
  
    ServiceSmember.remove(id)
      .then((response: any) => {
        console.log(response.data);
            })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
    return(
          <>
          <Row className="mb-3">
        
        <Stack  direction="horizontal" gap={2}>
             
         <Form.Group controlId="formFilter" className="col col-sm-6">
              <InputGroup>
                  <Form.Control size="sm"
                   type="text"
                    name="searchAyantDroit" 
                      placeholder="Filtre "
                      value={searchAyantDroit}
                      onChange={onChangeSearchAyantDroit}  />
              </InputGroup>
          </Form.Group>
                  <Button variant="outline-dark btn-sm"
                   onClick={findByAyantDroit}>Cherchez</Button>            
            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
           </Stack>   
        
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              
              <th>codeAyantDroit</th>
              <th>ayantDroit</th>
              <th>epouse</th>
              <th>plus</th>
              <th>Status</th>
              <th>Code</th>
            </tr>
          </thead>
          <tbody>
          {AyantDroit.length>0 ? (
            AyantDroit.map((i, index) => (
                
                  <tr key={i.id }>
                 
                  <td>{i.codeAyantDroit}</td>
                  <td>{i.ayantDroit}</td>
                  <td>{i.epouse}</td>
                  <td>{i.enfantplus}</td>
                  <td>{i.resilierSMember}</td>
                  <td>{i.accountSmember}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setActiveAyantDroit(i, index)}>Edit</Button>
  
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteAyantDroit(i.id)}>Delete</Button>
                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no Type Doctors</td>
              </tr>
            )}
  
          </tbody>
        </Table>
        </Row>
  
          </>
      );
  }