import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelCouverture from "../Models/IdataModelCouverture";
import IdataModelCouvertureDtos from "../Models/IdataModelCouvertureDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelCouvertureDtos>>("/couvertureconfig", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<IdataModelCouverture>>("/sortedcouvertureconfig");
};

const create = 
(socialinsuranceId:any,
  enterprisesAssuranceId:any,
  
  categoriesInsuranceCodeId:any,
  servicesId:any,
  
  data: IdataModelCouverture) => {
  return http.post<IdataModelCouverture>(`/${socialinsuranceId}/${enterprisesAssuranceId}/${categoriesInsuranceCodeId}/${servicesId}/couvertureconfig`, data);
};

const update = (id: any, data: IdataModelCouverture) => {
  return http.put<any>(`/couvertureconfig/${id}`, data);
  
};

const remove = (id: any) => {
  return http.delete<any>(`/couvertureconfig/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/couvertureconfig`);
};

const findByenterpriseName = (socialinsuranceId:any,enterpriseName: string) => {
  return http.get<Array<IdataModelCouvertureDtos>>(`/couvertureconfig?socialinsuranceId=${socialinsuranceId}&enterpriseName=${enterpriseName}`);
};
const findByInsuranceIdAndEnterpriseIdAndSecteurAndService = (idInsurance:any,idEnterprise: any,secter:string,service: string,type_category: string) => {
  return http.get<Array<IdataModelCouvertureDtos>>(`/couvertureconfig/${idInsurance}/${idEnterprise}/${secter}/${service}/${type_category}`);
};

const findByInsuranceIdAndEnterpriseIdAndSecteurAndidCouvertureConfig = (idInsurance:any,idEnterprise: any,secter:string,idService: any,type_category: string) => {
  return http.get<Array<IdataModelCouvertureDtos>>(`/couvertureconfigIdConfig/${idInsurance}/${idEnterprise}/${secter}/${idService}/${type_category}`);
};

const CouvertureconfigService = {
  getAllWithoutPagination,
  getAll,
  create,
  update,
  remove,
  removeAll,
  findByenterpriseName,
  findByInsuranceIdAndEnterpriseIdAndSecteurAndService,
  findByInsuranceIdAndEnterpriseIdAndSecteurAndidCouvertureConfig
};

export default CouvertureconfigService;