// ModalComponent.tsx
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Card, Col, Container, Row } from 'react-bootstrap';
import IdataModelTypeDoctor from '../../Components/Doctor/TypesDoctors/Models/IdataModelTypeDoctor';
import { EditNamDoctor } from '../../Components/Doctor/Names/NameDoctorOperation/EditNomDoctor';
import { ConsultationDoctor } from "../../Components/Doctor/Names/Tables/ConsultationDoctor";
import IdataModeConsultationDtos from "../../Components/Doctor/Names/Models/IdataModelConsultationDtos";
import { UserAjoutNomDoctor } from '../Doctor/Names/NameDoctorOperation/PartieUtilisateur/UserAjoutNomDoctor';
import { UserConsultationDoctor } from '../Doctor/Names/Tables/PartieUtilizateur/UserConsultationDoctor';


interface ModalProps {
  show: boolean;
  handleClose: () => void;
}


const initialTypeState = {
    id: null,
    typeName: '',
    accountType:0,
    createdBy: '',
    dateCreatedBy: new Date()
  };
  const initialNamDoctorDtos = {
    accountNameDoctor:'',
     accountTypeDoctor:'',
     doctorName:'',
     
    prescriptPar:'',
     doctorNameId:'',
     doctorTypeId:'',
     hospitalId:'',
     typehospital:'',
     hospitalAccount:'',
     referenceId:'',
     categorySoinsId:'',
     categorySoinsName:'',
     hospitalName:'',
     priceConsultation:'',
     typeName:''
      };
export const ModalAddDoctorUserPage: React.FC<ModalProps> = ({ show, handleClose }) => {

    const [EditList, setEditList] = useState<IdataModelTypeDoctor>(initialTypeState);

    const onCurrentTypeDoctor = (TypeDoctor: IdataModelTypeDoctor) => {
        setEditList(TypeDoctor);
      };

    const [EditListDoctorConsultation,setEditListDoctorConsultation] = 
    useState<IdataModeConsultationDtos>(initialNamDoctorDtos)

    const onCurrentDoctor = (Doctor: IdataModeConsultationDtos) => {
        setEditListDoctorConsultation(Doctor);
      };

    const [isSwichLayerTypeDoctor,setSwichLayerTypeDoctor] = useState(true);
    const [isSwichLayerDoctor,setSwichLayerDoctor] = useState(true);
    
  return (
    <Modal
    size="lg"
     show={show} 
     onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un médecin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
      <Container fluid>
 <Row>
       
                <Col> 
                <br />
                <Card>
            <Card.Body>
            {isSwichLayerDoctor?
            
                (
                <UserAjoutNomDoctor/>
                ):(
<EditNamDoctor show={isSwichLayerDoctor}  onHide={() => setSwichLayerDoctor(true)}
             EditInfoNamDoctor={EditListDoctorConsultation} /> 
                )
                }
                <UserConsultationDoctor show={isSwichLayerDoctor}
                  onHide={() => setSwichLayerDoctor(false)}
                  OnEditNameDoctor={onCurrentDoctor}
                 />
 
   </Card.Body>
        </Card>
        <br />
                   </Col>

            </Row>
          
        </Container>
        </Modal.Body>
      
    </Modal>
  );
}

