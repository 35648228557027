import http from "../../../AxiosRequest/httpcommon";
import IdataModelService from "../Models/IdataModelService";
const getAll = (params:any) => {
  return http.get<Array<IdataModelService>>("/ServicesPharma", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelService>>("/sortedServicesPharma");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelService>>("/ServicesPharmaAccount");
}

const create = (socialinsuranceId:any,data: IdataModelService) => {
  return http.post<IdataModelService>(`/ServicesPharma/${socialinsuranceId}`, data);
};

const update = (id: any, data: IdataModelService) => {
  return http.put<any>(`/ServicesPharma/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/ServicesPharma/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/ServicesPharma`);
};

const findByTypeTypeName = (socialinsuranceId:any,typeName: string) => {
  return http.get<Array<IdataModelService>>(`/ServicesPharma?socialinsuranceId=${socialinsuranceId}&&typeName=${typeName}`);
};

const ServicePharmacy = {
  getAllTypeWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByTypeTypeName,
};

export default ServicePharmacy;