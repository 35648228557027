import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { useState, ChangeEvent, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import IdataModelHospitalType from "../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import { Ifactureservie } from "../interface/Ifactureservie";
import FactureServieDto from "../Model/Dto/FactureServieDto";
import ServicesFactureServie from "../services/ServiceFactureServie";


const FactureServieDtoinit ={

    /// facture fa
      /// facture fa
    idNewViste:'',
    receiptBill:'',
    patientName:'',
    typeAssuree:'',
    patientCode:'',
    CodeSoins:'',
    createdBy:'',
    dateCreatedVisite:'',
    timeCreated:'',

    //social Insuarance so
     idinsurance:'',
     insuranceName:'',

    /// Enterprise   e
     idEnterprise:'',
     accountEnterprise:'',
     enterpriseName:'',
     couvertureDu:'',
     couvertureAau:'',
     modePayment:'',
     frequancePayment:'',

    /// pmember   p
     idpmember:'',
     codeAssuree:'',
     nomPrenomAffilie:'',
     cotisationExact:'',
     accountPmember:'',
     CatégorieSoins:'',

    /// hospital h
     idhospital:'',
     hospitalName:'',
     referenceId:'',
     typehospital:'',
     hospitalAccount:'',

///// typeBons Visite  tvi
     idVisite:'',
     typeNameVisite:'',
     accountVisite:'',

    ///// typeBon declaration verifivcation rembourcement b
     idtypeBonverification:'',
     typeBonVerificationName:'',
     accountVerificationName:'',
    /// typeBon Commend  tc
     idTypeBonCommend:'',
     typeBonCommendName:'',
     accountBonCommend:'',
 }


    const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];
  
    let HospitalName='';

export const TableFactureServie:React.FC=()=>{
      

const [isNewVisite, setisNewVisite] = useState<Array<FactureServieDto>>([]);
const [currentIndex, setCurrentIndex] = useState<number>(-1);

const [searchReceiptBill, setsearchReceiptBill] = useState<string>("");

const [hospital, setHospital] = useState(defaultinitiaHospitalType);

const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];

const getRequestParams = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     hospitalName:string,
     startDate:string,
     endDate:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    hospitalName:hospitalName,
    startDate:startDate,
    endDate:endDate,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }
  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }
  if (startDate) {
    params["startDate"] = startDate;
  }
  if (endDate) {
    params["endDate"] = endDate;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};
const handleChangeTypeHospital = (e: { target: {value: any; }; }) => {
  HospitalName=e.target.value;
    }
    const getHospitalWithoutPagination = () => {  
      HospitalTypeService.getAllHospitalNameWithoutPagination()
        .then((response: any) => {
          setHospital(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

const onChangesearchReceiptBill = (e: ChangeEvent<HTMLInputElement>) => {
  const searchReceiptBill = e.target.value;
  setsearchReceiptBill(searchReceiptBill);
};

const findAllFactureServie = () => {
  
    const params = getRequestParams(52,'','hospitalNamevalue' , 'startDateValue' ,'endDateValue' , page, pageSize);

    ServicesFactureServie.findBySocialinsuranceIdRecieptAndHospitalBetweenDateServiePage(params)
    .then((response: any) => {
      const {findAll} = response.data;
      setisNewVisite(findAll);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });

};
const retrieveInfo = () => {
  const params = getRequestParams(52,searchReceiptBill,'hospitalNamevalue' , 'startDateValue' ,'endDateValue' , page, pageSize);

  ServicesFactureServie.findBySocialinsuranceIdRecieptAndHospitalBetweenDateServiePage(params)

    .then((response: any) => {

      const {findAll , totalPages} = response.data;

      setisNewVisite(findAll);
      setCount(totalPages);

      console.log(params);

      
    })
    .catch((e: Error) => {
      console.log(e);
    });

};
useEffect(() => {
  retrieveInfo();
  getHospitalWithoutPagination();
},[page , pageSize]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  return(
        <>
        <Row className="mb-3">
      
      <Stack  direction="horizontal" gap={2}>
                <Form.Control className="me-auto"
                  placeholder="Filtre le Bon..."
                  value={searchReceiptBill}
                   onChange={onChangesearchReceiptBill} 
                  />
                <Button variant="outline-dark btn-sm"
                 onClick={findAllFactureServie}>Cherchez</Button>
                 

<Form.Group controlId="formGridCheckbox" className="col col-sm-3">
<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">hopital..</option>
  {hospital &&
hospital.map((hospital) => (
    <option key={hospital.id} value={hospital.id} >{hospital.hospitalName}</option>
))}
    
</Form.Select>
</Form.Group>          
          {"Page:"}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
         
         </Stack>   
        <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>id</th>
            <th>Enterprise</th>
            <th>Expiration</th>
            <th>Code</th>
            <th>Adherent</th>
            <th>Patient</th>
            <th>Bon</th>
            <th>Soignant</th>
            <th>visite</th>
            <th>Temp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {isNewVisite.length>0 ? (
          isNewVisite.map((i, index) => (
                <tr key={i.idfactureServie }>
                <td>{i.enterpriseName}</td>
                <td>{i.couvertureAau}</td>
                <td>{i.nomPrenomAffilie}</td>
                <td>{i.codeAssuree}</td>
                <td>{i.patientName}</td>
                <td>{i.hospitalName}</td>
                <td>{i.receiptBill}</td>
                <td>{i.typeAssuree}</td>
                <td>{i.dateCreatedVisite}</td>
                <td>{i.timeCreated}</td>
                <td>
                </td>
              </tr>
            ))
          ) : ( 
            <tr>
              <td colSpan={3}>no Type </td>
            </tr>
          )}

        </tbody>
      </Table>
          </Row>

        </>
    );
}