import http from "../../../AxiosRequest/httpcommon";
import IdataModelMedicament from "../Models/ListMedicament";
const getAll = (params:any) => {
  return http.get<Array<IdataModelMedicament>>("/ListeMedicament", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelMedicament>>("/sortedListeMedicament");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelMedicament>>("/lastCode");
}

const create = (data: IdataModelMedicament) => {
  return http.post<IdataModelMedicament>("/ListeMedicament", data);
};

const update = (id: any, data: IdataModelMedicament) => {
  return http.put<any>(`/ListeMedicament/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/ListeMedicament/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/ListeMedicament`);
};

const findByTypeTypeName = (typeName: string) => {
  return http.get<Array<IdataModelMedicament>>(`/ListeMedicament?typeName=${typeName}`);
};

const ServiceMedicament = {
  getAllTypeWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByTypeTypeName,
};

export default ServiceMedicament;