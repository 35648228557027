import { useCallback, useEffect, useState } from 'react';
import { InputGroup, Form, Row } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Redux/Store/store';

import { useHistory } from 'react-router-dom';
import HospitalTypeService from '../../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService';
import IdataModelTypeDoctor from '../../../TypesDoctors/Models/IdataModelTypeDoctor';
import TypeDoctorServices from '../../../TypesDoctors/Services/TypeDoctorServices';
import DoctorService from '../../Services/NamDoctorServices';
import IdataModelHospitalType from '../../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType';

import { FaSave, FaTimes } from 'react-icons/fa';


let accountIncrement=0


let MonStructure:string='';

const initialNamDoctor = {
    id : '',
    doctorName:'',
    prescriptPar:'',
    accountNameDoctor:'',
    priceConsultation:'',
    createdBy: '',
    dateCreatedBy: new Date()
    };
const defaultinitiaTypeDoctor: Array<IdataModelTypeDoctor> = [];


export const UserAjoutNomDoctor:React.FC=() =>{
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  
  const [specialty, setSpecialty] = useState(defaultinitiaTypeDoctor);
  const history = useHistory();
  const [hospitalData, setHospitalData] = useState<Array<IdataModelHospitalType>>([]);

  const [accountIncrement, setAccountIncrement] = useState(0);
    
  const [isInput,setInput]=useState(initialNamDoctor);

  const [IdtypeDoctor,setIdtypeDoctor]=useState(0);
  

  const [isTypeName, setIsTypeName] = useState('')

      const getLastAccountType = useCallback(() => {
        DoctorService.getLastAccountType()
          .then((response: any) => {
            setAccountIncrement(response.data.accountNameDoctor);
           // console.log(response.data.accountNameDoctor);
          });
      }, []);

      const findByHospitalId = useCallback(() => {
        if (StructuresData.length > 0) {
          let concatenatedString: string = StructuresData.join(", ");
          console.log("concatenatedString", concatenatedString);
          const MonStructure = concatenatedString;
    
          HospitalTypeService.findByhospitalName(MonStructure)
            .then((response: any) => {
              const { hospital } = response.data;
              setHospitalData(hospital);
              console.log("response.data.id", hospital);
            });
        }
      }, [StructuresData]);
      const getTypeDoctorWithoutPagination = () => {
  
        TypeDoctorServices.getAllTypeDoctorWithoutPagination()
      
          .then((response: any) => {
            setSpecialty(response.data);
           // console.log(speciality);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      };
      useEffect(() => {
        getLastAccountType();   
        getTypeDoctorWithoutPagination(); 
      });
      useEffect(() => { 
        findByHospitalId();  
     }, []); //
    
      useEffect(() => {
        if (StructuresData.length===0) {
            // Redirect to the desired route when StructuresData becomes null
            history.push('/FacturationConsultation');
          // Add your logic here
        }
      }, [StructuresData, history]); // This effect will re-run whenever StructuresData changes
    

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
      setInput({ ...isInput, [e.target.name]: e.target.value });
      console.log(e.target.value);
    }
    
    
      const resetButton = () => {
        setInput(initialNamDoctor)}

        const save = () => {  
          if(IdtypeDoctor===0 && hospitalData[0].id===0){
            console.log('error while saving data')
          }else{
            if(accountIncrement===undefined){
              setAccountIncrement(6000000)
            }
            let data = {
              doctorName: isInput.doctorName,
              prescriptPar:`${isTypeName}/${isInput.doctorName}`,
              accountNameDoctor: accountIncrement+1,
              priceConsultation:isInput.priceConsultation,
              createdBy:'Ghost ZIla',
              dateCreatedBy: new Date()
            };
    
        DoctorService.create(IdtypeDoctor,hospitalData[0].id ,data)
            .then((response: any) => {
              setInput({
              id: response.data.id,
              doctorName: response.data.doctorName,
              prescriptPar:response.data.prescriptPar,
              accountNameDoctor: response.accountIncrement+1,
              priceConsultation:response.data.priceConsultation,
              createdBy:response.data.createdBy,
              dateCreatedBy: response.data.dateCreatedBy
              });

              console.log(response.data);
              console.log(data);
              setInput(initialNamDoctor)
            })
            .catch((e: Error) => {
              console.log(e);
            });
          };
        };

        const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
           

          
      const handleChangeTypeDoctor = (selectedValue: string) => {
        const selectedOption = specialty.find(item => item.id === parseInt(selectedValue));
      
        if (selectedOption) {
          const selectedTypeName = selectedOption.typeName;
      
          // Now you have both id and typeName
         // console.log('Selected Type Doctor Id: ', typeDoctorId);
         // console.log('Selected Type Doctor Name: ', selectedTypeName);
      
          // You can update the state or perform any other actions with the selected values
          setIdtypeDoctor(selectedOption.id);
          setIsTypeName(selectedTypeName);
      
            console.log('selectedTypeName is  ', isTypeName);
        } else {
          console.log('Unable to find selectedOption for value: ', selectedValue);
        }
      };
    return(
        <>
 < form className="form-edit" onSubmit={onFormSubmit}>
 
         <Row className="mb-3">   
         
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">

<Form.Label>Type Docteur</Form.Label>
<Form.Select
className="form-control"
onChange={(e) => handleChangeTypeDoctor(e.target.value)}
>
<option value="0">Choose..</option>
{specialty &&
specialty.map((specialty) => (
  <option key={specialty.id} value={specialty.id}>
    {specialty.typeName}
  </option>
))}
</Form.Select>

</Form.Group>
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Nom du Docteur</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="doctorName" name="doctorName" value={isInput.doctorName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formprixConsultation" className="col col-sm-6">
            <Form.Label>prix de Consultation</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="priceConsultation" name="priceConsultation" value={isInput.priceConsultation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Form.Select className="form-control">
   <option value="0">{StructuresData}</option>
</Form.Select>
</Form.Group>
         </Row>

         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
        <button type="submit" onClick={save} className="me-4 btn btn-success btn-sm btn-block">
        <FaSave className="me-2" />
        Save
      </button>
      <button type="reset" onClick={resetButton} className="me-4 btn btn-danger btn-sm btn-block">
        <FaTimes className="me-2" />
        Cancel
      </button>
        </Form.Group>
      </Row>
 </form>
        </>
    );
}