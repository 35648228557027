import http from "../../../../AxiosRequest/httpcommon";
import ConsommationAdherantPrincipal from "../../../Consomations/Models/ConsommationAdherantPrincipal";
import ConsommationAdherantPrincipalDetails from "../../../Consomations/Models/ConsommationAdherantPrincipalDetails";
import ConsommationDepertmentServiceDto from "../../../Consomations/Models/ConsommationDepertmentServiceDto";
import ConsommationDetailDto from "../../../Consomations/Models/ConsommationDetailDto";
import ConsommationEnterpriseDto from "../../../Consomations/Models/ConsommationEnterpriseDto";
import ConsommationEntrepriseInOneDto from "../../../Consomations/Models/ConsommationEntrepriseInOneDto";
import ConsommationHospitalDepartmentDto from "../../../Consomations/Models/ConsommationHospitalDepartmentDto";
import ConsommationHospitalServiceDto from "../../../Consomations/Models/ConsommationHospitalServiceDto";
import ConsommationStructureDetailsDto from "../../../Consomations/Models/ConsommationStructureDetailsDto";
import ConsommationStructureDto from "../../../Consomations/Models/ConsommationStructureDto";
import FactureTotalInOneDto from "../model/Dto/FactureTotalInOneDto";
import FactureTotalInOne from '../model/FactureTotalInOne';


const getAllVisitelWithoutPagination = () => {
  return http.get<Array<FactureTotalInOne>>("/sortedFactureTotalInOne");
};



const findBySocialinsuranceIdHospitalNamePage = (params:any) => {
  return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdHospitalNamePage", { params });
};
const findBySocialinsuranceIdHospitalNameBetweenDateServiePage = (params:any) => {
  return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdHospitalNameBetweenDateServiePage", { params });
};
const checkBillNumberDateIdServiceIdDepartment = (params:any) => {
  return http.get<Array<FactureTotalInOneDto>>("/checkBillNumberDateIdServiceIdDepartment", { params });
};

const findBySocialinsuranceIdHospitalNameBetweenDateServieList= (params:any) => {
  return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdHospitalNameBetweenDateServieList",{ params });
};

const findBySocialinsuranceIdRecieptBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdRecieptBetweenDateServiePageInOne", { params });
  };

const findBySocialinsuranceIdRecieptBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdRecieptBetweenDateServieListInOne");
  };

  const findBySocialinsuranceIdCodeAssureBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdCodeAssureBetweenDateServiePageInOne", { params });
  };

const findBySocialinsuranceIdCodeAssureBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdCodeAssureBetweenDateServieListInOne");
  };


  const findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServiePageInOne", { params });
  };

const findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServieListInOne");
  };

  const findBySocialinsuranceIdBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdBetweenDateServiePageInOne", { params });
  };

const findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServieList = () => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServiePage", { params });
  };

const SocialinsuranceIdBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDto>>("/SocialinsuranceIdBetweenDateServieListInOne");
  };

  const findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServiePageInOne", { params });
  };

const findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDto>>("/findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServieListInOne");
  };


const findById=(id:any)=>{
  return http.get<Array<FactureTotalInOne>>(`/FactureTotalInOne/${id}`);
}

const createFactureTotal = 
(hemaNewVisitePatient_id:any,
  typeDepartment_id:any,
  services_id:any,
  data: FactureTotalInOne) => {
  return http.post<FactureTotalInOne>(`/${hemaNewVisitePatient_id}/${typeDepartment_id}/${services_id}/FactureTotalInOne`, data);
};


const update = (id: any, data: FactureTotalInOne) => {
  return http.put<any>(`/FactureTotalInOne/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/FactureTotalInOne/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/FactureTotalInOne`);
};
////// consomation
const findByConsommationAdherantPrincipal = () => {
  return http.get<Array<ConsommationAdherantPrincipal>>("/findConsommationAdherantPrincipal");
};

const findByConsommationAdherantPrincipalDetails = () => {
  return http.get<Array<ConsommationAdherantPrincipalDetails>>("/findConsommationAdherantPrincipalDetails");
};

const findByConsommationDepertmentServiceDto = () => {
  return http.get<Array<ConsommationDepertmentServiceDto>>("/findConsommationDepertmentServiceDto");
};

const findByConsommationDetailDto = () => {
  return http.get<Array<ConsommationDetailDto>>("/findConsommationDetailDto");
};

const findByConsommationEnterpriseDto = () => {
  return http.get<Array<ConsommationEnterpriseDto>>("/findByConsommationEnterpriseDtoList");
};

const findByConsommationHospitalDepartmentDto = () => {
  return http.get<Array<ConsommationHospitalDepartmentDto>>("/findConsommationHospitalDepartment");
};

const findByConsommationHospitalServiceDto = () => {
  return http.get<Array<ConsommationHospitalServiceDto>>("/findConsommationHospitalService");
};

const findByConsommationStructureDetailsDto = () => {
  return http.get<Array<ConsommationStructureDetailsDto>>("/findConsommationStructureDetailsDto");
};
const findByConsommationStructureDto = () => {
  return http.get<Array<ConsommationStructureDto>>("/findConsommationStructureDto");
};
const findByConsommationEntrepriseInOneDto = () => {
  return http.get<Array<ConsommationEntrepriseInOneDto>>("/findByConsommationDetailDtoList");
};

const ServicesFactureTotalInOne= {
  
  findByConsommationAdherantPrincipal,
  findByConsommationAdherantPrincipalDetails,
  findByConsommationDepertmentServiceDto,
  findByConsommationDetailDto,
  findByConsommationEnterpriseDto,
  findByConsommationHospitalDepartmentDto,
  findByConsommationHospitalServiceDto,
  findByConsommationStructureDetailsDto,
  findByConsommationStructureDto,
  findByConsommationEntrepriseInOneDto,
  ////
  checkBillNumberDateIdServiceIdDepartment,
  findBySocialinsuranceIdHospitalNameBetweenDateServiePage,
  findBySocialinsuranceIdHospitalNameBetweenDateServieList,
    findBySocialinsuranceIdRecieptBetweenDateServiePageInOne,
    findBySocialinsuranceIdRecieptBetweenDateServieListInOne,
    findBySocialinsuranceIdCodeAssureBetweenDateServiePageInOne,
    findBySocialinsuranceIdCodeAssureBetweenDateServieListInOne,
    findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServiePageInOne,
    findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServieListInOne,
    findBySocialinsuranceIdBetweenDateServiePageInOne,
          SocialinsuranceIdBetweenDateServieListInOne,
    findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServiePageInOne,
    findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServieListInOne,
    
    findById,
    update,
    remove,
    removeAll,
    getAllVisitelWithoutPagination,
    createFactureTotal,
    findBySocialinsuranceIdHospitalNamePage
};

export default ServicesFactureTotalInOne;







