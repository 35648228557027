import http from "../../../../../../AxiosRequest/httpcommon";
import Classee from "../Models/Classee";
import ClasseeDto from "../Models/ClasseeDto";

const getAll = (params:any) => {
  return http.get<Array<ClasseeDto>>("/remboursementClassPharmacy", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<Classee>>("/sortedremboursementClassPharmacy");
};
const getLastAccountType=()=>{
  return http.get<Array<Classee>>("/remboursementClassPharmacyAccountEnterpriseName");
}

const create = (socialinsuranceId:any,pharmacies_id:any,data: Classee) => {

  return http.post<Classee>(`/${socialinsuranceId}/${pharmacies_id}/remboursementClassPharmacy`, data);
};

const update = (id: any, data: Classee) => {
  return http.put<any>(`/remboursementClassPharmacy/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/remboursementClassPharmacy/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/remboursementClassPharmacy`);
};

const findByEnterpriseName = (socialinsuranceId:any,enterpriseName: string) => {
  return http.get<Array<ClasseeDto>>(`/remboursementClassPharmacy?socialinsuranceId=${socialinsuranceId}&enterpriseName=${enterpriseName}`);
};

const ServiceremboursementClass = {
  getAllWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByEnterpriseName
};

export default ServiceremboursementClass;