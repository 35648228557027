import { Row, Table, Stack, Button, Form,Col } from "react-bootstrap"
import { ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { Itype } from "../Interfaces/Itype";
import TypeService from "../Services/TypeServices";
import { ModelPriceMateriels } from "../TypeOperations/PriceMaterialModel/ModelPriceMateriels";
import IdataModelHospitalType from "../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import IdataModelTypeMateriel from "../Models/IdataModelTypeMateriel";

const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];
const initialTypeMaterial = {
  id : '',
  typeName: '',
  typeMateriel:'',
  account:'',
  createdBy: '',
  dateCreatedBy:new Date()
};
export const TypeTableMaterial:React.FC<Itype>=props=>{
    
  const [hospital, setHospital] = useState(defaultinitiaHospitalType);

  const [type, setType] = useState<Array<IdataModelTypeMateriel>>([]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [modalShow, setModalShow] = useState(false);


  const [typeHospitalId,setTypeHospitalId]= useState('Choose');
  const [EditTypeMaterial, setEditTypeMaterial] = useState<IdataModelTypeMateriel>(initialTypeMaterial);


  
  const [searchTypeTypeName, setsearchTypeTypeName] = useState<string>("");
  
  const [page, setPage]= useState(1);
  const [count, setCount]= useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  
  const getHospitalWithoutPagination = () => {  
    HospitalTypeService.getAllHospitalNameWithoutPagination()
      .then((response: any) => {
        setHospital(response.data);

      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
  const getRequestParams = (searchtypeName:string, thepage: number, thepageSize: number) => {
    let params = {
      typeName:searchtypeName,
      page: thepage,
      size:thepageSize
    };
  
    if (searchtypeName) {
      params["typeName"] = searchtypeName;
    }
  
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangesearchTypeTypeName = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTypeTypeName = e.target.value;
    setsearchTypeTypeName(searchTypeTypeName);
  };
  
  const findByTypeTypeName = () => {
    TypeService.findByTypeTypeName(searchTypeTypeName)
      .then((response: any) => {
        const {tousMaterielles } = response.data;
        setType(tousMaterielles);
        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const retrieveType = () => {
    const params = getRequestParams(searchTypeTypeName, page, pageSize);
  
    TypeService.getAll(params)
  
      .then((response: any) => {
  
        const {tousMaterielles , totalPages} = response.data;
  
        setType(tousMaterielles);
        setCount(totalPages);
  
        console.log(response.data);
        console.log(params);
  
      })
  
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    retrieveType();
    getHospitalWithoutPagination();
  },[page, pageSize]);
  
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
    const handleChangeTypeHospital = (e: { target: {value: any; }; }) => {
      setTypeHospitalId(e.target.value);
      console.log('typeHospitalId',typeHospitalId);
        }
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const refreshList = () => {
    retrieveType();
    setCurrentIndex(-1);
  };
  
  const setActiveType = (datatype: IdataModelTypeMateriel, index: number) => {
    setCurrentIndex(index); 
     props.onEditType(datatype);
     props.onHide();
    console.log(datatype);
    console.log(index);
  };

  const setActiveMaterialPrice = (idataModelType: IdataModelTypeMateriel, index: number) => {

    if(typeHospitalId==='Choose'){
      console.log('halii dganii missing hospitalId') 
   }
    else{
      setCurrentIndex(index); 
      setEditTypeMaterial(idataModelType);
      setModalShow(true);
      console.log(idataModelType);
    }
  };
  
  const OnDeleteType = (id:any) => {
  
    TypeService.remove(id)
      .then((response: any) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
    return(
          <>
          <Row className="mb-3">
        
        <Stack  direction="horizontal" gap={2}>
                  <Form.Control  size="sm" className="me-auto"
                    placeholder="Filtre le type docteur..."
                    value={searchTypeTypeName}
                     onChange={onChangesearchTypeTypeName} 
                    />
                  <Button variant="outline-dark btn-sm"
                   onClick={findByTypeTypeName}>Cherchez</Button>
           </Stack>   
           <div className="mt-3">
              
              {"Items per Page: "}
                  <select onChange={handlePageSizeChange} value={pageSize}>
                    {pageSizes.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                  <Row>
                    <Col>
                    <Pagination
                  className="my-3"
                  count={count}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
                    </Col>
                    
                    <Col>
    <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
  
      <Form.Label>Type Hopital </Form.Label>
      <Form.Select className="form-control" 
       onChange={handleChangeTypeHospital}>
         <option defaultValue={0}>Choose</option>
        {hospital &&
      hospital.map((hospital) => (
          <option key={hospital.id} value={hospital.id} >{hospital.hospitalName}</option>
      ))}
      </Form.Select>
  </Form.Group>              
              </Col>             
            </Row>
                
              </div>
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>id</th>
              <th>Materiel </th>
              <th>Compte</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
          {type.length>0 ? (
            type.map((i, index) => (
                
                  <tr key={i.id }>
                  <td>{i.id}</td>
                  <td>{i.typeName}</td>
                  <td>{i.account}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setActiveType(i, index)}>Edit</Button>
  
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteType(i.id)}>Delete</Button>
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => setActiveMaterialPrice(i, index)}>Prix</Button>

                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no Type</td>
              </tr>
            )}
  
          </tbody>
        </Table>
        <ModelPriceMateriels
              show={modalShow}
              onHide={() => setModalShow(false)}
              editinfo={EditTypeMaterial} 
              hospitalid={typeHospitalId} />

        </Row>
  
          </>
      );
  }