import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Card, Row, Form, InputGroup, Button, Col } from "react-bootstrap";
import IdataModelTypeDoctor from "../Models/IdataModelRegisretationNonAffilie";
import TypeDoctorServices from '../Services/NonAffilieService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../Redux/Store/store';
import { INonAffilie } from '../Interfaces/INonAffilie';
import NonAffiliesDto from '../Models/NonAffiliesDto';
import IdataModelRegisretationNonAffilie from '../Models/IdataModelRegisretationNonAffilie';

  interface INonAffilieList {
    show: boolean; 
    onHide: () => void; 
    EditNonAffilierDto: NonAffiliesDto;
  }

  export const EditNonAffilier :React.FC<INonAffilieList>=props=> {

    const preferredName = useSelector((state: RootState) => state.user.preferred_username);

    const initialTypeState = {
      id: null,
      fullNamePatient:'',
      phoneNumberPatient:'',
      addressPatient:'',
      cdnpatient:'',
      genderPatient:'',
      age:'',
      codePatient:0,
      createdBy:'',
      createdDate:'',
    };
    const initialTypeStateDto = {
      id: null,
      fullNamePatient:'',
      phoneNumberPatient:'',
      addressPatient:'',
      cdnpatient:'',
      genderPatient:'',
      age:'',
      codePatient:0,
      createdBy:'',
      createdDate:'',
      // hospital
      hospitalId:0,
      hospitalName:''
    };
    const [isInputDto,setInputDto]=useState<NonAffiliesDto>(props.EditNonAffilierDto);
    
    const [isInput,setInput]=useState<IdataModelRegisretationNonAffilie>(initialTypeState);


    function getCurrentDate(): string {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      // Months are zero-based, so we add 1 to get the correct month
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    
  
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputDto({ ...isInputDto, [name]: value });
       // console.log(event.target)
        }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditTypeDoctors = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
         
        const formattedDate = getCurrentDate();
      
        let data = {
          fullNamePatient: isInputDto.fullNamePatient,
          phoneNumberPatient: isInputDto.phoneNumberPatient,
          addressPatient: isInputDto.addressPatient,
          cdnpatient: isInputDto.cdnpatient,
          genderPatient: isInputDto.genderPatient,
          age: isInputDto.age,
          codePatient: isInputDto.codePatient,
          createdBy:preferredName,
          createdDate: formattedDate
        };

            TypeDoctorServices.update(isInputDto.id,data)
            .then((response: any) => {
              setInput({
                fullNamePatient: response.data.fullNamePatient,
                phoneNumberPatient: response.data.phoneNumberPatient,          
                addressPatient: response.data.addressPatient,         
                cdnpatient: response.data.cdnpatient,         
                genderPatient: response.data.genderPatient,
                age: response.data.age,
                codePatient:response.data.codePatient,
                createdBy:response.data.createdBy,
                createdDate: response.data.createdDate
              });
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInputDto);
          }

          const EditCancel = () => {
            setInputDto(initialTypeStateDto);
            setInput(initialTypeState)
            props.onHide();
          }
              
          
  useEffect(() => {

    setInputDto(props.EditNonAffilierDto)

      },[props])
      
      return (
        <>
          <form className="form-edit" onSubmit={onFormSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formFullName">
                <Form.Label>Full Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="fullNamePatient"
                    value={isInputDto.fullNamePatient}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="formPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="phoneNumberPatient"
                    value={isInputDto.phoneNumberPatient}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
              
            </Row>
            <Row className="mb-3">
             
              <Form.Group as={Col} controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="addressPatient"
                    value={isInputDto.addressPatient}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
             
              <Form.Group as={Col} controlId="formCDN">
                <Form.Label>CDN</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="cdnpatient"
                    value={isInputDto.cdnpatient}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGender">
                <Form.Label>Gender</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="genderPatient"
                    value={isInputDto.genderPatient}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="formAge">
                <Form.Label>Age</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="age"
                    value={isInputDto.age}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Col>
                
               <Button type="submit" onClick={EditTypeDoctors} className="me-4 btn btn-success btn-sm btn-block">Update</Button>
                <Button type="button" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">
                  Cancel
                </Button>
                
              </Col>
            </Row>
          </form>
        </>
      );
  }
