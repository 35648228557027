/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from "react";
import { Form, Row, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { Button, Divider, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from '../../../../Redux/Store/store';
import { clearMedicalLivraisonRecordsToprint } from "../../../../Redux/slices/currentMedical/currentMedicalLivraisonArrayToPrint";
import { useDispatch } from "react-redux";


  interface INewFactureLivraisonMedicamentDto {
    componentToPrint: React.MutableRefObject<null>;

  }


export const TableImprimeFacturePharmacy:React.FC<INewFactureLivraisonMedicamentDto>=props=>

{

   const { componentToPrint } = props;


const currentMedicalLivraison=useSelector((state:RootState)=>state.currentMedicalLivraisonArrayToPrint);


const operaterName=useSelector((state:RootState)=>state.user.preferred_username);

const  DispatchCurrantMedecineModifier=useDispatch()

    const [isfactureCouvert, setisfactureCouvert] = useState(0);
    const [isfactureNoCouvert, setisfactureNoCouvert] = useState(0);
    const [isfactureTotal, setisfactureTotal] = useState(0);

    const [isPartMituelle, setisPartMituelle] = useState(0);
    const [isPartPatient, setisPartPatient] = useState(0);
    const [isPartMituelleFacture, setisPartMituelleFacture] = useState(0);
    const [isPartPatientFacture, setisPartPatientFacture] = useState(0);


    const [isfactureApeyer, setisfactureApayer] = useState(0);
    const isgetselecterCurrantBillPushed=useSelector((state:RootState)=>state.currentBill);
    const isConfigPlafondFacture = useSelector((state: RootState) => state.plafondFacturePrService.plafondFacturePushed);


const AnalyseFactureToPrint = (selectedRowToPrint:any) => {  

  if(selectedRowToPrint.length===0)
{
setisfactureCouvert(0)
setisfactureNoCouvert(0)
setisPartPatient(0)
setisPartMituelle(0)
setisfactureTotal(0)

setisPartMituelleFacture(0)
setisPartPatientFacture(0)

setisfactureApayer(0)

}
else
{

// Create a Map to store the grouped results
const groupedByService = new Map<string, {
total: number,
quantity: number,
FactureCouvert: number,
FactureNocouvert: number,
PartMutuelle: number,
PartPatient: number ,
PartMutuelleFacture: number,
PartPatientFacture: number // New key for PartPatient 

}>();
let partMutuelleEqual = 0;
let partPatientEqual = 0;

let partMutuelleFactureEqual = 0;
let partPatientFactureEqual = 0;

    let partMutuelleGreater = 0;
    let partPatientGreater = 0;

    let partMutuelleFactureGreater = 0;
    let partPatientFactureGreater = 0;
// Iterate through the array and update the Map
selectedRowToPrint.forEach((
  entry: { service: any; quantity: any; partTotal: any; plafondFacture: any; ticketService: any; ticketServiceFacture: any; }) => {
const { service, quantity, partTotal, plafondFacture, ticketService,ticketServiceFacture } = entry;

if (groupedByService.has(service)) {
  // If the service is already in the Map, update the total and quantity
  const existingEntry = groupedByService.get(service)!;
  existingEntry.total += partTotal;
  existingEntry.quantity += quantity;

  // Check if the total is less than or equal to plafondFacture
  if (existingEntry.total <= plafondFacture) {
      existingEntry.FactureCouvert = existingEntry.total;
      existingEntry.FactureNocouvert = 0;
  } else {
      // If total is greater than plafondFacture, set FactureCouvert to plafondFacture and calculate FactureNocouvert
      existingEntry.FactureCouvert = plafondFacture;
      existingEntry.FactureNocouvert = existingEntry.total - plafondFacture;
  }

  // Calculate PartMutuelle based on FactureCouvert and ticket
  existingEntry.PartMutuelle = (existingEntry.FactureCouvert * (100 - ticketService)) / 100;
  existingEntry.PartMutuelleFacture = (existingEntry.FactureCouvert * (100 - ticketServiceFacture)) / 100;
  
  // Calculate PartPatient based on FactureCouvert and ticket
  existingEntry.PartPatient = (existingEntry.FactureCouvert * ticketService) / 100;
  existingEntry.PartPatientFacture = (existingEntry.FactureCouvert * ticketServiceFacture) / 100;

  console.log("Couverture Facture Decalre",ticketService)
  console.log("COuverture Facture",ticketServiceFacture)
} else {

  // If the service is not in the Map, add a new entry
  const factureCouvert = partTotal <= plafondFacture ? partTotal : plafondFacture;
  const factureNocouvert = partTotal <= plafondFacture ? 0 : partTotal - plafondFacture;

  // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
  const partMutuelle = (factureCouvert * (100 - ticketService)) / 100;
  const partPatient = (factureCouvert * ticketService) / 100;

  const partMutuelleFacture = (factureCouvert * (100 - ticketServiceFacture)) / 100;
  const partPatientFacture = (factureCouvert * ticketServiceFacture) / 100;
    // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
    partMutuelleEqual = (isConfigPlafondFacture * (100 - ticketService)) / 100;
   partPatientEqual = (isConfigPlafondFacture * ticketService) / 100;

   partMutuelleFactureEqual = (isConfigPlafondFacture * (100 - ticketServiceFacture)) / 100;
   partPatientFactureEqual = (isConfigPlafondFacture * ticketServiceFacture) / 100;

   partMutuelleGreater =  (isConfigPlafondFacture * (100 - ticketService)) / 100;
   partPatientGreater = (isConfigPlafondFacture * ticketService) / 100;

   partMutuelleFactureGreater =(factureCouvert * (100 - ticketServiceFacture)) / 100;
   partPatientFactureGreater =  (factureCouvert * ticketServiceFacture) / 100;

  console.log("Couverture Facture Decalre",ticketService)
  console.log("Couverture Facture",ticketServiceFacture)

  groupedByService.set(service, {
      total: partTotal,
      quantity: quantity,
      FactureCouvert: factureCouvert,
      FactureNocouvert: factureNocouvert,
      PartMutuelle: partMutuelle,
      PartPatient: partPatient,
      PartMutuelleFacture: partMutuelleFacture,
      PartPatientFacture: partPatientFacture
  });
}
});

// Calculate the total, FactureCouvert, FactureNocouvert, PartMutuelle, and PartPatient for all services

let totalAllServices = 0;
let factureCouvertAllServices = 0;
let factureNocouvertAllServices = 0;
let partMutuelleAllServices = 0;
let partPatientAllServices = 0;

let partMutuelleFactureAllServices = 0;
let partPatientFactureAllServices = 0;


groupedByService.forEach(({ total, FactureCouvert, FactureNocouvert, PartMutuelle, PartPatient,PartMutuelleFacture, PartPatientFacture }) => {
totalAllServices += total;
factureCouvertAllServices += FactureCouvert;
factureNocouvertAllServices += FactureNocouvert;
partMutuelleAllServices += PartMutuelle;
partPatientAllServices += PartPatient;

partMutuelleFactureAllServices += PartMutuelleFacture;
partPatientFactureAllServices += PartPatientFacture;
});

console.log("Total All Services:", totalAllServices);
console.log("FactureCouvert All Services:", factureCouvertAllServices);
console.log("FactureNocouvert All Services:", factureNocouvertAllServices);

console.log("PartMutuelle All Services:", partMutuelleAllServices);
console.log("PartPatient All Services:", partPatientAllServices);


console.log("PartMutuelleFacture All Services:", partMutuelleFactureAllServices);
console.log("PartPatientFacture All Services:", partPatientFactureAllServices);

const totalCombined = isgetselecterCurrantBillPushed.totalFactureInOne + totalAllServices;
const plafondFacture = Number(isConfigPlafondFacture);

let caseValue;

// Debugging output
console.log("totalCombined:", totalCombined);
console.log("plafondFacture:", plafondFacture);

if (totalCombined < plafondFacture) {
    caseValue = 'less';
} else if (totalCombined > plafondFacture) {
    caseValue = 'greater';
} else if (totalCombined === plafondFacture) {
    caseValue = 'equal';
} else {
    caseValue = 'default';
}

switch (caseValue) {
    case 'less':
        console.log("totalAllServicesRecent + totalAllServices < isConfigPlafondFacture");

        setisfactureTotal(totalAllServices);
        setisfactureCouvert(factureCouvertAllServices);
        setisfactureNoCouvert(factureNocouvertAllServices);

        setisPartMituelle(partMutuelleAllServices);
        setisPartPatient(partPatientAllServices);

        setisPartMituelleFacture(partMutuelleFactureAllServices);
        setisPartPatientFacture(partPatientFactureAllServices);
        setisfactureApayer(partPatientFactureAllServices + factureNocouvertAllServices);

        // Update fields
        break;

    case 'greater':
        console.log("totalAllServicesRecent + totalAllServices > isConfigPlafondFacture");

        if (isgetselecterCurrantBillPushed.totalFactureInOne===null) {

        setisfactureTotal(totalAllServices);
        setisfactureCouvert(isConfigPlafondFacture);
        setisfactureNoCouvert(totalAllServices-isConfigPlafondFacture);
          // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
       // const partMutuelleGreater = 0;
       // const partPatientGreater = totalAllServices;

      //  const partMutuelleFactureGreater = 0;
      //  const partPatientFactureGreater = totalAllServices;

        setisPartMituelle(partMutuelleGreater);
        setisPartPatient(partPatientGreater);

        setisPartMituelleFacture(partMutuelleFactureGreater);
        setisPartPatientFacture(partPatientFactureGreater);
        setisfactureApayer(partPatientFactureGreater + Number(totalAllServices-isConfigPlafondFacture));

        // Update fields
      } else{ 
        
        setisfactureTotal(totalAllServices);
        setisfactureCouvert(0);
        setisfactureNoCouvert(totalAllServices);
        // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
         const partMutuelleGreater2 = 0;
         const partPatientGreater2 = totalAllServices;
 
         const partMutuelleFactureGreater2 = 0;
         const partPatientFactureGreater2 = totalAllServices;
 
         setisPartMituelle(partMutuelleGreater2);
         setisPartPatient(partPatientGreater2);
 
         setisPartMituelleFacture(partMutuelleFactureGreater2);
         setisPartPatientFacture(partPatientFactureGreater2);
         setisfactureApayer(partPatientFactureGreater2);
 
         // Update fields
      } 
       
        break;

    case 'equal':
        console.log("totalAllServicesRecent + totalAllServices === isConfigPlafondFacture");

        setisfactureTotal(totalAllServices);
        setisfactureCouvert(isConfigPlafondFacture);
        setisfactureNoCouvert(0);

       
        setisPartMituelle(partMutuelleEqual);
        setisPartPatient(partPatientEqual);

        setisPartMituelleFacture(partMutuelleFactureEqual);
        setisPartPatientFacture(partPatientFactureEqual);
        setisfactureApayer(partPatientFactureEqual);

        // Update fields
        break;

    default:
        console.log("Default value of if", totalCombined);
        break;
}


}
  };

useEffect(() => {
  AnalyseFactureToPrint(currentMedicalLivraison)
    },[AnalyseFactureToPrint])



      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
     
      const deleteFacturation =()=>{

        DispatchCurrantMedecineModifier(clearMedicalLivraisonRecordsToprint())
    }
      return (
        <>
        
        <form className="form-edit" onSubmit={onFormSubmit}>
       <div>
       <span ref={(el) => (componentToPrint.current = el as null )}> 
    <Row className="p-3">
    <Form.Group  >
              <br/>
              <Form.Label ><u><strong>Hopital ********************************</strong></u></Form.Label>
              <br/>
             <Form.Label ><strong>Raison sociale</strong>: {currentMedicalLivraison[0]?.hospitalName} <strong>TEL </strong>: {77490341}  </Form.Label>
             <Form.Label ><strong>NIF</strong>:{'4000018630'} <strong>RC</strong>:{'77230'} <strong>BP</strong>:{'438'}</Form.Label>
             <Form.Label ><strong>Avenue</strong>:{'universite N 27'} <strong>Centre fiscal</strong>:{'DPMC'}</Form.Label>
             <Form.Label ><strong>secteur Activite</strong>:{currentMedicalLivraison[0]?.typehospital} <strong>Forme juridique</strong>:{'S A'}</Form.Label>
                  <div  className="mb-0">
                    <Form.Check
                      inline
                      label="Avec TVA"
                      name="group1"
                      type={'checkbox'}
                      id={`inline-${'checkboxA'}-1`}
                    />
                    <Form.Check
                      inline
                      label="Sans TVA"
                      defaultChecked
                      name="group1"
                      type={'checkbox'}
                      id={`inline-${'checkboxB'}-2`}
                    />
                  </div>
             <Form.Label ><u><strong>Doctor **********************************</strong></u></Form.Label>
             <br/>
             <Form.Label ><strong>Nom</strong>:{currentMedicalLivraison[0]?.doctorName} <strong>Specialite</strong>:{currentMedicalLivraison[0]?.typedoctorName}</Form.Label>
             <br></br>
             <Form.Label ><u><strong>Patient **********************************</strong></u></Form.Label>
             <br/>
             <Form.Label ><strong>Enterprise</strong>:{currentMedicalLivraison[0]?.enterpriseName} <strong>Ctg</strong>: {currentMedicalLivraison[0]?.CatégorieSoins}</Form.Label>
          
             <div key={`inline-${'checkbox'}`} className="mb-0">
                    <Form.Check
                      inline
                      label="Avec TVA"
                      name="group1"
                      type={'checkbox'}
                      id={`inline-${'checkbox'}-1`}
                    />
                    <Form.Check
                      inline
                      label="Sans TVA"
                      defaultChecked
                      name="group1"
                      type={'checkbox'}
                      id={`inline-${'checkbox'}-2`}
                    />
                  </div>
             <Form.Label ><strong>Adherant Principal</strong>:{currentMedicalLivraison[0]?.nomPrenomAffilie} <strong>ID</strong>:{currentMedicalLivraison[0]?.codeAssuree}</Form.Label>
             <br/>
             <Form.Label ><strong>Patient</strong>:{currentMedicalLivraison[0]?.patientName} <strong>Bon</strong>:{currentMedicalLivraison[0]?.receiptBill}</Form.Label>
            
             <br/>
              <Form.Label><strong>Plafond Sur Facture</strong>:{isConfigPlafondFacture}</Form.Label>
              <br/>
              <Form.Label><strong>Facture Resente</strong>:{isgetselecterCurrantBillPushed.totalFactureInOne}</Form.Label>
              <br/>
              <Form.Label><strong>Total de Facture</strong>:{isfactureTotal}</Form.Label>
              <br/>

              <Form.Label><strong>Facture Couvert</strong>:{isfactureCouvert}</Form.Label>
              <br/>
              <Form.Label><strong>Facture No Couvert</strong>:{isfactureNoCouvert}</Form.Label>
              <br/>
              <Form.Label><strong>Part Patient</strong>: {isPartPatientFacture}
               <strong>Part Mituelle</strong>: {isPartMituelleFacture}</Form.Label>
              <br/>
             <Form.Label><strong>Facture A payer</strong>:{isfactureApeyer}</Form.Label>
              <br/>
              <Form.Label><strong>Operateur</strong>: {operaterName} </Form.Label>
              <br/>
              
          
          </Form.Group>   
          <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Intitule</th>
                    <th>Q</th>
                    <th>PU</th>
                    <th>TOTAL</th>
                    <th> p solis</th>
                    <th>P Patient</th>
                    <th>Couvert<strong>%</strong></th>
                  </tr>
                </thead>
                <tbody>
                {currentMedicalLivraison.length>0 ? (
                      currentMedicalLivraison.map((i, index) => (
                          
                            <tr key={index+1}>
                            <td>{i.prestation}</td>
                            <td>{i.quantity}</td>
                            <td>{i.pricePrestation}</td>
                            <td>{i.partTotalFacture}</td>
                            <td>{i.partMutuelleFacture}</td>
                            <td>{i.partpatientFacture}</td>
                            <td>{i.ticketServiceFacture}</td>
                            <td>
              
                            </td>
                          </tr>
                        ))
                      ) : ( 
                        <tr>
                          <td colSpan={3}>no data</td>
                        </tr>
                      )}
                </tbody>
              </Table>
              <br/>
              <br/>
            <Form.Label><strong>.... DevelopedBy @hectorally94 ....</strong></Form.Label>
</Row>
      </span>
        
       </div>
          <div>

        <Stack 
        direction="row"
        divider={<Divider orientation="vertical" flexItem />
        }
        spacing={2}>
    
<ReactToPrint

trigger={() => (
  
<Button id={"print"} variant="contained" color="primary">
  {" "}{" "}
  {"Print"}{" "}
</Button>

)}
content={() => componentToPrint.current}
/>  
         
          </Stack>
          </div>
        </form>
                
        </>
      );
    }