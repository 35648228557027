import { useEffect, useState } from "react";
import IdataModelPriceActesDtos from "../../../PriceActes/Models/IdataModelPriceActesDtos";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { AddPrice } from "../../../PriceActes/PriceOperations/AddPrice";
import { EditAddPrice } from "../../../PriceActes/PriceOperations/EditAddPrice";
import { PriceActeTable } from "../../../PriceActes/Tables/PriceActeTable";
import IdataModeDetailsTypeDtos from "../../Models/IdataModeDetailsTypeDtos";
import { PriceActeTableAcceuille } from "../../../PriceActes/Tables/PriceActeTableAcceuille";

  const initialPriceModelDtos = {// soinsDetails e
    idSoinsDetails:0,
     code:0,
     intitule:'',
     excl:'',
     acc:'',
     dateCreatedBy:'',

    // soins s
    idSoins:0,
     codeSoins:'',
     intituleSoins:'',

    // typePrestation t
     idTypePrestation:0,
     typePrestationsName:'',
     accountType:0,

    // price b
     idPrice:0,
     price:0,
     priceCreatedBy:'',
    // hospital h
     hospitalId:'',
     hospitalName:''
  };

  const initialPriceModel= {
    // e
    idSoinsDetails:0,   // Use wrapper type Long for the primary key to handle null values gracefully
    code: 0,
    intitule: '',
    excl: '',
    acc: '',
    dateCreatedBy: '',
   // typePrestation
    IdTypePrestation: 0,
    typePrestationsName: '',
    accountType: '',
     // Soins m
    idSoins:0,        // Use wrapper type Long for the primary key to handle null values gracefully
    codeSoins: 0,    // Changed to Long for consistency and nullability
    intituleSoins: ''
  };
  interface EditDetailsTypeList {
    show: boolean; 
    hospitalid:any;
    onHide: () => void; 
    editinfodetailstype: IdataModeDetailsTypeDtos;
  }

  export const ModelPriceActesAcceuille:React.FC<EditDetailsTypeList>=props=>
    {
        const [isPriceModel,setPriceModel]= useState(true);
        const [typeHospitalId,setTypeHospitalId]= useState(0);
        const [EditPriceDtos, setEditPriceDtos] = useState<IdataModelPriceActesDtos>(initialPriceModelDtos);

        const [EditPrice, setEditPrice] = useState<IdataModeDetailsTypeDtos>(initialPriceModel);

        const onCurrantPriceEditDtos=(CurrantData:IdataModelPriceActesDtos)=>{
            setEditPriceDtos(CurrantData);
        }
        
  useEffect(() => {
    setEditPrice(props.editinfodetailstype)
    setTypeHospitalId(props.hospitalid)
      },[props.editinfodetailstype, props.hospitalid])
        return(
            <>
    <Modal {...props}
    size="lg"
    backdrop="static"
    keyboard={false}
    
     aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Prix de soins selon Hopital   
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
      <Container fluid>
                <Row>
                <Col> 
                <Card>
            <Card.Body>
           {isPriceModel?
                (
                    <AddPrice show={false}
                     hospitalid={typeHospitalId}
                     onHide={() => setPriceModel(false)}
                    editcurrantacts={EditPrice} />
                ):(
      <EditAddPrice 
      show={isPriceModel}
       onHide={() => setPriceModel(true)}
        editpriceDtos={EditPriceDtos}
             /> 
                )
  }
     <PriceActeTableAcceuille 
     show={isPriceModel} 
     onHide={() => setPriceModel(false)}
     onEditpriceActe={onCurrantPriceEditDtos}

          />
            </Card.Body>
        </Card>
                </Col>
                </Row>
            </Container>
      </Modal.Body>
    
    </Modal>
           
            </>
        );
    }