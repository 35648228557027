import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import IdataModeConsultationDtos from "../../../Doctor/Names/Models/IdataModelConsultationDtos";
import { TableAdherantPrincipal } from "../Tables/TableAdherantPrincipal/TableAdherantPrincipal";
import IdataModelSmemberDtos from "../../../ASSURANCES/Beneficieres/Models/IdataModelSmemberDtos";
import IdataModelPMemberDtos from "../../../ASSURANCES/AdherantPrencipales/Models/IdataModelPMemberDtos";
import { TableAyantDroitsHome } from "../Tables/TableAyantDroits/TableAyantDroitsHome";
import { ConsultationPmember } from "../Components/ConsultationPmember";
import { ConsultationSmember } from "../Components/ConsultationSmember";
import { ActionButton } from "../../../../Pages/Dashbord/ActionButton ";


import { useHistory } from 'react-router-dom';  
const initialTypeStatePmemberDtos = {
   
  //table enterpriseAssurance   ta1
  idInterprise:0,
  accountInterprise:0,
  enterpriseName: '',
  couvertureDu: '',
  couvertureAau: '',
  resilierSociete:'',

///table PMember pa
  idPmember:0,
  codeAssuree: '',
  nomPrenomAffilie: '',
  cotisationExact: '',
  account:0,
  genre: '',
  catégorieSoins: '',
  email: '',
  telephones: '',
  adresseAffilie: '',
  autresInformations: '',

  plafond_ambilatoire: '',
  plafond_hospitalisation: '',
  plafond_pharmacy: '',
  plafond_ORL: '',
  plafond_urugence: '',
  plafond_ophtalmologie: '',
  category_code: '',
  plafond_dentisteries: '',
  resilier: '',
  dataResiliation: '',
  photoMember: '',
  dateNaissance: '',
  createdBy: '',
  dateCreated: '',
  tva:'',
  etatCivil:'',
 ////table socialInsurance so
  idSociete:0,
  societeName: ''
 
};
const initialTypeStateSmemberDtos = {
   
  ////table socialInsurance so
  idSociete:0,
   SocieteName:'',

  //table enterpriseAssurance   ta1
   idInterprise:0,
   accountInterprise:0,
   enterpriseName:'',
   resilierSociete:'',
  ///table PMember pa
   idPmember:0,
   codeAssuree:'',
   nomPrenomAffilie:'',
   cotisationExact:'',
   account:0,
   Genre:'',
   catégorieSoins:'',
   email:'',
   Telephones:'',
   AdresseAffilie:'',
   AutresInformations:'',

   plafond_ambilatoire:'',
   plafond_hospitalisation:'',
   plafond_pharmacy:'',
   plafond_ORL:'',
   plafond_urugence:'',
   plafond_ophtalmologie:'',
   category_code:'',
   plafond_dentisteries:'',
   resilier:'',
   dataResiliation:'',
   PhotoMember:'',
   dateNaissance:'',
   createdBy:'',
   dateCreated:'',
   etatCivil:'',
  //table SMember ma
   id:0,
   codeAyantDroit:'',
   accountSmember:0,
   ayantDroit:'',
   dateNaissanceSmember:'',
   gender:'',
   bloodGroup:'',
   resilierSMember:'',
   dateResiliationSMember:'',
   PhotoMemberSmember:'',
   createdBySmember:'',
   dateCreatedSmember:'',
   epouse:'',
enfantplus:''

  
};
const initialNamDoctorDtos = {
  accountNameDoctor:'',
   accountTypeDoctor:'',
   doctorName:'',
   
   prescriptPar:'',
   doctorNameId:'',
   doctorTypeId:'',
   hospitalId:'',
   referenceId:'',
   typehospital:'',
   hospitalAccount:'',
   categorySoinsId:'',
   categorySoinsName:'',
   hospitalName:'',
   priceConsultation:'',
   typeName:''
    };

    interface ICurrantDoctor {
      show: boolean; 
      onHide: () => void; 
      currantdoctor: IdataModeConsultationDtos;
    }
  export const Home:React.FC<ICurrantDoctor>=props=>
    {
      const componentToPrint = useRef(null);
      const history=useHistory()
      const [isgetCurrantDoctorPushed,setgetCurrantDoctorPushed]=
      useState<IdataModeConsultationDtos>(initialNamDoctorDtos);
      const [isSwichMember,setSwichMember] = useState(true);
      const [show, setShow] = useState(false);

      const handleClose = () => {
        props.onHide()
        setCurrentPushedPrincipalMember(initialTypeStatePmemberDtos);
        history.push('/FacturationConsultation');

        
      }

      const [CurrentPushedPrincipalMember, setCurrentPushedPrincipalMember] = useState<IdataModelPMemberDtos>(initialTypeStatePmemberDtos);

      const onCurrantPcpl=(CurrantAhderantPrincipal:IdataModelPMemberDtos)=>{
        setCurrentPushedPrincipalMember(CurrantAhderantPrincipal)
    }
    const [CurrentPushedBeneficiere, setCurrentPushedBeneficiere] = useState<IdataModelSmemberDtos>(initialTypeStateSmemberDtos);

    const OnCurrentAyantDroit = (currantData: IdataModelSmemberDtos) => {
        setCurrentPushedBeneficiere(currantData);
      };
  useEffect(() => {
    setgetCurrantDoctorPushed(props.currantdoctor)
    
      },[props.currantdoctor])
        return(
     
          
            <>
            <Container fluid>
            <Modal 
     {...props}
    fullscreen
    backdrop="static"
    keyboard={false}
     aria-labelledby="contained-modal-title-vcenter">
      
      <Modal.Body className="show-grid">
      <Container fluid>
      <Row>
                <Col> 
            {isSwichMember?
                (
                  <TableAdherantPrincipal 
                                show={isSwichMember}
                                onHide={() => setSwichMember(false)}
                                onPush={onCurrantPcpl}
                                
                                />
                   ):(
            <TableAyantDroitsHome 
                                show={isSwichMember}
                                onHide={() => setSwichMember(true)} 
                                onPush={OnCurrentAyantDroit}
                                onGetPMemberDtos={CurrentPushedPrincipalMember}            /> 
                )
  }
          {isSwichMember?
                (
                  <>
                  <ConsultationPmember
                                    show={false}
                                    CurrantDoctor={isgetCurrantDoctorPushed}
                                    Currantpmember={CurrentPushedPrincipalMember}
                                    componentToPrint={componentToPrint}                                   />
                </>

                   ):(
            <ConsultationSmember 
            show={false}
            CurrantDoctor={isgetCurrantDoctorPushed} 
            Currantsmember={CurrentPushedBeneficiere}
            componentToPrint={componentToPrint} 
            /> 
                )
  }
                </Col>
      </Row>
      </Container>
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
    </Modal>
            </Container>
   
           
            </>
        );
    }