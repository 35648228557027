/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button, Stack } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import NewFactureSaisieMedicamentDto from "../models/Dto/NewFactureSaisieMedicamentDto";
import ServiceNewFactureSaisieMedicament from "../services/ServiceNewFactureSaisieMedicament";
import currentMedical, { updatecurrentMedical } from "../../../../Redux/slices/currentMedical/currentMedical";
import { LivraisonFactureMedicament } from "../LivraisonMedicament/LivraisonFactureMedicament";
import { addMultipleMedicalRecords, clearMedicalRecords, deleteMedicalRecord } from "../../../../Redux/slices/currentMedical/currentMedicalArray";

interface ItypeModal {
  show: boolean;
  onHide: () => void;
}

export const TableFactureMedicamentNoPayerLivraison: React.FC<ItypeModal> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const isgetselecterCurrantNewVisitePushed = useSelector((state: RootState) => state.currentNewFacture);
  
  const isgetselecterCurrantIdTypeMedicament = useSelector((state: RootState) => state.ConfigAddFactureSaisiePlafond);
  
  const dispatch = useDispatch();
  const dispatchCurrantMedecineList = useDispatch();
  const isCurrantMedecineList = useSelector((state: RootState) => state.currentMedicalArray);
 
  const fetchFactures = useCallback(async () => {
    if (isgetselecterCurrantNewVisitePushed?.idFacture !== 0) {
      try {
        const response = await ServiceNewFactureSaisieMedicament.findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer(
          isgetselecterCurrantNewVisitePushed.idinsurance,
          isgetselecterCurrantNewVisitePushed.receiptBill,
          "No payer"
        );
        const facture = response.data;
        
        console.log("facture value",facture)
      //  setisNewFactureSaisieDto(facture);
      if (facture.length!== 0 ) {
        dispatchCurrantMedecineList(clearMedicalRecords())
        dispatchCurrantMedecineList(addMultipleMedicalRecords(facture))
      }else
      {
        dispatchCurrantMedecineList(clearMedicalRecords())
      }
       
      } catch (e) {
        console.error('Error fetching factures:', e);

        dispatchCurrantMedecineList(clearMedicalRecords())
      }
    }
  }, [isgetselecterCurrantNewVisitePushed,dispatchCurrantMedecineList]);
  
  useEffect(() => {
    fetchFactures();
  }, [fetchFactures]);
  
  

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRowButtonClick = (row: any) => {
    if (Number(isgetselecterCurrantIdTypeMedicament.data) !== 0) {
      dispatch(updatecurrentMedical(row));
      setShowModal(true);
    } else {
      console.log("you need to choose type de medicament");
    }
  };

  const columns: GridColDef[] = [
    { field: 'prestation', headerName: 'Description de medicament pour le patient', width: 400 },
    { field: 'quantity', headerName: 'Quantity' },
    { field: 'statusFacturePayer', headerName: 'Status' },
    { field: 'doctorName', headerName: 'Doctor', width: 100 },
    { field: 'typedoctorName', headerName: 'Type Doctor' },
    { field: 'dateCreatedSaisie', headerName: 'Date Saisie' },
    { field: 'timeSaisie', headerName: 'Time Saisie' },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleRowButtonClick(params.row)}
        >
          Select
        </Button>
      ),
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rows={isCurrantMedecineList}
        getRowId={(row) => row.idFacture}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 9 },
          },
        }}
        pageSizeOptions={[9, 14]}
        autoHeight
      />

      {showModal && (
        <LivraisonFactureMedicament
          show={showModal}
          handleClose={handleCloseModal}
        />
      )}
    </div>
  );
};

