import { Container, Row, Col, Card } from "react-bootstrap";
import { CompListeCotisationClassOuNoClassParIndependant } from "../../../../Components/ASSURANCES/Recouvrements/Comptabillite/CompListeCotisationClassOuNoClassParIndependant";

export const ListeCotisationClassOuNoClassParIndependant=()=>{

    return (

         <Container fluid>
         <Row>
          <Col>
         <br />
           <Card border="secondary" >
             <Card.Body>
             <CompListeCotisationClassOuNoClassParIndependant/>
               </Card.Body>
           </Card>
           <br />
          </Col>
         </Row>
         
         </Container>
    );
}

