import { useEffect, useState } from "react";
import IdataModelDetailsType from "../Models/IdataModelDetailsType";
import { Form, InputGroup, Row } from "react-bootstrap";
import DetailsSoinsTypeService from "../Services/DetailsSoinsTypeService";
import IdataModelTypeCategory from "../../TypeCategory/Models/IdataModelTypeCategory";
import ServiceTypeCategory from "../../TypeCategory/Services/ServiceTypeCategory";

let accountIncrement=0
let TypCatgryId=0

const initialTypeDetails = {
  id: null,
  code: "",
  intitule: "",
  excl: "",
  acc: "",
  dateCreatedBy: "",
};

const defaultinitiaTypeCategory: Array<IdataModelTypeCategory> = [];

export const DetailsType:React.FC=()=> {


const [TypCatgry, setTypCatgry] = useState(defaultinitiaTypeCategory);

const getLastAccountType =()=>{
  DetailsSoinsTypeService.getLastAccountType()
    .then((response: any) => {
        accountIncrement=response.data.accountTypeDetailsSoins
  })
}
const typeSoinsNameWithoutPagination = () => {
  
  ServiceTypeCategory.getAlltypeSoinsNameWithoutPagination()
    .then((response: any) => {
      setTypCatgry(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

useEffect(() => {
  getLastAccountType();
  typeSoinsNameWithoutPagination();
 
});


const [isInput,setInput]=useState<IdataModelDetailsType>(initialTypeDetails);

const handleChange = (e: { target: { name: any; value: any; }; }) => {
  setInput({ ...isInput, [e.target.name]: e.target.value });
}
const handleChangeAcessSoinsCategoris = (e: { target: {value: any; }; }) => {
  TypCatgryId=e.target.value
   console.log('TypCatgryId  ',TypCatgryId);

    }
   
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
  const save = () => {
    // Get current date
const currentDate = new Date();

// Format date as dd-mm-yy
const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;

    if (accountIncrement === undefined) {
      accountIncrement = 30000;
    }

    let data = {
      code: isInput.code,
      intitule: isInput.intitule,
      excl: isInput.excl,
      acc: isInput.acc,
      dateCreatedBy: formattedDate
    };

    DetailsSoinsTypeService.create(TypCatgryId , data)
      .then((response: any) => {
        setInput({
          id: response.data.id,
          code: response.data.code,
          intitule: response.data.intitule,
          excl: response.data.excl,
          acc: response.data.acc,
          dateCreatedBy: response.data.dateCreatedBy
        });
        console.log(response.data);
        console.log(data);
        setInput(initialTypeDetails);
        setTypCatgry(defaultinitiaTypeCategory)
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
        
      const reset = () => {
        setInput(initialTypeDetails);
      }

     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Row className="mb-3">
          <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Intitulé</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="intitule"
                value={isInput.intitule}
                onChange={handleChange}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formGridExcl" className="col col-sm-3">
            <Form.Label>Excl</Form.Label>
            <Form.Select
              name="excl"
              value={isInput.excl}
              onChange={handleChange}
            >
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="formGridAcc" className="col col-sm-3">
            <Form.Label>Acc</Form.Label>
            <Form.Select
              name="acc"
              value={isInput.acc}
              onChange={handleChange}
            >
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </Form.Select>
          </Form.Group>
        </Row>

        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
          <Form.Label>Soins </Form.Label>
    <Form.Select className="form-control" 
        onChange={handleChangeAcessSoinsCategoris}>
        <option value="0">Choose..</option>
      {TypCatgry &&
         TypCatgry.map((TypCatgry) => (
            <option key={TypCatgry.id} value={TypCatgry.id} >
              {TypCatgry.intitule}</option>
))}
    
</Form.Select>
</Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}