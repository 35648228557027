import { useState } from "react";
import IdataModelAdhesionDtos from "../../../Components/ASSURANCES/Adhesions/Models/IdataModelAdhesionDtos";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AddAdhesion } from "../../../Components/ASSURANCES/Adhesions/AdhesionOperations/AddAdhesion";
import { EditAdhesion } from "../../../Components/ASSURANCES/Adhesions/AdhesionOperations/EditAdhesion";
import { TableAdhesion } from "../../../Components/ASSURANCES/Adhesions/Tables/TableAdhesion";



const initialTypeStatedto = {
    idAdhesion:'',
    priceAdhesion:'',
   
    idInterprise:'',
    account:'',
    enterpriseName:'',
   
    idSociete:'',
    SocieteName:''
  };
export const FraisAdhesions:React.FC=()=>{

  
    const [EditListAdherant,setEditListAdherant] = 
    useState<IdataModelAdhesionDtos>(initialTypeStatedto)

    const onCurrentAdhesion = (onCurrant: IdataModelAdhesionDtos) => {
        setEditListAdherant(onCurrant);
      };

    const [isSwichLayerAdherant,setSwichLayerAdherant] = useState(true);
    
    return(
        <>
        <Container fluid>
 <Row>  
                <Col> 
                <br />
                <Card>
            <Card.Body>
            {isSwichLayerAdherant?
                (
                <AddAdhesion/>
                ):(
<EditAdhesion
 show={isSwichLayerAdherant}
   onHide={() => setSwichLayerAdherant(true)}
   EditInfo={EditListAdherant} /> 
                )
                }
 <TableAdhesion
  show={isSwichLayerAdherant}
  onHide={() => setSwichLayerAdherant(false)}
  onEdit={onCurrentAdhesion}
          />
   </Card.Body>
        </Card>  <br />
                   </Col>

            </Row>
          
        </Container>
        </>
    );
}