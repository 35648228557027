import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import IdataModelPMemberDtos from "../../../../ASSURANCES/AdherantPrencipales/Models/IdataModelPMemberDtos";
import { ISmember } from "../../../../ASSURANCES/Beneficieres/Interfaces/ISmember";
import IdataModelSmemberDtos from "../../../../ASSURANCES/Beneficieres/Models/IdataModelSmemberDtos";
import ServiceSmember from "../../../../ASSURANCES/Beneficieres/Services/ServiceSmember";
import { ISmemberHome } from "./interfaces/ISmemberHome";


const initialTypeState = {
 
  //table enterpriseAssurance   ta1
  idInterprise:0,
  accountInterprise:0,
  enterpriseName: '',
  couvertureDu: '',
  couvertureAau: '',
  resilierSociete:'',

///table PMember pa
  idPmember:0,
  codeAssuree: '',
  nomPrenomAffilie: '',
  cotisationExact: '',
  account:0,
  genre: '',
  catégorieSoins: '',
  email: '',
  telephones: '',
  adresseAffilie: '',
  autresInformations: '',

  plafond_ambilatoire: '',
  plafond_hospitalisation: '',
  plafond_pharmacy: '',
  plafond_ORL: '',
  plafond_urugence: '',
  plafond_ophtalmologie: '',
  category_code: '',
  plafond_dentisteries: '',
  resilier: '',
  dataResiliation: '',
  photoMember: '',
  dateNaissance: '',
  createdBy: '',
  dateCreated: '',
  tva:'',
  etatCivil:'',
 ////table socialInsurance so
  idSociete:0,
  societeName: ''
};
const initialTypeStateSmemberDtos = {
   
  ////table socialInsurance so
  idSociete:0,
   SocieteName:'',

  //table enterpriseAssurance   ta1
   idInterprise:0,
   accountInterprise:0,
   enterpriseName:'',
   resilierSociete:'',
  ///table PMember pa
   idPmember:0,
   codeAssuree:'',
   nomPrenomAffilie:'',
   cotisationExact:'',
   account:0,
   Genre:'',
   catégorieSoins:'',
   email:'',
   Telephones:'',
   AdresseAffilie:'',
   AutresInformations:'',

   plafond_ambilatoire:'',
   plafond_hospitalisation:'',
   plafond_pharmacy:'',
   plafond_ORL:'',
   plafond_urugence:'',
   plafond_ophtalmologie:'',
   category_code:'',
   plafond_dentisteries:'',
   resilier:'',
   dataResiliation:'',
   PhotoMember:'',
   dateNaissance:'',
   createdBy:'',
   dateCreated:'',
   etatCivil:'',
  //table SMember ma
   id:0,
   codeAyantDroit:'',
   accountSmember:0,
   ayantDroit:'',
   dateNaissanceSmember:'',
   gender:'',
   bloodGroup:'',
   resilierSMember:'',
   dateResiliationSMember:'',
   PhotoMemberSmember:'',
   createdBySmember:'',
   dateCreatedSmember:'',
   epouse:'',
enfantplus:''

  
}; 
export const TableAyantDroitsHome:React.FC<ISmemberHome>=props=>{
    

  const [AyantDroit, setAyantDroit] = useState<Array<IdataModelSmemberDtos>>([]);
  const [CurrentPushedBeneficiere, setCurrentPushedBeneficiere] = useState<IdataModelSmemberDtos>(initialTypeStateSmemberDtos);

  const [pushprincipalMemberData, setpushprincipalMemberData] = useState<IdataModelPMemberDtos>(initialTypeState);

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchAyantDroit, setSearchAyantDroit] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  const [isResilier, setisResilier] = useState(false);
  
  const getRequestParams = (idSociete:any,idInterprise:any,idPmember:any,searchayantDroit:string, thepage: number, thepageSize: number) => {
    let params = {
      idSociete:idSociete,
      idInterprise:idInterprise,
      idPmember:idPmember,
      ayantDroit:searchayantDroit,
      page: thepage,
      size:thepageSize
    };
  
    if (idSociete) {
      params["idSociete"] = idSociete;
    }
    if (idInterprise) {
      params["idInterprise"] = idInterprise;
    }
    if (idPmember) {
      params["idPmember"] = idPmember;
    }
    if (searchayantDroit) {
      params["ayantDroit"] = searchayantDroit;
    }
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearchAyantDroit = (e: ChangeEvent<HTMLInputElement>) => {
    const searchAyantDroit = e.target.value;
    setSearchAyantDroit(searchAyantDroit);
  };
  
  const findByAyantDroit = () => {
    ServiceSmember.findByAyantDroit(pushprincipalMemberData.idSociete,pushprincipalMemberData.idInterprise,pushprincipalMemberData.idPmember,searchAyantDroit)
      .then((response: any) => {
        const {ayantDroit } = response.data;
        setAyantDroit(ayantDroit);
        setCurrentIndex(-1);
       // console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const retrieveAyantDroit = useCallback(() => {
    const params = getRequestParams(pushprincipalMemberData.idSociete,pushprincipalMemberData.idInterprise,pushprincipalMemberData.idPmember,searchAyantDroit, page, pageSize);
    ServiceSmember.getAll(params)
      .then((response: any) => {
        const {ayantDroit , totalPages} = response.data;
        setAyantDroit(ayantDroit);
        setCount(totalPages);
       // console.log(response.data);
       // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });  }, [page, pageSize, pushprincipalMemberData.idInterprise, pushprincipalMemberData.idPmember, pushprincipalMemberData.idSociete, searchAyantDroit]);

  const checkifPmemberIsResigned= useCallback(() => {
    // console.log('RELISIERRRRRRRRRRRRR',pushprincipalMemberData.resilier)
    if(pushprincipalMemberData.resilier==="Oui"){
      setisResilier(true)
    }else if(pushprincipalMemberData.resilier==="Non"){
      setisResilier(false)
    }else{
console.log('internal error')
    }
  },[pushprincipalMemberData.resilier]);    

  useEffect(() => {
    checkifPmemberIsResigned();
    retrieveAyantDroit();
    setpushprincipalMemberData(props.onGetPMemberDtos);
  },[retrieveAyantDroit, props.onGetPMemberDtos, page, pageSize, checkifPmemberIsResigned]);
  
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const setActiveAyantDroit = (dataAyantDroit: IdataModelSmemberDtos, index: number) => {
    setCurrentIndex(index); 
     props.onPush(dataAyantDroit);
    // props.onHide();
  //  console.log(dataAyantDroit);
  //  console.log(index);
  };
  
  const EditCancel = () => {
    props.onHide();
    props.onPush(CurrentPushedBeneficiere);
    }
    return(
          <>
          {!isResilier? (
 <Row className="mb-3">
        
 <Stack  direction="horizontal" gap={2}>
      
  <Form.Group controlId="formFilter" className="col col-sm-6">
       <InputGroup>
           <Form.Control size="sm"
            type="text"
             name="searchAyantDroit" 
               placeholder="Filtre "
               value={searchAyantDroit}
               onChange={onChangeSearchAyantDroit}  />
       </InputGroup>
   </Form.Group>
           <Button variant="outline-dark btn-sm"
            onClick={findByAyantDroit}>Cherchez</Button>            
     {"Items per Page: "}
         <select onChange={handlePageSizeChange} value={pageSize}>
           {pageSizes.map((size) => (
             <option key={size} value={size}>
               {size}
             </option>
           ))}
         </select>
       <Pagination
         className="my-3"
         count={count}
         page={page}
         siblingCount={1}
         boundaryCount={1}
         variant="outlined"
         shape="rounded"
         onChange={handlePageChange}
       />
    </Stack>   
 
   <Table striped bordered hover size="sm" responsive>
   <thead>
     <tr>
       
       <th>codeAyantDroit</th>
       <th>ayantDroit</th>
       <th>epouse</th>
       <th>plus</th>
       <th>Status</th>
       <th>Code</th>
     </tr>
   </thead>
   <tbody>
   {AyantDroit.length>0 ? (
     AyantDroit.map((i, index) => (
         
           <tr key={i.id }>                
           <td>{i.codeAyantDroit}</td>
           <td>{i.ayantDroit}</td>
           <td>{i.epouse}</td>
           <td>{i.enfantplus}</td>
           <td>{i.resilierSMember}</td>
           <td>{i.accountSmember}</td>
           <td>
           <Stack direction="horizontal" gap={2}>
           <Button className="me-4 btn btn-success btn-sm btn-block"
           onClick={() => setActiveAyantDroit(i, index)}>Moi</Button>
          <Button className="me-4 btn btn-success btn-sm btn-block"
          >Photo</Button>
             </Stack>
           </td>
         </tr>
       ))
     ) : ( 
       <tr>
         <td colSpan={3}>no Type Doctors</td>
       </tr>
     )}

   </tbody>
 </Table>
 <Row className="mb-3">
    <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
        <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
    </Form.Group>
  </Row>
 </Row>
):(
  <Stack direction="horizontal" gap={2}>
 <>
 <button className="me-4 btn btn-danger btn-lg btn-block"><strong>Access au soins Refusé !</strong></button>
 <button type="reset" onClick={EditCancel} className="me-4 btn btn-success btn-lg btn-block"><strong>Retour</strong></button>
</>
  </Stack>
           
          )}
         
  
          </>
      );
  }