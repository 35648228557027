
import { useCallback, useEffect, useState } from "react";
import {Form, Row, Stack, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import NewFactureLivraisonMedicament from "../models/NewFactureLivraisonMedicament";
import ServiceNewFactureLivraisonMedicament from "../services/ServiceNewFactureLivraisonMedicament";
import ServiceNewFactureSaisieMedicamentDtoMedicament from "../services/ServiceNewFactureSaisieMedicament";
import ServiceTarifMedicament from "../../../ListMedicament/Services/ServiceTarifMedicament";
import IdataModelListMedicamentPrix from '../../../ListMedicament/Models/ListMedicamentPrix';
import NewFactureSaisieMedicamentDto from "../models/Dto/NewFactureSaisieMedicamentDto";
import { deleteMedicalRecord } from "../../../../Redux/slices/currentMedical/currentMedicalArray";

      const newFactureSaisie={
  createdBy:'',
  dateCreatedLivree:'',
  timeLivree:'',

  statusFacturePayer:'',
  statusFactureDeclare:'',
  statusFactureVerifier:'',
  statusFactureRembourceAvecAutreFacture:'',
  statusFactureRembourceUneFacture:''
      } 
      
const initialTypeState = {
  id: null,
  price: 0,
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
interface EditMedicamentModalProps {
  show: boolean;
  handleClose: () => void;
}
export const LivraisonFactureMedicament: React.FC<EditMedicamentModalProps> = ({
  show,
  handleClose,
}) => {


 
  const isgetCurrantCouverturePushed=useSelector((state:RootState)=> state.currentCouverturepharma);

  const CurrantPatientMedecinePushed=useSelector((state:RootState)=> state.currentMedical);

 
const CurrantPharmacyInfo = useSelector((state: RootState) => state.detailsPharmacy);
const dispatchDeleteItem = useDispatch();
// console.log('CurrantPharmacyInfo:',CurrantPharmacyInfo );

//console.log('isgetselecterCurrantNewVisitePushed:',isgetselecterCurrantNewVisitePushed );

//console.log('isgetCurrantCouverturePushedPharmacy:',isgetCurrantCouverturePushed );

  let isServiceConfig=useSelector((state:RootState)=>state.ConfigAddFactureSaisiePlafond);
 // console.log('isServiceConfig.data',isServiceConfig.data);

  const preferredName = useSelector((state: RootState) => state.user.preferred_username);
  const isCurrantMedecineList = useSelector((state: RootState) => state.currentMedicalArray);
 
  const [isTotal, setisTotal] = useState(0);
    const [isPartMituelle, setisPartMituelle] = useState(0);
    const [isPartPatient, setisPartPatient] = useState(0);

    const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
    const [isTotalFacture, setisTotalFacture] = useState(0);
    const [isPartMituelleFacture, setisPartMituelleFacture] = useState(0);
    const [isPartPatientFacture, setisPartPatientFacture] = useState(0);

     const [isInputFactureSaisie,setisInputFactureSaisie]=useState<NewFactureLivraisonMedicament>(newFactureSaisie);

     const [isInput,setInput]=useState<IdataModelListMedicamentPrix>(initialTypeState);
      

  const [isPriceMedicament, setIsPriceMedicament] = useState(0);

  const [isCurrantPriceMedicament, setIsCurrantPriceMedicament] = useState(0);
  const [isCurrantIdPriceMedicament, setIsCurrantIdPriceMedicament] = useState(0);


  const [isQuantiteMedicament, setIsQuantiteMedicament] = useState(CurrantPatientMedecinePushed.quantity);
  const [isPersonneAccord, setIsPersonneAccord] = useState('');

  const [isAccordChecked, setIsAccordChecked] = useState(false);


  const [isInputUpdateFacturePayer, setisInputUpdateFacturePayer] = useState<{
     statusFacturePayer: string,
     pricePrestation:any,
     ticketService:any,
   ticketServiceFacture:any,
   quantity:any,
   plafondFacture:any,partMutuelle:any,
   partpatient:any,
   partTotal:any,

   partMutuelleFacture:any,
   partpatientFacture:any,
   partTotalFacture:any, }>({ statusFacturePayer: '',
    pricePrestation:0 , ticketService:0,
    ticketServiceFacture:0,
    quantity:0,plafondFacture:0,partMutuelle:0,
    partpatient:0,
    partTotal:0,
   
    partMutuelleFacture:0,
    partpatientFacture:0,
    partTotalFacture:0,});
  
  const handleCheckboxChange = () => {
    setIsAccordChecked(!isAccordChecked);
  };

let couvertureTotal=100;
  
function getCurrentDate(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  // Months are zero-based, so we add 1 to get the correct month
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
function getCurrentTime(): string {
  const currentDate = new Date();
  const hours = renderNumber(currentDate.getHours(), 2);
  const minutes = renderNumber(currentDate.getMinutes(), 2);
  return `${hours}h:${minutes}min`;
}


function renderNumber(value: number, length: number): string {
  let result: string = String(value);
  while (result.length < length) {
    result = '0' + result;
  }
  return result;
}

const fetchMedicamentPrice = useCallback(async () => {
  try {
    if (CurrantPharmacyInfo?.id) {
      const response = await ServiceTarifMedicament
        .findFirstByListeMedicamentIdAndPharmaciesIdOrderByIdDesc(
          CurrantPatientMedecinePushed.idListMedicament,
          CurrantPharmacyInfo.id
        );
      setIsPriceMedicament(response.data?.price);
      setIsCurrantPriceMedicament(response.data?.price);
      setIsCurrantIdPriceMedicament(response.data?.id);
      console.log('response.data.price', response.data?.price);

    console.log('response.data of price', response.data);
    } else {
      console.log('CurrantPharmacyInfo ID value', CurrantPharmacyInfo?.id);
    }
  } catch (err) {
    console.error('Error fetching medicament tariff:', err);
    // Optionally handle the error by setting a default value or showing an error message
    // setIsPriceMedicament(0);
  }
}, [CurrantPharmacyInfo?.id, CurrantPatientMedecinePushed.idListMedicament]);


useEffect(() => {
  fetchMedicamentPrice();
}, [fetchMedicamentPrice]);



const savePrice = () => {  
  let data = {
    price: isPriceMedicament,
    createdBy:preferredName,
    dateCreatedBy: new Date()
  };

  ServiceTarifMedicament.create(CurrantPatientMedecinePushed.idListMedicament,CurrantPharmacyInfo.id,data)
  .then((response: any) => {
    setInput({
      id: response.data.id,
      price: response.data.price,
      createdBy: response.data.createdBy,
      dateCreatedBy: response.data.dateCreatedBy
    });
    console.log(response.data);
    console.log(data);
    setInput(initialTypeState)

    handleClose();
  })
  .catch((e: Error) => {
    console.log(e);
  });
};

const updatePrice = () => {  
  let data = {
    price: isPriceMedicament,
    createdBy:preferredName,
    dateCreatedBy: new Date()
  };

  ServiceTarifMedicament.update(isCurrantIdPriceMedicament,data)
  .then((response: any) => {
    setInput({
      id: response.data.id,
      price: response.data.price,
      createdBy: response.data.createdBy,
      dateCreatedBy: response.data.dateCreatedBy
    });
    console.log(response.data);
    console.log(data);
    setInput(initialTypeState)

    handleClose();
  })
  .catch((e: Error) => {
    console.log(e);
  });
};


const checkValidPrice = (): void => {
  if (isCurrantPriceMedicament===undefined) {
    savePrice();
    return;
  }else {
    if (isCurrantIdPriceMedicament!==isPriceMedicament) {
      updatePrice();
    }else{
      console.log('no option')
    }
  }

  
};

function findRecordByIdIncrementOld(idIncrement: number): NewFactureSaisieMedicamentDto | undefined {
  const record = isCurrantMedecineList.find(item => item.idIncrement === idIncrement);
  if (record) {

    console.log('values exist',record)

    //UpdateFactureSaisiePharmacieOuOption(record.idFacture)
    //dispatchDeleteItem(deleteMedicalRecord(CurrantPatientMedecinePushed.idFacture))
    //dispatchDeleteItem(deleteMedicalRecord(record.idFacture))
      return record;
  } else{

    //UpdateFactureSaisiePharmacie(CurrantPatientMedecinePushed.idFacture)
   // dispatchDeleteItem(deleteMedicalRecord(CurrantPatientMedecinePushed.idFacture))
    console.log('values not exist')
  }
}


function findRecordByIdIncrement(idIncrement: number): NewFactureSaisieMedicamentDto[] {
  const records = isCurrantMedecineList.filter(item => item.idIncrement === idIncrement);

  if (records.length > 0) {
    console.log('values exist', records);
    console.log('CurrantPatientMedecinePushed.idFacture', CurrantPatientMedecinePushed.idFacture);

    for (let index = 0; index < records.length; index++) {
      const record = records[index];

    UpdateFactureSaisiePharmacieOuOption(record.idFacture)
    dispatchDeleteItem(deleteMedicalRecord(CurrantPatientMedecinePushed.idFacture))
    dispatchDeleteItem(deleteMedicalRecord(record.idFacture))
    }
    UpdateFactureSaisiePharmacie(CurrantPatientMedecinePushed.idFacture)

    // Perform any necessary actions with the found records here
    return records;
  } else {
    // Perform any necessary actions when no records are found here
      UpdateFactureSaisiePharmacie(CurrantPatientMedecinePushed.idFacture)

      dispatchDeleteItem(deleteMedicalRecord(CurrantPatientMedecinePushed.idFacture))
      dispatchDeleteItem(deleteMedicalRecord(CurrantPatientMedecinePushed.idIncrement))
         
    return [];
  }
}

function findRecordBy(idIncrement: number): NewFactureSaisieMedicamentDto[] {
  const records = isCurrantMedecineList.filter(item => item.idIncrement === idIncrement);
  if (records.length > 0) {
    console.log('values exist find by', records);
    
    // Perform any necessary actions with the found records here
    for (let index = 0; index < records.length; index++) {
      const record = records[index];
      if (record.idFacture !== CurrantPatientMedecinePushed.idFacture) { // check if current I exist
        UpdateFactureSaisiePharmacieOuOption(record.idFacture);
        dispatchDeleteItem(deleteMedicalRecord(record.idFacture));
      }
    }
    
     UpdateFactureSaisiePharmacieOuOption(CurrantPatientMedecinePushed.idIncrement); // update current id
     UpdateFactureSaisiePharmacie(CurrantPatientMedecinePushed.idFacture);  // update current id
     
     dispatchDeleteItem(deleteMedicalRecord(CurrantPatientMedecinePushed.idIncrement))
     dispatchDeleteItem(deleteMedicalRecord(CurrantPatientMedecinePushed.idFacture))

  } else {
    console.log('values exist else', records);
  }
  
  // Perform any necessary actions when no records are found here
  return [];
}

   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const reset = () => {
        setisInputFactureSaisie(newFactureSaisie);

        handleClose();
        
      }
     
      const UpdateFactureSaisiePharmacie = (id: any) => {
        let data = {
          statusFacturePayer: 'payer',
          pricePrestation:isPriceMedicament,
          ticketService:isgetCurrantCouverturePushed.ticket,
          ticketServiceFacture:isgetCurrantCouverturePushed.ticketFacture,
          quantity:isQuantiteMedicament,
          plafondFacture:CurrantPharmacyInfo.plafondFacture,
          partMutuelle:isPartMituelle,
    partpatient:isPartPatient,
    partTotal:isTotal,

    partMutuelleFacture:isPartMituelleFacture,
    partpatientFacture:isPartPatientFacture,
    partTotalFacture:isTotalFacture,
        };
      
        ServiceNewFactureSaisieMedicamentDtoMedicament.updateFactureSaisiePharmacyStatusPayer
        (id,
         data.statusFacturePayer,
        data.pricePrestation,
      data.ticketService,
    data.ticketServiceFacture,
  data.quantity,
data.plafondFacture,
data.partpatient,
data.partMutuelle,
data.partTotal,
data.partMutuelleFacture,
data.partpatientFacture,
data.partTotalFacture)
          .then((response: any) => {
            setisInputUpdateFacturePayer({
              statusFacturePayer: response.data.statusFacturePayer,
              pricePrestation:response.data.pricePrestation,
              ticketService:response.data.ticketService,
          ticketServiceFacture:response.data.ticketServiceFacture,
          quantity:response.data.quantity,
          plafondFacture:response.data.plafondFacture,
          partMutuelle:response.data.partMutuelle,
 partpatient:response.data.partpatient,
 partTotal:response.data.partTotal,

 partMutuelleFacture:response.data.partMutuelleFacture,
 partpatientFacture:response.data.partpatientFacture,
 partTotalFacture:response.data.partTotalFacture,
            });

          })
          .catch((e: Error) => {
            console.log(e);
          });
      };

      const UpdateFactureSaisiePharmacieOuOption = (id: any) => {
        let data = {
          statusFacturePayer: 'payer',
          pricePrestation:0,
          ticketService:0,
          ticketServiceFacture:0,
          quantity:1,
          plafondFacture:0,
          
          partMutuelle:0,
          partpatient:0,
          partTotal:0,
      
          partMutuelleFacture:0,
          partpatientFacture:0,
          partTotalFacture:0,
        };
      
        ServiceNewFactureSaisieMedicamentDtoMedicament.updateFactureSaisiePharmacyStatusPayer
        (id,
         data.statusFacturePayer,
        data.pricePrestation,
      data.ticketService,
    data.ticketServiceFacture,
    data.quantity,
    data.plafondFacture,
    
    data.partMutuelle,
    data.partpatient,
    data.partTotal,
    
    data.partMutuelleFacture,
    data.partpatientFacture,
    data.partTotalFacture)
          .then((response: any) => {
            setisInputUpdateFacturePayer({
              statusFacturePayer: response.data.statusFacturePayer,
              pricePrestation:response.data.pricePrestation,
              ticketService:response.data.ticketService,
          ticketServiceFacture:response.data.ticketServiceFacture,
          quantity:response.data.quantity,
          plafondFacture:response.data.plafondFacture,
          
     partMutuelle:response.data.partMutuelle,
     partpatient:response.data.partpatient,
     partTotal:response.data.partTotal,
    
     partMutuelleFacture:response.data.partMutuelleFacture,
     partpatientFacture:response.data.partpatientFacture,
     partTotalFacture:response.data.partTotalFacture,
            });
            
          })
          .catch((e: Error) => {
            console.log(e);
          });
      };


    const AddFacture = () => {  
const currentDateFormat=getCurrentDate()
const currentTime=getCurrentTime()
        let data = {
    createdBy:preferredName,
    dateCreatedLivree: currentDateFormat,
    timeLivree: currentTime,
  statusFacturePayer:'No payer',
  statusFactureDeclare:'no',
  statusFactureVerifier:'no',
  statusFactureRembourceAvecAutreFacture:'no',
  statusFactureRembourceUneFacture:'no',

        };

        ServiceNewFactureLivraisonMedicament.createFactureSaisiePharmacy(
          isgetCurrantCouverturePushed.idCouvertureConfig,
          Number(CurrantPharmacyInfo.id),
          CurrantPatientMedecinePushed.idFacture,
           data)
        .then((response: any) => {
          setisInputFactureSaisie({
            id: response.data.id,
            createdBy: response.data.createdBy,
            dateCreatedLivree:response.data.dateCreatedLivree,
            timeLivree:response.data.timeLivree,
            statusFacturePayer:response.data.statusFacturePayer,
            statusFactureDeclare:response.data.statusFactureDeclare,
            statusFactureVerifier:response.data.statusFactureVerifier,
            statusFactureRembourceAvecAutreFacture:response.data.statusFactureRembourceAvecAutreFacture,
            statusFactureRembourceUneFacture:response.data.statusFactureRembourceUneFacture
         
          });

          console.log('CurrantPharmacyInfo',CurrantPharmacyInfo.id)
          
          if (CurrantPatientMedecinePushed.idIncrement!== 0 ) {
            console.log('value incriemrntId is not null')

    findRecordBy(CurrantPatientMedecinePushed.idIncrement)
           // UpdateFactureSaisiePharmacieOuOption(CurrantPatientMedecinePushed.idIncrement)

           // dispatchDeleteItem(deleteMedicalRecord(CurrantPatientMedecinePushed.idFacture))
            //dispatchDeleteItem(deleteMedicalRecord(CurrantPatientMedecinePushed.idIncrement))
                  }else{
                    findRecordByIdIncrement(CurrantPatientMedecinePushed.idFacture)
                  }
          checkValidPrice()
    // Example usage
        })
        .catch((e: Error) => {
          console.log(e);

  console.log('CurrantPharmacyInfo',CurrantPharmacyInfo.id)
        });
    };


      
const CalculateFacture = useCallback(() => {
  
    let couvertureMituelle = couvertureTotal - isgetCurrantCouverturePushed.ticket;
    setisPartMituelle(
      (isPriceMedicament * couvertureMituelle * isQuantiteMedicament) / 100
    );
    setisPartPatient(
      (isPriceMedicament *  isgetCurrantCouverturePushed.ticket * isQuantiteMedicament) / 100
    );
    setisTotal(isPriceMedicament * isQuantiteMedicament);

    let couvertureMituelle2 = couvertureTotal - isgetCurrantCouverturePushed.ticketFacture;
    setisPartMituelleFacture(
      (isPriceMedicament * couvertureMituelle2 * isQuantiteMedicament) /
        100
    );
    setisPartPatientFacture(
      (isPriceMedicament * isgetCurrantCouverturePushed.ticketFacture * isQuantiteMedicament) /
        100
    );
    setisTotalFacture(isPriceMedicament * isQuantiteMedicament);
 
}, [couvertureTotal, isgetCurrantCouverturePushed.ticket, isgetCurrantCouverturePushed.ticketFacture, isPriceMedicament, isQuantiteMedicament]);
    
    useEffect(() => {
        CalculateFacture();  
    },[CalculateFacture]);

   
 const handlePriceChangeNew = (e: React.ChangeEvent<HTMLInputElement>) => {
  setIsPriceMedicament(Number(e.target.value));
 
};
 const handleQuantiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  
  setIsQuantiteMedicament(Number(e.target.value));
 
};
const handleAccord= (e: React.ChangeEvent<HTMLInputElement>) => {
  setIsPersonneAccord(e.target.value);
 
};
  return (
    <Modal 
      show={show}
      onHide={() => {
        handleClose();
       // handleOtherMedical(); // Call your custom function when the modal is closed
      }}
       centered
       size="xl"
       >
      <Modal.Header closeButton>
        <Modal.Title>Saisie le prix de Medicament</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <>
     <div>
     <form className="form-edit" onSubmit={onFormSubmit}>
      <Row className="mb-3 mt-3">
      <Form.Group controlId="formBasicAccordCheckbox" className="col col-sm-3">
          <Form.Check
            type="checkbox"
            label="Autorise Sur Facturation"
            onChange={handleCheckboxChange}
            checked={isAccordChecked}
          />
        </Form.Group>
      </Row>
      {/* Medicament and quantity */}
      <Row className="mb-3">
              <Form.Group controlId="formBasicMedicament" className="col col-sm-6">
                <Form.Label>Medicament</Form.Label>
                <Form.Control
                  type="text"
                  readOnly={true} // Makes the field uneditable
                  value={CurrantPatientMedecinePushed.prestation}
                />
              </Form.Group>
              <Form.Group controlId="formBasicQuantity" className="col col-sm-3">
                <Form.Label>Quantite</Form.Label>
                <Form.Control
                  type="number"
                  defaultValue={isQuantiteMedicament}
                  onChange={handleQuantiteChange}
                />
              </Form.Group>
            </Row>
      {/* Prix config */}
      
       <Row className="mb-3">
        
        <Form.Group controlId="formBasicPrice" className="col col-sm-3">
          <Form.Label>New Prix</Form.Label>
          <Form.Control
            type="number"
            defaultValue={isPriceMedicament}
            onChange={handlePriceChangeNew}
          />
        </Form.Group>
         {/* Accord */}
      {isAccordChecked && (
          <Form.Group controlId="formBasicAccordPerson" className="col col-sm-3">
            <Form.Label>Personne Accorde</Form.Label>
            <Form.Control
              type="text"
              value={isPersonneAccord}
              onChange={handleAccord}
            />
          </Form.Group>
      )}
      </Row>


     
      <Row className="mb-3">
      <Stack  direction="horizontal" gap={2}>

<Form.Label><strong>Part Mituelle</strong>:{isPartMituelle} </Form.Label>
        
<Form.Label><strong>part Patient</strong>: {isPartPatient}</Form.Label>

<Form.Label><strong>Total de Facture</strong>:{isTotal}</Form.Label>
</Stack>
      </Row>
      <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-8">
            <button  onClick={()=>AddFacture()} className="me-4 btn btn-success btn-sm btn-block">saisie</button>
            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            <Button onClick={()=>findRecordBy(CurrantPatientMedecinePushed.idIncrement)}>test</Button>
           
</Form.Group>
      </Row>
            </form>
     </div>
        </>
      </Modal.Body>
    </Modal>
  );

}