import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import SettingsIcon from '@mui/icons-material/Settings';
import PaymentIcon from '@mui/icons-material/Payment';
import SwipeableViews from 'react-swipeable-views';
import { TableFactureMedicamentNoPayer } from '../Espace_Patients/FactureMedicament/Table/TableFactureMedicamentNoPayer';
import { SaisieLivraisonComponet } from './SaisieLivraisonComponet';
import { Receipt } from '@material-ui/icons';
import { useRef } from 'react';
import { TableImprimeFacturePharmacy } from '../Espace_Patients/FactureMedicament/Table/TableImprimeFacturePharmacy';
import { TableEditFactureMituellePharmacy } from '../Espace_Patients/FactureSaisie/Tables/TableEditFactureMituellePharmacy';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 1 }}>
        <div>{children}</div>
      </Box>
    )}
  </div>
);

}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function TabFacturationPrixMedicament() {
  const componentToPrint = useRef(null);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', ml: 3, mr: 3 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab icon={<SettingsIcon />} label="Control Facture" {...a11yProps(0)} style={{ flexDirection: 'row-reverse' }} />
          <Tab icon={<PaymentIcon />} label="Payer la facture" {...a11yProps(1)} style={{ flexDirection: 'row-reverse' }} />
          <Tab icon={<Receipt />} label="Imprime la facture" {...a11yProps(2)} style={{ flexDirection: 'row-reverse' }} />
          <Tab icon={<SettingsIcon />} label="Modifier la facture" {...a11yProps(3)} style={{ flexDirection: 'row-reverse' }} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <SaisieLivraisonComponet />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TableFactureMedicamentNoPayer />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <TableImprimeFacturePharmacy componentToPrint={componentToPrint} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <TableEditFactureMituellePharmacy />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
