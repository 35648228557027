import { Container, Row, Col, Card } from "react-bootstrap";
import { CompListeCotisationPayerOuNopayerParEnterprise } from "../../../../Components/ASSURANCES/Recouvrements/Comptabillite/CompListeCotisationPayerOuNopayerParEnterprise";

export const ListeCotisationPayerOuNopayerParEnterprise=()=>{

    return (

     <Container fluid>
     <Row>
      <Col>
     <br />
       <Card border="secondary" >
         <Card.Body>
         <CompListeCotisationPayerOuNopayerParEnterprise/>
           </Card.Body>
       </Card>
       <br />
      </Col>
     </Row>
     
     </Container>
    );
}

