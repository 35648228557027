import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Card, Row, Form, InputGroup } from "react-bootstrap";
import Iinsurance from '../Models/Iinsurance';
import InsuranceTypeService from '../Services/InsuranceTypeService';

  interface IEditType {
    show: boolean; 
    onHide: () => void; 
    EditInfo: Iinsurance;
  }

  export const EditInsurance :React.FC<IEditType>=props=> {

    const initialTypeState = {
      id: null,
      insuranceName: '',
      status: '',
      plafond: '',
      statusPlafond: '',
      typeInsurance: '',
      userName: '',
      phoneNumber: '',
      email: '',
      address: '',
      password: '',
      account: '',
      createdBy: 'Ghost Zila',
      dateCreatedBy: new Date()
    };

    const [isInput,setInput]=useState<Iinsurance>(props.EditInfo);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInput({ ...isInput, [name]: value });
       // console.log(event.target)
        }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditType = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
       let data = {
        insuranceName: isInput.insuranceName,
        status: isInput.status,
        plafond: isInput.plafond,
       statusPlafond: isInput.statusPlafond,
       typeInsurance: isInput.typeInsurance,
        userName: isInput.userName,
       phoneNumber: isInput.phoneNumber,
       email: isInput.email,
      address: isInput.address,
       password: isInput.password,
        createdBy:'haliii ganii',
        dateCreatedBy: new Date()
      };

      InsuranceTypeService.update(isInput.id,data)
      .then((response: any) => {
        setInput({
          id: response.data.id,
          insuranceName: response.data.insuranceName,
          status: response.data.status,
         plafond: response.data.plafond,
         statusPlafond: response.data.statusPlafond,
         typeInsurance: response.data.typeInsurance,
         userName: response.data.userName,
         phoneNumber: response.data.phoneNumber,
         email: response.data.email,
           address: response.data.address,
          password: response.data.password,
          createdBy: response.data.createdBy,
          dateCreatedBy: response.data.dateCreatedBy
        });
        console.log(response.data);
        console.log(data);
        setInput(initialTypeState)
      })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
          
  useEffect(() => {

    setInput(props.EditInfo)

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
            <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-6">
               <Form.Label>Edit insuranceName  {isInput.insuranceName}</Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="insuranceName" name="insuranceName" value={ isInput.insuranceName} onChange={handleChange} />
               </InputGroup>
           </Form.Group>
            </Row>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditType} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
