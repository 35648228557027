import http from "../../../../AxiosRequest/httpcommon";
import IdataModelType from "../Models/IdataModelTypeStructure";
import StructureDtos from "../Models/StructureDtos";
const getAll = (params:any) => {
  return http.get<Array<StructureDtos>>("/typeStructureDtos", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelType>>("/sortedtypeStructure");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelType>>("/typeStructureAccount");
}

const create = (socialinsuranceId:any,data: IdataModelType) => {
  return http.post<IdataModelType>(`/socialinsurance/${socialinsuranceId}/typeStructure`, data);
};

const update = (id: any, data: IdataModelType) => {
  return http.put<any>(`/typeStructure/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/typeStructure/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/typeStructure`);
};

const findByTypeStructureName = (insuranceId:any,structureName: string) => {
  return http.get<Array<StructureDtos>>(`/typeStructureDtos?insuranceId=${insuranceId}&structureName=${structureName}`);
};
const TypeService = {
  getAllTypeWithoutPagination,
  findByTypeStructureName,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
};

export default TypeService;