import { ChangeEvent, useEffect, useState } from "react";
import IdataModelPharmacy from "../Models/IdataModelPharmacy";
import { Accordion, Form, InputGroup, Row } from "react-bootstrap";
import PharmacyTypeService from "../Services/PharmacyTypeService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/Store/store";


let accountIncrement=0

const initialPharmacie = {
  id: null,
  pharmacyName: '',
  plafondFacture:0,
  raisonSociale:'',
  tel:'',
  nif:'',
  rc:'',
  bp:'',
  avenue:'',
  centreFiscal:'',
  secteurActivite:'',
  formeJuridique:'',
  tva:'',
  account:accountIncrement,
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
// 👇️ const now: Date
//const now = new Date();

//console.log(now); // 👉️ 2023-01-20T12:01:41.216Z

//console.log(now.toLocaleDateString()); // 👉️ 1/20/2023

//console.log(now.toLocaleString()); // 👉️ 1/20/2023, 2:01:41 PM

//console.log(now.toUTCString()); // 👉️ Fri, 20 Jan 2023 12:01:41 GMT

//console.log(now.toISOString()); // 👉️ 2023-01-20T12:01:41.216Z

export const PharmacyType:React.FC=()=> {

  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  
const [selectedStructure, setSelectedStructure] = useState('');
  const handleChangeStructure = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
   
    setSelectedStructure(selectedValue);
  };
const getLastAccountType =()=>{
  PharmacyTypeService.getLastAccountType()
    .then((response: any) => {
        accountIncrement=response.data.account
        console.log(response.data);
  })
}

useEffect(() => {
  getLastAccountType();
 
});


const [isInput,setInput]=useState<IdataModelPharmacy>(initialPharmacie);

  
      const handleChange = (e: { target: { name: any; value: any; }; }) => {
           setInput({ ...isInput, [e.target.name]: e.target.value });
        }

      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        if(accountIncrement===undefined){
          accountIncrement=5000000
        }
        let data = {
          pharmacyName: selectedStructure,
          plafondFacture:isInput.plafondFacture,
          raisonSociale:isInput.raisonSociale,
          tel:isInput.tel,
          nif:isInput.nif,
          rc:isInput.rc,
          bp:isInput.bp,
          avenue:isInput.avenue,
          centreFiscal:isInput.centreFiscal,
          secteurActivite:isInput.secteurActivite,
          formeJuridique:isInput.formeJuridique,
          tva:isInput.tva,
          account: accountIncrement+1,
          createdBy:'ghost zila ',
          dateCreatedBy: new Date()
        };

        PharmacyTypeService.create(data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            pharmacyName: response.data.pharmacyName,
            plafondFacture:response.data.plafondFacture,

            raisonSociale:response.data.raisonSociale,
            tel:response.data.tel,
            nif:response.data.nif,
            rc:response.data.rc,
            bp:response.data.bp,
            avenue:response.data.avenue,
            centreFiscal:response.data.centreFiscal,
            secteurActivite:response.data.secteurActivite,
            formeJuridique:response.data.formeJuridique,
            tva:response.data.tva,

            account:response.data.data.account,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialPharmacie)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialPharmacie);
      }

     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
      
         <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Configuration Pharmacie</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
         <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
      <Form.Label>Structrures</Form.Label>
      <Form.Select
        className="form-control"
        name="typehospital"
        value={selectedStructure}
        onChange={handleChangeStructure}
      >
        <option value="Choose...">Choose...</option>
        {StructuresData.map((group, index) => (
          <option key={index} value={group}>
            {group}
          </option>
        ))}
      </Form.Select>
         </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Plafond de Facture</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="plafondFacture" value={isInput.plafondFacture} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Configuration sur Facture</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Raison social</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="raisonSociale" value={isInput.raisonSociale} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Tel</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="tel" value={isInput.tel} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Nif</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="number" name="nif" value={isInput.nif} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>RC</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="rc" value={isInput.rc} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>BP</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="bp" value={isInput.bp} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Avenue</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="avenue" value={isInput.avenue} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Centre Fiscal</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="centreFiscal" value={isInput.centreFiscal} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Secteur d'Activite</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="secteurActivite" value={isInput.secteurActivite} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Forme juridique</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="formeJuridique" value={isInput.formeJuridique} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
 <Form.Label>TVA</Form.Label>
    <Form.Select  size="sm" name="tva" value={isInput.tva} onChange={handleChange}>
        <option>Choisir </option>
       <option value="No">No</option>
     <option value="Oui">Oui</option>
</Form.Select>
</Form.Group>
</Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}