import {
    Row,
    Table,
    Stack,
    Button,
    Form,
    InputGroup,
  } from "react-bootstrap";
  import { useState, ChangeEvent, useEffect, useCallback } from "react";
  import React from "react";
  
import Pagination from "@material-ui/lab/Pagination/Pagination";
  import { RiSearch2Line } from "react-icons/ri";
  
  import IdataModeEnterprise from "../../Entreprises/Models/IdataModeEnterprise";
  import EnterpriseService from "../../Entreprises/Services/EnterpriseService";
import CotisationIndepandantDto from "../SaisieIndepandant/Models/CotisationIndepandantDto";
import ServiceCotisationIndepandant from "../SaisieIndepandant/Services/ServiceCotisationIndepandant";
  
  const defaultinitiaType: Array<IdataModeEnterprise> = [];
  
  export const CompListeCotisationPayerOuNopayerParIndependant: React.FC = () => {

    const [isCotisationAssuranceDtoBetween, setisCotisationAssuranceDtoBetween] =
      useState<Array<CotisationIndepandantDto>>([]);
    const [enterpriseId, setEnterpriseId] = useState("");
    const [statusName, setStatus] = useState("");
    const [enterprise, setEnterprise] = useState(defaultinitiaType);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
  
  const [searchValue, setSearchValue] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];

    const getRequestParamsBetweenDate = (
      socialinsuranceId: number,
      enterprisesAssuranceId: any,
      nomPrenomAffilie:any,
      statusPayer: string,
      startDate: string,
      endDate: string,
      thepage: number,
       thepageSize: number
    ) => {
      let params = {
        socialinsuranceId: socialinsuranceId,
        enterprisesAssuranceId: enterprisesAssuranceId,
        nomPrenomAffilie:nomPrenomAffilie,
        statusPayer: statusPayer,
        startDate: startDate,
        endDate: endDate,
        page: thepage,
        size:thepageSize
      };
  
    if (socialinsuranceId) {
      params["socialinsuranceId"] = socialinsuranceId;
    }
    if (enterprisesAssuranceId) {
      params["enterprisesAssuranceId"] = enterprisesAssuranceId;
    }
  
    if (nomPrenomAffilie) {
      params["nomPrenomAffilie"] = nomPrenomAffilie - 1;
    }
  
    if (statusPayer) {
      params["statusPayer"] = statusPayer;
    }

    if (startDate) {
        params["startDate"] = startDate;
      }

    if (endDate) {
        params["endDate"] = endDate;
      }
  
      return params;
    };
    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        setSearchValue(searchValue);
      };

      const findAllBetween = () => {
        const params = getRequestParamsBetweenDate(52,enterpriseId,searchValue,statusName,startDate,endDate, page, pageSize);
      
        ServiceCotisationIndepandant.cotisationIndependantDetailsStatusPayerBetween(params)
      
          .then((response: any) => {
      
            const {cotisationDetails , totalPages} = response.data;
      
            setisCotisationAssuranceDtoBetween(cotisationDetails);
            setCount(totalPages);
      
            console.log(response.data);
            console.log(params);
      
          })
      
          .catch((e: Error) => {
            console.log(e);
          });
      };
       const retrieveData = useCallback(() => {
        const params = getRequestParamsBetweenDate(52, enterpriseId, searchValue, statusName, startDate, endDate, page, pageSize);
      
        ServiceCotisationIndepandant.cotisationIndependantDetailsStatusPayerBetween(params)
          .then((response: any) => {
            const { cotisationDetails, totalPages } = response.data;
      
            setisCotisationAssuranceDtoBetween(cotisationDetails);
            setCount(totalPages);
      
            console.log(response.data);
            console.log(params);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }, [enterpriseId, searchValue, statusName, startDate, endDate, page, pageSize]);
      
      useEffect(() => {
        retrieveData();
      }, [retrieveData]);

  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };
    const handleChangeEnterpriseId = (e: ChangeEvent<HTMLSelectElement>) => {
      setEnterpriseId(e.target.value);
    };
    const handleChangeStatus = (event: any) => {
        setStatus(event.target.value);
      };
  
    const getWithoutPagination = () => {
      EnterpriseService.getAllEnterpriseNameWithoutPagination()
        .then((response: any) => {
          setEnterprise(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    useEffect(() => {
      getWithoutPagination();
    }, []);
  
  
  
  
    return (
      <>
        <Row className="mb-3 mt-3  ">
          <div className="d-flex flex-column align-items-start ">
            <Stack direction="horizontal" gap={2} >
              <Form.Select
                className="form-control ml-3"
                onChange={handleChangeEnterpriseId}
              >
                <option value="0">Enterprise..</option>
                {enterprise &&
                  enterprise.map((enterprise) => (
                    <option key={enterprise.id} value={enterprise.id}>
                      {enterprise.enterpriseName}
                    </option>
                  ))}
              </Form.Select>
              <Form.Select
                aria-label="Status"
                onChange={handleChangeStatus}
              >
                <option value="0">Status..</option>
                <option value=" payer">payer</option>
                <option value="no payer">no payer</option>
              </Form.Select>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <Button
                className="btn-sm rounded-pill mr-3"
                variant="outline-success"
                onClick={findAllBetween}
              >
                <RiSearch2Line size={25} />
              </Button>
            </Stack>
         
        <Stack  direction="horizontal" gap={2}>
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <InputGroup>
            <Form.Control size="sm" 
                    placeholder="Filtre l'independant..."
                    value={searchValue}
                     onChange={onChangeSearch} 
                    />
            </InputGroup>
        </Form.Group>

            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
           </Stack>  
          </div>
        </Row>

        <div className="row row-sm mb-2">
          <div className="col-sm-12 col-lg-12">
            <div className="table-responsive table-sm table-wrapper-scroll-y my-custom-scrollbar">
              <Table
                bordered
                className="table table-sm table-hover table-striped"
              >
                <thead style={{ background: "#ecfceb" }}>
                  <tr className="text-center">
                    <th>Date Created</th>
                    <th>Time Created</th>
                    <th>Month Paid</th>
                    <th>Status Payer</th>
                    <th>Money</th>
                    <th>Enterprise Name</th>
                    <th>Social Insurance Name</th>
                    <th>Created By</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {isCotisationAssuranceDtoBetween &&
                    isCotisationAssuranceDtoBetween.map((mydata, index) => (
                      <tr key={index}>
                        <td>{mydata.dateCreated}</td>
                        <td>{mydata.timeCreated}</td>
                        <td>{mydata.moisPayer}</td>
                        <td>{mydata.statusPayer}</td>
                        <td>{mydata.money}</td>
                        <td>{mydata.enterpriseName}</td>
                        <td>{mydata.socialInsuranceName}</td>
                        <td>{mydata.createdBy}</td>
                        <td>{mydata.comment}</td>
                      </tr>
                    ))}
                  
                </tbody>
              </Table>
            </div>
          </div>
        </div>
       
      </>
    );
  };
  