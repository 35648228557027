// ConfigAddFactureSaisieDepertmentSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ArrayState {
  data: any;
}

const initialState: ArrayState = {
 // data: ['offline_access', 'default-roles-hema-clinic', 'uma_authorization', 'receptionist', 'user'],
  data:0,
};

const ConfigAddFactureSaisieDepertmentSlice = createSlice({
  name: 'ConfigAddFactureSaisieDepertment',
  initialState,
  reducers: {
    updateConfigAddFactureSaisieDepertment: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { updateConfigAddFactureSaisieDepertment } = ConfigAddFactureSaisieDepertmentSlice.actions;
export default ConfigAddFactureSaisieDepertmentSlice.reducer;
