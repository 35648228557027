import { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import IdataModelSmember from "../../../Components/ASSURANCES/Beneficieres/Models/IdataModelSmember";
import { AddSmember } from "../../../Components/ASSURANCES/Beneficieres/Operations/AddSmember";
import { EditSmember } from "../../../Components/ASSURANCES/Beneficieres/Operations/EditSmember";
import { TableSmember } from "../../../Components/ASSURANCES/Beneficieres/Tables/TableSmember";
import IdataModeEnterprise from "../../../Components/ASSURANCES/Entreprises/Models/IdataModeEnterprise";
import IdataModelPMemberDtos from "../../../Components/ASSURANCES/AdherantPrencipales/Models/IdataModelPMemberDtos";
import IdataModelSmemberDtos from "../../../Components/ASSURANCES/Beneficieres/Models/IdataModelSmemberDtos";

  const initialTypeStateSmemberDtos = {
   
    ////table socialInsurance so
    idSociete:0,
     SocieteName:'',

    //table enterpriseAssurance   ta1
     idInterprise:0,
     accountInterprise:0,
     enterpriseName:'',
     resilierSociete:'',
    ///table PMember pa
     idPmember:0,
     codeAssuree:'',
     nomPrenomAffilie:'',
     cotisationExact:'',
     account:0,
     Genre:'',
     catégorieSoins:'',
     email:'',
     Telephones:'',
     AdresseAffilie:'',
     AutresInformations:'',

     plafond_ambilatoire:'',
     plafond_hospitalisation:'',
     plafond_pharmacy:'',
     plafond_ORL:'',
     plafond_urugence:'',
     plafond_ophtalmologie:'',
     category_code:'',
     plafond_dentisteries:'',
     resilier:'',
     dataResiliation:'',
     PhotoMember:'',
     dateNaissance:'',
     createdBy:'',
     dateCreated:'',
   etatCivil:'',
    //table SMember ma
     id:0,
     codeAyantDroit:'',
     accountSmember:0,
     ayantDroit:'',
     dateNaissanceSmember:'',
     gender:'',
     bloodGroup:'',
     resilierSMember:'',
     dateResiliationSMember:'',
     PhotoMemberSmember:'',
     createdBySmember:'',
     dateCreatedSmember:'',
     epouse:'',
enfantplus:''
 
    
  };
  const initialTypeStatePMemberDtos = {
    
    //table enterpriseAssurance   ta1
    idInterprise:0,
    accountInterprise:0,
    enterpriseName: '',
    couvertureDu: '',
    couvertureAau: '',
    resilierSociete:'',

///table PMember pa
    idPmember:0,
    codeAssuree: '',
    nomPrenomAffilie: '',
    cotisationExact: '',
    account:0,
    genre: '',
    catégorieSoins: '',
    email: '',
    telephones: '',
    adresseAffilie: '',
    autresInformations: '',

    plafond_ambilatoire: '',
    plafond_hospitalisation: '',
    plafond_pharmacy: '',
    plafond_ORL: '',
    plafond_urugence: '',
    plafond_ophtalmologie: '',
    category_code: '',
    plafond_dentisteries: '',
    resilier: '',
    dataResiliation: '',
    photoMember: '',
    dateNaissance: '',
    createdBy: '',
    dateCreated: '',
    tva:'',
    etatCivil:'',
   ////table socialInsurance so
    idSociete:0,
    societeName: ''
  };
  interface IpushCurrantValue {
    show: boolean; 
    onHide: () => void; 
    editinfo: IdataModelPMemberDtos;
  }
export const Beneficieres:React.FC<IpushCurrantValue>=props=>{
    const [getPushedValue, setgetPushedValue] = useState<IdataModelPMemberDtos>(initialTypeStatePMemberDtos);

    const [EditList, setEditList] = useState<IdataModelSmemberDtos>(initialTypeStateSmemberDtos);

    const OnCurrentAyantDroit = (currantData: IdataModelSmemberDtos) => {
        setEditList(currantData);
      };
    const [isSwichLayerAyantDroit,setSwichLayerAyantDroit] = useState(true);
    
  useEffect(() => {
    setgetPushedValue(props.editinfo);
      },[props.editinfo])
    return(
        <>
         <Modal {...props}
    size="lg"
    backdrop="static"
    keyboard={false}
    
     aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Beneficiere de {getPushedValue.nomPrenomAffilie}  
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
      <Container fluid>
            <Row>
                <Col> 
                <Card>
            <Card.Body>
           {isSwichLayerAyantDroit?
                (
                    <AddSmember editinfo={getPushedValue} />
                ):(
            <EditSmember 
            show={isSwichLayerAyantDroit}  
            onHide={() => setSwichLayerAyantDroit(true)}
            EditInfo={EditList}
            /> 
                )
  }
         <TableSmember 
                        show={isSwichLayerAyantDroit}
                        onHide={() => setSwichLayerAyantDroit(false)}
                        onEdit={OnCurrentAyantDroit}
                         onGetPMemberDtos={getPushedValue}          />
            </Card.Body>
        </Card>
                </Col>
            </Row>
          
        </Container>
      </Modal.Body>
    
    </Modal>
       
        </>
    );
}