import http from "../../../../../AxiosRequest/httpcommon";
import CotisationIndepandant from "../Models/CotisationIndepandant";
import CotisationIndepandantDto from "../Models/CotisationIndepandantDto";

const getAll = (params:any) => {
  return http.get<Array<CotisationIndepandantDto>>("/cotisationIndependant", { params });
};



const cotisationIndependantDetailsStatusPayerBetween = (params:any) => {
  return http.get<Array<CotisationIndepandantDto>>("/cotisationIndependantDetailsStatusPayerBetween", { params });
};

const cotisationIndependantDetailsStatusClasseeBetween = (params:any) => {
  return http.get<Array<CotisationIndepandantDto>>("/cotisationIndependantDetailsStatusClasseeBetween", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<CotisationIndepandant>>("/sortedcotisationIndependant");
};

const create = (socialinsuranceId:any,enterprisesAssuranceId:any,pMember_id:any,data: CotisationIndepandant) => {
  return http.post<CotisationIndepandant>(`/${socialinsuranceId}/${enterprisesAssuranceId}/${pMember_id}/cotisationIndependant`, data);
};

const update = (id: any, data: CotisationIndepandant) => {
  return http.put<any>(`/cotisationIndependant/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/cotisationIndependant/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/cotisationIndependant`);
};

const findByEnterpriseName = (socialinsuranceId:any,enterpriseName: string) => {
  return http.get<Array<CotisationIndepandantDto>>(`/cotisationIndependant?socialinsuranceId=${socialinsuranceId}&enterpriseName=${enterpriseName}`);
};

const ServiceCotisationIndepandant = {
  getAllWithoutPagination,
  getAll,
  cotisationIndependantDetailsStatusPayerBetween,
  cotisationIndependantDetailsStatusClasseeBetween,
  create,
  update,
  remove,
  removeAll,
  findByEnterpriseName,
};

export default ServiceCotisationIndepandant;