import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import CategoryIcon from '@mui/icons-material/Category';
import DetailsIcon from '@mui/icons-material/Details';
import TypeIcon from '@mui/icons-material/TypeSpecimen';

import { CategorySoins } from "../../Components/Acts/Categories/CategoriesSoins/CategorySoins";
import { CategorySoinsEdit } from "../../Components/Acts/Categories/CategoriesSoins/CategorySoinsEdit";
import { CategoryTable } from "../../Components/Acts/Categories/Tables/CategoryTable";
import { AddType } from "../../Components/Acts/TypeCategory/Type/AddType";
import { EditType } from "../../Components/Acts/TypeCategory/Type/EditType";
import { TypeCategoryTable } from "../../Components/Acts/TypeCategory/Tables/TypeCategoryTable";
import { DetailsType } from "../../Components/Acts/Details/Details/DetailsType";
import { DetailsTypeEdit } from "../../Components/Acts/Details/Details/DetailsTypeEdit";
import { DetailsTable } from "../../Components/Acts/Details/Tables/DetailsTable";

import IdataModelCategory from "../../Components/Acts/Categories/Models/IdataModelCategory";
import IdataModeDetailsTypeDtos from "../../Components/Acts/Details/Models/IdataModeDetailsTypeDtos";
import IdataModelTypeCategoryDtos from "../../Components/Acts/TypeCategory/Models/IdataModelTypeCategoryDtos";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const initialCategory: IdataModelCategory = {
  id: null,
  typePrestationsName: '',
  accountType: 0,
  createdBy: '',
  dateCreatedBy: new Date()
};

const initialTypeCategoryDetails: IdataModeDetailsTypeDtos = {
  idSoinsDetails: 0,
  code: 0,
  intitule: '',
  excl: '',
  acc: '',
  dateCreatedBy: '',
  IdTypePrestation: 0,
  typePrestationsName: '',
  accountType: '',
  idSoins: 0,
  codeSoins: 0,
  intituleSoins: ''
};

const initialTypeCategory: IdataModelTypeCategoryDtos = {
    idSoins: 0, // Use wrapper type Long for the primary key to handle null values gracefully
    code: 0,
    intitule: '',
    excl: '',
    acc: '',
    dateCreatedBy: '',
 
   // typePrestation
   IdTypePrestation: '',
  typePrestationsName: '',
  accountType: ''
};

export const Actes=()=> {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const [editCategory, setEditCategory] = React.useState<IdataModelCategory>(initialCategory);
  const [editTypeCategory, setEditTypeCategory] = React.useState<IdataModelTypeCategoryDtos>(initialTypeCategory);
  const [editTypeCategoryDetails, setEditTypeCategoryDetails] = React.useState<IdataModeDetailsTypeDtos>(initialTypeCategoryDetails);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const onCurrentCategory = (category: IdataModelCategory) => {
    setEditCategory(category);
  };

  const onCurrentHospital = (hospital: IdataModelTypeCategoryDtos) => {
    setEditTypeCategory(hospital);
  };

  const onCurrentTypeCategoryDetails = (typeCategoryDetails: IdataModeDetailsTypeDtos) => {
    setEditTypeCategoryDetails(typeCategoryDetails);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', ml: 3, mr: 3 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab icon={<CategoryIcon />} label="Category Soins" {...a11yProps(0)} style={{ flexDirection: 'row-reverse' }} />
          <Tab icon={<TypeIcon />} label="Type Category" {...a11yProps(1)} style={{ flexDirection: 'row-reverse' }} />
          <Tab icon={<DetailsIcon />} label="Details Type" {...a11yProps(2)} style={{ flexDirection: 'row-reverse' }} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {editCategory.id === null ? <CategorySoins /> : <CategorySoinsEdit show={true} onHide={() => setEditCategory(initialCategory)} EditInfoCategorySoins={editCategory} />}
          <CategoryTable show={true} onHide={() => setEditCategory(initialCategory)} onEditCategory={onCurrentCategory} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {editTypeCategory.idSoins === 0 ? <AddType /> : <EditType show={true} onHide={() => setEditTypeCategory(initialTypeCategory)} EditInfoTcategory={editTypeCategory} />}
          <TypeCategoryTable show={true} onHide={() => setEditTypeCategory(initialTypeCategory)} onEditTypeCategory={onCurrentHospital} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {editTypeCategoryDetails.idSoinsDetails === 0 ? <DetailsType /> : <DetailsTypeEdit show={true} onHide={() => setEditTypeCategoryDetails(initialTypeCategoryDetails)} EditInfoDetailsType={editTypeCategoryDetails} />}
          <DetailsTable show={true} onHide={() => setEditTypeCategoryDetails(initialTypeCategoryDetails)} onEditDetails={onCurrentTypeCategoryDetails} />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
