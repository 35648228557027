import React, { useState, useEffect, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/Store/store';
import PharmacyTypeService from '../ASSURANCES/StructurePartenaires/Pharmacies/Services/PharmacyTypeService';
import { updateDetailsPharamcy } from '../../Redux/slices/pharmacyDetails/pharmacyDetails';
import ServicesNewVisite from '../Espace_Patients/NouvelleVisite/Services/ServicesNewVisite';
import { clearcurrentNewFacture, updatecurrentNewFacture } from '../../Redux/slices/currentNewVisite/currentNewFacture';

let MonStructure: string = '';

export default function SearchBonVerification() {
  const [searchQuery, setSearchQuery] = useState('');
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  const dispatch = useDispatch();
  const dispatchFactureSaisiePharmacy = useDispatch();
      /////////////////

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(3);

  const findByBon = async () => {
    try {
      if (StructuresData.length === 1) {
        let concatenatedString = StructuresData.join(", ");
        MonStructure = concatenatedString;
  const response = await ServicesNewVisite.findByReceiptBillPharmacy(searchQuery,52);
  if (response.data!==null) { 

    const response = await ServicesNewVisite.findByReceiptBillPharmacy(searchQuery,52);
    dispatchFactureSaisiePharmacy(updatecurrentNewFacture(response.data))
    console.log(response.data);
  } else {
    dispatchFactureSaisiePharmacy(clearcurrentNewFacture())
    console.log(response.data);
      }
        dispatchFactureSaisiePharmacy(updatecurrentNewFacture(response.data))
        console.log(response.data);
      } else {
        const response = await ServicesNewVisite.findByReceiptBillPharmacy(searchQuery,52);
        dispatchFactureSaisiePharmacy(updatecurrentNewFacture(response.data))
        console.log(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const findBydetailsPharma = useCallback(async () => {
    try {
      if (StructuresData.length === 1) {
        let concatenatedString = StructuresData.join(", ");
        MonStructure = concatenatedString;
        PharmacyTypeService.findByPharmacyName(MonStructure)
      .then((response: any) => {

        const {Pharmacies } = response.data;
        dispatch(updateDetailsPharamcy(Pharmacies[0]));
        
        //console.log("Pharmacies",Pharmacies);
      })
      .catch((e: Error) => {
        console.log(e);
      });
      }

    } catch (e) {
      console.log(e);
    }
  }, [dispatch, StructuresData]);
  useEffect(() => {
    const intervalId = setInterval(findBydetailsPharma, 50000); // Call the function every 5 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [findBydetailsPharma]); // Add dependencies as needed

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();
    console.log('Searching for:', searchQuery);
    findByBon();
  };

  return (
    <Paper
      component="form"
      sx={{ 
        p: '2px 4px', 
        display: 'flex', 
        alignItems: 'center', 
        width: 300,
        mt: 2, // margin top
        mb: 1, // margin bottom
      }}
    >
      <InputBase
        sx={{ 
          ml: 1,
          flex: 1,
          fontSize: '2rem', // Set the text size (adjust as needed)
          fontWeight: 600, // Set the font weight to semi-bold
        }}
        type="number"
        placeholder="Search Bon"
        inputProps={{ 'aria-label': 'search bon' }}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
