import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { IPmember } from "../Interfaces/IPmember";
import ServicePrincipalMember from "../Services/ServicePrincipalMember";
import { Beneficieres } from "../../../../Pages/ASSURANCES/Beneficieres/Beneficieres";
import IdataModelPMemberDtos from "../Models/IdataModelPMemberDtos";
import IdataModeEnterpriseDtos from "../../Entreprises/Models/IdataModeEnterpriseDtos";

const initialTypeState = {
 
    //table enterpriseAssurance   ta1
    idInterprise:0,
    accountInterprise:0,
    enterpriseName: '',
    couvertureDu: '',
    couvertureAau: '',
    resilierSociete:'',
///table PMember pa
    idPmember:0,
    codeAssuree: '',
    nomPrenomAffilie: '',
    cotisationExact: '',
    account:0,
    genre: '',
    catégorieSoins: '',
    email: '',
    telephones: '',
    adresseAffilie: '',
    autresInformations: '',

    plafond_ambilatoire: '',
    plafond_hospitalisation: '',
    plafond_pharmacy: '',
    plafond_ORL: '',
    plafond_urugence: '',
    plafond_ophtalmologie: '',
    category_code: '',
    plafond_dentisteries: '',
    resilier: '',
    dataResiliation: '',
    photoMember: '',
    dateNaissance: '',
    createdBy: '',
    dateCreated: '',
tva:'',
etatCivil:'',
   ////table socialInsurance so
    idSociete:0,
    societeName: ''
};
const initialTypeStateEnterpriseDtos = {
  ////table socialInsurance ta
  idSociete:0,
  societeName:0,
 //table enterpriseAssurance   ta1
idInterprise:0,
accountInterprise:0,
  enterpriseName: '',
  responsable: '',
  email: '',
  phoneNumber: '',
  couvertureDu: '',
  couvertureAau: '',
  address: '',
  autreInformation: '',
  resilier: '',
  dataResiliation: '',
     dateCreatedBy: new Date,
  createdBy: '',
  firstCallForPayment: '',
  secondCallForPayment: '',
  thirdCallForPayment: '',
  modePayment: '',
  frequancePayment: '',
 ////// configuration of assurance start
  primeIndividuelle: '',
  primeTotal: '',
  soinsAmbilatoire: '',
  hospitalisation: '',
  ophtalmologie: '',
  dentisterie: '',
  fraisFuneraires: ''
};

export const TablePmember:React.FC<IPmember>=props=>{
    
  const [CurrantEnterprise, setCurrantEnterprise] = useState<IdataModeEnterpriseDtos>(initialTypeStateEnterpriseDtos);

  const [pushprincipalMemberData, setpushprincipalMemberData] = useState<IdataModelPMemberDtos>(initialTypeState);
  const [modalShow, setModalShow] = useState(false);

  const [PrencipalMember, setPrencipalMember] = useState<Array<IdataModelPMemberDtos>>([]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchPrencipalMember, setSearchPrencipalMember] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  
  const getRequestParams = (socialinsuranceId:any,enterprisesAssuranceId:any, searchnomPrenomAffilie:string, thepage: number, thepageSize: number) => {
    let params = {
      socialinsuranceId:socialinsuranceId,
      enterprisesAssuranceId:enterprisesAssuranceId,
      nomPrenomAffilie:searchnomPrenomAffilie,
      page: thepage,
      size:thepageSize
    };
  
    if (socialinsuranceId) {
      params["socialinsuranceId"] = socialinsuranceId;
    }
    if (enterprisesAssuranceId) {
      params["enterprisesAssuranceId"] = enterprisesAssuranceId;
    }
    if (searchnomPrenomAffilie) {
      params["nomPrenomAffilie"] = searchnomPrenomAffilie;
    }
  
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearchPrencipalMember = (e: ChangeEvent<HTMLInputElement>) => {
    const searchPrencipalMember = e.target.value;
    setSearchPrencipalMember(searchPrencipalMember);
  };
  
  const findBynomPrenomAffilie = () => {
    ServicePrincipalMember.findBynomPrenomAffilie(52,CurrantEnterprise.idInterprise,searchPrencipalMember)
      .then((response: any) => {
        const {PmkB } = response.data;
        setPrencipalMember(PmkB);
        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const retrievePrencipalMember = useCallback(() => {
    const params = getRequestParams(52,CurrantEnterprise.idInterprise,searchPrencipalMember, page, pageSize);
  
    ServicePrincipalMember.getAll(params)
  
      .then((response: any) => {
  
        const {PmkB , totalPages} = response.data;
  
        setPrencipalMember(PmkB);
        setCount(totalPages);
  
        console.log(response.data);
       // console.log(params);
  
      })
  
      .catch((e: Error) => {
        console.log(e);
      });
      }, [PrencipalMember,count]);
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const refreshList = () => {
    retrievePrencipalMember();
    setCurrentIndex(-1);
  };
  
  const setActivePrencipalMember = (onCurrant: IdataModelPMemberDtos, index: number) => {
    setCurrentIndex(index); 
     props.onEdit(onCurrant);
     props.onHide();
    console.log(onCurrant);
    console.log(index);
  };
  const PushPrincipalMemberData = (onCurrant: IdataModelPMemberDtos, index: number) => {
    setCurrentIndex(index); 
    setpushprincipalMemberData(onCurrant);
    setModalShow(true);
    console.log(onCurrant);
    console.log(index);
  };
  
  const OnDeletePrencipalMember = (id:any) => {
  
    ServicePrincipalMember.remove(id)
      .then((response: any) => {
        console.log(response.data);
      //  refreshList();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrievePrencipalMember();
    setCurrantEnterprise(props.onGetEnterpriseDtos);
  },[retrievePrencipalMember,props.onGetEnterpriseDtos,page, pageSize]);
  
    return(
          <>{CurrantEnterprise.idInterprise}
          <Row className="mb-3">
          <Stack  direction="horizontal" gap={2}>
          
          <Form.Group controlId="formFilter" className="col col-sm-3">
              <InputGroup>
                  <Form.Control size="sm"
                   type="text"
                    name="searchEnterprise" 
                      placeholder="Filtre Enterprise"
                    value={searchPrencipalMember}
                     onChange={onChangeSearchPrencipalMember} />
              </InputGroup>
          </Form.Group>
            <Button variant="outline-dark btn-sm"
            onClick={findBynomPrenomAffilie}>Cherchez</Button>
                {"    Items per Page: "}
                  <select onChange={handlePageSizeChange} value={pageSize}>
                    {pageSizes.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                <Pagination
                  className="my-3"
                  count={count}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
             </Stack> 
              
       
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
            <th>ID</th>
              <th>Etat Civil</th>
              <th>Nom/prenom</th>
              <th>Code</th>
              <th>sex</th>
              <th>Telephone</th>
              <th>Naissance</th>
              <th>cotisation</th>
              <th>category code</th>
              <th>category Soins</th>
              <th>Resilier</th>
              <th>date debit</th>
              <th>date fin</th>
              <th>Options</th>

              
            </tr>
          </thead>
          <tbody>
          {PrencipalMember.length>0 ? (
            PrencipalMember.map((i, index) => (
                
                  <tr key={i.idPmember }>
                  <td>{i.idInterprise}</td>
                  <td>{i.etatCivil}</td>
                  <td>{i.nomPrenomAffilie}</td>
                  <td>{i.codeAssuree}</td>
                  <td>{i.genre}</td>
                  <td>{i.telephones}</td>
                  <td>{i.dateNaissance}</td>
                  <td>{i.cotisationExact}</td>
                  <td>{i.category_code}</td>
                  <td>{i.catégorieSoins}</td>
                  <td>{i.resilier}</td>
                  <td>{i.couvertureDu}</td>
                  <td>{i.couvertureAau}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setActivePrencipalMember(i, index)}>Edit</Button>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => PushPrincipalMemberData(i, index)}>+</Button>
  
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeletePrencipalMember(i.idPmember)}>Delete</Button>
                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no Type Doctors</td>
              </tr>
            )}
  
          </tbody>
        </Table>
        <Beneficieres
            show={modalShow}
            onHide={() => setModalShow(false)}
            editinfo={pushprincipalMemberData}
         />
        </Row>
  
          </>
      );
  }