import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import TypeDoctorServices from '../Services/TypeDoctorServices';
import IdataModelTypeDoctor from '../Models/IdataModelTypeDoctor';

let accountIncrement=0

const initialTypeState = {
  id: null,
  typeName: '',
  accountTypeDoctor:accountIncrement,
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
// 👇️ const now: Date
//const now = new Date();

//console.log(now); // 👉️ 2023-01-20T12:01:41.216Z

//console.log(now.toLocaleDateString()); // 👉️ 1/20/2023

//console.log(now.toLocaleString()); // 👉️ 1/20/2023, 2:01:41 PM

//console.log(now.toUTCString()); // 👉️ Fri, 20 Jan 2023 12:01:41 GMT

//console.log(now.toISOString()); // 👉️ 2023-01-20T12:01:41.216Z

export const AddTypeDoctor:React.FC=()=> {

  const getLastAccountType = useCallback(() => {
    TypeDoctorServices.getLastAccountType()
      .then((response: any) => {
        accountIncrement = response.data.accountTypeDoctor;
        console.log(accountIncrement);
      })
  }, []);
  
  useEffect(() => {
    getLastAccountType();
  }, [getLastAccountType]);


const [isInput,setInput]=useState<IdataModelTypeDoctor>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const saveTypeDoctors = () => {  

        if(accountIncrement===undefined){
          accountIncrement=1000000
        }
        let data = {
          typeName: isInput.typeName,
          accountTypeDoctor: accountIncrement+1,
          createdBy:'haliii ganii',
          dateCreatedBy: new Date()
        };

        TypeDoctorServices.create(data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            typeName: response.data.typeName,
            accountTypeDoctor:response.data.accountTypeDoctor,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const resetTypeDoctor = () => {
        setInput(initialTypeState);
      }

     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Type de Docteur</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="typeName" name="typeName" value={isInput.typeName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>saveTypeDoctors()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={resetTypeDoctor} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}