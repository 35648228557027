import { useState } from "react";
import IdataModelSejourVisite from "../../Components/Acts/SejourVisites/InOneSejourVisites/Models/IdataModelSejourVisite";
import IdataModelSejourVisitePlafondDtos from "../../Components/Acts/SejourVisites/SejourVisitePlafond/Models/IdataModelSejourVisitePlafondDtos";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AddInOneSjourVisite } from "../../Components/Acts/SejourVisites/InOneSejourVisites/TypeOperations/AddSjourVisite";
import { TableSejourVisite } from "../../Components/Acts/SejourVisites/InOneSejourVisites/Tables/TableSejourVisite";
import { Addplafond } from "../../Components/Acts/SejourVisites/SejourVisitePlafond/PriceOperations/Addplafond";
import { Editplafond } from "../../Components/Acts/SejourVisites/SejourVisitePlafond/PriceOperations/Editplafond";
import { EditInOneSejourV } from "../../Components/Acts/SejourVisites/InOneSejourVisites/TypeOperations/EditInOneSejourV";
import { TablePlafondSejourVisite } from "../../Components/Acts/SejourVisites/SejourVisitePlafond/Tables/TablePlafondSejourVisite";


  const initialInOneSejourVisite = {
    id: null,
    typeName: '',
    account:'',
    createdBy: 'Ghost Zila',
    dateCreatedBy: new Date()
  };

  const initialSejourVisitePlafond = {
 // InOneSejourVisites s
 idInOneSejourVisite:0,
 typeName:'',
 account:0,

/// enterprise e
 idEnterprise:0,
 enterpriseName:'',
//// insurance i
 idInsurance:0,
 insuranceName:'',
/// plafond sejourvisite pl
 idplafond:0,
 plafondFacture:0
  };
  


export const SejourVisitesPage:React.FC=()=>
    {
        const [isSwichSejourVisitePlafond,setSwichSejourVisitePlafond]= useState(true);
        const [isSwichInOneSejourVisite,setSwichInOneSejourVisite]= useState(true);


        const [EditSejourVisiteplafond, setEditSejourVisiteplafond] = useState<IdataModelSejourVisitePlafondDtos>(initialSejourVisitePlafond);

        const onCurrant=(data:IdataModelSejourVisitePlafondDtos)=>{
            setEditSejourVisiteplafond(data);
        }
        const [EditInOneSejourVisite, setEditInOneSejourVisite] = useState<IdataModelSejourVisite>(initialInOneSejourVisite);

        const OnCurrant=(data:IdataModelSejourVisite)=>{
            setEditInOneSejourVisite(data);
        }

        return(
            <>
            <Container fluid>
                <Row>
         
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                {isSwichInOneSejourVisite?
                (
                    <AddInOneSjourVisite />
                ):(
      <EditInOneSejourV 
      show={isSwichInOneSejourVisite} 
       onHide={() => setSwichInOneSejourVisite(true)}

            EditInfo={EditInOneSejourVisite} /> 
                )
  }
         <TableSejourVisite
          show={isSwichInOneSejourVisite} 
           onHide={() => setSwichInOneSejourVisite(false)}
         onEditType={OnCurrant}
          />
                    </Card.Body>
                </Card>
                <br />
                </Col>
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                {isSwichSejourVisitePlafond?
                (
                    <Addplafond />
                ):(
      <Editplafond
       show={isSwichSejourVisitePlafond}
         onHide={() => setSwichSejourVisitePlafond(true)}
         editplafondDtos={EditSejourVisiteplafond} /> 
                )
  }
         <TablePlafondSejourVisite
          show={isSwichSejourVisitePlafond} 
           onHide={() => setSwichSejourVisitePlafond(false)}
           onEditpriceActe={onCurrant}
          />
                    </Card.Body>
                </Card>
                <br />
                </Col>

            </Row>

            </Container>
            </>
        );
    }