import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import IdataModelHospitalType from "../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import { IfactureSaisie } from "../interfaces/IfactureSaisie";
import NewFactureSaisieDto from "../models/Dto/NewFactureSaisieDto";
import ServicesNewFactureSaisie from "../services/ServiceNewFactureSaisie";
import { ModalFactureSaisie } from "../Modals/ModalFactureSaisie";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../../../Redux/Store/store";
import { updateCurrentNewFactureSaisie } from "../../../../Redux/slices/currentNewFactureSaisie/currentNewFactureSaisie";

import { useHistory } from 'react-router-dom';

 const NewFactureSaisieDtoinit ={
    
    /// facture fa
    idFacture:'',
    prestation:'', //itule

  prestationPrescriptPar:'',
    pricePrestation:'',
    plafondFacture:'',
    ticketService:'',
    quantity:'',
    partMutuelle:'',
    partpatient:'',
    partTotal:'',
    statusFacturePayer:'',
    createdBy:'',
    dateCreatedSaisie:'',
    timeSaisie:'',


    partMutuelleFacture:0,
    partpatientFacture:0,
    partTotalFacture:0,
    
    ticketServiceFacture:0,

    statusFactureDeclare:'',
    statusFactureVerifier:'',
    statusFactureRembourceAvecAutreFacture:'',
    statusFactureRembourceUneFacture:'',

   /// hospital h
    idhospital:'',
    hospitalName:'',
    referenceId:'',
    typehospital:'',
    hospitalAccount:'',

   /// docteur
    idDoctorName:'',
    prescriptPar:'',
    doctorName:'',
     priceConsultation:'',
    accountNameDoctor:'',

   /// typeDoctor
   idtypeDoctor:'',
   typedoctorName:'',
   accountTypeDoctor:'',
   /// couvertureConfig  se
   idCouvertureConfig:'',
    service:'',
    ticket:'',
    particulie:'',
    type_category:'',
    access:'',
    secter:'',
    plafondOrdonence:'',
/// new visite
    idNewVisite:'',
    receiptBill:'',
    patientName:'',
    typeAssuree:'',
   patientCode:'',
   newVisitecreatedBy:'',
    dateCreatedVisite:'',
    timeCreated:'',

   //social Insuarance so
    idinsurance:'',
    insuranceName:'',
   //////////////////////////////////////////
   /// Enterprise   e
    idEnterprise:'',
    accountEnterprise:'',
    enterpriseName:'',
    couvertureDu:'',
    couvertureAau:'',
    modePayment:'',
    frequancePayment:'',
   /// pmember   p
    idpmember:'',
    codeAssuree:'',
    nomPrenomAffilie:'',
    cotisationExact:'',
    accountPmember:'',
    catégorieSoins:'',
    category_code:'',
   ///// typeBons Visite  tvi
    idVisite:'',
    typeNameVisite:'',
    accountVisite:'',

   ///// typeBon declaration verifivcation rembourcement b
    idtypeBonverification:'',
    typeBonVerificationName:'',
    accountVerificationName:'',
   /// typeBon Commend  tc
    idTypeBonCommend:'',
    typeBonCommendName:'',
    accountBonCommend:'',
   /// depertment dp
    idDepertment:'',
    depertmentName:'',

}

    const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];
  
    let HospitalName='';

    let MonStructure:string='';
export const TableFactureSaisie:React.FC=()=>{
      
  const history = useHistory();
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
const DispatchPushCarrantisNewFactureSaisieDto=useDispatch();
const [isHospitalDropDown,setIsHospitalDropDown]=useState(true);
const [isNewFactureSaisieDto, setisNewFactureSaisieDto] = useState<Array<NewFactureSaisieDto>>([]);
const [currentIndex, setCurrentIndex] = useState<number>(-1);

const [searchReceiptBill, setsearchReceiptBill] = useState<string>("");

const [modalShow, setModalShow] = useState(false);
const [hospital, setHospital] = useState(defaultinitiaHospitalType);



const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];

const getRequestParams = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};
const getRequestParamsByStructure = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     hospitalName:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    hospitalName:hospitalName,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }
  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};

    const  handleChangeTypeHospital = (e: ChangeEvent<HTMLSelectElement>) => {
      HospitalName=e.target.value;
    };

    const getHospitalWithoutPagination = () => {  
      HospitalTypeService.getAllHospitalNameWithoutPagination()
        .then((response: any) => {
          setHospital(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

const onChangesearchReceiptBill = (e: ChangeEvent<HTMLInputElement>) => {
  const searchReceiptBill = e.target.value;
  setsearchReceiptBill(searchReceiptBill);
};

const findAllFactureSasie = () => {

  if(StructuresData.length===1){

    let concatenatedString: string = StructuresData.join(", ");
    ServicesNewFactureSaisie.findBySocialinsuranceIdAndPatientCodeByStructure(52,searchReceiptBill,concatenatedString)
    .then((response: any) => {
      const {facture} = response.data;
      setisNewFactureSaisieDto(facture);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  }else{
    ServicesNewFactureSaisie.findBySocialinsuranceIdAndPatientCode(52,searchReceiptBill)
    .then((response: any) => {
      const {facture} = response.data;
      setisNewFactureSaisieDto(facture);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  }
  
};
const retrieveNewVisites = useCallback(() => {
  
  if (StructuresData.length === 1) {
    let concatenatedString: string = StructuresData.join(", ");
    const params = getRequestParamsByStructure(52, searchReceiptBill, concatenatedString, page, pageSize);

    ServicesNewFactureSaisie.getAllNewFactureSaiseByStructure(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;
        setisNewFactureSaisieDto(facture);
        setCount(totalPages);
        // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  } else {
    const params = getRequestParams(52, searchReceiptBill, page, pageSize);

    ServicesNewFactureSaisie.getAllNewFactureSaise(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;
        setisNewFactureSaisieDto(facture);
        setCount(totalPages);
        // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }
}, [StructuresData, searchReceiptBill, page, pageSize, setisNewFactureSaisieDto, setCount]);

// Usage in the component
useEffect(() => {
  retrieveNewVisites();
}, [retrieveNewVisites]);

const trigerDropDownState = useCallback(() => {
  setIsHospitalDropDown((prevIsHospitalDropDown) => {
    if (StructuresData.length === 1) {
      let concatenatedString: string = StructuresData.join(", ");
      MonStructure = concatenatedString;
      return true;
    } else {
      return false;
    }
  });
}, [StructuresData]);
useEffect(() => {
  retrieveNewVisites();
  getHospitalWithoutPagination();
  trigerDropDownState();
},[page, pageSize, retrieveNewVisites, trigerDropDownState]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };



const setpPushNewVisite = (dataNouvelleVisite: NewFactureSaisieDto, index: number) => {
   setCurrentIndex(index); 
   DispatchPushCarrantisNewFactureSaisieDto(updateCurrentNewFactureSaisie(dataNouvelleVisite));
   setModalShow(true);

  history.push('/FactureSaisie');

};

const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
}

  return(
        <>
        
        <Row className="mb-3">
        <div className="d-flex flex-column align-items-start">

        <Stack  direction="horizontal" gap={2}>
        <Form.Control className="me-auto"
                  placeholder="Filtre le patient ou Numero bon"
                  value={searchReceiptBill}
                   onChange={onChangesearchReceiptBill} 
                  />
                <Button variant="outline-dark btn-sm"
                 onClick={findAllFactureSasie}>Cherchez</Button>
                 

        </Stack>
      <Stack  direction="horizontal" gap={2}>
      {"Page:"}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />         
{!isHospitalDropDown?(

<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
  
<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">hopital..</option>
  {hospital &&
hospital.map((hospital) => (
    <option key={hospital.id} value={hospital.id} >{hospital.hospitalName}</option>
))}
    
</Form.Select>
  </Form.Group>          

         ):(
          
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">{MonStructure}</option>
</Form.Select>
</Form.Group>
         )}
         </Stack>   
</div>
        <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
           <th>Date</th>
           <th>Temp</th>
            <th>Enterprise</th>
            <th>Code</th>
            <th>Patient</th>
            <th>Bon</th>
            <th>Medecin</th>
            <th>Service</th>
            <th>Status</th>
            <th>Prestation</th>
            <th>Quantite</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {isNewFactureSaisieDto.length>0 ? (
          isNewFactureSaisieDto.map((i, index) => (
                <tr key={i.idFacture }>

                <td>{i.dateCreatedSaisie}</td>
                <td>{i.timeCreated}</td>
                <td>{i.enterpriseName}</td>
                <td>{i.codeAssuree}</td>
                <td>{i.patientName}</td>
                <td>{i.receiptBill}</td>
                <td>{i.doctorName}</td>
                <td>{i.depertmentName}</td>
                <td>{i.statusFacturePayer}</td>
                <td>{i.prestation}</td>
                <td>{i.quantity}</td>
                <td>
                <Stack direction="horizontal" gap={0}>
                <Button className="me-4 btn btn-success btn-sm btn-block"
                onClick={() => setpPushNewVisite(i, index)}>Voir</Button>
                </Stack>
                </td>
              </tr>
            ))
          ) : ( 
            <tr>
              <td colSpan={3}>no Data </td>
            </tr>
          )}

        </tbody>
      </Table>

          </Row>

     
        </>
    );
}