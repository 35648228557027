/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from "react";
import NewFactureSaisieDto from "../models/Dto/NewFactureSaisieDto";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import ServicesNewFactureSaisie from "../services/ServiceNewFactureSaisie";
import {Divider, Stack } from "@mui/material";

import Button from 'react-bootstrap/Button';
import { Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import RestPlafondYearService from "../../../ASSURANCES/CounterRestPlafond/Services/RestPlafondYearService";
import PlafondYearTypeVisiteService from "../../../ASSURANCES/PlafondYearTypeVisite/Services/PlafondYearTypeVisiteService";
import ServiceSmember from "../../../ASSURANCES/Beneficieres/Services/ServiceSmember";
import ServicesFactureTotalInOne from "../../FactureTotalinOne/services/ServiceFactureTotalInOne";
import IdataModelCounterRestPlafond from "../../../ASSURANCES/CounterRestPlafond/Models/CounterRestPlafond";
import IdataModelService from "../../../ServicesData/Models/IdataModelService";
import IdataModelTypeDepertment from "../../../HopitalDepertment/Models/IdataModelTypeDepertment";
import ServiceHopital from "../../../ServicesData/Services/ServiceHopital";
import ServiceDepartment from "../../../HopitalDepertment/Services/ServiceDepartment";


  
const columns: GridColDef[] = [

    { field: 'plafondFacture', headerName: 'plafond' },
    { field: 'statusFacturePayer', headerName: 'Status' },
    { field: 'prestation', headerName: 'Prestation' },
    { field: 'pricePrestation', headerName: 'Price ' },

    { field: 'quantity', headerName: 'Quts' },

  //  { field: 'ticketService', headerName: 'D Ticket' },
    { field: 'ticketServiceFacture', headerName: 'Ticket' },
    { field: 'partMutuelle', headerName: 'Part Mutuelle' },
    { field: 'partpatient', headerName: 'Part Patient' },
    { field: 'partTotal', headerName: 'Total'  },

   // { field: 'partMutuelleFacture', headerName: 'F Part Mutuelle' },
   // { field: 'partpatientFacture', headerName: 'F Part Patient' },
   // { field: 'partTotalFacture', headerName: 'F Total'  },

    { field: 'typeNameVisite', headerName: 'Type Viste' },
   // { field: 'typeBonVerificationName', headerName: 'Verfier' },
    { field: 'typeBonCommendName', headerName: 'Type Bon' },
    { field: 'depertmentName', headerName: 'Depertement' },
    { field: 'dateCreatedSaisie', headerName: 'Created' },
    { field: 'timeSaisie', headerName: 'Time Saisie' },
    
  ];
  const idd: GridRowId[]=[];

let newFactureSaisieDtoUnit:Array<NewFactureSaisieDto>=[];

const InitRestPlafond={
  codeId:'',
  numeroBon:0,
  partPatientConsomme:0,
  idRestIncrementPlafondConsomme:0,
  status:'payer',
  created_by:'Ramadhani Ally',
  date_created_by: new Date(),
}

const defaultservice: Array<IdataModelService> = [];
const defaultdepertment:Array<IdataModelTypeDepertment>=[];


export const TableEditFactureMituellePrService:React.FC=()=>

{
  const preferredName = useSelector((state: RootState) => state.user.preferred_username);

  const [service, setservice] = useState(defaultservice);
    
  const [depertment, setdepertment] = useState(defaultdepertment);

  const [idService, seIdService] = useState(0);

    
  const [departmentId, setdepertmentId] = useState(0);

  const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);

 const [isInputFactureSaisie, setisInputFactureSaisie] = useState<{ statusFacturePayer: string }>({ statusFacturePayer: '' });
 

 const [isNewFactureSaisieDto, setisNewFactureSaisieDto] = useState(newFactureSaisieDtoUnit);

 const [isInputRestPlafond,setisInputRestPlafond]=useState<IdataModelCounterRestPlafond>(InitRestPlafond);
  
 const [ischeckBillRecent, setCheckBillRecent] = useState(false);

const [selectedIdInRow, setIdRowSelected] = useState(idd);

const [isIdTotalInOne, setIsIdTtoalInOneRecent] = useState<number>(0);

const [totalAllServicesRecent, setTotalAllServicesRecent] = useState(0);
const [factureCouvertAllServicesRecent, setFactureCouvertAllServicesRecent] = useState<number>(0);
const [factureNocouvertAllServicesRecent, setFactureNocouvertAllServicesRecent] = useState(0);
const [partMutuelleFactureAllServicesRecent, setPartMutuelleFactureAllServicesRecent] = useState(0);

const [partPatientFactureAllServicesRecent, setPartPatientFactureAllServicesRecent] = useState(0);

function getCurrentDate(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  // Months are zero-based, so we add 1 to get the correct month
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}


  
const UpdateFactureSaisie = (id: any) => {
  let data = {
    statusFacturePayer: 'No payer',
  };

  ServicesNewFactureSaisie.updateFactureSaisieStatusPayer(id, data.statusFacturePayer)
    .then((response: any) => {
      setisInputFactureSaisie({
        statusFacturePayer: response.data.statusFacturePayer,
      });

      findAllFactureByInsuarenceAndReceipt();
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const UpdateFactureSaisieById = () => {  

  for (let id of selectedIdInRow) {
    UpdateFactureSaisie(id)
  }
};

const checkExistingPayedBill = () => {  

  const formattedDate = getCurrentDate();
  // Define your parameters
  const params = {
    idinsurance: isgetselecterCurrantNewVisitePushed.idinsurance,
    idDepertment: departmentId, 
    idService: idService,
    inOnedateCreated:formattedDate,
    receiptBill: isgetselecterCurrantNewVisitePushed.receiptBill
  };
  ServicesFactureTotalInOne.checkBillNumberDateIdServiceIdDepartment(params)
  .then((response: any) => {
    // Check if the response data is not empty
    if (response.data!== '' ) {
      setCheckBillRecent(true)
        // Update state with the response data
      console.log('checkBillNumberDateIdServiceIdDepartment if ', response);
      // Add further processing if needed, such as updating state or rendering data

      setIsIdTtoalInOneRecent(response.data.idFactureInOne)

      console.log("response.idFactureInOne",response.idFactureInOne);

setTotalAllServicesRecent(response.data.totalFactureInOne);
setFactureCouvertAllServicesRecent(response.data.totalFactureInOneCouvert);
setFactureNocouvertAllServicesRecent(response.data.totalFactureInOneNoCouvert);
setPartMutuelleFactureAllServicesRecent(response.data.totalInOnePartSolisFacture);
setPartPatientFactureAllServicesRecent(response.data.totalInOnePartPatientFacture);
    } else {
      // If the response data is empty, handle it accordingly (e.g., call a function)

     setCheckBillRecent(false)
      console.log('No content found.');
     // setisNewFactureSaisieDto(newFactureSaisieDtoUnit)
      // Add further handling as per your requirement

setTotalAllServicesRecent(0);
setFactureCouvertAllServicesRecent(0);
setFactureNocouvertAllServicesRecent(0);
setPartMutuelleFactureAllServicesRecent(0);
setPartPatientFactureAllServicesRecent(0);
    }
  })
  .catch(error => {
    // Handle errors
    console.error('Error fetching data:', error);
    // Add further error handling if needed
  });

};

const ActualizePerServices = () => {  
    findAllFactureByInsuarenceAndReceipt()
  };
   

  const findAllFactureByInsuarenceAndReceipt = async () => {
    try {
      ServicesNewFactureSaisie.findBySocialinsuranceIdAndReceiptBillIdServiceIdDepertmentPayer(
        isgetselecterCurrantNewVisitePushed.idinsurance,
        isgetselecterCurrantNewVisitePushed.receiptBill,
        idService,
        departmentId,
      ).then((response:any)=>{
        const facture = response.data;
console.log("findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelle",response.data)
      setisNewFactureSaisieDto(facture);
      checkExistingPayedBill();

      setIdRowSelected(facture.map((row: { idFacture: any; }) => row.idFacture));
      console.log("facture.map((row: { idFacture: any; }) => row.idFacture)",facture.map((row: { idFacture: any; }) => row.idFacture))  
      })
      
    } catch (e) {
      console.log(e);
    }
  };

const removeFactureTotoalInOne = () => { 

  ServicesFactureTotalInOne.remove(Number(isIdTotalInOne))
      .then((response: any) => {
       
          UpdateRestPlafond();
          UpdateFactureSaisieById();
          console.log(response.data);
      }).catch((e: Error) => {
    console.log(e);
  });
  
};


const updateBill = () => { 

  if(ischeckBillRecent===false){
    console.log("no value to update")
  }else{
    removeFactureTotoalInOne()
  }
};

const UpdateRestPlafond = () => { 
  let currentDateFormat=getCurrentDate()
  
    let data = {
      codeId:isgetselecterCurrantNewVisitePushed.codeAssuree,
      numeroBon:isgetselecterCurrantNewVisitePushed.receiptBill,
      partPatientConsomme:- Number(partPatientFactureAllServicesRecent),
      idRestIncrementPlafondConsomme:0,
      status:"payer" ,
      created_by:preferredName,
      date_created_by:currentDateFormat
    };
  
    RestPlafondYearService.createRestPlafond(
      isgetselecterCurrantNewVisitePushed.idinsurance,
      isgetselecterCurrantNewVisitePushed.idEnterprise,
      isgetselecterCurrantNewVisitePushed.idpmember,
      isgetselecterCurrantNewVisitePushed.plafondYearId,
      isgetselecterCurrantNewVisitePushed.categoryId,
      isgetselecterCurrantNewVisitePushed.idVisite,
      data)
    .then((response: any) => {
      setisInputRestPlafond({
        id: response.data.id,
        codeId: response.data.incrementIdReceiptBill,
        numeroBon: response.data.receiptBill,
        partPatientConsomme:response.data.partPatientConsomme,
        idRestIncrementPlafondConsomme:response.data.idRestIncrementPlafondConsomme,
        status:response.data.status,
        created_by: response.data.created_by,
        date_created_by: response.data.date_created_by
      });
      console.log(response.data); 
      console.log("isPartPatientFacture",- Number(partPatientFactureAllServicesRecent))
    }).catch((e: Error) => {
      console.log(e);
    });
    
  
};
const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }
  const handleChangeConfig = (e: { target: { name: any; value: any; }; }) => {
    seIdService(e.target.value)
   
  }
  const handleChangeDepertment = (e: { target: { name: any; value: any; }; }) => {
    setdepertmentId(e.target.value)
//        console.log('')
};
const getServices = useCallback(() => {
    ServiceHopital.getAllTypeWithoutPagination()
      .then((response: any) => {
        setservice(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [setservice]);

  const getDepertment = useCallback(() => {
    ServiceDepartment.getAllTypeWithoutPagination()
      .then((response: any) => {
        setdepertment(response.data);
        // setdepertmentFacture(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [setdepertment]);
   
  useEffect(() => {
    getDepertment();
    getServices();
},[getDepertment,  getServices]);

    
      return (
        <>
        <div>
     < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">

<Form.Group controlId="formBasicEmail" className="col col-sm-3">
<Form.Label> plafond</Form.Label>
<Form.Select className="form-control" size="sm" 
onChange={handleChangeConfig}>
<option value="0">Choose..</option>
{service &&
service.map((service) => (
<option key={service.id} value={service.id} >{service.typeName}</option>
))
}

</Form.Select>
</Form.Group>

<Form.Group controlId="formBasicEmail" className="col col-sm-3">
<Form.Label>Depertment</Form.Label>
<Form.Select className="form-control" size="sm" 
onChange={handleChangeDepertment}>
<option value="0">Choose..</option>
{
depertment &&
depertment.map((depertment) => (
<option key={depertment.id} value={depertment.id} >{depertment.typeName}</option>
))}

</Form.Select>
</Form.Group>

<Button className="col col-sm-3" onClick={() => {ActualizePerServices()}} variant='me-4 btn btn-success btn-sm  btn-block'>
         Actualise
       </Button>
         </Row>
        
            </form>
     </div>

     <div>

<Form.Label ><strong>Facture</strong></Form.Label>
       <Stack
  direction="row"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}
>
              <Form.Label><strong>Total de Facture</strong>:{totalAllServicesRecent}</Form.Label>
              <Form.Label><strong>Part Patient </strong>:{partPatientFactureAllServicesRecent}</Form.Label> 
              <Form.Label><strong>Part Mituelle </strong>:{partMutuelleFactureAllServicesRecent}</Form.Label> 
              <Form.Label><strong>Facture couvert</strong>:{factureCouvertAllServicesRecent}</Form.Label> 
              <Form.Label><strong>Facture No couvert</strong>:{factureNocouvertAllServicesRecent}</Form.Label> 
</Stack>     



     </div>
     
<div style={{ width: '100%' }}>
<DataGrid 
          rows={isNewFactureSaisieDto}
          getRowId={(row) => row.idFacture}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel:{page:0,pageSize:6},
            },
          }}
          pageSizeOptions={[10,15,25,35,45]}
            autoHeight
            
          checkboxSelection
         rowSelectionModel={selectedIdInRow}
         
         
        />

 <Stack 
 direction="row"
 spacing={3}>
  

      
   <Button
     onClick={() => { updateBill()}}
     variant='me-4 btn btn-success btn-sm btn-block'
   >
     Facture No payer
   </Button>

 </Stack>

     </div>


          
        </>
      );
    }
