import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelSejourPrise from "../Models/IdataModelSejourPrise";
import IdataModelSejourVisiteDtos from "../Models/IdataModelSejourVisiteDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelSejourVisiteDtos>>("/AllInOneSejourVisitesDtosPage", { params });
};

const getAllInOneSejourVisitesTariffNameWithoutPagination = () => {
  return http.get<Array<IdataModelSejourPrise>>("/sortedInOneSejourVisitesTariff");
};

const create = (detailsSoinsId:any,hospitalId:any,data: IdataModelSejourPrise) => {
  return http.post<IdataModelSejourPrise>(`/${detailsSoinsId}/${hospitalId}/InOneSejourVisitesTariff`, data);
};

const update = (id:any, data: IdataModelSejourPrise) => {
  return http.put<any>(`/InOneSejourVisitesTariff/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/InOneSejourVisitesTariff/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/InOneSejourVisitesTariff`);
};

const findBytypeName = (typeName: string) => {
  return http.get<Array<IdataModelSejourVisiteDtos>>(`/AllInOneSejourVisitesDtosPage?typeName=${typeName}`);
};

const PriceSoinsService = {
  getAllInOneSejourVisitesTariffNameWithoutPagination,
  getAll,
  create,
  update,
  remove,
  removeAll,
  findBytypeName,
};

export default PriceSoinsService;
