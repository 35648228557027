import {  ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import IdataModelSejourVisitePlafondDtos from "../Models/IdataModelSejourVisitePlafondDtos";
import { IplafondActesSejour } from "../Interfaces/IplafondActesSejour";
import ServicePlafondServiseVisites from "../Services/ServicePlafondServiseVisites";




export const TablePlafondSejourVisite:React.FC<IplafondActesSejour>=props=>{

    const [plafondSejourVisite, setplafondSejourVisite] = useState<Array<IdataModelSejourVisitePlafondDtos >>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchdetails, setSearchdetails] = useState<string>("");
    
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6];
    
    const getRequestParams = (insuranceId:any,searchenterpriseName:string, thepage: number, thepageSize: number) => {
      let params = {
        insuranceId:insuranceId,
        enterpriseName:searchenterpriseName,
        page: thepage,
        size:thepageSize
      };
    
      if (insuranceId) {
        params["insuranceId"] = insuranceId;
      }
      if (searchenterpriseName) {
        params["enterpriseName"] = searchenterpriseName;
      }
    
      if (thepage) {
        params["page"] = thepage - 1;
      }
      
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
   

    const onChangeSearchdetails = (e: ChangeEvent<HTMLInputElement>) => {
      const searchdetails = e.target.value;
      setSearchdetails(searchdetails);
    };
    
    const findBydetails = () => {
      ServicePlafondServiseVisites.findByEnterpriseName(52,searchdetails)
        .then((response: any) => {
          const {types } = response.data;
          setplafondSejourVisite(types);
          setCurrentIndex(-1);
          console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const retrievedetails = () => {
      const params = getRequestParams(52,searchdetails, page, pageSize);
      ServicePlafondServiseVisites.getAll(params)
    
        .then((response: any) => {
    
          const {types , totalPages} = response.data;
    
          setplafondSejourVisite(types);
          setCount(totalPages);
    
          console.log(response.data);
          console.log(params);
    
        })
    
        .catch((e: Error) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrievedetails();       
    },[page , pageSize]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
    const refreshList = () => {
      retrievedetails();
      setCurrentIndex(-1);
    };
    
    const setActivedetails = (idataModelSejourVisitePlafondDtos : IdataModelSejourVisitePlafondDtos , index: number) => {
      setCurrentIndex(index); 
       props.onEditpriceActe(idataModelSejourVisitePlafondDtos);
       props.onHide();
      console.log(idataModelSejourVisitePlafondDtos);
      console.log(index);
    };
    
    const OnDeletedetails = (id:any) => {
    
      ServicePlafondServiseVisites.remove(id)
        .then((response: any) => {
          console.log(response.data);
          refreshList();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

      return(
            <>
            <Row className="mb-3">
          
          <Stack  direction="horizontal" gap={2}>
                    <Form.Control className="me-auto"
                      placeholder="Filtre ENTREPRISE."
                      value={searchdetails}
                       onChange={onChangeSearchdetails} 
                      />
                    <Button variant="outline-dark btn-sm"
                     onClick={findBydetails}>Cherchez</Button>
             </Stack>   
            <div className="mt-3">
              
            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
                <Row>
                  <Col>
                  <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />      
            </Col>             
          </Row>
              
            </div>
            <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>Societe</th>
                <th>Sejour/Visite</th>
                <th>plafond</th>
                <th> Enterprise</th>
              </tr>
            </thead>
            <tbody>
            {plafondSejourVisite.length>0 ? (
              plafondSejourVisite.map((i, index) => (
                    <tr key={index }>
                    <td>{index+1}</td>
                    <td>{i.insuranceName}</td>
                    <td>{i.enterpriseName}</td>
                    <td>{i.plafondFacture}</td>
                    <td>{i.enterpriseName}</td>
                    <td>
                    <Stack direction="horizontal" gap={2}>
                    <Button className="me-4 btn btn-success btn-sm btn-block"
                    onClick={() => setActivedetails(i, index)}>Edit</Button>
    
                      <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeletedetails(i.idplafond)}>Delete</Button>
                    </Stack>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={3}>No details</td>
                </tr>
              )}
    
            </tbody>
          </Table>
          </Row>
    
            </>
        );
    }

