// currentNewFactureSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewVisiteDtos from '../../../Components/Espace_Patients/NouvelleVisite/Models/Dtos/NewVisiteDtos';



const initialState:NewVisiteDtos= {
    

    /// facture fa
    idFacture:null,
    receiptBill:null,
    patientName:null,
    typeAssuree:null,
   createdBy:null,
   dateCreatedSaisie:null,
   timeSaisie:null,
  /// hospital h
   idhospital:null,
   hospitalName:null,
   referenceId:null,
   typehospital:null,
   hospitalAccount:null,

  /// docteur
   idDoctorName:null,
   doctorName:null,
   prescriptPar:null,
   priceConsultation:null,
   accountNameDoctor:null,

  /// typeDoctor
   idtypeDoctor:null,
   typedoctorName:null,
   accountTypeDoctor:null,
  /// couvertureConfig  se
   idCouvertureConfig:null,
   ticket:null,
   ticketFacture:null,
   particulie:null,
   access:null,
   secter:null,
   plafondOrdonence:null,

    // services ser
    idService:null,
    serviceName:null,
   // code_category co

   idCategoryCode:null,
   categoryCode:null,
   


  //social Insuarance so
   idinsurance:null,
   insuranceName:null,
  //////////////////////////////////////////
  /// Enterprise   e
   idEnterprise:null,
   accountEnterprise:null,
   enterpriseName:null,
   couvertureDu:null,
   couvertureAau:null,
   modePayment:null,
   frequancePayment:null,
  /// pmember   p
   idpmember:null,
   codeAssuree:null,
   nomPrenomAffilie:null,
   cotisationExact:null,
   accountPmember:null,
   catégorieSoins:null,
   category_code:null,
   etatCivil:null,
  ///// typeBons Visite  tvi
   idVisite:null,
   typeNameVisite:null,
   accountVisite:null,
  ///// typeBon declaration verifivcation rembourcement b
   idtypeBonverification:null,
   typeBonVerificationName:null,
   accountVerificationName:null,
  /// typeBon Commend  tc
   idTypeBonCommend:null,
   typeBonCommendName:null,
   accountBonCommend:null,
  /// depertment dp
   idDepertment:null,
   depertmentName:null,
/// plafondYear pla
plafondYearId:null,
plafondYear:null,
/// category c
categoryId:null,
categoryName:null

}

const currentNewFactureSlice = createSlice({
  name: 'currentNewFacture',
  initialState,
  reducers: {
    updatecurrentNewFacture: (state, action: PayloadAction<NewVisiteDtos>) => {
        
      Object.assign(state, action.payload);
    },
    clearcurrentNewFacture: (state) => {
      return initialState;
    },
  },
});

export const { updatecurrentNewFacture, clearcurrentNewFacture } = currentNewFactureSlice.actions;
export default currentNewFactureSlice.reducer;
