import {  ChangeEvent, useEffect, useState } from "react";
import IdataModelPharmacy from "../Models/IdataModelPharmacy";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import { Button, Form, Row, Stack, Table } from "react-bootstrap";
import PharmacyTypeService from "../Services/PharmacyTypeService";
import { Ipharmacy } from "../Interfaces/IPharmacy";


export const PharmacyTable:React.FC<Ipharmacy>=props=>{
    

    const [Pharmacy, setPharmacy] = useState<Array<IdataModelPharmacy>>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchPharmacy, setSearchPharmacy] = useState<string>("");
    
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6, 9,12];
    
    const getRequestParams = (searchpharmacyName:string, thepage: number, thepageSize: number) => {
      let params = {
        pharmacyName:searchpharmacyName,
        page: thepage,
        size:thepageSize
      };
    
      if (searchpharmacyName) {
        params["pharmacyName"] = searchpharmacyName;
      }
    
      if (thepage) {
        params["page"] = thepage - 1;
      }
    
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
    
    const onChangeSearchPharmacy = (e: ChangeEvent<HTMLInputElement>) => {
      const searchPharmacy = e.target.value;
      setSearchPharmacy(searchPharmacy);
    };
    
    const findByPharmacy = () => {
      PharmacyTypeService.findByPharmacyName(searchPharmacy)
        .then((response: any) => {
          const {Pharmacies } = response.data;
          setPharmacy(Pharmacies);
          setCurrentIndex(-1);
          console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const retrievePharmacy = () => {
      const params = getRequestParams(searchPharmacy, page, pageSize);
    
      PharmacyTypeService.getAll(params)
    
        .then((response: any) => {
    
          const {Pharmacies , totalPages} = response.data;
    
          setPharmacy(Pharmacies);
          setCount(totalPages);
    
          console.log(response.data);
          console.log(params);
    
        })
    
        .catch((e: Error) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrievePharmacy();
    },[page , pageSize]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
    const refreshList = () => {
      retrievePharmacy();
      setCurrentIndex(-1);
    };
    
    const setActivePharmacy = (dataPharmacy: IdataModelPharmacy, index: number) => {
      setCurrentIndex(index); 
       props.onEditPharmacy(dataPharmacy);
       props.onHide();
      console.log(dataPharmacy);
      console.log(index);
    };
    
    const OnDeletePharmacy = (id:any) => {
    
      PharmacyTypeService.remove(id)
        .then((response: any) => {
          console.log(response.data);
          refreshList();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    
      return(
            <>
            <Row className="mb-3">
          
          <Stack  direction="horizontal" gap={2}>
                    <Form.Control className="me-auto"
                      placeholder="Filtre le paharmacie..."
                      value={searchPharmacy}
                       onChange={onChangeSearchPharmacy} 
                      />
                    <Button variant="outline-dark btn-sm"
                     onClick={findByPharmacy}>Cherchez</Button>
             </Stack>   
            <div className="mt-3">
              
            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
            <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>Pharmacie</th>
                <th>Plafond</th>
              </tr>
            </thead>
            <tbody>
            {Pharmacy.length>0 ? (
              Pharmacy.map((i, index) => (
                  
                    <tr key={i.id }>
                    <td>{i.id}</td>
                    <td>{i.pharmacyName}</td>
                    <td>{i.plafondFacture}</td>
                    <td>
                    <Stack direction="horizontal" gap={2}>
                    <Button className="me-4 btn btn-success btn-sm btn-block"
                    onClick={() => setActivePharmacy(i, index)}>Edit</Button>
    
                      <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeletePharmacy(i.id)}>Delete</Button>
                    </Stack>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={3}>No Pharmacy</td>
                </tr>
              )}
    
            </tbody>
          </Table>
          </Row>
    
            </>
        );
    }

