// clientSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ArrayState {
  data: string[];
}

const initialState: ArrayState = {
 // data: ['offline_access', 'default-roles-hema-clinic', 'uma_authorization', 'receptionist', 'user'],
  data: [],
};

const clientSlice = createSlice({
  name: 'clientRoles',
  initialState,
  reducers: {
    updateClientRoles: (state, action: PayloadAction<string[]>) => {
      state.data = action.payload;
    },
  },
});

export const { updateClientRoles } = clientSlice.actions;
export default clientSlice.reducer;
