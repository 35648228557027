import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Card, Row, Form, InputGroup } from "react-bootstrap";
import TypeService from '../Services/TypeServices';
import IdataModelTypeBonCommendDtos from '../Models/IdataModelTypeBonCommendDtos';
import IdataModelTypeBonCommend from '../Models/IdataModelTypeBonCommend';

  interface IEditType {
    show: boolean; 
    onHide: () => void; 
    EditInfo: IdataModelTypeBonCommendDtos;
  }

  export const EditTypeBonSoin :React.FC<IEditType>=props=> {

    const initialTypeState = {
      id: null,
      typeName: '',
      account:0,
      createdBy: '',
      dateCreatedBy: new Date()
    };
   

    const [isInputDtos,setInputDtos]=useState<IdataModelTypeBonCommendDtos>(props.EditInfo);
    const [isInput,setInput]=useState<IdataModelTypeBonCommend>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputDtos({ ...isInputDtos, [name]: value });
       // console.log(event.target)
        }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditType = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              typeName: isInputDtos.typeBonsName,
              createdBy:isInput.createdBy,
              dateCreatedBy: isInput.dateCreatedBy
            };
    
            TypeService.update(isInputDtos.typeBonsId,data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                typeName: response.data.typeName,
                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
          
  useEffect(() => {

    setInputDtos(props.EditInfo)

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
            <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-6">
               <Form.Label>Edit Type  </Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="text" name="typeBonsName" value={ isInputDtos.typeBonsName} onChange={handleChange} />
               </InputGroup>
           </Form.Group>
            </Row>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditType} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
