import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import IdataModelHospitalType from "../../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/Store/store";
import NewFactureSaisieDto from "../../models/Dto/NewFactureSaisieDto";
import HospitalTypeService from "../../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import ServicesNewFactureSaisie from "../../services/ServiceNewFactureSaisie";
import { updateCurrentNewFactureSaisie } from "../../../../../Redux/slices/currentNewFactureSaisie/currentNewFactureSaisie";
import { ModalFactureSaisie } from "../../Modals/ModalFactureSaisie";
import React from "react";

import { useHistory } from 'react-router-dom';
    const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];
  
    let HospitalName='';

    let MonStructure:string='';
export const TableListeVisiteGroupHospital:React.FC=()=>{

  const history = useHistory();
  
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
const DispatchPushCarrantisNewFactureSaisieDto=useDispatch();
const [isHospitalDropDown,setIsHospitalDropDown]=useState(true);
const [isNewFactureSaisieDto, setisNewFactureSaisieDto] = useState<Array<NewFactureSaisieDto>>([]);
const [currentIndex, setCurrentIndex] = useState<number>(-1);

const [searchReceiptBill, setsearchReceiptBill] = useState<string>("");

const [modalShow, setModalShow] = useState(false);
const [hospital, setHospital] = useState(defaultinitiaHospitalType);
const [groupedData, setGroupedData] = useState<Map<string, NewFactureSaisieDto[]>>(new Map());
  


const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];

const getRequestParams = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};
const getRequestParamsByStructure = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     hospitalName:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    hospitalName:hospitalName,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }
  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};

    const  handleChangeTypeHospital = (e: ChangeEvent<HTMLSelectElement>) => {
      HospitalName=e.target.value;
    };

    const getHospitalWithoutPagination = () => {  
      HospitalTypeService.getAllHospitalNameWithoutPagination()
        .then((response: any) => {
          setHospital(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

const onChangesearchReceiptBill = (e: ChangeEvent<HTMLInputElement>) => {
  const searchReceiptBill = e.target.value;
  setsearchReceiptBill(searchReceiptBill);
};

const findAllFactureSasie = () => {

  if(StructuresData.length===1){

    let concatenatedString: string = StructuresData.join(", ");
    ServicesNewFactureSaisie.findBySocialinsuranceIdAndPatientCodeByStructure(52,searchReceiptBill,concatenatedString)
    .then((response: any) => {
      const {facture} = response.data;
      setisNewFactureSaisieDto(facture);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  }else{
    ServicesNewFactureSaisie.findBySocialinsuranceIdAndPatientCode(52,searchReceiptBill)
    .then((response: any) => {
      const {facture} = response.data;
      setisNewFactureSaisieDto(facture);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  }
  
};
const retrieveNewVisites = useCallback(() => {
  
  if (StructuresData.length === 1) {
    let concatenatedString: string = StructuresData.join(", ");
    const params = getRequestParamsByStructure(52, searchReceiptBill, concatenatedString, page, pageSize);

    ServicesNewFactureSaisie.getAllNewFactureSaiseByStructureGrouped(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;
        setisNewFactureSaisieDto(facture);
        setCount(totalPages);
        // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  } else {
    const params = getRequestParams(52, searchReceiptBill, page, pageSize);

    ServicesNewFactureSaisie.getAllNewFactureSaise(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;
        setisNewFactureSaisieDto(facture);
        setCount(totalPages);
        // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }
}, [StructuresData, searchReceiptBill, page, pageSize, setisNewFactureSaisieDto, setCount]);

// Usage in the component
useEffect(() => {
  retrieveNewVisites();
}, [retrieveNewVisites]);

const trigerDropDownState = useCallback(() => {
  setIsHospitalDropDown((prevIsHospitalDropDown) => {
    if (StructuresData.length === 1) {
      let concatenatedString: string = StructuresData.join(", ");
      MonStructure = concatenatedString;
      return true;
    } else {
      return false;
    }
  });
}, [StructuresData]);
useEffect(() => {
  retrieveNewVisites();
  getHospitalWithoutPagination();
  trigerDropDownState();
},[page, pageSize, retrieveNewVisites, trigerDropDownState]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };



const setpPushNewVisite = (dataNouvelleVisite: NewFactureSaisieDto, index: number) => {
   setCurrentIndex(index); 
   DispatchPushCarrantisNewFactureSaisieDto(updateCurrentNewFactureSaisie(dataNouvelleVisite));
   setModalShow(true);

  history.push('/FactureSaisie');
};

const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
}
const calculateGroupSums = (groupItems: NewFactureSaisieDto[]): { partMutuelleFactureSum: number, partpatientFactureSum: number, partTotalFactureSum: number } => {
  let partMutuelleFactureSum = 0;
  let partpatientFactureSum = 0;
  let partTotalFactureSum = 0;

  groupItems.forEach((facture) => {
    partMutuelleFactureSum += facture.partMutuelleFacture;
    partpatientFactureSum += facture.partpatientFacture;
    partTotalFactureSum += facture.partTotalFacture;
  });

  return { partMutuelleFactureSum, partpatientFactureSum, partTotalFactureSum };
};

useEffect(() => {
    // Update groupedData whenever fetched data changes
    const groupData = () => {
      const grouped = new Map<string, NewFactureSaisieDto[]>();
      isNewFactureSaisieDto.forEach((facture) => {
        const key = `${facture.dateCreatedSaisie} -  ${facture.enterpriseName}  -  ${facture.receiptBill}  -  ${facture.patientName}`;
        const existingGroup = grouped.get(key) || [];
        existingGroup.push(facture);
        grouped.set(key, existingGroup);
      });
      setGroupedData(grouped);
    };

    groupData();
  }, [isNewFactureSaisieDto]);

  return(
        <>
        
        <Row className="mb-3">
        <div className="d-flex flex-column align-items-start">

        <Stack  direction="horizontal" gap={2}>
        <Form.Control className="me-auto"
                  placeholder="Filtre le patient ou Numero bon"
                  value={searchReceiptBill}
                   onChange={onChangesearchReceiptBill} 
                  />
                <Button variant="outline-dark btn-sm"
                 onClick={findAllFactureSasie}>Cherchez</Button>
                 

        </Stack>
      <Stack  direction="horizontal" gap={2}>
      {"Page:"}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />         
{!isHospitalDropDown?(

<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
  
<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">hopital..</option>
  {hospital &&
hospital.map((hospital) => (
    <option key={hospital.id} value={hospital.id} >{hospital.hospitalName}</option>
))}
    
</Form.Select>
  </Form.Group>          

         ):(
          
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">{MonStructure}</option>
</Form.Select>
</Form.Group>
         )}
         </Stack>   
</div><Table striped bordered hover size="sm" responsive>
  <thead>
    <tr>
      <th>Date</th>
      <th>bon</th>
      <th>Adherent</th>
      <th>Code</th>
      <th>Enterprise</th>
      <th>Beneficieres</th>
      <th>Doctor</th>
      <th>Depertment</th>
      <th>Prestation</th>
      <th>Plafond</th>
      <th>QTE</th>
      <th>P.U</th>
      <th>100%</th>
      <th>Par solis</th>
      <th>Par Patient</th>
      <th>Time</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {Array.from(groupedData).map(([groupKey, groupItems], index) => (
      <React.Fragment key={index}>
    <tr>
      <td colSpan={9}>Conclusion sur facture</td>
      <td colSpan={1}></td>
      <td colSpan={1}></td>
      <td colSpan={1}>Total</td>

      <td colSpan={1}>{calculateGroupSums(groupItems).partTotalFactureSum}</td>
      <td colSpan={1}>{calculateGroupSums(groupItems).partMutuelleFactureSum}</td>
      <td colSpan={1}>{calculateGroupSums(groupItems).partpatientFactureSum}</td>
      <td colSpan={1}></td>
    </tr>
        {groupItems.map((facture, factureIndex) => (
          <tr key={`${groupKey}-${factureIndex}`}>
            <td>{facture.dateCreatedSaisie}</td>
            <td>{facture.receiptBill}</td>
            <td>{facture.nomPrenomAffilie}</td>
            <td>{facture.codeAssuree}</td>
            <td>{facture.enterpriseName}</td>
            <td>{facture.patientName}</td>
            <td>{facture.doctorName}</td>
            <td>{facture.depertmentName}</td>
            <td>{facture.prestation}</td>
            <td>{facture.plafondFacture}</td>
            <td>{facture.quantity}</td>
            <td>{facture.pricePrestation}</td>
            <td>{facture.partTotalFacture}</td>
            <td>{facture.partMutuelleFacture}</td>
            <td>{facture.partpatientFacture}</td>
            <td>{facture.timeCreated}</td>
            <td>
              <Stack direction="horizontal" gap={0}>
                <Button
                  className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setpPushNewVisite(facture, factureIndex)}
                >
                  Voir
                </Button>
              </Stack>
            </td>
          </tr>
        ))}
      </React.Fragment>
    ))}
  </tbody>
</Table>




          </Row>

     
        </>
    );
}