import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewFactureLivraisonMedicamentDto from '../../../Components/Espace_Patients/FactureMedicament/models/Dto/NewFactureLivraisonMedicamentDto';

const initialState: NewFactureLivraisonMedicamentDto[] = [];

const currentMedicalLivraisonArray = createSlice({
  name: 'currentMedicalLivraisonArray',
  initialState,
  reducers: {                                        
    addMedicalLivraisonRecord: (state, action: PayloadAction<NewFactureLivraisonMedicamentDto>) => {
      state.push(action.payload);
    },
    addMultipleMedicalLivraisonRecords: (state, action: PayloadAction<NewFactureLivraisonMedicamentDto[]>) => {
        state.push(...action.payload);
      },
    updateMedicalLivraisonRecord: (state, action: PayloadAction<NewFactureLivraisonMedicamentDto>) => {
      const index = state.findIndex(record => record.idLivree === action.payload.idLivree);
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
    deleteMedicalLivraisonRecord: (state, action: PayloadAction<any>) => {
      return state.filter(record => record.idLivree !== action.payload);
    },
    clearMedicalLivraisonRecords: () => {
      return initialState;
    },
  },
});

export const { addMedicalLivraisonRecord, addMultipleMedicalLivraisonRecords, updateMedicalLivraisonRecord, deleteMedicalLivraisonRecord, clearMedicalLivraisonRecords } = currentMedicalLivraisonArray.actions;
export default currentMedicalLivraisonArray.reducer;
