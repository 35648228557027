import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelCategoryCode from "../Models/IdataModelCategoryCode";

const getAll = (params:any) => {
  return http.get<Array<IdataModelCategoryCode>>("/CategoriesInsuranceCode", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<IdataModelCategoryCode>>("/sortedCategoriesInsuranceCode");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelCategoryCode>>("/CategoriesInsuranceCodeAccountCategory");
}

const create = (socialinsuranceId:any,data: IdataModelCategoryCode) => {
  return http.post<IdataModelCategoryCode>(`/socialinsurance/${socialinsuranceId}/CategoriesInsuranceCode`, data);
};

const update = (id: any, data: IdataModelCategoryCode) => {
  return http.put<any>(`/CategoriesInsuranceCode/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/CategoriesInsuranceCode/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/CategoriesInsuranceCode`);
};

const findByCategoryType = (categoryName: string) => {

  return http.get<Array<IdataModelCategoryCode>>(`/CategoriesInsuranceCode?categoryName=${categoryName}`);
};

const CategoryTypeService = {
  getAllWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByCategoryType,
};

export default CategoryTypeService;
