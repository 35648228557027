// currentBillSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FactureTotalInOneDto from '../../../Components/Espace_Patients/FactureTotalinOne/model/Dto/FactureTotalInOneDto';



const initialState:FactureTotalInOneDto= {
    
    // facture servie f delete
    /// hospital h delete also delete @Param("hospitalName") hospitalName,  also AND h.hospitalName=:hospitalName also AND h.hospitalName=:hospitalName
    /// docteurName to delete
    /// typeDoctor to delete
    /// couvertureConfig  se
    //NewVisitePatient n

    idNewVisite:null,
    receiptBill:null,
    patientName:null,
    typeAssuree:null,
    patientCode:null,
    CodeSoins:null,
    newVisitecreatedBy:null,
    dateCreatedVisite:null,
    timeCreated:null,

    //social Insuarance so
    idinsurance:null,
    insuranceName:null,
    /// Enterprise   e
    idEnterprise:null,
    accountEnterprise:null,
    enterpriseName:null,
    couvertureDu:null,
    couvertureAau:null,
    modePayment:null,
    frequancePayment:null,

    /// pmember   p
    idpmember:null,
    codeAssuree:null,
    nomPrenomAffilie:null,
    cotisationExact:null,
    accountPmember:null,
    CatégorieSoins:null,

    ///// typeBons Visite  tvi to delete
    ///// typeBon declaration verifivcation rembourcement b to delete
    /// typeBon Commend  tc to delete
    /// depertment dp  to delete

    ///////////////////////
    // track visite k

    /// FactureTotalInOne q
    idFactureInOne:null,
    totalFactureInOne:null,
    totalFactureInOneCouvert:null,
    totalFactureInOneNoCouvert:null,
    totalInOnePartPatient:null,
    totalInOnePartSolis:null,
    totalInOnePartPatientFacture:null,
    totalInOnePartSolisFacture:null,
    inOnecreatedBy:null,
    inOnedateCreated:null,
    inOnetimeCreated:null,
    mois:null,

            // hospital h
    idhospital:null,
    hospitalName:null,
    referenceId:null,
    typehospital:null,
    hospitalAccount:null,
     /// couvertureConfig  se I deleted this

// services ser
 idService:null,
 serviceName:null,  //replace service
 
     /// depertment dp
     idDepertment:null,
      depertmentName:null,
}

const currentBillSlice = createSlice({
  name: 'currentBill',
  initialState,
  reducers: {
    updatecurrentBill: (state, action: PayloadAction<FactureTotalInOneDto>) => {
        
      Object.assign(state, action.payload);
    },
    clearcurrentBill: (state) => {
      return initialState;
    },
  },
});

export const { updatecurrentBill, clearcurrentBill } = currentBillSlice.actions;
export default currentBillSlice.reducer;
