
import { useCallback, useEffect, useState } from "react";
import {Form, Row } from "react-bootstrap";
import IdataModelService from "../../../ServicesData/Models/IdataModelService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import ServicePharmacy from "../../../ServicesDataPharma/Services/ServicePharmacy";
import CouvertureconfigServicePharmacy from "../../../ASSURANCES/Configurations/CouverturestypeMedicament/Services/CouvertureconfigServicePharmacy";
import { updatecurrentCouverturepharma } from "../../../../Redux/slices/currentCouverturepharma/currentCouverturepharma";
import { updateConfigAddFactureSaisiePlafond } from "../../../../Redux/slices/ConfigAddFactureSaisie/ConfigAddFactureSaisiePlafond";


  const defaultservice: Array<IdataModelService> = [];
 
export const AddFactureSaisieConfigPharmacy:React.FC=()=>{

     const [service, setservice] = useState(defaultservice);
     const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);
     const DispachCurrentCOuverturePharmacy=useDispatch()

  const dispatchPlafond = useDispatch();
     const [serviceId, setserviceId] = useState(0);
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      

    const handleChangeConfig = (e: { target: { name: any; value: any; }; }) => {
        setserviceId(e.target.value)
        dispatchPlafond(updateConfigAddFactureSaisiePlafond(e.target.value))
      }
      const getServices = useCallback(() => {
        ServicePharmacy.getAllTypeWithoutPagination()
          .then((response: any) => {
            setservice(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }, [setservice]);

      
    useEffect(() => {
        getServices();
    },[getServices]);
     
  const ConfigServiceInterprise = useCallback(() => {
    if (
      isgetselecterCurrantNewVisitePushed.idinsurance !== 0 &&
      isgetselecterCurrantNewVisitePushed.idEnterprise !== 0 &&
      serviceId !== 0 &&
      isgetselecterCurrantNewVisitePushed.category_code !== null
    ) {
      CouvertureconfigServicePharmacy.findByInsuranceIdAndEnterpriseIdAndSecteurAndidCouvertureConfig(
        isgetselecterCurrantNewVisitePushed.idinsurance,
        isgetselecterCurrantNewVisitePushed.idEnterprise,
        isgetselecterCurrantNewVisitePushed.typehospital,
        serviceId,
        isgetselecterCurrantNewVisitePushed.category_code
      )
        .then((response: any) => {
           console.log('CouvertureconfigService data pharmacy',response)
           DispachCurrentCOuverturePharmacy(updatecurrentCouverturepharma(response.data))
        })
        .catch((e: Error) => {
          console.log('CouvertureconfigService ERROR',e);
        });
    } else {
      console.log('haliii ganiii');
    }
  }, [isgetselecterCurrantNewVisitePushed.idinsurance, isgetselecterCurrantNewVisitePushed.idEnterprise, isgetselecterCurrantNewVisitePushed.category_code, isgetselecterCurrantNewVisitePushed.typehospital, serviceId, DispachCurrentCOuverturePharmacy]);

  useEffect(() => {
    ConfigServiceInterprise();
},[ConfigServiceInterprise]);
 

    return (
      <>
        <form className="form-edit" onSubmit={onFormSubmit}>
          <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-6">
              <Form.Label>Type Medicament A servir</Form.Label>
              <Form.Select className="form-control" size="sm" onChange={handleChangeConfig}>
                <option value="0">Choose..</option>
                {service &&
                  service.map((service) => (
                    <option key={service.id} value={service.id}>{service.typeName}</option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Row>
        </form>
      </>
    );
    
}