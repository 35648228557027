import http from "../../../../AxiosRequest/httpcommon";
import IPlafondYearTypeVisite from "../Models/PlafondYearTypeVisite";
import IPlafondYearTypeVisiteDto from "../Models/PlafondYearTypeVisiteDto";


const getAll = (params:any) => {
  return http.get<Array<IPlafondYearTypeVisite>>("/PlafondTypeVisiteDtos", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IPlafondYearTypeVisite>>("/sortedPlafondTypeVisite");

};
const create = (socialinsuranceId:any,enterprisesAssurance_id:any,categoriesInsurance_id:any,typeBons_id:any,data: IPlafondYearTypeVisite) => {
  return http.post<IPlafondYearTypeVisite>
(`/socialinsurance/${socialinsuranceId}/${enterprisesAssurance_id}/${categoriesInsurance_id}/${typeBons_id}/PlafondTypeVisite`, data);
};

const update = (id: any, data: IPlafondYearTypeVisite) => {
  return http.put<any>(`/PlafondTypeVisite/${id}`, data);
};

const findByMultipleIdsPlafondYear=(socialinsuranceId: number, 
                                    typeBonsId: number,
                                    enterprisesAssuranceId: number, 
                                    categoriesInsuranceId: number,
                                    enfant:any,
                                  etatCivil:any) => {
  return http.get<Array<IPlafondYearTypeVisite>>(`/findByMultipleIds?socialinsuranceId=${socialinsuranceId}&typeBonsId=${typeBonsId}&enterprisesAssuranceId=${enterprisesAssuranceId}
  &categoriesInsuranceId=${categoriesInsuranceId}&enfant=${enfant}&etatCivil=${etatCivil}`);
};

const remove = (id: any) => {
  return http.delete<any>(`/PlafondTypeVisite/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/PlafondTypeVisite`);
};

const findByEnterpriseName = (insuranceId:any,enterpriseName: string) => {
  return http.get<Array<IPlafondYearTypeVisiteDto>>(`/PlafondTypeVisiteDtos?insuranceId=${insuranceId}&enterpriseName=${enterpriseName}`);
};

const findAllByInsuranceByEnterpriseBytypeBon=(insuranceId:any,
                                               enterpriseId:any,
                                               categoryName:any,
                                               enfant:any,
                                               etatCivil:any) => {
               
  
  return http.get<Array<IPlafondYearTypeVisiteDto>>(`/findAllByInsuranceByEnterpriseBytypeBon?insuranceId=${insuranceId}&enterpriseId=${enterpriseId}&categoryName=${categoryName}&enfant=${enfant}&etatCivil=${etatCivil}`
  );
};

const findAllByInsuarenceEnterpCategEnfEtat=(
  insuranceId:any,
  enterpriseId:any,
  categoryName:any,
  enfant:any,
  etatCivil:any) => {


return http.get<Array<IPlafondYearTypeVisiteDto>>(`/findAllByInsuarenceEnterpCategEnfEtat?insuranceId=${insuranceId}&enterpriseId=${enterpriseId}&categoryName=${categoryName}&enfant=${enfant}&etatCivil=${etatCivil}`
);
};
const findAllByInsuarenceEnterpCategEnfEtatTwo=(
  insuranceId:any,
  enterpriseId:any,
  categoryName:any,
  enfant:any,
  etatCivil:any,
  typeBonId:any) => {


return http.get<Array<IPlafondYearTypeVisiteDto>>(`/findAllByInsuarenceEnterpCategEnfEtatTwo?insuranceId=${insuranceId}&enterpriseId=${enterpriseId}&categoryName=${categoryName}&enfant=${enfant}&etatCivil=${etatCivil}&typeBons_id=${typeBonId}`
);
};

const PlafondYearTypeVisiteService = {
  findAllByInsuranceByEnterpriseBytypeBon,
  findAllByInsuarenceEnterpCategEnfEtat,
  findAllByInsuarenceEnterpCategEnfEtatTwo,
  getAllTypeWithoutPagination,
  findByEnterpriseName,
  findByMultipleIdsPlafondYear,
  create,
  update,
  remove,
  getAll,
  removeAll,
};

export default PlafondYearTypeVisiteService;