import { Container, Row, Col, Card } from "react-bootstrap";
import { TableListeVisiteGroupDelcarationPharma } from "../../Components/Espace_Patients/FactureSaisie/Tables/TableListeVisite/TableListeVisiteGroupDelcarationPharma";
export const ListeDeLivraison=()=>{

    return (

        <Container fluid>
     
        <Row>
         <Col>
        <br />
          <Card border="secondary" >
            <Card.Body>
              <TableListeVisiteGroupDelcarationPharma/>
              </Card.Body>
          </Card>
          <br />
         </Col>
        </Row>
        
        </Container>
    
    );
}
