import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import React from "react";
import Excel from 'exceljs';
import saveAs from "file-saver";

import { RiSearch2Line } from 'react-icons/ri';
import { IoIosDownload } from 'react-icons/io';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Redux/Store/store";
import { updateCurrentNewFactureSaisie } from "../../Redux/slices/currentNewFactureSaisie/currentNewFactureSaisie";
import IdataModelHospitalType from "../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import NewFactureSaisieDto from "../Espace_Patients/FactureSaisie/models/Dto/NewFactureSaisieDto";
import ServicesNewFactureSaisie from "../Espace_Patients/FactureSaisie/services/ServiceNewFactureSaisie";

    const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];
  
    let MonStructure:string='';
export const NoVerifeCombineeHospital:React.FC=()=>{
      
  const history = useHistory();
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
const DispatchPushCarrantisNewFactureSaisieDto=useDispatch();
const [isHospitalDropDown,setIsHospitalDropDown]=useState(true);
const [isNewFactureSaisieDto, setisNewFactureSaisieDto] = useState<Array<NewFactureSaisieDto>>([]);

const [isNewFactureSaisieDtoBetween, setisNewFactureSaisieDtoBetween] = useState<Array<NewFactureSaisieDto>>([]);
const [currentIndex, setCurrentIndex] = useState<number>(-1);

const [searchReceiptBill, setsearchReceiptBill] = useState<string>("");

const [HospitalName, setHospitalName] = useState('');

const [modalShow, setModalShow] = useState(false);
const [hospital, setHospital] = useState(defaultinitiaHospitalType);
const [groupedData, setGroupedData] = useState<Map<string, NewFactureSaisieDto[]>>(new Map());

const [groupedDataBETWEEN, setGroupedDataBETWEEN] = useState<Map<string, NewFactureSaisieDto[]>>(new Map());
  
 const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];

const getRequestParams = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};
const getRequestParamsByStructure = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     hospitalName:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    hospitalName:hospitalName,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }
  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};

const getRequestParamsByStructureBetweenDate = 
    (
      idinsurance:number,
      hospitalName:string,
      startDate: string, 
      endDate: string) => {

  let params = {
    idinsurance:idinsurance,
    hospitalName:hospitalName,
    startDate:startDate,
    endDate:endDate,
  };

  if (idinsurance) {
    params["idinsurance"] = idinsurance;
  }

  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }
  if (startDate) {
    params["startDate"] = startDate;
  }

  if (endDate) {
    params["endDate"] = endDate;
  }


  return params;
};

    const  handleChangeTypeHospital = (e: ChangeEvent<HTMLSelectElement>) => {
      setHospitalName(e.target.value);
      console.log('HospitalName',HospitalName)
    };

    const getHospitalWithoutPagination = () => {  
      HospitalTypeService.getAllHospitalNameWithoutPagination()
        .then((response: any) => {
          setHospital(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

const findAllFactureDeclareBetween = () => {

  if(HospitalName===''){

    let concatenatedString: string = StructuresData.join(", ");

    const params = getRequestParamsByStructureBetweenDate(52, concatenatedString,startDate, endDate);

    ServicesNewFactureSaisie.getDeclarationBetweenDates(params)
    .then((response: any) => {

      setisNewFactureSaisieDtoBetween(response.data);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  }else{
    const params = getRequestParamsByStructureBetweenDate(52, HospitalName,startDate, endDate);

    ServicesNewFactureSaisie.getDeclarationBetweenDates(params)
    .then((response: any) => {

      setisNewFactureSaisieDtoBetween(response.data);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });

  }
  
};

const findAllFactureSasie = () => {


  let valueSwip=" "
  if(MonStructure.length>0){

    let concatenatedString: string = StructuresData.join(", ");
console.log("AA")
      valueSwip=concatenatedString
  }else{
console.log("bb")
      valueSwip=HospitalName
  }


  if(StructuresData.length>0){

    ServicesNewFactureSaisie.findBySocialinsuranceIdAndPatientCodeByStructure(52,searchReceiptBill,valueSwip)
    .then((response: any) => {
      const {facture} = response.data;
      setisNewFactureSaisieDto(facture);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  }
  
};
const retrieveNewVisites = useCallback(() => {

  let valueSwip=" "
  if(MonStructure.length>0){
console.log("AA")
let concatenatedString: string = StructuresData.join(", ");
  
      valueSwip=concatenatedString
  }else{
console.log("bb")
      valueSwip=HospitalName
  }
  
  if (StructuresData.length >0) {
    const params = getRequestParamsByStructure(52, searchReceiptBill, valueSwip, page, pageSize);

    ServicesNewFactureSaisie.getAllNewFactureSaiseByStructureGrouped(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;
        setisNewFactureSaisieDto(facture);
        setCount(totalPages);
        // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  } else {
    const params = getRequestParams(52, searchReceiptBill, page, pageSize);

    ServicesNewFactureSaisie.getAllNewFactureSaise(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;
        setisNewFactureSaisieDto(facture);
        setCount(totalPages);
        // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }
}, [StructuresData, searchReceiptBill, page, pageSize, setisNewFactureSaisieDto, setCount]);

// Usage in the component
useEffect(() => {
  retrieveNewVisites();
}, [retrieveNewVisites]);

const trigerDropDownState = useCallback(() => {
  setIsHospitalDropDown((prevIsHospitalDropDown) => {
    if (StructuresData.length === 1) {
      let concatenatedString: string = StructuresData.join(", ");
      MonStructure = concatenatedString;
      return true;
    } else {
      return false;
    }
  });
}, [StructuresData]);
useEffect(() => {
  retrieveNewVisites();
  getHospitalWithoutPagination();
  trigerDropDownState();
},[page, pageSize, retrieveNewVisites, trigerDropDownState]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };



const setpPushNewVisite = (dataNouvelleVisite: NewFactureSaisieDto, index: number) => {
   setCurrentIndex(index); 
   DispatchPushCarrantisNewFactureSaisieDto(updateCurrentNewFactureSaisie(dataNouvelleVisite));
   setModalShow(true);

  history.push('/FactureSaisie');
};

const totalReceiptBill = isNewFactureSaisieDto.length;
const totalParTotal = isNewFactureSaisieDto.reduce((acc: any, item: { partTotal: any; }) => acc + item.partTotal, 0);
const totalParPatient = isNewFactureSaisieDto.reduce((acc: any, item: { partpatient: any; }) => acc + item.partpatient, 0);
const totalpartMutuelle = isNewFactureSaisieDto.reduce((acc: any, item: { partMutuelle: any; }) => acc + item.partMutuelle, 0);

const totalReceiptBillBetween = isNewFactureSaisieDtoBetween.length;
const totalParTotalBetween = isNewFactureSaisieDtoBetween.reduce((acc: any, item: { partTotal: any; }) => acc + item.partTotal, 0);
const totalParPatientBetween = isNewFactureSaisieDtoBetween.reduce((acc: any, item: { partpatient: any; }) => acc + item.partpatient, 0);
const totalpartMutuelleBetween = isNewFactureSaisieDtoBetween.reduce((acc: any, item: { partMutuelle: any; }) => acc + item.partMutuelle, 0);

const exportToExcelLogic = () => {

if(startDate==="" && endDate===""){
exportToExcel()

}else{
exportToExcelBETWEEN()
};

};
const exportToExcel = () => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('@ALLY_DECLARATION');
  worksheet.properties.tabColor = { argb: 'FF0000FF' }; // Set tab color to blue
 
  // Add header row
  const headerRow = worksheet.addRow([
    'Time', 'Date Created','Date Saisie', 'Bon', 'Adherent', 'Code', 'Enterprise', 'Beneficieres',
      'Doctor', 'Depertment', 'Prestation', 'Plafond', 'QTE', 'P.U',
      '100%', 'Par solis', 'Par Patient','Structure'
  ]);

  // Grouped data iteration
  groupedData.forEach((groupItems) => {
     

      // Add empty row for visual separation
    //  worksheet.addRow([]);

      // Add rows for individual items in the group
      groupItems.forEach((facture) => {
          worksheet.addRow([

              facture.timeCreated,
              facture.dateCreatedVisite,
              facture.dateCreatedSaisie,
              facture.receiptBill,
              facture.nomPrenomAffilie,
              facture.codeAssuree,
              facture.enterpriseName,
              facture.patientName,
              facture.doctorName,
              facture.depertmentName,
              facture.prestation,
              facture.plafondFacture,
              facture.quantity,
              facture.pricePrestation,
              facture.partTotal,
              facture.partMutuelle,
              facture.partpatient,
              facture.hospitalName
          ]);
      });

      // Add empty row for visual separation between groups
     // worksheet.addRow([]);
      // Calculate and add total row
      const groupSums = calculateGroupSums(groupItems);
      const totalRow =  worksheet.addRow([
          'Facture total Par Bon', '','', '', '', '', '', '', '', '', '', '', 'Total','',
          groupSums.partTotalFactureSum,
          groupSums.partMutuelleFactureSum,
          groupSums.partpatientFactureSum, 
      ]);
       // Apply style to the total row
       totalRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECFCEB' } // Green color
    };
    totalRow.eachCell((cell) => {
      cell.font = { color: { argb: 'FF008000' } }; // Green text color
  });
  });
   // Add empty row for visual separation
      worksheet.addRow([]);

   // Calculate and add total row
   const totalRow =  worksheet.addRow([
       'DECLARATION', '','', totalReceiptBill, '', '', '', '', '', '', '', '', 'Total A declare',
       '',
       totalParTotal,
       totalpartMutuelle,
       totalParPatient, 
   ]);
    // Apply style to the total row
    totalRow.fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: 'FFECFCEB' } // Green color
 };
 totalRow.eachCell((cell) => {
   cell.font = { color: { argb: 'FF008000' } }; // Green text color
});

  // Auto-fit column widths based on content
  worksheet.columns.forEach((column) => {
    if (column && column.eachCell) { // Check if column and column.eachCell are not undefined
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            let columnLength = 0;
            if (cell.value instanceof Date) {
                columnLength = cell.value
                    .toISOString()
                    .slice(0, 10)
                    .replace(/-/g, '.').length;
            } else {
                columnLength = String(cell.value).length;
            }
            if (columnLength > dataMax) {
                dataMax = columnLength;
            }
        });
        column.width = dataMax < 10 ? 15 : dataMax + 8;
    }
});

  // Save the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, '@ALLY_DECLARATION.xlsx');
  });
};

const exportToExcelBETWEEN = () => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('@ALLY_DECLARATION');
  worksheet.properties.tabColor = { argb: 'FF0000FF' }; // Set tab color to blue
 
  // Add header row
  const headerRow = worksheet.addRow([
    'Time', 'Date Created','Date Saisie', 'Bon', 'Adherent', 'Code', 'Enterprise', 'Beneficieres',
      'Doctor', 'Depertment', 'Prestation', 'Plafond', 'QTE', 'P.U',
      '100%', 'Par solis', 'Par Patient','Structure'
  ]);

  // Grouped data iteration
  groupedDataBETWEEN.forEach((groupItems) => {
     

      // Add empty row for visual separation
    //  worksheet.addRow([]);

      // Add rows for individual items in the group
      groupItems.forEach((facture) => {
          worksheet.addRow([

              facture.timeCreated,
              facture.dateCreatedVisite,
              facture.dateCreatedSaisie,
              facture.receiptBill,
              facture.nomPrenomAffilie,
              facture.codeAssuree,
              facture.enterpriseName,
              facture.patientName,
              facture.doctorName,
              facture.depertmentName,
              facture.prestation,
              facture.plafondFacture,
              facture.quantity,
              facture.pricePrestation,
              facture.partTotal,
              facture.partMutuelle,
              facture.partpatient,
              facture.hospitalName
          ]);
      });

      // Add empty row for visual separation between groups
     // worksheet.addRow([]);
      // Calculate and add total row
      const groupSums = calculateGroupSums(groupItems);
      const totalRow =  worksheet.addRow([
          'Facture total Par Bon', '','', '', '', '', '', '', '', '', '', '', 'Total','',
          groupSums.partTotalFactureSum,
          groupSums.partMutuelleFactureSum,
          groupSums.partpatientFactureSum, 
      ]);
       // Apply style to the total row
       totalRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECFCEB' } // Green color
    };
    totalRow.eachCell((cell) => {
      cell.font = { color: { argb: 'FF008000' } }; // Green text color
  });
  });
   // Add empty row for visual separation
      worksheet.addRow([]);

   // Calculate and add total row
   const totalRow =  worksheet.addRow([
       'DECLARATION', '','', totalReceiptBillBetween, '', '', '', '', '', '', '', '', 'Total A declare',
       '',
       totalParTotalBetween,
       totalpartMutuelleBetween,
       totalParPatientBetween, 
   ]);
    // Apply style to the total row
    totalRow.fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: 'FFECFCEB' } // Green color
 };
 totalRow.eachCell((cell) => {
   cell.font = { color: { argb: 'FF008000' } }; // Green text color
});

  // Auto-fit column widths based on content
  worksheet.columns.forEach((column) => {
    if (column && column.eachCell) { // Check if column and column.eachCell are not undefined
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            let columnLength = 0;
            if (cell.value instanceof Date) {
                columnLength = cell.value
                    .toISOString()
                    .slice(0, 10)
                    .replace(/-/g, '.').length;
            } else {
                columnLength = String(cell.value).length;
            }
            if (columnLength > dataMax) {
                dataMax = columnLength;
            }
        });
        column.width = dataMax < 10 ? 15 : dataMax + 8;
    }
});

  // Save the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, '@ALLY_DECLARATION.xlsx');
  });
};




const calculateGroupSums = (groupItems: NewFactureSaisieDto[]): { partMutuelleFactureSum: number, partpatientFactureSum: number, partTotalFactureSum: number } => {
  let partMutuelleFactureSum = 0;
  let partpatientFactureSum = 0;
  let partTotalFactureSum = 0;

  groupItems.forEach((facture) => {
    partMutuelleFactureSum += facture.partMutuelle;
    partpatientFactureSum += facture.partpatient;
    partTotalFactureSum += facture.partTotal;
  });

  return { partMutuelleFactureSum, partpatientFactureSum, partTotalFactureSum };
};
const calculatePlafondSums = (groupItems: any[]) => {
  const plafondGroups = groupItems.reduce((acc, item) => {
    acc[item.plafondFacture] = acc[item.plafondFacture] || { total: 0, partMutuelle: 0, partpatient: 0, plafond: item.plafondFacture };
    acc[item.plafondFacture].total += item.partTotal;
    acc[item.plafondFacture].partMutuelle += item.partMutuelle;
    acc[item.plafondFacture].partpatient += item.partpatient;
    return acc;
  }, {});

  return Object.keys(plafondGroups).map(plafond => ({
    description: `Facture total Par Plafond (Plafond: ${plafond})`,
    total: plafondGroups[plafond].total,
    partMutuelle: plafondGroups[plafond].partMutuelle,
    partpatient: plafondGroups[plafond].partpatient,
    plafond: plafond, // Ensure to include plafond property
  }));
};



useEffect(() => {
    // Update groupedData whenever fetched data changes
    const groupData = () => {
      const grouped = new Map<string, NewFactureSaisieDto[]>();
      isNewFactureSaisieDto.forEach((facture) => {
        const key = `${facture.dateCreatedSaisie} -  ${facture.enterpriseName}  -  ${facture.receiptBill}  -  ${facture.patientName}`;
        const existingGroup = grouped.get(key) || [];
        existingGroup.push(facture);
        grouped.set(key, existingGroup);
      });
      setGroupedData(grouped);
    };

    groupData();
  }, [isNewFactureSaisieDto]);

  
useEffect(() => {
    // Update groupedData whenever fetched data changes
    const groupDataBETWEEN = () => {
      const grouped = new Map<string, NewFactureSaisieDto[]>();
      isNewFactureSaisieDtoBetween.forEach((facture) => {
        const key = `${facture.dateCreatedSaisie} -  ${facture.enterpriseName}  -  ${facture.receiptBill}  -  ${facture.patientName}`;
        const existingGroup = grouped.get(key) || [];
        existingGroup.push(facture);
        grouped.set(key, existingGroup);
      });
      setGroupedDataBETWEEN(grouped);
    };

    groupDataBETWEEN();
  }, [isNewFactureSaisieDtoBetween]);

  return(
        <>
        
        <div>
        <div className="d-flex flex-column align-items-start">
      
        <Stack  direction="horizontal" gap={2}>
        DU
         <Form.Group controlId="formBasicStartDate" className="col col-sm-3">
                <InputGroup>
                    <Form.Control
                        size="sm"
                        type="date"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>
            AU
            <Form.Group controlId="formBasicEndDate" className="col col-sm-3">
                <InputGroup>
                    <Form.Control
                        size="sm"
                        type="date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>
                 <Button variant="outline-dark" size="sm" onClick={findAllFactureDeclareBetween}>
                <RiSearch2Line />
            </Button>

            <Button variant="outline-dark" size="sm" onClick={exportToExcelLogic}>
                <IoIosDownload /> 
            </Button>

        </Stack>
      <Stack  direction="horizontal" gap={2}>
      {"Page:"}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />         
{!isHospitalDropDown?(

<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
  
<Stack  direction="horizontal" gap={2}>

<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">hopital..</option>
  {hospital &&
hospital.map((hospital) => (
    <option key={hospital.id} value={hospital.hospitalName} >{hospital.hospitalName}</option>
))}
    
</Form.Select>
<Button variant="outline-dark" size="sm" onClick={findAllFactureSasie}>
              <RiSearch2Line />
          </Button>

</Stack>     
  </Form.Group>          

         ):(
          
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">{MonStructure}</option>
</Form.Select>
</Form.Group>
         )}
         </Stack>   
         <Stack direction="horizontal" gap={2}>
          
        </Stack>   
</div>
<Table striped bordered hover size="sm" responsive>
  <thead>
    <tr>
      <th>Time</th>
      <th>Date</th>
      <th>bon</th>
      <th>Adherent</th>
      <th>Code</th>
      <th>Enterprise</th>
      <th>Beneficieres</th>
      <th>Doctor</th>
      <th>Depertment</th>
      <th>Prestation</th>
      <th>Plafond</th>
      <th>QTE</th>
      <th>P.U</th>
      <th>100%</th>
      <th>Par solis</th>
      <th>Par Patient</th>
      <th>Structure</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {Array.from(groupedData).map(([groupKey, groupItems], index) => (
      <React.Fragment key={index}>
        {groupItems.map((facture, factureIndex) => (
          <tr key={`${groupKey}-${factureIndex}`}>
            <td>{facture.timeCreated}</td>
            <td>{facture.dateCreatedSaisie}</td>
            <td>{facture.receiptBill}</td>
            <td>{facture.nomPrenomAffilie}</td>
            <td>{facture.codeAssuree}</td>
            <td>{facture.enterpriseName}</td>
            <td>{facture.patientName}</td>
            <td>{facture.doctorName}</td>
            <td>{facture.depertmentName}</td>
            <td>{facture.prestation}</td>
            <td>{facture.plafondFacture}</td>
            <td>{facture.quantity}</td>
            <td>{facture.pricePrestation}</td>
            <td>{facture.partTotal}</td>
            <td>{facture.partMutuelle}</td>
            <td>{facture.partpatient}</td>
            <td>{facture.hospitalName}</td>
            <td>
            <Stack direction="horizontal" gap={0}>
                <Button
                  className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setpPushNewVisite(facture, factureIndex)}
                >
                  Voir
                </Button>
              </Stack>
            </td>
          </tr>
        ))}
        {calculatePlafondSums(groupItems).map((plafondItem, plafondIndex) => (
          <tr key={`plafond-${plafondIndex}`}>
            <td colSpan={9}>Facture total Par Plafond (Plafond: {plafondItem.plafond})</td>
            <td colSpan={1}></td>
            <td colSpan={1}></td>
            <td colSpan={1}></td>
            <td colSpan={1} style={{ color: 'green' }}>{plafondItem.total}</td>
            <td colSpan={1} style={{ color: 'green' }}>{plafondItem.partMutuelle}</td>
            <td colSpan={1} style={{ color: 'green' }}>{plafondItem.partpatient}</td>
            <td colSpan={1}></td>
          </tr>
        ))}
        <tr>
          <td colSpan={9}>Facture total Par Bon</td>
          <td colSpan={1}></td>
          <td colSpan={1}></td>
          <td colSpan={1}></td>
          <td colSpan={1} style={{ color: 'green' }}>{calculateGroupSums(groupItems).partTotalFactureSum}</td>
          <td colSpan={1} style={{ color: 'green' }}>{calculateGroupSums(groupItems).partMutuelleFactureSum}</td>
          <td colSpan={1} style={{ color: 'green' }}>{calculateGroupSums(groupItems).partpatientFactureSum}</td>
          <td colSpan={1}></td>
        </tr>
      </React.Fragment>
    ))}
  </tbody>
</Table>



          </div>

     
        </>
    );
}