import {  useEffect, useState } from "react";
import DetailsSoinsTypeService from "../Services/DetailsSoinsTypeService";
import { Row, Form, InputGroup } from "react-bootstrap";
import IdataModelDetailsType from "../Models/IdataModelDetailsType";
import IdataModeDetailsTypeDtos from '../Models/IdataModeDetailsTypeDtos';
import IdataModelTypeCategory from "../../TypeCategory/Models/IdataModelTypeCategory";
import ServiceTypeCategory from "../../TypeCategory/Services/ServiceTypeCategory";

interface EditDetailsTypeList {
    show: boolean; 
    onHide: () => void; 

    EditInfoDetailsType: IdataModeDetailsTypeDtos;
  }

  const defaultinitiaTypeCategory: Array<IdataModelTypeCategory> = [];

  let TypCatgryId=0

  export const DetailsTypeEdit:React.FC<EditDetailsTypeList>=props=> {

    const [TypCatgry, setTypCatgry] = useState(defaultinitiaTypeCategory);

    const initialTypeState = {
      id: null,
      code: "",
      intitule: "",
      excl: "",
      acc: "",
      dateCreatedBy: "",
    };
    

    const [isInputDtos,setInputDtos]=useState<IdataModeDetailsTypeDtos>
    (props.EditInfoDetailsType);
    const [isInput,setInput]=useState<IdataModelDetailsType>(initialTypeState);


    
const handleChange = (e: { target: { name: any; value: any; }; }) => {
  setInputDtos({ ...isInputDtos, [e.target.name]: e.target.value });
}
   
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
          const EditDetails = (e: { preventDefault: () => void; }) => {
            e.preventDefault();
            let data: any = {
              code: isInputDtos.code,
              intitule: isInputDtos.intitule,
              excl: isInputDtos.excl,
              acc: isInputDtos.acc,
              dateCreatedBy: isInputDtos.dateCreatedBy
            };
          
            ServiceTypeCategory.update(isInputDtos.idSoinsDetails, data)
                .then((response: any) => {
                  setInput({
                    id: response.data.id,
                    code: response.data.code,
                    intitule: response.data.intitule,
                    excl: response.data.excl,
                    acc: response.data.acc,
                    dateCreatedBy: response.data.dateCreatedBy
                  });
                  console.log(response.data);
                  console.log(data);
                  EditCancel();
                })
                .catch((e: Error) => {
                  console.log(e);
                });
              console.log(isInput);
            
          };
          
          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
          const onHandlerchangeTypCatgry = (e: { target: {value: any; }; }) => {
            TypCatgryId=e.target.value
             console.log('TypCatgryId  ',TypCatgryId);
              }
              const typeSoinsNameWithoutPagination = () => {
  
                ServiceTypeCategory.getAlltypeSoinsNameWithoutPagination()
                  .then((response: any) => {
                    setTypCatgry(response.data);
                   // console.log(speciality);
                  })
                  .catch((e: Error) => {
                    console.log(e);
                  });
              };
       
          
  useEffect(() => {
    typeSoinsNameWithoutPagination();
    setInputDtos(props.EditInfoDetailsType)

      },[props.EditInfoDetailsType])
      
          return(
            <>
        < form  onSubmit={onFormSubmit}>
        <Row className="mb-3">
          <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Intitulé</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="intitule"
                value={isInputDtos.intitule}
                onChange={handleChange}
              />
            </InputGroup>
          </Form.Group>
          
          <Form.Group controlId="formGridExcl" className="col col-sm-3">
            <Form.Label>Excl</Form.Label>
            <Form.Select
              name="excl"
              value={isInputDtos.excl}
              onChange={handleChange}
            >
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </Form.Select>
          </Form.Group>

          
        <Row className="mb-3">
          <Form.Group controlId="formGridAcc" className="col col-sm-3">
            <Form.Label>Acc</Form.Label>
            <Form.Select
              name="acc"
              value={isInputDtos.acc}
              onChange={handleChange}
            >
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </Form.Select>
          </Form.Group>
        </Row>
  
        </Row>
            <Row className="mb-3">         
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
          <Form.Label>Categories Autorise {isInputDtos.intitule}</Form.Label>
    <Form.Select className="form-control" 
        onChange={onHandlerchangeTypCatgry}>
        <option value="0">Choose..</option>
      {TypCatgry &&
         TypCatgry.map((TypCatgry) => (
            <option key={TypCatgry.id} value={TypCatgry.id} >
              {TypCatgry.intitule}</option>
))}
</Form.Select>
</Form.Group>
            </Row>

            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditDetails} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
