// userSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Iuser from './Iuser';



const initialState: Iuser= {
  sid: null,
  email: null,
  family_name: null,
  given_name: null,
  preferred_username: null,
  hemaClinicGroups:[] // Add this line
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<Iuser>) => {
      Object.assign(state, action.payload);
    },
    clearUser: (state) => {
      return initialState;
    },
  },
});

export const { updateUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
