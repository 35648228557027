import { ChangeEvent, useEffect, useState } from "react";
import CategoryTypeService from "../Services/CategoryTypeServices";
import { Row, Form, InputGroup } from "react-bootstrap";
import IdataModelCategoryCode from "../Models/IdataModelCategoryCode";

interface EditCategoryTypeList {
    show: boolean; 
    onHide: () => void; 
    EditInfoCategoryType: IdataModelCategoryCode;
  }

  export const CategoriesCodeEdit :React.FC<EditCategoryTypeList>=props=> {

    const initialTypeState = {
      id: null,
      categoryName: '',
      account:0,
      createdBy: '',
      dateCreatedBy: new Date()
    };

    const [isInput,setInput]=useState<IdataModelCategoryCode>(props.EditInfoCategoryType);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInput({ ...isInput, [name]: value });
       // console.log(event.target)
        }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditCategory = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              categoryName: isInput.categoryName,
              createdBy:isInput.createdBy,
              dateCreatedBy: isInput.dateCreatedBy
            };
    
            CategoryTypeService.update(isInput.id,data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                categoryName: response.data.categoryName,
                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);
              EditCancel()
            })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
            
  useEffect(() => {
    
    setInput(props.EditInfoCategoryType)
    
      },[props.EditInfoCategoryType])
      
          return(
            <>
        < form  onSubmit={onFormSubmit}>
            <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-6">
               <Form.Label>Edit Category Code</Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="categoryName" name="categoryName" value={ isInput.categoryName} onChange={handleChange} />
               </InputGroup>
           </Form.Group>
            </Row>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditCategory} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
