
import { useCallback, useEffect, useState } from "react";
import NewFactureSaisieDto from "../models/Dto/NewFactureSaisieDto";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import ServicesNewFactureSaisie from "../services/ServiceNewFactureSaisie";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import currentNewFactureSaisieSlice from '../../../../Redux/slices/currentNewFactureSaisie/currentNewFactureSaisie';
import Button from "@mui/material/Button";


 const NewFactureSaisieDtoInt ={
    
  /// facture fa
  idFacture:'',
  prestation:'', //itule
  prestationPrescriptPar:'',
  pricePrestation:'',
  plafondFacture:'',
  ticketService:'',
  quantity:'',
  partMutuelle:'',
  partpatient:'',
  partTotal:'',
  statusFacturePayer:'',
  createdBy:'',
  dateCreatedSaisie:'',
  timeSaisie:'',


  partMutuelleFacture:0,
  partpatientFacture:0,
  partTotalFacture:0,
  
  ticketServiceFacture:0,

  statusFactureDeclare:'',
  statusFactureVerifier:'',
  statusFactureRembourceAvecAutreFacture:'',
  statusFactureRembourceUneFacture:'',

 /// hospital h
  idhospital:'',
  hospitalName:'',
  referenceId:'',
  typehospital:'',
  hospitalAccount:'',

 /// docteur
  idDoctorName:'',
  prescriptPar:'',
  doctorName:'',
   priceConsultation:'',
  accountNameDoctor:'',

 /// typeDoctor
 idtypeDoctor:'',
 typedoctorName:'',
 accountTypeDoctor:'',
 /// couvertureConfig  se
 idCouvertureConfig:'',
  service:'',
  ticket:'',
  particulie:'',
  type_category:'',
  access:'',
  secter:'',
  plafondOrdonence:'',
/// new visite
  idNewVisite:'',
  receiptBill:'',
  patientName:'',
  typeAssuree:'',
 patientCode:'',
 newVisitecreatedBy:'',
  dateCreatedVisite:'',
  timeCreated:'',

 //social Insuarance so
  idinsurance:'',
  insuranceName:'',
 //////////////////////////////////////////
 /// Enterprise   e
  idEnterprise:'',
  accountEnterprise:'',
  enterpriseName:'',
  couvertureDu:'',
  couvertureAau:'',
  modePayment:'',
  frequancePayment:'',
 /// pmember   p
  idpmember:'',
  codeAssuree:'',
  nomPrenomAffilie:'',
  cotisationExact:'',
  accountPmember:'',
  catégorieSoins:'',
  category_code:'',
 ///// typeBons Visite  tvi
  idVisite:'',
  typeNameVisite:'',
  accountVisite:'',

 ///// typeBon declaration verifivcation rembourcement b
  idtypeBonverification:'',
  typeBonVerificationName:'',
  accountVerificationName:'',
 /// typeBon Commend  tc
  idTypeBonCommend:'',
  typeBonCommendName:'',
  accountBonCommend:'',
 /// depertment dp
  idDepertment:'',
  depertmentName:'',

}


  const defaultList: Array<NewFactureSaisieDto> = [];
  
const columns: GridColDef[] = [

    { field: 'id', headerName: 'ID'  },
    { field: 'prestation', headerName: 'Prestation'  },
    { field: 'pricePrestation', headerName: 'Price '  },

    { field: 'ticketService', headerName: 'Ticket' },
    { field: 'quantity', headerName: 'Quantite' },

    { field: 'partMutuelle', headerName: 'Part Mutuelle' },
    { field: 'partpatient', headerName: 'Part Patient' },

    { field: 'partTotal', headerName: 'Total'   },

    { field: 'ticketServiceFacture', headerName: 'F Ticket ' },
    { field: 'partMutuelleFacture', headerName: 'F Part Mutuelle' },
    { field: 'partpatientFacture', headerName: 'F Part Patient' },
    { field: 'partTotalFacture', headerName: 'F Total'   },
    
    { field: 'statusFacturePayer', headerName: 'Status' },
    { field: 'typeNameVisite', headerName: 'Type Viste' },
    { field: 'typeBonVerificationName', headerName: 'Verfier' },
    { field: 'typeBonCommendName', headerName: 'Type Bon' },
    { field: 'depertmentName', headerName: 'Depertement' },
    { field: 'dateCreatedSaisie', headerName: 'Created' },
    { field: 'timeSaisie', headerName: 'Time Saisie'  },
    
  ];
  const idd: GridRowId[]=[];
export const TableFactureComplete:React.FC=()=>

{

    const [isNewFactureSaisieDto, setisNewFactureSaisieDto] = useState<Array<NewFactureSaisieDto>>([]);
const isgetCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);

const [selectedRows, setRowsSelected] = useState(defaultList);
const [selectedIdInRow, setIdRowSelected] = useState(idd);

const findAllFactureByInsuarenceAndReceipt = () => {

  try {
    ServicesNewFactureSaisie.findByReceiptBillAndSocialInsuarance(
      isgetCurrantNewVisitePushed.idinsurance,
      isgetCurrantNewVisitePushed.receiptBill
    ).then((response: any) => {
      const facture  = response.data;
      
    setisNewFactureSaisieDto(facture)
    })
    
  } catch (e) {
    console.log(e);
  }
};

      return (
        <>

      <Button onClick={() => {findAllFactureByInsuarenceAndReceipt()}} variant="contained" color="warning">
         Actualise
       </Button>
       
       <div style={{ height: 400, width: '100%' }}>
       <DataGrid
            rows={isNewFactureSaisieDto}
            getRowId={(row) => row.idFacture}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel:{page:0,pageSize:10},
              },
            }}
            pageSizeOptions={[10,15,25,35,45]}
            checkboxSelection
            
            onRowSelectionModelChange={(ids) => {

              const selectedIDs = new Set(ids);
              const selectedRow = isNewFactureSaisieDto.filter((row) => selectedIDs.has(row.idFacture),);
             
              setIdRowSelected(ids)
            setRowsSelected(selectedRow)
            }
          }
           
          />
     </div>
        
        </>
      );
    }