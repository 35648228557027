import { useState, useEffect } from 'react';
import { Row, Form, InputGroup } from "react-bootstrap";
import IdataModelCouverture from '../Models/IdataModelCouverture';

import IdataModelCategoryCode from '../../../Categories/CategoriiesCode/Models/IdataModelCategoryCode';
import IdataModelTypeStatus from '../../../../Type/TypeStatus/Models/IdataModelTypeStatus';
import CategoryTypeService from '../../../Categories/CategoriiesCode/Services/CategoryTypeServices';
import IdataModelCouvertureDtos from '../Models/IdataModelCouvertureDtos';
import IdataModelService from '../../../../ServicesData/Models/IdataModelService';
import ServiceHopital from '../../../../ServicesData/Services/ServiceHopital';
import TypeService from '../../../../Type/TypeStructure/Services/TypeServices';
import CouvertureconfigServicePharmacy from '../Services/CouvertureconfigServicePharmacy';
import ServicePharmacy from '../../../../ServicesDataPharma/Services/ServicePharmacy';


const defaultservice: Array<IdataModelService> = [];
const defaultcode:Array<IdataModelCategoryCode>=[];
const defaultStatus:Array<IdataModelTypeStatus>=[];

  interface IEditConfigList {
    show: boolean; 
    onHide: () => void; 
    EditInfo: IdataModelCouvertureDtos;
  }
  let selectedService:any;
  let selectedCode:any;
  let selectedEnterprise:any;
  export const EditCouverture :React.FC<IEditConfigList>=props=> {

    const [service, setservice] = useState(defaultservice);
    const [code, setcode] = useState(defaultcode);
    const [status, setStatus] = useState(defaultStatus);
  
const initialTypeState = {
service:'',
 ticket:'',
 ticketFacture:'',
 particulie:'',
 type_category:'',
 access:'',
 secter:'',
 plafondOrdonence:'',
 createdBy:'',
dateCreated: ''
};

    const [isInputDtos,setInputDtos]=useState<IdataModelCouvertureDtos>(props.EditInfo);
    const [isInput,setInput]=useState<IdataModelCouverture>(initialTypeState);


    const getAllWithoutPaginationCode = () => {
  
      CategoryTypeService.getAllWithoutPagination()
        .then((response: any) => {
          setcode(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const getAllWithoutPagination = () => {
  
      ServicePharmacy.getAllTypeWithoutPagination()
        .then((response: any) => {
          setservice(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const getAllTypeStatusWithoutPagination = () => {
  
      TypeService.getAllTypeWithoutPagination()
        .then((response: any) => {
          setStatus(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }; 
    
    useEffect(() => {
      getAllTypeStatusWithoutPagination();
      getAllWithoutPaginationCode();
      getAllWithoutPagination();
    });
    const handleChange = (e: { target: { name: any; value: any; }; }) => {
      setInputDtos({ ...isInputDtos, [e.target.name]: e.target.value });
    }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
         
   const EditConfig = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              ticket: isInputDtos.ticket ,
              ticketFacture:isInputDtos.ticketFacture,
              particulie: isInputDtos.particulie ,
              access: isInputDtos.access ,
              secter: isInputDtos.secter ,
              plafondOrdonence:isInputDtos.plafondOrdonence,
              createdBy: isInputDtos.createdBy ,
             dateCreated: isInputDtos.dateCreated 
            };
    
            CouvertureconfigServicePharmacy.update(isInputDtos.idCouvertureConfig,data)
            .then((response: any) => {
              setInput({
                ticket:response.data.ticket ,
                
            ticketFacture: response.data.ticketFacture,
                particulie:response.data.particulie ,
                access:response.data.access ,
                secter:response.data.secter ,
                plafondOrdonence:response.data.plafondOrdonence,
                createdBy:response.data.createdBy ,
               dateCreated:response.data.dateCreated 
              });
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInputDtos);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
          
  useEffect(() => {

    setInputDtos(props.EditInfo)

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
        <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
         <Form.Label>Services</Form.Label>
    <Form.Select className="form-control" size="sm" name="service" value={isInputDtos.serviceName}
     onChange={handleChange}>
       <option value="0">Choose..</option>
      {service &&
    service.map((service) => (
        <option key={service.id} value={service.typeName} >{service.typeName}</option>
    ))}
        
    </Form.Select>
        </Form.Group>
          <Form.Group controlId="formBasicEmail" className="col col-sm-3">
              <Form.Label>Ticket sur Declaration</Form.Label>
              <InputGroup>
                  <Form.Control size="sm"  aria-describedby="basic-addon2" type="number" name="ticket" value={isInputDtos.ticket} onChange={handleChange} />
              </InputGroup>
          </Form.Group>
          <Form.Group controlId="formBasicEmail" className="col col-sm-3">
              <Form.Label>ticket sut Facture</Form.Label>
              <InputGroup>
                  <Form.Control size="sm"  aria-describedby="basic-addon2" type="number" name="ticketFacture" value={isInputDtos.ticketFacture} onChange={handleChange} />
              </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>particulie</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="particulie" value={isInputDtos.particulie}  onChange={handleChange}>
                <option>Choisir </option>
               <option value="OUI">OUI</option>
             <option value="No">No</option>
    </Form.Select>
        </Form.Group>

        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Code Category</Form.Label>
    <Form.Select className="form-control"  size="sm" name="type_category" value={isInputDtos.categoryCode} 
     onChange={handleChange}>
       <option value="0">Choose..</option>
      {code &&
    code.map((code) => (
        <option key={code.id} value={code.categoryName} >{code.categoryName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
   <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Access</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="access"  value={isInputDtos.access}  onChange={handleChange}>
                <option>Choisir </option>
               <option value="OUI">OUI</option>
             <option value="No">No</option>
    </Form.Select>
        </Form.Group>
    
        <Form.Group controlId="formGridCheckbox" className="col col-sm-3">
            <Form.Label>Secteur</Form.Label>
            <Form.Select className="form-control" size="sm" name="secter" value={isInputDtos.secter}
             onChange={handleChange}>
                     <option value="0">Choose..</option>
                {status &&
    status.map((status) => (
        <option key={status.id} value={status.typeName} >{status.typeName}</option>
    ))}
            </Form.Select>
        </Form.Group>
        
   <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>plafond/Facture</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="number" name="plafondOrdonence" value={isInputDtos.plafondOrdonence} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        </Row>
  
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditConfig} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
