
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewFactureSaisieDto from '../../../Components/Espace_Patients/FactureSaisie/models/Dto/NewFactureSaisieDto';


const newFactureSansMutuelleSlice = createSlice({
  name: 'newFactureSansMutuelle',
  initialState: [] as NewFactureSaisieDto[], // Set initial state as an empty array
  reducers: {
    updateFactureSansMutuelle: (state, action: PayloadAction<NewFactureSaisieDto[]>) => {
      // Assuming you want to replace the entire array in the state
      return action.payload;
    },
    clearFactureSansMutuelle: () => {
      return [];
    },
  },
});

export const { updateFactureSansMutuelle, clearFactureSansMutuelle } = newFactureSansMutuelleSlice.actions;
export default newFactureSansMutuelleSlice.reducer;
