/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import {Divider, Stack } from "@mui/material";

import Button from 'react-bootstrap/Button';
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/store";
import { clearMedicalLivraisonRecordsModifer, addMultipleMedicalLivraisonRecordsModifer } from "../../Redux/slices/currentMedical/currentMedicalLivraisonArrayModifie";
import ServiceNewFactureLivraisonMedicament from "../Espace_Patients/FactureMedicament/services/ServiceNewFactureLivraisonMedicament";
import NewFactureLivraisonMedicamentDto from "../Espace_Patients/FactureMedicament/models/Dto/NewFactureLivraisonMedicamentDto";
import { RiSearch2Line } from "react-icons/ri";
import IdataModelPharmacy from "../ASSURANCES/StructurePartenaires/Pharmacies/Models/IdataModelPharmacy";
import PharmacyTypeService from "../ASSURANCES/StructurePartenaires/Pharmacies/Services/PharmacyTypeService";


  
const columns: GridColDef[] = [

    { field: 'plafondFacture', headerName: 'plafond' },

    { field: 'statusFacturePayer', headerName: 'Status S' },
    { field: 'statusFacturePayerPharmacy', headerName: 'Status pha' },

    { field: 'statusFactureVerifierPharmacy', headerName: 'Verifier' },
    { field: 'prestation', headerName: 'Description de medicament pour le patientx', width: 400 },
    { field: 'pricePrestation', headerName: 'Price ' },

    { field: 'quantity', headerName: 'Quts' },

  //  { field: 'ticketService', headerName: 'D Ticket' },
    { field: 'ticketServiceFacture', headerName: 'Ticket' },
    { field: 'partMutuelle', headerName: 'Part Mutuelle' },
    { field: 'partpatient', headerName: 'Part Patient' },
    { field: 'partTotal', headerName: 'Total'  },

   // { field: 'partMutuelleFacture', headerName: 'F Part Mutuelle' },
   // { field: 'partpatientFacture', headerName: 'F Part Patient' },
   // { field: 'partTotalFacture', headerName: 'F Total'  },

    { field: 'typeNameVisite', headerName: 'Type Viste' },
   // { field: 'typeBonVerificationName', headerName: 'Verfier' },
    { field: 'typeBonCommendName', headerName: 'Type Bon' },
    { field: 'depertmentName', headerName: 'Depertement' },
    { field: 'dateCreatedSaisie', headerName: 'Created' },
    { field: 'timeSaisie', headerName: 'Time Saisie' },
    
  ];
  const idd: GridRowId[]=[];

  let selectedRow:Array<NewFactureLivraisonMedicamentDto> = [];


const defaultinitiaHospitalType: Array<IdataModelPharmacy> = [];


export const EspacePharmacy:React.FC=()=>

{
  const preferredName = useSelector((state: RootState) => state.user.preferred_username);



  const [hospital, setHospital] = useState(defaultinitiaHospitalType);

  const [isInputFacture, setisInputFacture] = useState<{ status: string }>({ status: '' });

  const [PharmacyId, setPharmacyId] = useState(0);
  const DispatchCurrantMedecineModifier=useDispatch();
  const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);

  //console.log('values pushed is',isgetselecterCurrantNewVisitePushed)


 const isCurrantMedecineList = useSelector((state: RootState) => state.currentMedicalLivraisonArrayModifie);


const [selectedIdInRow, setIdRowSelected] = useState(idd);

const [isfactureCouvert, setisfactureCouvert] = useState(0);
const [isfactureNoCouvert, setisfactureNoCouvert] = useState(0);
const [isfactureTotal, setisfactureTotal] = useState(0);

const [isPartMituelle, setisPartMituelle] = useState(0);
const [isPartPatient, setisPartPatient] = useState(0);

const [isfactureApeyer, setisfactureApeyer] = useState(0);

const [isPartMituelleFacture, setisPartMituelleFacture] = useState(0);
const [isPartPatientFacture, setisPartPatientFacture] = useState(0);
const [selectedIDsVal, setSelectedIDsVal] = useState<Set<any>>(new Set([0]));



const AnalyseFactureToPrint = (selectedRowToPrint:any) => {  

  if(selectedRowToPrint.length===0)
{

setisfactureCouvert(0)
setisfactureNoCouvert(0)
setisPartPatient(0)
setisPartMituelle(0)
setisfactureTotal(0)

setisPartMituelleFacture(0)
setisPartPatientFacture(0)

}
else
{

// Create a Map to store the grouped results
const groupedByService = new Map<string, {
total: number,
quantity: number,
FactureCouvert: number,
FactureNocouvert: number,
PartMutuelle: number,
PartPatient: number ,
PartMutuelleFacture: number,
PartPatientFacture: number // New key for PartPatient 

}>();
// Iterate through the array and update the Map
selectedRowToPrint.forEach((
  entry: { service: any; quantity: any; partTotal: any; plafondFacture: any; ticketService: any; ticketServiceFacture: any; }) => {
const { service, quantity, partTotal, plafondFacture, ticketService,ticketServiceFacture } = entry;

if (groupedByService.has(service)) {
  // If the service is already in the Map, update the total and quantity
  const existingEntry = groupedByService.get(service)!;
  existingEntry.total += partTotal;
  existingEntry.quantity += quantity;

  // Check if the total is less than or equal to plafondFacture
  if (existingEntry.total <= plafondFacture) {
      existingEntry.FactureCouvert = existingEntry.total;
      existingEntry.FactureNocouvert = 0;
  } else {
      // If total is greater than plafondFacture, set FactureCouvert to plafondFacture and calculate FactureNocouvert
      existingEntry.FactureCouvert = plafondFacture;
      existingEntry.FactureNocouvert = existingEntry.total - plafondFacture;
  }

  // Calculate PartMutuelle based on FactureCouvert and ticket
  existingEntry.PartMutuelle = (existingEntry.FactureCouvert * (100 - ticketService)) / 100;
  existingEntry.PartMutuelleFacture = (existingEntry.FactureCouvert * (100 - ticketServiceFacture)) / 100;
  
  // Calculate PartPatient based on FactureCouvert and ticket
  existingEntry.PartPatient = (existingEntry.FactureCouvert * ticketService) / 100;
  existingEntry.PartPatientFacture = (existingEntry.FactureCouvert * ticketServiceFacture) / 100;

  console.log("Couverture Facture Decalre",ticketService)
  console.log("COuverture Facture",ticketServiceFacture)
} else {

  // If the service is not in the Map, add a new entry
  const factureCouvert = partTotal <= plafondFacture ? partTotal : plafondFacture;
  const factureNocouvert = partTotal <= plafondFacture ? 0 : partTotal - plafondFacture;

  // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
  const partMutuelle = (factureCouvert * (100 - ticketService)) / 100;
  const partPatient = (factureCouvert * ticketService) / 100;

  const partMutuelleFacture = (factureCouvert * (100 - ticketServiceFacture)) / 100;
  const partPatientFacture = (factureCouvert * ticketServiceFacture) / 100;
   
  console.log("Couverture Facture Decalre",ticketService)
  console.log("Couverture Facture",ticketServiceFacture)

  groupedByService.set(service, {
      total: partTotal,
      quantity: quantity,
      FactureCouvert: factureCouvert,
      FactureNocouvert: factureNocouvert,
      PartMutuelle: partMutuelle,
      PartPatient: partPatient,
      PartMutuelleFacture: partMutuelleFacture,
      PartPatientFacture: partPatientFacture
  });
}
});

// Calculate the total, FactureCouvert, FactureNocouvert, PartMutuelle, and PartPatient for all services

let totalAllServices = 0;
let factureCouvertAllServices = 0;
let factureNocouvertAllServices = 0;
let partMutuelleAllServices = 0;
let partPatientAllServices = 0;

let partMutuelleFactureAllServices = 0;
let partPatientFactureAllServices = 0;


groupedByService.forEach(({ total, FactureCouvert, FactureNocouvert, PartMutuelle, PartPatient,PartMutuelleFacture, PartPatientFacture }) => {
totalAllServices += total;
factureCouvertAllServices += FactureCouvert;
factureNocouvertAllServices += FactureNocouvert;
partMutuelleAllServices += PartMutuelle;
partPatientAllServices += PartPatient;

partMutuelleFactureAllServices += PartMutuelleFacture;
partPatientFactureAllServices += PartPatientFacture;
});

console.log("Total All Services:", totalAllServices);
console.log("FactureCouvert All Services:", factureCouvertAllServices);
console.log("FactureNocouvert All Services:", factureNocouvertAllServices);

console.log("PartMutuelle All Services:", partMutuelleAllServices);
console.log("PartPatient All Services:", partPatientAllServices);


console.log("PartMutuelleFacture All Services:", partMutuelleFactureAllServices);
console.log("PartPatientFacture All Services:", partPatientFactureAllServices);


setisfactureTotal(totalAllServices);
setisfactureCouvert(factureCouvertAllServices);
setisfactureNoCouvert(factureNocouvertAllServices);

setisPartMituelle(partMutuelleAllServices);
setisPartPatient(partPatientAllServices);

setisPartMituelleFacture(partMutuelleFactureAllServices);
setisPartPatientFacture(partPatientFactureAllServices);
setisfactureApeyer(Number(partPatientFactureAllServices+factureNocouvertAllServices))

}
  };


  const getHospitalWithoutPagination = useCallback(() => {
    PharmacyTypeService.getAllTypeDoctorWithoutPagination()
      .then((response: any) => {
        setHospital(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, []); // Empty dependency array means this function is memoized and won't change
  
  
  useEffect(() => {
    getHospitalWithoutPagination();
  },[getHospitalWithoutPagination]);
  
  
const UpdateFactureSaisie = (id: any) => {
  let data = {
    status: 'Verifie',
  };

  ServiceNewFactureLivraisonMedicament.updateStatusFactureVerifier(PharmacyId,id, data.status)
    .then((response: any) => {
      setisInputFacture({
        status: response.data.status,
      });

      fetchFactures();
      console.log('my result',response)
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
const UpdateFactureSaisieNoVerifier = (id: any) => {
  let data = {
    status: 'no',
  };

  ServiceNewFactureLivraisonMedicament.updateStatusFactureVerifier(PharmacyId,id, data.status)
    .then((response: any) => {
      setisInputFacture({
        status: response.data.status,
      });

      fetchFactures();
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const UpdateFactureSaisieById = () => {  

    for (let id of selectedIDsVal) {
      UpdateFactureSaisie(id)
      console.log("selectedIDs",id)
    }
  };
  const UpdateFactureSaisieByIdNoVerifie = () => {  

    for (let id of selectedIDsVal) {
      UpdateFactureSaisieNoVerifier(id)
      console.log("selectedIDs",id)
    }
  };


  const fetchFactures = async () => {
    if (isgetselecterCurrantNewVisitePushed.idFacture !== 0) {
      try {
        const response = await ServiceNewFactureLivraisonMedicament.findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer(
          isgetselecterCurrantNewVisitePushed.idinsurance,
          isgetselecterCurrantNewVisitePushed.receiptBill,
          "payer"
        );
        const facture = response.data; 
     //   setisNewFactureLivraisonMedicamentDto(facture);
        
      setIdRowSelected(facture.map((row: { idLivree: any; }) => row.idLivree));
      

     // console.log("facture.map((row: { idFacture: any; }) => row.idFacture)",facture.map((row: { idLivree: any; }) => row.idLivree))  
      
        DispatchCurrantMedecineModifier(clearMedicalLivraisonRecordsModifer())
        DispatchCurrantMedecineModifier(addMultipleMedicalLivraisonRecordsModifer(facture))
  
      } catch (e) {
        console.log(e);
      }
    }
  };

const handleChange = (e: { target: {value: any; }; }) => {
  setPharmacyId(e.target.value);
    }
      return (
        <>
     <div>
    
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Stack  
  direction="row"
  className="mb-3 mt-3"
  divider={<Divider orientation="horizontal" flexItem />}
  spacing={1}
  >

<Form.Select className="form-control" 
onChange={handleChange}>
 <option value="0">select Pharmacy ...</option>
{hospital &&
hospital.map((hospital) => (
  <option key={hospital.id} value={hospital.id} >{hospital.pharmacyName}</option>
))}
  
</Form.Select>


<Button variant="outline-dark" size="sm" onClick={fetchFactures}>
              <RiSearch2Line />
          </Button>

</Stack>     
</Form.Group>  

     <Stack
  direction="row"
  className="mb-3 mt-3"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}
>
              <Form.Label><strong>Total de Facture</strong>:{isfactureTotal}</Form.Label>
              <Form.Label><strong>Part Patient </strong>:{isPartPatientFacture}</Form.Label> 
              <Form.Label><strong>Part Mituelle </strong>:{isPartMituelleFacture}</Form.Label> 
              <Form.Label><strong>Facture couvert</strong>:{isfactureCouvert}</Form.Label> 
              <Form.Label><strong>Facture No couvert</strong>:{isfactureNoCouvert}</Form.Label> 
</Stack>    



     </div>
     
<div style={{ width: '100%' }}>
<DataGrid 
          rows={isCurrantMedecineList}
          getRowId={(row) => row.idLivree}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel:{page:0,pageSize:6},
            },
          }}
          pageSizeOptions={[6,10,15,25,35,45]}
            autoHeight
            
          checkboxSelection
          
          onRowSelectionModelChange={(ids) => {

            const selectedIDs = new Set(ids);
            setSelectedIDsVal(selectedIDs);
            selectedRow = isCurrantMedecineList.filter((row) => selectedIDs.has(row.idLivree),);
         
            console.log("selectedIDs selectedIDs",selectedIDs)
            setIdRowSelected(ids)
            AnalyseFactureToPrint(selectedRow)
            console.log("values of selectedRow",selectedRow)
           

       
        }
        }
         
        />
  <Form.Label className="mt-2 mb-2"><strong>FACTURE A VERIFIER </strong>:{isfactureApeyer}</Form.Label> 
 <Stack 
 direction="row"
 spacing={3}>
  
  
  <Button
     onClick={() => {UpdateFactureSaisieById()}}
     className="mt-3"
     variant='me-4 btn btn-success btn-sm btn-block'
   >
     Verifier
   </Button>
  
   <Button
     onClick={() => {UpdateFactureSaisieByIdNoVerifie()}}
     className="mt-3"
     variant='me-4 btn btn-success btn-sm btn-block'
   >
     No Verifier
   </Button>

    
 </Stack>

     </div>


          
        </>
      );
    }
