import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NumberState {
  plafondFacturePushed: number;
}

const initialState: NumberState = {
  plafondFacturePushed: 0, // Initial plafondFacturePushed, can be set to number default number
};

const plafondFacturePrServiceSlice = createSlice({
  name: 'plafondFacturePrService',
  initialState,
  reducers: {
    updateplafondFacturePrService: (state, action: PayloadAction<number>) => {
      state.plafondFacturePushed = action.payload;
    }
  },
});

export const { updateplafondFacturePrService} = plafondFacturePrServiceSlice.actions;
export default plafondFacturePrServiceSlice.reducer;
