import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import ServiceAdhesion from '../Services/ServiceAdhesion';
import IdataModeEnterprise from '../../Entreprises/Models/IdataModeEnterprise';
import IdataModelAdhesion from '../Models/IdataModelAdhesion';
import EnterpriseService from '../../Entreprises/Services/EnterpriseService';


const initialTypeState = {
  id: null,
  money: '',
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
const defaultenterprise:Array<IdataModeEnterprise>=[];
let selectedEnterpriseID:any;

export const AddAdhesion:React.FC=()=> {

  const [enterprise, setenterprise] = useState(defaultenterprise);

const [isInput,setInput]=useState<IdataModelAdhesion>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const handleChangeEnterprise= (e: { target: {value: any; }; }) => {
        selectedEnterpriseID=e.target.value;
          }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const saveAdhesion = () => {  

        let data = {
          money: isInput.money,
          createdBy:'haliii ganii',
          dateCreatedBy: new Date()
        };

        ServiceAdhesion.create(52,selectedEnterpriseID,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            money: response.data.money,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const resetTypeDoctor = () => {
        setInput(initialTypeState);
      }
      const getAllWithoutPaginationEnterprise = () => {
  
        EnterpriseService.getAllEnterpriseNameWithoutPagination()
          .then((response: any) => {
            setenterprise(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      };
     
useEffect(() => {
  getAllWithoutPaginationEnterprise();

});
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Adhesion</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="number" name="money" value={isInput.money} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
         <Form.Label>Entreprise</Form.Label>
    <Form.Select className="form-control"  size="sm"  
     onChange={handleChangeEnterprise}>
       <option value="0">Choose..</option>
      {enterprise &&
    enterprise.map((enterprise) => (
        <option key={enterprise.id} value={enterprise.id} >{enterprise.enterpriseName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>saveAdhesion()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={resetTypeDoctor} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}