import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Accordion } from "react-bootstrap";
import ServicePrincipalMember from '../Services/ServicePrincipalMember';
import IdataModelPMember from '../Models/IdataModelPMember';
import IdataModelCategoryCode from '../../Categories/CategoriiesCode/Models/IdataModelCategoryCode';
import CategoryTypeService from '../../Categories/CategoriiesCode/Services/CategoryTypeServices';

import IdataModelCategoryType from '../../Categories/CategoriesAffilies/Models/IdataModelCategoryType';
import CategoryAffilieTypeService from '../../Categories/CategoriesAffilies/Services/CategoryAffilieTypeService';
import IdataModeEnterpriseDtos from '../../Entreprises/Models/IdataModeEnterpriseDtos';

let accountIncrement=0;
const initialTypeState = {
  id: null,
  codeAssuree: '',
  nomPrenomAffilie: '',
  
  cotisationExact: '',
  account:accountIncrement,
  genre: '',
  catégorieSoins: '',
  email: '',
  telephones: '',
  adresseAffilie: '',
  autresInformations: '',
 
  plafond_ambilatoire:'',
  plafond_hospitalisation:'',
  plafond_pharmacy:'',
  plafond_ORL:'',
 
  plafond_urugence:'',
  plafond_ophtalmologie:'',
  category_code: '',
  plafond_dentisteries:'',

  resilier: '',
 dataResiliation: '',

  dateNaissance: '',
  photoMember: '',
  createdBy: 'Ghost Zila',
  dateCreated: '',
  tva:'',
  etatCivil:''
 
};
const defaultcode:Array<IdataModelCategoryCode>=[];
const defaultsoins:Array<IdataModelCategoryType>=[];

const defaultenterprise:Array<IdataModeEnterpriseDtos>=[];

interface ICurrantinterprise {

  editinfo: IdataModeEnterpriseDtos;
}
const initialTypeStateEnterpriseDtos = {
  ////table socialInsurance ta
  idSociete:0,
  societeName:0,
 //table enterpriseAssurance   ta1
idInterprise:0,
accountInterprise:0,
  enterpriseName: '',
  responsable: '',
  email: '',
  phoneNumber: '',
  couvertureDu: '',
  couvertureAau: '',
  address: '',
  autreInformation: '',
  resilier: '',
  dataResiliation: '',
     dateCreatedBy: new Date,
  createdBy: '',
  firstCallForPayment: '',
  secondCallForPayment: '',
  thirdCallForPayment: '',
  modePayment: '',
  frequancePayment: '',
 ////// configuration of assurance start
  primeIndividuelle: '',
  primeTotal: '',
  soinsAmbilatoire: '',
  hospitalisation: '',
  ophtalmologie: '',
  dentisterie: '',
  fraisFuneraires: ''
};
export const AddPmember:React.FC<ICurrantinterprise>=props=>{

  const [CurrantEnterprise, setCurrantEnterprise] = useState<IdataModeEnterpriseDtos>(initialTypeStateEnterpriseDtos);

    const [code, setcode] = useState(defaultcode);
    const [soins, setsoins] = useState(defaultsoins);
    const [isResilier,setResilier]=useState<string>('');


const getLastAccountType =()=>{
  ServicePrincipalMember.getLastAccountType()
    .then((response: any) => {
        accountIncrement=response.data.account
        console.log(accountIncrement);
  })
}
  
const getAllWithoutPaginationCode = () => {
  
    CategoryTypeService.getAllWithoutPagination()
      .then((response: any) => {
        setcode(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const getAllWithoutPaginationSoins = () => {
  
    CategoryAffilieTypeService.getAllTypesoinsWithoutPagination()
      .then((response: any) => {
        setsoins(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

useEffect(() => {
getLastAccountType();
getAllWithoutPaginationCode();
getAllWithoutPaginationSoins();
setCurrantEnterprise(props.editinfo);
    },[props.editinfo])


const [isInput,setInput]=useState<IdataModelPMember>(initialTypeState);

const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setInput({ ...isInput, [e.target.name]: e.target.value });
  }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }

    
      const saveTypeDoctors = () => {  

        if(accountIncrement===undefined){
          accountIncrement=40000000
        }
        let data = {
         
 codeAssuree: isInput.codeAssuree,
 nomPrenomAffilie: isInput.nomPrenomAffilie,

 cotisationExact: isInput.cotisationExact,
 account: accountIncrement+1,
 genre: isInput.genre,
 catégorieSoins: isInput.catégorieSoins,
 email: isInput.email,
 telephones: isInput.telephones,
 adresseAffilie: isInput.adresseAffilie,
 autresInformations: isInput.autresInformations,

 plafond_ambilatoire:isInput.plafond_ambilatoire,
 plafond_hospitalisation:isInput.plafond_hospitalisation,
 plafond_pharmacy:isInput.plafond_pharmacy,
 plafond_ORL:isInput.plafond_ORL,

 plafond_urugence:isInput.plafond_urugence,
 plafond_ophtalmologie:isInput.plafond_ophtalmologie,
 category_code: isInput.category_code,
 plafond_dentisteries:isInput.plafond_dentisteries,

 resilier: isResilier,
 dataResiliation: isInput.dataResiliation,

 dateNaissance: isInput.dateNaissance,
 photoMember: isInput.photoMember,
 createdBy: isInput.createdBy,
 dateCreated: isInput.dateCreated,
 tva:"no",
 etatCivil:isInput.etatCivil

        };

        ServicePrincipalMember.create(52,CurrantEnterprise.idInterprise,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            
 codeAssuree: response.data.codeAssuree,
 nomPrenomAffilie: response.data.nomPrenomAffilie,

 cotisationExact: response.data.cotisationExact,
 account: response.data.account,
 genre: response.data.genre,
 catégorieSoins: response.data.catégorieSoins,
 email: response.data.email,
 telephones: response.data.telephones,
 adresseAffilie: response.data.adresseAffilie,
 autresInformations: response.data.autresInformations,

 plafond_ambilatoire: response.data.plafond_ambilatoire,
 plafond_hospitalisation: response.data.plafond_hospitalisation,
 plafond_pharmacy: response.data.plafond_pharmacy,
 plafond_ORL: response.data.plafond_ORL,

 plafond_urugence: response.data.plafond_urugence,
 plafond_ophtalmologie: response.data.plafond_ophtalmologie,
 category_code: response.data.category_code,
 plafond_dentisteries: response.data.plafond_dentisteries,

 resilier: response.data.resilier,
 dataResiliation: response.data.dataResiliation,


 dateNaissance: response.data.dateNaissance,
 photoMember: response.data.photoMember,
 createdBy: response.data.createdBy,
 dateCreated: response.data.dateCreated,
 tva:response.data.tva,
 etatCivil:response.data.etatCivil

          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }
const CountValue=()=>{
  for (let i = 0; i < 6500; i++) {
    saveTypeDoctors();
    console.log ("Block statement execution no." + i);
  }
}
     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>

         <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Informaton Prencipal #1</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">

        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Nom/Prenom</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="text" name="nomPrenomAffilie" value={isInput.nomPrenomAffilie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
       
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>email</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="email" name="email" value={isInput.email} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>PhoneNumber</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="phoneNumber" name="telephones" value={isInput.telephones} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>address</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="address" name="adresseAffilie" value={isInput.adresseAffilie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Date naissance</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="Date" name="dateNaissance" value={isInput.dateNaissance} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Autre Information</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="text" name="autresInformations" value={isInput.autresInformations} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>sex</Form.Label>
            <Form.Select  size="sm" name="genre" value={isInput.genre} onChange={handleChange}>
                <option>Choisir </option>
               <option value="Homme">Homme</option>
             <option value="Femme">Femme</option>
    </Form.Select>
        </Form.Group>
        
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Etat Civil</Form.Label>
            <Form.Select  size="sm" name="etatCivil" value={isInput.etatCivil} onChange={handleChange}>
                <option>Choisir </option>
               <option value="yes">yes</option>
             <option value="No">no</option>
    </Form.Select>
        </Form.Group>
        </Row>
       
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Information Secondaire #2</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
 
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Cotisation Exact</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="cotisationExact" value={isInput.cotisationExact} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Code Category</Form.Label>
    <Form.Select className="form-control"  size="sm" name="category_code"
    value={isInput.category_code} onChange={handleChange}>
       <option value="0">Choose..</option>
      {code &&
    code.map((code) => (
        <option key={code.id} value={code.categoryName} >{code.categoryName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
   <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label> Category Soins</Form.Label>
    <Form.Select className="form-control"  size="sm" name="catégorieSoins"  
    value={isInput.catégorieSoins} onChange={handleChange}>
    <option value="0">Choose..</option>
      {soins &&
    soins.map((soins) => (
        <option key={soins.id} value={soins.categoryName} >{soins.categoryName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond ambilatoire</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_ambilatoire" value={isInput.plafond_ambilatoire} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond hospitalisation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_hospitalisation" value={isInput.plafond_hospitalisation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond pharmacy</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_pharmacy" value={isInput.plafond_pharmacy} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond ORL</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_ORL" value={isInput.plafond_ORL} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond urugence</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_urugence" value={isInput.plafond_urugence} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond ophtalmologie</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_ophtalmologie" value={isInput.plafond_ophtalmologie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond dentisteries</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_dentisteries" value={isInput.plafond_dentisteries} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Resilier</Form.Label>
            <Form.Select  size="sm" name="resilier" value={isInput.resilier} onChange={handleChange}>
                <option>Choisir </option>
               <option value="Oui">Oui</option>
             <option value="Non">Non</option>
    </Form.Select>
        </Form.Group>
             <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Date de resiliation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="dataResiliation" value={isInput.dataResiliation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>saveTypeDoctors()} className="me-4 btn btn-success btn-sm btn-block">save</button>
            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>

        </Form.Group>
      </Row>
            </form>
        </>
    );
}