// ConfigAddFactureSaisiePrescriptParSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ArrayState {
  data: any;
}

const initialState: ArrayState = {
 // data: ['offline_access', 'default-roles-hema-clinic', 'uma_authorization', 'receptionist', 'user'],
  data:'',
};

const ConfigAddFactureSaisiePrescriptParSlice = createSlice({
  name: 'ConfigAddFactureSaisiePrescriptPar',
  initialState,
  reducers: {
    updateConfigAddFactureSaisiePrescriptPar: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { updateConfigAddFactureSaisiePrescriptPar } = ConfigAddFactureSaisiePrescriptParSlice.actions;
export default ConfigAddFactureSaisiePrescriptParSlice.reducer;
