
import { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { AddMedicament } from "../../Components/ListMedicament/Operations/AddMedicament";
import { EditMedicament } from "../../Components/ListMedicament/Operations/EditMedicament";
import IdataModelListMedicament from "../../Components/ListMedicament/Models/ListMedicament";
import { TableMedicament } from "../../Components/ListMedicament/Tables/TableMedicament";
import { TableMedicamentClient } from "../../Components/ListMedicament/Tables/TableMedicamentClient";

  
  const initialTypeState = {
    code: 0,
       typeName: '',
       excl: '',  //ouii or no
       acc: '',  // oui or no
       createby: '',
       dateCreatedBy: '',
  };
  
  

export const PageListMedicaments:React.FC=()=>
    {
        const [isSwichMedoc,setSwichMedoc]= useState(true);
      


        const [EditDepart, setEditDepart] = useState<IdataModelListMedicament>(initialTypeState);

        const onCurrantMedicament=(data:IdataModelListMedicament)=>{
            setEditDepart(data);
        }
        
        return(
            <>
            <Container fluid>
                <Row>
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                {isSwichMedoc?
                (
                    <AddMedicament />
                ):(
      <EditMedicament
       show={isSwichMedoc} 
        onHide={() => setSwichMedoc(true)}

            EditInfo={EditDepart} /> 
                )
  }
         <TableMedicament
          show={isSwichMedoc} 
           onHide={() => setSwichMedoc(false)}
         onEditType={onCurrantMedicament}
          />
                    </Card.Body>
                </Card>
                <br />
                </Col>

            </Row>
        
            
            </Container>
            </>
        );
    }