import http from "../../../../AxiosRequest/httpcommon";
import NewBonDto from "../Models/DTO/NewBonDto";
import NewBon from "../Models/NewBon";

const getAll = (params:any) => {
  return http.get<Array<NewBonDto>>("/NewBonPatient", { params });
};

const getAllNewBonWithoutPagination = () => {
  return http.get<Array<NewBon>>("/sortedNewBon");
};

const getLastIncrementIdReceiptBill=( socialinsurance_id:any, hospital_id: any)=>{
  return http.get<Array<NewBon>>(`/NewBonIncrementIdReceiptBill/${socialinsurance_id}/${hospital_id}`);

}
const findBySocialinsuranceIdAndHospitalIdAndReceiptBill=( socialinsurance_id:any, hospital_id: any, receiptBill: any)=>{
  return http.get<Array<NewBon>>(`/NewBon/${socialinsurance_id}/${hospital_id}/${receiptBill}`);

}

const create = (socialinsuranceId:any,enterprisesAssurance_id:any,pMember_id:any,hospital_id: any,data: NewBon) => {
    return http.post<NewBon>(`/createNewBon/${socialinsuranceId}/${enterprisesAssurance_id}/${pMember_id}/${hospital_id}/NewBon`, data);
  };
  

const findBySocialinsuranceIdAndCodeAssureeControl=( socialinsurance_id:any, codeAssureeControl: any)=>{
  return http.get<Array<NewBon>>(`/NewBoncodeAssureeControl/${socialinsurance_id}/${codeAssureeControl}`);
}

const remove = (id: any) => {
  return http.delete<any>(`/NewBon/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/NewBon`);
};

const ServicesNewBon = {
  getAllNewBonWithoutPagination,
  getAll,
  getLastIncrementIdReceiptBill,
  findBySocialinsuranceIdAndHospitalIdAndReceiptBill,
  findBySocialinsuranceIdAndCodeAssureeControl,
  create,
  remove,
  removeAll
};

export default ServicesNewBon;