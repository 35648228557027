
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {Form, InputGroup, Row, Stack } from "react-bootstrap";
import NewFactureSaisie from "../models/NewFactureSaisie";
import ServicesNewFactureSaisie from "../services/ServiceNewFactureSaisie";
import CouvertureconfigService from "../../../ASSURANCES/Configurations/Couvertures/Services/CouvertureconfigService";
import IdataModelPriceActesDtos from '../../../Acts/PriceActes/Models/IdataModelPriceActesDtos';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import HospitalTypeService from "../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import { updateDetailsHospital } from "../../../../Redux/slices/hospitalDetails/hospitalDetails";
import RestPlafondYearService from "../../../ASSURANCES/CounterRestPlafond/Services/RestPlafondYearService";


      const newFactureSaisie={
        prestation:'',

  prestationPrescriptPar:'',
        pricePrestation:0,
        plafondFacture:0,
        ticketService:0,
        quantity:1,
        partMutuelle:0,
        partpatient:0,
        partTotal:0,
        statusFacturePayer:'No payer',
        createdBy:'Ramadhani Ally',
        dateCreatedSaisie:'',
        timeSaisie:'',

     partMutuelleFacture:0,
     partpatientFacture:0,
     partTotalFacture:0,
     
     ticketServiceFacture:0,

     statusFactureDeclare:'',
     statusFactureVerifier:'',
     statusFactureRembourceAvecAutreFacture:'',
     statusFactureRembourceUneFacture:'',
     idRestIncrementPlafondConsomme:''
      } 
      
   
 const IdataModelPriceActesDtosInit= {// soinsDetails e
  idSoinsDetails:0,
   code:0,
   intitule:'',
   excl:'',
   acc:'',
   dateCreatedBy:'',

  // soins s
  idSoins:0,
   codeSoins:'',
   intituleSoins:'',

  // typePrestation t
   idTypePrestation:0,
   typePrestationsName:'',
   accountType:0,

  // price b
   idPrice:0,
   price:0,
   priceCreatedBy:'',
  // hospital h
   hospitalId:'',
   hospitalName:''
}



interface ICurrantSaisie {
    show: boolean; 
    onHide: () => void; 

    currentactsprices:IdataModelPriceActesDtos;
  }

export const AddFactureSaisie:React.FC<ICurrantSaisie>=props=>{

  const dispatchHospitalInfo = useDispatch();

  const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);

  let isDoctor=useSelector((state:RootState)=>state.ConfigAddFactureSaisiePrescriptPar);
  let isServiceConfig=useSelector((state:RootState)=>state.ConfigAddFactureSaisiePlafond);
  console.log('isServiceConfig.data',isServiceConfig.data);

  let isDepertment=useSelector((state:RootState)=>state.ConfigAddFactureSaisieDepertment);
  
  console.log("isDoctor",isDoctor.data);
  console.log("isDepertment",isDepertment.data);
  console.log("isServiceConfig",isServiceConfig.data);

  const preferredName = useSelector((state: RootState) => state.user.preferred_username);
    const [isTotal, setisTotal] = useState(0);
    const [isPartMituelle, setisPartMituelle] = useState(0);
    const [isPartPatient, setisPartPatient] = useState(0);

    const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
    const [isTotalFacture, setisTotalFacture] = useState(0);
    const [isPartMituelleFacture, setisPartMituelleFacture] = useState(0);
    const [isPartPatientFacture, setisPartPatientFacture] = useState(0);

    const [ConfigTicket, setConfigTicket] = useState(0);
    const [ConfigTicketFacture, setConfigTicketFacture] = useState(0);

     
     const [isInputFactureSaisie,setisInputFactureSaisie]=useState<NewFactureSaisie>(newFactureSaisie);
     const [isInputCurrentActsPriceDtos, setisInputCurrentActsPriceDtos]=useState<IdataModelPriceActesDtos>(IdataModelPriceActesDtosInit);
    

      const [isChecked, setIsChecked] = useState(true);

      const [plafondYearTypeVisiteId, setplafondYearTypeVisiteId] = useState(0);
      const [plafondYearCategoryId, setplafondYearCategoryId] = useState(0);
    const [plafondYearId, setplafondYearId] = useState(0);
    
      
      const handleSwitchChange = () => {
        setIsChecked(!isChecked);
        CalculateFacture();
        // Do something else when the switch changes state
      };

let couvertureTotal=100;
  
  let currentDate = new Date();
      var getYear = currentDate.toLocaleString("default", { year: "numeric" });
      var getMonth = currentDate.toLocaleString("default", { month: "2-digit" });
      var getDay = currentDate.toLocaleString("default", { day: "2-digit" });
  
      const renderNumber = (value: number, length: number) => {
        let result: string = String(value);
      
        for (; length > result.length; length -= 1) result = '0' + result;
      
        return result;
      };
      const hours: string = renderNumber(currentDate.getHours(), 2);
      const minutes: string = renderNumber(currentDate.getMinutes(), 2);
     // const seconds: string = renderNumber(currentDate.getSeconds(), 2);
      //const milliseconds: string = renderNumber(currentDate.getMilliseconds(), 3);
      
   let currentDateFormat = getYear + "-" + getMonth + "-" + getDay;

   let currentTime=hours + "h:" + minutes + "min" ;
  
  const [ConfigId, setConfigId] = useState(0);
  const [ConfigPlafondFacture, setConfigPlafondFacture] = useState(0);



  const ConfigServiceInterprise = useCallback(() => {
    if (
      isgetselecterCurrantNewVisitePushed.idinsurance !== 0 &&
      isgetselecterCurrantNewVisitePushed.idEnterprise !== 0 &&
      isServiceConfig.data !== 0 &&
      isgetselecterCurrantNewVisitePushed.category_code !== null
    ) {
      CouvertureconfigService.findByInsuranceIdAndEnterpriseIdAndSecteurAndidCouvertureConfig(
        isgetselecterCurrantNewVisitePushed.idinsurance,
        isgetselecterCurrantNewVisitePushed.idEnterprise,
        isgetselecterCurrantNewVisitePushed.typehospital,
        isServiceConfig.data,
        isgetselecterCurrantNewVisitePushed.category_code
      )
        .then((response: any) => {
          setConfigId(response.data.idCouvertureConfig);
          setConfigTicket(response.data.ticket);
          setConfigTicketFacture(response.data.ticketFacture);
          setConfigPlafondFacture(response.data.plafondOrdonence);

           console.log('CouvertureconfigService data',response)
        })
        .catch((e: Error) => {
          console.log('CouvertureconfigService ERROR',e);
        });
    } else {
      console.log('haliii ganiii');
    }
  }, [isgetselecterCurrantNewVisitePushed.idinsurance, isgetselecterCurrantNewVisitePushed.idEnterprise, isgetselecterCurrantNewVisitePushed.category_code, isgetselecterCurrantNewVisitePushed.typehospital, isServiceConfig.data]);

  useEffect(() => {
    ConfigServiceInterprise();
},[ConfigServiceInterprise]);
 

  
  useEffect(() => {

  console.log('isServiceConfig.data',isServiceConfig.data);

    setisInputCurrentActsPriceDtos(props.currentactsprices);
   // console.log(props.currentactsprices);
      },[props.currentactsprices])  

      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const reset = () => {
        setisInputFactureSaisie(newFactureSaisie);
        props.onHide()
      }
      
      const getRestPlafondYear = async () => {
        try {
          if (isgetselecterCurrantNewVisitePushed.codeAssuree === "") {
            /* Don't do anything */
          } else {
            try {
              const response: any = await RestPlafondYearService.getLastIdRefRestPlafondAndPlafondYear(
                isgetselecterCurrantNewVisitePushed.idinsurance,
                isgetselecterCurrantNewVisitePushed.idEnterprise,
                isgetselecterCurrantNewVisitePushed.codeAssuree
              );
              console.log("response.data.idRestIncrementPlafondConsomme",response.data.idRestIncrementPlafondConsomme);
              setplafondYearId(isgetselecterCurrantNewVisitePushed.plafondYearId);
              setplafondYearCategoryId(isgetselecterCurrantNewVisitePushed.categoryId);
              setplafondYearTypeVisiteId(isgetselecterCurrantNewVisitePushed.idVisite);
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      
useEffect(() => {
  getRestPlafondYear();
},[]);

    const AddSaisie = () => {  

        let data = {
          prestation:isInputCurrentActsPriceDtos.typePrestationsName,
          pricePrestation:isInputCurrentActsPriceDtos.price,
          prestationPrescriptPar:isDoctor.data,
          plafondFacture:ConfigPlafondFacture,
          ticketService:ConfigTicket,
          ticketServiceFacture:ConfigTicketFacture,
          quantity:isInputFactureSaisie.quantity,
          partMutuelle:isPartMituelle,
          partpatient:isPartPatient,
          partTotal:isTotal,
          partMutuelleFacture:isPartMituelleFacture,
          partpatientFacture:isPartPatientFacture,
          partTotalFacture:isTotalFacture,
          statusFacturePayer:'No payer',
          statusFactureDeclare:'no',
          statusFactureVerifier:'no',
          statusFactureRembourceAvecAutreFacture:'no',
          statusFactureRembourceUneFacture:'no',
          createdBy:preferredName,
          dateCreatedSaisie:currentDateFormat,
          timeSaisie:currentTime,
          idRestIncrementPlafondConsomme:0,
        };

        ServicesNewFactureSaisie.createFactureSaisie(
          isgetselecterCurrantNewVisitePushed.idinsurance,
          isgetselecterCurrantNewVisitePushed.idFacture,
          isgetselecterCurrantNewVisitePushed.idDoctorName,
          isgetselecterCurrantNewVisitePushed.idtypeDoctor ,
          ConfigId,
          isgetselecterCurrantNewVisitePushed.idhospital,
          isDepertment.data,
          isgetselecterCurrantNewVisitePushed.plafondYearId ,data)
        .then((response: any) => {
          setisInputFactureSaisie({
            id: response.data.id,
            prestation: response.data.prestation,
            prestationPrescriptPar:response.data.prestationPrescriptPar,
            pricePrestation: response.data.pricePrestation,
            plafondFacture: response.data.plafondFacture,
            ticketService: response.data.ticketService,
            quantity: response.data.quantity,
            partMutuelle: response.data.partMutuelle,
            partpatient: response.data.partpatient,
            partTotal: response.data.partTotal,
            statusFacturePayer: response.data.statusFacturePayer,
            createdBy: response.data.createdBy,
            dateCreatedSaisie:currentDateFormat,
            timeSaisie:currentTime,
            partMutuelleFacture:response.data.partMutuelleFacture,
            partpatientFacture:response.data.partpatientFacture,
            partTotalFacture:response.data.partTotalFacture,
            
            ticketServiceFacture:response.data.ticketServiceFacture,
      
            statusFactureDeclare:response.data.statusFactureDeclare,
            statusFactureVerifier:response.data.statusFactureVerifier,
            statusFactureRembourceAvecAutreFacture:response.data.statusFactureRembourceAvecAutreFacture,
            statusFactureRembourceUneFacture:response.data.statusFactureRembourceUneFacture,
            idRestIncrementPlafondConsomme:response.data.idRestIncrementPlafondConsomme,
          });

          findByHospital()
          props.onHide()
    // Example usage
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
   
      const handleChangeNewFactureSaisie = (e: ChangeEvent<HTMLInputElement>) => {
        setisInputFactureSaisie({ ...isInputFactureSaisie, [e.target.name]: e.target.value });
      };

      const findByHospital = () => {

        if(StructuresData.length===1){
          let concatenatedString: string = StructuresData.join(", ");
          HospitalTypeService.findByhospitalName(concatenatedString)
          .then((response: any) => {
            const {hospital}=response.data
            dispatchHospitalInfo(updateDetailsHospital(hospital));
            console.log(hospital);
          })
          .catch((e: Error) => {
            console.log(e);
          });
          
        }else{
          console.log('you need to choose the structure on which you are working');
        }
      };

      
const CalculateFacture = useCallback(() => {
  if (isChecked === true) {
    let couvertureMituelle = couvertureTotal - ConfigTicket;
    setisPartMituelle(
      (isInputCurrentActsPriceDtos.price * couvertureMituelle * isInputFactureSaisie.quantity) / 100
    );
    setisPartPatient(
      (isInputCurrentActsPriceDtos.price * ConfigTicket * isInputFactureSaisie.quantity) / 100
    );
    setisTotal(isInputCurrentActsPriceDtos.price * isInputFactureSaisie.quantity);

    let couvertureMituelle2 = couvertureTotal - ConfigTicketFacture;
    setisPartMituelleFacture(
      (isInputCurrentActsPriceDtos.price * couvertureMituelle2 * isInputFactureSaisie.quantity) /
        100
    );
    setisPartPatientFacture(
      (isInputCurrentActsPriceDtos.price * ConfigTicketFacture * isInputFactureSaisie.quantity) /
        100
    );
    setisTotalFacture(isInputCurrentActsPriceDtos.price * isInputFactureSaisie.quantity);
  } else {
    // setConfigPlafondFacture(0)
    // setConfigTicket(0)

    setisPartMituelle(0);
    setisPartPatient(isInputCurrentActsPriceDtos.price * isInputFactureSaisie.quantity);
    setisTotal(isInputCurrentActsPriceDtos.price * isInputFactureSaisie.quantity);

    setisPartMituelleFacture(0);
    setisPartPatientFacture(isInputCurrentActsPriceDtos.price * isInputFactureSaisie.quantity);
    setisTotalFacture(isInputCurrentActsPriceDtos.price * isInputFactureSaisie.quantity);
  }
}, [
  isChecked,
  couvertureTotal,
  ConfigTicket,
  ConfigTicketFacture,
  setisPartMituelle,
  setisPartPatient,
  setisTotal,
  setisPartMituelleFacture,
  setisPartPatientFacture,
  setisTotalFacture,
  isInputCurrentActsPriceDtos.price,
  isInputFactureSaisie.quantity,
]);
    
    useEffect(() => {
        CalculateFacture();  
    },[CalculateFacture]);
     
    return(
        <>
     <div>
     < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Price</Form.Label>
         <InputGroup>
         <Form.Label
  aria-label="Price"
  aria-describedby="basic-addon2"
  style={{ marginLeft: '10px', fontWeight: 'bold' }}
>
  {isInputCurrentActsPriceDtos.price}
</Form.Label> </InputGroup>




        </Form.Group>
       
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Combien de Quantite</Form.Label>
            <InputGroup>
                <Form.Control aria-label="quantity" aria-describedby="basic-addon2" type="number"name="quantity" value= 
                  {isInputFactureSaisie.quantity} onChange={handleChangeNewFactureSaisie} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
        <Form.Label>Couverture</Form.Label>
      <Form.Check
        type="switch"
        id="custom-switch"
        label={isChecked ? 'Prestation Couvert' : 'Prestation No Couvert'}
        checked={isChecked}
        onChange={handleSwitchChange}
      />
    </Form.Group>
         </Row>
        
       
      <Row className="mb-3">
      <Stack  direction="horizontal" gap={2}>
  <Form.Label ><strong>Prestation</strong>:{isInputCurrentActsPriceDtos.typePrestationsName}</Form.Label>

<Form.Label><strong>Part Mituelle</strong>:{isPartMituelle} </Form.Label>
        
<Form.Label><strong>part Patient</strong>: {isPartPatient}</Form.Label>

<Form.Label><strong>Total de Facture</strong>:{isTotal}</Form.Label>
</Stack>
      </Row>
      <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-8">
            <button  onClick={()=>AddSaisie()} className="me-4 btn btn-success btn-sm btn-block">saisie</button>
            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
</Form.Group>
      </Row>
            </form>
     </div>
        </>
    );
}