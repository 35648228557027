import {  ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import { IpriceActes } from "../Interfaces/IpriceActes";
import IdataModelPriceActesDtos from "../Models/IdataModelPriceActesDtos";
import PriceSoinsService from "../Services/PriceSoinsService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";




export const PriceActeTableAcceuille:React.FC<IpriceActes>=props=>{

    const [priceHopital, setpriceHopital] = useState<Array<IdataModelPriceActesDtos>>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchdetails, setSearchdetails] = useState<string>("");
    
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6];
    
    const getRequestParams = (searchintitule:string,hospitalName:any, thepage: number, thepageSize: number) => {
      let params = {
        intitule:searchintitule,
        hospitalName:hospitalName,
        page: thepage,
        size:thepageSize
      };
    
      if (searchintitule) {
        params["intitule"] = searchintitule;
      }
      if (hospitalName) {
        params["hospitalName"] = hospitalName;
      }
    
      if (thepage) {
        params["page"] = thepage - 1;
      }
      
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
   

    const onChangeSearchdetails = (e: ChangeEvent<HTMLInputElement>) => {
      const searchdetails = e.target.value;
      setSearchdetails(searchdetails);
    };
    
    const findBydetails = () => {

      let hospital: string = StructuresData.join(", ");
      setpriceHopital([]);
       PriceSoinsService.findByDetailsSoinsNameByStructure(searchdetails,hospital)
        .then((response: any) => {
          const {price } = response.data;
          setpriceHopital(price);
          setCurrentIndex(-1);
          console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const retrievedetails = () => {

      let hospital: string = StructuresData.join(", ");
      const params = getRequestParams(searchdetails,hospital, page, pageSize);
       setpriceHopital([]);
       PriceSoinsService.getAllByStructure(params)
    
        .then((response: any) => {
    
          const {price , totalPages} = response.data;
    
          setpriceHopital(price);
          setCount(totalPages);
    
          console.log(response.data);
          console.log(params);
    
        })
    
        .catch((e: Error) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrievedetails();       
    },[page , pageSize]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
    const refreshList = () => {
      retrievedetails();
      setCurrentIndex(-1);
    };
    
    const setActivedetails = (idataModelPriceActesDtos: IdataModelPriceActesDtos, index: number) => {
      setCurrentIndex(index); 
       props.onEditpriceActe(idataModelPriceActesDtos);
       props.onHide();
      console.log(idataModelPriceActesDtos);
      console.log(index);
    };
    

      return(
            <>
            <Row className="mb-3">
          
          <Stack  direction="horizontal" gap={2}>
                    <Form.Control className="me-auto"
                      placeholder="Filtre le soins..."
                      value={searchdetails}
                       onChange={onChangeSearchdetails} 
                      />
                    <Button variant="outline-dark btn-sm"
                     onClick={findBydetails}>Cherchez</Button>
             </Stack>   
            <div className="mt-3">
              
            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
                <Row>
                  <Col>
                  <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />      
            </Col>             
          </Row>
              
            </div>
            <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>Type</th>
                <th>Category</th>
                <th>Soins</th>
                <th>Prix</th>
                <th>Struxtcture</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {priceHopital.length>0 ? (
              priceHopital.map((i, index) => (
                    <tr key={index }>
                    <td>{index+1}</td>
                    <td>{i.typePrestationsName}</td>
                    <td>{i.intituleSoins}</td>
                    <td>{i.intitule}</td>
                    <td>{i.price}</td>
                    <td>{i.hospitalName}</td>
                    <td>
                    <Stack direction="horizontal" gap={2}>
                    <Button className="me-4 btn btn-success btn-sm btn-block"
                    onClick={() => setActivedetails(i, index)}>Edit</Button>
     </Stack>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={3}>No details</td>
                </tr>
              )}
    
            </tbody>
          </Table>
          </Row>
    
            </>
        );
    }

