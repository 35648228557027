import { useState, useEffect } from 'react';
import { Row, Form, InputGroup, Accordion } from "react-bootstrap";
import IdataModelFrequance from '../../Payments/Frequance/Models/IdataModelFrequance';
import IdataModelMode from '../../Payments/Mode/Models/IdataModelMode';
import EnterpriseService from '../Services/EnterpriseService';
import ModeService from '../../Payments/Mode/Services/ModeService';
import FrequenceService from '../../Payments/Frequance/Services/FrequenceService';
import IdataModeEnterpriseDtos from '../Models/IdataModeEnterpriseDtos';
import IdataModeEnterprise from '../Models/IdataModeEnterprise';

  interface IEditTypeEnterpriseList {
    show: boolean; 
    onHide: () => void; 
    EditInfo: IdataModeEnterpriseDtos;
  }
  const defaultFrequance: Array<IdataModelFrequance> = [];
  const defaultMode: Array<IdataModelMode> = [];
  
  export const EditEnterprise :React.FC<IEditTypeEnterpriseList>=props=> {
    const [mode, setmode] = useState(defaultMode);
    const [frequence, setfrequence] = useState(defaultFrequance);
    const [isResilier,setResilier]=useState('');

    const initialTypeStateDtos = {
        ////table socialInsurance ta
        idSociete:0,
        SocieteName:0,
       //table enterpriseAssurance   ta1
      idInterprise:0,
      accountInterprise:0,
        enterpriseName: '',
        responsable: '',
        email: '',
        phoneNumber: '',
        couvertureDu: '',
        couvertureAau: '',
        address: '',
        autreInformation: '',
        resilier: '',
        dataResiliation: '',
        dateCreatedBy: new Date(),
        createdBy: '',
        firstCallForPayment: '',
        secondCallForPayment: '',
        thirdCallForPayment: '',
        modePayment: '',
        frequancePayment: '',
       ////// configuration of assurance start
        primeIndividuelle: '',
        primeTotal: '',
        soinsAmbilatoire: '',
        hospitalisation: '',
        Ophtalmologie: '',
        dentisterie: '',
        fraisFuneraires: ''
      };
      const initialTypeState = {
        id:null,
        account: 0,
        enterpriseName: '',
        responsable: '',
        email: '',
        phoneNumber: '',
        couvertureDu: '',
        couvertureAau: '',
        address: '',
        autreInformation:'',
        resilier: '',
        dataResiliation: '',
        dateCreatedBy: '',
        createdBy:'ghostzila',
        firstCallForPayment: '',
        secondCallForPayment: '',
        thirdCallForPayment: '',
        modePayment: '',
        frequancePayment: '',
       ////// configuration of assurance start
        primeIndividuelle:'',
        primeTotal:'',
       //les differents plafonds
        soinsAmbilatoire:'',
        hospitalisation:'',
        ophtalmologie:'',
        dentisterie:'',
        fraisFuneraires:''
      };
    const [isInput,setInput]=useState<IdataModeEnterprise>(initialTypeState);

    const [isInputDtos,setInputDtos]=useState<IdataModeEnterpriseDtos>(props.EditInfo);


    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setInputDtos({ ...isInputDtos, [e.target.name]: e.target.value });
      }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
          
   const EditTypeDoctors = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
                enterpriseName: isInputDtos.enterpriseName,
                responsable: isInputDtos.responsable,
                email: isInputDtos.email,
                phoneNumber: isInputDtos.phoneNumber,
                couvertureDu:isInputDtos.couvertureDu,
                couvertureAau: isInputDtos.couvertureAau,
                address: isInputDtos.address,
                autreInformation:isInputDtos.autreInformation,
               
                resilier:isInputDtos.resilier,
                dataResiliation: isInputDtos.dataResiliation,
                dateCreatedBy: isInput.dateCreatedBy,
                createdBy: isInputDtos.createdBy,
                firstCallForPayment: isInputDtos.firstCallForPayment,
                secondCallForPayment: isInputDtos.secondCallForPayment,
                thirdCallForPayment: isInputDtos.thirdCallForPayment,
                modePayment: isInputDtos.modePayment,
                frequancePayment: isInputDtos.frequancePayment,
               
               ////// configuration of assurance start
                primeIndividuelle: isInputDtos.primeIndividuelle,
                primeTotal: isInputDtos.primeTotal,
               //les differents plafonds
                soinsAmbilatoire: isInputDtos.soinsAmbilatoire,
                hospitalisation: isInputDtos.hospitalisation,
                ophtalmologie: isInputDtos.ophtalmologie,
                dentisterie: isInputDtos.dentisterie,
                fraisFuneraires:isInputDtos.fraisFuneraires
              };
            EnterpriseService .update(isInputDtos.idInterprise,data)
            .then((response: any) => {
                setInput({
         enterpriseName:  response.data.enterpriseName,
         responsable:  response.data.responsable,
         email:  response.data.email,
         phoneNumber:  response.data.phoneNumber,
         couvertureDu:  response.data.couvertureDu,
         couvertureAau:  response.data.couvertureAau,
         address:  response.data.address,
         autreInformation:response.data.autreInformation,
         resilier:  response.data.resilier,
         dataResiliation:  response.data.dataResiliation,
         dateCreatedBy:  response.data.dateCreatedBy,
         createdBy:  response.data.createdBy,
        
         firstCallForPayment:  response.data.firstCallForPayment,
         secondCallForPayment:  response.data.secondCallForPayment,
         thirdCallForPayment:  response.data.thirdCallForPayment,
         modePayment:  response.data.modePayment,
         frequancePayment:  response.data.frequancePayment,
        
        ////// configuration of assurance start
         primeIndividuelle:  response.data.primeIndividuelle,
         primeTotal:  response.data.primeTotal,
        //les differents plafonds
         soinsAmbilatoire:  response.data.soinsAmbilatoire,
            hospitalisation:  response.data.hospitalisation,
         ophtalmologie:  response.data.ophtalmologie,
         dentisterie:  response.data.dentisterie,
         fraisFuneraires: response.data.fraisFuneraires
        
        
                  });
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInputDtos);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
        
                  const radioHandlerStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
                     setResilier(event.target.value);
                  };
             
const getMode =()=>{
    ModeService .getAllWithoutPagination()
      .then((response: any) => {
        setmode(response.data);
    
    })
  }
  const geFrequance =()=>{
    FrequenceService .getAllTypeWithoutPagination()
      .then((response: any) => {
         setfrequence(response.data)
    })
  }
          
  useEffect(() => {

    setInputDtos(props.EditInfo)
    getMode();
    geFrequance();

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
        <Accordion  flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Informaton Prencipal #1</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Nom entreprise</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="name" name="enterpriseName" value={isInputDtos.enterpriseName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Responsable</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="text" name="responsable" value={isInputDtos.responsable} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Email</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="email" name="email" value={isInputDtos.email} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>PhoneNumber</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="phoneNumber" name="phoneNumber" value={isInputDtos.phoneNumber} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>couver de</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="Date" name="couvertureDu" value={isInputDtos.couvertureDu} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>couver au</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="couvertureAau" value={isInputDtos.couvertureAau} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>address</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="address" name="address" value={isInputDtos.address} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>autreInformation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="text" name="autreInformation" value={isInputDtos.autreInformation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Mode Payment</Form.Label>
    <Form.Select className="form-control" 
    name="modePayment" value={isInputDtos.modePayment} onChange={handleChange}
     >
       <option value="0">Choose..</option>
      {mode &&
    mode.map((mode) => (
        <option key={mode.id} value={mode.typeName} >{mode.typeName}</option>
    ))}
        
    </Form.Select>
        </Form.Group>
<Form.Group controlId="formBasicEmail" className="col col-sm-3">
    <Form.Label>Frequance Payment</Form.Label>
    <Form.Select className="form-control" 
    name="frequancePayment" value={isInputDtos.frequancePayment} onChange={handleChange}
    >
       <option value="0">Choose..</option>
      {frequence &&
    frequence.map((frequence) => (
        <option key={frequence.id} value={frequence.typeName} >{frequence.typeName}</option>
    ))}
        
    </Form.Select>     
       </Form.Group>
        </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Information Secondaire #2</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
            <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Resilier</Form.Label>
            <Form.Select  size="sm" name="resilier" value={isInputDtos.resilier} onChange={handleChange}>
                <option>Choisir </option>
               <option value="Oui">Oui</option>
             <option value="No">No</option>
    </Form.Select>
        </Form.Group>
        
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>date de Resiliation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="dataResiliation" value={isInputDtos.dataResiliation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Rappel no 1</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="firstCallForPayment" value={isInputDtos.firstCallForPayment} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Rappel no 2</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="secondCallForPayment" value={isInputDtos.secondCallForPayment} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Rappel no 3</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="thirdCallForPayment" value={isInputDtos.thirdCallForPayment} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Information Sur les Plafonds #2</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label> prime Individuelle</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="primeIndividuelle" value={isInputDtos.primeIndividuelle} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>prime Total</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="primeTotal" value={isInputDtos.primeTotal} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>soins Ambilatoire</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="soinsAmbilatoire" value={isInputDtos.soinsAmbilatoire} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>hospitalisation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="hospitalisation" value={isInputDtos.hospitalisation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>ophtalmologie</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="ophtalmologie" value={isInputDtos.ophtalmologie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>dentisterie</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="dentisterie" value={isInputDtos.dentisterie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>fraisFuneraires</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="fraisFuneraires" value={isInputDtos.fraisFuneraires} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>



    <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditTypeDoctors} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
  </Form.Group>
         </Row>
         </form>
            </>
        );
  }
