import { useState } from "react";
import TypeBonsDtos from "../../../Components/ASSURANCES/Bons/Models/TypeBonsDtos";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AddTypeBon } from "../../../Components/ASSURANCES/Bons/TypeOperations/AddTypeBon";
import { TypeTableBons } from "../../../Components/ASSURANCES/Bons/Tables/TypeTableBons";
import { EditTypeBons } from "../../../Components/ASSURANCES/Bons/TypeOperations/EditTypeBons";

  const initialTypeBonDto = {
    typeBonsId:'',
    code:'',
    typeBonsName:'',
    typeBonAccount:'',
    insuranceId:'',
    insuranceName:''
  };

export const Bons:React.FC=()=>
    {
        const [isTypeBons,setTypeBons] = useState(true);


        const [EditBons, setEditBons] = 
        useState<TypeBonsDtos>(initialTypeBonDto);

        const onCurrantStructure=(mydata:TypeBonsDtos)=>{
            setEditBons(mydata)
        }
        
        return(
            <>
            <Container fluid >
                <Row>
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                    {isTypeBons?
                (
                    <AddTypeBon />
                ):(
      <EditTypeBons
       show={isTypeBons} 
        onHide={() => setTypeBons(true)}

      EditInfo={EditBons} /> 
                )
  }
         <TypeTableBons
          show={isTypeBons} 
           onHide={() => setTypeBons(false)}
         onEditType={onCurrantStructure}
          />
 </Card.Body>

                </Card>
                <br />
                </Col>
               
            </Row>
            
            </Container>
            </>
        );
    }