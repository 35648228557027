import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewFactureLivraisonMedicamentDto from '../../../Components/Espace_Patients/FactureMedicament/models/Dto/NewFactureLivraisonMedicamentDto';

const initialState: NewFactureLivraisonMedicamentDto[] = [];

const currentMedicalLivraisonArrayModifie = createSlice({
  name: 'currentMedicalLivraisonArrayModifie',
  initialState,
  reducers: {  
      addMultipleMedicalLivraisonRecordsModifer: (state, action: PayloadAction<NewFactureLivraisonMedicamentDto[]>) => {
        state.push(...action.payload);
      },
    clearMedicalLivraisonRecordsModifer: () => {
      return initialState;
    },
  },
});

export const {addMultipleMedicalLivraisonRecordsModifer,clearMedicalLivraisonRecordsModifer } = currentMedicalLivraisonArrayModifie.actions;
export default currentMedicalLivraisonArrayModifie.reducer;
