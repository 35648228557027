import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import IdataModelFrequance from '../Models/IdataModelFrequance';
import FrequenceService from '../Services/FrequenceService';

const initialTypeState = {
  id: null,
  typeName: '',
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};

export const AddFrequance:React.FC=()=> {


const [isInput,setInput]=useState<IdataModelFrequance>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const saveTypeDoctors = () => {  

       
        let data = {
          typeName: isInput.typeName,
          createdBy:'haliii ganii',
          dateCreatedBy: new Date()
        };

        FrequenceService.create(52,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            typeName: response.data.typeName,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const resetTypeDoctor = () => {
        setInput(initialTypeState);
      }

     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Add Frequance</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="typeName" name="typeName" value={isInput.typeName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>saveTypeDoctors()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={resetTypeDoctor} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}