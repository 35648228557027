import { useRef, useState } from "react";

import { Card, Col, Container, Row } from "react-bootstrap";
import { AddEnterprise } from "../../../Components/ASSURANCES/Entreprises/EnterpriseOperations/AddEnterprise";
import { EditEnterprise } from "../../../Components/ASSURANCES/Entreprises/EnterpriseOperations/EditEnterprise";
import { TableEnterprise } from "../../../Components/ASSURANCES/Entreprises/Tables/TableEnterprise";
import IdataModeEnterpriseDtos from "../../../Components/ASSURANCES/Entreprises/Models/IdataModeEnterpriseDtos";


const initialTypeStateEnterpriseDtos = {
    ////table socialInsurance ta
    idSociete:0,
    societeName:0,
   //table enterpriseAssurance   ta1
  idInterprise:0,
  accountInterprise:0,
    enterpriseName: '',
    responsable: '',
    email: '',
    phoneNumber: '',
    couvertureDu: '',
    couvertureAau: '',
    address: '',
    autreInformation: '',
    resilier: '',
    dataResiliation: '',
       dateCreatedBy: new Date,
    createdBy: '',
    firstCallForPayment: '',
    secondCallForPayment: '',
    thirdCallForPayment: '',
    modePayment: '',
    frequancePayment: '',
   ////// configuration of assurance start
    primeIndividuelle: '',
    primeTotal: '',
    soinsAmbilatoire: '',
    hospitalisation: '',
    ophtalmologie: '',
    dentisterie: '',
    fraisFuneraires: ''
  };



export const Enterprises =()=>{

    const componentToPrint = useRef(null);


    const [EditList, setEditList] = useState<IdataModeEnterpriseDtos>(initialTypeStateEnterpriseDtos);

    const onCurrentEnterprise = (idataModeEnterprise: IdataModeEnterpriseDtos) => {
        setEditList(idataModeEnterprise);
      };

    const [isSwichLayerEnterprise,setSwichLayerEnterprise] = useState(true);
    
    return(
        <>
        <Container fluid>
 <Row>
                <Col> 
                  <br />
                <Card>
            <Card.Body>
           {isSwichLayerEnterprise?
                (
                    <AddEnterprise />
                ):(
            <EditEnterprise 
            show={isSwichLayerEnterprise}  
            onHide={() => setSwichLayerEnterprise(true)}
            EditInfo={EditList}
            /> 
             
                )
  }
         <TableEnterprise 
         show={isSwichLayerEnterprise}  
         onHide={() => setSwichLayerEnterprise(false)}
         onEdit={onCurrentEnterprise}
         componentToPrint={componentToPrint}
          />
            </Card.Body>
            </Card> 
            <br />
                </Col>
            </Row>
          
        </Container>
        </>
    );
}