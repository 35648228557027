import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Card, Row, Form, InputGroup } from "react-bootstrap";
import IdataModelSejourVisitePlafondDtos from '../Models/IdataModelSejourVisitePlafondDtos';
import IdataModelSejourPlafond from '../Models/IdataModelSejourPlafond';
import ServicePlafondServiseVisites from '../Services/ServicePlafondServiseVisites';


  interface Ieditplafond {
    show: boolean; 
    onHide: () => void; 
    editplafondDtos: IdataModelSejourVisitePlafondDtos;
  }
  const initialTypeState = {
    id: null,
    plafondFacture: '',
    createdBy: 'Ghost Zila',
    dateCreatedBy: new Date()
  };
  export const Editplafond :React.FC<Ieditplafond>=props=> {

    

    const [isInputDtos,setInputDtos]=useState<IdataModelSejourVisitePlafondDtos>(props.editplafondDtos);
    const [isInput,setInput]=useState<IdataModelSejourPlafond>(initialTypeState);



    
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputDtos({ ...isInputDtos, [name]: value });
        }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const Editplafond = (
    e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              plafondFacture: isInputDtos.plafondFacture,
              createdBy:"prof",
              dateCreatedBy: new Date()
            };
    
            ServicePlafondServiseVisites.update(isInputDtos.idplafond, data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                plafondFacture: response.data.plafondFacture,
                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInputDtos);
          }

          const EditCancel = () => {
            props.onHide();
          }
              
          
  useEffect(() => {

    setInputDtos(props.editplafondDtos)

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
            <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-6">
               <Form.Label>Edit plafond{isInputDtos.plafondFacture}</Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="plafondFacture" value={ isInputDtos.plafondFacture} onChange={handleChange} />
               </InputGroup>
           </Form.Group>
            </Row>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={Editplafond} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
