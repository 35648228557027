// currentMedicalSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewFactureSaisieMedicamentDto from '../../../Components/Espace_Patients/FactureMedicament/models/Dto/NewFactureSaisieMedicamentDto';



const initialState:NewFactureSaisieMedicamentDto= {
     /// facture fa
     idFacture:null,
     prestation:null, //itule
     prestationPrescriptPar:null,
     pricePrestation:null,
     plafondFacture:null,
     ticketService:null,
     ticketServiceFacture:null,
     quantity:null,

    partMutuelle:null,
    partpatient:null,
    partTotal:null,

     partMutuelleFacture:null,
     partpatientFacture:null,
     partTotalFacture:null,



     statusFacturePayer:null,
     statusFactureDeclare:null,
     statusFactureVerifier:null,
     statusFactureRembourceAvecAutreFacture:null,
     statusFactureRembourceUneFacture:null,

     createdBy:null,
     dateCreatedSaisie:null,
     timeSaisie:null,
     idRestIncrementPlafondConsomme:null,
     idIncrement:null,
     accordPar:null,
   /// hospital h
     idhospital:null,
     hospitalName:null,
     referenceId:null,
     typehospital:null,
     hospitalAccount:null,

   /// docteur
     idDoctorName:null,
     doctorName:null,
     prescriptPar:null,
     priceConsultation:null,
     accountNameDoctor:null,

   /// typeDoctor
    idtypeDoctor:null,
    typedoctorName:null,
    accountTypeDoctor:null,
/// new visite

    idNewVisite:null,
     receiptBill:null,
     patientName:null,

     typeAssuree:null,
     newVisitecreatedBy:null,
     dateCreatedVisite:null,
     timeCreated:null,


   //social Insuarance so
     idinsurance:null,
     insuranceName:null,
   //////////////////////////////////////////
   /// Enterprise   e
     idEnterprise:null,
     accountEnterprise:null,
     enterpriseName:null,
     couvertureDu:null,
     couvertureAau:null,
     modePayment:null,
     frequancePayment:null,
   /// pmember   p
     idpmember:null,
     codeAssuree:null,
     nomPrenomAffilie:null,
     cotisationExact:null,
     accountPmember:null,
     CatégorieSoins:null,
     category_code:null,

   ///// typeBons Visite  tvi
     idVisite:null,
     typeNameVisite:null,
     accountVisite:null,

   /// typeBon Commend  tc
     idTypeBonCommend:null,
     typeBonCommendName:null,
     accountBonCommend:null,

      // w medimcament
      idListMedicament:null
}

const currentMedicalSlice = createSlice({
  name: 'currentMedical',
  initialState,
  reducers: {                                        
    updatecurrentMedical: (state, action: PayloadAction<NewFactureSaisieMedicamentDto>) => {
        
      Object.assign(state, action.payload);
    },
    clearcurrentMedical: (state) => {
      return initialState;
    },
  },
});

export const { updatecurrentMedical, clearcurrentMedical } = currentMedicalSlice.actions;
export default currentMedicalSlice.reducer;
