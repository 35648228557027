import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import IPlafondYearTypeVisiteDto from "../../Components/ASSURANCES/PlafondYearTypeVisite/Models/PlafondYearTypeVisiteDto";
import { TablePlafondYearTypeVisite } from "../../Components/ASSURANCES/PlafondYearTypeVisite/Tables/TablePlafondYearTypeVisite";
import { EditPlafondYearTypeVisite } from "../../Components/ASSURANCES/PlafondYearTypeVisite/TypeOperations/EditPlafondYearTypeVisite";
import { AddPlafondYearTypeVisite } from "../../Components/ASSURANCES/PlafondYearTypeVisite/TypeOperations/AddPlafondYearTypeVisite";
  
const initialDto = {
     /// plafondYear str
     plafondYearId:'',
     //
     plafondYear:'',
     plafondYearAlert:0,
     
    enfant:0,
    prime:0,
    cotization:0,
    etatCivil:'',
    // insuarence so
     insuranceId:'',
     insuranceName:'',
    /// category c
     categoryName:'',
     categoryId:0,
    //enterprise e
    
    enterpriseId:'',
     enterpriseName:'',
    //typeBon t
    
    typeBonId:'',
     typeName:''
  };

export const PlafondYear:React.FC=()=>
    {
        const [isType,setType] = useState(true);
        const [isEditPlafondYearTypeVisite, setisEditPlafondYearTypeVisite] = 
        useState<IPlafondYearTypeVisiteDto>(initialDto);

        const onCurrantStructure=(mydata:IPlafondYearTypeVisiteDto)=>{
            setisEditPlafondYearTypeVisite(mydata)
        }
        
        return(
            <>
            <Container fluid >
                <Row>
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                    {isType?
                (
                    <AddPlafondYearTypeVisite/>
                ):(
      <EditPlafondYearTypeVisite
       show={isType} 
        onHide={() => setType(true)}

      EditInfo={isEditPlafondYearTypeVisite} /> 
                )
  }
         <TablePlafondYearTypeVisite
          show={isType} 
           onHide={() => setType(false)}
         onEditType={onCurrantStructure}
          />
 </Card.Body>

                </Card>
                <br />
                </Col>
               
            </Row>
            
            </Container>
            </>
        );
    }