import http from "../../../../../AxiosRequest/httpcommon";
import CodeCategoryCountAyandDroitSurPlusEnfant from "../../../../StatistiquesSurAffilies/Models/CodeCategoryCountAyandDroitSurPlusEnfant";
import CodeCategoryCountDto from "../../../../StatistiquesSurAffilies/Models/CodeCategoryCountDto";
import CodeCategoryCountEtatCivilAyantDroitDto from "../../../../StatistiquesSurAffilies/Models/CodeCategoryCountEtatCivilAyantDroitDto";
import CodeCategoryCountEtatCivilDto from "../../../../StatistiquesSurAffilies/Models/CodeCategoryCountEtatCivilDto";
import CodeCategorySoinsCountAyandDroitSurPlusEnfant from "../../../../StatistiquesSurAffilies/Models/CodeCategorySoinsCountAyandDroitSurPlusEnfant";
import CodeCategorySoinsCountDto from "../../../../StatistiquesSurAffilies/Models/CodeCategorySoinsCountDto";
import CodeCategorySoinsCountEtatCivilAyantDroitDto from "../../../../StatistiquesSurAffilies/Models/CodeCategorySoinsCountEtatCivilAyantDroitDto";
import CodeCategorySoinsEtatCivilDto from "../../../../StatistiquesSurAffilies/Models/CodeCategorySoinsEtatCivilDto";
import IdataModelPMember from "../Models/IdataModelPMember";
import IdataModelPMemberDtos from "../Models/IdataModelPMemberDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelPMemberDtos>>("/principalMemberByInterpriseId", { params });
};
const getAllAdherantPrincipal = (params:any) => {
  return http.get<Array<IdataModelPMemberDtos>>("/principalMember", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<IdataModelPMember>>("/sortedprincipalMember");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelPMember>>("/principalMemberAccount");
}
const create = (socialinsuranceId:any,enterprisesAssurance_id:any,data: IdataModelPMember) => {
  return http.post<IdataModelPMember>(`/${socialinsuranceId}/${enterprisesAssurance_id}/principalMember`, data);
};

const update = (id: any, data: IdataModelPMember) => {
  return http.put<any>(`/principalMember/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/principalMember/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/principalMember`);
};

const findBynomPrenomAffilie = (socialinsuranceId:any,enterprisesAssuranceId:any,nomPrenomAffilie: string) => {
  return http.get<Array<IdataModelPMemberDtos>>(`/principalMemberByInterpriseId?socialinsuranceId=${socialinsuranceId}&enterprisesAssuranceId=${enterprisesAssuranceId}&nomPrenomAffilie=${nomPrenomAffilie}`);
};
const findAllPrincipalMember = (socialinsuranceId:any,nomPrenomAffilie: string) => {
  return http.get<Array<IdataModelPMemberDtos>>(`/principalMember?socialinsuranceId=${socialinsuranceId}&nomPrenomAffilie=${nomPrenomAffilie}`);
};
/// statistiquesSurAffilies 

const getCodeCategoryCountAyandDroitSurPlusEnfant = () => {
  return http.get<Array<CodeCategoryCountAyandDroitSurPlusEnfant>>("/findcodeCategoryCountAyandDroitSurPlusEnfant");
};

const getCodeCategoryCountDto = () => {
  return http.get<Array<CodeCategoryCountDto>>("/findCodeCategoryCountDto");
};

const getCodeCategoryCountEtatCivilAyantDroitDto = () => {
  return http.get<Array<CodeCategoryCountEtatCivilAyantDroitDto>>("/findcodeCategoryCountEtatCivilAyantDroit");
};

const getCodeCategoryCountEtatCivilDto = () => {
  return http.get<Array<CodeCategoryCountEtatCivilDto>>("/findCodeCategoryCountEtatCivilDto");
};

const getCodeCategorySoinsCountAyandDroitSurPlusEnfant = () => {
  return http.get<Array<CodeCategorySoinsCountAyandDroitSurPlusEnfant>>("/findcodeCategorySoinsCountAyandDroitSurPlusEnfant");
};

const getCodeCategorySoinsCountDto = () => {
  return http.get<Array<CodeCategorySoinsCountDto>>("/findCodeCategorySoinsCountDto");
};

const getCodeCategorySoinsCountEtatCivilAyantDroitDto = () => {
  return http.get<Array<CodeCategorySoinsCountEtatCivilAyantDroitDto>>("/findcodeCategorySoinsCountEtatCivilAyantDroit");
};

const getCodeCategorySoinsEtatCivilDto = () => {
  return http.get<Array<CodeCategorySoinsEtatCivilDto>>("/findCodeCategorySoinsEtatCivilDto");
};

const ServicePrincipalMember = {

  getCodeCategoryCountAyandDroitSurPlusEnfant,
  getCodeCategoryCountDto,
  getCodeCategoryCountEtatCivilAyantDroitDto,
  getCodeCategoryCountEtatCivilDto,
  getCodeCategorySoinsCountAyandDroitSurPlusEnfant,
  getCodeCategorySoinsCountDto,
  getCodeCategorySoinsCountEtatCivilAyantDroitDto,
  getCodeCategorySoinsEtatCivilDto,
  //
  getAllWithoutPagination,
  getAll,
  getAllAdherantPrincipal,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findBynomPrenomAffilie,
  findAllPrincipalMember
};

export default ServicePrincipalMember;