import http from "../../../../AxiosRequest/httpcommon";
import IdataModelTypeBons from "../Models/IdataModelTypeBons ";
import TypeBonsDtos from "../Models/TypeBonsDtos";

const getAll = (params:any) => {
  return http.get<Array<TypeBonsDtos>>("/TypeBonsDtos", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelTypeBons>>("/sortedTypeBons");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelTypeBons>>("/TypeBonsAccount");
}

const create = (socialinsuranceId:any,data: IdataModelTypeBons) => {
  return http.post<IdataModelTypeBons>(`/socialinsurance/${socialinsuranceId}/TypeBons`, data);
};

const update = (id: any, data: IdataModelTypeBons) => {
  return http.put<any>(`/TypeBons/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/TypeBons/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/TypeBons`);
};

const findByTypeBonsName = (insuranceId:any,typeBonsName: string) => {
  return http.get<Array<TypeBonsDtos>>(`/TypeBonsDtos?insuranceId=${insuranceId}&typeBonsName=${typeBonsName}`);
};
const TypeBonsService = {
  getAllTypeWithoutPagination,
  findByTypeBonsName,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
};

export default TypeBonsService;