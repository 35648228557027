import {  ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import DetailsSoinsTypeService from "../Services/PriceSoinsService";
import { IpriceActesSejour } from "../Interfaces/IpriceActesSejour";
import IdataModelSejourVisiteDtos from "../Models/IdataModelSejourVisiteDtos";
import IdataModelHospitalType from "../../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";


export const PriceSejourVisiteTable:React.FC<IpriceActesSejour>=props=>{
  
    const [priceHopital, setpriceHopital] = useState<Array<IdataModelSejourVisiteDtos>>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchdetails, setSearchdetails] = useState<string>("");
    
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6];
  
    const getRequestParams = (searchtypeName:string, thepage: number, thepageSize: number) => {
      let params = {
        typeName:searchtypeName,
        page: thepage,
        size:thepageSize
      };
    
      if (searchtypeName) {
        params["typeName"] = searchtypeName;
      }
    
      if (thepage) {
        params["page"] = thepage - 1;
      }
      
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
    

    const onChangeSearchdetails = (e: ChangeEvent<HTMLInputElement>) => {
      const searchdetails = e.target.value;
      setSearchdetails(searchdetails);
    };
   
    const findBydetails = () => {
      //setpriceHopital([]);
      DetailsSoinsTypeService.findBytypeName(searchdetails)
        .then((response: any) => {
          const {InOneSejourVisitesDtos } = response.data;
          setpriceHopital(InOneSejourVisitesDtos);
          setCurrentIndex(-1);
          console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    const retrievedetails = () => {
      const params = getRequestParams(searchdetails, page, pageSize);
      // setpriceHopital([]);
      DetailsSoinsTypeService.getAll(params)
    
        .then((response: any) => {
    
          const {InOneSejourVisitesDtos , totalPages} = response.data;
    
          setpriceHopital(InOneSejourVisitesDtos);
          setCount(totalPages);
    
          console.log(response.data);
          console.log(params);
    
        })
    
        .catch((e: Error) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrievedetails();       
    },[page , pageSize]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
    const refreshList = () => {
      retrievedetails();
      setCurrentIndex(-1);
    };
    
    const setActivedetails = (idataModelSejourVisiteDtos: IdataModelSejourVisiteDtos, index: number) => {
      setCurrentIndex(index); 
       props.onEditpriceActe(idataModelSejourVisiteDtos);
       props.onHide();
      console.log(idataModelSejourVisiteDtos);
      console.log(index);
    };
  
    const OnDeletedetails = (id:any) => {
    
      DetailsSoinsTypeService.remove(id)
        .then((response: any) => {
          console.log(response.data);
          refreshList();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

      return(
            <>
            <Row className="mb-3">
          
          <Stack  direction="horizontal" gap={2}>
                    <Form.Control className="me-auto"
                      placeholder="Filtre le soins..."
                      value={searchdetails}
                       onChange={onChangeSearchdetails} 
                      />
                    <Button variant="outline-dark btn-sm"
                     onClick={findBydetails}>Cherchez</Button>
             </Stack>   
            <div className="mt-3">
              
            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
                <Row>
                  <Col>
                  <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />      
            </Col>    
                
          </Row>
              
            </div>
            <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>Sejour/Visite</th>
                <th>code</th>
                <th>price</th>
                <th> hopital</th>
              </tr>
            </thead>
            <tbody>
            {priceHopital.length>0 ? (
              priceHopital.map((i, index) => (
                    <tr key={index }>
                    <td>{index+1}</td>
                    <td>{i.typeName}</td>
                    <td>{i.account}</td>
                    <td>{i.price}</td>
                    <td>{i.hospitalName}</td>
                    <td>
                    <Stack direction="horizontal" gap={2}>
                    <Button className="me-4 btn btn-success btn-sm btn-block"
                    onClick={() => setActivedetails(i, index)}>Edit</Button>
    
                      <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeletedetails(i.idInOneSejourVisitesPrice)}>Delete</Button>

                    </Stack>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={3}>No details</td>
                </tr>
              )}
    
            </tbody>
          </Table>
          </Row>
    
            </>
        );
    }

