// currentNewFactureSaisieSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewFactureSaisieDto from '../../../Components/Espace_Patients/FactureSaisie/models/Dto/NewFactureSaisieDto';



const initialState:NewFactureSaisieDto= {
    
    /// facture fa
    idFacture:null,
    prestation:null, //itule
    prestationPrescriptPar:null,
    pricePrestation:null,
    plafondFacture:null,
    ticketService:null,
    ticketServiceFacture:null,

    quantity:null,
    partMutuelleFacture:null,
    partpatientFacture:null,
    partTotalFacture:null,

    partMutuelle:null,
    partpatient:null,
    partTotal:null,

    statusFacturePayer:null,

 statusFactureDeclare:null,
 statusFactureVerifier:null,
 statusFactureRembourceAvecAutreFacture:null,
 statusFactureRembourceUneFacture:null,

    createdBy:null,
    dateCreatedSaisie:null,
    timeSaisie:null,
    idRestIncrementPlafondConsomme:null,
   /// hospital h
    idhospital:null,
    hospitalName:null,
    referenceId:null,
    typehospital:null,
    hospitalAccount:null,

   /// docteur
    idDoctorName:null,
    doctorName:null,
    
prescriptPar:null,
     priceConsultation:null,
    accountNameDoctor:null,

   /// typeDoctor
   idtypeDoctor:null,
   typedoctorName:null,
   accountTypeDoctor:null,
   /// couvertureConfig  se
   idCouvertureConfig:null,
    ticket:null,
    particulie:null,
    access:null,
    secter:null,
    plafondOrdonence:null,

    // services ser
    idService:null,
    serviceName:null,
   // code_category co

   idCategoryCode:null,
   categoryCode:null,
   

/// new visite
    idNewVisite:null,
    receiptBill:null,
    patientName:null,
    typeAssuree:null,
   newVisitecreatedBy:null,
    dateCreatedVisite:null,
    timeCreated:null,

   //social Insuarance so
    idinsurance:null,
    insuranceName:null,
   //////////////////////////////////////////
   /// Enterprise   e
    idEnterprise:null,
    accountEnterprise:null,
    enterpriseName:null,
    couvertureDu:null,
    couvertureAau:null,
    modePayment:null,
    frequancePayment:null,
   /// pmember   p
    idpmember:null,
    codeAssuree:null,
    nomPrenomAffilie:null,
    cotisationExact:null,
    accountPmember:null,
    catégorieSoins:null,
    category_code:null,
   ///// typeBons Visite  tvi
    idVisite:null,
    typeNameVisite:null,
    accountVisite:null,

   ///// typeBon declaration verifivcation rembourcement b
    idtypeBonverification:null,
    typeBonVerificationName:null,
    accountVerificationName:null,
   /// typeBon Commend  tc
    idTypeBonCommend:null,
    typeBonCommendName:null,
    accountBonCommend:null,
   /// depertment dp
    idDepertment:null,
    depertmentName:null,
/// plafondYear pla
plafondYearId:null,
plafondYear:null,
/// category c
categoryId:null,
categoryName:null
}

const currentNewFactureSaisieSlice = createSlice({
  name: 'currentNewFactureSaisie',
  initialState,
  reducers: {
    updateCurrentNewFactureSaisie: (state, action: PayloadAction<NewFactureSaisieDto>) => {
        
      Object.assign(state, action.payload);
    },
    clearCurrentNewFactureSaisie: (state) => {
      return initialState;
    },
  },
});

export const { updateCurrentNewFactureSaisie, clearCurrentNewFactureSaisie } = currentNewFactureSaisieSlice.actions;
export default currentNewFactureSaisieSlice.reducer;
