
import { ChangeEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import {Form, InputGroup, Row } from "react-bootstrap";
import NewFactureSaisieDto from "../models/Dto/NewFactureSaisieDto";
import IdataModelTypeDepertment from "../../../HopitalDepertment/Models/IdataModelTypeDepertment";
import IdataModelService from "../../../ServicesData/Models/IdataModelService";
import NewFactureSaisie from "../models/NewFactureSaisie";
import IdataModelCouvertureDtos from "../../../ASSURANCES/Configurations/Couvertures/Models/IdataModelCouvertureDtos";
import ServiceDepartment from "../../../HopitalDepertment/Services/ServiceDepartment";
import ServiceHopital from "../../../ServicesData/Services/ServiceHopital";
import ServicesNewFactureSaisie from "../services/ServiceNewFactureSaisie";
import CouvertureconfigService from "../../../ASSURANCES/Configurations/Couvertures/Services/CouvertureconfigService";
import IdataModelPriceActesDtos from '../../../Acts/PriceActes/Models/IdataModelPriceActesDtos';
import DoctorService from "../../../Doctor/Names/Services/NamDoctorServices";
import IdataModelDoctor from "../../../Doctor/Names/Models/IdataModelDoctor";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import HospitalTypeService from "../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import { updateDetailsHospital } from "../../../../Redux/slices/hospitalDetails/hospitalDetails";
import { updateFactureMutuelle } from "../../../../Redux/slices/FactureMutuelleDetails/FactureMutuelleDetails";
import { updateFactureSansMutuelle } from "../../../../Redux/slices/FactureSansMutuelleDetails/FactureSansMutuelleDetails";
import IdataModelCounterRestPlafond from "../../../ASSURANCES/CounterRestPlafond/Models/CounterRestPlafond";
import RestPlafondYearService from "../../../ASSURANCES/CounterRestPlafond/Services/RestPlafondYearService";
import { updateConfigAddFactureSaisiePlafond } from "../../../../Redux/slices/ConfigAddFactureSaisie/ConfigAddFactureSaisiePlafond";
import { updateConfigAddFactureSaisieDepertment } from "../../../../Redux/slices/ConfigAddFactureSaisie/ConfigAddFactureSaisieDepertment";
import { updateConfigAddFactureSaisiePrescriptPar } from "../../../../Redux/slices/ConfigAddFactureSaisie/ConfigAddFactureSaisiePrescriptPar";


const initialCouvertureConfig={
    /// c
    idCouvertureConfig:0,
    ticket:'',
    ticketFacture:'',
    particulie:'',
    access:'',
    secter:'',
    plafondOrdonence:'',
    createdBy:'',
    dateCreated:'',
    // services ser
    idService:'',
    serviceName:'',
   // code_category co

   idCategoryCode:'',
   categoryCode:'',
   
   /// enterprise e
    idEnterprise:0,
    enterpriseName:'',
   //// insurance i
    idInsurance:0,
    insuranceName:''
     };

      const newFactureSaisie={
        prestation:'',

  prestationPrescriptPar:'',
        pricePrestation:0,
        plafondFacture:0,
        ticketService:0,
        quantity:1,
        partMutuelle:0,
        partpatient:0,
        partTotal:0,
        statusFacturePayer:'No payer',
        createdBy:'Ramadhani Ally',
        dateCreatedSaisie:'',
        timeSaisie:'',

     partMutuelleFacture:0,
     partpatientFacture:0,
     partTotalFacture:0,
     
     ticketServiceFacture:0,

     statusFactureDeclare:'',
     statusFactureVerifier:'',
     statusFactureRembourceAvecAutreFacture:'',
     statusFactureRembourceUneFacture:'',
     idRestIncrementPlafondConsomme:''
      } 
      
   
 const IdataModelPriceActesDtosInit= {
    categoryId:'',
   typePrestationsName:'',
   categoryName:'',
   accountCategoryType:'',
   structureNane:'',
   structureId:'',
   price:'',
   idPrice:'',
   categoryTypeId:'',
    typeSoinsName:'',
    accountCategory:'',

    detailsSoinsId:'',
   accountTypeDetailsSoins:'',
    detailsSoinsName:''
}

const InitRestPlafond={
  codeId:'',
  numeroBon:0,
  partPatientConsomme:0,
  idRestIncrementPlafondConsomme:0,
  status:0,
  created_by:'Ramadhani Ally',
  date_created_by: '',
}
  const defaultservice: Array<IdataModelService> = [];
  const defaultdepertment:Array<IdataModelTypeDepertment>=[];


export const AddFactureSaisieConfig:React.FC=()=>{


  const dispatchPlafond = useDispatch();

  const dispatchDepertmt = useDispatch();

  const dispatchPrescrptPar = useDispatch();

  const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);

     
     const [service, setservice] = useState(defaultservice);
    
     const [depertment, setdepertment] = useState(defaultdepertment);
     const [DoctorName, setDoctorName] = useState<Array<IdataModelDoctor>>([]);

  const [ConfigId, setConfigId] = useState(0);
  const [ConfigPlafondFacture, setConfigPlafondFacture] = useState(0);

  const [isServiceConfig,setServiceConfig]=useState<IdataModelCouvertureDtos>(initialCouvertureConfig);

  
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      

    const handleChangeConfig = (e: { target: { name: any; value: any; }; }) => {
        dispatchPlafond(updateConfigAddFactureSaisiePlafond(e.target.value))
      }
      const getServices = useCallback(() => {
        ServiceHopital.getAllTypeWithoutPagination()
          .then((response: any) => {
            setservice(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }, [setservice]);

      const getDepertment = useCallback(() => {
        ServiceDepartment.getAllTypeWithoutPagination()
          .then((response: any) => {
            setdepertment(response.data);
            // setdepertmentFacture(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }, [setdepertment]);

    
const getDoctorName = useCallback(() => {
  DoctorService.getDoctorNameWithoutPagination()
    .then((response: any) => {
      setDoctorName(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
}, [setDoctorName]);

    const handleChangeDepertment = (e: { target: { name: any; value: any; }; }) => {
           dispatchDepertmt(updateConfigAddFactureSaisieDepertment(e.target.value))
   //        console.log('')
      };

      const handleChangeDoctor = (event: { target: { value: SetStateAction<string>; }; }) => {
        dispatchPrescrptPar(updateConfigAddFactureSaisiePrescriptPar(event.target.value))
       // console.log('concatDoctor',event.target.value)
   };
      
    useEffect(() => {
        getDepertment();
        getDoctorName();
        getServices();
    },[getDepertment, getDoctorName, getServices]);
     
    return (
      <>
        <form className="form-edit" onSubmit={onFormSubmit}>
          <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-3">
              <Form.Label>Plafond</Form.Label>
              <Form.Select className="form-control" size="sm" onChange={handleChangeConfig}>
                <option value="0">Choose..</option>
                {service &&
                  service.map((service) => (
                    <option key={service.id} value={service.id}>{service.typeName}</option>
                  ))}
              </Form.Select>
            </Form.Group>
    
            <Form.Group controlId="formBasicEmail" className="col col-sm-3">
              <Form.Label>Depertment</Form.Label>
              <Form.Select className="form-control" size="sm" onChange={handleChangeDepertment}>
                <option value="0">Choose..</option>
                {depertment &&
                  depertment.map((depertment) => (
                    <option key={depertment.id} value={depertment.id}>{depertment.typeName}</option>
                  ))}
              </Form.Select>
            </Form.Group>
    
            <Form.Group controlId="formBasicEmail" className="col col-sm-3">
              <Form.Label>Prescript par</Form.Label>
              <Form.Select className="form-control" size="sm" onChange={handleChangeDoctor}>
                <option value="0">{isgetselecterCurrantNewVisitePushed.prescriptPar}</option>
                {DoctorName &&
                  DoctorName.map((doctorName) => (
                    <option key={doctorName.id} value={doctorName.prescriptPar}>{doctorName.doctorName}</option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Row>
        </form>
      </>
    );
    
}