import http from "../../../../../AxiosRequest/httpcommon";
import CotisationAssuranceDetails from "../Models/CotisationAssuranceDetails";
import { CotisationAssuranceDetailsDto } from "../Models/CotisationAssuranceeDetailsDto";

const getAll = (params:any) => {
  return http.get<Array<CotisationAssuranceDetailsDto>>("/cotisationAssuranceDetails", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<CotisationAssuranceDetails>>("/sortedcotisationAssuranceDetails");
};
const getLastAccountType=()=>{
  return http.get<Array<CotisationAssuranceDetails>>("/cotisationAssuranceAccountEnterpriseName");
}

const create = (cotisationAssurance_id:any,data: CotisationAssuranceDetails) => {
  return http.post<CotisationAssuranceDetails>(`/${cotisationAssurance_id}/cotisationAssuranceDetails`, data);
};

const update = (id: any, data: CotisationAssuranceDetails) => {
  return http.put<any>(`/cotisationAssuranceDetails/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/cotisationAssuranceDetails/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/cotisationAssuranceDetails`);
};

const findByEnterpriseName = (socialinsuranceId:any,enterpriseName: string) => {
  return http.get<Array<CotisationAssuranceDetailsDto>>(`/cotisationAssuranceDetails?socialinsuranceId=${socialinsuranceId}&enterpriseName=${enterpriseName}`);
};

const ServiceCotisationAssuranceDetails = {
  getAllWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByEnterpriseName,
};

export default ServiceCotisationAssuranceDetails;