import IdataModeEnterprise from "../Models/IdataModeEnterprise";
import http from "../../../../AxiosRequest/httpcommon";
import IdataModeEnterpriseDtos from "../Models/IdataModeEnterpriseDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModeEnterpriseDtos>>("/enterprisesAssurance", { params });
};

const getAllEnterpriseNameWithoutPagination = () => {
  return http.get<Array<IdataModeEnterprise>>("/sortedenterprisesAssurance");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModeEnterprise>>("/enterprisesAssuranceAccount");
}

const create = (socialinsuranceId:any,data: IdataModeEnterprise) => {
  return http.post<IdataModeEnterprise>(`/socialinsurance/${socialinsuranceId}/enterprisesAssurance`, data);
};

const update = (id: any, data: IdataModeEnterprise) => {
  return http.put<any>(`/enterprisesAssurance/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/enterprisesAssurance/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/enterprisesAssurance`);
};

const findByEnterpriseName = (socialinsuranceId:any,enterpriseName: string) => {
  return http.get<Array<IdataModeEnterpriseDtos>>(`/enterprisesAssurance?socialinsuranceId=${socialinsuranceId}&enterpriseName=${enterpriseName}`);
};

const EnterpriseService = {
  getAllEnterpriseNameWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByEnterpriseName,
};

export default EnterpriseService;