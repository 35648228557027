import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { IdoctorConsultation } from '../Interfaces/IdoctorConsultation';
import { useState, ChangeEvent, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import DoctorService from "../Services/NamDoctorServices";
import IdataModeConsultationDtos from "../Models/IdataModelConsultationDtos";

export const ConsultationDoctor:React.FC<IdoctorConsultation>=props=>{
      
  
const [DoctorName, setDoctorName] = useState<Array<IdataModeConsultationDtos>>([]);

const [currentIndex, setCurrentIndex] = useState<number>(-1);

const [searchDoctor, setSearchDoctor] = useState<string>("");

const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];

const getRequestParams = (searchdoctorName:string, thepage: number, thepageSize: number) => {
  let params = {
    doctorName:searchdoctorName,
    page: thepage,
    size:thepageSize
  };

  if (searchdoctorName) {
    params["doctorName"] = searchdoctorName;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};

const onChangeSearchDoctor = (e: ChangeEvent<HTMLInputElement>) => {
  const searchDoctor = e.target.value;
  setSearchDoctor(searchDoctor);
};

const findByDoctor = () => {
  DoctorService.findByDoctor(searchDoctor)
    .then((response: any) => {
      const {consultationDoctor } = response.data;
      setDoctorName(consultationDoctor);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
const retrieveDoctor = () => {
  const params = getRequestParams(searchDoctor, page, pageSize);

  DoctorService.getAll(params)

    .then((response: any) => {

      const {consultationDoctor , totalPages} = response.data;

      setDoctorName(consultationDoctor);
      setCount(totalPages);

      console.log(params);

      
    })
    .catch((e: Error) => {
      console.log(e);
    });

};
useEffect(() => {
  retrieveDoctor();
},[page , pageSize]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };

const refreshList = () => {
  retrieveDoctor();
  setCurrentIndex(-1);
};

const setActiveDoctor = (dataDoctor: IdataModeConsultationDtos, index: number) => {
   setCurrentIndex(index); 
   props.OnEditNameDoctor(dataDoctor);
   props.onHide();
   console.log(dataDoctor);
   console.log(index);
};

const OnDeleteDoctor = (id:any) => {

  DoctorService.remove(id)
    .then((response: any) => {
      console.log(response.data);
      refreshList();
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

  return(
        <>
        <Row className="mb-3">
      
      <Stack  direction="horizontal" gap={2}>
                <Form.Control className="me-auto"
                  placeholder="Filtre le type docteur..."
                  value={searchDoctor}
                   onChange={onChangeSearchDoctor} 
                  />
                <Button variant="outline-dark btn-sm"
                 onClick={findByDoctor}>Cherchez</Button>
         </Stack>   
        <div className="mt-3">
          
        {"Items per Page: "}
            <select onChange={handlePageSizeChange} value={pageSize}>
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          <Pagination
            className="my-3"
            count={count}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
        <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>id</th>
            <th>Docteur</th>
            <th>Specialité</th>
            <th>Prix </th>
            <th>Hopital</th>
            <th>Compte </th>
           
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {DoctorName.length>0 ? (
          DoctorName.map((i, index) => (
                <tr key={i.doctorNameId }>
                <td>{i.doctorNameId}</td>
                <td>{i.doctorName}</td>
                <td>{i.typeName}</td>
                <td>{i.priceConsultation}</td>
                <td>{i.hospitalName}</td>
                <td>{i.accountNameDoctor}</td>
               
                <td>
                <Stack direction="horizontal" gap={0}>
                <Button className="me-4 btn btn-success btn-sm btn-block"
                onClick={() => setActiveDoctor(i, index)}>Consultation</Button>
                <Button className="me-4 btn btn-success btn-sm btn-block"
                onClick={() => setActiveDoctor(i, index)}>Edit</Button>

                  <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteDoctor(i.doctorNameId)}>Delete</Button>
                  
                </Stack>
                </td>
              </tr>
            ))
          ) : ( 
            <tr>
              <td colSpan={3}>no Type Doctors</td>
            </tr>
          )}

        </tbody>
      </Table>
      </Row>

        </>
    );
}