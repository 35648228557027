import http from "../../../../AxiosRequest/httpcommon";
import IdataModeConsultationDtos from "../Models/IdataModelConsultationDtos";
import IdataModelDoctor from "../Models/IdataModelDoctor";

const getAll = (params:any) => {
  
  return http.get<Array<IdataModeConsultationDtos>>("/ConsultationStructurePage", { params });
};
const getAllByStructure = (params:any) => {
  return http.get<Array<IdataModeConsultationDtos>>("/ConsultationStructurePageDoctor", { params });
};
const getDoctorNameWithoutPagination=()=>{
  return http.get<Array<IdataModelDoctor>>("/sortedNameDoctors");
}
const getLastAccountType=()=>{
  return http.get<Array<IdataModelDoctor>>("/typeDoctorsAccountNameDoctor");
}

const create = (typeDoctorsId: any,hospitalId: any, data: IdataModelDoctor) => {

  return http.post<IdataModelDoctor>(`/typeDoctors/${typeDoctorsId}/${hospitalId}/nameDoctors`,data);

};

const updateAll = (id: any,typeDoctorsId:any,hospitalId:any, data: IdataModelDoctor) => {

  return http.put<any>(`/hospital/nameDoctors/${id}/${typeDoctorsId}/${hospitalId}`,data);

};
const update = (id: any, data: IdataModelDoctor) => {

  return http.put<any>(`/nameDoctors/${id}`,data);

};

const remove = (id: any) => {
  return http.delete<any>(`/nameDoctors/${id}`);
};

const removeAll = (typeDoctorsId: any) => {
  return http.delete<any>(`/typeDoctors/${typeDoctorsId}/nameDoctors`);
};

const findByDoctor = (doctorName: string) => {
  return http.get<Array<IdataModeConsultationDtos> >(`/ConsultationStructurePage?doctorName=${doctorName}`);
};
const findByDoctorAndHospitalName = (doctorName: string,hospitalName:string) => {
  return http.get<Array<IdataModeConsultationDtos> >(`/ConsultationStructurePageDoctor?doctorName=${doctorName}&hospitalName=${hospitalName}`);
};

const DoctorService = {
  getAll,
  getAllByStructure,
  getDoctorNameWithoutPagination,
  getLastAccountType,
  create,
  update,
  updateAll,
  remove,
  removeAll,
  findByDoctor,
  findByDoctorAndHospitalName
};

export default DoctorService;