import { ChangeEvent, useEffect, useState } from "react";
import IdataModelHospitalType from "../Models/IdataModeHospitalType";
import { Accordion, Form, InputGroup, Row } from "react-bootstrap";
import HospitalTypeService from "../Services/HospitalTypeService";
import IdataModelCategoriesAcessSoins from "../../../Categories/CategorieSoinsHopitaux/Models/IdataModelCategoriesAcessSoins";
import CategoriesAcessSoinsServices from "../../../Categories/CategorieSoinsHopitaux/Services/CategoriesAcessSoinsServices";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/Store/store";


let accountIncrement=0
let acessSoinsCategoryId=0

const initialHospitalType = {
  id: null,
  hospitalName: '',
  typehospital: '',
  raisonSociale:'',
    tel:'',
    nif:'',
    rc:'',
    bp:'',
    avenue:'',
    centreFiscal:'',
    secteurActivite:'',
    formeJuridique:'',
    tva:'',
  referenceId:0,
  totalBonsIncremental:0,
  hospitalAccount:accountIncrement,
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
const defaultinitiaCategories: Array<IdataModelCategoriesAcessSoins> = [];
export const HospitalType:React.FC=()=> {


const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);

const [acessSoinsCategory, setAcessSoinsCategory] = useState(defaultinitiaCategories);
const [isreferenceId,setreferenceId]=useState(0)
const getLastAccountType =()=>{
  HospitalTypeService.getLastAccountType()
    .then((response: any) => {
        accountIncrement=response.data.hospitalAccount
        console.log(accountIncrement);
  })
}
const getLastReferenceId =()=>{
  HospitalTypeService.getLastReferenceId()
    .then((response: any) => {
      if(response.data=undefined){
        setreferenceId(1);
      }
      if(response.data!=undefined){
        setreferenceId(response.data.referenceId)
      }
  })
}

const CategoryAccessSoinsWithoutPagination = () => {
  
  CategoriesAcessSoinsServices.getCategoryAccessSoinsWithoutPagination()
    .then((response: any) => {
      setAcessSoinsCategory(response.data);
     // console.log(speciality);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
useEffect(() => {
  getLastReferenceId();
  getLastAccountType();
  CategoryAccessSoinsWithoutPagination();
},);



const [isInput,setInput]=useState<IdataModelHospitalType>(initialHospitalType);
const [selectedStructure, setSelectedStructure] = useState('');
const handleChangeStructure = (e: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedValue = e.target.value;
 
  setSelectedStructure(selectedValue);
};

const handleChange = (e: { target: { name: any; value: any; }; }) => {
   setInput({ ...isInput, [e.target.name]: e.target.value });
}
const handleChangeAcessSoinsCategoris = (e: { target: {value: any; }; }) => {
  acessSoinsCategoryId=e.target.value
   console.log('AcessSoinsCategoris  ',acessSoinsCategoryId);

    }
   
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        if(accountIncrement===undefined){
          accountIncrement=4000000
        }
       
        let data = {
          hospitalName: selectedStructure,
          typehospital:isInput.typehospital,
          hospitalAccount: accountIncrement+1,
          raisonSociale:isInput.raisonSociale,
          tel:isInput.tel,
          nif:isInput.nif,
          rc:isInput.rc,
          bp:isInput.bp,
          avenue:isInput.avenue,
          centreFiscal:isInput.centreFiscal,
          secteurActivite:isInput.secteurActivite,
          formeJuridique:isInput.formeJuridique,
          tva:isInput.tva,
          referenceId:isreferenceId+1,
          createdBy:'ghost zila ',
          dateCreatedBy: new Date()
        };

        HospitalTypeService.create(acessSoinsCategoryId,52, data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            hospitalName: response.data.hospitalName,

            raisonSociale:response.data.raisonSociale,
            tel:response.data.tel,
            nif:response.data.nif,
            rc:response.data.rc,
            bp:response.data.bp,
            avenue:response.data.avenue,
            centreFiscal:response.data.centreFiscal,
            secteurActivite:response.data.secteurActivite,
            formeJuridique:response.data.formeJuridique,
            tva:response.data.tva,

            typehospital: response.data.typehospital,
            hospitalAccount:response.data.account,
            referenceId:response.data.referenceId,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialHospitalType);
          setAcessSoinsCategory(defaultinitiaCategories)

        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialHospitalType);
      }

     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
        
         <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>configuration de hopital </Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
         <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
      <Form.Label>Structrures</Form.Label>
      <Form.Select
        className="form-control"
        name="typehospital"
        value={selectedStructure}
        onChange={handleChangeStructure}
      >
        <option value="Choose...">Choose...</option>
        {StructuresData.map((group, index) => (
          <option key={index} value={group}>
            {group}
          </option>
        ))}
      </Form.Select>
        </Form.Group>
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <Form.Label>Type Hospital</Form.Label>
            <Form.Select className="form-control" name="typehospital" value={isInput.typehospital}
             onChange={handleChange}>
                <option value="Choose...">Choose...</option>
                <option value="private">Private</option>
                <option value="public">Public</option>
                <option value="home">Home</option>
            </Form.Select>
        </Form.Group>
         </Row>
         <Row className="mb-3">
         <Form.Group controlId="formGridCheckbox" className="col col-sm-14">
          <Form.Label>Categories Autorise</Form.Label>
    <Form.Select className="form-control" 
        onChange={handleChangeAcessSoinsCategoris}>
        <option value="0">Choose..</option>
      {acessSoinsCategory &&
         acessSoinsCategory.map((acessSoinsCategory) => (
            <option key={acessSoinsCategory.id} 
            value={acessSoinsCategory.id} >
              {acessSoinsCategory.categorySoinsName}</option>
))}
    
</Form.Select>
</Form.Group>
         </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Configuration sur Facture</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Raison social</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="raisonSociale" value={isInput.raisonSociale} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Tel</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="tel" value={isInput.tel} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Nif</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="number" name="nif" value={isInput.nif} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>RC</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="rc" value={isInput.rc} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>BP</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="bp" value={isInput.bp} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Avenue</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="avenue" value={isInput.avenue} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Centre Fiscal</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="centreFiscal" value={isInput.centreFiscal} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Secteur d'Activite</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="secteurActivite" value={isInput.secteurActivite} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Forme juridique</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="formeJuridique" value={isInput.formeJuridique} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
 <Form.Label>TVA</Form.Label>
    <Form.Select  size="sm" name="tva" value={isInput.tva} onChange={handleChange}>
        <option>Choisir </option>
       <option value="No">No</option>
     <option value="Oui">Oui</option>
</Form.Select>
</Form.Group>
</Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    
    <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}