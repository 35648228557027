import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Doctor } from "../../../Components/Espace_Patients/ConsultationDoctor/DoctorTable/Doctor";
import IdataModeConsultationDtos from "../../../Components/Doctor/Names/Models/IdataModelConsultationDtos";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaUserPlus } from "react-icons/fa";
import { ModalAddDoctorUserPage } from "../../../Components/ModalAddDoctorUserPage/ModalAddDoctorUserPage";


const initialNamDoctorDtos = {
  accountNameDoctor:'',
   accountTypeDoctor:'',
   doctorName:'',
   
   prescriptPar:'',
   doctorNameId:'',
   doctorTypeId:'',
   hospitalId:'',
   typehospital:'',
   hospitalAccount:'',
   referenceId:'',
   categorySoinsId:'',
   categorySoinsName:'',
   hospitalName:'',
   priceConsultation:'',
   typeName:''
    };

export const FacturationPatient=()=>{

  const [pushedDoctors, setpushedDoctors] = useState<IdataModeConsultationDtos>(initialNamDoctorDtos);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onCurrantDoctor=(Data:IdataModeConsultationDtos)=>{
      setpushedDoctors(Data)
  }
    return (
        
      <>
      <Container fluid>
      <Card >
          <Card.Header><strong>Creation de Numero de Bon </strong></Card.Header>
          <Card.Body>
          
           <Doctor
            onClick={handleShow}
            onSearchDoctor={onCurrantDoctor}/>
           <ModalAddDoctorUserPage show={show} handleClose={handleClose} />
          </Card.Body>
        </Card>
      
      </Container>
  
      </>
      
);
}