import http from "../../../AxiosRequest/httpcommon";
import IdataModelPriceMateriel from "../Models/IdataModelPriceMateriel";
import IdataModelPriceMaterielDtos from "../Models/IdataModelPriceMaterielDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelPriceMaterielDtos>>("/AllMateriellesDtosPage", { params });
};

const getAllMaterielTariffNameWithoutPagination = () => {
  return http.get<Array<IdataModelPriceMateriel>>("/sortedMaterielTariff");
};

const create = (detailsSoinsId:any,hospitalId:any,data: IdataModelPriceMateriel) => {
  return http.post<IdataModelPriceMateriel>(`/${detailsSoinsId}/${hospitalId}/MaterielTariff`, data);
};

const update = (id:any, data: IdataModelPriceMateriel) => {
  return http.put<any>(`/MaterielTariff/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/MaterielTariff/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/MaterielTariff`);
};

const findByMaterielName = (materielName: string) => {
  return http.get<Array<IdataModelPriceMaterielDtos>>(`/AllMateriellesDtosPage?materielName=${materielName}`);
};

const PriceMaterialService = {
  getAllMaterielTariffNameWithoutPagination,
  getAll,
  create,
  update,
  remove,
  removeAll,
  findByMaterielName,
};

export default PriceMaterialService;
