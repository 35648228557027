import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import IdataModelPriceActes from '../Models/IdataModelPriceActes';
import PriceSoinsService from '../Services/PriceSoinsService';
import IdataModeDetailsTypeDtos from '../../Details/Models/IdataModeDetailsTypeDtos';


const initialTypeState = {
  id: null,
  price: '',
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
interface ICurrantEditData {
  show: boolean; 
  hospitalid:any;
  onHide: () => void; 
  editcurrantacts: IdataModeDetailsTypeDtos;
}
export const AddPrice:React.FC<ICurrantEditData>=props=> {

const [isInput,setInput]=useState<IdataModelPriceActes>(initialTypeState);
const [isInputDtos,setInputDtos]=useState<IdataModeDetailsTypeDtos>(props.editcurrantacts);


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }

      const handleChangeDtos = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputDtos({ ...isInputDtos, [name]: value });
        }

        useEffect(() => {
          setInputDtos(props.editcurrantacts)
            },[props.editcurrantacts])
            
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        let data = {
          price: isInput.price,
          createdBy:'ghost zila',
          dateCreatedBy: new Date()
        };

        PriceSoinsService.create(isInputDtos.idSoinsDetails,props.hospitalid,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            price: response.data.price,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }

     
    return(
        <>
        
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
               <Form.Label>Sorte de soins {props.hospitalid}</Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="detailsSoinsName" name="detailsSoinsName"
                    value={ isInputDtos.intitule} 
                    disabled={true}
                   onChange={handleChangeDtos} />
               </InputGroup>
           </Form.Group>
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Prix</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="price" name="price" value={isInput.price} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}