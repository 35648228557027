import { useState, ChangeEvent, useEffect } from 'react';
import { Row, Form, InputGroup } from "react-bootstrap";
import IdataModelListMedicament from '../Models/ListMedicament';
import { RootState } from '../../../Redux/Store/store';
import { useSelector } from 'react-redux';
import ServiceMedicament from '../Services/ServiceMedicament';

  interface IEditType {
    show: boolean; 
    onHide: () => void; 
    EditInfo: IdataModelListMedicament;
  }

  export const EditMedicament :React.FC<IEditType>=props=> {

    
const initialTypeState = {
  code: 0,
       typeName: '',
       excl: '',  //ouii or no
       acc: '',  // oui or no
       createby: '',
       dateCreatedBy: '',
};


    const [isInput,setInput]=useState<IdataModelListMedicament>(props.EditInfo);

    const preferredName = useSelector((state: RootState) => state.user.preferred_username);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInput({ ...isInput, [name]: value });
       // console.log(event.target)
        }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
   
          function getCurrentDate(): string {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            // Months are zero-based, so we add 1 to get the correct month
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = currentDate.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
          }       
          
   const EditType = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            
        let currentDateFormat = getCurrentDate();
        let myCode=0;
        myCode=Number()
            let data = {
              typeName: isInput.typeName,
              code: isInput.code,
              excl:isInput.excl,
              acc:isInput.acc,
              createby:preferredName,
              dateCreatedBy: currentDateFormat
            };
    
            ServiceMedicament.update(isInput.id, data)
            .then((response: any) => {
              setInput({
                typeName: response.data.typeName,
                code: response.data.code,
                excl:response.data.excl,
                acc:response.data.acc,
                createby:response.data.preferredName,
                dateCreatedBy: response.data.dateCreatedBy
              });
                 console.log(response.data);
              console.log(data);
              
              EditCancel();
                  })

                  
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
          
  useEffect(() => {

    setInput(props.EditInfo)

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
        <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Medicament </Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-label="Recipient's username" aria-describedby="basic-addon2" type="typeName" name="typeName" value={isInput.typeName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

         <Form.Group controlId="formBasicGender" className="col col-sm-3">
                <Form.Label>Non Couvert</Form.Label>
                <InputGroup>
                    <Form.Control 
                        size="sm" 
                        as="select" 
                        aria-label="Gender selection" 
                        name="excl" 
                        value={isInput.excl} 
                        onChange={handleChange}
                    >
                        <option value="">Select </option>
                        <option value="oui">OUI</option>
                        <option value="no">No</option>
                    </Form.Control>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="formBasicGender" className="col col-sm-3">
                <Form.Label>Couvert</Form.Label>
                <InputGroup>
                    <Form.Control 
                        size="sm" 
                        as="select" 
                        aria-label="Gender selection" 
                        name="acc" 
                        value={isInput.acc} 
                        onChange={handleChange}
                    >
                       <option value="">Select </option>
                        <option value="oui">OUI</option>
                        <option value="no">No</option>
                    </Form.Control>
                </InputGroup>
            </Form.Group>
         </Row>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditType} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
