/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {Button, Stack } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import NewFactureSaisieMedicamentDto from "../models/Dto/NewFactureSaisieMedicamentDto";
import ServiceNewFactureSaisieMedicament from "../services/ServiceNewFactureSaisieMedicament";
import { updatecurrentMedical } from "../../../../Redux/slices/currentMedical/currentMedical";



  
export const TableFactureMedicamentPayer:React.FC=()=>

{
  
 const [isNewFactureSaisieDto, setisNewFactureSaisieDto] = useState<Array<NewFactureSaisieMedicamentDto>>([]);

const DispatchPushCarrentMedecine=useDispatch();
const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);



  const findAllFactureByInsuarenceAndReceipt = async () => {
    try {
        ServiceNewFactureSaisieMedicament.findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer(
        isgetselecterCurrantNewVisitePushed.idinsurance,
        isgetselecterCurrantNewVisitePushed.receiptBill,
        "No payer",

      ).then((response:any)=>{
        const facture = response.data;
        console.log('response',response)
      setisNewFactureSaisieDto(facture);
      })
      
    } catch (e) {
      console.log(e);
    }
  };
  
  const handleRowButtonDelete = (row: NewFactureSaisieMedicamentDto) => {

    if(row.idIncrement!==0){

    DeleteFactures(row.idFacture);
    DeleteFactures(row.idIncrement);
    }else { 
    DeleteFactures(row.idFacture);
    }
      console.log("you need to choose type de medicament Delete");
  };

  const handleRowButtonPush = (row: NewFactureSaisieMedicamentDto) => {
    DispatchPushCarrentMedecine(updatecurrentMedical(row))
      console.log("you need to choose type de medicament Push");
    
  };

const DeleteFactures = async (id:any) => {
   
  try {
      const response = await ServiceNewFactureSaisieMedicament.remove(id);
      findAllFactureByInsuarenceAndReceipt()
       console.log(response);
    } catch (e) {
      console.log(e);
    }
  
};

  const columns: GridColDef[] = [
    { field: 'prestation', headerName: 'Description de medicament pour le patient', width: 400 },
    { field: 'idFacture', headerName: 'idFacture' },

    { field: 'idIncrement', headerName: 'idIncrement' },
    { field: 'quantity', headerName: 'Quantity' },
    { field: 'statusFacturePayer', headerName: 'Status' },
    { field: 'doctorName', headerName: 'Doctor', width: 100 },
    { field: 'typedoctorName', headerName: 'Type Doctor' },
    { field: 'dateCreatedSaisie', headerName: 'Date Saisie' },
    { field: 'timeSaisie', headerName: 'Time Saisie' },
    {
      field: 'actionss',
      headerName: 'Actionss',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleRowButtonDelete(params.row)}
        >
          Delete
        </Button>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleRowButtonPush(params.row)}
        >
          Ou
        </Button>
      ),
    },
  ];
      return (
     
<div style={{ width: '100%' }}>
<DataGrid
        rows={isNewFactureSaisieDto}
        getRowId={(row) => row.idFacture}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 9 },
          },
        }}
        pageSizeOptions={[9, 14]}
        autoHeight
       
      />

 <Stack >
   <Button className="mb-5 mt-3" onClick={() => {findAllFactureByInsuarenceAndReceipt()}} variant="contained" color="primary">
    Actualise
  </Button>
 </Stack>

     </div>

      );
    }
