import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import CotisationAssuranceDto from "../../../Components/ASSURANCES/Recouvrements/PreparationFacture/Models/CotisationAssuranceDto";
import { Add } from "../../../Components/ASSURANCES/Recouvrements/PreparationFacture/Operations/Add";
import { Edit } from "../../../Components/ASSURANCES/Recouvrements/PreparationFacture/Operations/Edit";
import { TableOperations } from "../../../Components/ASSURANCES/Recouvrements/PreparationFacture/Tables/TableOperations";

const initialTypeStatedto = {
  id: 0,
  money: 0,
  createdBy: '',
  moisPayer: '',
  statusPayer: '',
  statusClassee: '',
  comment: '',
  dateCreated: '',
  timeCreated: '',
  
  enterprisesAssuranceId: 0,
  enterpriseName: '',

  socialinsuranceId: 0,
  socialInsuaranceName: '',
};
export const CotisationEntreprise:React.FC=()=>{

  
    const [EditListAdherant,setEditListAdherant] = 
    useState<CotisationAssuranceDto>(initialTypeStatedto)

    const onCurrentAdhesion = (onCurrant: CotisationAssuranceDto) => {
        setEditListAdherant(onCurrant);
      };

    const [isSwichLayerAdherant,setSwichLayerAdherant] = useState(true);
    
    return(
        <>
        <Container fluid>
 <Row>  
                <Col> 
                <br />
                <Card>
            <Card.Body>
            {isSwichLayerAdherant?
                (
                <Add/>
                ):(
<Edit
 show={isSwichLayerAdherant}
   onHide={() => setSwichLayerAdherant(true)}
   EditInfo={EditListAdherant} /> 
                )
                }
 <TableOperations
  show={isSwichLayerAdherant}
  onHide={() => setSwichLayerAdherant(false)}
  onEdit={onCurrentAdhesion}
          />
   </Card.Body>
        </Card>  <br />
                   </Col>

            </Row>
          
        </Container>
        </>
    );
}