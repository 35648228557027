import { ChangeEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import IdataModeConsultationDtos from "../../../Doctor/Names/Models/IdataModelConsultationDtos";
import { Row, Form, InputGroup, Stack } from "react-bootstrap";
import IdataModelPMemberDtos from "../../../ASSURANCES/AdherantPrencipales/Models/IdataModelPMemberDtos";
import IdataModelService from "../../../ServicesData/Models/IdataModelService";
import CouvertureconfigService from "../../../ASSURANCES/Configurations/Couvertures/Services/CouvertureconfigService";
import IdataModelCouvertureDtos from "../../../ASSURANCES/Configurations/Couvertures/Models/IdataModelCouvertureDtos";
import IdataModelTypeBonCommend from "../../../Type/TypeBonCommend/Models/IdataModelTypeBonCommend";
import TypeService from "../../../Type/TypeBonCommend/Services/TypeServices";
import IdataModelTypeDepertment from "../../../HopitalDepertment/Models/IdataModelTypeDepertment";
import ServiceDepartment from "../../../HopitalDepertment/Services/ServiceDepartment";
import NewBon from "../../NewBon/Models/NewBon";
import NewFactureSaisie from '../../FactureSaisie/models/NewFactureSaisie';
import ServicesNewBon from "../../NewBon/Services/ServiceNewBon";
import ServicesNewFactureSaisie from '../../FactureSaisie/services/ServiceNewFactureSaisie';
import ServiceHopital from "../../../ServicesData/Services/ServiceHopital";
import NewVisite from "../../NouvelleVisite/Models/NewVisite";
import ServicesNewVisite from "../../NouvelleVisite/Services/ServicesNewVisite";
import RestPlafondYearService from "../../../ASSURANCES/CounterRestPlafond/Services/RestPlafondYearService";
import PlafondYearTypeVisiteService from "../../../ASSURANCES/PlafondYearTypeVisite/Services/PlafondYearTypeVisiteService";
import IPlafondYearTypeVisiteDto from "../../../ASSURANCES/PlafondYearTypeVisite/Models/PlafondYearTypeVisiteDto";
import { RootState } from "../../../../Redux/Store/store";
import { useSelector } from "react-redux";
import IdataModelCounterRestPlafond from "../../../ASSURANCES/CounterRestPlafond/Models/CounterRestPlafond";
import ServiceSmember from "../../../ASSURANCES/Beneficieres/Services/ServiceSmember";
import { useHistory } from "react-router-dom";



interface pushedCurrantDoctorList {
    show: boolean; 
    CurrantDoctor: IdataModeConsultationDtos;
    Currantpmember: IdataModelPMemberDtos;
    componentToPrint: React.MutableRefObject<null>;
  }
  const initialTypeStatePmemberDtos = {
   
    //table enterpriseAssurance   ta1
    idInterprise:0,
    accountInterprise:0,
    enterpriseName: '',
    couvertureDu: '',
     couvertureAau: '',
     resilierSociete:'',

  ///table PMember pa
    idPmember:0,
    codeAssuree: '',
    nomPrenomAffilie: '',
    cotisationExact: '',
    account:0,
    genre: '',
    catégorieSoins: '',
    email: '',
    telephones: '',
    adresseAffilie: '',
    autresInformations: '',
  
    plafond_ambilatoire: '',
    plafond_hospitalisation: '',
    plafond_pharmacy: '',
    plafond_ORL: '',
    plafond_urugence: '',
    plafond_ophtalmologie: '',
    category_code: '',
    plafond_dentisteries: '',
    resilier: '',
    dataResiliation: '',
    photoMember: '',
    dateNaissance: '',
    createdBy: '',
    dateCreated: '',
  tva:'',
  etatCivil:'',
   ////table socialInsurance so
    idSociete:0,
    societeName: ''
   
  };
 
  const initialCouvertureConfig={
 /// c
 idCouvertureConfig:0,
 ticket:'',
 ticketFacture:'',
 particulie:'',
 access:'',
 secter:'',
 plafondOrdonence:'',
 createdBy:'',
 dateCreated:'',

    // services ser
    idService:'',
    serviceName:'',
   // code_category co

   idCategoryCode:'',
   categoryCode:'',
   

/// enterprise e
 idEnterprise:0,
 enterpriseName:'',
//// insurance i
 idInsurance:0,
 insuranceName:''
  };
  const newBonInit={
    incrementIdReceiptBill:0,
    receiptBill:'',
    codeAssureeControl:'',
    createdBy:'Ramadhani Ally',
    dateCreated: '',

  }
   const newVisiteInit={
     receiptBill:'',
     patientName:'',
     typeAssuree:'', // si c'est moi / ayantDroit
     createdBy:'Ramadhani Ally',
     dateCreatedSaisie:'',
     timeSaisie:'',
   }
   const newFactureSaisie={
    prestation:'',
    prestationPrescriptPar:'',
    pricePrestation:0,
    plafondFacture:0,
    ticketService:0,
    quantity:1,
    partMutuelle:0,
    partpatient:0,
    partTotal:0,
    statusFacturePayer:'No payer',
    createdBy:'Ramadhani Ally',
    dateCreatedSaisie:'',
    timeSaisie:'',

     partMutuelleFacture:0,
     partpatientFacture:0,
     partTotalFacture:0,

     ticketServiceFacture:0,

     statusFactureDeclare:'',
     statusFactureVerifier:'',
     statusFactureRembourceAvecAutreFacture:'',
     statusFactureRembourceUneFacture:'',
    idRestIncrementPlafondConsomme:0
  } 
  const defaultservice: Array<IdataModelService> = [];
  const defaultdepertment:Array<IdataModelTypeDepertment>=[];
  const defaulttypeVisiteSelonPlafond: Array<IPlafondYearTypeVisiteDto> = [];
  const defaulttypeBonCommend: Array<IdataModelTypeBonCommend> = [];

  let couvertureTotal=100;
  
  let currentDate = new Date();
  let billReceiptSearch= '';
  let concatPatientName='';
  let isNewVisitePatientId=0;


      var getYear = currentDate.toLocaleString("default", { year: "numeric" });
      var getMonth = currentDate.toLocaleString("default", { month: "2-digit" });
      var getDay = currentDate.toLocaleString("default", { day: "2-digit" });

      const renderNumber = (value: number, length: number) => {
        let result: string = String(value);
      
        for (; length > result.length; length -= 1) result = '0' + result;
      
        return result;
      };
      const hours: string = renderNumber(currentDate.getHours(), 2);
      const minutes: string = renderNumber(currentDate.getMinutes(), 2);
     // const seconds: string = renderNumber(currentDate.getSeconds(), 2);
      //const milliseconds: string = renderNumber(currentDate.getMilliseconds(), 3);
      
   let currentDateFormat = getYear + "-" + getMonth + "-" + getDay;

   let currentTime=hours + "h:" + minutes + "min" ;

   export const ConsultationPmember:React.FC<pushedCurrantDoctorList>=props=> {
    const preferredName = useSelector((state: RootState) => state.user.preferred_username);

    const history=useHistory()
    const [isInputDtosCurrantDoctorDtos,setInputDtosCurrantDoctorDtos]=useState<IdataModeConsultationDtos>(props.CurrantDoctor);
    const [CurrentPushedPrincipalMember, setCurrentPushedPrincipalMember] = useState<IdataModelPMemberDtos>(initialTypeStatePmemberDtos);
    const [isRestPlafond, setisRestPlafond] = useState(0);
    /// registering new bon
    const [isInputNewBon,setisInputNewBon]=useState<NewBon>(newBonInit);
    /// registering new Visite
    const [isInputNewVisite,setisInputNewVisite]=useState<NewVisite>(newVisiteInit);
    
   //for registering new facture Saisie
   const [isInputFactureSaisie,setisInputFactureSaisie]=useState<NewFactureSaisie>(newFactureSaisie);

   const [isTypeVisiteId, setisTypeVisiteId] = useState('');
   const [isDepertment, setisDepertment] = useState('');
   const [isTypeBonCommend, setisTypeBonCommend] = useState('');

    const [service, setservice] = useState(defaultservice);
    
    const [plafondYearId, setplafondYearId] = useState(0);
    
    const [plafondYearTypeVisiteId, setplafondYearTypeVisiteId] = useState(0);
    const [plafondYearInterpriseId, setplafondYearInterpriseId] = useState(0);
    const [plafondYearCategoryId, setplafondYearCategoryId] = useState(0);
    const [plafondYearSocieteid, setplafondYearSocieteid] = useState(0);
    
    const [plafondYear, setplafondYear] = useState(0);
    

    const [depertment, setdepertment] = useState(defaultdepertment);
    const [depertmentFacture, setdepertmentFacture] = useState(defaultdepertment);


    const [isServiceConfig,setServiceConfig]=useState<IdataModelCouvertureDtos>(initialCouvertureConfig);

    const [ConfigId, setConfigId] = useState(0);
    const [ConfigTicket, setConfigTicket] = useState(0);
    const [ConfigTicketFacture, setConfigTicketFacture] = useState(0);
    const [Configparticulie, setConfigparticulie] = useState('NA');
    const [Configtype_category, setConfigtype_category] = useState('NA');
    const [Configaccess, setConfigaccess] = useState('NA');
    const [ConfigPlafondFacture, setConfigPlafondFacture] = useState(0);
    const [accessCategory, setaccessCategory] = useState('na');
    const [ishowingtextButton, setIsshowingtextButton] = useState(false);

    const [isButtonshowVerifier, setisButtonshowVerifier] = useState(false);
   
    const [isTotal, setisTotal] = useState(0);
    const [isPartMituelle, setisPartMituelle] = useState(0);
    const [isPartPatient, setisPartPatient] = useState(0);
  
    const [isTotalFacture, setisTotalFacture] = useState(0);
    const [isPartMituelleFacture, setisPartMituelleFacture] = useState(0);
    const [isPartPatientFacture, setisPartPatientFacture] = useState(0);

    const [isIncrementIdReceiptBill, setisIncrementIdReceiptBill] = useState(0);


    const [typeVisite, settypeVisite] = useState(defaulttypeVisiteSelonPlafond);
    const [typebonCommend, settypebonCommend] = useState(defaulttypeBonCommend);
    
    const [typeCountAyant, settypeCountAyant] = useState(0);
    
    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      const handleChangeConfig = (e: { target: { name: any; value: any; }; }) => {
        setServiceConfig({ ...isServiceConfig, [e.target.name]: e.target.value });
      }


      const handleChangeTypeVisite = (event: ChangeEvent<HTMLSelectElement>) => {
        setisTypeVisiteId(event.target.value);
        

        const selectedTypeBonId = parseInt(event.target.value, 10);

       // console.log('selectedTypeBonId', selectedTypeBonId);

        // Find the selected typeVisite based on the selectedTypeBonId
const selectedTypeVisite = typeVisite.find((type) => type.typeBonId === selectedTypeBonId);

console.log('type:',selectedTypeVisite);
//console.log('selectedTypeVisite',selectedTypeVisite);
// Check if the selectedTypeVisite is found
if (selectedTypeVisite) {
  // Access the moneyPrice property of the selectedTypeVisite
   setplafondYear(selectedTypeVisite.plafondYear);
   setplafondYearId(selectedTypeVisite.plafondYearId);
   setplafondYearCategoryId(selectedTypeVisite.categoryId)
   setplafondYearTypeVisiteId(selectedTypeVisite.typeBonId);
   setplafondYearInterpriseId(selectedTypeVisite.enterpriseId);
   setplafondYearSocieteid(selectedTypeVisite.insuranceId);

  // Now you can do something with the moneyPrice value

  // You can also update state or perform other actions based on the moneyPrice
}else{
  console.log('error baba')
}

      };
      
      const getCountNomberOfAyantDroit = useCallback(() => {
        if (
          CurrentPushedPrincipalMember.idSociete !== 0 &&
          CurrentPushedPrincipalMember.idInterprise !== 0 &&
          CurrentPushedPrincipalMember.catégorieSoins !== null
        ) {
      
          ServiceSmember.AccountEnfants(
          CurrentPushedPrincipalMember.codeAssuree && CurrentPushedPrincipalMember.codeAssuree.slice(0, -1),
            CurrentPushedPrincipalMember.idSociete
          )
            .then((response: any) => {
              settypeCountAyant(response.data);
      
               console.log('settypeCountAyant', response.data)
            })
            .catch((e: Error) => {
              console.log(e);
            });
        } else {
          console.log('haliii ganiii');
        }
      }, [CurrentPushedPrincipalMember.idSociete, CurrentPushedPrincipalMember.idInterprise, CurrentPushedPrincipalMember.catégorieSoins, CurrentPushedPrincipalMember.codeAssuree]);

      
useEffect(() => {
  getCountNomberOfAyantDroit();
}, [getCountNomberOfAyantDroit]);

      const getPlafondBySelectedVisite = useCallback(() => {
        if (
          CurrentPushedPrincipalMember.idSociete !== 0 &&
          CurrentPushedPrincipalMember.idInterprise !== 0 &&
          CurrentPushedPrincipalMember.catégorieSoins !== null
        ) {
          const encodedCategoryName = encodeURIComponent(CurrentPushedPrincipalMember.catégorieSoins);
      
          PlafondYearTypeVisiteService.findAllByInsuarenceEnterpCategEnfEtat(
            CurrentPushedPrincipalMember.idSociete,
            CurrentPushedPrincipalMember.idInterprise,
            encodedCategoryName,
            typeCountAyant,
            CurrentPushedPrincipalMember.etatCivil
          )
            .then((response: any) => {
              settypeVisite(response.data);
      
               console.log('findAllByInsuarenceEnterpCategEnfEtat', response.data)
            })
            .catch((e: Error) => {
              console.log(e);
            });
        } else {
          console.log('haliii ganiii');
        }
      }, [CurrentPushedPrincipalMember.idSociete, CurrentPushedPrincipalMember.idInterprise, CurrentPushedPrincipalMember.catégorieSoins, CurrentPushedPrincipalMember.etatCivil, typeCountAyant]);
      

      const handleChangeNewFactureSaisie = (e: { target: { name: any; value: any; }; }) => {
        setisInputFactureSaisie({ ...isInputFactureSaisie, [e.target.name]: e.target.value });
      }
      const handleChangeDepertment = (event: { target: { value: SetStateAction<string>; }; }) => {
            setisDepertment(event.target.value);
          };
      const handleChangeTypeBonCommend = (event: { target: { value: SetStateAction<string>; }; }) => {
            setisTypeBonCommend(event.target.value);
          };
          
          const getServices = useCallback(async () => {
            try {
              const response = await ServiceHopital.getAllTypeWithoutPagination();
              setservice(response.data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          }, []); // Empty dependency array since there are no dependencies
        
          useEffect(() => {
            const fetchData = async () => {
              await getServices();
            };
        
            fetchData();
          }, [getServices]); // Now getServices is a dependency
        

      const getDepertment = useCallback(async () => {
        try {
          const response = await ServiceDepartment.getAllTypeWithoutPagination();
          setdepertment(response.data);
          setdepertmentFacture(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }, []); // Empty dependency array since there are no dependencies
    
      useEffect(() => {
        const fetchData = async () => {
          await getDepertment();
        };
    
        fetchData();
      }, [getDepertment]); // Now getDepertment is a dependency
    
     
      const getAllTypeBonCommend = useCallback(async () => {
        try {
         const response= await TypeService.getAllTypeWithoutPagination();
          
          settypebonCommend(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }, []);
    
      useEffect(() => {
        const fetchData = async () => {
          await getAllTypeBonCommend();
        };
    
        fetchData();
      }, [getAllTypeBonCommend]); // Now getAllTypeBonCommend is a dependency
    
     
     
      const ConfigServiceInterprise = useCallback(() => {
        if (
          CurrentPushedPrincipalMember.idSociete !== 0 &&
          CurrentPushedPrincipalMember.idInterprise !== 0 &&
          isServiceConfig.serviceName.length !== 0 &&
          CurrentPushedPrincipalMember.category_code !== null
        ) {
          CouvertureconfigService.findByInsuranceIdAndEnterpriseIdAndSecteurAndService(
            CurrentPushedPrincipalMember.idSociete,
            CurrentPushedPrincipalMember.idInterprise,
            isInputDtosCurrantDoctorDtos.typehospital,
            isServiceConfig.serviceName,
            CurrentPushedPrincipalMember.category_code
          )
            .then((response: any) => {
              setConfigId(response.data.idCouvertureConfig);
              setConfigTicket(response.data.ticket);
              setConfigPlafondFacture(response.data.ticketFacture);
              setConfigTicketFacture(response.data.ticketFacture);
              setConfigparticulie(response.data.particulie);
              setConfigtype_category(response.data.type_category);
              setConfigaccess(response.data.access);
              setConfigPlafondFacture(response.data.plafondOrdonence);
      
              // console.log(response.data)
            })
            .catch((e: Error) => {
              console.log(e);
            });
        } else {
          // console.log('haliii ganiii')
        }
      }, [
        CurrentPushedPrincipalMember.idSociete,
        CurrentPushedPrincipalMember.idInterprise,
        isInputDtosCurrantDoctorDtos.typehospital,
        isServiceConfig.serviceName,
        CurrentPushedPrincipalMember.category_code,
        setConfigId,
        setConfigTicket,
        setConfigPlafondFacture,
        setConfigTicketFacture,
        setConfigparticulie,
        setConfigtype_category,
        setConfigaccess,
      ]);
      
      // Usage in the component
      useEffect(() => {
        ConfigServiceInterprise();
      }, [ConfigServiceInterprise]);
      
      
      const CalculateFacture = useCallback(() => {
        let couvertureMituelle = couvertureTotal - ConfigTicket;
        setisPartMituelle(
          (isInputDtosCurrantDoctorDtos.priceConsultation * couvertureMituelle * isInputFactureSaisie.quantity) / 100
        );
        setisPartPatient(
          (isInputDtosCurrantDoctorDtos.priceConsultation * ConfigTicket * isInputFactureSaisie.quantity) / 100
        );
        setisTotal(isInputDtosCurrantDoctorDtos.priceConsultation * isInputFactureSaisie.quantity);
      
        let couvertureMituelle2 = couvertureTotal - ConfigTicketFacture;
        setisPartMituelleFacture(
          (isInputDtosCurrantDoctorDtos.priceConsultation * couvertureMituelle2 * isInputFactureSaisie.quantity) / 100
        );
        setisPartPatientFacture(
          (isInputDtosCurrantDoctorDtos.priceConsultation * ConfigTicketFacture * isInputFactureSaisie.quantity) / 100
        );
        setisTotalFacture(isInputDtosCurrantDoctorDtos.priceConsultation * isInputFactureSaisie.quantity);
      }, [ConfigTicket, isInputDtosCurrantDoctorDtos.priceConsultation, isInputFactureSaisie.quantity, ConfigTicketFacture, setisPartMituelle, setisPartPatient, setisTotal, setisPartMituelleFacture, setisPartPatientFacture, setisTotalFacture]);
      
      // Usage in the component
      useEffect(() => {
        CalculateFacture();
      }, [CalculateFacture]);
      

useEffect(() => {
  getServices();
},[
  getServices]);
   
   
const findFirstBySocialinsuranceIdAndHospitalIdOrderByIncrementIdReceiptBillDesc = useCallback(() => {
  try {
    if (CurrentPushedPrincipalMember.codeAssuree === "") {
      /* Don't do anything */
    } else {
      ServicesNewBon.getLastIncrementIdReceiptBill(
        CurrentPushedPrincipalMember.idSociete,
        isInputDtosCurrantDoctorDtos.hospitalId
      )
        .then((response: any) => {
          setisIncrementIdReceiptBill(response.data.incrementIdReceiptBill + 1);
          if (response.data.incrementIdReceiptBill === undefined) {
            setisIncrementIdReceiptBill(1);
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  } catch (error) {
    console.log(error);
  }
}, [
  CurrentPushedPrincipalMember.codeAssuree,
  CurrentPushedPrincipalMember.idSociete,
  isInputDtosCurrantDoctorDtos.hospitalId,
  setisIncrementIdReceiptBill,
]);

//


    
    
const Processplafond = useCallback(() => {
  
      if ((isRestPlafond+isPartPatient) > plafondYear ) {
        setIsshowingtextButton(false);
        setisButtonshowVerifier(false);
      } else {
         setisButtonshowVerifier(true);
        setIsshowingtextButton(true);
      }
    
}, [isRestPlafond, isPartPatient, plafondYear]);


useEffect(() => {
  Processplafond();
},[Processplafond]);
    
const FilterCategorySoins = useCallback(() => {
  if (CurrentPushedPrincipalMember.catégorieSoins !== '') {
    // Adding trim() to remove leading and trailing whitespaces
    const trimmedCategorySoins = CurrentPushedPrincipalMember.catégorieSoins.trim();
    const trimmedCategorySoinsName = isInputDtosCurrantDoctorDtos.categorySoinsName.trim();

    if (trimmedCategorySoinsName.search(trimmedCategorySoins) === -1) {
      setaccessCategory("NO");
    } else {
      setaccessCategory("OUI");
      if (Configaccess === "OUI" && CurrentPushedPrincipalMember.resilier === "Non" && CurrentPushedPrincipalMember.resilierSociete === "Non") {
        setisButtonshowVerifier(true);
        setIsshowingtextButton(true);
      } else {
        setIsshowingtextButton(false);
        setisButtonshowVerifier(false);
      }
    }
  }
}, [CurrentPushedPrincipalMember.catégorieSoins, isInputDtosCurrantDoctorDtos.categorySoinsName, Configaccess, CurrentPushedPrincipalMember.resilier, CurrentPushedPrincipalMember.resilierSociete, setaccessCategory, setisButtonshowVerifier, setIsshowingtextButton]);


 
 
 const findBySocialinsuranceIdAndHospitalIdAndReceiptBill =()=>{
  try {
    if(billReceiptSearch===''){
      /* don't do nothing */
      console.log('hello billReceiptSearch is not working');
    }else{
      console.log('hello is working');
      ServicesNewVisite.findBySocialinsuranceIdAndHospitalIdAndReceiptBill(
        CurrentPushedPrincipalMember.idSociete,
        isInputDtosCurrantDoctorDtos.hospitalId,
        billReceiptSearch)
      .then((response: any) => {
          if(response.data.idFacture===undefined){
           console.log('id not found of the corresponding id')
           }else{
            isNewVisitePatientId=response.data.idFacture;
            console.log('isNewVisitePatientId',isNewVisitePatientId)
            saveFactureSaisie()
           }
    })
    }
  } catch (error) {
    console.log(error)
  }
}

const getRestPlafondYear = useCallback(() => {
  try {
    if (CurrentPushedPrincipalMember.codeAssuree === "") {
      /* Don't do anything */
    } else {
      
    
      RestPlafondYearService.getSum(CurrentPushedPrincipalMember.codeAssuree,"payer",isTypeVisiteId)
        .then((response: any) => {
          console.log("API response",response);
          console.log("getRestPlafondYear",response.data);
          if(response.data===undefined){
            setisRestPlafond(0);
          }else{
            setisRestPlafond(response.data);
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  } catch (error) {
    console.log(error);
  }
}, [CurrentPushedPrincipalMember.codeAssuree, isTypeVisiteId]);

useEffect(() => {
  getRestPlafondYear();
},[getRestPlafondYear]);

 
 const VerifierExitingNewBonAndNewVisite=()=>{
  saveNewBon() // this function was design to make the platform use only one control number for the intire life of the application ones the patient get the control number he will never request egain for the new control number(Bon de soin)
  saveNewVisite()
  
  //setIsshowingtextButton(true)

 }
    useEffect(() => {
       FilterCategorySoins();
      getPlafondBySelectedVisite();
        setInputDtosCurrantDoctorDtos(props.CurrantDoctor);
        setCurrentPushedPrincipalMember(props.Currantpmember);
      
        concatPatientName = `${CurrentPushedPrincipalMember.nomPrenomAffilie}/${CurrentPushedPrincipalMember.nomPrenomAffilie}`;
        findFirstBySocialinsuranceIdAndHospitalIdOrderByIncrementIdReceiptBillDesc();
        },[CurrentPushedPrincipalMember.nomPrenomAffilie, FilterCategorySoins, findFirstBySocialinsuranceIdAndHospitalIdOrderByIncrementIdReceiptBillDesc, getPlafondBySelectedVisite, props.CurrantDoctor, props.Currantpmember])

        const saveNewBon = () => {  
          let data = {
            incrementIdReceiptBill:isIncrementIdReceiptBill,
            receiptBill:''+isInputDtosCurrantDoctorDtos.referenceId+ +isIncrementIdReceiptBill,
            codeAssureeControl:CurrentPushedPrincipalMember.codeAssuree,
            createdBy:preferredName,
            dateCreated:currentDateFormat
          };
              
          ServicesNewBon.create(CurrentPushedPrincipalMember.idSociete,
            CurrentPushedPrincipalMember.idInterprise,
            CurrentPushedPrincipalMember.idPmember,
            isInputDtosCurrantDoctorDtos.hospitalId,data)
          .then((response: any) => {
            setisInputNewBon({
              id: response.data.id,
              incrementIdReceiptBill: response.data.incrementIdReceiptBill,
              receiptBill: response.data.receiptBill,
              createdBy: response.data.createdBy,
              codeAssureeControl:response.data.codeAssureeControl,
              dateCreated: response.data.dateCreatedBy
            });
            console.log(response.data);
            billReceiptSearch=response.data.receiptBill

            console.log("billReceiptSearch",billReceiptSearch); 
          }).catch((e: Error) => {
            console.log(e);
          });
      };
     

          const saveNewVisite = () => {  
            let data = {
              receiptBill:''+isInputDtosCurrantDoctorDtos.referenceId+ +isIncrementIdReceiptBill,
              patientName:concatPatientName,
              typeAssuree:'moi',
              createdBy:preferredName,
              dateCreatedSaisie:currentDateFormat,
              timeSaisie:currentTime
            };
                
            ServicesNewVisite.createNewVisite(
              
            CurrentPushedPrincipalMember.idSociete,
            isInputDtosCurrantDoctorDtos.doctorNameId,
            isInputDtosCurrantDoctorDtos.doctorTypeId ,
            ConfigId,
            isInputDtosCurrantDoctorDtos.hospitalId,
            isDepertment,
            CurrentPushedPrincipalMember.idInterprise,
              CurrentPushedPrincipalMember.idPmember,
              isTypeVisiteId,
              isTypeBonCommend,
              plafondYearId,data)
            .then((response: any) => {
              setisInputNewVisite({
                id: response.data.id,
                receiptBill: response.data.receiptBill,
                patientName: response.data.patientName,
                typeAssuree:response.data.typeAssuree,
                createdBy:response.data.createdBy,
                dateCreatedSaisie:response.data.dateCreatedSaisie,
                timeSaisie:response.data.timeSaisie

              });
              console.log(response.data);
      
            ///  setisInputNewVisite(newVisiteInit);
              findBySocialinsuranceIdAndHospitalIdAndReceiptBill();
              

            }).catch((e: Error) => {
              console.log(e);
            });
        };
const saveFactureSaisie = () => {  

          let data = {
            prestation:isInputDtosCurrantDoctorDtos.typeName,
            pricePrestation:isInputDtosCurrantDoctorDtos.priceConsultation,
            prestationPrescriptPar:isInputDtosCurrantDoctorDtos.prescriptPar,
            plafondFacture:ConfigPlafondFacture,
            ticketService:ConfigTicket,
            ticketServiceFacture:ConfigTicketFacture,
            quantity:isInputFactureSaisie.quantity,
            partMutuelle:isPartMituelle,
            partpatient:isPartPatient,
            partTotal:isTotal,
            partMutuelleFacture:isPartMituelleFacture,
            partpatientFacture:isPartPatientFacture,
            partTotalFacture:isTotalFacture,
            statusFacturePayer:'No payer',
            statusFactureDeclare:'no',
            statusFactureVerifier:'no',
            statusFactureRembourceAvecAutreFacture:'no',
            statusFactureRembourceUneFacture:'no',
            createdBy:preferredName,
            dateCreatedSaisie:currentDateFormat,
            timeSaisie:currentTime,  
          idRestIncrementPlafondConsomme:0 // this line is to remove later
          };
  
          ServicesNewFactureSaisie.createFactureSaisie(
            CurrentPushedPrincipalMember.idSociete,
            isNewVisitePatientId,
            isInputDtosCurrantDoctorDtos.doctorNameId,
            isInputDtosCurrantDoctorDtos.doctorTypeId ,
            ConfigId,
            isInputDtosCurrantDoctorDtos.hospitalId,
            isDepertment,
            plafondYearId,data)
          .then((response: any) => {
            setisInputFactureSaisie({
              id: response.data.id,
              prestation: response.data.prestation,
              pricePrestation: response.data.pricePrestation,
              prestationPrescriptPar:response.data. prestationPrescriptPar,
              plafondFacture: response.data.plafondFacture,
              ticketService: response.data.ticketService,
              quantity: response.data.quantity,
              partMutuelle: response.data.partMutuelle,
              partpatient: response.data.partpatient,
              partTotal: response.data.partTotal,
              statusFacturePayer: response.data.statusFacturePayer,
              createdBy: response.data.createdBy,
              dateCreatedSaisie:currentDateFormat,
              timeSaisie:currentTime,
              partMutuelleFacture:response.data.partMutuelleFacture,
              partpatientFacture:response.data.partpatientFacture,
              partTotalFacture:response.data.partTotalFacture,
              
              ticketServiceFacture:response.data.ticketServiceFacture,
        
              statusFactureDeclare:response.data.statusFactureDeclare,
              statusFactureVerifier:response.data.statusFactureVerifier,
              statusFactureRembourceAvecAutreFacture:response.data.statusFactureRembourceAvecAutreFacture,
              statusFactureRembourceUneFacture:response.data.statusFactureRembourceUneFacture,
              idRestIncrementPlafondConsomme:response.data.idRestIncrementPlafondConsomme,
            });
            console.log(response.data);
            console.log(data);
            setisInputFactureSaisie(newFactureSaisie)
            setisButtonshowVerifier(false)
            setIsshowingtextButton(true)
            history.push('/EspacePatients');

          })
          .catch((e: Error) => {
            console.log(e);
          });
      };
     
     

          return(
 <>
< form onSubmit={onFormSubmit} >
<Row className="mb-3">
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>Docteur</strong>: {isInputDtosCurrantDoctorDtos.doctorName} </Form.Label>
<br/>
<Form.Label><strong>Specialite</strong>:{isInputDtosCurrantDoctorDtos.typeName}</Form.Label>
</Form.Group>
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>Prix Prestation</strong>: {isInputDtosCurrantDoctorDtos.priceConsultation} </Form.Label>
<br/>
<Form.Label><strong>Structure</strong>:{isInputDtosCurrantDoctorDtos.typehospital} {isInputDtosCurrantDoctorDtos.hospitalName}</Form.Label>
</Form.Group>
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>Access Interprise</strong>: {Configaccess} <strong>Acc Patient</strong>: {accessCategory} </Form.Label>
<br/>
<Form.Label><strong>Category</strong>: {Configtype_category} <strong>Soins</strong>: {CurrentPushedPrincipalMember.catégorieSoins}</Form.Label>
</Form.Group>
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>Ticket</strong>: {ConfigTicket}<strong>%</strong> <strong>Entreprise Desactive :</strong> {CurrentPushedPrincipalMember.resilierSociete}</Form.Label>
<br/>
<Form.Label><strong>Payer</strong>: {Configparticulie} <strong>Entreprise</strong>: {CurrentPushedPrincipalMember.enterpriseName}</Form.Label>
</Form.Group>
</Row>
<Row className="mb-3">
<Form.Group controlId="formBasicEmail" className="col col-sm-3">
<Form.Label>Type Visite</Form.Label>
<Form.Select className="form-control"  size="sm"
onChange={handleChangeTypeVisite}>
<option value="0">Choose..</option>
{typeVisite &&
typeVisite.map((typeVisite) => (
<option key={typeVisite.typeBonId} value={typeVisite.typeBonId} >{typeVisite.typeName}</option>
))}

</Form.Select>
</Form.Group>
<Form.Group controlId="formBasicEmail" className="col col-sm-3">
<Form.Label>TypeBon</Form.Label>
<Form.Select aria-label=" select Gender" size="sm"  
onChange={handleChangeTypeBonCommend}>
<option value="0">Choose..</option>
{typebonCommend &&
typebonCommend.map((typebonCommend) => (
<option key={typebonCommend.id} value={typebonCommend.id} >{typebonCommend.typeName}</option>
))}
</Form.Select>

</Form.Group>

<Form.Group controlId="formBasicEmail" className="col col-sm-3">
<Form.Label> plafond</Form.Label>
<Form.Select className="form-control" size="sm"  name="serviceName" value={isServiceConfig.serviceName}
onChange={handleChangeConfig}>
<option value="0">Choose..</option>
{
service &&
service.map((service) => (
<option key={service.id} value={service.typeName} >{service.typeName}</option>
))}

</Form.Select>
</Form.Group>
<Form.Group controlId="formBasicEmail" className="col col-sm-3">
<Form.Label>Depertment</Form.Label>
<Form.Select className="form-control" size="sm" 
onChange={handleChangeDepertment}>
<option value="0">Choose..</option>
{
depertment &&
depertment.map((depertment) => (
<option key={depertment.id} value={depertment.id} >{depertment.typeName}</option>
))}

</Form.Select>
</Form.Group>
</Row>
<Row className="mb-3">
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>ID</strong>: {CurrentPushedPrincipalMember.codeAssuree} </Form.Label>
<br/>
<Form.Label><strong>Adherant</strong>:{CurrentPushedPrincipalMember.nomPrenomAffilie}</Form.Label>
</Form.Group>
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>Plafond Anuelle</strong>: {plafondYear} </Form.Label>
<br/>
<Form.Label><strong>Plafond Consomme </strong>: {isRestPlafond}</Form.Label>
</Form.Group>
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>Total de Facture</strong>:{isTotal}</Form.Label>
<br/>
<Form.Label><strong>Numero de Bon</strong>: {''+isInputDtosCurrantDoctorDtos.referenceId+ +isIncrementIdReceiptBill}</Form.Label>

</Form.Group>
</Row>
<Row className="mb-3">         
<Row>
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>Patient</strong>:{concatPatientName}</Form.Label>
<br/>          
<Form.Label><strong>part Patient</strong>: {isPartPatient}</Form.Label>

</Form.Group>
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>TypePrestation</strong>:{isInputDtosCurrantDoctorDtos.typeName}</Form.Label>
<br/>
<Form.Label><strong>Part Mituelle</strong>: {isPartMituelle} </Form.Label>
        
</Form.Group>
<Form.Group  controlId="formBasicEmail" className="col col-sm-3">
<Form.Label><strong>Quantite</strong></Form.Label>
<InputGroup>
    <Form.Control size="sm"  type="number" name="quantity" value={isInputFactureSaisie.quantity} onChange={handleChangeNewFactureSaisie} />
</InputGroup>
</Form.Group>
</Row>
<Form.Group   className="col col-sm-6">
<Stack direction="horizontal" gap={5}>

{isButtonshowVerifier ?(
<>
<button onClick={() => VerifierExitingNewBonAndNewVisite()} className="me-4 btn btn-success btn-sm btn-block">Enregistre</button>
</>
):(
<>
</>
)}


{ishowingtextButton ?(
<div></div>
):(
<button  className="me-4 btn btn-danger btn-lg btn-block"><strong>Access au soins Refusé !</strong></button>
)}
</Stack>         
</Form.Group>
</Row>
       </form> 
  
 </>
        );
}


