import { Container, Row, Col, Card } from "react-bootstrap";
import { HospitalType } from "../../../Components/ASSURANCES/StructurePartenaires/Hospitals/HospitalType/HospitalType";
import { HospitalTypeEdit } from "../../../Components/ASSURANCES/StructurePartenaires/Hospitals/HospitalType/HospitalTypeEdit";
import { HospitalTable } from "../../../Components/ASSURANCES/StructurePartenaires/Hospitals/Tables/HospitalTable";
import { PharmacyType } from "../../../Components/ASSURANCES/StructurePartenaires/Pharmacies/PharmacyType/PharmacyType";
import { PharmacyTypeEdit } from "../../../Components/ASSURANCES/StructurePartenaires/Pharmacies/PharmacyType/PharmacyTypeEdit";
import { PharmacyTable } from "../../../Components/ASSURANCES/StructurePartenaires/Pharmacies/Tables/PharmacyTable";
import IdataModelHospitalCategoryDtos from "../../../Components/ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModelHospitalCategoryDtos";
import IdataModelPharmacy from "../../../Components/ASSURANCES/StructurePartenaires/Pharmacies/Models/IdataModelPharmacy";
import { useState } from "react";

const initialTypeHospitalState = {
    hospitalId: '',
    hospitalName: 'Structures',
    raisonSociale:'',
    tel:'',
    nif:'',
    rc:'',
    bp:'',
    avenue:'',
    centreFiscal:'',
    secteurActivite:'',
    formeJuridique:'',
    tva:'',
    typehospital: '',
    hospitalAccount:'',
    categorySoinsId: '',
    categorySoinsName: ''
  };
  const initialTypePharmacyState = {
    id: null,
    pharmacyName: '',
    raisonSociale:'',
    tel:'',
    nif:'',
    rc:'',
    bp:'',
    avenue:'',
    centreFiscal:'',
    secteurActivite:'',
    formeJuridique:'',
    tva:'',
    plafondFacture:'',
    account:0,
    createdBy: '',
    dateCreatedBy: new Date()
  };

export const Structures:React.FC=()=>
    {
        const [isSwichLayerHopital,SwichLayerHopital] = useState(true);
        const [isSwichLayerPharmacy,SwichLayerPharmacy] = useState(true);


        const [EditList, setEditList] = useState<IdataModelHospitalCategoryDtos>(initialTypeHospitalState);

        const onCurrantHopspital=(Hospital:IdataModelHospitalCategoryDtos)=>{

            setEditList(Hospital)
        }

        const [EditListPharmacy, setEditListPharmacy] = useState<IdataModelPharmacy>(initialTypePharmacyState);

        const onCurrantPharmacy=(Pharmacy:IdataModelPharmacy)=>{

            setEditListPharmacy(Pharmacy)
        }

        return(
            <>
            <Container fluid>
                <Row>
                <Col> 
                <br />
                <Card>
            <Card.Body>
           {isSwichLayerHopital?
                (
                 <HospitalType/>
                ):(
      <HospitalTypeEdit show={isSwichLayerHopital}  onHide={() => SwichLayerHopital(true)}

            EditInfoHospitalType={EditList} /> 
                )
  }
         <HospitalTable show={isSwichLayerHopital}  onHide={() => SwichLayerHopital(false)}
         onEditHospital={onCurrantHopspital}
          />
            </Card.Body>
        </Card>
        <br />
                </Col>

                <Col> 
                <br />
                <Card>
            <Card.Body>
           {isSwichLayerPharmacy?
                (
                    <PharmacyType />
                ):(
      <PharmacyTypeEdit show={isSwichLayerPharmacy}  onHide={() => SwichLayerPharmacy(true)}

      EditInfoPharmacy={EditListPharmacy} /> 
                )
  }
         <PharmacyTable show={isSwichLayerPharmacy}  onHide={() => SwichLayerPharmacy(false)}
         onEditPharmacy={onCurrantPharmacy}
          />
            </Card.Body>
        </Card>
        <br />
                </Col>
                </Row>
            
            </Container>
            </>
        );
    }

