import Pagination from "@material-ui/lab/Pagination/Pagination";

import { Button, Form, Row, Stack, Table } from "react-bootstrap";
import ServiceTypeCategory from "../Services/ServiceTypeCategory";
import IdataModelTypeCategoryDtos from "../Models/IdataModelTypeCategoryDtos";
import { useState, ChangeEvent, useEffect } from "react";
import { ItypeCategory } from "../Interfaces/ItypeCategory";


export const TypeCategoryTable:React.FC<ItypeCategory>=props=>{

    const [type, setType] = useState<Array<IdataModelTypeCategoryDtos>>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchtype, setSearchtype] = useState<string>("");
    
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6, 9,12];
    
    const getRequestParams = (searchintitule:string, thepage: number, thepageSize: number) => {
      let params = {
        intitule:searchintitule,
        page: thepage,
        size:thepageSize
      };
    
      if (searchintitule) {
        params["intitule"] = searchintitule;
      }
    
      if (thepage) {
        params["page"] = thepage - 1;
      }
    
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
    
    const onChangeSearchtype = (e: ChangeEvent<HTMLInputElement>) => {
      const searchtype = e.target.value;
      setSearchtype(searchtype);
    };
    
    const findBytype = () => {
      ServiceTypeCategory.findBytypeSoinsName(searchtype)
        .then((response: any) => {
          const {Soins } = response.data;
          setType(Soins);
          setCurrentIndex(-1);
          console.log(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
          
        });
    };
    const retrievetype = () => {
      const params = getRequestParams(searchtype, page, pageSize);
    
      ServiceTypeCategory.getAll(params)
    
        .then((response: any) => {
    
          const {Soins , totalPages} = response.data;
    
          setType(Soins);
          setCount(totalPages);
    
          console.log(response.data);
          console.log(params);
    
        })
    
        .catch((e: Error) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrievetype();
    },[page , pageSize]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
    const refreshList = () => {
      retrievetype();
      setCurrentIndex(-1);
    };
    
    const setActivetype = (datatypeCategory: IdataModelTypeCategoryDtos, index: number) => {
      setCurrentIndex(index); 
       props.onEditTypeCategory(datatypeCategory);
       props.onHide();
      console.log(datatypeCategory);
      console.log(index);
    };
    
    const OnDeletetype = (id:any) => {
    
      ServiceTypeCategory.remove(id)
        .then((response: any) => {
          console.log(response.data);
          refreshList();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
    
      return(
            <>
            <Row className="mb-3">
          
          <Stack  direction="horizontal" gap={2}>
                    <Form.Control className="me-auto"
                      placeholder="Filtre le Categorie..."
                      value={searchtype}
                       onChange={onChangeSearchtype} 
                      />
                    <Button variant="outline-dark btn-sm"
                     onClick={findBytype}>Cherchez</Button>
             </Stack>   
            <div className="mt-3">
              
            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
            <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
              <th>Type</th>
              <th>Catégorie</th>
              <th>Accord requis ?</th>
              <th>Exclu ?</th>

              </tr>
            </thead>
            <tbody>
            {type.length>0 ? (
              type.map((i, index) => (
                  
                    <tr key={i.idSoins }>
                      
                    <td>{i.typePrestationsName}</td>
                    <td>{i.intitule}</td>
                    <td>{i.acc}</td>
                    <td>{i.excl}</td>
                    <td>
                    <Stack direction="horizontal" gap={2}>
                    <Button className="me-4 btn btn-success btn-sm btn-block"
                    onClick={() => setActivetype(i, index)}>Edit</Button>
    
                      <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeletetype(i.idSoins)}>Delete</Button>
                    </Stack>
                    </td>
                  </tr>
                ))
              ) : ( 
                <tr>
                  <td colSpan={3}>No type</td>
                </tr>
              )}
    
            </tbody>
          </Table>
          </Row>
    
            </>
        );
    }

