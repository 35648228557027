import { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Iinsurance from "../../../Components/ASSURANCES/CreateInsurance/Models/Iinsurance";
import { TableTypeInsurance } from "../../../Components/ASSURANCES/CreateInsurance/Tables/TableTypeInsurance";
import { AddInsurance } from "../../../Components/ASSURANCES/CreateInsurance/TypeOperations/AddInsurance";
import { EditInsurance } from "../../../Components/ASSURANCES/CreateInsurance/TypeOperations/EditInsurance";

const initialTypeState = {
    id: null,
    insuranceName: '',
    status: '',
    plafond: '',
    statusPlafond: '',
    typeInsurance: '',
    userName: '',
    phoneNumber: '',
    email: '',
    address: '',
    password: '',
    account: '',
    createdBy: 'Ghost Zila',
    dateCreatedBy: new Date()
  };
  

export const CreateInsurance:React.FC=()=>
    {
        const [isSwichInsurance,setSwichInsurance]= useState(true);

        const [EditInsurace, setEditInsurace] = useState<Iinsurance>(initialTypeState);

        const onCurrantInsurance=(data:Iinsurance)=>{
            setEditInsurace(data);
        }

        
        return(
            <>
            <Container fluid>
                <Row>
              <Col> 
              <br />
                <Card>
                    <Card.Body>
                {isSwichInsurance?
                (
                    <AddInsurance />
                ):(
       <EditInsurance
         show={isSwichInsurance} 
         onHide={() => setSwichInsurance(true)}
         EditInfo={EditInsurace} /> 
                )
  }
         <TableTypeInsurance show={isSwichInsurance} 
          onHide={() => setSwichInsurance(false)}
         onEditType={onCurrantInsurance}
          />
                    </Card.Body>
                </Card>
                <br />
                </Col>
           
            </Row>
            
            </Container>
            </>
        );
    }