import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelSejourVisite from "../Models/IdataModelSejourVisite";
const getAll = (params:any) => {
  return http.get<Array<IdataModelSejourVisite>>("/InOneSejourVisites", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelSejourVisite>>("/sortedInOneSejourVisites");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelSejourVisite>>("/InOneSejourVisitesAccount");
}

const create = (data: IdataModelSejourVisite) => {
  return http.post<IdataModelSejourVisite>(`/InOneSejourVisites`, data);
};


const update = (id: any, data: IdataModelSejourVisite) => {
  return http.put<any>(`/InOneSejourVisites/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/InOneSejourVisites/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/InOneSejourVisites`);
};

const findByTypeTypeName = (typeName: string) => {
  return http.get<Array<IdataModelSejourVisite>>(`/InOneSejourVisites?typeName=${typeName}`);
};

const TypeService = {
  getAllTypeWithoutPagination,
  getLastAccountType,
  getAll,
  create,
  update,
  remove,
  removeAll,
  findByTypeTypeName,
};

export default TypeService;