import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import ClasseeDto from "../../../Components/ASSURANCES/Recouvrements/ClasseeRemboursements/Pharamacies/Models/ClasseeDto";
import { Add } from "../../../Components/ASSURANCES/Recouvrements/ClasseeRemboursements/Pharamacies/Operations/Add";
import { Edit } from "../../../Components/ASSURANCES/Recouvrements/ClasseeRemboursements/Pharamacies/Operations/Edit";
import { TableOperations } from "../../../Components/ASSURANCES/Recouvrements/ClasseeRemboursements/Pharamacies/Tables/TableOperations";
const initialTypeStatedto = { 
    id:0,
       money:0,
        moisPayer:'',
        comment:'',
        createdBy:'',
        dateCreated:'',
        timeCreated:'',
      // for pharmacy
        pharmacyId:0,
        pharmacyName:'',
    // for enterprise
        socialinsuranceId:0,
         socialInsuaranceName:'',
};
export const RemboursementPharmacy:React.FC=()=>{

  
    const [EditListAdherant,setEditListAdherant] = 
    useState<ClasseeDto>(initialTypeStatedto)

    const onCurrentAdhesion = (onCurrant: ClasseeDto) => {
        setEditListAdherant(onCurrant);
      };

    const [isSwichLayerAdherant,setSwichLayerAdherant] = useState(true);
    
    return(
        <>
        <Container fluid>
 <Row>  
                <Col> 
                <br />
                <Card>
            <Card.Body>
            {isSwichLayerAdherant?
                (
                <Add/>
                ):(
<Edit
 show={isSwichLayerAdherant}
   onHide={() => setSwichLayerAdherant(true)}
   EditInfo={EditListAdherant} /> 
                )
                }
 <TableOperations
  show={isSwichLayerAdherant}
  onHide={() => setSwichLayerAdherant(false)}
  onEdit={onCurrentAdhesion}
          />
   </Card.Body>
        </Card>  <br />
                   </Col>

            </Row>
          
        </Container>
        </>
    );
}