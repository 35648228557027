import { Row, Table, Stack, Form, InputGroup } from "react-bootstrap"
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

import Pagination from "@material-ui/lab/Pagination/Pagination";
import EnterpriseService from "../Services/EnterpriseService";
import IdataModeEnterpriseDtos from "../Models/IdataModeEnterpriseDtos";
import ReactToPrint from "react-to-print";
import {Add, EditAttributesOutlined, FileUpload, NewReleases, VerifiedUserOutlined } from "@mui/icons-material";
import { AdherantPrencipalCotisation } from "../../../../Pages/ASSURANCES/AdherantPrencipales/AdherantPrencipalCotisation";

const initialTypeStateDtos = {
  ////table socialInsurance ta
  idSociete:0,
  societeName:0,
 //table enterpriseAssurance   ta1
idInterprise:0,
accountInterprise:0,
  enterpriseName: '',
  responsable: '',
  email: '',
  phoneNumber: '',
  couvertureDu: '',
  couvertureAau: '',
  address: '',
  autreInformation: '',
  resilier: '',
  dataResiliation: '',
     dateCreatedBy: new Date,
  createdBy: '',
  firstCallForPayment: '',
  secondCallForPayment: '',
  thirdCallForPayment: '',
  modePayment: '',
  frequancePayment: '',
 ////// configuration of assurance start
  primeIndividuelle: '',
  primeTotal: '',
  soinsAmbilatoire: '',
  hospitalisation: '',
  ophtalmologie: '',
  dentisterie: '',
  fraisFuneraires: ''
};
const columns = [
  ////table socialInsurance ta
  { header: ' societeName', key: 'societeName' },
 //table enterpriseAssurance   ta1
{ header: ' idInterprise', key: 'idInterprise' },
{ header: ' accountInterprise', key: 'accountInterprise' },
  { header: ' enterpriseName', key: 'enterpriseName' },
  { header: ' responsable', key: 'responsable' },
  { header: ' email', key: 'email' },
  { header: ' phoneNumber', key: 'phoneNumber' },
  { header: ' couvertureDu', key: 'couvertureDu' },
  { header: ' couvertureAau', key: 'couvertureAau' },
  { header: ' address', key: 'address' },
  { header: ' autreInformation', key: 'autreInformation' },
  { header: ' resilier', key: 'resilier' },
  { header: ' dataResiliation', key: 'dataResiliation' },
  { header: ' CallForPayment', key: 'CallForPayment' },
  { header: ' secondCallForPayment', key: 'secondCallForPayment' },
  { header: ' thirdCallForPayment', key: 'thirdCallForPayment' },
  { header: ' modePayment', key: 'modePayment' },
  { header: ' frequancePayment', key: 'frequancePayment' },
 ////// configuration of assurance start
  { header: ' primeIndividuelle', key: 'primeIndividuelle' },
  { header: ' primeTotal', key: 'primeTotal' },
  { header: ' soinsAmbilatoire', key: 'soinsAmbilatoire' },
  { header: ' hospitalisation', key: 'hospitalisation' },
  { header: ' ophtalmologie', key: 'ophtalmologie' },
  { header: ' dentisterie', key: 'dentisterie' },
  { header: ' fraisFuneraires', key: 'fraisFuneraires' }
];

const workSheetName = 'enterprise';
//const workBookName = 'MyWorkBook';
//const myInputId = 'myInput';
interface Ienterprise{
     componentToPrint: React.MutableRefObject<null>;
}
export const TableEnterpriseCotisation:React.FC<Ienterprise>=props=>{

  const workbook = new Excel.Workbook();

  const { componentToPrint } = props;

  const [Enterprise, setEnterprise] = useState<Array<IdataModeEnterpriseDtos>>([]);

  const [Enterprisepush, setEnterprisepush] = useState<IdataModeEnterpriseDtos>(initialTypeStateDtos);
  const [modalShow, setModalShow] = useState(false);

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchEnterprise, setSearchEnterprise] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];

  const getRequestParams = (socialinsuranceId:any,searchenterpriseName:string, thepage: number, thepageSize: number) => {
    let params = {
      socialinsuranceId:socialinsuranceId,
      enterpriseName:searchenterpriseName,
      page: thepage,
      size:thepageSize
    };
  
    if (socialinsuranceId) {
      params["socialinsuranceId"] = socialinsuranceId;
    }
    if (searchenterpriseName) {
      params["enterpriseName"] = searchenterpriseName;
    }
  
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearchEnterprise = (e: ChangeEvent<HTMLInputElement>) => {
    const searchEnterprise = e.target.value;
    setSearchEnterprise(searchEnterprise);
  };
  
  const findByEnterpriseName = () => {
    EnterpriseService.findByEnterpriseName(52,searchEnterprise)
      .then((response: any) => {
        const {enterprises } = response.data;
        setEnterprise(enterprises);
        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const retrieveEnterprise = useCallback(() => {
    const params = getRequestParams(52,searchEnterprise, page, pageSize);
    EnterpriseService.getAll(params)
      .then((response: any) => {
        const { enterprises, totalPages } = response.data;
        setEnterprise(enterprises);
        setCount(totalPages);
      //  console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [getRequestParams, page, pageSize, searchEnterprise]);

  useEffect(() => {
    retrieveEnterprise();
  }, [retrieveEnterprise]);

  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  

  const pushCurrantEnterprise= (currantenterprise: IdataModeEnterpriseDtos, index: number) => {
    setCurrentIndex(index); 
    setEnterprisepush(currantenterprise);
    setModalShow(true);
  };

 
  const saveExcel = async () => {
    try {
     // const myInput = document.getElementById(myInputId);
      const fileName = "Enterprise";

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach(column => {
       // column.width = column.header.length + 5;
        column.alignment = { horizontal: 'center' };
      });

      // loop through data and add each one to worksheet
      Enterprise.forEach(singleData => {
        worksheet.addRow(singleData);
      });
/*
      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, row => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell: { _address: any; }) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });
*/
      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
    //  console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };
    return(
          <>
          <Row>
        
        <Stack  direction="horizontal" gap={2}>
          
        <Form.Group controlId="formFilter" className="col col-sm-3">
            <InputGroup>
                <Form.Control size="sm"
                 type="text"
                  name="searchEnterprise" 
                    placeholder="Filtre Enterprise"
                  value={searchEnterprise}
                   onChange={onChangeSearchEnterprise} />
            </InputGroup>
        </Form.Group>

          <Button  size="small" variant="contained"  color="primary"
          onClick={findByEnterpriseName}>Cherchez</Button>
              {"    Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
  <ReactToPrint
          trigger={() => (
            <Button  size="small" id={"print"} variant="contained" color="primary">
              {" "}{" "}
              {"Print"}{" "}
            </Button>
          )}
          content={() => componentToPrint.current}
          documentTitle="LES EntrePrises"
        />
        <Button   variant="outlined" startIcon={<FileUpload/>} onClick={saveExcel}>
              {"Export"}
            </Button>
</Stack> 

         <span ref={(el) => (componentToPrint.current = el as null )}> 
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>Assurance</th>
              <th>Enterprise</th>
              <th>Code</th>
              <th>responsable</th>
              <th>resilier</th>
              <th>email</th>
              <th>phoneNumber</th>
              <th>couverture debit</th>
              <th>couverture fin</th>
              <th>Mode</th>
              <th>Frequence</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
          {Enterprise.length>0 ? (
            Enterprise.map((i, index) => (
                
                  <tr key={i.idInterprise }>
                  <td>{i.societeName}</td>
                  <td>{i.enterpriseName}</td>
                  <td>{i.accountInterprise}</td>
                  <td>{i.responsable}</td>
                  <td>{i.resilier}</td>
                  <td>{i.email}</td>
                  <td>{i.phoneNumber}</td>
                  <td>{i.couvertureDu}</td>
                  <td>{i.couvertureAau}</td>
                  <td>{i.modePayment}</td>
                  <td>{i.frequancePayment}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>

                      <Button  variant="outlined" startIcon={<Add/>}
                  onClick={() => pushCurrantEnterprise(i, index)}>add</Button>
               </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no Data</td>
              </tr>
            )}
          </tbody>
        </Table>         
        </span>
<AdherantPrencipalCotisation
 show={modalShow}
 onHide={() => setModalShow(false)}
 editinfo={Enterprisepush} 
 />
        </Row>
  
          </>
      );
  }