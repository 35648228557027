import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import React from "react";
import Excel from 'exceljs';
import saveAs from "file-saver";

import { RiSearch2Line } from 'react-icons/ri';
import { IoIosDownload } from 'react-icons/io';

import { useHistory } from 'react-router-dom';
import IdataModeEnterprise from "../../Entreprises/Models/IdataModeEnterprise";
import EnterpriseService from "../../Entreprises/Services/EnterpriseService";
import ServiceCotisationAssurance from "../PreparationFacture/Services/ServiceCotisationAssurance";
import CotisationAssuranceDto from "../PreparationFacture/Models/CotisationAssuranceDto";

const defaultinitiaType: Array<IdataModeEnterprise> = [];
  

export const CompListeCotisationClassOuNoClassParEnterprise:React.FC=()=>{
      
const [isCotisationAssuranceDto, setisCotisationAssuranceDto] = useState<Array<CotisationAssuranceDto>>([]);

const [isCotisationAssuranceDtoBetween, setisCotisationAssuranceDtoBetween] = useState<Array<CotisationAssuranceDto>>([]);

const [enterpriseId, setEnterpriseId] = useState('');

const [statusName, setStatus] = useState('');

const [enterprise, setEnterprise] = useState(defaultinitiaType);

const [groupedDataBETWEEN, setGroupedDataBETWEEN] = useState<Map<string, CotisationAssuranceDto[]>>(new Map());
  
 const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


const getRequestParamsBetweenDate = 
    (
      socialinsuranceId:number,
      statusClassee:string,
      startDate: string, 
      endDate: string,
      enterprisesAssuranceId:any) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    statusClassee:statusClassee,
    startDate:startDate,
    endDate:endDate,
    enterprisesAssuranceId:enterprisesAssuranceId
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (statusClassee) {
    params["statusClassee"] = statusClassee;
  }
  if (startDate) {
    params["startDate"] = startDate;
  }

  if (endDate) {
    params["endDate"] = endDate;
  }

  if (enterprisesAssuranceId) {
    params["enterprisesAssuranceId"] = enterprisesAssuranceId;
  }


  return params;
};

    const  handleChangeEnterpriseId = (e: ChangeEvent<HTMLSelectElement>) => {
      setEnterpriseId(e.target.value);
    };
   const handleChangeStatus = (event: any) => {
  setStatus(event.target.value);
};

    const getWithoutPagination = () => {  
      EnterpriseService.getAllEnterpriseNameWithoutPagination()
        .then((response: any) => {
          setEnterprise(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

const findAllBetween = () => {

    const params = getRequestParamsBetweenDate(52,statusName, startDate, endDate,enterpriseId);

    ServiceCotisationAssurance.getAllWithoutPaginationStatusClasseeBetweenParEnterprise(params)
    .then((response: any) => {

      setisCotisationAssuranceDtoBetween(response.data);

      setisCotisationAssuranceDto(response.data);

      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
    
};

// Usage in the component

useEffect(() => {
  getWithoutPagination();
},);

const totalBetween = isCotisationAssuranceDtoBetween.length;
const totalMoneyBetween = isCotisationAssuranceDtoBetween.reduce((acc: any, item: { money: any; }) => acc + item.money, 0);


const exportToExcelLogic = () => {

if(startDate==="" && endDate===""){
console.log("please shouse Dates in between")

}else{
exportToExcelBETWEEN()
};

};

const exportToExcelBETWEEN = () => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('@ALLY_Cotisation_ParEnterprise');
  worksheet.properties.tabColor = { argb: 'FF0000FF' }; // Set tab color to blue
 
  // Add header row
  const headerRow = worksheet.addRow([
    'temps cree', 'Date Created','Mois Payer','Classee','Money', 'Enterprise', 'Assurance','Created By','comment'
  ]);

  // Grouped data iteration
  groupedDataBETWEEN.forEach((groupItems) => {
     
      // Add rows for individual items in the group
      groupItems.forEach((mydata) => {
          worksheet.addRow([
              mydata.timeCreated,
              mydata.dateCreated,
              mydata.moisPayer,
              mydata.statusClassee,
              mydata.money,
              mydata.enterpriseName,
              mydata.socialInsuaranceName,
              mydata.createdBy,  
              mydata.comment,
          ]);
      });

      // Add empty row for visual separation between groups
     // worksheet.addRow([]);
      // Calculate and add total row
      const groupSums = calculateGroupSums(groupItems);

      const totalRow =  worksheet.addRow([
          'Cotisation total', '','', '', '', '', '', '', '', '', '', '', 'Total','',
          groupSums.moneySum,
      ]);
       // Apply style to the total row
       totalRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECFCEB' } // Green color
    };
    totalRow.eachCell((cell) => {
      cell.font = { color: { argb: 'FF008000' } }; // Green text color
  });
  });
   // Add empty row for visual separation
      worksheet.addRow([]);

   // Calculate and add total row
   const totalRow =  worksheet.addRow([
       'DECLARATION', '','', totalBetween, '', '', '', '', '', '', '', '', 'Total A declare',
       '',
       totalMoneyBetween,
   ]);
    // Apply style to the total row
    totalRow.fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: 'FFECFCEB' } // Green color
 };
 totalRow.eachCell((cell) => {
   cell.font = { color: { argb: 'FF008000' } }; // Green text color
});

  // Auto-fit column widths based on content
  worksheet.columns.forEach((column) => {
    if (column && column.eachCell) { // Check if column and column.eachCell are not undefined
        let dataMax = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            let columnLength = 0;
            if (cell.value instanceof Date) {
                columnLength = cell.value
                    .toISOString()
                    .slice(0, 10)
                    .replace(/-/g, '.').length;
            } else {
                columnLength = String(cell.value).length;
            }
            if (columnLength > dataMax) {
                dataMax = columnLength;
            }
        });
        column.width = dataMax < 10 ? 15 : dataMax + 8;
    }
});

  // Save the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, '@ALLY_Cotisation_ParEnterprise.xlsx');
  });
};

const calculateGroupSums = (groupItems: CotisationAssuranceDto[]): { moneySum: number } => {
  let moneySum = 0;

  groupItems.forEach((mydata) => {
    moneySum += mydata.money;
  });

  return { moneySum };
};

useEffect(() => {
    // Update groupedData whenever fetched data changes
    const groupDataBETWEEN = () => {
      const grouped = new Map<string, CotisationAssuranceDto[]>();
      isCotisationAssuranceDtoBetween.forEach((mydata) => {
        const key = `${mydata.id} `;
        const existingGroup = grouped.get(key) || [];
        existingGroup.push(mydata);
        grouped.set(key, existingGroup);
      });
      setGroupedDataBETWEEN(grouped);
    };

    groupDataBETWEEN();
  }, [isCotisationAssuranceDtoBetween]);

  return(
        <>
        
        <Row className="mb-3">
          <div className="d-flex flex-column align-items-start">
            <Stack direction="horizontal" gap={2}>
              <Form.Select
                className="form-control"
                onChange={handleChangeEnterpriseId}
              >
                <option value="0">Enterprise..</option>
                {enterprise &&
                  enterprise.map((enterprise) => (
                    <option key={enterprise.id} value={enterprise.id}>
                      {enterprise.enterpriseName}
                    </option>
                  ))}
              </Form.Select>
              <Form.Select
                aria-label="Status"
                onChange={handleChangeStatus}
              >
                <option value="0">Status..</option>
                <option value=" oui">Classee</option>
                <option value="no ">No Classee</option>
              </Form.Select>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <Button
                className="btn-sm rounded-pill mr-3"
                variant="outline-success"
                onClick={findAllBetween}
              >
                <RiSearch2Line size={25} />
              </Button>
            </Stack>
          </div>
        </Row>
  
        <Button
          className="btn-sm rounded-pill mb-3"
          variant="outline-success"
          onClick={exportToExcelLogic}
        >
          <IoIosDownload size={25} />
          Download
        </Button>
        
        <div className="row row-sm mb-2">
          <div className="col-sm-12 col-lg-12">
            <div className="table-responsive table-sm table-wrapper-scroll-y my-custom-scrollbar">
              <Table
                bordered
                className="table table-sm table-hover table-striped"
              >
                <thead style={{ background: "#ecfceb" }}>
                  <tr className="text-center">
                    <th>Date Created</th>
                    <th>Time Created</th>
                    <th>Month Paid</th>
                    <th>Classee</th>
                    <th>Money</th>
                    <th>Enterprise Name</th>
                    <th>Social Insurance Name</th>
                    <th>Created By</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {isCotisationAssuranceDtoBetween &&
                    isCotisationAssuranceDtoBetween.map((mydata, index) => (
                      <tr key={index}>
                        <td>{mydata.dateCreated}</td>
                        <td>{mydata.timeCreated}</td>
                        <td>{mydata.moisPayer}</td>
                        <td>{mydata.statusClassee}</td>
                        <td>{mydata.money}</td>
                        <td>{mydata.enterpriseName}</td>
                        <td>{mydata.socialInsuaranceName}</td>
                        <td>{mydata.createdBy}</td>
                        <td>{mydata.comment}</td>
                      </tr>
                    ))}
                  <tr style={{ background: "#ebecf1" }}>
                    <td colSpan={4}>Cotisation Classee Total</td>
                    <td>{totalMoneyBetween}</td>
                    <td colSpan={4}></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        </>
    );
}