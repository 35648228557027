import React, { ReactNode } from 'react';
import { Navbar, Nav, Offcanvas, Button} from 'react-bootstrap'; // Import Button from react-bootstrap
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faLock } from '@fortawesome/free-solid-svg-icons';
import Footer from '../Footer/Footer';
import { useSwipeable } from 'react-swipeable';

import { useKeycloak } from '@react-keycloak/web';
import { RootState } from '../../Redux/Store/store';
import { useSelector } from 'react-redux';
import {  faBriefcase, faUser, faCog, faHospital, faMoneyBill, faFileAlt, faHeart, faBuilding, faTools, faCalendar, faChartBar, faClipboardList, faUserMd, faListAlt } from '@fortawesome/free-solid-svg-icons';
import LinkItem from './LinkItem';

const yourLinksArray = [
  { to: '/', label: 'Dashboard', icon: faHome, menulabel: 'Dashboard', menuicon: faHome, roles: ['admin'] },
  { to: '/AfficherlesVisitesdepatients', label: 'Visites de Patients', icon: faUser, menulabel: 'Patients', menuicon: faUser, roles: ['admin'] },
  { to: '/AjouterTypeDoctorPage', label: 'Doctor', icon: faUserMd, menulabel: 'Parameters', menuicon: faCog, roles: ['admin'] },
  { to: '/categories', label: 'Categories', icon: faListAlt, menulabel: 'Parameters', menuicon: faCog, roles: ['admin'] },
  { to: '/structures', label: 'Structures', icon: faBuilding, menulabel: 'Parameters', menuicon: faCog, roles: ['admin'] },
  { to: '/materiel', label: 'Materiel-Depertments-Services', icon: faTools, menulabel: 'Parameters', menuicon: faCog, roles: ['admin'] },
  { to: '/actes', label: 'Actes', icon: faHeart, menulabel: 'Parameters', menuicon: faCog, roles: ['admin'] },
  { to: '/types', label: 'Types', icon: faClipboardList, menulabel: 'Parameters', menuicon: faCog, roles: ['admin'] },
  
  { to: '/typeMedicament', label: 'Type Medicament', icon: faClipboardList, menulabel: 'Parameters', menuicon: faCog, roles: ['admin'] },

  { to: '/createInsurance', label: 'Create Insurance', icon: faFileAlt, menulabel: 'Enterprise & Insurance', menuicon: faBriefcase, roles: ['admin'] },
  { to: '/fraisAdhesions', label: 'Frais de Adhesions', icon: faMoneyBill, menulabel: 'Enterprise & Insurance', menuicon: faBriefcase, roles: ['admin'] },
  { to: '/modePayment', label: 'Mode Payment', icon: faMoneyBill, menulabel: 'Enterprise & Insurance', menuicon: faBriefcase, roles: ['admin'] },
  { to: '/sejour', label: 'Sejour', icon: faCalendar, menulabel: 'Enterprise & Insurance', menuicon: faBriefcase, roles: ['admin'] },
  { to: '/bon', label: 'Bon', icon: faClipboardList, menulabel: 'Enterprise & Insurance', menuicon: faBriefcase, roles: ['admin'] },
  { to: '/enterprise', label: 'Enterprises', icon: faBuilding, menulabel: 'Enterprise & Insurance', menuicon: faBriefcase, roles: ['admin'] },
  
  { to: '/FacturationConsultation', label: 'Créer le Bon', icon: faHospital, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin', 'user','verification'] },
  { to: '/saveNonAfflier', label: 'Créer Non Afflier', icon: faHospital, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin', 'user'] },

  { to: '/EspacePatients', label: 'EspacePatients', icon: faHospital, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin', 'user','verification'] },
  { to: '/VisiteEnCours', label: 'Visite en Cours no payer',icon: faHospital, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['user','verification'] },
  
  //{ to: '/FacturationListes', label: 'Listes de Factures payer', icon: faHospital, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin', 'user'] },
    { to: '/DeclationFacture', label: 'Factures A Declare', icon: faHospital, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin', 'user','verification'] },
    { to: '/DeclationFactureDepertment', label: 'Consommation Par Deperment', icon: faHospital, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin', 'user','verification'] },
    { to: '/SyntheseDeclaration', label: 'Synthese Declration', icon: faHospital, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin', 'user','verification'] },
   

  { to: '/configurations', label: 'Couverture sur Facture de Soins', icon: faCog, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin'] }, 
  { to: '/configurationsPharma', label: 'Couverture sur Facture de Pharmacie', icon: faCog, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin'] }, 
  { to: '/configurationsSurPlafondSoins', label: 'Config surPlafond de Soins', icon: faCog, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin'] }, 
  { to: '/configurationsSurPlafondPharma', label: 'Config surPlafond de Pharmacie', icon: faCog, menulabel: 'Billing', menuicon: faMoneyBill, roles: ['admin'] }, 
  

  { to: '/PlafondYear', label: 'Plafond Annuel', icon: faChartBar, menulabel: 'Billing', menuicon: faChartBar, roles: ['admin'] },
  { to: '/listeMedicament', label: 'Medicament', icon: faHospital, menulabel: 'Medicament', menuicon: faChartBar, roles: ['admin'] },
  // pharmacy

  { to: '/livraison', label: 'Livraison', icon: faHospital, menulabel: 'Medicament', menuicon: faChartBar, roles: ['pharmacy'] },

  { to: '/listLivraison', label: 'Liste de Livraison', icon: faHospital, menulabel: 'Medicament', menuicon: faChartBar, roles: ['admin','pharmacy','verification'] },
  { to: '/SyntheseDeclarationLivraison', label: 'Synthese Livraison', icon: faHospital, menulabel: 'Medicament', menuicon: faChartBar, roles: ['admin','pharmacy','verification'] },

  //{ to: '/listDeFactureDeclare', label: 'Declaration', icon: faHospital, menulabel: 'Medicament', menuicon: faChartBar, roles: ['admin','pharmacy','verification'] },
  //{ to: '/listDeFactureRembource', label: 'Rembourcement', icon: faHospital, menulabel: 'Medicament', menuicon: faChartBar, roles: ['admin','pharmacy','verification'] },

 //Comptabilite

{ to: '/listCotisationClassee', label: 'Cotisation Classee par Entrep', icon: faHospital, menulabel: 'Comptabilite', menuicon: faChartBar, roles: ['admin'] },
{ to: '/listCotisationPayer', label: 'Cotisation Payer par  Entrep', icon: faHospital, menulabel: 'Comptabilite', menuicon: faChartBar, roles: ['admin'] },

{ to: '/listCotisationClasseeAll', label: 'Cotisation Classee', icon: faHospital, menulabel: 'Comptabilite', menuicon: faChartBar, roles: ['admin'] },
{ to: '/listCotisationPayerAll', label: 'Cotisation Payer', icon: faHospital, menulabel: 'Comptabilite', menuicon: faChartBar, roles: ['admin'] },

{ to: '/listCotisationClasseeInd', label: 'Cotisation Classee Ind', icon: faHospital, menulabel: 'Comptabilite', menuicon: faChartBar, roles: ['admin'] },
{ to: '/listCotisationPayerInd', label: 'Cotisation Payer Ind', icon: faHospital, menulabel: 'Comptabilite', menuicon: faChartBar, roles: ['admin'] },

 // Verification
{ to: '/EspaceVerification', label: 'Espace Verification', icon: faHospital, menulabel: 'Verification', menuicon: faChartBar, roles: ['admin','verification'] },

{ to: '/NoVerifeCombineeHospital', label: "Declaration No Verifier d'Hopital", icon: faHospital, menulabel: 'Verification', menuicon: faChartBar, roles: ['admin','verification'] },
{ to: '/VerifeCombineeHospital', label: "Declaration Verifie d'Hopital", icon: faHospital, menulabel: 'Verification', menuicon: faChartBar, roles: ['admin','verification'] },

{ to: '/NoVerifieCombineePharmacy', label: "Declaration No Verifie de pharmacie", icon: faHospital, menulabel: 'Verification', menuicon: faChartBar, roles: ['admin','verification'] },
{ to: '/VerifieCombineePharmacy', label: "Declaration Verifie de pharmacie", icon: faHospital, menulabel: 'Verification', menuicon: faChartBar, roles: ['admin','verification'] },

  //Recouvremnt

  { to: '/cotisationEntreprise', label: 'Cotisation entreprise', icon: faHospital, menulabel: 'Recouvrment', menuicon: faChartBar, roles: ['admin'] },

  { to: '/cotisationEntrepriseDetails', label: 'cotisation Entreprise Details', icon: faHospital, menulabel: 'Recouvrment', menuicon: faChartBar, roles: ['admin'] },

  { to: '/cotisationIndependant', label: 'Cotisation Independant', icon: faHospital, menulabel: 'Recouvrment', menuicon: faChartBar, roles: ['admin'] },

  { to: '/remboursementHospital', label: 'Remboursement Hospital', icon: faHospital, menulabel: 'Recouvrment', menuicon: faChartBar, roles: ['admin'] },

  { to: '/remboursementPharmacy', label: 'Remboursement Pharmacy', icon: faHospital, menulabel: 'Recouvrment', menuicon: faChartBar, roles: ['admin'] },

  // Consomation
  { to: '/consommationsAdherantPrincipal', label: 'Par adherantPrincipal', icon: faHospital, menulabel: 'Consommation', menuicon: faChartBar, roles: ['admin'] },
  { to: '/consommationsAdherantPrincipalDetails', label: 'Par adherant Principal Details', icon: faHospital, menulabel: 'Consommation', menuicon: faChartBar, roles: ['admin'] },
  { to: '/consommationsDepertments', label: 'Par Depertments', icon: faHospital, menulabel: 'Consommation', menuicon: faChartBar, roles: ['admin','user'] },

  { to: '/consommationsDepertmentServices', label: 'Par Depertment et Services', icon: faHospital, menulabel: 'Consommation', menuicon: faChartBar, roles: ['admin'] },
  { to: '/consommationsEnterprise', label: 'Par enterprise', icon: faHospital, menulabel: 'Consommation', menuicon: faChartBar, roles: ['admin'] },
  { to: '/consommationsEnterpriseDetails', label: 'par enterprise Details', icon: faHospital, menulabel: 'Consommation', menuicon: faChartBar, roles: ['admin'] },
  { to: '/consommationsServices', label: 'Par Services', icon: faHospital, menulabel: 'Consommation', menuicon: faChartBar, roles: ['admin'] },


  // Statistique sur les Affilies
  { to: '/adherantPrincipalCodeCategory', label: 'Adhe P CodeCategory', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },
  { to: '/adherantPrincipalCodeCategoryMarie', label: 'Adhe P CodeCategory Marie', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },
  { to: '/adherantPrincipalCodeCategorySoins', label: 'Adhe P CodeCategorySoins', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },
  { to: '/adherantPrincipalCodeCategorySoinsMarie', label: 'Adhe P CodeCategorySoins Marie', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },
  
  { to: '/ayantDroitCategorySoins', label: 'AyantDroit CategorySoins', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },
  { to: '/ayantDroitCategorySoinsSurPLusEnfant', label: 'AyantDroit CategorySoins PLusEnfant', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },
  { to: '/ayantDroitCodeCategory', label: 'AyantDroit CodeCategory', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },
  { to: '/ayantDroitCodeCategorySoins', label: 'AyantDroit CodeCategorySoins', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },
  { to: '/ayantDroitCodeCategorySurPlusEnfant', label: 'AyantDroit CodeCategory PlusEnfant', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },
  { to: '/ayantDroitCodeSoinsMarie', label: 'AyantDroit CodeSoins Marie', icon: faHospital, menulabel: 'StatistiquesAffilies', menuicon: faChartBar, roles: ['admin'] },

];

interface MySidebarProps {
  children: ReactNode;
}

const MySidebar: React.FC<MySidebarProps> = ({ children }) => {

  const [showOffcanvas, setShowOffcanvas] = React.useState(false);
  const [activeLink, setActiveLink] = React.useState<string>('');
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [selectedItem, setSelectedItem] = React.useState<string>('');

  const UserRolesData = useSelector((state: RootState) => state.userRoles.data);
  const preferredName = useSelector((state: RootState) => state.user.preferred_username);

  const ClientRolesData = useSelector((state: RootState) => state.clientRoles.data);

  const RealmRolesData = useSelector((state: RootState) => state.realmRoles.data);

  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  let MonStructure:any;
  if(StructuresData.length===1){
     MonStructure=StructuresData
    }else{
       MonStructure='Tous'
    }
  const userInfo = useSelector((state: RootState) => state.user);
  
    console.log('ClientRolesDatale',ClientRolesData)
   console.log('RealmRolesData',RealmRolesData)
  console.log('UserRole',UserRolesData)
  console.log('StructuresData',StructuresData)
  console.log('UserInfo',userInfo)


  const { keycloak } = useKeycloak();


  // Define a type for the link
type LinkRouter = { 
   to: string;
  label: string;
  icon: any;
  menuicon: any; // Adjust the type according to the actual type of your menu icons
  menulabel: string;
  roles: string[] 
};
// Filter links based on roles
let yourArrayLinkRole: LinkRouter[] = yourLinksArray.filter(link =>
  link.roles.some(role => UserRolesData.includes(role))
);
  
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    // Implement your search logic here
  };
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  // Filter links based on user roles

  const handleNavItemClicked = (link: string) => {
    handleCloseOffcanvas();
    setActiveLink(link);
    setSelectedItem(link);
  };
  

  const linkStyles = {
    color: '#333',
    textDecoration: 'none',
  };

  const iconStyles = {
    marginRight: '8px',
  };
// Initialize the swipe handlers
const handlers = useSwipeable({
  onSwipedRight: () => handleShowOffcanvas(),
});
const handleLogin = async () => {
  try {
    await keycloak.init({ onLoad: 'login-required' });
    console.log('User successfully logged in:', keycloak.subject);
    // Perform any additional actions after a successful login
  } catch (error) {
    console.error('Login failed:', error);
  }
};

const handleLogout = async () => {
  try {
    await keycloak.logout();
    console.log('User successfully logged out');
    // Perform any additional actions after a successful logout
  } catch (error) {
    console.error('Logout failed:', error);
  }
};


const groupedLinksRole = yourArrayLinkRole.reduce<{ [key: string]: LinkItem[] }>((acc, link) => {
  const groupKey = link.menulabel;

  if (!acc[groupKey]) {
    acc[groupKey] = [];
  }

  acc[groupKey].push(link);

  return acc;
}, {});

  return ( <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} {...handlers}>
  <Navbar bg="light" expand="lg" className="px-4 py-2 fixed-top" style={{ zIndex: 1031 }}>
  
        <Navbar.Brand onClick={handleShowOffcanvas}>
          <FontAwesomeIcon icon={faHome} style={iconStyles} />
          Hema Sante   {selectedItem}
         
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav.Link
            as={Link}
            to="/"
            onClick={() => handleNavItemClicked('/')}
            style={{ ...linkStyles, fontWeight: activeLink === '/' ? 'bold' : 'normal', paddingLeft: '8px' }}
            className="mr-3"
          >
            <FontAwesomeIcon icon={faHome} style={iconStyles} />
            Dashbord
          </Nav.Link>
          
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <div>
            {keycloak.authenticated ? (
              <>
              <Navbar.Text className="mr-2" style={{ marginInlineEnd: '46px' }}>Access au Structure: {MonStructure}</Navbar.Text>
                <Navbar.Text className="mr-2" style={{ marginInlineEnd: '16px' }}>Signed in as: {preferredName}</Navbar.Text>
                <Button variant="outline-primary" onClick={handleLogout} className="ml-2">
                  Logout
                </Button>
              </>
            ) : (
              <Button variant="primary" onClick={handleLogin}>
                Login ...
              </Button>
            )}
          </div>
        </Navbar.Collapse>



      </Navbar>

<Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="start">
 
<Offcanvas.Header closeButton>
  
  <div className="d-flex flex-column align-items-start">
  <div>
            {keycloak.authenticated ? (
              <>
  <Offcanvas.Title className="d-flex flex-column align-items-start">Signed in as:  {preferredName}</Offcanvas.Title>
  <Offcanvas.Title className="d-flex flex-column align-items-start">Access au Structure:  {MonStructure}</Offcanvas.Title>

    <Nav.Link onClick={handleLogout} style={{ ...linkStyles, marginTop: '8px',marginBottom: '8px' }}>
      <FontAwesomeIcon icon={faLock} style={iconStyles} />
      Fin de session
    </Nav.Link>
              </>
            ) : (
              <Button variant="primary" onClick={handleLogin}>
                Login ...
              </Button>
            )}
          </div>
  
    <input
      type="text"
      placeholder="Search Page..."
      value={searchTerm}
      onChange={handleSearchChange}
      className="form-control"
    />
    
   
  </div>
</Offcanvas.Header>



<Offcanvas.Body>
  {/* Main Display */}
  {searchTerm === '' ? (
    <>
     <Nav className="flex-column">
      {Object.entries(groupedLinksRole).map(([groupName, groupLinks]) => (
        <React.Fragment key={groupName}>
          <div className="font-weight-bold">
            <FontAwesomeIcon icon={groupLinks[0].menuicon} style={{ marginRight: '8px' }} />
            {groupName}
          </div>
          {groupLinks.map((link) => (
            <Nav.Link
              key={link.to}
              as={Link}
              to={link.to}
              onClick={() => handleNavItemClicked(link.to)}
              style={{
                ...linkStyles,
                fontWeight: link.to === activeLink ? 'bold' : 'normal',
              }}
            >
              <FontAwesomeIcon icon={link.icon} style={iconStyles} />
              {link.label}
            </Nav.Link>
          ))}
        </React.Fragment>
      ))}
    </Nav>
    </>
  ) : (
    <Nav className="flex-column">
    {Object.entries(groupedLinksRole).map(([groupName, groupedLinksRole]) => (
      <React.Fragment key={groupName}>
        <div className="font-weight-bold">
          <FontAwesomeIcon icon={groupedLinksRole[0].menuicon} style={{ marginRight: '8px' }} />
          {groupName}
        </div>
        {groupedLinksRole
          .filter((link) => link.label.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((link) => (
            <Nav.Link
              key={link.to}
              as={Link}
              to={link.to}
              onClick={() => handleNavItemClicked(link.to)}
              style={{
                ...linkStyles,
                fontWeight: link.to === activeLink ? 'bold' : 'normal',
              }}
            >
              <FontAwesomeIcon icon={link.icon} style={iconStyles} />
              {link.label}
            </Nav.Link>
          ))}
      </React.Fragment>
    ))}
  </Nav>
    
  )}
</Offcanvas.Body>

      </Offcanvas>

      {/* Main Content */}
      <div style={{ flex: '1', marginTop: '56px', marginBottom: '56px', overflow: 'auto' }}>
        {children}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default MySidebar;
