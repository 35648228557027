import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import FactureTotalInOneDto from "../../FactureTotalinOne/model/Dto/FactureTotalInOneDto";
import { TableFactureServie } from "../Tables/TableFactureServie";

    
 
 const  FactureServieDtoInit={
    // facture servie Serv
    idfactureServie:'',
    createdByServie:'',
    dateCreatedServie:'',
    timeCreatedServie:'',

    // facture saisie fa
     idfactureSaisie:'',
     prestation:'',
     pricePrestation:'',
     plafondFacture:'',
     ticketService:'',
     quantity:'',
     partMutuelle:'',
     partpatient:'',
     partTotal:'',
     statusFacturePayer:'',
     createdBy:'',
     dateCreatedSaisie:'',
     timeSaisie:'',

    /// hospital h
    idhospital:'',
    hospitalName:'',
    referenceId:'',
    typehospital:'',
    hospitalAccount:'',

    /// docteur
    idDoctorName:'',
    doctorName:'',
    priceConsultation:'',
    accountNameDoctor:'',

    /// typeDoctor
    idtypeDoctor:'',
    typedoctorName:'',
    accountTypeDoctor:'',
    /// couvertureConfig  se
    idCouvertureConfig:'',
    ticket:'',
    particulie:'',
    access:'',
    secter:'',
    plafondOrdonence:'',


    // services ser
    idService:'',
    serviceName:'',
   // code_category co

   idCategoryCode:'',
   categoryCode:'',
   


    //NewVisitePatient n k.receiptBillTracked=:receiptBillTracked
    idNewVisite:'',
    receiptBill:'',
    patientName:'',
    typeAssuree:'',
    patientCode:'',
    CodeSoins:'',
    newVisitecreatedBy:'',
    dateCreatedVisite:'',
    timeCreated:'',

    //social Insuarance so
    idinsurance:'',
    insuranceName:'',
    /// Enterprise   e
     idEnterprise:'',
     accountEnterprise:'',
     enterpriseName:'',
     couvertureDu:'',
     couvertureAau:'',
     modePayment:'',
     frequancePayment:'',

    /// pmember   p
    idpmember:'',
    codeAssuree:'',
    nomPrenomAffilie:'',
    cotisationExact:'',
    accountPmember:'',
    CatégorieSoins:'',
    category_code:'',

    ///// typeBons Visite  tvi
    idVisite:'',
    typeNameVisite:'',
    accountVisite:'',

    ///// typeBon declaration verifivcation rembourcement b
    idtypeBonverification:'',
    typeBonVerificationName:'',
    accountVerificationName:'',
    /// typeBon Commend  tc
    idTypeBonCommend:'',
    typeBonCommendName:'',
    accountBonCommend:'',

    /// depertment dp
    idDepertment:'',
    depertmentName:'',
    ///////////////////////

    // track visite k

    
    /// FactureTotalInOne
    
    ///////////// FactureTotalDetails z
}

const FactureTotalInOneDtoint = {
    

    idNewVisite:'',
    receiptBill:'',
    patientName:'',
    typeAssuree:'',
    patientCode:'',
    CodeSoins:'',
    newVisitecreatedBy:'',
    dateCreatedVisite:'',
    timeCreated:'',

   //social Insuarance so
    idinsurance:'',
    insuranceName:'',
   /// Enterprise   e
    idEnterprise:'',
    accountEnterprise:'',
    enterpriseName:'',
    couvertureDu:'',
    couvertureAau:'',
    modePayment:'',
    frequancePayment:'',

   /// pmember   p
    idpmember:'',
    codeAssuree:'',
    nomPrenomAffilie:'',
    cotisationExact:'',
    accountPmember:'',
    CatégorieSoins:'',

   ///// typeBons Visite  tvi to delete
   ///// typeBon declaration verifivcation rembourcement b to delete
   /// typeBon Commend  tc to delete
   /// depertment dp  to delete

   ///////////////////////
   // track visite k

   /// FactureTotalInOne q
    idFactureInOne:'',
    totalFactureInOne:'',
    totalFactureInOneCouvert:'',
    totalFactureInOneNoCouvert:'',
    totalInOnePartPatient:'',
    totalInOnePartSolis:'',
    totalInOnePartPatientFacture:'',
    totalInOnePartSolisFacture:'',
    inOnecreatedBy:'',
    inOnedateCreated:'',
    inOnetimeCreated:'',

   /// hospital h

    idhospital:'',
    hospitalName:'',
    referenceId:'',
    typehospital:'',
    hospitalAccount:'',
     /// couvertureConfig  se
     idCouvertureConfig:'',
     ticket:'',
     particulie:'',
     access:'',
     secter:'',
     plafondOrdonence:'',

    // services ser
    idService:'',
    serviceName:'',
   // code_category co

   idCategoryCode:'',
   categoryCode:'',
   

    /// depertment dp
    idDepertment:'',
     depertmentName:'',
     mois:''
}
    interface ICurrantFactureTotal {
      show: boolean; 
      onHide: () => void; 
      CurrentFactureTotalInOneDto: FactureTotalInOneDto;
    }
  export const ModalFacturesServie:React.FC<ICurrantFactureTotal>=props=>
    {

      const [modalShow, setModalShow] = useState(false);
      const [isgetCurrentFactureTotalInOneDtoPushed,setgetCurrentFactureTotalInOneDtoPushed]=
      useState<FactureTotalInOneDto>(FactureTotalInOneDtoint);


      const handleClose = () => {
        props.onHide()
        setgetCurrentFactureTotalInOneDtoPushed(FactureTotalInOneDtoint);
        
      }

     
  useEffect(() => {
    setgetCurrentFactureTotalInOneDtoPushed(props.CurrentFactureTotalInOneDto)
    
      },[props.CurrentFactureTotalInOneDto])
        return(
     
          
            <>
            <Container fluid>
            
            <Modal 
     {...props}
    fullscreen
    backdrop="static"
    keyboard={false}
     aria-labelledby="contained-modal-title-vcenter">
      
      <Modal.Body className="show-grid">
      <Container fluid>
      <Row>
                <Col> 
                <div>{isgetCurrentFactureTotalInOneDtoPushed.accountEnterprise}</div>
                
                <TableFactureServie/>
                </Col>

                
      </Row>
      </Container>
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
    </Modal>
            </Container>
   
           
            </>
        );
    }