import { Card, Container } from "react-bootstrap";
import { TableNouvelleVisite } from "../../../Components/Espace_Patients/NouvelleVisite/TableNouvelleVisite/TableNouvelleVisite";

export const EspacePatients=()=>{

    return (
        
      <>
      <Container fluid>
     
      <Card >
          <Card.Header><strong>Espace Patients</strong></Card.Header>
          <Card.Body>
            <TableNouvelleVisite/>  
            </Card.Body>
        </Card>
       
      </Container>
  
      </>
      
);
}