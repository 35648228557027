// store.ts

import { configureStore } from '@reduxjs/toolkit';

import userRolesReducer from '../slices/UserRoles/userSlice';

import clientRolesReducer from '../slices/clientRoles/clientSlice';

import realmRolesReducer from '../slices/RealmRoles/realmSlice';

import structuresReducer from '../slices/GroupStructures/structuresSlice';
import userReduser from'../slices/UserProfile/UserProfileSlice';
import newFactureMutuelleReducer from '../slices/FactureMutuelleDetails/FactureMutuelleDetails';
import detailsHospitalReducer from '../slices/hospitalDetails/hospitalDetails';
import detailsPharmacyReducer from '../slices/pharmacyDetails/pharmacyDetails';
import newFactureSansMutuelleReducer from '../slices/FactureSansMutuelleDetails/FactureSansMutuelleDetails';
import currentNewFactureSaisieReduser from '../slices/currentNewFactureSaisie/currentNewFactureSaisie';
import currentNewFactureReduser from '../slices/currentNewVisite/currentNewFacture';
import currentBillReduser from '../slices/currentBill/currentBill';
import ConfigAddFactureSaisiePrescriptParReduser from '../slices/ConfigAddFactureSaisie/ConfigAddFactureSaisiePrescriptPar'
import ConfigAddFactureSaisiePlafondReduser from '../slices/ConfigAddFactureSaisie/ConfigAddFactureSaisiePlafond'
import ConfigAddFactureSaisieDepertmentReduser from '../slices/ConfigAddFactureSaisie/ConfigAddFactureSaisieDepertment'
import plafondFacturePrServiceReduser from '../slices/plafondFacture/plafondFacturePrService'
import currentMedicalReducer from '../slices/currentMedical/currentMedical'
import currentMedicalArrayReducer from '../slices/currentMedical/currentMedicalArray'
import currentMedicalLivraisonArrayReducer from '../slices/currentMedical/currentMedicalLivraisonArray'
import currentMedicalLivraisonArrayToPrintReducer from '../slices/currentMedical/currentMedicalLivraisonArrayToPrint'
import currentMedicalLivraisonArrayModifieReducer from '../slices/currentMedical/currentMedicalLivraisonArrayModifie'
import currentCouverturepharmaReduser from '../slices/currentCouverturepharma/currentCouverturepharma'
import pMembersSliceReduser from '../slices/pMembers/pMembersSlice'

const store = configureStore({
  reducer: {
    
    userRoles: userRolesReducer,

    realmRoles: realmRolesReducer,

    clientRoles: clientRolesReducer,

    structures: structuresReducer,
    user:userReduser,
    ConfigAddFactureSaisiePrescriptPar:ConfigAddFactureSaisiePrescriptParReduser,
    ConfigAddFactureSaisiePlafond:ConfigAddFactureSaisiePlafondReduser,
    ConfigAddFactureSaisieDepertment:ConfigAddFactureSaisieDepertmentReduser,

    newFactureMutuelle: newFactureMutuelleReducer,
    detailsHospital: detailsHospitalReducer,
    detailsPharmacy: detailsPharmacyReducer,
    newFactureSansMutuelle: newFactureSansMutuelleReducer,
    currentNewFactureSaisie:currentNewFactureSaisieReduser,
    currentNewFacture:currentNewFactureReduser,
    currentBill:currentBillReduser,
    currentMedical:currentMedicalReducer,
    currentMedicalArray:currentMedicalArrayReducer,
    currentMedicalLivraisonArray:currentMedicalLivraisonArrayReducer,
    currentMedicalLivraisonArrayToPrint:currentMedicalLivraisonArrayToPrintReducer,
    currentMedicalLivraisonArrayModifie:currentMedicalLivraisonArrayModifieReducer,
    currentCouverturepharma:currentCouverturepharmaReduser,
    plafondFacturePrService:plafondFacturePrServiceReduser,
    pMembersSlice:pMembersSliceReduser,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch

export default store;

