import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AddCouverture } from "../../../Components/ASSURANCES/Configurations/CouverturestypeMedicament/ConfigOperations/AddCouverture";
import { EditCouverture } from "../../../Components/ASSURANCES/Configurations/CouverturestypeMedicament/ConfigOperations/EditCouverture";
import { TableCouverture } from "../../../Components/ASSURANCES/Configurations/CouverturestypeMedicament/Tables/TableCouverture";
import IdataModelCouvertureDtos from "../../../Components/ASSURANCES/Configurations/CouverturestypeMedicament/Models/IdataModelCouvertureDtos";


const initialTypeStateCouvertureDtos = {
   /// c
   idCouvertureConfig:0,
   ticket:'',
   ticketFacture:'',
   particulie:'',
   access:'',
   secter:'',
   plafondOrdonence:'',
   createdBy:'',
   dateCreated:'',

    // services ser
    idService:'',
    serviceName:'',
   // code_category co

   idCategoryCode:'',
   categoryCode:'',
   
  /// enterprise e
   idEnterprise:0,
   enterpriseName:'',
  //// insurance i
   idInsurance:0,
   insuranceName:''
  };
  
export const ConfigurationsPharma:React.FC=()=>{

    const [EditListCouverture, setEditListCouverture] = useState<IdataModelCouvertureDtos>(initialTypeStateCouvertureDtos);

    const onCurrentCouverture = (data: IdataModelCouvertureDtos) => {
        setEditListCouverture(data);
      };

    const [isCouverture,setCouverture] = useState(true);
    const [isFacture,setFacture] = useState(true);
    const [isFactureMaternite,setFactureMaternite] = useState(true);

    

    return(
        <>
        <Container fluid>
 <Row>
                <Col> 
                <br />
                <Card>
            <Card.Body>
           {isCouverture?
                (
                    <AddCouverture />
                ):(
            <EditCouverture 
            show={isCouverture}  
            onHide={() => setCouverture(true)}
            EditInfo={EditListCouverture}
            /> 
             
                )
  }
         <TableCouverture 
         show={isCouverture}  
         onHide={() => setCouverture(false)}
         onEdit={onCurrentCouverture}
          />
            </Card.Body>
        </Card>  
        <br />
                </Col>

            </Row>

          
        </Container>
        </>
    );
}