import { useEffect, useState } from 'react';
import { InputGroup, Form, Row } from "react-bootstrap";
import DoctorService from '../Services/NamDoctorServices';
import { DropdownTypeDoctorHopital } from '../../Views/DropdownTypeDoctorHopital';
import { QuestionValidation } from '../../../ValidationComponents/QuestionValidation';
import { AcceptValidation } from '../../../ValidationComponents/AcceptValidation';



let accountIncrement=0


const initialNamDoctor = {
    id : '',
    doctorName:'',
    prescriptPar:'',
    accountNameDoctor:'',
    priceConsultation:'',
    createdBy: '',
    dateCreatedBy: new Date()
    };

export const AjoutNomDoctor:React.FC=() =>{

const [isHospitalDropDown,setIsHospitalDropDown]=useState(true);
  
const [modalShowAccept, setModalShowAccept] = useState(false);

const [modalShow, setModalShow] = useState(false);
    const getLastAccountType =()=>{
        DoctorService.getLastAccountType()
          .then((response: any) => {
              accountIncrement=response.data.accountNameDoctor
              console.log(accountIncrement);
        })
      }
      useEffect(() => {
        getLastAccountType();       
      });

    
    const [isInput,setInput]=useState(initialNamDoctor);

    const [IdtypeDoctor,setIdtypeDoctor]=useState(0);
    
  const [isTypeName, setIsTypeName] = useState('');
    const [IdtypeHospital,setIdtypeHospital]=useState(0);

    const updateIdtypeDoctor = (id: any):void => {
      setIdtypeDoctor(id)
 }
 const updateTypeName = (typeName: any):void => {
  setIsTypeName(typeName)
}

  const updateIdtypeHospital=(id: any):void => {
      setIdtypeHospital(id)
 }
 
    
    
    const handleChange = (e: { target: { name: any; value: any; }; }) => {
      setInput({ ...isInput, [e.target.name]: e.target.value });
      console.log(e.target.value);
    }
    
    
      const resetButton = () => {
        setInput(initialNamDoctor)}

        const saveDoctor = () => {  
          if(IdtypeDoctor===0 && IdtypeHospital===0){
            console.log('error while saving data')
          }else{
            if(accountIncrement===undefined){
              accountIncrement=6000000
            }
            let data = {
              doctorName: isInput.doctorName,
              prescriptPar:`${isTypeName}/${isInput.doctorName}`,
              accountNameDoctor: accountIncrement+1,
              priceConsultation:isInput.priceConsultation,
              createdBy:'Ghost ZIla',
              dateCreatedBy: new Date()
            };
            setModalShow(false)
        DoctorService.create(IdtypeDoctor,IdtypeHospital,data)
            .then((response: any) => {
              setInput({
              id: response.data.id,
              doctorName: response.data.doctorName,
              prescriptPar:response.data.prescriptPar,
              accountNameDoctor: response.accountIncrement+1,
              priceConsultation:response.data.priceConsultation,
              createdBy:response.data.createdBy,
              dateCreatedBy: response.data.dateCreatedBy
              });

              console.log(response.data);
              console.log(data);
              setInput(initialNamDoctor);
              setModalShowAccept(true)
            })
            .catch((e: Error) => {
              console.log(e);
            });
          };
        };

        const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
           

    return(
        <>
 < form className="form-edit" onSubmit={onFormSubmit}>
 
         <Row className="mb-3">   
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Nom du Docteur</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="doctorName" name="doctorName" value={isInput.doctorName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formprixConsultation" className="col col-sm-6">
            <Form.Label>prix de Consultation</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="priceConsultation" name="priceConsultation" value={isInput.priceConsultation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
          <DropdownTypeDoctorHopital
          typeDoctorId={IdtypeDoctor} 
          typeHospitalId={IdtypeHospital} 
          updateIdtypeDoctor={updateIdtypeDoctor} 
          updateIdtypeHospital={updateIdtypeHospital}
          updateTypeName={updateTypeName}
          />
         </Row>

         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button type="submit"  onClick={() => setModalShow(true)} className="me-4 btn btn-success btn-sm btn-block">save</button>
            <button type="reset" onClick={resetButton} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
        </Form.Group>

        <QuestionValidation
              show={modalShow}
              onHide={() => setModalShow(false)} 
              onClickYes={saveDoctor}/>
              <AcceptValidation
              show={modalShowAccept}
              onHide={() => setModalShowAccept(false)} 
              />
      </Row>
 </form>
        </>
    );
}