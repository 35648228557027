
import { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { AddTypeMaterial } from "../../Components/Materials/TypeOperations/AddTypeMaterial";
import { EditTypeMaterial } from "../../Components/Materials/TypeOperations/EditTypeMaterial";
import { TypeTableMaterial } from "../../Components/Materials/Tables/TypeTableMaterial";
import IdataModelTypeMateriel from "../../Components/Materials/Models/IdataModelTypeMateriel";
import IdataModelTypeDepertment from "../../Components/HopitalDepertment/Models/IdataModelTypeDepertment";
import IdataModelService from "../../Components/ServicesData/Models/IdataModelService";
import { AddService } from "../../Components/ServicesData/TypeOperations/AddService";
import { EditServie } from "../../Components/ServicesData/TypeOperations/EditServie";
import { TypeTableService } from "../../Components/ServicesData/Tables/TypeTableService";
import { AddDeptment } from "../../Components/HopitalDepertment/Operations/AddDeptment";
import { EditDeptment } from "../../Components/HopitalDepertment/Operations/EditDeptment";
import { TableDepartment } from "../../Components/HopitalDepertment/Tables/TableDepartment";


  const initialMaterialTypeState = {
    id: null,
    typeName: '',
    typeMateriel:'',
    account:'',
    createdBy: 'Ghost Zila',
    dateCreatedBy: new Date()
  };

  const initialservice = {
    id: null,
    typeName: '',
    createdBy: 'Ghost Zila',
    dateCreatedBy: new Date()
  };
  const initialDepertment = {
    id: null,
    typeName: '',
    createdBy: 'Ghost Zila',
    dateCreatedBy: new Date()
  };
  

export const Materiel:React.FC=()=>
    {
        const [isSwichDepartment,setSwichDepartment]= useState(true);
        const [isSwichMaterial,setSwichMaterial] = useState(true);
        const [isSwichService,setSwichService]= useState(true);



        const [EditDepart, setEditDepart] = useState<IdataModelTypeDepertment>(initialDepertment);

        const onCurrantService=(data:IdataModelTypeDepertment)=>{
            setEditDepart(data);
        }
        const [ServiceEdit, setServiceEdit] = useState<IdataModelService>(initialservice);

        const onCurrantDepartment=(data:IdataModelService)=>{
            setServiceEdit(data);
        }

        const [EditMaterial, setEditMaterial] = 
        useState<IdataModelTypeMateriel>(initialMaterialTypeState);

        const onCurrantMaterial=(mydata:IdataModelTypeMateriel)=>{
            setEditMaterial(mydata)
        }
        
        return(
            <>
            <Container fluid>
                <Row>
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                    {isSwichMaterial?
                (
                    <AddTypeMaterial />
                ):(
      <EditTypeMaterial
       show={isSwichMaterial} 
        onHide={() => setSwichMaterial(true)}

      EditInfo={EditMaterial} /> 
                )
  }
         <TypeTableMaterial
          show={isSwichMaterial} 
           onHide={() => setSwichMaterial(false)}
         onEditType={onCurrantMaterial}
          />
 </Card.Body>

                </Card>
                <br />
                </Col>
                
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                {isSwichService?
                (
                    <AddService />
                ):(
      <EditServie 
      show={isSwichService} 
       onHide={() => setSwichService(true)}
       EditInfo={ServiceEdit} /> 
                )
  }
         <TypeTableService 
         show={isSwichService} 
          onHide={() => setSwichService(false)}
         onEditService={onCurrantDepartment}
          />
                    </Card.Body>
                </Card>
                <br />
                </Col>
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                {isSwichDepartment?
                (
                    <AddDeptment />
                ):(
      <EditDeptment
       show={isSwichDepartment} 
        onHide={() => setSwichDepartment(true)}

            EditInfo={EditDepart} /> 
                )
  }
         <TableDepartment
          show={isSwichDepartment} 
           onHide={() => setSwichDepartment(false)}
         onEditType={onCurrantService}
          />
                    </Card.Body>
                </Card>
                <br />
                </Col>

            </Row>
        
            
            </Container>
            </>
        );
    }