import http from "../../../../AxiosRequest/httpcommon";
import FactureTotalInOneDtoPharma from "../model/Dto/FactureTotalInOneDtoPharma";
import FactureTotalInOne from '../model/FactureTotalInOne';


const getAllVisitelWithoutPagination = () => {
  return http.get<Array<FactureTotalInOne>>("/sortedFactureTotalInOnePharmacies");
};


const findBySocialinsuranceIdHospitalNamePage = (params:any) => {
  return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdPharmacyNamePage", { params });
};

const findBySocialinsuranceIdHospitalNameBetweenDateServiePage = (params:any) => {
  return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdHospitalNameBetweenDateServiePagePharmacies", { params });
};

const checkBillNumberByDatePharmacies = (params:any) => {
  return http.get<Array<FactureTotalInOneDtoPharma>>("/checkBillNumberByDatePharmacies", { params });
};

const checkBillNumber = (params:any) => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/checkBillNumberPharmacies", { params });
  };

const findBySocialinsuranceIdHospitalNameBetweenDateServieList = (params:any) => {
  return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdRecieptBetweenDateServieListInOnePharmacies",{ params });
};

const findBySocialinsuranceIdRecieptBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdRecieptBetweenDateServiePageInOnePharmacies", { params });
  };

const findBySocialinsuranceIdRecieptBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdRecieptBetweenDateServieListInOnePharmacies");
  };

  const findBySocialinsuranceIdCodeAssureBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdCodeAssureBetweenDateServiePageInOnePharmacies", { params });
  };

const findBySocialinsuranceIdCodeAssureBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdCodeAssureBetweenDateServieListInOnePharmacies");
  };


  const findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServiePageInOnePharmacies", { params });
  };

const findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServieListInOnePharmacies");
  };

  const findBySocialinsuranceIdBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdBetweenDateServiePageInOnePharmacies", { params });
  };

const findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServieList = () => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServieListPharmacies");
  };

  const findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServiePagePharmacies", { params });
  };

const SocialinsuranceIdBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/SocialinsuranceIdBetweenDateServieListInOnePharmacies");
  };

  const findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServiePageInOne = (params:any) => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServiePageInOnePharmacies", { params });
  };

const findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServieListInOne = () => {
    return http.get<Array<FactureTotalInOneDtoPharma>>("/findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServieListInOnePharmacies");
  };


const findById=(id:any)=>{
  return http.get<Array<FactureTotalInOne>>(`/FactureTotalInOnePharmacies/${id}`);
}

const createFactureTotal = 
(hemaNewVisitePatient_id:any,
  pharmacies_id:any,
  data: FactureTotalInOne) => {
  return http.post<FactureTotalInOne>(`/${hemaNewVisitePatient_id}/${pharmacies_id}/FactureTotalInOnePharmacies`, data);
};


const update = (id: any, data: FactureTotalInOne) => {
  return http.put<any>(`/FactureTotalInOnePharmacies/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/FactureTotalInOnePharmacies/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/FactureTotalInOnePharmacies`);
};

const ServiceFactureTotalInOnePharmacy= {
  findBySocialinsuranceIdHospitalNamePage,
  checkBillNumber,
  checkBillNumberByDatePharmacies,
  findBySocialinsuranceIdHospitalNameBetweenDateServiePage,
  findBySocialinsuranceIdHospitalNameBetweenDateServieList,
    findBySocialinsuranceIdRecieptBetweenDateServiePageInOne,
    findBySocialinsuranceIdRecieptBetweenDateServieListInOne,
    findBySocialinsuranceIdCodeAssureBetweenDateServiePageInOne,
    findBySocialinsuranceIdCodeAssureBetweenDateServieListInOne,
    findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServiePageInOne,
    findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServieListInOne,
    findBySocialinsuranceIdBetweenDateServiePageInOne,
          SocialinsuranceIdBetweenDateServieListInOne,
    findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServiePageInOne,
    findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServieListInOne,


  findById,
  update,
  remove,
  removeAll,
  getAllVisitelWithoutPagination,
  createFactureTotal
};

export default ServiceFactureTotalInOnePharmacy;







