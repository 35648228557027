import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { ICouverture } from "../Interfaces/ICouverture";
import IdataModelCouverture from "../Models/IdataModelCouverture";
import IdataModelCouvertureDtos from "../Models/IdataModelCouvertureDtos";
import CouvertureconfigServiceSurPlafondSoins from "../Services/CouvertureconfigServiceSurPlafondSoins";


export const TableCouverture:React.FC<ICouverture>=props=>{
    

  const [Couverture, setCouverture] = useState<Array<IdataModelCouvertureDtos>>([]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchCouverture, setSearchCouverture] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12,14];
  
  const getRequestParams = (socialinsuranceId:any,searchenterpriseName:string, thepage: number, thepageSize: number) => {
    let params = {
      socialinsuranceId:socialinsuranceId,
      enterpriseName:searchenterpriseName,
      page: thepage,
      size:thepageSize
    };
  
    if (socialinsuranceId) {
      params["socialinsuranceId"] = socialinsuranceId;
    }
    if (searchenterpriseName) {
      params["enterpriseName"] = searchenterpriseName;
    }
  
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearchCouverture = (e: ChangeEvent<HTMLInputElement>) => {
    const searchCouverture = e.target.value;
    setSearchCouverture(searchCouverture);
  };
  
  const findByenterpriseName = () => {
    CouvertureconfigServiceSurPlafondSoins.findByenterpriseName(52,searchCouverture)
      .then((response: any) => {
        const {couverture } = response.data;
        setCouverture(couverture);
        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
  const retrieveCouverture = () => {
    const params = getRequestParams(52,searchCouverture, page, pageSize);
  
    CouvertureconfigServiceSurPlafondSoins.getAll(params)
  
      .then((response: any) => {
  
        const {couverture , totalPages} = response.data;
  
        setCouverture(couverture);
        setCount(totalPages);
  
        console.log(response.data);
        console.log(params);
  
      })
  
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    retrieveCouverture();
  },[page, pageSize]);
  
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const refreshList = () => {
    retrieveCouverture();
    setCurrentIndex(-1);
  };
  
  const setActiveCouverture = (dataCouverture: IdataModelCouvertureDtos, index: number) => {
    setCurrentIndex(index); 
     props.onEdit(dataCouverture);
     props.onHide();
    console.log(dataCouverture);
    console.log(index);
  };
  
  const OnDeleteCouverture = (id:any) => {
  
    CouvertureconfigServiceSurPlafondSoins.remove(id)
      .then((response: any) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
    return(
          <>
          <Row className="mb-3">
        
        <Stack  direction="horizontal" gap={2}>
                  <Form.Control className="me-auto"
                    placeholder="Filtre le type docteur..."
                    value={searchCouverture}
                     onChange={onChangeSearchCouverture} 
                    />
                  <Button variant="outline-dark btn-sm"
                   onClick={findByenterpriseName}>Cherchez</Button>
           </Stack>   
          <div className="mt-3">
            
          {"Items per Page: "}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </div>
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>id</th>
              <th>Societe</th>
              <th>Entreprise</th>
              <th>Service</th>
              <th>Ticket sur Declaration</th>
              <th>Ticket sur Facture</th>
              <th>Particulie</th>
              <th>Category</th>
              <th>Access</th>
              <th>Secter</th>
              <th>plafond</th>
            </tr>
          </thead>
          <tbody>
          {Couverture.length>0 ? (
            Couverture.map((i, index) => (
                
                  <tr key={i.idCouvertureConfig }>
                  <td>{i.idCouvertureConfig}</td>
                  <td>{i.insuranceName}</td>
                  <td>{i.enterpriseName}</td>
                  <td>{i.serviceName}</td>
                  <td>{i.ticket}</td>
                  <td>{i.ticketFacture}</td>
                  <td>{i.particulie}</td>
                  <td>{i.categoryCode}</td>
                  <td>{i.access}</td>
                  <td>{i.secter}</td>
                  <td>{i.plafondOrdonence}</td>


                  
                  <td>
                  <Stack direction="horizontal" gap={2}>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setActiveCouverture(i, index)}>Edit</Button>
  
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteCouverture(i.idCouvertureConfig)}>Delete</Button>
                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no Type Doctors</td>
              </tr>
            )}
  
          </tbody>
        </Table>
        </Row>
  
          </>
      );
  }