import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Card, Row, Form, InputGroup } from "react-bootstrap";
import ServiceCotisationAssurance from '../Services/ServiceCotisationAssurance';
import { CotisationAssuranceDetailsDto } from '../Models/CotisationAssuranceeDetailsDto';
import CotisationAssuranceDetails from '../Models/CotisationAssuranceDetails';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Redux/Store/store';

interface IEditAdhesionList {
    show: boolean; 
    onHide: () => void; 
    EditInfo: CotisationAssuranceDetailsDto;
  }

  export const Edit :React.FC<IEditAdhesionList>=props=> {
    const preferredName = useSelector((state: RootState) => state.user.preferred_username);

   const initialTypeState = {
      id: null, 
      codeCategory:'',
      codeCategorySoins:'',
    
      number: 0,
      total: 0,
      comment:  '',
      createdBy:  '',

      dateCreated:  '',
      timeCreated:  '',
    };

    const [isInputDto,setInputDto]=useState<CotisationAssuranceDetailsDto>(props.EditInfo);

    const [isInput,setInput]=useState<CotisationAssuranceDetails>(initialTypeState);


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInput({ ...isInput, [name]: value });
       // console.log(event.target)
        }
        const handleChangeDto = (event: ChangeEvent<HTMLInputElement>) => {
          const { name, value } = event.target;
          setInputDto({ ...isInputDto, [name]: value });
         // console.log(event.target)
          }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const Edit = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            const now = new Date();

            // Format date as YYYY-MM-DD
            const dateCreated = now.toISOString().split('T')[0];
        
            // Format time as HH:MM:SS
            const timeCreated = now.toTimeString().split(' ')[0];
      
            let data = {
              codeCategory:isInputDto.codeCategory,
              codeCategorySoins:isInputDto.codeCategorySoins,
              number: isInputDto.number,
              total: isInputDto.total,
              comment:  isInputDto.comment,

              createdBy: preferredName,
              dateCreated: dateCreated,
              timeCreated: timeCreated,
            };

            ServiceCotisationAssurance.update(isInputDto.cotisationAssuranceIdDetails,data)
            .then((response: any) => {
              setInput({
      
            id: response.data.id, 
            codeCategory:response.data.codeCategory,
           codeCategorySoins:response.data.codeCategorySoins,
  
            number: response.data.number,
            total: response.data.total,
            createdBy: response.data.createdBy,
            comment: response.data.comment,
            dateCreated: response.data.dateCreated,
            timeCreated: response.data.timeCreated
          });
            
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

        
          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
          
  useEffect(() => {

    setInputDto(props.EditInfo)

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
          
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
               <Form.Label>number </Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="number" value={ isInputDto.number} onChange={handleChangeDto} />
               </InputGroup>
           </Form.Group>
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>comment</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="text" name="comment" value={isInputDto.comment} onChange={handleChangeDto} />
            </InputGroup>
        </Form.Group>
        
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>total</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="number" name="total" value={isInputDto.total} onChange={handleChangeDto} />
            </InputGroup>
        </Form.Group>
         </Row>

         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>code</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="text" name="codeCategory" value={isInputDto.codeCategory} onChange={handleChangeDto} />
            </InputGroup>
        </Form.Group>

        
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Category Sois</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="text" name="codeCategorySoins" value={isInputDto.codeCategorySoins} onChange={handleChangeDto} />
            </InputGroup>
        </Form.Group>
         </Row>
         
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={Edit} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
