import { Container, Row, Col } from "react-bootstrap";
import { TableFactureMedicamentNoPayerLivraison } from "../Espace_Patients/FactureMedicament/Table/TableFactureMedicamentNoPayerLivraison";
import { AddFactureSaisieConfigPharmacy } from "../Espace_Patients/FactureSaisie/AddFactureSaisie/AddFactureSaisieConfigPharmacy";
import { useState } from "react";

export const SaisieLivraisonComponet=()=>{

    const [isSwichMedoc,setSwichMedoc]= useState(true);
    
    return (
        <Container fluid>
          <Row>
            <Col>    
              <>
                 <AddFactureSaisieConfigPharmacy />
                  <TableFactureMedicamentNoPayerLivraison
                  show={isSwichMedoc} 
                  onHide={() => setSwichMedoc(false)}
                   />
              </>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      );
}