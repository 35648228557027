import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { useHistory } from 'react-router-dom';

import { useState, ChangeEvent, useEffect, useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import IdataModelHospitalType from "../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import NewVisiteDtos from "../Models/Dtos/NewVisiteDtos";
import ServicesNewVisite from "../Services/ServicesNewVisite";
import { ModalFactureSaisie } from "../../FactureSaisie/Modals/ModalFactureSaisie";
import { RootState } from "../../../../Redux/Store/store";
import { useDispatch, useSelector } from "react-redux";
import { updatecurrentNewFacture } from "../../../../Redux/slices/currentNewVisite/currentNewFacture";
import { FaPaperPlane, FaStethoscope } from 'react-icons/fa';
import { Sync } from "@mui/icons-material";




    const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];
  
    let HospitalName='';

    let MonStructure:string='';

export const TableNouvelleVisite:React.FC=()=>{
      

  const history = useHistory();
  
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
const DispatchPushCarrantisNewFactureDto=useDispatch();
const [isNewVisite, setisNewVisite] = useState<Array<NewVisiteDtos>>([]);
const [currentIndex, setCurrentIndex] = useState<number>(-1);

const [isHospitalDropDown,setIsHospitalDropDown]=useState(true);
const [searchReceiptBill, setsearchReceiptBill] = useState<string>("");

const [modalShow, setModalShow] = useState(false);
const [hospital, setHospital] = useState(defaultinitiaHospitalType);

const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];

const getRequestParams = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};

const getRequestParamsStructure = 
    (socialinsuranceId:number,
     searchReceiptBill:string,
     hospitalName:string,
     thepage: number, 
     thepageSize: number) => {

  let params = {
    socialinsuranceId:socialinsuranceId,
    receiptBill:searchReceiptBill,
    hospitalName:hospitalName,
    page: thepage,
    size:thepageSize
  };

  if (socialinsuranceId) {
    params["socialinsuranceId"] = socialinsuranceId;
  }

  if (searchReceiptBill) {
    params["receiptBill"] = searchReceiptBill;
  }
  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }
  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};

const setpPushNewVisite = (dataNouvelleVisite: NewVisiteDtos, index: number) => {
 
  setCurrentIndex(index); 
  DispatchPushCarrantisNewFactureDto(updatecurrentNewFacture(dataNouvelleVisite));
  setModalShow(true);
  console.log('dataNouvelleVisite',dataNouvelleVisite) 
  history.push('/FactureSaisie');

};
const handleChangeTypeHospital = (e: { target: {value: any; }; }) => {
  HospitalName=e.target.value;
    }
    const getHospitalWithoutPagination = useCallback(() => {
    
      HospitalTypeService.getAllHospitalNameWithoutPagination()
        .then((response: any) => {
          setHospital(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }, []);
const onChangesearchReceiptBill = (e: ChangeEvent<HTMLInputElement>) => {
  const searchReceiptBill = e.target.value;
  setsearchReceiptBill(searchReceiptBill);
};


const trigerDropDownState = useCallback(() => {
  setIsHospitalDropDown((prevIsHospitalDropDown) => {
    if (StructuresData.length === 1) {
      let concatenatedString: string = StructuresData.join(", ");
      MonStructure = concatenatedString;
      return true;
    } else {
      return false;
    }
  });
}, [StructuresData]);

useEffect(() => {
  trigerDropDownState();
},[trigerDropDownState]);

const findByNewVisitePatient = () => {

  let valueSwip=" "
  if(MonStructure.length>0){
console.log("AA")
let concatenatedString: string = StructuresData.join(", ");
  
      valueSwip=concatenatedString
  }else{
console.log("bb")
      valueSwip=HospitalName
  }
    const params = getRequestParamsStructure(52,searchReceiptBill,valueSwip, page, pageSize);
    ServicesNewVisite.getAllHemaNewVisitePatient(params)
    .then((response: any) => {
      const {facture} = response.data;
      setisNewVisite(facture);
      setCurrentIndex(-1);
      console.log("facture visaaaa",response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
  
};

const retrieveNewVisites = useCallback(() => {
 
  const fetchData = (params: any) => {
    return StructuresData.length >0
      ? ServicesNewVisite.getAllHemaNewVisitePatientByStructure(params)
      : ServicesNewVisite.getAllHemaNewVisitePatient(params);
  };

  let valueSwip=" "
  if(MonStructure.length>0){
console.log("AA")
let concatenatedString: string = StructuresData.join(", ");
  
      valueSwip=concatenatedString
  }else{
console.log("bb")
      valueSwip=HospitalName
  }

    const params = getRequestParamsStructure(52, searchReceiptBill, valueSwip, page, pageSize);

    fetchData(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;

        setisNewVisite(facture);
        setCount(totalPages);

        console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  
}, [StructuresData, searchReceiptBill, page, pageSize]);

const retrieveNewVisitesT = () => {
 
  const fetchData = (params: any) => {
    return StructuresData.length >0
      ? ServicesNewVisite.getAllHemaNewVisitePatientByStructure(params)
      : ServicesNewVisite.getAllHemaNewVisitePatient(params);
  };

  let valueSwip=" "
  if(MonStructure.length>0){
console.log("AA")
let concatenatedString: string = StructuresData.join(", ");
  
      valueSwip=concatenatedString
  }else{
console.log("bb")
      valueSwip=HospitalName
  }

    const params = getRequestParamsStructure(52, searchReceiptBill, valueSwip, page, pageSize);

    fetchData(params)
      .then((response: any) => {
        const { facture, totalPages } = response.data;

        setisNewVisite(facture);
        setCount(totalPages);

        console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  
};

useEffect(() => {
  getHospitalWithoutPagination();
},[getHospitalWithoutPagination]);

useEffect(() => {
  retrieveNewVisites();
},[retrieveNewVisites]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  return(
        <>
        <Row className="mb-3">
      
        <Form.Control 
                   className="me-auto w-50" // This sets the width to 50% of the parent element
                  placeholder="Filtre le Bon..."
                  value={searchReceiptBill}
                   onChange={onChangesearchReceiptBill} 
                  />
     
        <Stack  direction="horizontal" gap={2}>
       
        {"Page:"}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
            {!isHospitalDropDown?(

<Stack direction="horizontal" gap={2}>
          
          <Form.Group controlId="formBasicEmail" className="col col-sm-12">
      
<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">hopital..</option>
  {hospital &&
hospital.map((hospital) => (
    <option key={hospital.id} value={hospital.hospitalName} >{hospital.hospitalName}</option>
))}
    
</Form.Select>
          </Form.Group>
          <Button variant="outline-dark" size="sm" onClick={retrieveNewVisitesT}>
                          <Sync /> 
                      </Button>
                  </Stack>  
            ):(
              
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">{MonStructure}</option>
</Form.Select>
</Form.Group>
            ) }
        </Stack>
      <Stack  direction="horizontal" gap={2}>
              

         
         </Stack> 
         </Row>


        <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>Enterprise</th>
            <th>Code Assuere</th>
            <th>PatientName</th>
            <th>HospitalName</th>
            <th>Numero Bon</th>
            <th>date saisie</th>
            <th>Temp saisie</th>
            
            <th>Saisir</th>
          </tr>
        </thead>
        <tbody>
        {isNewVisite.length>0 ? (
          isNewVisite.map((i, index) => (
                <tr key={i.idFacture }>
                <td>{i.enterpriseName}</td>
                <td>{i.codeAssuree}</td>
                <td>{i.patientName}</td>
                <td>{i.hospitalName}</td>
                <td>{i.receiptBill}</td>
                <td>{i.dateCreatedSaisie}</td>
                <td>{i.timeSaisie}</td>
                <td>
                <Stack direction="horizontal" gap={0}>
                <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => setpPushNewVisite(i, index)}>
  <FaPaperPlane className="me-2" size={20}/>
</Button>
                </Stack>
                </td>
              </tr>
            ))
          ) : ( 
            <tr>
              <td colSpan={3}>no Patients </td>
            </tr>
          )}

        </tbody>
      </Table>
      

        </>
    );
}

