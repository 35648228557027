import http from "../../../AxiosRequest/httpcommon";
import IdataModelTypeMateriel from "../Models/IdataModelTypeMateriel";
const getAll = (params:any) => {
  return http.get<Array<IdataModelTypeMateriel>>("/tousMaterielles", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelTypeMateriel>>("/sortedtousMaterielles");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelTypeMateriel>>("/tousMateriellesAccount");
}

const create = (data: IdataModelTypeMateriel) => {
  return http.post<IdataModelTypeMateriel>("/tousMaterielles", data);
};

const update = (id: any, data: IdataModelTypeMateriel) => {
  return http.put<any>(`/tousMaterielles/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/tousMaterielles/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/tousMaterielles`);
};

const findByTypeTypeName = (typeName: string) => {
  return http.get<Array<IdataModelTypeMateriel>>(`/tousMaterielles?typeName=${typeName}`);
};

const TypeService = {
  getAllTypeWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByTypeTypeName,
};

export default TypeService;