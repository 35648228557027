// detailsPharmacySlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import IdataModelPharmacy from '../../../Components/ASSURANCES/StructurePartenaires/Pharmacies/Models/IdataModelPharmacy';



const initialState: IdataModelPharmacy= {
    id : null,
    pharmacyName: '',
    plafondFacture:null,
    raisonSociale:null,
    tel:null,
    nif:null,
    rc:null,
    bp:null,
    avenue:null,
    centreFiscal:null,
    secteurActivite:null,
    formeJuridique:null,
    tva:null,
    account:null,
    createdBy: '',
    dateCreatedBy: new Date()
  }

const detailsPharmacySlice = createSlice({
  name: 'detailsPharmacy',
  initialState,
  reducers: {
    updateDetailsPharamcy: (state, action: PayloadAction<IdataModelPharmacy>) => {
      Object.assign(state, action.payload);
    },
    clearDetailsPharmacy: (state) => {
      return initialState;
    },
  },
});

export const { updateDetailsPharamcy, clearDetailsPharmacy } = detailsPharmacySlice.actions;
export default detailsPharmacySlice.reducer;
