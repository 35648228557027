import { useEffect, useState } from 'react';
import { InputGroup, Form, Row } from "react-bootstrap";
import IdataModelCouverture from '../Models/IdataModelCouverture';
import CategoryTypeService from '../../../Categories/CategoriiesCode/Services/CategoryTypeServices';
import IdataModelCategoryCode from '../../../Categories/CategoriiesCode/Models/IdataModelCategoryCode';
import IdataModeEnterprise from '../../../Entreprises/Models/IdataModeEnterprise';
import EnterpriseService from '../../../Entreprises/Services/EnterpriseService';
import IdataModelTypeStatus from '../../../../Type/TypeStatus/Models/IdataModelTypeStatus';
import IdataModelService from '../../../../ServicesData/Models/IdataModelService';
import ServiceHopital from '../../../../ServicesData/Services/ServiceHopital';
import TypeService from '../../../../Type/TypeStructure/Services/TypeServices';
import ServicePharmacy from '../../../../ServicesDataPharma/Services/ServicePharmacy';
import CouvertureconfigServiceSurPlafondPharma from '../Services/CouvertureconfigServiceSurPlafondPharma';

const defaultservice: Array<IdataModelService> = [];
const defaultcode:Array<IdataModelCategoryCode>=[];
const defaultStatus:Array<IdataModelTypeStatus>=[];
const defaultenterprise:Array<IdataModeEnterprise>=[];


let selectedEnterprise:any;
let categoryCodeId:any;
let serviceId:any;

const initialTypeState = {
  service:'',
 ticket:'',
 ticketFacture:'',
 particulie:'',
 type_category:'',
 access:'',
 secter:'',
 plafondOrdonence:'',
 createdBy:'',
dateCreated: ''
};

export const AddCouverture:React.FC=()=> {

  const [service, setservice] = useState(defaultservice);
  const [code, setcode] = useState(defaultcode);
  const [status, setStatus] = useState(defaultStatus);


  const [enterprise, setenterprise] = useState(defaultenterprise);



const [isInput,setInput]=useState<IdataModelCouverture>(initialTypeState);

const getAllWithoutPagination = () => {
  
  ServicePharmacy.getAllTypeWithoutPagination()
    .then((response: any) => {
      setservice(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
const getAllTypeStatusWithoutPagination = () => {
  
  TypeService.getAllTypeWithoutPagination()
    .then((response: any) => {
      setStatus(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const getAllWithoutPaginationCode = () => {
  
  CategoryTypeService.getAllWithoutPagination()
    .then((response: any) => {
      setcode(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const getAllWithoutPaginationEnterprise = () => {
  
  EnterpriseService.getAllEnterpriseNameWithoutPagination()
    .then((response: any) => {
      setenterprise(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

useEffect(() => {
  getAllTypeStatusWithoutPagination();
  getAllWithoutPagination();
  getAllWithoutPaginationCode();
  getAllWithoutPaginationEnterprise();

});
    
      const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setInput({ ...isInput, [e.target.name]: e.target.value });
      }
      const handleChangeCodeCategory = (e: { target: { name: any; value: any; }; }) => {
        categoryCodeId=e.target.value
//        console.log('')
   };
   const handleChangeServices = (e: { target: { name: any; value: any; }; }) => {
    serviceId=e.target.value
//        console.log('')
};
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
 const handleChangeEnterprise= (e: { target: {value: any; }; }) => {
                selectedEnterprise=e.target.value;
                  }

      const save = () => {  

        let data = {
 ticket: isInput.ticket ,
 ticketFacture: isInput.ticketFacture ,
 particulie: isInput.particulie ,
 access: isInput.access ,
 secter: isInput.secter ,
 plafondOrdonence:isInput.plafondOrdonence,
 createdBy: isInput.createdBy ,
dateCreated: isInput.dateCreated 
        };

        CouvertureconfigServiceSurPlafondPharma.create(52,selectedEnterprise,categoryCodeId,serviceId, data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            ticket:response.data.ticket ,
            ticketFacture: response.data.ticketFacture,
            particulie:response.data.particulie ,
            access:response.data.access ,
            secter:response.data.secter ,
            plafondOrdonence:response.data.plafondOrdonence,
            createdBy:response.data.createdBy ,
           dateCreated:response.data.dateCreated 
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const resetTypeDoctor = () => {
        setInput(initialTypeState);
      }
      const CountValue=()=>{
        for (let i = 0; i <2756; i++) {
          save();
          console.log ("Block statement execution no." + i);
        }
      }
     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
         <Form.Label>Services</Form.Label>
    <Form.Select className="form-control" size="sm" 
     onChange={handleChangeServices}>
       <option value="0">Choose..</option>
      {service &&
    service.map((service) => (
        <option key={service.id} value={service.id} >{service.typeName}</option>
    ))}
        
    </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Ticket sur Declaration</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="number" name="ticket" value={isInput.ticket} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Ticket sur Facture</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="number" name="ticketFacture" value={isInput.ticketFacture} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        </Row>
        <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>particulie</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="particulie" value={isInput.particulie}  onChange={handleChange}>
                <option>Choisir </option>
               <option value="OUI">OUI</option>
             <option value="No">No</option>
    </Form.Select>
        </Form.Group>

        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Code Category</Form.Label>
    <Form.Select className="form-control"  size="sm" 
     onChange={handleChangeCodeCategory}>
       <option value="0">Choose..</option>
      {code &&
    code.map((code) => (
        <option key={code.id} value={code.id} >{code.categoryName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
   <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Access</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="access"  value={isInput.access}  onChange={handleChange}>
                <option>Choisir </option>
               <option value="OUI">OUI</option>
             <option value="No">No</option>
    </Form.Select>
        </Form.Group>
    
        <Form.Group controlId="formGridCheckbox" className="col col-sm-3">
            <Form.Label>Secteur</Form.Label>
            <Form.Select className="form-control" size="sm" name="secter" value={isInput.secter}
             onChange={handleChange}>
                     <option value="0">Choose..</option>
                {status &&
    status.map((status) => (
        <option key={status.id} value={status.typeName} >{status.typeName}</option>
    ))}
            </Form.Select>
        </Form.Group>
        
   <Form.Group controlId="formBasicEmail" className="col col-sm-6">
         <Form.Label>Entreprise</Form.Label>
    <Form.Select className="form-control"  size="sm"  
     onChange={handleChangeEnterprise}>
       <option value="0">Choose..</option>
      {enterprise &&
    enterprise.map((enterprise) => (
        <option key={enterprise.id} value={enterprise.id} >{enterprise.enterpriseName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
   <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>plafond/Facture</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="number" name="plafondOrdonence" value={isInput.plafondOrdonence} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        </Row>
  
         
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>
            <button  onClick={resetTypeDoctor} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>       

        </Form.Group>
      </Row>
            </form>
        </>
    );
}