import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../Redux/Store/store';
import Classee from '../Models/Classee';
import ServiceremboursementClass from '../Services/ServiceremboursementClass';


const initialTypeState = {
  id: null,
  money: '',
  createdBy: '',
  moisPayer: '',
  comment: '',
  dateCreated: '',
  timeCreated: '',
};


export const Add:React.FC=()=> {

  const preferredName = useSelector((state: RootState) => state.user.preferred_username);

const [isInput,setInput]=useState<Classee>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
     
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        const now = new Date();

        // Format date as YYYY-MM-DD
        const dateCreated = now.toISOString().split('T')[0];
    
        // Format time as HH:MM:SS
        const timeCreated = now.toTimeString().split(' ')[0];
    
        // Get current month in French
        const monthsInFrench = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
        const moisPayer = monthsInFrench[now.getMonth()];
    
        let data = {
          money: isInput.money,
          createdBy: preferredName,
          moisPayer:moisPayer,
          comment: isInput.comment,
          dateCreated: dateCreated,
          timeCreated: timeCreated,
        };

        ServiceremboursementClass.create(52,'pharmacies_id',data)
        .then((response: any) => {
          setInput({
            id: response.data.id, 
            money: response.data.money,
            createdBy: response.data.createdBy,
            moisPayer:response.data.moisPayer,
            comment: response.data.comment,
            dateCreated: response.data.dateCreated,
            timeCreated: response.data.timeCreated
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }
   
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>money</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="number" name="money" value={isInput.money} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

         </Row>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>comment</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="text" name="comment" value={isInput.comment} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}