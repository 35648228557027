
import { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import IdataModelService from "../../Components/ServicesData/Models/IdataModelService";
import { AddService } from "../../Components/ServicesDataPharma/TypeOperations/AddService";
import { EditServie } from "../../Components/ServicesDataPharma/TypeOperations/EditServie";
import { TypeTableService } from "../../Components/ServicesDataPharma/Tables/TypeTableService";

  const initialservice = {
    id: null,
    typeName: '',
    createdBy: 'Ghost Zila',
    dateCreatedBy: new Date()
  };
  

export const TypeMedicament:React.FC=()=>
    {
        const [isSwichService,setSwichService]= useState(true);

        const [ServiceEdit, setServiceEdit] = useState<IdataModelService>(initialservice);

        const onCurrantDepartment=(data:IdataModelService)=>{
            setServiceEdit(data);
        }

        
        return(
            <>
            <Container fluid>
                <Row>
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                {isSwichService?
                (
                    <AddService />
                ):(
      <EditServie 
      show={isSwichService} 
       onHide={() => setSwichService(true)}
       EditInfo={ServiceEdit}/> 
                )
  }
         <TypeTableService 
         show={isSwichService} 
          onHide={() => setSwichService(false)}
         onEditService={onCurrantDepartment}
          />
                    </Card.Body>
                </Card>
                <br />
                </Col>
            </Row>
        
            
            </Container>
            </>
        );
    }