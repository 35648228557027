import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelCategoriesAcessSoins from "../Models/IdataModelCategoriesAcessSoins";

const getAll = (params:any) => {
  return http.get<Array<IdataModelCategoriesAcessSoins>>("/categorySoins", { params });
};

const getCategoryAccessSoinsWithoutPagination = () => {
  return http.get<Array<IdataModelCategoriesAcessSoins>>("/sortedCategorySoins");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelCategoriesAcessSoins>>("/categorySoinsAccount");
}

const create = (socialinsuranceId:any,data: IdataModelCategoriesAcessSoins) => {

  return http.post<IdataModelCategoriesAcessSoins>(`/socialinsurance/${socialinsuranceId}/categorySoins`, data);
};

const update = (id: any, data: IdataModelCategoriesAcessSoins) => {
  return http.put<any>(`/categorySoins/${id}`, data);
};


const remove = (id: any) => {
  return http.delete<any>(`/categorySoins/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/categorySoins`);
};

const findByCategoriesAcessSoinsType = (categorySoinsName: string) => {
  return http.get<Array<IdataModelCategoriesAcessSoins>>(`/categorySoins?categorySoinsName=${categorySoinsName}`);
};

const CategoriesAcessSoinsServices = {
  getCategoryAccessSoinsWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByCategoriesAcessSoinsType,
};

export default CategoriesAcessSoinsServices;
