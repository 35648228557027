// userSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IdataModelHospitalType from '../../../Components/ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType';




const initialState: IdataModelHospitalType= {
    id : null ,
    hospitalName: '',
    typehospital: '',
    hospitalAccount:null,
    referenceId:null,
    
    raisonSociale:null,
    tel:null,
    nif:null,
    rc:null,
    bp:null,
    avenue:null,
    centreFiscal:null,
    secteurActivite:null,
    formeJuridique:null,
    tva:null,
    
    createdBy: '',
    dateCreatedBy: new Date()
  }

const detailsHospitalSlice = createSlice({
  name: 'detailsHospital',
  initialState,
  reducers: {
    updateDetailsHospital: (state, action: PayloadAction<IdataModelHospitalType>) => {
   
      Object.assign(state, action.payload);
    },
    clearDetailsHospital: (state) => {
      return initialState;
    },
  },
});

export const { updateDetailsHospital, clearDetailsHospital } = detailsHospitalSlice.actions;
export default detailsHospitalSlice.reducer;
