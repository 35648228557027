/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import {Container, Divider,Stack } from "@mui/material";

import Button from 'react-bootstrap/Button';
import { Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NewFactureSaisieDto from "../Espace_Patients/FactureSaisie/models/Dto/NewFactureSaisieDto";
import IdataModelService from "../ServicesData/Models/IdataModelService";
import IdataModelTypeDepertment from "../HopitalDepertment/Models/IdataModelTypeDepertment";
import { RootState } from "../../Redux/Store/store";
import CouvertureconfigService from "../ASSURANCES/Configurations/Couvertures/Services/CouvertureconfigService";
import ServiceHopital from "../ServicesData/Services/ServiceHopital";
import ServiceDepartment from "../HopitalDepertment/Services/ServiceDepartment";
import ServicesNewFactureSaisie from "../Espace_Patients/FactureSaisie/services/ServiceNewFactureSaisie";
import { RiSearch2Line } from "react-icons/ri";
import IdataModelHospitalType from "../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
  
const columns: GridColDef[] = [

    { field: 'plafondFacture', headerName: 'plafond' },
    { field: 'statusFacturePayer', headerName: 'Status' },
    { field: 'statusFactureVerifier', headerName: 'Verifier' },
    { field: 'prestation', headerName: 'Prestation' },
    { field: 'pricePrestation', headerName: 'Price ' },

    { field: 'quantity', headerName: 'Quts' },

  //  { field: 'ticketService', headerName: 'D Ticket' },
    { field: 'ticketServiceFacture', headerName: 'Ticket' },
    { field: 'partMutuelle', headerName: 'Part Mutuelle' },
    { field: 'partpatient', headerName: 'Part Patient' },
    { field: 'partTotal', headerName: 'Total'  },

   // { field: 'partMutuelleFacture', headerName: 'F Part Mutuelle' },
   // { field: 'partpatientFacture', headerName: 'F Part Patient' },
   // { field: 'partTotalFacture', headerName: 'F Total'  },

    { field: 'typeNameVisite', headerName: 'Type Viste' },
   // { field: 'typeBonVerificationName', headerName: 'Verfier' },
    { field: 'typeBonCommendName', headerName: 'Type Bon' },
    { field: 'depertmentName', headerName: 'Depertement' },
    { field: 'dateCreatedSaisie', headerName: 'Created' },
    { field: 'timeSaisie', headerName: 'Time Saisie' },
    
  ];
  const idd: GridRowId[]=[];

let selectedRow:Array<NewFactureSaisieDto> = [];



const defaultservice: Array<IdataModelService> = [];
const defaultdepertment:Array<IdataModelTypeDepertment>=[];

const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];


export const EspaceAllSoins:React.FC=()=>

{
  const preferredName = useSelector((state: RootState) => state.user.preferred_username);

  const [service, setservice] = useState(defaultservice);
    
  const [depertment, setdepertment] = useState(defaultdepertment);

  const [idService, seIdService] = useState(0);

  const [isConfigPlafondFacture, setConfigPlafondFacture] = useState<number>(0);
    
  const [HospitalId, setHospitalId] = useState(0);

  const [departmentId, setdepertmentId] = useState(0);

  const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);
  

  const [isfactureCouvert, setisfactureCouvert] = useState(0);
    const [isfactureNoCouvert, setisfactureNoCouvert] = useState(0);
    const [isfactureTotal, setisfactureTotal] = useState(0);

    const [isPartMituelle, setisPartMituelle] = useState(0);
    const [isPartPatient, setisPartPatient] = useState(0);

    const [isfactureApeyer, setisfactureApeyer] = useState(0);

    const [isPartMituelleFacture, setisPartMituelleFacture] = useState(0);
    const [isPartPatientFacture, setisPartPatientFacture] = useState(0);

    const [hospital, setHospital] = useState(defaultinitiaHospitalType);

 const [isInputFactureSaisie, setisInputFactureSaisie] = useState<{ status: string }>({ status: '' });

 const [isNewFactureSaisieDto, setisNewFactureSaisieDto] = useState<Array<NewFactureSaisieDto>>([]);


//const [selectedRows, setRowsSelected] = useState(defaultList);

const [selectedIdInRow, setIdRowSelected] = useState(idd);

const getHospitalWithoutPagination = useCallback(() => {
  HospitalTypeService.getAllHospitalNameWithoutPagination()
    .then((response: any) => {
      setHospital(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
}, []); // Empty dependency array means this function is memoized and won't change


useEffect(() => {
  getHospitalWithoutPagination();
},[getHospitalWithoutPagination]);

const UpdateFactureSaisie = (id: any) => {
  let data = {
    status: 'Verifie',
  };

  ServicesNewFactureSaisie.updateStatusFactureVerifier(HospitalId, id, data.status)
    .then((response: any) => {
      setisInputFactureSaisie({
        status: response.data.status,
      });
console.log('response API',response)
      findAllFactureByInsuarenceAndReceipt();
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
  
const UpdateFactureSaisieNoVerifier = (id: any) => {
  let data = {
    status: 'no',
  };

  ServicesNewFactureSaisie.updateStatusFactureVerifier(HospitalId, id, data.status)
    .then((response: any) => {
      setisInputFactureSaisie({
        status: response.data.status,
      });
console.log('response API',response)
      findAllFactureByInsuarenceAndReceipt();
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const UpdateFactureSaisieById = () => {  

  for (let id of selectedIdInRow) {
    UpdateFactureSaisie(id)

   console.log("selectedIdInRow",id)
  }
};

const UpdateFactureSaisieByIdNoVerifier = () => {  

  for (let id of selectedIdInRow) {
    UpdateFactureSaisieNoVerifier(id)

   console.log("selectedIdInRow",id)
  }
};



const ActualizePerServices = () => {  
    findAllFactureByInsuarenceAndReceipt()
  };
   


const AnalyseFactureToPrint = (selectedRowToPrint:any) => {  

  if(selectedRowToPrint.length===0)
{

setisfactureCouvert(0)
setisfactureNoCouvert(0)
setisPartPatient(0)
setisPartMituelle(0)
setisfactureTotal(0)

setisPartMituelleFacture(0)
setisPartPatientFacture(0)

}
else
{

// Create a Map to store the grouped results
const groupedByService = new Map<string, {
total: number,
quantity: number,
FactureCouvert: number,
FactureNocouvert: number,
PartMutuelle: number,
PartPatient: number ,
PartMutuelleFacture: number,
PartPatientFacture: number // New key for PartPatient 

}>();
// Iterate through the array and update the Map
selectedRowToPrint.forEach((
  entry: { service: any; quantity: any; partTotal: any; plafondFacture: any; ticketService: any; ticketServiceFacture: any; }) => {
const { service, quantity, partTotal, plafondFacture, ticketService,ticketServiceFacture } = entry;

if (groupedByService.has(service)) {
  // If the service is already in the Map, update the total and quantity
  const existingEntry = groupedByService.get(service)!;
  existingEntry.total += partTotal;
  existingEntry.quantity += quantity;

  // Check if the total is less than or equal to plafondFacture
  if (existingEntry.total <= plafondFacture) {
      existingEntry.FactureCouvert = existingEntry.total;
      existingEntry.FactureNocouvert = 0;
  } else {
      // If total is greater than plafondFacture, set FactureCouvert to plafondFacture and calculate FactureNocouvert
      existingEntry.FactureCouvert = plafondFacture;
      existingEntry.FactureNocouvert = existingEntry.total - plafondFacture;
  }

  // Calculate PartMutuelle based on FactureCouvert and ticket
  existingEntry.PartMutuelle = (existingEntry.FactureCouvert * (100 - ticketService)) / 100;
  existingEntry.PartMutuelleFacture = (existingEntry.FactureCouvert * (100 - ticketServiceFacture)) / 100;
  
  // Calculate PartPatient based on FactureCouvert and ticket
  existingEntry.PartPatient = (existingEntry.FactureCouvert * ticketService) / 100;
  existingEntry.PartPatientFacture = (existingEntry.FactureCouvert * ticketServiceFacture) / 100;

  console.log("Couverture Facture Decalre",ticketService)
  console.log("COuverture Facture",ticketServiceFacture)
} else {

  // If the service is not in the Map, add a new entry
  const factureCouvert = partTotal <= plafondFacture ? partTotal : plafondFacture;
  const factureNocouvert = partTotal <= plafondFacture ? 0 : partTotal - plafondFacture;

  // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
  const partMutuelle = (factureCouvert * (100 - ticketService)) / 100;
  const partPatient = (factureCouvert * ticketService) / 100;

  const partMutuelleFacture = (factureCouvert * (100 - ticketServiceFacture)) / 100;
  const partPatientFacture = (factureCouvert * ticketServiceFacture) / 100;
   
  console.log("Couverture Facture Decalre",ticketService)
  console.log("Couverture Facture",ticketServiceFacture)

  groupedByService.set(service, {
      total: partTotal,
      quantity: quantity,
      FactureCouvert: factureCouvert,
      FactureNocouvert: factureNocouvert,
      PartMutuelle: partMutuelle,
      PartPatient: partPatient,
      PartMutuelleFacture: partMutuelleFacture,
      PartPatientFacture: partPatientFacture
  });
}
});

// Calculate the total, FactureCouvert, FactureNocouvert, PartMutuelle, and PartPatient for all services

let totalAllServices = 0;
let factureCouvertAllServices = 0;
let factureNocouvertAllServices = 0;
let partMutuelleAllServices = 0;
let partPatientAllServices = 0;

let partMutuelleFactureAllServices = 0;
let partPatientFactureAllServices = 0;


groupedByService.forEach(({ total, FactureCouvert, FactureNocouvert, PartMutuelle, PartPatient,PartMutuelleFacture, PartPatientFacture }) => {
totalAllServices += total;
factureCouvertAllServices += FactureCouvert;
factureNocouvertAllServices += FactureNocouvert;
partMutuelleAllServices += PartMutuelle;
partPatientAllServices += PartPatient;

partMutuelleFactureAllServices += PartMutuelleFacture;
partPatientFactureAllServices += PartPatientFacture;
});

console.log("Total All Services:", totalAllServices);
console.log("FactureCouvert All Services:", factureCouvertAllServices);
console.log("FactureNocouvert All Services:", factureNocouvertAllServices);

console.log("PartMutuelle All Services:", partMutuelleAllServices);
console.log("PartPatient All Services:", partPatientAllServices);


console.log("PartMutuelleFacture All Services:", partMutuelleFactureAllServices);
console.log("PartPatientFacture All Services:", partPatientFactureAllServices);


setisfactureTotal(totalAllServices);
setisfactureCouvert(factureCouvertAllServices);
setisfactureNoCouvert(factureNocouvertAllServices);

setisPartMituelle(partMutuelleAllServices);
setisPartPatient(partPatientAllServices);

setisPartMituelleFacture(partMutuelleFactureAllServices);
setisPartPatientFacture(partPatientFactureAllServices);
setisfactureApeyer(Number(partPatientFactureAllServices+factureNocouvertAllServices))

}
  };

  const findAllFactureByInsuarenceAndReceipt = async () => {
    try {
      ServicesNewFactureSaisie.findBySocialinsuranceIdAndReceiptBillIdServiceIdDepertmentPayer(
        isgetselecterCurrantNewVisitePushed.idinsurance,
        isgetselecterCurrantNewVisitePushed.receiptBill,
        idService,
        departmentId,
      ).then((response:any)=>{
        const facture = response.data;
        console.log('find all values',response.data)
      setisNewFactureSaisieDto(facture);
      ConfigServiceInterprise();
      })
      
    } catch (e) {
      console.log(e);
    }
  };


const handleChangeTypeHospital = (e: { target: {value: any; }; }) => {
  setHospitalId(e.target.value);
    }

const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }
  const handleChangeConfig = (e: { target: { name: any; value: any; }; }) => {
    seIdService(e.target.value)
   
  }
  const handleChangeDepertment = (e: { target: { name: any; value: any; }; }) => {
    setdepertmentId(e.target.value)
//        console.log('')
};
const getServices = useCallback(() => {
    ServiceHopital.getAllTypeWithoutPagination()
      .then((response: any) => {
        setservice(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [setservice]);

  const getDepertment = useCallback(() => {
    ServiceDepartment.getAllTypeWithoutPagination()
      .then((response: any) => {
        setdepertment(response.data);
        // setdepertmentFacture(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [setdepertment]);
   
  useEffect(() => {
    getDepertment();
    getServices();
},[getDepertment,  getServices]);

    
    const ConfigServiceInterprise =() => {
      if (
        isgetselecterCurrantNewVisitePushed.idinsurance !== 0 &&
        isgetselecterCurrantNewVisitePushed.idEnterprise !== 0 &&
        isgetselecterCurrantNewVisitePushed.serviceName.length !== 0 &&
        isgetselecterCurrantNewVisitePushed.category_code !== null
      ) {
        CouvertureconfigService.findByInsuranceIdAndEnterpriseIdAndSecteurAndidCouvertureConfig(
          isgetselecterCurrantNewVisitePushed.idinsurance,
          isgetselecterCurrantNewVisitePushed.idEnterprise,
          isgetselecterCurrantNewVisitePushed.typehospital,
          idService,
          isgetselecterCurrantNewVisitePushed.category_code
        )
          .then((response: any) => {
            setConfigPlafondFacture(response.data.plafondOrdonence);
        
           console.log('Visaa response.data.plafondOrdonence',response.data.plafondOrdonence)
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else {
         console.log('haliii ganiii')
      }
    };
    
      return (
        <>
        <div>
     < form className="form-edit" onSubmit={onFormSubmit}>
     <Stack direction="row" divider={<Divider orientation="horizontal" flexItem />} spacing={1}>
  <Row className="mb-3 mt-3">
    
    <Form.Group controlId="formBasicEmail" className="col col-sm-3" style={{ width: '200px' }}>
      
      <Form.Select className="form-control" size="sm" style={{ width: '100%' }} onChange={handleChangeConfig}>
        <option value="0" style={{ display: 'none' }}>Plafond..</option>
        {service &&
          service.map((service) => (
            <option key={service.id} value={service.id}>
              {service.typeName}
            </option>
          ))}
      </Form.Select>
    </Form.Group>

    <Form.Group controlId="formBasicEmail" className="col col-sm-3" style={{ width: '200px' }}>
      <Form.Select className="form-control" size="sm" style={{ width: '100%' }} onChange={handleChangeDepertment}>
        <option value="0" style={{ display: 'none' }}>Depertment..</option>
        {depertment &&
          depertment.map((depertment) => (
            <option key={depertment.id} value={depertment.id}>
              {depertment.typeName}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
    <Form.Group controlId="formBasicEmail" className="col col-sm-3" style={{ width: '200px' }}>
     
    <Button
      className="btn-sm rounded-pill mr-3"
      variant="outline-success"
      onClick={() => {
        ActualizePerServices();
      }}
    >
      <RiSearch2Line size={25} />
    </Button>
    </Form.Group>

  </Row>
</Stack>
        
            </form>
     </div>

     <div>

<Stack  direction="row"
  className="mb-3"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}>

<Form.Select className="form-control" 
onChange={handleChangeTypeHospital}>
 <option value="0">select hopital..</option>
{hospital &&
hospital.map((hospital) => (
  <option key={hospital.id} value={hospital.id} >{hospital.hospitalName}</option>
))}
</Form.Select>
</Stack>  
<Stack
  direction="row"
  className="mb-3"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}
>
              <Form.Label><strong>Total de Facture</strong>:{isfactureTotal}</Form.Label>
              <Form.Label><strong>Part Patient </strong>:{isPartPatientFacture}</Form.Label> 
              <Form.Label><strong>Part Mituelle </strong>:{isPartMituelleFacture}</Form.Label> 
              <Form.Label><strong>Facture couvert</strong>:{isfactureCouvert}</Form.Label> 
              <Form.Label><strong>Facture No couvert</strong>:{isfactureNoCouvert}</Form.Label> 
</Stack>    

     </div>
     
<div style={{ width: '100%' }}>
<DataGrid 
          rows={isNewFactureSaisieDto}
          getRowId={(row) => row.idFacture}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel:{page:0,pageSize:6},
            },
          }}
          pageSizeOptions={[6,10,15,25,35,45]}
            autoHeight
            
          checkboxSelection
          
          onRowSelectionModelChange={(ids) => {

            const selectedIDs = new Set(ids);
            selectedRow = isNewFactureSaisieDto.filter((row) => selectedIDs.has(row.idFacture),);
         
            console.log("selectedIDs selectedIDs",selectedIDs)
            setIdRowSelected(ids)
            AnalyseFactureToPrint(selectedRow)
            console.log("values of selectedRow",selectedRow)
           

       
        }
        }
         
        />

<Stack
  direction="row"
  divider={<Divider orientation="horizontal" flexItem />}
  spacing={2}
>
  <Form.Label className="mt-2 mb-2"><strong>FACTURE A VERIFIER </strong>:{isfactureApeyer}</Form.Label> 
</Stack>
<>
<Stack
  direction="row"
  divider={<Divider orientation="horizontal" flexItem />}
  spacing={2}
>
<Button
     onClick={() => { UpdateFactureSaisieById()}}
     variant='me-4 btn btn-success btn-sm btn-block'
   >
     Verifier
   </Button>
<Button
     onClick={() => { UpdateFactureSaisieByIdNoVerifier()}}
     variant='me-4 btn btn-success btn-sm btn-block'
   >
     No Verifier
   </Button>
 
 </Stack>
    

</>
     </div>


          
        </>
      );
    }
