import http from "../../../../../../AxiosRequest/httpcommon";
import Classee from "../Models/Classee";
import ClasseeDto from "../Models/ClasseeDto";

const getAll = (params:any) => {
  return http.get<Array<ClasseeDto>>("/remboursementClassHospital", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<Classee>>("/sortedremboursementClassHospital");
};
const getLastAccountType=()=>{
  return http.get<Array<Classee>>("/remboursementClassHospitalAccountEnterpriseName");
}

const create = (socialinsuranceId:any,hospitalId:any,data: Classee) => {

  return http.post<Classee>(`/${socialinsuranceId}/${hospitalId}/remboursementClassHospital`, data);
};

const update = (id: any, data: Classee) => {
  return http.put<any>(`/remboursementClassHospital/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/remboursementClassHospital/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/remboursementClassHospital`);
};

const findByEnterpriseName = (socialinsuranceId:any,enterpriseName: string) => {
  return http.get<Array<ClasseeDto>>(`/remboursementClassHospital?socialinsuranceId=${socialinsuranceId}&enterpriseName=${enterpriseName}`);
};

const ServiceremboursementClass = {
  getAllWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByEnterpriseName
};

export default ServiceremboursementClass;