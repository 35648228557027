import { useState, useEffect, ChangeEvent } from 'react';
import {Row, Form, InputGroup, Accordion } from "react-bootstrap";
import ServicePrincipalMember from '../Services/ServicePrincipalMember';
import CategoryAffilieTypeService from '../../Categories/CategoriesAffilies/Services/CategoryAffilieTypeService';
import IdataModelCategoryCode from '../../Categories/CategoriiesCode/Models/IdataModelCategoryCode';
import IdataModelCategoryType from '../../Categories/CategoriesAffilies/Models/IdataModelCategoryType';
import CategoryTypeService from '../../Categories/CategoriiesCode/Services/CategoryTypeServices';
import IdataModelPMemberDtos from '../Models/IdataModelPMemberDtos';
import IdataModelPMember from '../Models/IdataModelPMember';


  interface IEditPmemberList {
    show: boolean; 
    onHide: () => void; 
    EditInfo: IdataModelPMemberDtos;
  }
  const defaultcode:Array<IdataModelCategoryCode>=[];
const defaultsoins:Array<IdataModelCategoryType>=[];


  export const EditPmember :React.FC<IEditPmemberList>=props=> {

    const [code, setcode] = useState(defaultcode);
    const [soins, setsoins] = useState(defaultsoins);

    const initialTypeState = {
        id: null,
        codeAssuree: '',
        nomPrenomAffilie: '',
        
        cotisationExact: '',
        account:0,
        genre: '',
        catégorieSoins: '',
        email: '',
        telephones: '',
        adresseAffilie: '',
        autresInformations: '',
       
        plafond_ambilatoire:'',
        plafond_hospitalisation:'',
        plafond_pharmacy:'',
        plafond_ORL:'',
       
        plafond_urugence:'',
        plafond_ophtalmologie:'',
        category_code: '',
        plafond_dentisteries:'', 

        
  resilier: '',
  dataResiliation: '',

       
        dateNaissance: '',
        photoMember: '',
        createdBy: 'Ghost ZIla',
        dateCreated: '',
        tva:'',
        etatCivil:'',
        socialinsurance_id:null
      };
    const [isInput,setInput]=useState<IdataModelPMember>(initialTypeState);
    const [isInputDtos,setInputDtos]=useState<IdataModelPMemberDtos>(props.EditInfo);

    
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInputDtos({ ...isInputDtos, [name]: value });
    };
    const handleChangeSelected = (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      setInputDtos({ ...isInputDtos, [name]: value });
    };
    
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
  
          
   const EditAdherant = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
         
              codeAssuree: isInputDtos.codeAssuree,
              nomPrenomAffilie: isInputDtos.nomPrenomAffilie,
             
              cotisationExact: isInputDtos.cotisationExact,
              genre: isInputDtos.genre,
              catégorieSoins: isInputDtos.catégorieSoins,
              email: isInputDtos.email,
              telephones: isInputDtos.telephones,
              adresseAffilie: isInputDtos.adresseAffilie,
              autresInformations: isInputDtos.autresInformations,
             
              plafond_ambilatoire:isInputDtos.plafond_ambilatoire,
              plafond_hospitalisation:isInputDtos.plafond_hospitalisation,
              plafond_pharmacy:isInputDtos.plafond_pharmacy,
              plafond_ORL:isInputDtos.plafond_ORL,
             
              plafond_urugence:isInputDtos.plafond_urugence,
              plafond_ophtalmologie:isInputDtos.plafond_ophtalmologie,
              category_code: isInputDtos.category_code,
              plafond_dentisteries:isInputDtos.plafond_dentisteries,

              
                 resilier: isInputDtos.resilier,
                 dataResiliation: isInputDtos.dataResiliation,
             
              dateNaissance: isInputDtos.dateNaissance,
              photoMember: isInputDtos.photoMember,
              createdBy:  "ghostzila",
              dateCreated: isInputDtos.dateCreated,
              tva:"no",
              etatCivil:isInputDtos.etatCivil
             
                     };
    
            ServicePrincipalMember.update(isInputDtos.idPmember,data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                
     codeAssuree: response.data.codeAssuree,
     nomPrenomAffilie: response.data.nomPrenomAffilie,
    
     cotisationExact: response.data.cotisationExact,
     account: response.data.account,
     genre: response.data.genre,
     catégorieSoins: response.data.catégorieSoins,
     email: response.data.email,
     telephones: response.data.telephones,
     adresseAffilie: response.data.adresseAffilie,
     autresInformations: response.data.autresInformations,
    
     plafond_ambilatoire: response.data.plafond_ambilatoire,
     plafond_hospitalisation: response.data.plafond_hospitalisation,
     plafond_pharmacy: response.data.plafond_pharmacy,
     plafond_ORL: response.data.plafond_ORL,
    
     plafond_urugence: response.data.plafond_urugence,
     plafond_ophtalmologie: response.data.plafond_ophtalmologie,
     category_code: response.data.category_code,
     plafond_dentisteries: response.data.plafond_dentisteries,
    
     
    resilier: response.data.resilier,
    dataResiliation: response.data.dataResiliation,

     dateNaissance: response.data.dateNaissance,
     photoMember: response.data.photoMember,
     createdBy: response.data.createdBy,
     dateCreated: response.data.dateCreated,
     tva:response.data.tva,
     etatCivil:response.data.etatCivil
    
              });
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
        
const getAllWithoutPaginationCode = () => {
  
    CategoryTypeService.getAllWithoutPagination()
      .then((response: any) => {
        setcode(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const getAllWithoutPaginationSoins = () => {
  
    CategoryAffilieTypeService.getAllTypesoinsWithoutPagination()
      .then((response: any) => {
        setsoins(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
  useEffect(() => {

    setInputDtos(props.EditInfo);
getAllWithoutPaginationCode();
getAllWithoutPaginationSoins();

      },[props.EditInfo])
      
          return(
            <>
          
          < form className="form-edit" onSubmit={onFormSubmit}>
         <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Informaton Prencipal #1</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">

        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Nom/Prenom</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="text" name="nomPrenomAffilie" value={isInputDtos.nomPrenomAffilie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
       
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>email</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="email" name="email" value={isInputDtos.email} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>PhoneNumber</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="phoneNumber" name="telephones" value={isInputDtos.telephones} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>address</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="address" name="adresseAffilie" value={isInputDtos.adresseAffilie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Date naissance</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="Date" name="dateNaissance" value={isInputDtos.dateNaissance} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Autre Information</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="text" name="autresInformations" value={isInputDtos.autresInformations} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>sex</Form.Label>
            <Form.Select  size="sm" name="genre" value={isInputDtos.genre} onChange={handleChangeSelected}>
                <option>Choisir </option>
               <option value="Homme">Homme</option>
             <option value="Femme">Femme</option>
    </Form.Select>
        </Form.Group>
        
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Etat Civil</Form.Label>
            <Form.Select  size="sm" name="etatCivil" value={isInputDtos.etatCivil} onChange={handleChangeSelected}>
                <option>Choisir </option>
               <option value="yes">yes</option>
             <option value="No">no</option>
    </Form.Select>
        </Form.Group>
        </Row>
       
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Information Secondaire #2</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
 
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Cotisation Exact</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="cotisationExact" value={isInputDtos.cotisationExact} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Code Category</Form.Label>
    <Form.Select className="form-control"  size="sm" name="category_code"
    value={isInputDtos.category_code} onChange={handleChangeSelected}>
       <option value="0">Choose..</option>
      {code &&
    code.map((code) => (
        <option key={code.id} value={code.categoryName} >{code.categoryName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
   <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label> Category Soins</Form.Label>
    <Form.Select className="form-control"  size="sm" name="catégorieSoins"  
    value={isInputDtos.catégorieSoins} onChange={handleChangeSelected}>
    <option value="0">Choose..</option>
      {soins &&
    soins.map((soins) => (
        <option key={soins.id} value={soins.categoryName} >{soins.categoryName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond ambilatoire</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_ambilatoire" value={isInputDtos.plafond_ambilatoire} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond hospitalisation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_hospitalisation" value={isInputDtos.plafond_hospitalisation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond pharmacy</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_pharmacy" value={isInputDtos.plafond_pharmacy} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond ORL</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_ORL" value={isInputDtos.plafond_ORL} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond urugence</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_urugence" value={isInputDtos.plafond_urugence} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond ophtalmologie</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_ophtalmologie" value={isInputDtos.plafond_ophtalmologie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>plafond dentisteries</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="plafond_dentisteries" value={isInputDtos.plafond_dentisteries} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Resilier</Form.Label>
            <Form.Select  size="sm" name="resilier" value={isInputDtos.resilier} onChange={handleChangeSelected}>
                <option>Choisir </option>
               <option value="Oui">Oui</option>
             <option value="Non">Non</option>
    </Form.Select>
        </Form.Group>
             <Form.Group controlId="formBasicemail" className="col col-sm-3">
            <Form.Label>Date de resiliation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="dataResiliation" value={isInputDtos.dataResiliation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditAdherant} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
