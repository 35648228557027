import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelSejourPlafond from "../Models/IdataModelSejourPlafond";
import IdataModelSejourVisitePlafondDtos from "../Models/IdataModelSejourVisitePlafondDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelSejourVisitePlafondDtos>>("/SejourVisite", { params });
};

const getAllSejourVisiteNameWithoutPagination = () => {
  return http.get<Array<IdataModelSejourPlafond>>("/sortedSejourVisite");
};

const create = (socialinsuranceId:any,enterprisesAssuranceId:any,inOneSejourVisitesId:any,data: IdataModelSejourPlafond) => {
  return http.post<IdataModelSejourPlafond>(`/${socialinsuranceId}/${enterprisesAssuranceId}/${inOneSejourVisitesId}/SejourVisite`, data);
};

const update = (id:any, data: IdataModelSejourPlafond) => {
  return http.put<any>(`/SejourVisite/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/SejourVisite/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/SejourVisite`);
};

const findByEnterpriseName = (insuranceId:any,enterpriseName: string) => {
  return http.get<Array<IdataModelSejourVisitePlafondDtos>>(`/SejourVisite?insuranceId=${insuranceId}&enterpriseName=${enterpriseName}`);
};

const ServicePlafondServiseVisites = {
  getAllSejourVisiteNameWithoutPagination,
  getAll,
  create,
  update,
  remove,
  removeAll,
  findByEnterpriseName,
};

export default ServicePlafondServiseVisites;
