import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelFrequance from "../Models/IdataModelFrequance";

const getAll = (params:any) => {
  return http.get<Array<IdataModelFrequance>>("/Frequence", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelFrequance>>("/sortedFrequence");
};

const create = (socialinsuranceId:any,data: IdataModelFrequance) => {
  return http.post<IdataModelFrequance>(`/socialinsurance/${socialinsuranceId}/Frequence`, data);
};

const update = (id: any, data: IdataModelFrequance) => {
  return http.put<any>(`/Frequence/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/Frequence/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/Frequence`);
};

const findByTypeName = (typeName: string) => {
  return http.get<Array<IdataModelFrequance>>(`/Frequence?typeName=${typeName}`);
};

const FrequenceService = {
  getAllTypeWithoutPagination,
  getAll,
  create,
  update,
  remove,
  removeAll,
  findByTypeName,
};

export default FrequenceService;