/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import NewFactureSaisieDto from "../models/Dto/NewFactureSaisieDto";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import {Divider, Stack } from "@mui/material";

import Button from 'react-bootstrap/Button';
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import RestPlafondYearService from "../../../ASSURANCES/CounterRestPlafond/Services/RestPlafondYearService";
import IdataModelCounterRestPlafond from "../../../ASSURANCES/CounterRestPlafond/Models/CounterRestPlafond";
import ServiceNewFactureLivraisonMedicament from "../../FactureMedicament/services/ServiceNewFactureLivraisonMedicament";
import ServiceFactureTotalInOnePharmacy from "../../FactureTotalinOne/services/ServiceFactureTotalInOnePharmacy";
import { addMultipleMedicalLivraisonRecordsModifer, clearMedicalLivraisonRecordsModifer } from "../../../../Redux/slices/currentMedical/currentMedicalLivraisonArrayModifie";
import ServiceNewFactureSaisieMedicamentDtoMedicament from "../../FactureMedicament/services/ServiceNewFactureSaisieMedicament";
import NewFactureLivraisonMedicamentDto from "../../FactureMedicament/models/Dto/NewFactureLivraisonMedicamentDto";
import { addMultipleMedicalLivraisonRecordsToprintList, clearMedicalLivraisonRecordsToprint } from "../../../../Redux/slices/currentMedical/currentMedicalLivraisonArrayToPrint";
import NewFactureSaisieMedicamentDto from "../../FactureMedicament/models/Dto/NewFactureSaisieMedicamentDto";
import { Console } from "console";


  
const columns: GridColDef[] = [

    { field: 'plafondFacture', headerName: 'plafond' },

    { field: 'statusFacturePayer', headerName: 'Status S' },
    { field: 'statusFacturePayerPharmacy', headerName: 'Status pha' },
    { field: 'prestation', headerName: 'Description de medicament pour le patientx', width: 400 },
    { field: 'pricePrestation', headerName: 'Price ' },

    { field: 'quantity', headerName: 'Quts' },

  //  { field: 'ticketService', headerName: 'D Ticket' },
    { field: 'ticketServiceFacture', headerName: 'Ticket' },
    { field: 'partMutuelle', headerName: 'Part Mutuelle' },
    { field: 'partpatient', headerName: 'Part Patient' },
    { field: 'partTotal', headerName: 'Total'  },

   // { field: 'partMutuelleFacture', headerName: 'F Part Mutuelle' },
   // { field: 'partpatientFacture', headerName: 'F Part Patient' },
   // { field: 'partTotalFacture', headerName: 'F Total'  },

    { field: 'typeNameVisite', headerName: 'Type Viste' },
   // { field: 'typeBonVerificationName', headerName: 'Verfier' },
    { field: 'typeBonCommendName', headerName: 'Type Bon' },
    { field: 'depertmentName', headerName: 'Depertement' },
    { field: 'dateCreatedSaisie', headerName: 'Created' },
    { field: 'timeSaisie', headerName: 'Time Saisie' },
    
  ];
  const idd: GridRowId[]=[];


const InitRestPlafond={
  codeId:'',
  numeroBon:0,
  partPatientConsomme:0,
  idRestIncrementPlafondConsomme:0,
  status:'payer',
  created_by:'Ramadhani Ally',
  date_created_by: new Date(),
}

export const TableEditFactureMituellePharmacy:React.FC=()=>

{
  const preferredName = useSelector((state: RootState) => state.user.preferred_username);

  const DispatchCurrantMedecineModifier=useDispatch();
  const isgetselecterCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);

const [isInputUpdateFactureLivreePayer, setisInputUpdateFactureLivreePayer] = useState<{ statusFacturePayer: string}>({ statusFacturePayer: ''});
const [isNewFactureLivraisonMedicamentDto, setisNewFactureLivraisonMedicamentDto] = useState<Array<NewFactureLivraisonMedicamentDto>>([]);


  //console.log('values pushed is',isgetselecterCurrantNewVisitePushed)

 const [isInputUpdateFacturePayer, setisInputUpdateFacturePayer] = useState<{
    statusFacturePayer: string,
    pricePrestation:any,
    ticketService:any,
  ticketServiceFacture:any,
  quantity:any,plafondFacture:any,partMutuelle:any,
  partpatient:any,
  partTotal:any,
  
  partMutuelleFacture:any,
  partpatientFacture:any,
  partTotalFacture:any, }>({ statusFacturePayer: '',
   pricePrestation:0 , ticketService:0,
   ticketServiceFacture:0,
   quantity:0,plafondFacture:0,partMutuelle:0,
   partpatient:0,
   partTotal:0,
  
   partMutuelleFacture:0,
   partpatientFacture:0,
   partTotalFacture:0,});

 const isCurrantMedecineList = useSelector((state: RootState) => state.currentMedicalLivraisonArrayModifie);

 const [isInputRestPlafond,setisInputRestPlafond]=useState<IdataModelCounterRestPlafond>(InitRestPlafond);
  
 const [ischeckBillRecent, setCheckBillRecent] = useState(false);

const [selectedIdInRow, setIdRowSelected] = useState(idd);

const [isIdTotalInOne, setIsIdTtoalInOneRecent] = useState<number>(0);

const [totalAllServicesRecent, setTotalAllServicesRecent] = useState(0);
const [factureCouvertAllServicesRecent, setFactureCouvertAllServicesRecent] = useState<number>(0);
const [factureNocouvertAllServicesRecent, setFactureNocouvertAllServicesRecent] = useState(0);
const [partMutuelleFactureAllServicesRecent, setPartMutuelleFactureAllServicesRecent] = useState(0);

const [partPatientFactureAllServicesRecent, setPartPatientFactureAllServicesRecent] = useState(0);

function getCurrentDate(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  // Months are zero-based, so we add 1 to get the correct month
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}


const UpdateFactureSaisieLivreeById = () => {  

  for (let id of selectedIdInRow) {
    console.log('selectedIdInRow vqlues',id)
    UpdateFactureSaisiePharmacieLivraison(id)
  }
};

const UpdateFactureSaisieById = () => {  

    for (let id of isCurrantMedecineList) {
        fetchDataIdIncrimentList(id.idFacture)
    }
  };

const UpdateFactureSaisiePharmacieLivraison = (id: any) => {
   
    ServiceNewFactureLivraisonMedicament.remove(Number(id))
      .then((response: any) => {
       console.log('my delete response',response) 
        DispatchCurrantMedecineModifier(clearMedicalLivraisonRecordsModifer())
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const UpdateFactureSaisiePharmacie = (id: any) => {
    let data = {
      statusFacturePayer: 'No payer',
      pricePrestation:0,
      ticketService:0,
      ticketServiceFacture:0,
      quantity:1,
      plafondFacture:0,
      
      partMutuelle:0,
      partpatient:0,
      partTotal:0,
  
      partMutuelleFacture:0,
      partpatientFacture:0,
      partTotalFacture:0,
    };
  
    ServiceNewFactureSaisieMedicamentDtoMedicament.updateFactureSaisiePharmacyStatusPayer
    (id,
     data.statusFacturePayer,
    data.pricePrestation,
  data.ticketService,
data.ticketServiceFacture,
data.quantity,
data.plafondFacture,

data.partMutuelle,
data.partpatient,
data.partTotal,

data.partMutuelleFacture,
data.partpatientFacture,
data.partTotalFacture)
      .then((response: any) => {
        setisInputUpdateFacturePayer({
          statusFacturePayer: response.data.statusFacturePayer,
          pricePrestation:response.data.pricePrestation,
          ticketService:response.data.ticketService,
      ticketServiceFacture:response.data.ticketServiceFacture,
      quantity:response.data.quantity,
      plafondFacture:response.data.plafondFacture,
      
 partMutuelle:response.data.partMutuelle,
 partpatient:response.data.partpatient,
 partTotal:response.data.partTotal,

 partMutuelleFacture:response.data.partMutuelleFacture,
 partpatientFacture:response.data.partpatientFacture,
 partTotalFacture:response.data.partTotalFacture,
        });
        console.log('your update data values works')
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const fetchDataIdIncrimentList = async (id:any) => {

    try {
      const response = await ServiceNewFactureSaisieMedicamentDtoMedicament.findByIdIncrimentList(id);
      if(response.data.length>0){

        console.log('fetchDataIdIncrimentList if',response.data)
  
        for (let value = 0; value < response.data.length; value++) {
          const val = response.data[value];
        UpdateFactureSaisiePharmacie(val.idFacture); // for ou option
        console.log('your fetch data values works')
        }
        UpdateFactureSaisiePharmacie(id);
        
        }else {
            console.log('fetchDataIdIncrimentList else')
       
           findRecordBy(id);
        }
    } catch (e) {
      console.log(e);
    }
  
  };

  async function findRecordBy(idFacture: number): Promise<NewFactureSaisieMedicamentDto[]> {

    const records = isCurrantMedecineList.filter(item => item.idFacture === idFacture);
    console.log('records values',records[0].idIncrement);

    try {
        const response = await ServiceNewFactureSaisieMedicamentDtoMedicament.findByIdIncrimentList(records[0].idIncrement);
        if(response.data.length>0){
  
          console.log('fetchDataIdIncrimentList if',response.data)
    
          for (let value = 0; value < response.data.length; value++) {
            const val = response.data[value];
          UpdateFactureSaisiePharmacie(val.idFacture); // for ou option
          console.log('your fetch data values works')
          }
          UpdateFactureSaisiePharmacie(records[0].idIncrement);
          
          }else {
              console.log(' else')
             UpdateFactureSaisiePharmacie(records[0].idIncrement);
          }
      } catch (e) {
        console.log(e);
      }
    
    // Perform any necessary actions when no records are found here
    return [];
  }
const checkExistingPayedBill = () => {  

    // Define your parameters
    const params = {
      idinsurance: isgetselecterCurrantNewVisitePushed.idinsurance,
      receiptBill: isgetselecterCurrantNewVisitePushed.receiptBill
    };
    ServiceFactureTotalInOnePharmacy.checkBillNumber(params)
    .then((response: any) => {
      // Check if the response data is not empty
      if (response.data!== '' ) {
        setCheckBillRecent(true)

      setIsIdTtoalInOneRecent(response.data.idFactureInOne)

      console.log("response.idFactureInOne",response.idFactureInOne);

setTotalAllServicesRecent(response.data.totalFactureInOne);
setFactureCouvertAllServicesRecent(response.data.totalFactureInOneCouvert);
setFactureNocouvertAllServicesRecent(response.data.totalFactureInOneNoCouvert);
setPartMutuelleFactureAllServicesRecent(response.data.totalInOnePartSolisFacture);
setPartPatientFactureAllServicesRecent(response.data.totalInOnePartPatientFacture);
      } else {
        // If the response data is empty, handle it accordingly (e.g., call a function)
  
    setCheckBillRecent(false)
        console.log('checkBillNumberDateIdServiceIdDepartment else', response);
  
        console.log('No content found.');
        // Add further handling as per your requirement
  
  setTotalAllServicesRecent(0);
  setFactureCouvertAllServicesRecent(0);
  setFactureNocouvertAllServicesRecent(0);
  setPartMutuelleFactureAllServicesRecent(0);
  setPartPatientFactureAllServicesRecent(0);
      }
    })
    .catch(error => {
      // Handle errors
      console.error('Error fetching data:', error);
      // Add further error handling if needed
    });
  
  };

  const fetchFactures = async () => {
    if (isgetselecterCurrantNewVisitePushed.idFacture !== 0) {
      try {
        const response = await ServiceNewFactureLivraisonMedicament.findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer(
          isgetselecterCurrantNewVisitePushed.idinsurance,
          isgetselecterCurrantNewVisitePushed.receiptBill,
          "payer"
        );
        const facture = response.data; 
     //   setisNewFactureLivraisonMedicamentDto(facture);
        
      setIdRowSelected(facture.map((row: { idLivree: any; }) => row.idLivree));
      

     // console.log("facture.map((row: { idFacture: any; }) => row.idFacture)",facture.map((row: { idLivree: any; }) => row.idLivree))  
      
        DispatchCurrantMedecineModifier(clearMedicalLivraisonRecordsModifer())
        DispatchCurrantMedecineModifier(addMultipleMedicalLivraisonRecordsModifer(facture))
        checkExistingPayedBill();
  
      } catch (e) {
        console.log(e);
      }
    }
  };
  useEffect(() => {
    fetchFactures();
  }, [DispatchCurrantMedecineModifier]);
  

const removeFactureTotoalInOne = () => { 

    ServiceFactureTotalInOnePharmacy.remove(Number(isIdTotalInOne))
      .then((response: any) => {
       
          UpdateRestPlafond();
          UpdateFactureSaisieById();
          UpdateFactureSaisieLivreeById();

          console.log(response.data);
      }).catch((e: Error) => {
    console.log(e);
  });
  
};


const updateBill = () => { 

    
  if(ischeckBillRecent===false){
    console.log("no value to update")
  }else{
    removeFactureTotoalInOne()
  }
};
const facturation =()=>{

    DispatchCurrantMedecineModifier(clearMedicalLivraisonRecordsToprint())
    DispatchCurrantMedecineModifier(addMultipleMedicalLivraisonRecordsToprintList(isCurrantMedecineList))
}

const UpdateRestPlafond = () => { 
  let currentDateFormat=getCurrentDate()
  
    let data = {
      codeId:isgetselecterCurrantNewVisitePushed.codeAssuree,
      numeroBon:isgetselecterCurrantNewVisitePushed.receiptBill,
      partPatientConsomme:- Number(partPatientFactureAllServicesRecent),
      idRestIncrementPlafondConsomme:0,
      status:"payer" ,
      created_by:preferredName,
      date_created_by:currentDateFormat
    };
  
    RestPlafondYearService.createRestPlafond(
      isgetselecterCurrantNewVisitePushed.idinsurance,
      isgetselecterCurrantNewVisitePushed.idEnterprise,
      isgetselecterCurrantNewVisitePushed.idpmember,
      isgetselecterCurrantNewVisitePushed.plafondYearId,
      isgetselecterCurrantNewVisitePushed.categoryId,
      isgetselecterCurrantNewVisitePushed.idVisite,
      data)
    .then((response: any) => {
      setisInputRestPlafond({
        id: response.data.id,
        codeId: response.data.incrementIdReceiptBill,
        numeroBon: response.data.receiptBill,
        partPatientConsomme:response.data.partPatientConsomme,
        idRestIncrementPlafondConsomme:response.data.idRestIncrementPlafondConsomme,
        status:response.data.status,
        created_by: response.data.created_by,
        date_created_by: response.data.date_created_by
      });
      console.log(response.data); 
     // console.log("isPartPatientFacture",- Number(partPatientFactureAllServicesRecent))
    }).catch((e: Error) => {
      console.log(e);
    });
    
  
};
const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }

      return (
        <>
     <div>

<Form.Label ><strong>Facture</strong></Form.Label>
       <Stack
  direction="row"
  divider={<Divider orientation="vertical" flexItem />}
  spacing={2}
>
              <Form.Label><strong>Total de Facture</strong>:{totalAllServicesRecent}</Form.Label>
              <Form.Label><strong>Part Patient </strong>:{partPatientFactureAllServicesRecent}</Form.Label> 
              <Form.Label><strong>Part Mituelle </strong>:{partMutuelleFactureAllServicesRecent}</Form.Label> 
              <Form.Label><strong>Facture couvert</strong>:{factureCouvertAllServicesRecent}</Form.Label> 
              <Form.Label><strong>Facture No couvert</strong>:{factureNocouvertAllServicesRecent}</Form.Label> 
</Stack>     



     </div>
     
<div style={{ width: '100%' }}>
<DataGrid 
          rows={isCurrantMedecineList}
          getRowId={(row) => row.idLivree}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel:{page:0,pageSize:10},
            },
          }}
          pageSizeOptions={[10,15,25,35,45]}
            autoHeight
            
          checkboxSelection
         rowSelectionModel={selectedIdInRow}
        
        />

 <Stack 
 direction="row"
 spacing={3}>
  

      
   <Button
     onClick={() => { updateBill()}}
     variant='me-4 btn btn-success btn-sm btn-block'
   >
     Facture No payer
   </Button>
   <Button
     onClick={() => { facturation()}}
     variant='me-4 btn btn-success btn-sm btn-block'
   >
     Evoiyer au facturation
   </Button>

 </Stack>

     </div>


          
        </>
      );
    }
