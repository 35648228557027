import {  useEffect, useState } from "react";
import HospitalTypeService from "../Services/HospitalTypeService";
import { Row, Form, InputGroup, Accordion } from "react-bootstrap";
import IdataModelHospitalType from "../Models/IdataModeHospitalType";
import IdataModelHospitalCategoryDtos from '../Models/IdataModelHospitalCategoryDtos';
import IdataModelCategoriesAcessSoins from "../../../Categories/CategorieSoinsHopitaux/Models/IdataModelCategoriesAcessSoins";
import CategoriesAcessSoinsServices from "../../../Categories/CategorieSoinsHopitaux/Services/CategoriesAcessSoinsServices";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/Store/store";

interface EditHospitalTypeList {
    show: boolean; 
    onHide: () => void; 
    EditInfoHospitalType: IdataModelHospitalCategoryDtos;
  }
  const defaultinitiaCategories: Array<IdataModelCategoriesAcessSoins> = [];
  let acessSoinsCategoryId=0

  export const HospitalTypeEdit :React.FC<EditHospitalTypeList>=props=> {

    const [acessSoinsCategory, setAcessSoinsCategory] = useState(defaultinitiaCategories);


    const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
    const initialTypeState = {
    id: null,
    hospitalName: '',
    raisonSociale:'',
    tel:'',
    nif:'',
    rc:'',
    bp:'',
    avenue:'',
    centreFiscal:'',
    secteurActivite:'',
    formeJuridique:'',
    tva:'',
      typehospital: '',
      hospitalAccount:0,
      createdBy: 'Ghost Zila',
      dateCreatedBy: new Date()
    };
    const [selectedStructure, setSelectedStructure] = useState('');
    
    const handleChangeStructure  = (e: { target: {value: any; }; }) => {
     
       setSelectedStructure(e.target.value);
       console.log(e.target.value)
       
    };

    const [isInputDtos,setInputDtos]=useState<IdataModelHospitalCategoryDtos>
    (props.EditInfoHospitalType);
    const [isInput,setInput]=useState<IdataModelHospitalType>(initialTypeState);


    
const handleChange = (e: { target: { name: any; value: any; }; }) => {
  setInputDtos({ ...isInputDtos, [e.target.name]: e.target.value });
}
   
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditHospital= (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              hospitalName: selectedStructure,
              typehospital:isInputDtos.typehospital,
              raisonSociale:isInputDtos.raisonSociale,
              tel:isInputDtos.tel,
              nif:isInputDtos.nif,
              rc:isInputDtos.rc,
              bp:isInputDtos.bp,
              avenue:isInputDtos.avenue,
              centreFiscal:isInputDtos.centreFiscal,
              secteurActivite:isInputDtos.secteurActivite,
              formeJuridique:isInputDtos.formeJuridique,
              tva:isInputDtos.tva,
              createdBy:isInput.createdBy,
              dateCreatedBy: isInput.dateCreatedBy
            };
    if(acessSoinsCategoryId===0){
      HospitalTypeService.update(isInputDtos.hospitalId,data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                hospitalName: response.data.hospitalName,

                raisonSociale:response.data.raisonSociale,
                tel:response.data.tel,
                nif:response.data.nif,
                rc:response.data.rc,
                bp:response.data.bp,
                avenue:response.data.avenue,
                centreFiscal:response.data.centreFiscal,
                secteurActivite:response.data.secteurActivite,
                formeJuridique:response.data.formeJuridique,
                tva:response.data.tva,
    
                typehospital:response.data.typehospital,
                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);
              EditCancel()
            })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);

    }else{
      HospitalTypeService.updateAll(isInputDtos.hospitalId,acessSoinsCategoryId,data)
      .then((response: any) => {
        setInput({
          id: response.data.id,
          hospitalName: response.data.hospitalName,

          raisonSociale:response.data.raisonSociale,
          tel:response.data.tel,
          nif:response.data.nif,
          rc:response.data.rc,
          bp:response.data.bp,
          avenue:response.data.avenue,
          centreFiscal:response.data.centreFiscal,
          secteurActivite:response.data.secteurActivite,
          formeJuridique:response.data.formeJuridique,
          tva:response.data.tva,

          typehospital:response.data.typehospital,
          createdBy: response.data.createdBy,
          dateCreatedBy: response.data.dateCreatedBy
        });
        console.log(response.data);
        console.log(data);
        EditCancel()
      })
      .catch((e: Error) => {
        console.log(e);
      });
      console.log(isInput);
    }
            
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
          const handleChangeAcessSoinsCategoris = (e: { target: {value: any; }; }) => {
            acessSoinsCategoryId=e.target.value
             console.log('AcessSoinsCategoris  ',acessSoinsCategoryId);
              }
          const CategoryAccessSoinsWithoutPagination = () => {
  
            CategoriesAcessSoinsServices.getCategoryAccessSoinsWithoutPagination()
              .then((response: any) => {
                setAcessSoinsCategory(response.data);
              })
              .catch((e: Error) => {
                console.log(e);
              });
          };
          
  useEffect(() => {
    CategoryAccessSoinsWithoutPagination();
    setInputDtos(props.EditInfoHospitalType)

      },[])
      
          return(
            <>
        < form  onSubmit={onFormSubmit}>
         <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Edit hospital</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
            <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
      <Form.Label>Structrures</Form.Label>
      <Form.Select
        className="form-control"
        name="typehospital"
        value={selectedStructure}
        onChange={handleChangeStructure}
      >
        <option defaultValue={isInputDtos.hospitalName} >{isInputDtos.hospitalName} </option>
        {StructuresData.map((group, index) => (
          <option key={index} value={group}>
            {group}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <Form.Label>Type Hospital</Form.Label>
            <Form.Select className="form-control" name="typehospital" value={isInputDtos.typehospital}
             onChange={handleChange}>
                <option defaultValue={isInputDtos.typehospital}>{isInputDtos.typehospital}</option>
                <option value="Private">Private</option>
                <option value="Public">Public</option>
                <option value="Home">Home</option>
            </Form.Select>
        </Form.Group>
           
            </Row>
            <Row className="mb-3">
         <Form.Group controlId="formGridCheckbox" className="col col-sm-14">
          <Form.Label>Categories Autorise </Form.Label>
    <Form.Select className="form-control" 
        onChange={handleChangeAcessSoinsCategoris}>
        <option defaultValue={isInputDtos.categorySoinsName}>{isInputDtos.categorySoinsName}</option>
      {acessSoinsCategory &&
         acessSoinsCategory.map((acessSoinsCategory) => (
            <option key={acessSoinsCategory.id} value={acessSoinsCategory.id} >
              {acessSoinsCategory.categorySoinsName}</option>
))}
    
</Form.Select>
</Form.Group>
         </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Edit info sur Facture</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Raison social</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="raisonSociale" value={isInputDtos.raisonSociale} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Tel</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="tel" value={isInputDtos.tel} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Nif</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="number" name="nif" value={isInputDtos.nif} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>RC</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="rc" value={isInputDtos.rc} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>BP</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="bp" value={isInputDtos.bp} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Avenue</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="avenue" value={isInputDtos.avenue} onChange={handleChange} />
    </InputGroup>
</Form.Group>

<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Centre Fiscal</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="centreFiscal" value={isInputDtos.centreFiscal} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Secteur d'Activite</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="secteurActivite" value={isInputDtos.secteurActivite} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
    <Form.Label>Forme juridique</Form.Label>
    <InputGroup>
        <Form.Control size="sm"  type="text" name="formeJuridique" value={isInputDtos.formeJuridique} onChange={handleChange} />
    </InputGroup>
</Form.Group>
<Form.Group controlId="formBasicemail" className="col col-sm-3">
 <Form.Label>TVA</Form.Label>
    <Form.Select  size="sm" name="tva" value={isInputDtos.tva} onChange={handleChange}>
        <option>Choisir </option>
       <option value="No">No</option>
     <option value="Oui">Oui</option>
</Form.Select>
</Form.Group>
</Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </form>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditHospital} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
            </>
        );
  }
