import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelPharmacy from "../Models/IdataModelPharmacy";

const getAll = (params:any) => {
  return http.get<Array<IdataModelPharmacy>>("/Pharmacies", { params });
};

const getAllTypeDoctorWithoutPagination = () => {
  return http.get<Array<IdataModelPharmacy>>("/sortedPharmacies");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelPharmacy>>("/PharmaciesAccount");
}

const create = (data: IdataModelPharmacy) => {
  return http.post<IdataModelPharmacy>("/Pharmacies", data);
};

const update = (id: any, data: IdataModelPharmacy) => {
  return http.put<any>(`/Pharmacies/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/Pharmacies/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/Pharmacies`);
};

const findByPharmacyName = (pharmacyName: string) => {
  return http.get<Array<IdataModelPharmacy>>(`/Pharmacies?pharmacyName=${pharmacyName}`);
};

const findByPharmacyNameOnly = (pharmacyName: any) => {
  return http.get<IdataModelPharmacy>(`/getInfoHospital?pharmacyName=${pharmacyName}`);
};


const PharmacyTypeService = {
  getAllTypeDoctorWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByPharmacyName,  
 findByPharmacyNameOnly
};

export default PharmacyTypeService;
