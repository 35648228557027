import { useState, ChangeEvent, useEffect } from 'react';
import { Row, Form, InputGroup } from "react-bootstrap";
import TypeBonsDtos from '../Models/TypeBonsDtos';
import IdataModelTypeBons from '../Models/IdataModelTypeBons ';
import TypeBonsService from '../Services/TypeBonsService';

  interface IEditType {
    show: boolean; 
    onHide: () => void; 
    EditInfo: TypeBonsDtos;
  }

  export const EditTypeBons :React.FC<IEditType>=props=> {

    const initialTypeState = {
      id: null,
      typeName: '',
      code:'',
      account:0,
      createdBy: '',
      dateCreatedBy: new Date()
    };

    const [isInputDto,setInputDto]=useState<TypeBonsDtos>(props.EditInfo);

    const [isInput,setInput]=useState<IdataModelTypeBons>(initialTypeState);


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInput({ ...isInput, [name]: value });
       // console.log(event.target)
        }
        const handleChangeDtos = (event: ChangeEvent<HTMLInputElement>) => {
          const { name, value } = event.target;
          setInputDto({ ...isInputDto, [name]: value });
         // console.log(event.target)
          }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditType = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              typeName: isInputDto.typeBonsName,
              code: isInputDto.code,

              createdBy:isInput.createdBy,
              dateCreatedBy: isInput.dateCreatedBy
            };
    
            TypeBonsService.update(isInputDto.typeBonsId,data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                typeName: response.data.typeName,
                code: response.data.code,

                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
          
  useEffect(() => {

    setInputDto(props.EditInfo)

      },[props])
      
          return(
            <>
          
        < form  onSubmit={onFormSubmit}>
            <Row className="mb-3">
            <Form.Group controlId="formBasicEmail" className="col col-sm-6">
               <Form.Label>Edit Type Visite</Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="structureName" name="typeBonsName" value={ isInputDto.typeBonsName} onChange={handleChangeDtos} />
               </InputGroup>
           </Form.Group>
           <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Code </Form.Label>
            <InputGroup>
            <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="text" name="code" value={isInputDto.code} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
            </Row>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditType} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
