import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card } from "react-bootstrap";
import IdataModelSejourVisitePlafondDtos from '../Models/IdataModelSejourVisitePlafondDtos';
import IdataModelSejourPlafond from '../Models/IdataModelSejourPlafond';
import ServicePlafondServiseVisites from '../Services/ServicePlafondServiseVisites';
import IdataModeEnterprise from '../../../../ASSURANCES/Entreprises/Models/IdataModeEnterprise';
import IdataModelSejourVisite from '../../InOneSejourVisites/Models/IdataModelSejourVisite';
import EnterpriseService from '../../../../ASSURANCES/Entreprises/Services/EnterpriseService';
import TypeService from '../../InOneSejourVisites/Services/TypeServices';


const initialTypeState = {
  id: null,
  plafondFacture: '',
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
const defaultenterprise:Array<IdataModeEnterprise>=[];
const defaultinOneSejourVisite:Array<IdataModelSejourVisite>=[];
let selectedEnterpriseId:any;
let selectedInOneSejourVisiteId:any;
export const Addplafond:React.FC=()=> {

const [isInput,setInput]=useState<IdataModelSejourPlafond>(initialTypeState);
const [enterprise, setenterprise] = useState(defaultenterprise);
const [inOneSejourVisite, setinOneSejourVisite] = useState(defaultinOneSejourVisite);

const getAllWithoutPaginationEnterprise = () => {
  
  EnterpriseService.getAllEnterpriseNameWithoutPagination()
    .then((response: any) => {
      setenterprise(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
const getAllWithoutPaginationInOneSejourVisite = () => {
  
  TypeService.getAllTypeWithoutPagination()
    .then((response: any) => {
      setinOneSejourVisite(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
useEffect(() => {
  getAllWithoutPaginationInOneSejourVisite();
  getAllWithoutPaginationEnterprise();
});
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const handleChangeEnterprise= (e: { target: {value: any; }; }) => {
         selectedEnterpriseId=e.target.value;
          }
          const handleChangeInOneSejourVisites= (e: { target: {value: any; }; }) => {
            selectedInOneSejourVisiteId=e.target.value;
              }   
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        let data = {
          plafondFacture: isInput.plafondFacture,
          createdBy:'ghost zila',
          dateCreatedBy: new Date()
        };

        ServicePlafondServiseVisites.create(52,selectedEnterpriseId,selectedInOneSejourVisiteId,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            plafondFacture: response.data.plafond,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }

     
    return(
        <>
        
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
               <Form.Label>plafond</Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="number" name="plafondFacture"
                    value={ isInput.plafondFacture} 
                   onChange={handleChange} />
               </InputGroup>
           </Form.Group>

   <Form.Group controlId="formBasicEmail" className="col col-sm-6">
         <Form.Label>Sejour/Visites</Form.Label>
    <Form.Select className="form-control"  size="sm"  
     onChange={handleChangeInOneSejourVisites}>
       <option value="0">Choose..</option>
      {inOneSejourVisite &&
    inOneSejourVisite.map((inOneSejourVisite) => (
        <option key={inOneSejourVisite.id} value={inOneSejourVisite.id} >{inOneSejourVisite.typeName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
         </Row>

         <Row className="mb-3">
  <Form.Group controlId="formBasicEmail" className="col col-sm-6">
         <Form.Label>Souf Entreprise</Form.Label>
    <Form.Select className="form-control"  size="sm"  
     onChange={handleChangeEnterprise}>
       <option value="0">Choose..</option>
      {enterprise &&
    enterprise.map((enterprise) => (
        <option key={enterprise.id} value={enterprise.id} >{enterprise.enterpriseName}</option>
    ))}
        
    </Form.Select>
  </Form.Group>
         </Row>

         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}