import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row } from "react-bootstrap";
import CotisationAssuranceDetails from '../Models/CotisationAssuranceDetails';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Redux/Store/store';
import ServiceCotisationAssuranceDetails from '../Services/ServiceCotisationAssurance';
import ServiceCotisationAssurance from '../../PreparationFacture/Services/ServiceCotisationAssurance';
import CotisationAssuranceDto from '../../PreparationFacture/Models/CotisationAssuranceDto';

const initialTypeState = {
  id: null, 
  codeCategory:'',
  codeCategorySoins:'',

  number: 0,
  total: 0,
  comment:  '',

  
  createdBy:  '',
  dateCreated:  '',
  timeCreated:  '',
};
const defaultenterprise:Array<CotisationAssuranceDto>=[];

let selectedID:any;

export const Add:React.FC=()=> {

  const preferredName = useSelector((state: RootState) => state.user.preferred_username);

  const [enterprise, setenterprise] = useState(defaultenterprise);

const [isInput,setInput]=useState<CotisationAssuranceDetails>(initialTypeState);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const handleChangeEnterprise= (e: { target: {value: any; }; }) => {
        selectedID=e.target.value;
          }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        const now = new Date();

        // Format date as YYYY-MM-DD
        const dateCreated = now.toISOString().split('T')[0];
    
        // Format time as HH:MM:SS
        const timeCreated = now.toTimeString().split(' ')[0];
  
    
        let data = {
          codeCategory:isInput.codeCategory,
    codeCategorySoins:isInput.codeCategorySoins,

          number: isInput.number,
          total: isInput.total,
          comment:  isInput.comment,
          createdBy: preferredName,
          dateCreated: dateCreated,
          timeCreated: timeCreated,
        };

        ServiceCotisationAssuranceDetails.create(selectedID,data)
        .then((response: any) => {
          setInput({
            id: response.data.id, 
            codeCategory:response.data.codeCategory,
           codeCategorySoins:response.data.codeCategorySoins,
  
            number: response.data.number,
            total: response.data.total,
            createdBy: response.data.createdBy,
            comment: response.data.comment,
            dateCreated: response.data.dateCreated,
            timeCreated: response.data.timeCreated
          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialTypeState);
      }
      const getAllWithoutPaginationEnterprise = () => {
  
        ServiceCotisationAssurance.getfindAllByStatusNoPayer()
          .then((response: any) => {
            setenterprise(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      };
     
useEffect(() => {
  getAllWithoutPaginationEnterprise();

});
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>money</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="number" name="number" value={isInput.number} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
         <Form.Label>Entreprise</Form.Label>
    <Form.Select className="form-control"  size="sm"  
     onChange={handleChangeEnterprise}>
       <option value="0">Choose..</option>
      {enterprise &&
    enterprise.map((enterprise) => (
        <option key={enterprise.id} value={enterprise.id} >{enterprise.enterpriseName}</option>
    ))}
        
    </Form.Select>
   </Form.Group>
         </Row>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>comment</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="text" name="comment" value={isInput.comment} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>total</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="number" name="total" value={isInput.total} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>

         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>code</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="text" name="codeCategory" value={isInput.codeCategory} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Category</Form.Label>
            <InputGroup>
                <Form.Control  size="sm" aria-describedby="basic-addon2" type="text" name="codeCategorySoins" value={isInput.codeCategorySoins} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}