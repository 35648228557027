import http from "../../../../AxiosRequest/httpcommon";
import IdataModelPriceActes from "../Models/IdataModelPriceActes";
import IdataModelPriceActesDtos from "../Models/IdataModelPriceActesDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelPriceActesDtos>>("/AllSoinPriceDtosPage", { params });
};
const getAllByStructure = (params:any) => {
  return http.get<Array<IdataModelPriceActesDtos>>("/AllSoinPriceDtosPageStructure", { params });
};

const getAllsoinsTariffNameWithoutPagination = () => {
  return http.get<Array<IdataModelPriceActes>>("/sortedsoinsTariff");
};

const create = (detailsSoinsId:any,hospitalId:any,data: IdataModelPriceActes) => {
  return http.post<IdataModelPriceActes>(`/${detailsSoinsId}/${hospitalId}/soinsTariff`, data);
};

const update = (id:any, data: IdataModelPriceActes) => {
  return http.put<any>(`/soinsTariff/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/soinsTariff/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/soinsTariff`);
};

const findByDetailsSoinsName = (intitule: string) => {
  return http.get<Array<IdataModelPriceActesDtos>>(`/AllSoinPriceDtosPage?intitule=${intitule}`);
};
const findByDetailsSoinsNameByStructure = (intitule: string, hospitalName: string) => {
  return http.get<Array<IdataModelPriceActesDtos>>(`/AllSoinPriceDtosPageStructure?intitule=${intitule}&hospitalName=${hospitalName}`);
};

const PriceSoinsService = {
  getAllsoinsTariffNameWithoutPagination,
  getAll,
  getAllByStructure,
  create,
  update,
  remove,
  removeAll,
  findByDetailsSoinsName,
  findByDetailsSoinsNameByStructure
};

export default PriceSoinsService;
