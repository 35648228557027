import http from "../../../../AxiosRequest/httpcommon";
import FactureServieDto from "../Model/Dto/FactureServieDto";
import FactureServie from "../Model/FactureServie";



const getAllVisitelWithoutPagination = () => {
    return http.get<Array<FactureServie>>("/sortedFactureServie");
  };
  
const findBySocialinsuranceIdRecieptAndHospitalBetweenDateServiePage = (params:any) => {
  return http.get<Array<FactureServieDto>>("/findAll", { params });
};

const findBySocialinsuranceIdRecieptAndHospitalBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdRecieptAndHospitalBetweenDateServieList");
  };

const findBySocialinsuranceIdRecieptBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdRecieptBetweenDateServiePage", { params });
  };

  
const findBySocialinsuranceIdRecieptBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdRecieptBetweenDateServieList");
  };

  const findBySocialinsuranceIdCodeAssureBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdCodeAssureBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdCodeAssureBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdCodeAssureBetweenDateServieList");
  };



  const findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServieList");
  };

  const findBySocialinsuranceIdCodeAssureEnterpriseHospitalBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdCodeAssureEnterpriseHospitalBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdCodeAssureEnterpriseHospitalBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdCodeAssureEnterpriseHospitalBetweenDateServieList");
  };


  const findBySocialinsuranceIdBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdAndHospitalBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalTypeVisiteBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalTypeVisiteBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdAndHospitalTypeVisiteBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalTypeVisiteBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalAndTypeBonCommendBetweenDateServiePage= (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalAndTypeBonCommendBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdAndHospitalAndTypeBonCommendBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalAndTypeBonCommendBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalTypeBonVerifactionBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalTypeBonVerifactionBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdAndHospitalTypeBonVerifactionBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalTypeBonVerifactionBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalServiceConfigBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalServiceConfigBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdAndHospitalServiceConfigBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalServiceConfigBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalDepertmentIdBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalDepertmentIdBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdAndHospitalDepertmentIdBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalDepertmentIdBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServieList");
  };

  const findBySocialinsuranceIdAndHospitalDepertmentIdEnterpriseNameBetweenDateServiePage = (params:any) => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalDepertmentIdEnterpriseNameBetweenDateServiePage", { params });
  };

const findBySocialinsuranceIdAndHospitalDepertmentEnterpriseNameIdBetweenDateServieList = () => {
    return http.get<Array<FactureServieDto>>("/findBySocialinsuranceIdAndHospitalDepertmentEnterpriseNameIdBetweenDateServieList");
  };

const findById=(id:any)=>{
  return http.get<Array<FactureServie>>(`/FactureServie/${id}`);
}
const createFactureServie = (socialinsuranceId:any,enterprisesAssurance_id:any,hospital_id:any,pMember_id:any,typeBons_id:any,typeBonsCommend_id:any,typeBonsVerification_id:any,factureSaisie_id:any,nameDoctors_id:any,typeDoctors_id:any,couvertureConfig_id:any,typeDepartment_id:any,newVisitePatient_id:any,data: FactureServie) => {

return http.post<FactureServie>(`/socialinsurance/${socialinsuranceId}/${enterprisesAssurance_id}/${hospital_id}/${pMember_id}/${typeBons_id}/${typeBonsCommend_id}/${typeBonsVerification_id}/${factureSaisie_id}/${nameDoctors_id}/${typeDoctors_id}/${couvertureConfig_id}/${typeDepartment_id}/${newVisitePatient_id}/FactureServie`, data);
};

const update = (id: any, data: FactureServie) => {
  return http.put<any>(`/FactureSaisie/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/FactureSaisie/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/FactureSaisie`);
};


const ServicesFactureServie = {

    findBySocialinsuranceIdRecieptAndHospitalBetweenDateServiePage,
    findBySocialinsuranceIdRecieptAndHospitalBetweenDateServieList,  
    findBySocialinsuranceIdRecieptBetweenDateServiePage,
    findBySocialinsuranceIdRecieptBetweenDateServieList,
    findBySocialinsuranceIdCodeAssureBetweenDateServiePage,
    findBySocialinsuranceIdCodeAssureBetweenDateServieList,
    findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServiePage,
    findBySocialinsuranceIdCodeAssureEnterpriseBetweenDateServieList,
    findBySocialinsuranceIdCodeAssureEnterpriseHospitalBetweenDateServiePage,
    findBySocialinsuranceIdCodeAssureEnterpriseHospitalBetweenDateServieList,
    findBySocialinsuranceIdBetweenDateServiePage,
    findBySocialinsuranceIdBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalEnterpriseBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalTypeVisiteBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalTypeVisiteBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalAndTypeBonCommendBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalAndTypeBonCommendBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalTypeBonVerifactionBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalTypeBonVerifactionBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalServiceConfigBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalServiceConfigBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalDepertmentIdBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalDepertmentIdBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalServiceConfigEnterpriseBetweenDateServieList,
    findBySocialinsuranceIdAndHospitalDepertmentIdEnterpriseNameBetweenDateServiePage,
    findBySocialinsuranceIdAndHospitalDepertmentEnterpriseNameIdBetweenDateServieList,

    findById,
    update,
    remove,
    removeAll,
    getAllVisitelWithoutPagination,
    createFactureServie,
    };

export default ServicesFactureServie;