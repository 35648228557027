import { ChangeEvent, useEffect, useState } from "react";
import IdataModelCategoriesAcessSoins from "../Models/IdataModelCategoriesAcessSoins";
import { Row, Form, InputGroup } from "react-bootstrap";
import CategoriesAcessSoinsServices from "../Services/CategoriesAcessSoinsServices";

interface EditCategoryAcessSoinsList {
    show: boolean; 
    onHide: () => void; 
    EditInfoCategoryAcessSoins:IdataModelCategoriesAcessSoins;
  }

  export const CategoriesAcessSoinsEdit :React.FC<EditCategoryAcessSoinsList>=props=> {

    const initialTypeState = {
      id: null,
      categorySoinsName: '',
      account:0,
      createdBy: '',
      dateCreatedBy: new Date()
    };

    const [isInput,setInput]=useState
    <IdataModelCategoriesAcessSoins>(props.EditInfoCategoryAcessSoins);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInput({ ...isInput, [name]: value });
       // console.log(event.target)
        }
   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditTypeDoctors = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              categorySoinsName: isInput.categorySoinsName,
              createdBy:isInput.createdBy,
              dateCreatedBy: isInput.dateCreatedBy
            };
    
            CategoriesAcessSoinsServices.update(isInput.id,data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                categorySoinsName: response.data.categorySoinsName,
                createdBy: response.data.createdBy,
                dateCreatedBy: response.data.dateCreatedBy
              });
              console.log(response.data);
              console.log(data);
              EditCancel()
            })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
            
  useEffect(() => {
    
      setInput(props.EditInfoCategoryAcessSoins)
    
      },[props.EditInfoCategoryAcessSoins])
      
          return(
            <>
        < form  onSubmit={onFormSubmit}>
            <Row className="mb-6">
            <Form.Group controlId="formBasicEmail" className="col col-sm-12">
               <Form.Label>Edit Categories Acess Soins</Form.Label>
               <InputGroup>
                   <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="categorySoinsName" name="categorySoinsName" value={ isInput.categorySoinsName} onChange={handleChange} />
               </InputGroup>
           </Form.Group>
            </Row>
            <Row className="mb-6">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditTypeDoctors} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
