import { Container, Row, Col, Card } from "react-bootstrap";
import { CompListeCotisationClassOuNoClassParEnterprise } from "../../../../Components/ASSURANCES/Recouvrements/Comptabillite/CompListeCotisationClassOuNoClassParEnterprise";
import { CompListeCotisationPayerOuNopayerParTous } from "../../../../Components/ASSURANCES/Recouvrements/Comptabillite/CompListeCotisationPayerOuNopayerParTous";
import { CompListeCotisationPayerOuNopayerParIndependant } from "../../../../Components/ASSURANCES/Recouvrements/Comptabillite/CompListeCotisationPayerOuNopayerParIndependant";

export const ListeCotisationPayerOuNopayerParIndependant=()=>{

    return (

         <Container fluid>
         <Row>
          <Col>
         <br />
           <Card border="secondary" >
             <Card.Body>
             <CompListeCotisationPayerOuNopayerParIndependant/>
               </Card.Body>
           </Card>
           <br />
          </Col>
         </Row>
         
         </Container>
    );
}

