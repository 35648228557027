import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography'; // Import Typography

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const MyPhotos: React.FC<ModalProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="modal-title">
      <DialogTitle id="modal-title">
        <Typography variant="h6">Patient Photo</Typography>
        <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <p>BABA visaa here...</p>
      </DialogContent>
    </Dialog>
  );
};

export default MyPhotos;
