/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Form, Row, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { Button, Divider, Stack } from "@mui/material";
import NewFactureSaisieDto from "../../models/Dto/NewFactureSaisieDto";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/Store/store";



const NewFactureSaisieDtoInt ={
    
  /// facture fa
  idFacture:'',
  prestation:'', //itule
  prestationPrescriptPar:'',
  pricePrestation:'',
  plafondFacture:'',
  ticketService:'',
  quantity:'',
  partMutuelle:'',
  partpatient:'',
  partTotal:'',
  statusFacturePayer:'',
  createdBy:'',
  dateCreatedSaisie:'',
  timeSaisie:'',


  partMutuelleFacture:0,
  partpatientFacture:0,
  partTotalFacture:0,
  
  ticketServiceFacture:0,

  statusFactureDeclare:'',
  statusFactureVerifier:'',
  statusFactureRembourceAvecAutreFacture:'',
  statusFactureRembourceUneFacture:'',

 /// hospital h
  idhospital:'',
  hospitalName:'',
  referenceId:'',
  typehospital:'',
  hospitalAccount:'',

 /// docteur
  idDoctorName:'',
  doctorName:'',
  prescriptPar:'',
   priceConsultation:'',
  accountNameDoctor:'',

 /// typeDoctor
 idtypeDoctor:'',
 typedoctorName:'',
 accountTypeDoctor:'',
 /// couvertureConfig  se
 idCouvertureConfig:'',
  service:'',
  ticket:'',
  particulie:'',
  type_category:'',
  access:'',
  secter:'',
  plafondOrdonence:'',
/// new visite
  idNewVisite:'',
  receiptBill:'',
  patientName:'',
  typeAssuree:'',
 patientCode:'',
 newVisitecreatedBy:'',
  dateCreatedVisite:'',
  timeCreated:'',

 //social Insuarance so
  idinsurance:'',
  insuranceName:'',
 //////////////////////////////////////////
 /// Enterprise   e
  idEnterprise:'',
  accountEnterprise:'',
  enterpriseName:'',
  couvertureDu:'',
  couvertureAau:'',
  modePayment:'',
  frequancePayment:'',
 /// pmember   p
  idpmember:'',
  codeAssuree:'',
  nomPrenomAffilie:'',
  cotisationExact:'',
  accountPmember:'',
  catégorieSoins:'',
  category_code:'',
 ///// typeBons Visite  tvi
  idVisite:'',
  typeNameVisite:'',
  accountVisite:'',

 ///// typeBon declaration verifivcation rembourcement b
  idtypeBonverification:'',
  typeBonVerificationName:'',
  accountVerificationName:'',
 /// typeBon Commend  tc
  idTypeBonCommend:'',
  typeBonCommendName:'',
  accountBonCommend:'',
 /// depertment dp
  idDepertment:'',
  depertmentName:'',

}
  interface ICurrantNewSaisieFacture {
    componentToPrint: React.MutableRefObject<null>;

  }

  const defaultList: Array<NewFactureSaisieDto> = [];

export const TableFacturationTousSansMituelle:React.FC<ICurrantNewSaisieFacture>=props=>

{

   const { componentToPrint } = props;

const isListToprint=useSelector((state:RootState)=>state.newFactureSansMutuelle);

const isgetCurrantNewVisitePushed=useSelector((state:RootState)=>state.currentNewFacture);
const operaterName=useSelector((state:RootState)=>state.user.preferred_username);



    const [isfactureCouvert, setisfactureCouvert] = useState(0);
    const [isfactureNoCouvert, setisfactureNoCouvert] = useState(0);
    const [isfactureTotal, setisfactureTotal] = useState(0);

    const [isPartMituelle, setisPartMituelle] = useState(0);
    const [isPartPatient, setisPartPatient] = useState(0);

    const AnalyseFactureToPrint = (selectedRowToPrint:any) => {  

      if(selectedRowToPrint.length===0)
  {
    setisfactureCouvert(0)
    setisfactureNoCouvert(0)
    setisPartPatient(0)
    setisPartMituelle(0)
    setisfactureTotal(0)
   
   
  }else
  {

// Create a Map to store the grouped results
const groupedByService = new Map<string, {
  total: number,
  quantity: number,
  FactureCouvert: number,
  FactureNocouvert: number,
  PartMutuelle: number,
  PartPatient: number // New key for PartPatient
}>();

// Iterate through the array and update the Map
selectedRowToPrint.forEach((entry: { service: any; quantity: any; partTotal: any; plafondFacture: any; ticket: any; }) => {
  const { service, quantity, partTotal, plafondFacture, ticket } = entry;

  if (groupedByService.has(service)) {
      // If the service is already in the Map, update the total and quantity
      const existingEntry = groupedByService.get(service)!;
      existingEntry.total += partTotal;
      existingEntry.quantity += quantity;

      // Check if the total is less than or equal to plafondFacture
      if (existingEntry.total <= plafondFacture) {
          existingEntry.FactureCouvert = existingEntry.total;
          existingEntry.FactureNocouvert = 0;
      } else {
          // If total is greater than plafondFacture, set FactureCouvert to plafondFacture and calculate FactureNocouvert
          existingEntry.FactureCouvert = plafondFacture;
          existingEntry.FactureNocouvert = existingEntry.total - plafondFacture;
      }

      // Calculate PartMutuelle based on FactureCouvert and ticket
      existingEntry.PartMutuelle =0;

      // Calculate PartPatient based on FactureCouvert and ticket
      existingEntry.PartPatient =0;
  } else {
      // If the service is not in the Map, add a new entry
      const factureCouvert = partTotal <= plafondFacture ? partTotal : plafondFacture;
      const factureNocouvert = partTotal <= plafondFacture ? 0 : partTotal - plafondFacture;

      // Calculate PartMutuelle and PartPatient based on factureCouvert and ticket
      const partMutuelle = 0;
      const partPatient = 0;

      groupedByService.set(service, {
          total: partTotal,
          quantity: quantity,
          FactureCouvert: factureCouvert,
          FactureNocouvert: factureNocouvert,
          PartMutuelle: partMutuelle,
          PartPatient: partPatient
      });
  }
});

// Calculate the total, FactureCouvert, FactureNocouvert, PartMutuelle, and PartPatient for all services
let totalAllServices = 0;
let factureCouvertAllServices = 0;
let factureNocouvertAllServices = 0;
let partMutuelleAllServices = 0;
let partPatientAllServices = 0;

groupedByService.forEach(({ total, FactureCouvert, FactureNocouvert, PartMutuelle, PartPatient }) => {
  totalAllServices += total;
  factureCouvertAllServices += FactureCouvert;
  factureNocouvertAllServices += FactureNocouvert;
  partMutuelleAllServices += PartMutuelle;
  partPatientAllServices += PartPatient;
});



   setisfactureTotal(totalAllServices)
   setisfactureCouvert(factureCouvertAllServices)
   setisfactureNoCouvert(factureNocouvertAllServices)
   setisPartMituelle(partMutuelleAllServices)
   setisPartPatient(partPatientAllServices)

  }

      };
  
useEffect(() => {
  AnalyseFactureToPrint(isListToprint)
    },[AnalyseFactureToPrint])



      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
     
      return (
        <>
        
        <form className="form-edit" onSubmit={onFormSubmit}>
       <div>
       <span ref={(el) => (componentToPrint.current = el as null )}> 
    <Row className="p-3">
    <Form.Group  >
              <br/>
              <Form.Label ><u><strong>Hopital ********************************</strong></u></Form.Label>
              <br/>
             <Form.Label ><strong>Raison sociale</strong>: {isgetCurrantNewVisitePushed.hospitalName} <strong>TEL </strong>: {77490341}  </Form.Label>
             <Form.Label ><strong>NIF</strong>:{'4000018630'} <strong>RC</strong>:{'77230'} <strong>BP</strong>:{'438'}</Form.Label>
             <Form.Label ><strong>Avenue</strong>:{'universite N 27'} <strong>Centre fiscal</strong>:{'DPMC'}</Form.Label>
             <Form.Label ><strong>secteur Activite</strong>:{isgetCurrantNewVisitePushed.typehospital} <strong>Forme juridique</strong>:{'S A'}</Form.Label>
                  <div  className="mb-0">
                    <Form.Check
                      inline
                      label="Avec TVA"
                      name="group1"
                      type={'checkbox'}
                      id={`inline-${'checkboxA'}-1`}
                    />
                    <Form.Check
                      inline
                      label="Sans TVA"
                      defaultChecked
                      name="group1"
                      type={'checkbox'}
                      id={`inline-${'checkboxB'}-2`}
                    />
                  </div>
             <Form.Label ><u><strong>Doctor **********************************</strong></u></Form.Label>
             <br/>
             <Form.Label ><strong>Nom</strong>:{isgetCurrantNewVisitePushed.doctorName} <strong>Specialite</strong>:{isgetCurrantNewVisitePushed.typedoctorName}</Form.Label>
             <br></br>
             <Form.Label ><u><strong>Patient **********************************</strong></u></Form.Label>
             <br/>
             <Form.Label ><strong>Enterprise</strong>:{isgetCurrantNewVisitePushed.enterpriseName}
              <strong>Ctg</strong>: {isgetCurrantNewVisitePushed.catégorieSoins}</Form.Label>
          
             <div key={`inline-${'checkbox'}`} className="mb-0">
                    <Form.Check
                      inline
                      label="Avec TVA"
                      name="group1"
                      type={'checkbox'}
                      id={`inline-${'checkbox'}-1`}
                    />
                    <Form.Check
                      inline
                      label="Sans TVA"
                      defaultChecked
                      name="group1"
                      type={'checkbox'}
                      id={`inline-${'checkbox'}-2`}
                    />
                  </div>
             <Form.Label ><strong>Adherant Principal</strong>:{isgetCurrantNewVisitePushed.nomPrenomAffilie} <strong>ID</strong>:{isgetCurrantNewVisitePushed.codeAssuree}</Form.Label>
             <br/>
             <Form.Label ><strong>Patient</strong>:{isgetCurrantNewVisitePushed.patientName} <strong>Bon</strong>:{isgetCurrantNewVisitePushed.receiptBill}</Form.Label>
            
            
              <br/>
              <Form.Label><strong>Total de Facture</strong>:{isfactureTotal}</Form.Label>
              <br/>
              <Form.Label><strong>Operateur</strong>: {operaterName} </Form.Label>
              <br/>
              
          
          </Form.Group>   
          <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Intitule</th>
                    <th>Q</th>
                    <th>PU</th>
                    <th>TOTAL</th>
                    <th> p solis</th>
                    <th>P Patient</th>
                    <th>Couvert<strong>%</strong></th>
                  </tr>
                </thead>
                <tbody>
                {isListToprint.length>0 ? (
                      isListToprint.map((i, index) => (
                          
                            <tr key={index+1}>
                            <td>{i.prestation}</td>
                            <td>{i.quantity}</td>
                            <td>{i.pricePrestation}</td>
                            <td>{i.partTotalFacture}</td>
                            <td>{i.partMutuelleFacture}</td>
                            <td>{i.partpatientFacture}</td>
                            <td>{i.ticketServiceFacture}</td>
                            <td>
              
                            </td>
                          </tr>
                        ))
                      ) : ( 
                        <tr>
                          <td colSpan={3}>no data</td>
                        </tr>
                      )}
                </tbody>
              </Table>
              <br/>
              <br/>
            <Form.Label><strong>.... DevelopedBy @hectorally94 ....</strong></Form.Label>
</Row>
      </span>
        
       </div>
          <div>

        <Stack 
        direction="row"
        divider={<Divider orientation="vertical" flexItem />
        }
        spacing={2}>
    
<ReactToPrint
trigger={() => (
  <Button id={"print"} variant="contained" color="primary">
    {" "}{" "}
    {"Print"}{" "}
  </Button>
)}
content={() => componentToPrint.current}
/>  
         
          </Stack>
          </div>
        </form>
                
        </>
      );
    }