// pMembersSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IdataModelPMemberDtos from '../../../Components/ASSURANCES/AdherantPrencipales/Models/IdataModelPMemberDtos';


const initialState:IdataModelPMemberDtos= {
 
    //table enterpriseAssurance   ta1
    idInterprise:0,
    accountInterprise:0,
    enterpriseName: '',
    couvertureDu: '',
    couvertureAau: '',
    resilierSociete:'',
///table PMember pa
    idPmember:0,
    codeAssuree: '',
    nomPrenomAffilie: '',
    cotisationExact: '',
    account:0,
    genre: '',
    catégorieSoins: '',
    email: '',
    telephones: '',
    adresseAffilie: '',
    autresInformations: '',

    plafond_ambilatoire: '',
    plafond_hospitalisation: '',
    plafond_pharmacy: '',
    plafond_ORL: '',
    plafond_urugence: '',
    plafond_ophtalmologie: '',
    category_code: '',
    plafond_dentisteries: '',
    resilier: '',
    dataResiliation: '',
    photoMember: '',
    dateNaissance: '',
    createdBy: '',
    dateCreated: '',
tva:'',
etatCivil:'',
   ////table socialInsurance so
    idSociete:0,
    societeName: ''
};
const pMembersSlice = createSlice({
  name: 'pMembersSlice',
  initialState,
  reducers: {                                        
    updatecurrentPmeber: (state, action: PayloadAction<IdataModelPMemberDtos>) => {
        
      Object.assign(state, action.payload);
    },
    clearcurrentPmember: (state) => {
      return initialState;
    },
  },
});

export const { updatecurrentPmeber,clearcurrentPmember} = pMembersSlice.actions;
export default pMembersSlice.reducer;
