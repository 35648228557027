import http from "../../../../AxiosRequest/httpcommon";
import IdataModelTypeBonCommend from "../Models/IdataModelTypeBonCommend";
import IdataModelTypeBonCommendDtos from "../Models/IdataModelTypeBonCommendDtos";
const getAll = (params:any) => {
  return http.get<Array<IdataModelTypeBonCommendDtos>>("/TypeBonsCommendDtos", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelTypeBonCommend>>("/sortedTypeBonsCommend");
};
const create = (socialinsuranceId:any,data: IdataModelTypeBonCommend) => {
  return http.post<IdataModelTypeBonCommend>(`/socialinsurance/${socialinsuranceId}/TypeBonsCommend`, data);
};

const update = (id: any, data: IdataModelTypeBonCommend) => {
  return http.put<any>(`/TypeBonsCommend/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/TypeBonsCommend/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/TypeBonsCommend`);
};

const findByTypetypeBonsName = (typeBonsName: string) => {
  return http.get<Array<IdataModelTypeBonCommendDtos>>(`/TypeBonsCommendDtos?typeBonsName=${typeBonsName}`);
};

const TypeService = {
  getAllTypeWithoutPagination,
  getAll,
  create,
  update,
  remove,
  removeAll,
  findByTypetypeBonsName,
};

export default TypeService;