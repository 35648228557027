import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelHospitalType from "../Models/IdataModeHospitalType";
import IdataModelHospitalCategoryDtos from "../Models/IdataModelHospitalCategoryDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelHospitalCategoryDtos>>("/hospitalCategorySoinsPage", { params });
};

const getAllHospitalNameWithoutPagination = () => {
  return http.get<Array<IdataModelHospitalType>>("/sortedHospital");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelHospitalType>>("/hospitalAccount");
}
const getLastReferenceId=()=>{
  return http.get<Array<IdataModelHospitalType>>("/hospitalReferenceId");
}

const create = (categoryAccessSoisnId:any,socialinsuranceId:any,data: IdataModelHospitalType) => {
  return http.post<IdataModelHospitalType>(`/categorysoins/${categoryAccessSoisnId}/${socialinsuranceId}/hospital`, data);
};

const update = (id:any, data: IdataModelHospitalType) => {
  return http.put<any>(`/hospital/${id}`, data);
};
const updateAll = (id: any, categorysoins_id:any, data: IdataModelHospitalType) => {
  return http.put<any>(`/hospital/${id}/${categorysoins_id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/hospital/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/hospital`);
};

const findByhospitalName = (hospitalName: string) => {
  return http.get<Array<IdataModelHospitalType>>(`/hospital?hospitalName=${hospitalName}`);
};

const HospitalTypeService = {
  getAllHospitalNameWithoutPagination,
  getLastReferenceId,
  getAll,
  getLastAccountType,
  create,
  update,
  updateAll,
  remove,
  removeAll,
  findByhospitalName,
};

export default HospitalTypeService;
