import { Row, Table, Stack, Button, Form, InputGroup } from "react-bootstrap"
import { ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { lAdhesion } from "../Interfaces/lAdhesion";
import IdataModelAdhesionDtos from "../Models/IdataModelAdhesionDtos";
import ServiceAdhesion from "../Services/ServiceAdhesion";


export const TableAdhesion:React.FC<lAdhesion>=props=>{
    

  const [Adhesion, setAdhesion] = useState<Array<IdataModelAdhesionDtos>>([]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchAdhesion, setSearchAdhesion] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  
  const getRequestParams = (id:any,searchenterpriseName:string, thepage: number, thepageSize: number) => {
    let params = {
      id:id,
      enterpriseName:searchenterpriseName,
      page: thepage,
      size:thepageSize
    };
  
    if (id) {
      params["id"] = id;
    }
    if (searchenterpriseName) {
      params["enterpriseName"] = searchenterpriseName;
    }
  
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearchAdhesion = (e: ChangeEvent<HTMLInputElement>) => {
    const searchAdhesion = e.target.value;
    setSearchAdhesion(searchAdhesion);
  };
  
  const findByEnterpriseName = () => {
    ServiceAdhesion.findByEnterpriseName(52,searchAdhesion)
      .then((response: any) => {
        const {Adhesions } = response.data;
        setAdhesion(Adhesions);
        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const retrieveAdhesion = () => {
    const params = getRequestParams(52,searchAdhesion, page, pageSize);
  
    ServiceAdhesion.getAll(params)
  
      .then((response: any) => {
  
        const {Adhesions , totalPages} = response.data;
  
        setAdhesion(Adhesions);
        setCount(totalPages);
  
        console.log(response.data);
        console.log(params);
  
      })
  
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    retrieveAdhesion();
  },[page, pageSize]);
  
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const refreshList = () => {
    retrieveAdhesion();
    setCurrentIndex(-1);
  };
  
  const setActiveAdhesion = (dataAdhesion: IdataModelAdhesionDtos, index: number) => {
    setCurrentIndex(index); 
     props.onEdit(dataAdhesion);
     props.onHide();
    console.log(dataAdhesion);
    console.log(index);
  };
  
  const OnDeleteAdhesion = (id:any) => {
  
    ServiceAdhesion.remove(id)
      .then((response: any) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
    return(
          <>
          <Row className="mb-3">
        
        <Stack  direction="horizontal" gap={2}>
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <InputGroup>
            <Form.Control size="sm" 
                    placeholder="Filtre le type docteur..."
                    value={searchAdhesion}
                     onChange={onChangeSearchAdhesion} 
                    />
            </InputGroup>
        </Form.Group>
              
                  <Button variant="outline-dark btn-sm"
                   onClick={findByEnterpriseName}>Cherchez</Button>

            {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
           </Stack>   
         
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>id</th>
              <th>Entreprise</th>
              <th>prix</th>
            </tr>
          </thead>
          <tbody>
          {Adhesion.length>0 ? (
            Adhesion.map((i, index) => (
                
                  <tr key={i.idAdhesion }>
                  <td>{i.idAdhesion}</td>
                  <td>{i.enterpriseName}</td>
                  <td>{i.priceAdhesion}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setActiveAdhesion(i, index)}>Edit</Button>
  
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteAdhesion(i.idAdhesion)}>Delete</Button>
                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no data</td>
              </tr>
            )}
  
          </tbody>
        </Table>
        </Row>
  
          </>
      );
  }