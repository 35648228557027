import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import IdataModeConsultationDtos from "../../../Doctor/Names/Models/IdataModelConsultationDtos";
import { Idoctor } from "./Interfaces/Idoctor";
import DoctorService from "../../../Doctor/Names/Services/NamDoctorServices";
import IdataModelPMemberDtos from "../../../ASSURANCES/AdherantPrencipales/Models/IdataModelPMemberDtos";
import IdataModelSmemberDtos from "../../../ASSURANCES/Beneficieres/Models/IdataModelSmemberDtos";
import IdataModelHospitalType from "../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import { Home } from "../../ModalConsultation/Home/Home";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/store";
import { FaSearchengin, FaStethoscope, FaUserPlus } from "react-icons/fa";


const initialNamDoctorDtos = {
  accountNameDoctor:'',
   accountTypeDoctor:'',
   doctorName:'',
   
   prescriptPar:'',
   doctorNameId:'',
   doctorTypeId:'',
   hospitalId:'',
   typehospital:'',
   hospitalAccount:'',
   referenceId:'',
   categorySoinsId:'',
   categorySoinsName:'',
   hospitalName:'',
   priceConsultation:'',
   typeName:''
    };

    const initialTypeStatePmemberDtos = {
   
      //table enterpriseAssurance   ta1
      idInterprise:0,
      accountInterprise:0,
      enterpriseName: '',
      couvertureDu: '',
      couvertureAau: '',
      resilierSociete:'',
    ///table PMember pa
      idPmember:0,
      codeAssuree: '',
      nomPrenomAffilie: '',
      cotisationExact: '',
      account:0,
      genre: '',
      catégorieSoins: '',
      email: '',
      telephones: '',
      adresseAffilie: '',
      autresInformations: '',
    
      plafond_ambilatoire: '',
      plafond_hospitalisation: '',
      plafond_pharmacy: '',
      plafond_ORL: '',
      plafond_urugence: '',
      plafond_ophtalmologie: '',
      category_code: '',
      plafond_dentisteries: '',
      resilier: '',
      dataResiliation: '',
      photoMember: '',
      dateNaissance: '',
      createdBy: '',
      dateCreated: '',
      tva:'',
      etatCivil:'',
     ////table socialInsurance so
      idSociete:0,
      societeName: ''
     
    };
    const initialTypeStateSmemberDtos = {
       
      ////table socialInsurance so
      idSociete:0,
       SocieteName:'',
    
      //table enterpriseAssurance   ta1
       idInterprise:0,
       accountInterprise:0,
       enterpriseName:'',
     resilierSociete:'',
      ///table PMember pa
       idPmember:0,
       codeAssuree:'',
       nomPrenomAffilie:'',
       cotisationExact:'',
       account:0,
       Genre:'',
       catégorieSoins:'',
       email:'',
       Telephones:'',
       AdresseAffilie:'',
       AutresInformations:'',
    
       plafond_ambilatoire:'',
       plafond_hospitalisation:'',
       plafond_pharmacy:'',
       plafond_ORL:'',
       plafond_urugence:'',
       plafond_ophtalmologie:'',
       category_code:'',
       plafond_dentisteries:'',
       resilier:'',
       dataResiliation:'',
       PhotoMember:'',
       dateNaissance:'',
       createdBy:'',
       dateCreated:'',
   etatCivil:'',
      //table SMember ma
       id:0,
       codeAyantDroit:'',
       accountSmember:0,
       ayantDroit:'',
       dateNaissanceSmember:'',
       gender:'',
       bloodGroup:'',
       resilierSMember:'',
       dateResiliationSMember:'',
       PhotoMemberSmember:'',
       createdBySmember:'',
       dateCreatedSmember:'',
       epouse:'',
    enfantplus:''
    
      
    };   
    const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];
    let HospitalName='';
    let MonStructure:string='';
   
    interface IDoctor{
    
      onSearchDoctor: (idataModelTypeDoctor: IdataModeConsultationDtos) => void;
      onClick: () => void;
  }
export const Doctor:React.FC<IDoctor>= ({ onClick })=>{
      

  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  
  
const [DoctorName, setDoctorName] = useState<Array<IdataModeConsultationDtos>>([]);
const [isHospitalDropDown,setIsHospitalDropDown]=useState(true);

const [currentIndex, setCurrentIndex] = useState<number>(-1);
const [PushCarrantDoctorName, setPushCarrantDoctorName] = useState<IdataModeConsultationDtos>(initialNamDoctorDtos);

const [searchDoctor, setSearchDoctor] = useState<string>("");
const [modalShow, setModalShow] = useState(false);
const [hospital, setHospital] = useState(defaultinitiaHospitalType);

const [CurrentPushedPrincipalMember, setCurrentPushedPrincipalMember] = useState<IdataModelPMemberDtos>(initialTypeStatePmemberDtos);

const onCurrantPcpl=(CurrantAhderantPrincipal:IdataModelPMemberDtos)=>{
  setCurrentPushedPrincipalMember(CurrantAhderantPrincipal)
}
const [CurrentPushedBeneficiere, setCurrentPushedBeneficiere] = useState<IdataModelSmemberDtos>(initialTypeStateSmemberDtos);

const OnCurrentAyantDroit = (currantData: IdataModelSmemberDtos) => {
  setCurrentPushedBeneficiere(currantData);
};

const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];

const getRequestParams = (searchdoctorName:string, thepage: number, thepageSize: number) => {
  let params = {
    doctorName:searchdoctorName,
    page: thepage,
    size:thepageSize
  };

  if (searchdoctorName) {
    params["doctorName"] = searchdoctorName;
  }
  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};
const getRequestParamsHospitalName = (searchdoctorName:string,hospitalName:string, thepage: number, thepageSize: number) => {
  let params = {
    doctorName:searchdoctorName,
    hospitalName:hospitalName,
    page: thepage,
    size:thepageSize
  };

  if (searchdoctorName) {
    params["doctorName"] = searchdoctorName;
  }
  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};
const handleChangeTypeHospital = (e: { target: {value: any; }; }) => {
  HospitalName=e.target.value;
    }
  const trigerHospital=()=>{ 
    if(StructuresData.length===1){
      let concatenatedString: string = StructuresData.join(", ");
      MonStructure=concatenatedString;
     }else{
     
        HospitalTypeService.getAllHospitalNameWithoutPagination()
          .then((response: any) => {
            setHospital(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      
     }
    }
const onChangeSearchDoctor = (e: ChangeEvent<HTMLInputElement>) => {
  const searchDoctor = e.target.value;
  setSearchDoctor(searchDoctor);
};

const findByDoctorAndHospitalName = () => {

  if(StructuresData.length===1){
    
    let concatenatedString: string = StructuresData.join(", ");
    MonStructure=concatenatedString
    setIsHospitalDropDown(true)
    DoctorService.findByDoctorAndHospitalName(searchDoctor,MonStructure)
    .then((response: any) => {
      const {consultationDoctor } = response.data;
      setDoctorName(consultationDoctor);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
   }else{  
    setIsHospitalDropDown(false)

  DoctorService.findByDoctorAndHospitalName(searchDoctor,HospitalName)
    .then((response: any) => {
      const {consultationDoctor } = response.data;
      setDoctorName(consultationDoctor);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
}
const retrieveDoctor = useCallback(() => {
  if (StructuresData.length === 1) {
    let concatenatedString: string = StructuresData.join(", ");
    MonStructure = concatenatedString;

    const params = getRequestParamsHospitalName(searchDoctor, MonStructure, page, pageSize);
    DoctorService.getAllByStructure(params)
      .then((response: any) => {
        const { consultationDoctor, totalPages } = response.data;
        setDoctorName(consultationDoctor);
        setCount(totalPages);
        // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  } else {
    const params = getRequestParams(searchDoctor, page, pageSize);
    DoctorService.getAll(params)
      .then((response: any) => {
        const { consultationDoctor, totalPages } = response.data;
        setDoctorName(consultationDoctor);
        setCount(totalPages);
        // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }
}, [StructuresData, searchDoctor, page, pageSize, setDoctorName, setCount]);

const trigerDropDownState = useCallback(() => {
  if (StructuresData.length === 1) {
    let concatenatedString: string = StructuresData.join(", ");
    MonStructure = concatenatedString;
    setIsHospitalDropDown(true);
  } else {
    setIsHospitalDropDown(false);
  }
}, [StructuresData, setIsHospitalDropDown]);
useEffect(() => {
  trigerHospital();
  trigerDropDownState();
},[trigerDropDownState, trigerHospital]);

useEffect(() => {
  retrieveDoctor();
},[retrieveDoctor]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };



const setPushDoctor = (dataDoctor: IdataModeConsultationDtos, index: number) => {
   setCurrentIndex(index); 

   setPushCarrantDoctorName(dataDoctor);
   setModalShow(true);
};


  return(
        <>
        <Row className="mb-3">
        <div className="d-flex flex-column align-items-start">

        <Stack  direction="horizontal" gap={2}>
        <Form.Control
                placeholder="Filter doctors..."
                value={searchDoctor}
                onChange={onChangeSearchDoctor}
                style={{ width: '300px' }} // Adjust the width as needed
              />

                <Button variant="outline-dark btn-sm"
                 onClick={findByDoctorAndHospitalName}>
                   <FaSearchengin className="me-2" size={20} />
                   Cherchez</Button>
                 {!isHospitalDropDown?(
                  <div></div>
                 ):( 
                  <Button variant="outline-dark btn-sm" onClick={onClick}>
                  <FaUserPlus className="me-2" size={20} /> Ajouter un médecin
                </Button>
                 )}
                
         
         
         </Stack>
         <Stack  direction="horizontal" gap={2}>
         {"Page:"}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />   
                 {!isHospitalDropDown?(
                  <div></div>        

         ):(
          
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">
<Form.Select className="form-control" 
 onChange={handleChangeTypeHospital}>
   <option value="0">{MonStructure}</option>
</Form.Select>
</Form.Group>
         )}
         
         </Stack>
      
        </div>

        <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>id</th>
            <th>Docteur</th>
            <th>Specialité</th>
            <th>Prix </th>
            <th>Structure</th>
            <th>Code </th>
            <th>Consulte</th>
          </tr>
        </thead>
        <tbody>
        {DoctorName.length>0 ? (
          DoctorName.map((i, index) => (
                <tr key={i.doctorNameId }>
                <td>{i.doctorNameId}</td>
                <td>{i.doctorName}</td>
                <td>{i.typeName}</td>
                <td>{i.priceConsultation}</td>
                <td>{i.hospitalName}</td>
                <td>{i.accountNameDoctor}</td>
                <td>
                <Stack direction="horizontal" gap={0}>
               
                <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => setPushDoctor(i, index)}>
  <FaStethoscope className="me-2" size={20} />
</Button>
                </Stack>
                </td>
              </tr>
            ))
          ) : ( 
            <tr>
              <td colSpan={3}>no Type Doctors</td>
            </tr>
          )}

        </tbody>
      </Table>
      <Home
          show={modalShow}
          onHide={() => setModalShow(false)}
          currantdoctor={PushCarrantDoctorName}
             />

          </Row>

        </>
    );
}
