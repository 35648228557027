import http from "../../../../AxiosRequest/httpcommon";
import Iinsurance from "../Models/Iinsurance";

const getAll = (params:any) => {
  return http.get<Array<Iinsurance>>("/Socialinsurance", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<Iinsurance>>("/sortedSocialinsurance");
};
const getLastAccountType=()=>{
  return http.get<Array<Iinsurance>>("/socialinsuranceAccount");
}

const create = (data: Iinsurance) => {
  return http.post<Iinsurance>("/socialinsurance", data);
};

const update = (id: any, data: Iinsurance) => {
  return http.put<any>(`/socialinsurance/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/socialinsurance/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/Socialinsurance`);
};

const findByTypeinsuranceName = (insuranceName: string) => {
  return http.get<Array<Iinsurance>>(`/Socialinsurance?insuranceName=${insuranceName}`);
};

const InsuranceTypeService = {
  getAllTypeWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByTypeinsuranceName,
};

export default InsuranceTypeService;