import http from "../../../../AxiosRequest/httpcommon";
import IdataModelDetailsType from "../Models/IdataModelDetailsType";
import IdataModeDetailsTypeDtos from "../Models/IdataModeDetailsTypeDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModeDetailsTypeDtos>>("/AllSoinsDetailsDtosPage", { params });
};

const getAllSoinsDetailsNameWithoutPagination = () => {
  return http.get<Array<IdataModelDetailsType>>("/sortedSoinsDetails");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelDetailsType>>("/eSoinsCodeDetails");
}

const create = (soins_id:any,data: IdataModelDetailsType) => {
  return http.post<IdataModelDetailsType>(`/CreateSoinsDetails/${soins_id}/SoinsDetails`, data);
};

const update = (id:any, data: IdataModelDetailsType) => {
  return http.put<any>(`/SoinsDetails/${id}`, data);
};
const updateAll = (id: any, typeSoinsId:any, data: IdataModelDetailsType) => {
  return http.put<any>(`/SoinsDetails/${id}/${typeSoinsId}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/deleteSoinsDetails/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/SoinsDetails`);
};

const findBySoinsDetailsName = (intitule: string) => {
  return http.get<Array<IdataModelDetailsType>>(`/AllSoinsDetailsDtosPage?intitule=${intitule}`);
};

const DetailsSoinsTypeService = {
  getAllSoinsDetailsNameWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  updateAll,
  remove,
  removeAll,
  findBySoinsDetailsName,
};

export default DetailsSoinsTypeService;
