import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelMode from "../Models/IdataModelMode";

const getAll = (params:any) => {
  return http.get<Array<IdataModelMode>>("/Mode", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<IdataModelMode>>("/sortedMode");
};

const create = (socialinsuranceId:any,data: IdataModelMode) => {
  return http.post<IdataModelMode>(`/socialinsurance/${socialinsuranceId}/Mode`,data);
};

const update = (id: any, data: IdataModelMode) => {
  return http.put<any>(`/Mode/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/Mode/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/Mode`);
};

const findByTypeName = (typeName: string) => {
  return http.get<Array<IdataModelMode>>(`/Mode?typeName=${typeName}`);
};

const ModeService = {
  getAllWithoutPagination,
  getAll,
  create,
  update,
  remove,
  removeAll,
  findByTypeName,
};

export default ModeService;