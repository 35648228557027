import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { IdoctorConsultation } from "../../Interfaces/IdoctorConsultation";
import { RootState } from "../../../../../Redux/Store/store";
import { useSelector } from "react-redux";
import IdataModeConsultationDtos from "../../Models/IdataModelConsultationDtos";
import DoctorService from "../../Services/NamDoctorServices";
import { FaEdit ,FaSearchengin} from "react-icons/fa";


let MonStructure:string='';
export const UserConsultationDoctor:React.FC<IdoctorConsultation>=props=>{
      
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  
const [DoctorName, setDoctorName] = useState<Array<IdataModeConsultationDtos>>([]);

const [currentIndex, setCurrentIndex] = useState<number>(-1);

const [searchDoctor, setSearchDoctor] = useState<string>("");

const [page, setPage] = useState(1);
const [count, setCount] = useState(0);
const [pageSize, setPageSize] = useState(3);
const pageSizes = [3, 6, 9,12];

const getRequestParamsHospitalName = (searchdoctorName:string,hospitalName:string, thepage: number, thepageSize: number) => {
  let params = {
    doctorName:searchdoctorName,
    hospitalName:hospitalName,
    page: thepage,
    size:thepageSize
  };

  if (searchdoctorName) {
    params["doctorName"] = searchdoctorName;
  }
  if (hospitalName) {
    params["hospitalName"] = hospitalName;
  }

  if (thepage) {
    params["page"] = thepage - 1;
  }

  if (thepageSize) {
    params["size"] = thepageSize;
  }

  return params;
};

const onChangeSearchDoctor = (e: ChangeEvent<HTMLInputElement>) => {
  const searchDoctor = e.target.value;
  setSearchDoctor(searchDoctor);
};

const findByDoctor = () => {
  DoctorService.findByDoctor(searchDoctor)
    .then((response: any) => {
      const {consultationDoctor } = response.data;
      setDoctorName(consultationDoctor);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
};
useEffect(() => {
  retrieveDoctor();
},[page , pageSize]);

const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setPage(1);
  };

const refreshList = () => {
  retrieveDoctor();
  setCurrentIndex(-1);
};

const setActiveDoctor = (dataDoctor: IdataModeConsultationDtos, index: number) => {
   setCurrentIndex(index); 
   props.OnEditNameDoctor(dataDoctor);
   props.onHide();
   console.log(dataDoctor);
   console.log(index);
};

const OnDeleteDoctor = (id:any) => {

  DoctorService.remove(id)
    .then((response: any) => {
      console.log(response.data);
      refreshList();
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const findByDoctorAndHospitalName = () => {

  if(StructuresData.length===1){
    
    let concatenatedString: string = StructuresData.join(", ");
    MonStructure=concatenatedString
    DoctorService.findByDoctorAndHospitalName(searchDoctor,MonStructure)
    .then((response: any) => {
      const {consultationDoctor } = response.data;
      setDoctorName(consultationDoctor);
      setCurrentIndex(-1);
      console.log(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
   }
}
const retrieveDoctor = useCallback(() => {
  if (StructuresData.length === 1) {
    let concatenatedString: string = StructuresData.join(", ");
    MonStructure = concatenatedString;

    const params = getRequestParamsHospitalName(searchDoctor, MonStructure, page, pageSize);
    DoctorService.getAllByStructure(params)
      .then((response: any) => {
        
      const {consultationDoctor , totalPages} = response.data;

      setDoctorName(consultationDoctor);
      setCount(totalPages);

        setCount(totalPages);
        // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  } 
}, [StructuresData, searchDoctor, page, pageSize, setDoctorName, setCount]);

  return(
        <>
        <Row className="mb-3">
      
      <Stack  direction="horizontal" gap={2}>
                
                   <Form.Control
                placeholder="Filter doctors..."
                value={searchDoctor}
                onChange={onChangeSearchDoctor}
                style={{ width: '300px' }} // Adjust the width as needed
              />
                <Button variant="outline-dark btn-sm"
                 onClick={findByDoctor}>
                  <FaSearchengin className="me-2" size={20}/>
                  Cherchez</Button>
         </Stack>   
        <div className="mt-3">
          
        {"Items per Page: "}
            <select onChange={handlePageSizeChange} value={pageSize}>
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          <Pagination
            className="my-3"
            count={count}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
        <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>id</th>
            <th>Docteur</th>
            <th>Specialité</th>
            <th>Prix </th>
            <th>Hopital</th>
            <th>Compte </th>
           
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {DoctorName.length>0 ? (
          DoctorName.map((i, index) => (
                <tr key={i.doctorNameId }>
                <td>{i.doctorNameId}</td>
                <td>{i.doctorName}</td>
                <td>{i.typeName}</td>
                <td>{i.priceConsultation}</td>
                <td>{i.hospitalName}</td>
                <td>{i.accountNameDoctor}</td>
               
                <td>
                <Stack direction="horizontal" gap={0}>
                <Button className="me-4 btn btn-success btn-sm btn-block"
                onClick={() => setActiveDoctor(i, index)}>
                
                  <FaEdit className="me-2" size={20}/>
                  Modifier</Button>

                  
                </Stack>
                </td>
              </tr>
            ))
          ) : ( 
            <tr>
              <td colSpan={3}>no Type Doctors</td>
            </tr>
          )}

        </tbody>
      </Table>
      </Row>

        </>
    );
}