import http from "../../../../AxiosRequest/httpcommon";
import IdataModelSmember from "../Models/IdataModelSmember";
import IdataModelSmemberDtos from "../Models/IdataModelSmemberDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelSmemberDtos>>("/beneficiaries", { params });
};

const AccountEnfants=(codeAyantDroit:any,socialinsurance_id:any)=>{
  return http.get<any>(`/countByCodeAyantDroit/${codeAyantDroit}/${socialinsurance_id}`);
}
const getAllWithoutPagination = () => {
  return http.get<Array<IdataModelSmember>>("/sortedbeneficiaries");
};
const getLastAccountType= (params:any)=>{
  return http.get<Array<IdataModelSmember>>("/beneficiariesAccount", { params });
}

const create = (enterprisesAssurance_id:any,enterprisesAssuranceId:any,pMemberId:any,data: IdataModelSmember) => {
  return http.post<IdataModelSmember>(`/${enterprisesAssurance_id}/${enterprisesAssuranceId}/${pMemberId}/beneficiaries`, data);
};

const update = (id: any, data: IdataModelSmember) => {
  return http.put<any>(`/beneficiaries/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/beneficiaries/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/beneficiaries`);
};

const findByAyantDroit = (idSociete:any,idInterprise:any,idPmember:any,ayantDroit: string) => {
  return http.get<Array<IdataModelSmemberDtos>>(`/beneficiaries?idSociete=${idSociete}&idInterprise=${idInterprise}&idPmember=${idPmember}&ayantDroit=${ayantDroit}`);
};

const ServiceSmember = {
  getAllWithoutPagination,
  getAll,
  AccountEnfants,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByAyantDroit,
};

export default ServiceSmember;