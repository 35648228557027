import http from "../../../../AxiosRequest/httpcommon";
import NewVisiteDtos from "../Models/Dtos/NewVisiteDtos";
import NewVisite from "../Models/NewVisite";

const getAllHemaNewVisitePatient = (params:any) => {
  return http.get<Array<NewVisiteDtos>>("/HemaNewVisitePatient", { params });
};

const getAllHemaNewVisitePatientByStructure = (params:any) => {
  return http.get<Array<NewVisiteDtos>>("/HemaNewVisitePatientByStructure", { params });
};

const getAllReceiptBillWithoutPagination = () => {
  return http.get<Array<NewVisite>>("/sortedHemaNewVisitePatient");
};

const findBySocialinsuranceIdAndHospitalIdAndReceiptBill=
( socialinsurance_id:any, hospital_id: any, receiptBill: any)=>{
  return http.get<Array<NewVisiteDtos>>(`/HemaNewVisitePatient/${socialinsurance_id}/${hospital_id}/${receiptBill}`);
}
const findBySocialinsuranceIdAndPatientCode=(socialinsurance_id:any, patientCode: any)=>{
  return http.get<Array<NewVisite>>(`/HemaNewVisitePatientPatientCode/${socialinsurance_id}/${patientCode}`);
}

const createNewVisite = (socialinsuranceId:any,
  nameDoctors_id:any,
  typeDoctors_id:any,
  couvertureConfig_id:any,
  hospital_id:any,
  typeDepartment_id:any,
  enterprisesAssurance_id:any,
  pMember_id:any,
  typeBons_id:any,
  typeBonsCommend_id:any,
  plafondTypeVisite_id:any,
  data: NewVisite) => {

  return http.post<NewVisite>(`/socialinsurance/${socialinsuranceId}/${nameDoctors_id}/${typeDoctors_id}/${couvertureConfig_id}/${hospital_id}/${typeDepartment_id}/${enterprisesAssurance_id}/${pMember_id}/${typeBons_id}/${typeBonsCommend_id}/${plafondTypeVisite_id}/HemaNewVisitePatient`, data);
};

const update = (id: any, data: NewVisite) => {
  return http.put<any>(`/HemaNewVisitePatient/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/HemaNewVisitePatient/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/HemaNewVisitePatient`);
};

const findByReceiptBill = (receiptBill: string) => {
  return http.get<Array<NewVisiteDtos>>(`/HemaNewVisitePatient?receiptBill=${receiptBill}`);
};

const findByReceiptBillPharmacy = (receiptBill: string,socialinsurance_id:any) => {
  return http.get<NewVisiteDtos>(`/FindBOnByInsurence/${receiptBill}/${socialinsurance_id}`);
};


const ServicesNewVisite = {
  getAllReceiptBillWithoutPagination,
  getAllHemaNewVisitePatient,
  getAllHemaNewVisitePatientByStructure,
  findBySocialinsuranceIdAndHospitalIdAndReceiptBill,
  findBySocialinsuranceIdAndPatientCode,
  createNewVisite,
  update,
  remove,
  removeAll,
  findByReceiptBill,
  findByReceiptBillPharmacy,
};

export default ServicesNewVisite;