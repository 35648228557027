import http from "../../../AxiosRequest/httpcommon";
import IdataModelTypeDepertment from "../Models/IdataModelTypeDepertment";
const getAll = (params:any) => {
  return http.get<Array<IdataModelTypeDepertment>>("/TypeDepartment", { params });
};

const getAllTypeWithoutPagination = () => {
  return http.get<Array<IdataModelTypeDepertment>>("/sortedTypeDepartment");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelTypeDepertment>>("/typeDepartmentAccount");
}

const create = (data: IdataModelTypeDepertment) => {
  return http.post<IdataModelTypeDepertment>("/TypeDepartment", data);
};

const update = (id: any, data: IdataModelTypeDepertment) => {
  return http.put<any>(`/TypeDepartment/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/TypeDepartment/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/TypeDepartment`);
};

const findByTypeTypeName = (typeName: string) => {
  return http.get<Array<IdataModelTypeDepertment>>(`/TypeDepartment?typeName=${typeName}`);
};

const ServiceDepartment = {
  getAllTypeWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByTypeTypeName,
};

export default ServiceDepartment;