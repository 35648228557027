import { ChangeEvent, useEffect, useState } from 'react';
import { InputGroup, Form, Row, Card, Accordion } from "react-bootstrap";
import IdataModeEnterprise from '../Models/IdataModeEnterprise';
import IdataModelFrequance from '../../Payments/Frequance/Models/IdataModelFrequance';
import IdataModelMode from '../../Payments/Mode/Models/IdataModelMode';
import EnterpriseService from '../Services/EnterpriseService';
import ModeService from '../../Payments/Mode/Services/ModeService';
import FrequenceService from '../../Payments/Frequance/Services/FrequenceService';

const defaultFrequance: Array<IdataModelFrequance> = [];
const defaultMode: Array<IdataModelMode> = [];

let accountIncrement=0
const initialTypeState = {
  id:null,
  account: 0,
  enterpriseName: '',
  responsable: '',
  email: '',
  phoneNumber: '',
  couvertureDu: '',
  couvertureAau: '',
  address: '',
  autreInformation:'',
  resilier: '',
  dataResiliation: '',
  dateCreatedBy: '',
  createdBy:'ghostzila',
  firstCallForPayment: '',
  secondCallForPayment: '',
  thirdCallForPayment: '',
  modePayment: '',
  frequancePayment: '',
 ////// configuration of assurance start
  primeIndividuelle:'',
  primeTotal:'',
 //les differents plafonds
  soinsAmbilatoire:'',
  hospitalisation:'',
  ophtalmologie:'',
  dentisterie:'',
  fraisFuneraires:''
};

export const AddEnterprise:React.FC=()=> {

    const [mode, setmode] = useState(defaultMode);
    const [frequence, setfrequence] = useState(defaultFrequance);
    const [isResilier,setResilier]=useState('');


    
const getLastAccountType =()=>{
  EnterpriseService .getLastAccountType()
    .then((response: any) => {
        accountIncrement=response.data.account
        console.log(accountIncrement);
  })
}
const getMode =()=>{
    ModeService .getAllWithoutPagination()
      .then((response: any) => {
        setmode(response.data);
    
    })
  }
  const geFrequance =()=>{
    FrequenceService .getAllTypeWithoutPagination()
      .then((response: any) => {
         setfrequence(response.data)
    })
  }

useEffect(() => {
  getLastAccountType();
  getMode();
  geFrequance();
 
});

          const radioHandlerStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
            setResilier(event.target.value);
          };

const [isInput,setInput]=useState<IdataModeEnterprise>(initialTypeState);

const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setInput({ ...isInput, [e.target.name]: e.target.value });
  }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const saveTypeDoctors = () => {  

        if(accountIncrement===undefined){
          accountIncrement=10000
        }
        let data = {
          account: accountIncrement+1,
          enterpriseName: isInput.enterpriseName,
          responsable: isInput.responsable,
          email: isInput.email,
          phoneNumber: isInput.phoneNumber,
          couvertureDu:isInput.couvertureDu,
          couvertureAau: isInput.couvertureAau,
          address: isInput.address,
          autreInformation:isInput.autreInformation,
         
          resilier:isInput.resilier,
          dataResiliation: isInput.dataResiliation,
          dateCreatedBy: isInput.dateCreatedBy,
          createdBy: isInput.createdBy,
          firstCallForPayment: isInput.firstCallForPayment,
          secondCallForPayment: isInput.secondCallForPayment,
          thirdCallForPayment: isInput.thirdCallForPayment,
          modePayment: isInput.modePayment,
          frequancePayment: isInput.frequancePayment,
         
         ////// configuration of assurance start
          primeIndividuelle: isInput.primeIndividuelle,
          primeTotal: isInput.primeTotal,
         //les differents plafonds
          soinsAmbilatoire: isInput.soinsAmbilatoire,
          hospitalisation: isInput.hospitalisation,
          ophtalmologie: isInput.ophtalmologie,
          dentisterie: isInput.dentisterie,
          fraisFuneraires:isInput.fraisFuneraires
        };

        EnterpriseService .create(52,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,    
 account: response.data.account,
 enterpriseName:  response.data.enterpriseName,
 responsable:  response.data.responsable,
 email:  response.data.email,
 phoneNumber:  response.data.phoneNumber,
 couvertureDu:  response.data.couvertureDu,
 couvertureAau:  response.data.couvertureAau,
 address:  response.data.address,
 autreInformation:response.data.autreInformation,
 resilier:  response.data.resilier,
 dataResiliation:  response.data.dataResiliation,
 dateCreatedBy:  response.data.dateCreatedBy,
 createdBy:  response.data.createdBy,

 firstCallForPayment:  response.data.firstCallForPayment,
 secondCallForPayment:  response.data.secondCallForPayment,
 thirdCallForPayment:  response.data.thirdCallForPayment,
 modePayment:  response.data.modePayment,
 frequancePayment:  response.data.frequancePayment,

////// configuration of assurance start
 primeIndividuelle:  response.data.primeIndividuelle,
 primeTotal:  response.data.primeTotal,
//les differents plafonds
 soinsAmbilatoire:  response.data.soinsAmbilatoire,
    hospitalisation:  response.data.hospitalisation,
 ophtalmologie:  response.data.ophtalmologie,
 dentisterie:  response.data.dentisterie,
 fraisFuneraires: response.data.fraisFuneraires


          });
          console.log(response.data);
          console.log(data);
          setInput(initialTypeState)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const resetTypeDoctor = () => {
        setInput(initialTypeState);
      }

     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Accordion  flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Informaton Prencipal #1</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Nom entreprise</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="name" name="enterpriseName" value={isInput.enterpriseName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Responsable</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="text" name="responsable" value={isInput.responsable} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Email</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="email" name="email" value={isInput.email} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>PhoneNumber</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="phoneNumber" name="phoneNumber" value={isInput.phoneNumber} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>couver de</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="Date" name="couvertureDu" value={isInput.couvertureDu} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>couver au</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="couvertureAau" value={isInput.couvertureAau} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>address</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="address" name="address" value={isInput.address} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>autreInformation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="text" name="autreInformation" value={isInput.autreInformation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Mode Payment</Form.Label>
    <Form.Select className="form-control" 
    name="modePayment" value={isInput.modePayment} onChange={handleChange}
     >
       <option value="0">Choose..</option>
      {mode &&
    mode.map((mode) => (
        <option key={mode.id} value={mode.typeName} >{mode.typeName}</option>
    ))}
        
    </Form.Select>
        </Form.Group>
<Form.Group controlId="formBasicEmail" className="col col-sm-3">
    <Form.Label>Frequance Payment</Form.Label>
    <Form.Select className="form-control" 
    name="frequancePayment" value={isInput.frequancePayment} onChange={handleChange}
    >
       <option value="0">Choose..</option>
      {frequence &&
    frequence.map((frequence) => (
        <option key={frequence.id} value={frequence.typeName} >{frequence.typeName}</option>
    ))}
        
    </Form.Select>     
       </Form.Group>
        </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Information Secondaire #2</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
            <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Resilier</Form.Label>
            <Form.Select  size="sm" name="resilier" value={isInput.resilier} onChange={handleChange}>
                <option>Choisir </option>
               <option value="Oui">Oui</option>
             <option value="No">No</option>
    </Form.Select>
        </Form.Group>
        
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>date de Resiliation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="dataResiliation" value={isInput.dataResiliation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Rappel no 1</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="firstCallForPayment" value={isInput.firstCallForPayment} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Rappel no 2</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="secondCallForPayment" value={isInput.secondCallForPayment} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Rappel no 3</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="date" name="thirdCallForPayment" value={isInput.thirdCallForPayment} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Information Sur les Plafonds #2</Accordion.Header>
        <Accordion.Body>
        <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label> prime Individuelle</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="primeIndividuelle" value={isInput.primeIndividuelle} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>prime Total</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="primeTotal" value={isInput.primeTotal} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>soins Ambilatoire</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="soinsAmbilatoire" value={isInput.soinsAmbilatoire} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>hospitalisation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="hospitalisation" value={isInput.hospitalisation} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Ophtalmologie</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="ophtalmologie" value={isInput.ophtalmologie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>dentisterie</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="dentisterie" value={isInput.dentisterie} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>fraisFuneraires</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  type="number" name="fraisFuneraires" value={isInput.fraisFuneraires} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>



         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-3">
            <button  onClick={()=>saveTypeDoctors()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={resetTypeDoctor} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}