
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewFactureSaisieDto from '../../../Components/Espace_Patients/FactureSaisie/models/Dto/NewFactureSaisieDto';




const newFactureMutuelleSlice = createSlice({
  name: 'newFactureMutuelle',
  initialState: [] as NewFactureSaisieDto[], // Set initial state as an empty array
  reducers: {
    updateFactureMutuelle: (state, action: PayloadAction<NewFactureSaisieDto[]>) => {
      // Assuming you want to replace the entire array in the state
      return action.payload;
    },
    clearFactureMutuelle: () => {
      return [];
    },
  },
});

export const { updateFactureMutuelle, clearFactureMutuelle } = newFactureMutuelleSlice.actions;
export default newFactureMutuelleSlice.reducer;
