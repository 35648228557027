import { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { CategoriesAcessSoins } from "../../../Components/ASSURANCES/Categories/CategorieSoinsHopitaux/CategoriesAcessSoins/CategoriesAcessSoins";
import { CategoriesAcessSoinsEdit } from "../../../Components/ASSURANCES/Categories/CategorieSoinsHopitaux/CategoriesAcessSoins/CategoriesAcessSoinsEdit";
import IdataModelCategoriesAcessSoins from "../../../Components/ASSURANCES/Categories/CategorieSoinsHopitaux/Models/IdataModelCategoriesAcessSoins";
import { CategoriesAcessSoinsTable } from "../../../Components/ASSURANCES/Categories/CategorieSoinsHopitaux/Tables/CategoriesAcessSoinsTable";
import { CategoriesType } from "../../../Components/ASSURANCES/Categories/CategoriesAffilies/CategoriesType/CategoriesType";
import { CategoryTypeEdit } from "../../../Components/ASSURANCES/Categories/CategoriesAffilies/CategoriesType/CategoriesTypeEdit";
import IdataModelCategoryType from "../../../Components/ASSURANCES/Categories/CategoriesAffilies/Models/IdataModelCategoryType";
import { CategoryTable } from "../../../Components/ASSURANCES/Categories/CategoriesAffilies/Tables/CategoryTable";
import IdataModelCategoryCode from "../../../Components/ASSURANCES/Categories/CategoriiesCode/Models/IdataModelCategoryCode";
import { CategoriesCode } from "../../../Components/ASSURANCES/Categories/CategoriiesCode/CategoriesType/CategoriesCode";
import { CategoriesCodeEdit } from "../../../Components/ASSURANCES/Categories/CategoriiesCode/CategoriesType/CategoriesCodeEdit";
import { CategoryCodeTable } from "../../../Components/ASSURANCES/Categories/CategoriiesCode/Tables/CategoryCodeTable";

const initialTypeAcessSoinsState = {
    id: null,
    categorySoinsName: '',
    account:0,
    createdBy: '',
    dateCreatedBy: new Date()
  };
  const initialTypeState = {
    id: null,
    categoryName: '',
    account:0,
    createdBy: '',
    dateCreatedBy: new Date()
  };

export const Categories:React.FC=()=>
    {
        const [isSwichLayerCategoryType,setSwichLayerCategoryType]= useState(true);
        const [isSwichLayerCategoryAccessSoins,setSwichLayerCategoryAccessSoins] = useState(true);
        const [isSwichLayerCategoryCode,setSwichLayerCategoryCode] = useState(true);



        const [EditList, setEditList] = useState<IdataModelCategoryType>(initialTypeState);

        const onCurrantTypeCategory=(Category:IdataModelCategoryType)=>{
            setEditList(Category);
        }
        const [EditListCode, setEditListCode] = useState<IdataModelCategoryCode>(initialTypeState);

        const onCurrantTypeCategoryCode=(Code:IdataModelCategoryCode)=>{
            setEditListCode(Code);
        }

        const [EditListAcessSoins, setEditListAcessSoins] = 
        useState<IdataModelCategoriesAcessSoins>(initialTypeAcessSoinsState);

        const onCaarrantCategoryAcessSoins=(CategoryAcessSoins:IdataModelCategoriesAcessSoins)=>{
            setEditListAcessSoins(CategoryAcessSoins)
        }

        return(
            <>
            <Container fluid>
                <Row>
                <Col>  
                 <br />
                <Card>
            <Card.Body>
           {isSwichLayerCategoryCode?
                (
                    <CategoriesCode />
                ):(
      <CategoriesCodeEdit show={isSwichLayerCategoryCode}  onHide={() => setSwichLayerCategoryCode(true)}

            EditInfoCategoryType={EditListCode} /> 
                )
            }
         <CategoryCodeTable
          show={isSwichLayerCategoryCode} 
           onHide={() => setSwichLayerCategoryCode(false)}
         onEditCategory={onCurrantTypeCategoryCode}
          />
            </Card.Body>
              </Card>
              <br />
                </Col>

                <Col> 
                <br />
                <Card>
            <Card.Body>
           {isSwichLayerCategoryType?
                (
                    <CategoriesType />
                ):(
      <CategoryTypeEdit show={isSwichLayerCategoryType}  onHide={() => setSwichLayerCategoryType(true)}

            EditInfoCategoryType={EditList} /> 
                )
            }
         <CategoryTable
          show={isSwichLayerCategoryType} 
           onHide={() => setSwichLayerCategoryType(false)}
         onEditCategory={onCurrantTypeCategory}
          />
            </Card.Body>
              </Card>
              <br />
                </Col>

                <Col> 
                <br />
                <Card>
            <Card.Body>
           {isSwichLayerCategoryAccessSoins?
                (
                    <CategoriesAcessSoins />
                ):(
      <CategoriesAcessSoinsEdit show={isSwichLayerCategoryAccessSoins}  onHide={() => setSwichLayerCategoryAccessSoins(true)}

      EditInfoCategoryAcessSoins={EditListAcessSoins} /> 
                )
  }
         <CategoriesAcessSoinsTable show={isSwichLayerCategoryAccessSoins}  onHide={() => setSwichLayerCategoryAccessSoins(false)}
         onEditCategoriesAcessSoins={onCaarrantCategoryAcessSoins}
          />
            </Card.Body>
        </Card>
        <br />
                </Col>
              
                </Row>
            
            </Container>
            </>
        );
    }