import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Card, Row, Form, InputGroup } from "react-bootstrap";
import ServiceSmember from '../Services/ServiceSmember';
import IdataModelSmemberDtos from '../Models/IdataModelSmemberDtos';
import IdataModelSmember from '../Models/IdataModelSmember';


  interface IEditSMember {
    show: boolean; 
    onHide: () => void; 
    EditInfo: IdataModelSmemberDtos;
  }
  export const EditSmember :React.FC<IEditSMember>=props=> {

    const initialTypeState = {
      id: null,
      codeAyantDroit: '',
      account: 0,
      ayantDroit: '',
      dateNaissance: '',
      gender: '',
      photoMember:'',
      bloodGroup: '',
      resilier: '',
      epouse:'',
      enfantplus:'',
      dateResiliation: '',
      createdBy: '',
      dateCreated: '',
      
    };
    const [isInput,setInput]=useState<IdataModelSmember>(initialTypeState);

    const [isInputDtos,setInputDtos]=useState<IdataModelSmemberDtos>(props.EditInfo);
    const [isResilier,setResilier]=useState<string>('');


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInputDtos({ ...isInputDtos, [name]: value });
    };
    const handleChangeSelected = (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      setInputDtos({ ...isInputDtos, [name]: value });
    };

   const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }
          
   const EditSmembers = (e: { preventDefault: () => void; })=>{
            e.preventDefault();
            let data = {
              codeAyantDroit: isInputDtos.codeAyantDroit ,
              ayantDroit: isInputDtos.ayantDroit ,
              dateNaissance: isInputDtos.dateNaissance ,
              gender: isInputDtos.gender,
              
              bloodGroup: isInputDtos.bloodGroup ,
              resilier:isInputDtos.resilierSMember,
              epouse:isInputDtos.epouse,
              enfantplus:isInputDtos.enfantplus,
              
              dateResiliation: isInputDtos.dateResiliationSMember ,
              createdBy: isInputDtos.createdBy ,
              dateCreated: isInputDtos.dateCreated 
            };
    
            ServiceSmember.update(isInputDtos.id,data)
            .then((response: any) => {
              setInput({
                id: response.data.id,
                codeAyantDroit: response.data.codeAyantDroit ,
                ayantDroit:response.data.ayantDroit,
                dateNaissance:response.data.dateNaissance,
                gender: response.data.gender,
                
                bloodGroup: response.data.bloodGroup ,
                resilier: response.data.resilier ,
                epouse:response.data.epouse,
                enfantplus:response.data.epouse,
                
                dateResiliation: response.data.dateResiliation ,
                createdBy: response.data.createdBy ,
                dateCreated: response.data.dateCreated 
                
              });
              console.log(response.data);
              console.log(data);
              EditCancel();
                  })
            .catch((e: Error) => {
              console.log(e);
            });
            console.log(isInput);
          }

          const EditCancel = () => {
            setInput(initialTypeState);
            props.onHide();
          }
              
          
  useEffect(() => {

    setInputDtos(props.EditInfo)

      },[props.EditInfo])
      
          return(
            <>
          
        < form className="form-edit" onSubmit={onFormSubmit}>
        <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>ayantDroit</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="text" name="ayantDroit" value={isInputDtos.ayantDroit} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>date Naissance</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="date" name="dateNaissance" value={isInputDtos.dateNaissance} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Sex</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="gender" 
            value={isInputDtos.gender} onChange={handleChangeSelected}>
                <option>Choisir</option>
               <option value="Homme">Homme</option>
             <option value="Femme">Femme</option>
    </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Epouse</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="epouse" 
            value={isInputDtos.epouse} onChange={handleChangeSelected}>
                <option>Choisir</option>
                <option value="Yes">yes</option>
             <option value="No">NO</option>
    </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
         <Form.Label>Enfant de plus</Form.Label>
            <Form.Select aria-label=" select Gender" size="sm" name="enfantplus" 
            value={isInputDtos.enfantplus} onChange={handleChangeSelected}>
                <option>Choisir</option>
                <option value="Yes">yes</option>
             <option value="No">NO</option>
    </Form.Select>
        </Form.Group>     
        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>Groupe sang</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="text" name="bloodGroup" value={isInputDtos.bloodGroup} onChange={handleChange} />
            </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicemail" className="col col-sm-3">
         <Form.Label>Resilier</Form.Label>
            <Form.Select  size="sm" name="resilierSMember" value={isInputDtos.resilierSMember} onChange={handleChangeSelected}>
                <option>Choisir </option>
               <option value="Oui">Oui</option>
             <option value="No">No</option>
    </Form.Select>
        </Form.Group>

        <Form.Group controlId="formBasicEmail" className="col col-sm-3">
            <Form.Label>date Resiliation</Form.Label>
            <InputGroup>
                <Form.Control size="sm"  aria-describedby="basic-addon2" type="date" name="dateResiliationSMember" value={isInputDtos.dateResiliationSMember} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
            <Row className="mb-3">
           <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
               <button type="submit" onClick={EditSmembers} className="me-4 btn btn-success btn-sm btn-block">Update</button>
               <button type="reset" onClick={EditCancel} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
           </Form.Group>
         </Row>
         </form>
            </>
        );
  }
