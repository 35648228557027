import http from "../../../../AxiosRequest/httpcommon";
import NewFactureSaisieMedicamentDto from "../models/Dto/NewFactureSaisieMedicamentDto";
import NewFactureSaisieMedicament from "../models/NewFactureSaisieMedicament";

const getAllNewFactureSaise = (params:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>("/FactureSaisiePharmacy", { params });
};
const getAllNewFactureSaiseByStructure = (params:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>("/FactureSaisiePharmacyByStructure", { params });
};

const getDeclarationBetweenDates = (params:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>("/findBySocialinsuranceIdByStructureGroupedListAndDateCreatedSaisieBetween", { params });
};
const getAllNewFactureSaiseByStructureGrouped = (params:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>("/FactureSaisiePharmacyByStructureGrouped", { params });
};

const getAllVisitelWithoutPagination = () => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>("/sortedFactureSaisiePharmacy");
};

const findById=(id:any)=>{
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacy/${id}`);
}

const findByIdIncriment=(IdIncriment:any)=>{
  return http.get<NewFactureSaisieMedicamentDto>(`/gofindByIdIncrimentPharmacy/${IdIncriment}`);
}

const findByIdIncrimentList=(IdIncriment:any)=>{
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/gofindByIdIncrimentPharmacyList/${IdIncriment}`);
}

const findBySocialinsuranceIdAndPatientCode=(socialinsurance_id:any, patientCode: any)=>{

  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacy?socialinsuranceId=${socialinsurance_id}&receiptBill=${patientCode}`);
}
const findBySocialinsuranceIdAndPatientCodeByStructure=(socialinsurance_id:any, patientCode: any,hospitalName:any)=>{

  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyByStructure?socialinsuranceId=${socialinsurance_id}&receiptBill=${patientCode}&hospitalName=${hospitalName}`);
}
const createFactureSaisiePharmacy = (
  socialinsuranceId:any,     
  hemaNewVisitePatient_id:any,
  nameDoctors_id:any,
  typeDoctors_id:any,
  hospital_id:any,
  listeMedicament_id:any,
  data: NewFactureSaisieMedicament) => {

  return http.post<NewFactureSaisieMedicament>(`/socialinsurance/${socialinsuranceId}/${hemaNewVisitePatient_id}/${nameDoctors_id}/${typeDoctors_id}/${hospital_id}/${listeMedicament_id}/FactureSaisiePharmacy`, data);
  
};

const updateFactureSaisiePharmacy = (id: any, data: NewFactureSaisieMedicamentDto) => {
  return http.put<any>(`/FactureSaisiePharmacy/${id}`, data);
};
const updateFactureSaisiePharmacyStatusPayer = (
  id: any,
   statusFacturePayer: string,
   pricePrestation:any,
   ticketService:any,
   ticketServiceFacture:any,
   quantity:any,
   plafondFacture:any,
    partMutuelle:any,
    partpatient:any,
    partTotal:any,

    partMutuelleFacture:any,
    partpatientFacture:any,
    partTotalFacture:any,
  ) => {
  const data = {
    params: {
      statusFacturePayer: statusFacturePayer,
      pricePrestation:pricePrestation,
      ticketService:ticketService,
      ticketServiceFacture:ticketServiceFacture,
      quantity:quantity,
      plafondFacture:plafondFacture,
      partMutuelle:partMutuelle,
    partpatient:partpatient,
    partTotal:partTotal,
    partMutuelleFacture:partMutuelleFacture,
    partpatientFacture:partpatientFacture,
    partTotalFacture:partTotalFacture,
    },
  };

  return http.put<any>(`/FactureSaisiePharmacyStatusPayer/${id}`, null, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/FactureSaisiePharmacy/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/FactureSaisiePharmacy`);
};

const findByReceiptBillAndSocialInsuarance = (socialinsurance_id:any, receiptBill:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacy/${socialinsurance_id}/${receiptBill}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyNoPayer/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusFacturePayer = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyPayer/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};

const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerSansMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyNoPayerSansMituelle/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusFacturePayerSansMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyPayerSansMituelle/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};


const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyNoPayerMituelle/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};

const findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelleParService = (socialinsurance_id:any, receiptBill:any,idService:any,idDepertment:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyNoPayerMituelleParService/${socialinsurance_id}/${receiptBill}/${idService}/${idDepertment}`);
};
const findBySocialinsuranceIdAndReceiptBillIdServiceIdDepertmentPayer = (socialinsurance_id:any, receiptBill:any,idService:any,idDepertment:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyPayerMituelleParService/${socialinsurance_id}/${receiptBill}/${idService}/${idDepertment}`);
};
const findBySocialinsuranceIdAndReceiptBillStatusFacturePayerMituelle = (socialinsurance_id:any, receiptBill:any,statusFacturePayer:any) => {
  return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyPayerMituelle/${socialinsurance_id}/${receiptBill}/${statusFacturePayer}`);
};

const findBySocialInsuranceAndCodeAssuree = (socialinsurance_id:any,codeAssuree: any) => {
    return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacyCodeAssuree/${socialinsurance_id}/${codeAssuree}`);
  };

const findByNewVisitePatientId = (idNewVisite: any) => {
    return http.get<Array<NewFactureSaisieMedicamentDto>>(`/FactureSaisiePharmacy?receiptBill=${idNewVisite}`);
  };


const getAllFactureSaisiePharmacyBySocialinsuranceIdAndCodeAssureePage = (params:any) => {
    return http.get<Array<NewFactureSaisieMedicamentDto>>("/FactureSaisiePharmacyBySocialinsuranceIdAndCodeAssureePage", { params });
  };


const ServiceNewFactureSaisieMedicamentDtoMedicament = {
  getAllNewFactureSaiseByStructure,
  findById,
  findByReceiptBillAndSocialInsuarance,
  findBySocialInsuranceAndCodeAssuree,
  getAllNewFactureSaise,
  findBySocialinsuranceIdAndPatientCode,
  findBySocialinsuranceIdAndPatientCodeByStructure,
  updateFactureSaisiePharmacy,
  updateFactureSaisiePharmacyStatusPayer,
  remove,
  removeAll,
  getAllFactureSaisiePharmacyBySocialinsuranceIdAndCodeAssureePage,
  getAllVisitelWithoutPagination,
  createFactureSaisiePharmacy,
  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayer,
  findBySocialinsuranceIdAndReceiptBillStatusFacturePayer,
  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelleParService,
  findBySocialinsuranceIdAndReceiptBillIdServiceIdDepertmentPayer,
  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerSansMituelle,
  findBySocialinsuranceIdAndReceiptBillStatusFacturePayerSansMituelle,

  findBySocialinsuranceIdAndReceiptBillStatusNoFacturePayerMituelle,
  findBySocialinsuranceIdAndReceiptBillStatusFacturePayerMituelle,
  findByNewVisitePatientId,
  getAllNewFactureSaiseByStructureGrouped,
  getDeclarationBetweenDates,
  findByIdIncriment,
  findByIdIncrimentList
};

export default ServiceNewFactureSaisieMedicamentDtoMedicament;