import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { Route } from 'react-router-dom';
import { AfficherlesVisitesdepatients } from '../Pages/Patient/AfficherlesVisitesdepatients';
import { Dashbord } from '../Pages/Dashbord/Dashbord';
import { DoctorPage } from '../Pages/Doctor/DoctorPage';
import { Categories } from '../Pages/ASSURANCES/Categories/Categories';
import { Structures}  from '../Pages/ASSURANCES/StructuresPartenaires/Structures';
import { Actes } from '../Pages/Actes/Actes';
import { Materiel } from '../Pages/Materiel/Materiel';
import { Types } from '../Pages/Type/Types';
import { CreateInsurance } from '../Pages/ASSURANCES/CreateInsurance/CreateInsurance';
import { Enterprises } from '../Pages/ASSURANCES/Enterprises/Enterprises';
import { FraisAdhesions } from '../Pages/ASSURANCES/Adhesions/FraisAdhesions';
import { Configurations } from '../Pages/ASSURANCES/Configurations/Configurations';
import { Payment } from '../Pages/ASSURANCES/Payment/payment';
import { SejourVisitesPage } from '../Pages/SejourVisites/SejourVisitesPage';
import { Bons } from '../Pages/ASSURANCES/Bons/Bons';
import { FacturationPatient } from '../Pages/Facturation/FacturationPatient/FacturationPatient';
import { PlafondYear } from '../Pages/PlafondYear/PlafondYear';
import MySidebar from '../Components/SideBar/SideBar';
import { useDispatch } from 'react-redux';
import { updateUserRoles } from '../Redux/slices/UserRoles/userSlice';
import { updateRealmRoles } from '../Redux/slices/RealmRoles/realmSlice';
import { updateClientRoles } from '../Redux/slices/clientRoles/clientSlice';
import { updateUser } from '../Redux/slices/UserProfile/UserProfileSlice';
import { FacturationListes } from '../Pages/Facturation/FacturationListesVisites/FacturationListes';
import { ListeVisiteEncours } from '../Pages/Facturation/VisiteEncours/ListeVisiteEncours';
import { FacturationListesDeclaration } from '../Pages/Facturation/FacturationListesVisites/FacturationListesDeclaration';
import { ModalFactureSaisie } from '../Components/Espace_Patients/FactureSaisie/Modals/ModalFactureSaisie';
import { EspacePatients } from '../Pages/Facturation/EspacePatients/EspacePatients';
import { SaveNonAfflier } from '../Pages/NonAffiliers/Registration/SaveNonAfflier';
import { PageListMedicaments } from '../Pages/PageListMedicaments/PageListMedicaments';
import { TypeMedicament } from '../Pages/TypeMedicament/TypeMedicament';
import { ConfigurationsPharma } from '../Pages/ASSURANCES/ConfigurationsPharma/ConfigurationsPharma';
import { Livraison } from '../Pages/Parmacy/Livraison';
import { ListeDeLivraison } from '../Pages/Parmacy/ListeDeLivraison';
import { ListeDeFactureDeclare } from '../Pages/Parmacy/ListeDeFactureDeclare';
import { ListeDeFactureRembource } from '../Pages/Parmacy/ListeDeFactureRembource';
import { ConfigurationsSurPlafondSoins } from '../Pages/ASSURANCES/Configurations/ConfigurationsSurPlafondSoins';
import { ConfigurationsSurPlafondPharma } from '../Pages/ASSURANCES/ConfigurationsPharma/ConfigurationsSurPlafondPharma';
import { AdherantPrincipalCodeCategory } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AdherantPrincipal/AdherantPrincipalCodeCategory';
import { AdherantPrincipalCodeCategoryMarie } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AdherantPrincipal/AdherantPrincipalCodeCategoryMarie';
import { AdherantPrincipalCodeCategorySoins } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AdherantPrincipal/AdherantPrincipalCodeCategorySoins';
import { AdherantPrincipalCodeCategorySoinsMarie } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AdherantPrincipal/AdherantPrincipalCodeCategorySoinsMarie';
import { AyantDroitCategorySoins } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AyantDroit/AyantDroitCategorySoins';
import { AyantDroitCategorySoinsSurPLusEnfant } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AyantDroit/AyantDroitCategorySoinsSurPLusEnfant';
import { AyantDroitCodeCategory } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AyantDroit/AyantDroitCodeCategory';
import { AyantDroitCodeCategorySoins } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AyantDroit/AyantDroitCodeCategorySoins';
import { AyantDroitCodeCategorySurPlusEnfant } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AyantDroit/AyantDroitCodeCategorySurPlusEnfant';
import { AyantDroitCodeSoinsMarie } from '../Pages/ASSURANCES/StatistiquesSurAffiliers/AyantDroit/AyantDroitCodeSoinsMarie';
import { ConsommationsAdherantPrincipal } from '../Pages/ASSURANCES/Consommations/ConsommationsAdherantPrincipal';
import { ConsommationsAdherantPrincipalDetails } from '../Pages/ASSURANCES/Consommations/ConsommationsAdherantPrincipalDetails';
import { ConsommationsDepertmentServices } from '../Pages/ASSURANCES/Consommations/ConsommationsDepertmentServices';
import { ConsommationsEnterprise } from '../Pages/ASSURANCES/Consommations/ConsommationsEnterprise';
import { ConsommationsEnterpriseDetails } from '../Pages/ASSURANCES/Consommations/ConsommationsEnterpriseDetails';
import { ConsommationsServices } from '../Pages/ASSURANCES/Consommations/ConsommationsServices';
import { FacturationListesDeclarationParDepertement } from '../Pages/Facturation/FacturationListesVisites/FacturationListesDeclarationParDertement';
import { SenteseListesDeclaration } from '../Pages/Facturation/FacturationListesVisites/SenteseListesDeclaration';
import { SyntheseDeclarationLivraison } from '../Pages/Parmacy/SyntheseDeclarationLivraison';
import { ConsommationsDepertments } from '../Pages/ASSURANCES/Consommations/ConsommationsDepertments';
import { CotisationEntreprise } from '../Pages/ASSURANCES/Recouvrements/CotisationEntreprise';
import { CotisationIndependant } from '../Pages/ASSURANCES/Recouvrements/CotisationIndependant';
import { RemboursementHospital } from '../Pages/ASSURANCES/Recouvrements/RemboursementHospital';
import { RemboursementPharmacy } from '../Pages/ASSURANCES/Recouvrements/RemboursementPharmacy';
import { CotisationEntrepriseDetails } from '../Pages/ASSURANCES/Recouvrements/CotisationEntrepriseDetails';
import { ListeCotisationClassOuNoClassParEnterprise } from '../Pages/ASSURANCES/Recouvrements/Comptabilite/ListeCotisationClassOuNoClassParEnterprise';
import { ListeCotisationPayerOuNopayerParEnterprise } from '../Pages/ASSURANCES/Recouvrements/Comptabilite/ListeCotisationPayerOuNopayerParEnterprise';
import { ListeCotisationClassOuNoClassParIndependant } from '../Pages/ASSURANCES/Recouvrements/Comptabilite/ListeCotisationClassOuNoClassParIndependant';
import { ListeCotisationPayerOuNopayerParIndependant } from '../Pages/ASSURANCES/Recouvrements/Comptabilite/ListeCotisationPayerOuNopayerParIndependant';
import { ListeCotisationClassOuNoClassTous } from '../Pages/ASSURANCES/Recouvrements/Comptabilite/ListeCotisationClassOuNoClassTous';
import { ListeCotisationPayerOuNopayerParTous } from '../Pages/ASSURANCES/Recouvrements/Comptabilite/ListeCotisationPayerOuNopayerParTous';
import { EspaceVerifications } from '../Pages/Verifications/EspaceVerifications';
import { NoVerifeCombineeHospitalPage } from '../Pages/Verifications/NoVerifeCombineeHospitalPage';
import { VerifeCombineeHospitalPage } from '../Pages/Verifications/VerifeCombineeHospitalPage';
import { NoVerifieCombineePharmacyPage } from '../Pages/Verifications/NoVerifieCombineePharmacyPage';
import { VerifieCombineePharmacyPage } from '../Pages/Verifications/VerifieCombineePharmacyPage';

interface Token {
  // Other properties of your token...
  
  realm_access?: {
    [clientId: string]: {
      roles: string[];
    };
  };
  resource_access?: {
    [clientId: string]: {
      roles: string[];
    };
  };
}

// Example dynamic routes
const dynamicRoutes = [
  { path: '/', component: Dashbord, roles: ['admin'] },
  { path: '/AfficherlesVisitesdepatients', component: AfficherlesVisitesdepatients, roles: ['admin'] },
  { path: '/AjouterTypeDoctorPage', component: DoctorPage, roles: ['admin'] },
  { path: '/categories', component: Categories, roles: ['admin'] },
  { path: '/structures', component: Structures, roles: ['admin'] },
  { path: '/materiel', component: Materiel, roles: ['admin'] },
  { path: '/actes', component: Actes, roles: ['admin'] },
  { path: '/types', component: Types, roles: ['admin'] },
  { path: '/createInsurance', component: CreateInsurance, roles: ['admin'] },
  { path: '/enterprise', component: Enterprises, roles: ['admin'] },
  { path: '/fraisAdhesions', component: FraisAdhesions, roles: ['admin'] },

  { path: '/configurations', component: Configurations, roles: ['admin'] },
  { path: '/configurationsPharma', component: ConfigurationsPharma, roles: ['admin'] },
  { path: '/configurationsSurPlafondSoins', component: ConfigurationsSurPlafondSoins, roles: ['admin'] },
  { path: '/configurationsSurPlafondPharma', component: ConfigurationsSurPlafondPharma, roles: ['admin'] },
  

  { path: '/modePayment', component: Payment, roles: ['admin'] },
  { path: '/sejour', component: SejourVisitesPage, roles: ['admin'] },
  { path: '/bon', component: Bons, roles: ['admin'] },
  { path: '/PlafondYear', component: PlafondYear, roles: ['admin'] },
  { path: '/FacturationConsultation', component: FacturationPatient, roles: ['admin','user','verification'] },

  { path: '/EspacePatients', component: EspacePatients, roles: ['admin','user','verification']},
  { path: '/VisiteEnCours', component: ListeVisiteEncours, roles: ['user','verification'] },
  { path: '/FacturationListes', component: FacturationListes, roles: ['admin','user','verification']},
  { path: '/FactureSaisie', component: ModalFactureSaisie, roles: ['admin','user','verification']},
  { path: '/DeclationFacture', component: FacturationListesDeclaration, roles: ['admin','user','verification']},

  { path: '/DeclationFactureDepertment', component: FacturationListesDeclarationParDepertement, roles: ['admin','user','verification']},
  { path: '/SyntheseDeclaration', component: SenteseListesDeclaration, roles: ['admin','user','verification']},
  
  { path: '/saveNonAfflier', component: SaveNonAfflier, roles: ['admin','user']},

  { path: '/listeMedicament', component: PageListMedicaments, roles: ['admin']},

  { path: '/typeMedicament', component: TypeMedicament, roles: ['admin']},

  { path: '/livraison', component: Livraison, roles: ['pharmacy']},

  { path: '/listLivraison', component: ListeDeLivraison, roles: ['admin','pharmacy','verification']},

  { path: '/listDeFactureDeclare', component: ListeDeFactureDeclare, roles: ['admin','pharmacy','verification']},
  
  { path: '/listDeFactureRembource', component: ListeDeFactureRembource, roles: ['admin','pharmacy','verification']},

  { path: '/SyntheseDeclarationLivraison', component: SyntheseDeclarationLivraison, roles: ['admin','pharmacy','verification']},

  // Verification

  { path: '/EspaceVerification', component: EspaceVerifications, roles: ['admin','verification']},
  { path: '/NoVerifeCombineeHospital', component: NoVerifeCombineeHospitalPage, roles: ['admin','verification']},
  { path: '/VerifeCombineeHospital', component: VerifeCombineeHospitalPage, roles: ['admin','verification']},
  { path: '/NoVerifieCombineePharmacy', component: NoVerifieCombineePharmacyPage, roles: ['admin','verification']},
  { path: '/VerifieCombineePharmacy', component: VerifieCombineePharmacyPage, roles: ['admin','verification']},

  // recouvrment ListeCotisationClassOuNoClassParEnterprise

  { path: '/cotisationEntreprise', component: CotisationEntreprise, roles: ['admin']},
  { path: '/cotisationEntrepriseDetails', component: CotisationEntrepriseDetails, roles: ['admin']},
  { path: '/cotisationIndependant', component: CotisationIndependant, roles: ['admin']},
  { path: '/remboursementHospital', component: RemboursementHospital, roles: ['admin']},
  { path: '/remboursementPharmacy', component: RemboursementPharmacy, roles: ['admin']},

// Comptablilite 

{ path: '/listCotisationClassee', component: ListeCotisationClassOuNoClassParEnterprise, roles: ['admin']},
{ path: '/listCotisationPayer', component: ListeCotisationPayerOuNopayerParEnterprise, roles: ['admin']},

{ path: '/listCotisationClasseeAll', component: ListeCotisationClassOuNoClassTous, roles: ['admin']},
{ path: '/listCotisationPayerAll', component: ListeCotisationPayerOuNopayerParTous, roles: ['admin']},

{ path: '/listCotisationClasseeInd', component: ListeCotisationClassOuNoClassParIndependant, roles: ['admin']},
{ path: '/listCotisationPayerInd', component: ListeCotisationPayerOuNopayerParIndependant, roles: ['admin']},

  // statistiques sur les affilies
  { path: '/adherantPrincipalCodeCategory', component: AdherantPrincipalCodeCategory, roles: ['admin']},
  { path: '/adherantPrincipalCodeCategoryMarie', component: AdherantPrincipalCodeCategoryMarie, roles: ['admin']},
  { path: '/adherantPrincipalCodeCategorySoins', component: AdherantPrincipalCodeCategorySoins, roles: ['admin']},
  { path: '/adherantPrincipalCodeCategorySoinsMarie', component:AdherantPrincipalCodeCategorySoinsMarie, roles: ['admin']},
  
  { path: '/ayantDroitCategorySoins', component: AyantDroitCategorySoins, roles: ['admin']},
  { path: '/ayantDroitCategorySoinsSurPLusEnfant', component: AyantDroitCategorySoinsSurPLusEnfant, roles: ['admin']},
  { path: '/ayantDroitCodeCategory', component: AyantDroitCodeCategory, roles: ['admin']},
  { path: '/ayantDroitCodeCategorySoins', component: AyantDroitCodeCategorySoins, roles: ['admin']},
  { path: '/ayantDroitCodeCategorySurPlusEnfant', component: AyantDroitCodeCategorySurPlusEnfant, roles: ['admin']},
  { path: '/ayantDroitCodeSoinsMarie', component: AyantDroitCodeSoinsMarie, roles: ['admin']},


  //consommation
  { path: '/consommationsAdherantPrincipal', component: ConsommationsAdherantPrincipal, roles: ['admin']},
  { path: '/consommationsAdherantPrincipalDetails', component: ConsommationsAdherantPrincipalDetails, roles: ['admin']},
  { path: '/consommationsDepertments', component: ConsommationsDepertments, roles: ['admin','user']},
  { path: '/consommationsDepertmentServices', component: ConsommationsDepertmentServices, roles: ['admin']},
  { path: '/consommationsEnterprise', component: ConsommationsEnterprise, roles: ['admin']},
  { path: '/consommationsEnterpriseDetails', component: ConsommationsEnterpriseDetails, roles: ['admin']},
  { path: '/consommationsServices', component: ConsommationsServices, roles: ['admin']},

];

export const App=()=> {
  const { keycloak,initialized } = useKeycloak();
  
  const [userRoles, setUserRoles] = useState<string | string[]>([]);
  
  const dispatchAllRoles = useDispatch();
  const dispatchRealmRoles = useDispatch();
  const dispatchClientRoles = useDispatch();
  const dispatchUserInfo = useDispatch();


  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        // Check if the user is authenticated before fetching roles
        if (keycloak.authenticated) {
          // Fetch user roles from Keycloak's token
          const token = await keycloak?.tokenParsed;
          console.log(token)
        // Fetch realm roles
const realmRoles = (token as Token)?.realm_access?.roles || [];

// Extract roles property if realmRoles is an object
const extractedRealmRoles = Array.isArray(realmRoles) ? realmRoles : realmRoles.roles || [];

// Fetch client roles
const clientRoles = (token as Token)?.resource_access?.['hema-app']?.roles || [];

// Ensure both extractedRealmRoles and clientRoles are arrays
 const userRoles = ([] as string[]).concat(extractedRealmRoles, clientRoles); 
 const userRolesClient = ([] as string[]).concat(clientRoles); 
 const userRolesRealm = ([] as string[]).concat(extractedRealmRoles); 
 // Set the state with the new userRoles value
 setUserRoles(userRoles);
  
// Dispatch the action to update 
    dispatchAllRoles(updateUserRoles([...userRoles]));

    dispatchRealmRoles(updateRealmRoles([...userRolesRealm]));

    dispatchClientRoles(updateClientRoles([...userRolesClient]));
    if (initialized && keycloak.authenticated && keycloak.tokenParsed) {
      const { 
        sid,
         email,
          family_name, 
          given_name,
           preferred_username,
          hemaClinicGroups} = keycloak.tokenParsed;
// Extract group or hemaClinicGroups names without leading '/'
     const cleanedGroups = hemaClinicGroups.map((group: string) => group.replace(/^\//, ''));

      // Dispatch the action to update user information in the Redux store
      dispatchUserInfo(
        updateUser({
          sid,
          email,
          family_name,
          given_name,
          preferred_username,
        hemaClinicGroups:cleanedGroups,
        })
      );
    }
        }
      } catch (error) {
        console.error('Error fetching user roles', error);
      }
    };

    fetchUserRoles();
  }, [keycloak.authenticated, keycloak]);


  return ( <div>
    <MySidebar>
      {/* Map through dynamic routes and render based on user roles */}
      { dynamicRoutes.map((route) =>
      route.roles.length === 0 || (Array.isArray(userRoles) && userRoles.some((role) => route.roles.includes(role))) ? (
        
          <Route key={route.path} path={route.path} exact>
            <route.component />
          </Route>
        ) : null
      )}
    </MySidebar>

  </div>
     
  );
}

