import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { ItypeDoctor } from '../Interfaces/ItypeDoctor';
import { ChangeEvent, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import IdataModelTypeDoctor from "../Models/IdataModelTypeDoctor";
import TypeDoctorServices from "../Services/TypeDoctorServices";


export const TypeDoctorTable:React.FC<ItypeDoctor>=props=>{
    

  const [typeDoctor, setTypeDoctor] = useState<Array<IdataModelTypeDoctor>>([]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchTypeDoctor, setSearchTypeDoctor] = useState<string>("");
  
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  
  const getRequestParams = (searchtypeName:string, thepage: number, thepageSize: number) => {
    let params = {
      typeName:searchtypeName,
      page: thepage,
      size:thepageSize
    };
  
    if (searchtypeName) {
      params["typeName"] = searchtypeName;
    }
  
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearchTypeDoctor = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTypeDoctor = e.target.value;
    setSearchTypeDoctor(searchTypeDoctor);
  };
  
  const findByTypeDoctor = () => {
    TypeDoctorServices.findByTypeDoctor(searchTypeDoctor)
      .then((response: any) => {
        const {typeDoctors } = response.data;
        setTypeDoctor(typeDoctors);
        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const retrieveTypeDoctor = () => {
    const params = getRequestParams(searchTypeDoctor, page, pageSize);
  
    TypeDoctorServices.getAll(params)
  
      .then((response: any) => {
  
        const {typeDoctors , totalPages} = response.data;
  
        setTypeDoctor(typeDoctors);
        setCount(totalPages);
  
        console.log(response.data);
        console.log(params);
  
      })
  
      .catch((e: Error) => {
        console.log(e);
      });
  };
  useEffect(() => {
    retrieveTypeDoctor();
  },[page, pageSize]);
  
  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const refreshList = () => {
    retrieveTypeDoctor();
    setCurrentIndex(-1);
  };
  
  const setActiveTypeDoctor = (datatypeDoctor: IdataModelTypeDoctor, index: number) => {
    setCurrentIndex(index); 
     props.onEditTypeDoctor(datatypeDoctor);
     props.onHide();
    console.log(datatypeDoctor);
    console.log(index);
  };
  
  const OnDeleteTypeDoctor = (id:any) => {
  
    TypeDoctorServices.remove(id)
      .then((response: any) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
    return(
          <>
          <Row className="mb-3">
        
        <Stack  direction="horizontal" gap={2}>
                  <Form.Control className="me-auto"
                    placeholder="Filtre le type docteur..."
                    value={searchTypeDoctor}
                     onChange={onChangeSearchTypeDoctor} 
                    />
                  <Button variant="outline-dark btn-sm"
                   onClick={findByTypeDoctor}>Cherchez</Button>
           </Stack>   
          <div className="mt-3">
            
          {"Items per Page: "}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </div>
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>id</th>
              <th>Type Docteur</th>
              <th>Compte</th>
            </tr>
          </thead>
          <tbody>
          {typeDoctor.length>0 ? (
            typeDoctor.map((i, index) => (
                
                  <tr key={i.id }>
                  <td>{i.id}</td>
                  <td>{i.typeName}</td>
                  <td>{i.accountTypeDoctor}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setActiveTypeDoctor(i, index)}>Edit</Button>
  
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteTypeDoctor(i.id)}>Delete</Button>
                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no Type Doctors</td>
              </tr>
            )}
  
          </tbody>
        </Table>
        </Row>
  
          </>
      );
  }