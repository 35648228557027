import http from "../../../../../AxiosRequest/httpcommon";
import IdataModelCouverture from "../Models/IdataModelCouverture";
import IdataModelCouvertureDtos from "../Models/IdataModelCouvertureDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelCouvertureDtos>>("/couvertureconfigPharmaSurConsomation", { params });
};

const getAllWithoutPagination = () => {
  return http.get<Array<IdataModelCouverture>>("/sortedcouvertureconfigPharmaSurConsomation");
};

const create = 
(socialinsuranceId:any,
  enterprisesAssuranceId:any,
  
  categoriesInsuranceCodeId:any,
  servicesId:any,
  
  data: IdataModelCouverture) => {
  return http.post<IdataModelCouverture>(`/${socialinsuranceId}/${enterprisesAssuranceId}/${categoriesInsuranceCodeId}/${servicesId}/couvertureconfigPharmaSurConsomation`, data);
};

const update = (id: any, data: IdataModelCouverture) => {
  return http.put<any>(`/couvertureconfigPharmaSurConsomation/${id}`, data);
  
};

const remove = (id: any) => {
  return http.delete<any>(`/couvertureconfigPharmaSurConsomation/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/couvertureconfigPharmaSurConsomation`);
};

const findByenterpriseName = (socialinsuranceId:any,enterpriseName: string) => {
  return http.get<Array<IdataModelCouvertureDtos>>(`/couvertureconfigPharmaSurConsomation?socialinsuranceId=${socialinsuranceId}&enterpriseName=${enterpriseName}`);
};
const findByInsuranceIdAndEnterpriseIdAndSecteurAndService = (idInsurance:any,idEnterprise: any,secter:string,service: string,type_category: string) => {
  return http.get<Array<IdataModelCouvertureDtos>>(`/couvertureconfigPharmaSurConsomation/${idInsurance}/${idEnterprise}/${secter}/${service}/${type_category}`);
};

const findByInsuranceIdAndEnterpriseIdAndSecteurAndidCouvertureConfig = (idInsurance:any,idEnterprise: any,secter:string,idService: any,type_category: string) => {
  return http.get<Array<IdataModelCouvertureDtos>>(`/couvertureconfigPharmaSurConsomationIdConfig/${idInsurance}/${idEnterprise}/${secter}/${idService}/${type_category}`);
};

const CouvertureconfigServiceSurPlafondPharma = {
  getAllWithoutPagination,
  getAll,
  create,
  update,
  remove,
  removeAll,
  findByenterpriseName,
  findByInsuranceIdAndEnterpriseIdAndSecteurAndService,
  findByInsuranceIdAndEnterpriseIdAndSecteurAndidCouvertureConfig
};

export default CouvertureconfigServiceSurPlafondPharma;