import IdataModelNonAffilie from "../Models/IdataModelRegisretationNonAffilie";
import http from "../../../../AxiosRequest/httpcommon";
import NonAffiliesDto from "../Models/NonAffiliesDto";

const getAll = (params:any) => {
  return http.get<Array<IdataModelNonAffilie>>("/AllNoAffiliesDtosPage", { params });
};

const getAllNonAffilieWithoutPagination = () => {
  return http.get<Array<IdataModelNonAffilie>>("/sortedNonAffilies");
};
const getLastCodePatient=(hospitalID: any)=>{
  return http.get<NonAffiliesDto>(`/lastCodePatient/${hospitalID}` );

}

const create = (hospital_id: any,data: IdataModelNonAffilie) => {
  return http.post<IdataModelNonAffilie>(`/NonAffilie/${hospital_id}`, data);
};

const update = (id: any, data: IdataModelNonAffilie) => {
  return http.put<any>(`/NonAffilie/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/NonAffilie/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/NonAffilie`);
};

const findByNonAffilie = (fullNamePatient: string) => {
  return http.get<Array<IdataModelNonAffilie>>(`/AllNoAffiliesDtosPage?fullNamePatient=${fullNamePatient}`);
};

const NonAffilieService = {
  getAllNonAffilieWithoutPagination,
  getAll,
  getLastCodePatient,
  create,
  update,
  remove,
  removeAll,
  findByNonAffilie,
};

export default NonAffilieService;