import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NewFactureLivraisonMedicamentDto from '../../../Components/Espace_Patients/FactureMedicament/models/Dto/NewFactureLivraisonMedicamentDto';

const initialState: NewFactureLivraisonMedicamentDto[] = [];

const currentMedicalLivraisonArrayToPrint = createSlice({
  name: 'currentMedicalLivraisonArrayToPrint',
  initialState,
  reducers: {  
      addMultipleMedicalLivraisonRecordsToprintList: (state, action: PayloadAction<NewFactureLivraisonMedicamentDto[]>) => {
        state.push(...action.payload);
      },
    clearMedicalLivraisonRecordsToprint: () => {
      return initialState;
    },
  },
});

export const {addMultipleMedicalLivraisonRecordsToprintList,clearMedicalLivraisonRecordsToprint } = currentMedicalLivraisonArrayToPrint.actions;
export default currentMedicalLivraisonArrayToPrint.reducer;
