import { Row, Table, Stack, Button, Form } from "react-bootstrap"
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import NonAffiliesDto from '../Models/NonAffiliesDto';
import NonAffilieService from "../Services/NonAffilieService";
import { RootState } from "../../../../Redux/Store/store";
import { useSelector } from "react-redux";
import IdataModelHospitalType from "../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import { INonAffilie } from "../Interfaces/INonAffilie";


export const NonAffilierTable:React.FC<INonAffilie>=props=>{
    

  const [tonAffiliesDto, setNonAffilier] = useState<Array<NonAffiliesDto>>([]);
  
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  
  const [searchNonAffilier, setSearchNonAffilier] = useState<string>("");
  
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  
  const [hosptId, sethosptId] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [3, 6, 9,12];
  
  const findByHospitalId = useCallback(() => {
    if (StructuresData.length > 0) {
      let concatenatedString: string = StructuresData.join(", ");
      console.log("concatenatedString", concatenatedString);
      const MonStructure = concatenatedString;

      HospitalTypeService.findByhospitalName(MonStructure)
        .then((response: any) => {
          const { hospital } = response.data;
          sethosptId(hospital[0].id);
          console.log("response.data.id", hospital);
          
        console.log( "nonAffilies",hospital[0].id)    
        });
    }
  }, [StructuresData]);
  
  useEffect(() => {
    findByHospitalId();
  }, [findByHospitalId]);

  const getRequestParams = (hospitalId:any,searchfullNamePatient:string, thepage: number, thepageSize: number) => {
    let params = {
      hospitalId:hospitalId,
      fullNamePatient:searchfullNamePatient,
      page: thepage,
      size:thepageSize
    };
  
    if (searchfullNamePatient) {
      params["fullNamePatient"] = searchfullNamePatient;
    }
    if (hospitalId) {
      params["hospitalId"] = hospitalId;
    }
  
    if (thepage) {
      params["page"] = thepage - 1;
    }
  
    if (thepageSize) {
      params["size"] = thepageSize;
    }
  
    return params;
  };
  
  const onChangeSearchNonAffilier = (e: ChangeEvent<HTMLInputElement>) => {
    const searchNonAffilier = e.target.value;
    setSearchNonAffilier(searchNonAffilier);
  };
  
  const findByNonAffilier = () => {
    NonAffilieService.findByNonAffilie(searchNonAffilier)
      .then((response: any) => {
        const {nonAffilies } = response.data;
        setNonAffilier(nonAffilies);
        setCurrentIndex(-1);
        console.log(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
const retrieveNonAffilier = useCallback(() => {
  
  const params = getRequestParams(hosptId, searchNonAffilier, page, pageSize);

 
    if(hosptId!==0) {
      NonAffilieService.getAll(params)
      .then((response: any) => {
        const { nonAffilies, totalPages } = response.data;
  
        setNonAffilier(nonAffilies);
        setCount(totalPages);
  
        console.log("response.data response.data",response.data);
        console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
    }else {
      console.log("hosptId is nul")
    }
}, [hosptId, searchNonAffilier, page, pageSize]);

useEffect(() => {
  retrieveNonAffilier();
}, [retrieveNonAffilier]);


  const handlePageChange = (event: any, value: any) => {
      setPage(value);
    };
  
    const handlePageSizeChange = (event: any) => {
      setPageSize(event.target.value);
      setPage(1);
    };
  
  const refreshList = () => {
    retrieveNonAffilier();
    setCurrentIndex(-1);
  };
  
  const setActiveNonAffilier = (NonAffiliesDto: NonAffiliesDto, index: number) => {
    setCurrentIndex(index); 
     props.onEditNonAffilie(NonAffiliesDto);
     props.onHide();
  //  console.log(index);
  };
  
  const OnDeleteNonAffilier = (id:any) => {
  
    NonAffilieService.remove(id)
      .then((response: any) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  
    return(
          <>
          <Row className="mb-3">
        
        <Stack  direction="horizontal" gap={2}>
                  <Form.Control className="me-auto"
                    placeholder="Filtre non affilier..."
                    value={searchNonAffilier}
                     onChange={onChangeSearchNonAffilier} 
                    />
                  <Button variant="outline-dark btn-sm"
                   onClick={findByNonAffilier}>Cherchez</Button>
           </Stack>   
          <div className="mt-3">
            
          {"Items per Page: "}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </div>
          <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>id</th>
              <th>FullName</th>
              <th>Code Patient</th>
            </tr>
          </thead>
          <tbody>
          {tonAffiliesDto.length>0 ? (
            tonAffiliesDto.map((i, index) => (
                
                  <tr key={i.id }>
                  <td>{i.id}</td>
                  <td>{i.fullNamePatient}</td>
                  <td>{i.codePatient}</td>
                  <td>
                  <Stack direction="horizontal" gap={2}>
                  <Button className="me-4 btn btn-success btn-sm btn-block"
                  onClick={() => setActiveNonAffilier(i, index)}>Edit</Button>
  
                    <Button className="me-4 btn btn-success btn-sm btn-block" onClick={() => OnDeleteNonAffilier(i.id)}>Delete</Button>
                  </Stack>
                  </td>
                </tr>
              ))
            ) : ( 
              <tr>
                <td colSpan={3}>no Type Doctors</td>
              </tr>
            )}
  
          </tbody>
        </Table>
        </Row>
  
          </>
      );
  }