import PatientInfo from "../../Components/ParmacyActivity/PatientInfo";
import TabFacturationPrixMedicament from "../../Components/ParmacyActivity/TabFacturationPrixMedicament";

export const Livraison=()=>{

    return (

        <div>
             <PatientInfo/>
             <TabFacturationPrixMedicament/>
         </div>
    );
}