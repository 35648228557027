import http from "../../../../AxiosRequest/httpcommon";
import IdataModelCounterRestPlafond from "../Models/CounterRestPlafond";
import IdataModelCounterRestPlafondDto from '../Models/IdataModelCounterRestPlafondDto';


const getAllRestPlafondYearEnterprise = (params:any) => {
  return http.get<Array<IdataModelCounterRestPlafondDto>>("/RestPlafondYearEnterprise", { params });
};

const getAllRestPlafondYearEnterprisenomPrenomAffilie = (params:any) => {
  return http.get<Array<IdataModelCounterRestPlafondDto>>("/RestPlafondYearEnterprisenomPrenomAffilie", { params });
};

const createRestPlafond = (socialinsuranceId:any,
 enterprisesAssurance_id:any,
 pMember_id:any,
 plafondTypeVisite_id:any,
 categoriesInsurance_id:any,
 typeBons_id:any,
  data: IdataModelCounterRestPlafond) => {
  return http.post<IdataModelCounterRestPlafond>
  (`${socialinsuranceId}/${enterprisesAssurance_id}/${pMember_id}/${plafondTypeVisite_id}/${categoriesInsurance_id}/${typeBons_id}/RestPlafondYear`, data);
};

const update = (id: any, data: IdataModelCounterRestPlafond) => {
  return http.put<any>(`/RestPlafondYear/${id}`, data);
};

const updateRestPlafondYearStatusPayer = (idRestIncrementPlafondConsomme: any,codeId:any, status: string) => {

  const data = {
    params: {
      status: status,
    },
  };

  return http.put<any>(`/RestPlafondYearStatusPayer/${idRestIncrementPlafondConsomme}/${codeId}`, null, data);
};

const findByCodeId = (insuranceId:any,idInterprise:any,codeAssuree: string) => {
  return http.get<Array<IdataModelCounterRestPlafondDto>>(`RestPlafondYearAccount/${insuranceId}/${idInterprise}/${codeAssuree}`);
};
const getSum = (codeId: any, status: any,typeBons_id:any) => {
  return http.get<any>(`/SumByCodeIdAndStatus?codeId=${codeId}&status=${status}&typeBons_id=${typeBons_id}`);
};


const getSumold = (params:any) => {
  return http.get<any>("/SumByCodeIdAndStatus", { params });
};

const getLastIdRefRestPlafondAndPlafondYear = (insuranceId:any,idInterprise:any,codeAssuree: string) => {
  return http.get<Array<IdataModelCounterRestPlafondDto>>(`getIdRefRestPlafond/${insuranceId}/${idInterprise}/${codeAssuree}`);
};
const RestPlafondYearService = {
  getAllRestPlafondYearEnterprise,
  getAllRestPlafondYearEnterprisenomPrenomAffilie,
  findByCodeId,
  getLastIdRefRestPlafondAndPlafondYear,
  createRestPlafond,
  getSum,
  update,
  updateRestPlafondYearStatusPayer
 
};

export default RestPlafondYearService;