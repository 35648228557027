import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './AppRoot/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';

// Import Redux Provider and store

// Import Redux Provider and store
import { Provider } from 'react-redux';
import store from '../src/Redux/Store/store'; // Update the path accordingly

import { keycloak, ReactKeycloakProvider } from './keycloak';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ onLoad: 'login-required' }}
  >
    {/* Wrap your app with the Redux Provider */}
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ReactKeycloakProvider>,
);
