import { useState } from "react";
import IdataModelTypeStatus from "../../Components/Type/TypeStatus/Models/IdataModelTypeStatus";
import IdataModelTypeStructure from "../../Components/Type/TypeStructure/Models/IdataModelTypeStructure";
import { AddTypeStructure } from "../../Components/Type/TypeStructure/TypeOperations/AddTypeStructure";
import { Card, Col, Container, Row } from "react-bootstrap";
import { EditTypeStructure } from "../../Components/Type/TypeStructure/TypeOperations/EditTypeStructure";
import { TypeTableStructure } from "../../Components/Type/TypeStructure/Tables/TypeTableStructure";
import { AddTypeStatus } from "../../Components/Type/TypeStatus/TypeOperations/AddTypeStatus";
import { EditTypeStatus } from "../../Components/Type/TypeStatus/TypeOperations/EditTypeStatus";
import { TypeTableStatus } from "../../Components/Type/TypeStatus/Tables/TypeTableStatus";
import StructureDtos from "../../Components/Type/TypeStructure/Models/StructureDtos";
import IdataModelTypeBonCommendDtos from "../../Components/Type/TypeBonCommend/Models/IdataModelTypeBonCommendDtos";
import { AddTypeBonSoin } from "../../Components/Type/TypeBonCommend/TypeOperations/AddTypeBonSoin";
import { EditTypeBonSoin } from "../../Components/Type/TypeBonCommend/TypeOperations/EditTypeBonSoin";
import { TableTypeBonSoins } from "../../Components/Type/TypeBonCommend/Tables/TableTypeBonSoins";
import IdataModelTypeStatusDtos from "../../Components/Type/TypeStatus/Models/IdataModelTypeStatusDto";


const initialTypeState = {
    id: null,
    typeName: '',
    account:'',
    createdBy: 'Ghost Zila',
    dateCreatedBy: new Date()
  };
  const initialTypeStateDtos = {
    typeBonsId:0,
typeBonsName:'',
typeBonAccount:'',
insuranceId:0,
   insuranceName:''
  };
  const initialStructureDto = {
    structureId:'',
    structureName:'',
    structureAccount:'',
    insuranceId:'',
    insuranceName:''
  };

export const Types:React.FC=()=>
    {
        const [isSwichStatus,setSwichStatus]= useState(true);
        const [isStructure,setStructure] = useState(true);

        const [istypeBonCommend,settypeBonCommend] = useState(true);

        const [EditModificationError, setEditModificationError] = 
                useState<IdataModelTypeBonCommendDtos>(initialTypeStateDtos);
        
                const onCurrantModificationError=(mydata:IdataModelTypeBonCommendDtos)=>{
                    setEditModificationError(mydata)
                }

        const [EditStatus, setEditStatus] = useState<IdataModelTypeStatusDtos>(initialTypeStateDtos);

        const onCurrantStatus=(data:IdataModelTypeStatusDtos)=>{
            setEditStatus(data);
        }

        const [EditStructure, setEditStructure] = 
        useState<StructureDtos>(initialStructureDto);

        const onCurrantStructure=(mydata:StructureDtos)=>{
            setEditStructure(mydata)
        }
        
        return(
            <>
            <Container fluid >
                <Row>
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                    {isStructure?
                (
                    <AddTypeStructure />
                ):(
      <EditTypeStructure
       show={isStructure} 
        onHide={() => setStructure(true)}

      EditInfo={EditStructure} /> 
                )
  }
         <TypeTableStructure
          show={isStructure} 
           onHide={() => setStructure(false)}
         onEditType={onCurrantStructure}
          />
 </Card.Body>

                </Card>
                <br />
                </Col>
                
                <Col> 
                <br />
                <Card>
                    <Card.Body>
                {isSwichStatus?
                (
                    <AddTypeStatus />
                ):(
       <EditTypeStatus
         show={isSwichStatus} 
         onHide={() => setSwichStatus(true)}
         EditInfo={EditStatus} /> 
                )
  }
         <TypeTableStatus show={isSwichStatus}  onHide={() => setSwichStatus(false)}
         onEditType={onCurrantStatus}
          />
                    </Card.Body>
                </Card>
                <br />
                </Col>
           
                   <Col> 
                   <br />
                <Card>
                    <Card.Body>
                    {istypeBonCommend?
                (
                    <AddTypeBonSoin />
                ):(
      <EditTypeBonSoin show={istypeBonCommend}  onHide={() => settypeBonCommend(true)}

      EditInfo={EditModificationError} /> 
                )
  }
         <TableTypeBonSoins
          show={istypeBonCommend} 
           onHide={() => settypeBonCommend(false)}
         onEditType={onCurrantModificationError}
          />
 </Card.Body>

                </Card>
                <br />
                </Col>
                

            </Row>
            
            </Container>
            </>
        );
    }