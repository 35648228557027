import { Row, Form } from "react-bootstrap";
import IdataModelTypeDoctor from "../TypesDoctors/Models/IdataModelTypeDoctor";
import { useEffect, useState } from "react";
import TypeDoctorServices from "../TypesDoctors/Services/TypeDoctorServices";
import { ItypeDoctorHospitals } from './Interfaces/ItypeDoctorHopital';
import IdataModelHospitalType from "../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";

const defaultinitiaTypeDoctor: Array<IdataModelTypeDoctor> = [];
const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];

let typeDoctorId;
let typeHospitalId;

export const DropdownTypeDoctorHopital:React.FC<ItypeDoctorHospitals>
= props =>{
  const [idTypeDoctor, setIdTypeDoctor] = useState<any>('')
  const [idTypeHospital, setidTypeHospital] = useState<any>('')
  const [isTypeName, setIsTypeName] = useState('')

  useEffect(() => {
    setIdTypeDoctor(props.typeDoctorId)
    setidTypeHospital(props.typeHospitalId)
  },[props.typeDoctorId,props.typeHospitalId])

    const [specialty, setSpecialty] = useState(defaultinitiaTypeDoctor);
    const [hospital, setHospital] = useState(defaultinitiaHospitalType);


    const getTypeDoctorWithoutPagination = () => {
  
        TypeDoctorServices.getAllTypeDoctorWithoutPagination()
      
          .then((response: any) => {
            setSpecialty(response.data);
           // console.log(speciality);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      };

      const getHospitalWithoutPagination = () => {  
        HospitalTypeService.getAllHospitalNameWithoutPagination()
          .then((response: any) => {
            setHospital(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      };
    

const handleChangeTypeDoctorold = (e: { target: {value: any; }; }) => {
    typeDoctorId=e.target.value;
props.updateIdtypeDoctor(typeDoctorId);
   console.log('typeDoctorId  ',typeDoctorId);

      }
     
      const handleChangeTypeDoctor = (selectedValue: string) => {
        const selectedOption = specialty.find(item => item.id === parseInt(selectedValue));
      
        if (selectedOption) {
          typeDoctorId = selectedOption.id;
          const selectedTypeName = selectedOption.typeName;
      
          // Now you have both id and typeName
         // console.log('Selected Type Doctor Id: ', typeDoctorId);
         // console.log('Selected Type Doctor Name: ', selectedTypeName);
      
          // You can update the state or perform any other actions with the selected values
          setIdTypeDoctor(typeDoctorId);
          setIsTypeName(selectedTypeName);
      
          props.updateIdtypeDoctor(typeDoctorId);
          console.log('typeDoctorId  ', typeDoctorId);
          props.updateTypeName(selectedTypeName);
          console.log('selectedTypeName is  ', isTypeName);
        } else {
          console.log('Unable to find selectedOption for value: ', selectedValue);
        }
      };
      

      const handleChangeTypeHospital = (e: { target: {value: any; }; }) => {
        typeHospitalId=e.target.value;
    props.updateIdtypeHospital(typeHospitalId);
    
    //     console.log('typeDoctorId iss ',props.typeDoctorId);
         console.log('typeHospitalId  ',typeHospitalId);
    
          }


      useEffect(() => {
        getTypeDoctorWithoutPagination();
        getHospitalWithoutPagination();
       
      });
      
    return(
        <>
 <Row className="mb-3">   
<Form.Group controlId="formGridCheckbox" className="col col-sm-6">

    <Form.Label>Type Docteur</Form.Label>
    <Form.Select
  className="form-control"
  onChange={(e) => handleChangeTypeDoctor(e.target.value)}
>
  <option value="0">Choose..</option>
  {specialty &&
    specialty.map((specialty) => (
      <option key={specialty.id} value={specialty.id}>
        {specialty.typeName}
      </option>
    ))}
</Form.Select>

</Form.Group>

<Form.Group controlId="formGridCheckbox" className="col col-sm-6">

    <Form.Label>Type Hopital</Form.Label>
    <Form.Select className="form-control" 
     onChange={handleChangeTypeHospital}>
       <option value="0">Choose..</option>
      {hospital &&
    hospital.map((hospital) => (
        <option key={hospital.id} value={hospital.id} >{hospital.hospitalName}</option>
    ))}
        
    </Form.Select>
</Form.Group>
 </Row>

        
        </>
    );
}