
import PatientInfo from "../../Components/VerificationActivity/PatientInfo";
import TabVerification from "../../Components/VerificationActivity/TabVerification";

export const EspaceVerifications=()=>{

    return (

        <div>
             <PatientInfo/>
             <TabVerification/>
         </div>
    );
}