import { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import IdataModelPriceMaterielDtos from "../../../MaterialPrice/Models/IdataModelPriceMaterielDtos";
import { AddPrice } from "../../../MaterialPrice/PriceOperations/AddPrice";
import { EditAddPrice } from "../../../MaterialPrice/PriceOperations/EditAddPrice";
import { PriceMaterielTable } from "../../../MaterialPrice/Tables/PriceMaterielTable";
import IdataModelTypeMateriel from "../../Models/IdataModelTypeMateriel";

  const initialMaterialModelDtos = {
    materielId:'',
    materielName:'',
    typeMateriel:'',
    accountMateriel:'',
    hospitalId:'',
    hospitalName:'',
    accountHospital:'',
    tariffId:'',
    tariffPrice:''
    
  };

  const initialMaterialModel= {
    id :'',
    typeName: '',
    typeMateriel:'',
    account:'',
    createdBy: '',
    dateCreatedBy: new Date()
  };
  interface IeditMaterialList {
    show: boolean; 
    hospitalid:any;
    onHide: () => void; 
    editinfo: IdataModelTypeMateriel;
  }

  export const ModelPriceMateriels:React.FC<IeditMaterialList>=props=>
    {
        const [isPriceModel,setPriceModel]= useState(true);
        const [typeHospitalId,setTypeHospitalId]= useState(0);

        const [EditPriceDtos, setEditPriceDtos] = useState<IdataModelPriceMaterielDtos>(initialMaterialModelDtos);

        const [EditPrice, setEditPrice] = useState<IdataModelTypeMateriel>(initialMaterialModel);

        const onCurrantPriceEditDtos=(CurrantData:IdataModelPriceMaterielDtos)=>{
            setEditPriceDtos(CurrantData);
        }
        
  useEffect(() => {
    setEditPrice(props.editinfo)
    setTypeHospitalId(props.hospitalid)
      },[props.editinfo, props.hospitalid])
        return(
            <>
    <Modal {...props}
    size="lg"
    backdrop="static"
    keyboard={false}
    
     aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Prix de Materiel selon Hopital   
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
      <Container fluid>
                <Row>
                <Col> 
                <Card>
            <Card.Body>
           {isPriceModel?
                (
                    <AddPrice show={false}
                     hospitalid={typeHospitalId}
                     onHide={() => setPriceModel(false)}
                    editcurrantacts={EditPrice} />
                ):(
      <EditAddPrice 
      show={isPriceModel}
       onHide={() => setPriceModel(true)}
        editpriceDtos={EditPriceDtos}
             /> 
                )
  }
     <PriceMaterielTable 
     show={isPriceModel} 
     onHide={() => setPriceModel(false)}
     onEditprice={onCurrantPriceEditDtos}

          />
            </Card.Body>
        </Card>
                </Col>
                </Row>
            </Container>
      </Modal.Body>
    
    </Modal>
           
            </>
        );
    }