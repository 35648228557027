import {  ChangeEvent, useCallback, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";

import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import IdataModelHospitalType from "../../../../ASSURANCES/StructurePartenaires/Hospitals/Models/IdataModeHospitalType";
import HospitalTypeService from "../../../../ASSURANCES/StructurePartenaires/Hospitals/Services/HospitalTypeService";
import { IpriceActes } from "../../../../Acts/PriceActes/Interfaces/IpriceActes";
import IdataModelPriceActesDtos from "../../../../Acts/PriceActes/Models/IdataModelPriceActesDtos";
import PriceSoinsService from "../../../../Acts/PriceActes/Services/PriceSoinsService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/Store/store";





let MonStructure:string='';

export const FacturationPriceActeTable:React.FC<IpriceActes>=props=>{

    
  const StructuresData = useSelector((state: RootState) => state.user.hemaClinicGroups);
  
    const defaultinitiaHospitalType: Array<IdataModelHospitalType> = [];
    const [hospital, setHospital] = useState(defaultinitiaHospitalType);

    const [priceHopital, setpriceHopital] = useState<Array<IdataModelPriceActesDtos>>([]);
    
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    
    const [searchdetails, setSearchdetails] = useState<string>("");
    
   
const getHospitalWithoutPagination = useCallback(() => {
  HospitalTypeService.getAllHospitalNameWithoutPagination()
    .then((response: any) => {
      setHospital(response.data);
    })
    .catch((e: Error) => {
      console.log(e);
    });
}, [setHospital]);

// Usage in the component
useEffect(() => {
  getHospitalWithoutPagination();
}, [getHospitalWithoutPagination]);


  const [typeHospitalId,setTypeHospitalId]= useState('Choose');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);
    const pageSizes = [3, 6];
    
    
    const getRequestParamsStructure = (searchintitule:string,hospitalName:string, thepage: number, thepageSize: number) => {
      let params = {
        intitule:searchintitule,
        hospitalName:hospitalName,
        page: thepage,
        size:thepageSize
      };
    
      if (searchintitule) {
        params["intitule"] = searchintitule;
      }
      if (hospitalName) {
        params["hospitalName"] = hospitalName;
      }
      if (thepage) {
        params["page"] = thepage - 1;
      }
      
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };
    const getRequestParams = (searchintitule:string, thepage: number, thepageSize: number) => {
      let params = {
        intitule:searchintitule,
        page: thepage,
        size:thepageSize
      };
    
      if (searchintitule) {
        params["intitule"] = searchintitule;
      }
      
      if (thepage) {
        params["page"] = thepage - 1;
      }
      
      if (thepageSize) {
        params["size"] = thepageSize;
      }
    
      return params;
    };

    const onChangeSearchdetails = (e: ChangeEvent<HTMLInputElement>) => {
      const searchdetails = e.target.value;
      setSearchdetails(searchdetails);
    };
    
    const findBydetails = () => {

      if(StructuresData.length===1){
    
        let concatenatedString: string = StructuresData.join(", ");
        MonStructure=concatenatedString

        setpriceHopital([]);
        PriceSoinsService.findByDetailsSoinsNameByStructure(searchdetails,MonStructure)
          .then((response: any) => {
            const {price} = response.data;
            setpriceHopital(price);
            setCurrentIndex(-1);
            console.log(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }else{
        setpriceHopital([]);
        PriceSoinsService.findByDetailsSoinsName(searchdetails)
          .then((response: any) => {
            const {price} = response.data;
            setpriceHopital(price);
            setCurrentIndex(-1);
            console.log(response.data);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }

    
    };

const retrievedetails = useCallback(() => {
  if (StructuresData.length === 1) {
    let concatenatedString: string = StructuresData.join(", ");
    MonStructure = concatenatedString;

    const params = getRequestParamsStructure(searchdetails, MonStructure, page, pageSize);
    // setpriceHopital([]);
    PriceSoinsService.getAllByStructure(params)
      .then((response: any) => {
        const { price, totalPages } = response.data;
        setpriceHopital(price);
        setCount(totalPages);
       //  console.log(response.data);
       // console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  } else {
    const params = getRequestParams(searchdetails, page, pageSize);
    // setpriceHopital([]);
    PriceSoinsService.getAll(params)
      .then((response: any) => {
        const { price, totalPages } = response.data;
        setpriceHopital(price);
        setCount(totalPages);
        console.log(response.data);
        console.log(params);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }
}, [StructuresData, searchdetails, page, pageSize, setpriceHopital, setCount]);


    useEffect(() => {  
      retrievedetails(); 
    },[retrievedetails]);

    useEffect(() => {  
    getHospitalWithoutPagination();      
    },[getHospitalWithoutPagination]);
    
    const handlePageChange = (event: any, value: any) => {
        setPage(value);
      };
    
      const handlePageSizeChange = (event: any) => {
        setPageSize(event.target.value);
        setPage(1);
      };
    
      const handleChangeTypeHospital = (e: { target: {value: any; }; }) => {
        setTypeHospitalId(e.target.value);
        console.log('typeHospitalId',typeHospitalId);
          }
    const refreshList = () => {
      retrievedetails();
      setCurrentIndex(-1);
    };
    
    const setActivedetails = (idataModelPriceActesDtos: IdataModelPriceActesDtos, index: number) => {
      setCurrentIndex(index); 
       props.onEditpriceActe(idataModelPriceActesDtos);
       props.onHide();
      console.log(idataModelPriceActesDtos);
     // console.log(index);
    };
    
    const OnDeletedetails = (id:any) => {
    
      PriceSoinsService.remove(id)
        .then((response: any) => {
          console.log(response.data);
          refreshList();
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

    return(
      <>
      <Row className="mb-3">
    
    <Stack  direction="horizontal" gap={2}>
              <Form.Control className="me-auto w-50"
                placeholder="Filtre le soins..."
                value={searchdetails}
                 onChange={onChangeSearchdetails} 
                />
       </Stack>   

    <Stack  direction="horizontal" gap={2}>

    {"Items per Page: "}
          <select onChange={handlePageSizeChange} value={pageSize}>
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
          <Row>
            <Col>
            <Pagination
          className="my-3"
          count={count}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />      
      </Col>             
    </Row>
        
    </Stack>
    </Row>

      <Table striped bordered hover size="sm" responsive>
      <thead>
        <tr>
                <th>Type</th>
                <th>Category</th>
                <th>Soins</th>
                <th>Prix</th>
                <th>Strutcture</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {priceHopital.length>0 ? (
              priceHopital.map((i, index) => (
                    <tr key={index }>
                    <td>{i.typePrestationsName}</td>
                    <td>{i.intituleSoins}</td>
                    <td>{i.intitule}</td>
                    <td>{i.price}</td>
                    <td>{i.hospitalName}</td>
                    <td>
              <Stack direction="horizontal" gap={2}>
              <Button className="me-4 btn btn-success btn-sm btn-block"
              onClick={() => setActivedetails(i, index)}>Selectioner</Button>

              </Stack>
              </td>
            </tr>
          ))
        ) : ( 
          <tr>
            <td colSpan={3}>No details</td>
          </tr>
        )}

      </tbody>
    </Table>
    

      </>
  );
    }

