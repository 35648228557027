import { ChangeEvent, useEffect, useState } from "react";
import CategoriesAcessSoinsServices from "../Services/CategoriesAcessSoinsServices";
import IdataModelCategoriesAcessSoins from "../Models/IdataModelCategoriesAcessSoins";
import { Row, Form, InputGroup } from "react-bootstrap";


let accountIncrement=0

const initialCategoryAccessSoins = {
  id: null,
  categorySoinsName: '',
  account:accountIncrement,
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
// 👇️ const now: Date
//const now = new Date();

//console.log(now); // 👉️ 2023-01-20T12:01:41.216Z

//console.log(now.toLocaleDateString()); // 👉️ 1/20/2023

//console.log(now.toLocaleString()); // 👉️ 1/20/2023, 2:01:41 PM

//console.log(now.toUTCString()); // 👉️ Fri, 20 Jan 2023 12:01:41 GMT

//console.log(now.toISOString()); // 👉️ 2023-01-20T12:01:41.216Z

export const CategoriesAcessSoins:React.FC=()=> {

const getLastAccountType =()=>{
  CategoriesAcessSoinsServices.getLastAccountType()
    .then((response: any) => {
        accountIncrement=response.data.account
        console.log(accountIncrement);
  })
}

useEffect(() => {
  getLastAccountType();
 
});


const [isInput,setInput]=useState<IdataModelCategoriesAcessSoins>(initialCategoryAccessSoins);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        if(accountIncrement===undefined){
          accountIncrement=50
        }
        let data = {
          categorySoinsName: isInput.categorySoinsName,
          account: accountIncrement+1,
          createdBy:'ghost zila ',
          dateCreatedBy: new Date()
        };

        CategoriesAcessSoinsServices.create(52,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            categorySoinsName: response.data.categorySoinsName,
            account:response.data.account,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialCategoryAccessSoins)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const resetTypeDoctor = () => {
        setInput(initialCategoryAccessSoins);
      }

     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Category Acess Soins</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="categorySoinsName" name="categorySoinsName" value={isInput.categorySoinsName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={resetTypeDoctor} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}