import http from "../../../../AxiosRequest/httpcommon";
import IdataModelAdhesion from "../Models/IdataModelAdhesion";
import IdataModelAdhesionDtos from "../Models/IdataModelAdhesionDtos";

const getAll = (params:any) => {
  return http.get<Array<IdataModelAdhesionDtos>>("/adhesionAssurance", { params });
};

const getAllEnterpriseWithoutPagination = () => {
  return http.get<Array<IdataModelAdhesion>>("/sortedadhesionAssurance");
};
const getLastAccountType=()=>{
  return http.get<Array<IdataModelAdhesion>>("/adhesionAssuranceAccountEnterpriseName");
}

const create = (socialinsuranceId:any,enterprisesAssuranceId:any,data: IdataModelAdhesion) => {
  return http.post<IdataModelAdhesion>(`/${socialinsuranceId}/${enterprisesAssuranceId}/adhesionAssurance`, data);
};

const update = (id: any, data: IdataModelAdhesion) => {
  return http.put<any>(`/adhesionAssurance/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/adhesionAssurance/${id}`);
};

const removeAll = () => {
  return http.delete<any>(`/adhesionAssurance`);
};

const findByEnterpriseName = (id:any,enterpriseName: string) => {
  return http.get<Array<IdataModelAdhesionDtos>>(`/adhesionAssurance?id=${id}&enterpriseName=${enterpriseName}`);
};

const ServiceAdhesion = {
  getAllEnterpriseWithoutPagination,
  getAll,
  getLastAccountType,
  create,
  update,
  remove,
  removeAll,
  findByEnterpriseName,
};

export default ServiceAdhesion;