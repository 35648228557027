import { ChangeEvent, useEffect, useState } from "react";
import IdataModelCategoryType from "../Models/IdataModelCategoryType";
import { Form, InputGroup, Row } from "react-bootstrap";
import CategoryAffilieTypeService from "../Services/CategoryAffilieTypeService";


let accountIncrement=0

const initialCategoryType = {
  id: null,
  categoryName: '',
  account:accountIncrement,
  createdBy: 'Ghost Zila',
  dateCreatedBy: new Date()
};
// 👇️ const now: Date
//const now = new Date();

//console.log(now); // 👉️ 2023-01-20T12:01:41.216Z

//console.log(now.toLocaleDateString()); // 👉️ 1/20/2023

//console.log(now.toLocaleString()); // 👉️ 1/20/2023, 2:01:41 PM

//console.log(now.toUTCString()); // 👉️ Fri, 20 Jan 2023 12:01:41 GMT

//console.log(now.toISOString()); // 👉️ 2023-01-20T12:01:41.216Z

export const CategoriesType:React.FC=()=> {

const getLastAccountType =()=>{
  CategoryAffilieTypeService.getLastAccountType()
    .then((response: any) => {
        accountIncrement=response.data.account
        console.log(accountIncrement);
  })
}

useEffect(() => {
  getLastAccountType();
 
});


const [isInput,setInput]=useState<IdataModelCategoryType>(initialCategoryType);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setInput({ ...isInput, [name]: value });
      }
      const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      }
      
      const save = () => {  

        if(accountIncrement===undefined){
          accountIncrement=20
        }
        let data = {
          categoryName: isInput.categoryName,
          account: accountIncrement+1,
          createdBy:'ghost zila ',
          dateCreatedBy: new Date()
        };

        CategoryAffilieTypeService.create(52,data)
        .then((response: any) => {
          setInput({
            id: response.data.id,
            categoryName: response.data.categoryName,
            account:response.data.account,
            createdBy: response.data.createdBy,
            dateCreatedBy: response.data.dateCreatedBy
          });
          console.log(response.data);
          console.log(data);
          setInput(initialCategoryType)
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
        
      const reset = () => {
        setInput(initialCategoryType);
      }

     
    return(
        <>
         < form className="form-edit" onSubmit={onFormSubmit}>
         <Row className="mb-3">
         <Form.Group controlId="formBasicEmail" className="col col-sm-6">
            <Form.Label>Category</Form.Label>
            <InputGroup>
                <Form.Control aria-label="Recipient's username" aria-describedby="basic-addon2" type="categoryName" name="categoryName" value={isInput.categoryName} onChange={handleChange} />
            </InputGroup>
        </Form.Group>
         </Row>
         <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
            <button  onClick={()=>save()} className="me-4 btn btn-success btn-sm btn-block">save</button>

            <button  onClick={reset} className="me-4 btn btn-danger btn-sm btn-block">Cancel</button>
            
        </Form.Group>
      </Row>
            </form>
        </>
    );
}